import {observable, action} from 'mobx';
import {QueryPannelStore} from './QueryPannelStore';
import {TablePannelStore} from './TablePannelStore';
import {BaseStore, NOTI_TITLE, NOTI_MSG} from './BaseStore';
import {ModalPannelStore} from "./ModalPannelStore";
import {AddPannelStore} from "./AddPannelStore";

/**
 * base store for every searchstore
 */

export class BaseSearchStoreForQuota extends BaseStore {
  @observable permissions:any
  @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  @observable queryPannel: QueryPannelStore;
  @observable tablePannel: TablePannelStore;
  @observable addPannel: AddPannelStore;
  @observable modalPannel: ModalPannelStore;
  @observable detailUrl = []

  pageIndex: number = 0
  pageSize: number = 20
  totalRecords = 0

  /**自定义排序搜索**/
  field
  order
  isSortSearch
  @observable sortSearchUrl

  searchCache = {}
  resetSearch = false
  paginator = null

  constructor(qp, tp, ap,params) {
    super(params)
    this.detailUrl = tp.detailUrl
    //this.modalPannel = new ModalPannelStore(qp)
    if (tp.modal) {
      tp.modal.ipts = this.init_ipts(tp.modal.ipts)
      tp.modal.btns = this.init_btns(tp.modal.btns)
      this.modalPannel = new ModalPannelStore(tp.modal)
    }
    this.queryPannel = new QueryPannelStore({ipts: this.init_ipts(qp.ipts), btns: this.init_btns(qp.btns), btnsclass: qp.btnsclass})
    this.tablePannel = new TablePannelStore(this.init_tp(tp))
    this.addPannel = new AddPannelStore({ipts: this.init_ipts(ap.ipts), btns: this.init_btns(ap.btns), btnsclass: ap.btnsclass})
    this.sortSearchUrl = tp.sortSearchUrl
    this.getPermission()
  }
  @action getPermission(){
    this.permissions = this.$CACHE.getObject('PERMISSIONS')[this.$PARAMS.snapshot.data.name]
    // console.log("----- permission module -----", this.$PARAMS.snapshot.data.name, this.permissions)
    this.setPermission(this.queryPannel.btns)
    this.setPermission(this.tablePannel.btns)
    // tslint:disable-next-line: no-bitwise
    if (this.$PARAMS.snapshot.data.name === 'Item Transfer Quota') {
      this.addPannel['show'] = true
    } else {
      this.addPannel['show'] = (this.permissions & this.defaultPermission.c) === this.defaultPermission.c
    }
  }
  @action changeTableSize() {
    setTimeout(() => {
      const event = new Event("resize");
      window.dispatchEvent(event);
    }, 800);
  }
  setPermission(list) {
    list.forEach(item=>{
      if(item.permissionType){
        let permission = this.defaultPermission[item.permissionType]
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permissions & permission) === permission
      }
    })
  }
  // searchWithTreeData
  /**
   * 搜索
   * @param url
   */
  @action search(url, index = 0) {

    if (!this.isSubmitAvailable()) return
    //判断之前是否有请求未响应
    if (this.executeWait()) return
    var data = this.queryPannel.format()
    // data.channelId = data.channelId ? data.channelId.data : null
    data.itemCode = data.itemCode ? data.itemCode.map(item=>item.code) : null
    // console.log(data);
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    data.accountName = this.$COMMONSTORE.getAccount()
    this.queryPannel.setBtnProperty(index, "loading", true)
    this.tablePannel.setLoading(true);

    this.isSortSearch = false
    // console.log(data);
    return this.$HTTP.post(url, data)
      .then(res => {
        this.tablePannel.setData(res.data)
        // console.log("SEARCHDATA");
        // console.log(res);
        this.tablePannel.mapOfCheckedId = {}
        //   this.tablePannel.mapOfChildrenCheckedId = {}
        this.tablePannel.allCheckBoxValue = false
        this.convertDateToTable(res);
        this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
        this.tablePannel.data = this.tablePannel.display_data
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
      })
      .catch(err => {
        console.log(err);
        // this.$NOTICE.error(NOTI_TITLE, err)
        this.showMessage('error', NOTI_TITLE, err);
      })
      .finally(() => {
        this.queryPannel.setBtnProperty(index, "loading", false)
        this.tablePannel.setLoading(false)
      })
  }

  sortSearch(key, order) {
    this.field = key
    this.isSortSearch = true
    if(order!=null){
      this.order = order
    }else if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    this.sortPageSearch(this.sortSearchUrl, key)
  }

  /**
   * 搜索
   * @param url
   */
  @action sortPageSearch(url, sortKey, index = 0) {


    if (!this.isSubmitAvailable()) return
    //判断之前是否有请求未响应
    if (this.executeWait()) return
    var data = this.queryPannel.format()
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    data.accountName = this.$COMMONSTORE.getAccount()
    data['sortEvent'] = {field: this.field, order: this.order}
    this.tablePannel.setLoading(true);

    console.log(data);
    return this.$HTTP.post(url, data)
      .then(res => {
        this.tablePannel.setData(res.data)
        // console.log("SORTSEARCHDATA");
        // console.log(res);
        // this.tablePannel.mapOfCheckedId = {}
        // //   this.tablePannel.mapOfChildrenCheckedId = {}
        // this.tablePannel.allCheckBoxValue = false
        // this.convertDateToTable(res);
        // this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
        // this.tablePannel.data = this.tablePannel.display_data
        // if (res.msg !== '') {
        //   this.totalRecords = res.msg
        // }
      })
      .catch(err => {
        console.log(err);
        // this.$NOTICE.error(NOTI_TITLE, err)
        this.showMessage('error', NOTI_TITLE, err);
      })
      .finally(() => {
        this.queryPannel.setBtnProperty(index, "loading", false)
        this.tablePannel.setLoading(false)
      })
  }

  /**
   * 判断当前是否有按钮处于loading状态
   */
  private executeWait() {
    let flag = false;
    this.queryPannel.btns.forEach(btn => {
      if (btn["loading"]) {
        flag = true;
      }
    });
    if (flag) {
      // this.$NOTICE.warning(NOTI_TITLE, "Please wait until the execution is completed")
      this.showMessage('warn', NOTI_TITLE, "Please wait until the execution is completed");
    }
    return flag;
  }

  // @action timestampToTime(v) {
  //   return CommonMethod.timestampToTime(v)
  // }

  /**
   * 清除输入值
   * @param url
   */
  @action clear(url) {
    //判断之前是否有请求未响应
    if (this.executeWait()) return
    // 清除输入值
    this.queryPannel.clear()
    // 重新请求
    this.tablePannel.setLoading(true);
    this.queryPannel.setBtnProperty(1, "loading", true)
    const accountName = this.$COMMONSTORE.getAccount()
    this.$HTTP.post(url, {pageIndex: this.pageIndex, pageSize: this.pageSize, accountName})
      .then(res => {
        this.tablePannel.setData(res.data)
        this.convertDateToTable(res);
        this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
      })
      .catch(err => {
        // this.$NOTICE.error(NOTI_TITLE, err)
        this.showMessage('error', NOTI_TITLE, err);
      })
      .finally(() => {
        this.tablePannel.setLoading(false)
        this.queryPannel.setBtnProperty(1, "loading", false)
      })
  }


  /**
   * 删除
   * @param url
   */
  @action delete(url, u, m) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    }
    if (this.judgeIsActive(obj) == false) {
      //模态框确定
      this.$MODAL.confirm({
        nzContent: `Do you want to delete these ${len} items?`,
        nzOnOk: () => {
          postDeleteRequest();
        }
      });
    }
    //删除请求
    const postDeleteRequest = () => {
      this.tablePannel.setLoading(true)
      //请求API
      this.$HTTP.post(url, obj)
        .then(res => {
          var data = []
          this.tablePannel.data.forEach((d, index) => {
            if (!this.tablePannel.mapOfCheckedId[d.id]) {
              data.push(d)
            }
          })
          //清空选中并重赋数据源
          this.tablePannel.setData(data)
          this.tablePannel.display_data = this.toTreeData(data, this.tablePannel)
          this.tablePannel.allCheckBoxValue = false
          this.tablePannel.mapOfCheckedId = {}
        })
        .catch(err => {
          // this.$NOTICE.error(NOTI_TITLE, err)
          this.showMessage('error', NOTI_TITLE, err);
        })
        .finally(() => {
          this.tablePannel.setLoading(false)
          if (u != null) {
            this.loadToCache(u, m)
          }
        })
    }
  }

  /**
   * 判断数据是否失效
   * @param obj
   */
  judgeIsActive(obj) {
    let isAs = false
    obj['ids'].forEach(id => {
      this.tablePannel.data.forEach(da => {
        if (id == da.id && da.active == 'Y') {
          // this.$NOTICE.warning(NOTI_TITLE, "The selected is active,please disable first!")
          this.showMessage('warn', NOTI_TITLE, "The selected is active,please disable first!");
          isAs = true
        }
      })
    })
    return isAs
  }

  /**
   * 禁用
   * @param url
   */
  @action disable(url, u, m) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    } else {
      //模态框确定
      this.$MODAL.confirm({
        nzContent: `Do you want to disable these ${len} items?`,
        nzOnOk: () => {
          this.tablePannel.setLoading(true)
          //请求API
          this.$HTTP.post(url, obj)
            .then(res => {
              if (res.code == '005') {
                // this.$NOTICE.warning(NOTI_TITLE, "The selected is used,can't disable!")
                this.showMessage('warn', NOTI_TITLE, "The selected is used,can't disable!");
                return
              } else if (res.code == '000') {
                var data = []
                this.tablePannel.data.forEach((d, index) => {
                  if (this.tablePannel.mapOfCheckedId[d.id]) {
                    d['active'] = 'N'
                  }
                })
                //清空选中并重赋数据源
                this.tablePannel.setData(this.tablePannel.data)
                this.tablePannel.display_data = this.toTreeData(this.tablePannel.data, this.tablePannel)
                this.tablePannel.allCheckBoxValue = false
                this.tablePannel.mapOfCheckedId = {}
              }
            })
            .catch(err => {
              // this.$NOTICE.error(NOTI_TITLE, err)
              this.showMessage('error', NOTI_TITLE, err);
            }).finally(() => {
              this.tablePannel.setLoading(false)
              if (u != null) {
                this.loadToCache(u, m)
              }
            }
          )
        }
      });

    }
  }

  /**
   * 启用
   * @param url
   */
  @action enable(url, u, m) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    } else {
      //模态框确定
      this.$MODAL.confirm({
        nzContent: `Do you want to enable these ${len} items?`,
        nzOnOk: () => {
          this.tablePannel.setLoading(true)
          //请求API
          this.$HTTP.post(url, obj)
            .then(res => {
              if (res.code == '000') {
                var data = []
                this.tablePannel.data.forEach((d, index) => {
                  if (this.tablePannel.mapOfCheckedId[d.id]) {
                    d['active'] = 'Y'
                  }
                })
                //清空选中并重赋数据源
                this.tablePannel.setData(this.tablePannel.data)
                this.tablePannel.display_data = this.toTreeData(this.tablePannel.data, this.tablePannel)
                this.tablePannel.allCheckBoxValue = false
                this.tablePannel.mapOfCheckedId = {}
              }
            })
            .catch(err => {
              // this.$NOTICE.error(NOTI_TITLE, err)
              this.showMessage('error', NOTI_TITLE, err);
            }).finally(() => {
              this.tablePannel.setLoading(false)
              if (u != null) {
                this.loadToCache(u, m)
              }
            }
          )
        }
      });

    }
  }

  /**
   * 跳转至修改页
   * @param routerRule
   */
  @action edit(routerRule) {
    //获取选中对象
    let arr = this.tablePannel.formatSelectedObjs();
    this.tablePannel.display_data.forEach(d => {
      if (arr[0] && d.id == arr[0]["id"]) {
        if (d.tableMutiData) {
          d.tableMultiData = JSON.stringify(d.tableMutiData)
          if (d.inventoryAssetFlag) d.inventoryAssetFlag = d.inventoryAssetFlag == 'I' ? true : null;
          if (d.returnableFlag) d.returnableFlag = d.returnableFlag == 'Y' ? true : null;
          if (d.tangibleItem) d.tangibleItem = d.tangibleItem == 'Y' ? true : null;
        }

        if (d.line) {
          d.line = JSON.stringify(d.line)
        }
        arr[0] = d
      }
    })
    //较验
    if (arr.length < 1 || arr.length > 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.ONE_SELECTED_ONLY)
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.ONE_SELECTED_ONLY);
      return
    }
    //跳转并传参（通过querystring,basestroe封装了自动获参和注值。）
    /* if (routerRule[1] == 'masterfile_attr_value')
      Object.assign(arr[0], {valueType: !arr[0]['attrValue'] ? -1 : 0}) */
    this.$ROUTER.navigate(routerRule, {queryParams: arr[0]})
  }

  /**
   * 跳转创建页
   * @param routerRule
   */
  @action create(routerRule) {
    this.$ROUTER.navigate(routerRule)
  }

  /**
   * 正则校验
   * @param store
   */
  @action isSubmitAvailable() {
    return this.queryPannel.isSubmitAvailable()
  }

  /**
   * 页面时间显示处理
   * @param store
   */
  @action convertDateToTable(res) {
    if (res.data && res.data != null) {
      res.data.forEach(d => {
        d.createTime = d.createAt
        if (d.createAt && d.createAt != null && d.createAt != 0) {
          d.createAt = this.convertDate(d.createAt).split(' ').reverse().join(' ')
          d.createAtNoTime = d.createAt.substr(9)
        } else {
          d.createAtNoTime = ""
        }
        if (d.updateAt && d.updateAt != null && d.updateAt != 0) {
          d.updateAt = this.convertDate(d.updateAt).split(' ').reverse().join(' ')
        }
        if (d.approval && d.approval != null && d.approval != 0) {
          d.approval = this.convertDate(d.approval).split(' ').reverse().join(' ')
        }
      })
    }
  }

  /**
   * 打开详情页面并处理需要展示的数据
   * @param d
   */
  @action openDet(d) {
    if (d.attrData && d.attrData.length < 1) {
      delete d.attrData
    }
    if (d.rightData && d.rightData.length < 1) {
      delete d.rightData
    }
    if (d.stepData && d.stepData.length < 1) {
      delete d.stepData
    }
    if (d.steps && d.steps.length < 1) {
      delete d.steps
    }
    if (d.logDtls && d.logDtls.line.length < 1) {
      delete d.logDtls
    }
    if (d.line && d.line.length < 1) {
      delete d.line
    }
    d.rightData = JSON.stringify(d.rightData)
    d.attrData = JSON.stringify(d.attrData)
    d.stepData = JSON.stringify(d.stepData)

    d.steps = JSON.stringify(d.steps)
    d.logDtls = JSON.stringify(d.logDtls)
    d.line = JSON.stringify(d.line)
    console.log(d.logDtls)
    this.detailUrl && this.$ROUTER.navigate(this.detailUrl, {queryParams: d})
  }

  /**
   * open modal
   */
  @action showModal() {
    this.modalPannel.showModal();
  }

  /**
   *
   * save
   */
  @action save() {
    this.modalPannel.required(this.modalPannel.ipts);
    if (!this.isSubmitAvailable()) return

    // var data: any = this.modalPannel.format()
    //API
  }

  /**
   * clear
   * @param url
   */
  @action clearModalPannel() {
    this.modalPannel.clear()
  }
  showMessage(severity, summary, detail){
    this.$NOTICE.add({severity, summary, detail});
  }
  
  doSortSearchBySortFunction(key, order){
    if(this.field==key && order!=null && this.order == order) return
    this.sortSearch(key, order)
  }
}
