import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/service/common/common-service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})

export class TaskComponent implements OnInit {
  tableData = {
    data: [
      {task: 'rh.cstk.order-type.fsta', topic: false, full: true},
      {task: 'rh.cstk.channel.fsta', topic: false, full: true},
      {task: 'rh.cstk.channel-condition-mapping.fsta', topic: false, full: true},
      {task: 'rh.cstk.stock-condition.fsta', topic: false, full: true},
      {task: 'rh.cstk.user-def-lookup.fsta', topic: false, full: true},
      {task: 'rh.cstk.stock-reservation-type.fsta', topic: false, full: true},
      {task: 'rh.cstk.sys-def-lookup.fsta', topic: false, full: true},
      {task: 'rh.cstk.stock-order-delta.fsta', topic: true, full: true},
      {task: 'rh.cstk.stock-transaction-history-delta.fsta', topic: true, full: true},
      {task: 'rh.cstk.stock-order-line-detail-delta.fsta', topic: true, full: true},
      {task: 'rh.cstk.item-master-delta.fsta', topic: true, full: true},
      {task: 'rh.cstk.lis-stock-interface-delta.fsta', topic: true, full: true},
      {task: 'rh.cstk.stock-reservation-delta.fsta', topic: true, full: true}
    ],
    selectedColumns: [
      { field: "action", title: "Action", type: 'action' , width: "40px"},
      { field: "task", title: "Task" },
    ],
    detailUrl: [],
    showCheckboxSwitch: false,
  }
  constructor(private commonService: CommonService, private msg: MessageService,) { }

  ngOnInit(): void {
  }
  rowClick(name, type) {
    // console.log(name, type);
    const data = {
      topic: name,
      type: type === "full" ? 1 : 2
    }
    this.commonService.runTask(data).subscribe(res=>{
      console.log(res);
      this.showMessage('info', 'Task', `${name} starts running!`)
    })
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
}
