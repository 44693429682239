import { Component, OnInit } from '@angular/core';
import { DailyStockMovementHistoryStore } from 'src/app/stores/stock/movement/DailyStockMovementHistoryStore';

@Component({
  providers: [DailyStockMovementHistoryStore],
  selector: 'app-ppos',
  templateUrl: './ppos.component.html',
  styleUrls: ['./ppos.component.css']
})
export class PposComponent implements OnInit {

  constructor(public store: DailyStockMovementHistoryStore) { }

  ngOnInit(): void {
  }

}
