import { BaseEditStore } from '../../base/BaseEditStore';
import { INPUT_TYPE,BUTTON_TYPE } from '../../base/BaseStore'

export class EditStore extends BaseEditStore{

    constructor(params) {
        var inputPannel = {
            ipts:[
              {
                title:"ID",
                name:'id',
                value:'',
                class: "p-col-12",
                type:INPUT_TYPE.INPUT,
              },
              {
                title:"Channel",
                name:'channelId',
                class: "p-col-12",
                type:INPUT_TYPE.TREESELECT,
                options:"REPOTREE",
                value:""
              },
              {
                title:"Item",
                name:'itemCode',
                class: "p-col-12",
                type:INPUT_TYPE.SELECT,
                options:"SKU",
              },
              {
                title: "UOM",
                name: 'uom',
                class: "p-col-12",
                type: INPUT_TYPE.SELECT,
                options:[
                  {code: "EA", other: "", name: "EA"}
                ]
              },
              {
                title:"Max Trans.Qty Per Day",
                name:'maxTransQtyPerDay',
                class: "p-col-12",
                type:INPUT_TYPE.INPUT
              }
              ],
            btnsclass: "p-d-flex p-col-12",
            btns:[{
                type:BUTTON_TYPE.BUTTON,
                title:'Submit',
                category: 'primary',
                class: "p-order-2",
                handler:{
                    "click":()=>{
                        this.edit(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_EDIT,
                          null, null)
                    }
                }
            },{
                type:BUTTON_TYPE.BUTTON,
                title:'Clear',
                class: "p-button-outlined p-order-1 p-mr-1",
                handler:{
                    'click':()=>{
                        this.clear()
                    }
                }
            },{
                type:BUTTON_TYPE.BUTTON,
                title:'Back',
                class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
                handler:{
                    'click':()=>{
                        this.back()
                    }
                }
            }]
        }
        super(inputPannel,params);
        this.ifChildren();
    }
    change(i, val, obj){
      console.log('--- change data ---', i, val, obj);

    }
}
