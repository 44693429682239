// import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @description: 树形选择组件
 * @author: 陈述承
 */
/* @Component({
  selector: 'app-treeSelect-cst',
  templateUrl: './treeselect-cst.component.html',
  styleUrls:['./treeselect-cst.component.css']
})
export class TreeSelectCustom {
  @Input() ipt
  @Output() change = new EventEmitter()
    onChange(v){
      console.log(v)
      event.stopPropagation();
      // this.change.emit(this.ipt.multiple?v:v[0])
      this.change.emit(v)
    }
} */
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'app-treeSelect-cst',
  templateUrl: './treeselect-cst.component.html',
  styleUrls: ['./treeselect-cst.component.scss'],
  animations: [
    trigger('overlayAnimation', [
        transition(':enter', [
            style({opacity: 0, transform: 'scaleY(0.8)'}),
            animate('{{showTransitionParams}}')
          ]),
          transition(':leave', [
            animate('{{hideTransitionParams}}', style({ opacity: 0 }))
          ])
    ])
  ],
})
export class TreeSelectCustom implements OnInit, OnDestroy {
  @ViewChild("overlay") overlay:OverlayPanel;
  @ViewChild("wrapper") wrapper:ElementRef<HTMLElement>;

  // @HostBinding("style.--input-wrapper-width") 
  public inputWrapperWidth = "300px"

  isSelect: boolean = false

  _isOpen: boolean = false
  set isOpen(e){
    this._isOpen = e;
    if(e){
      let dummyEvent = new CustomEvent("CustomEvent");
      Object.defineProperty(dummyEvent, "target", {
        writable: false,
        value: this.wrapper.nativeElement,
      });
      this.inputWrapperWidth = this.wrapper.nativeElement.clientWidth+'px'
      this.overlay.show(dummyEvent)
    }else{
      this.overlay.hide()
    }
  }
  get isOpen(){return this._isOpen}

  outside$: Subscription = null
  @Input() ipt
  @Output() change = new EventEmitter()
  _treeOptions=[];

  @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input() hideTransitionOptions: string = '.1s linear';

  constructor(@Inject(DOCUMENT) private doc: Document, private el: ElementRef) { }

  ngOnInit(): void {
    this.outside$ = fromEvent(this.doc, 'click').pipe(pluck('target')).subscribe(el=>{
      let inside = this.el.nativeElement.contains(el) || this.overlay.el.nativeElement.contains(el) || this.overlay.container?.contains(<Node>el);
      if (!inside) {
        this.isOpen = false
      }
    })
  }

  ngOnDestroy(): void {
    this.outside$ && this.outside$.unsubscribe()
  }

  ngOnChanges(changes){
    if(changes['ipt']){
      // default set parent selectable to false
      this._treeOptions = this.ipt.options?
        this.ipt.options.map((parent)=>{
          return parent.children&&parent.children.length>0&&parent.selectable==null?
            {...parent, selectable: false}:
            parent
        }):
        [];
    }
  }

  clearSelectData() {
    this.isSelect = false
    this.ipt.value = null
    this.ipt.showValue = null
    this.change.emit(null)
  }

  openSelectPanel() {
    if (!this.ipt.disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  nodeSelect(event) {
    this.ipt.error = null
    this.ipt.value = event.node
    this.ipt.showValue = event.node.label
    this.isSelect = true
    this.isOpen = false
    this.change.emit(event.node)
  }

  nodeUnselect(event) {
    this.change.emit(event.node)
  }

  onKeydown(event: KeyboardEvent) {
    switch (event.code) {
      //down
      case "ArrowDown":
        if (!this.isOpen && event.altKey) {
          this.isOpen = true;
          event.preventDefault();
        }
        break;

      //space
      case "Space":
        if (!this.isOpen) {
          this.isOpen = true;
          event.preventDefault();
        }
        break;

      //escape
      case "Escape":
        this.isOpen = false;
        break;
    }
  }

  onOverlayHide(e){
    this._isOpen = false
  }
}
