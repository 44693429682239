import { Component, OnInit } from '@angular/core';
import { MainStore } from '../../stores/main'
import { Router, ActivatedRoute } from '@angular/router';
import { InjectHelper } from 'src/app/util/InjectHelper';
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {Oauth2Config} from "../../config/Oauth2Config";
import {action} from "mobx";
import {LocalStorageHelper} from "../../util/LocalStorageHelper";
import {HttpHelper} from "../../util/HttpHelper";
import {URLDICT} from "../../stores/base/BaseStore";
import { MessageService, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MENU } from '../../config';
import { CommonService } from '@/service/common/common-service';
import { CommonStore } from '@/service/common/common-store-service';


@Component({
  // changeDetection:ChangeDetectionStrategy.OnPush,
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit{

  /**
   *
   */
  public store
  public stockItems = []
  public otherItems = []
  public rightItems
  public showOption = false
  constructor(
    private n: MessageService,
    private r: Router,
    private p: ActivatedRoute,
    private m: ConfirmationService,
    private oauthService: OAuthService,
    private i18n: TranslateService,
    public commonService: CommonService,
    public commonStore: CommonStore,
  ) {
    this.store = new MainStore(InjectHelper.start(this))
    //检查是否存在config或uam，有的话将showOption设为true
    const showConfig = this.store.menuArr.find(item => item.label === MENU.CHANNEL_ITEM_CONTROL).visible
    const showUAM = this.store.menuArr.find(item => item.label === MENU.SECURITY).visible
    this.showOption = showConfig || showUAM

    this.configure()

    this.stockItems = this.store.menuArr[0].items
    this.otherItems.push(this.store.menuArr[1])
    this.otherItems.push(this.store.menuArr[2])
    /* const language = {
      items: [
        {
          auth: "r",
          children: [],
          label: "English",
          name: "english",
          command: () => { this.changeLanguage('en-US') }
        }, {
          auth: "r",
          children: [],
          label: "Chinese",
          name: "chinese",
          command: () => { this.changeLanguage('zh-CN') }
        },
      ],
      label: 'Language',
      name: "language",
      visible: true
    } */
    // this.otherItems.push(language)
    this.rightItems = [
      {
        label: this.store.welcomeText,
        icon: 'pi pi-fw pi-user'
      }, 
      {
        label: 'Change Password',
        icon: 'pi pi-fw pi-key',
        command: () => { this.changepassword() }
      },{
        label: 'Quit',
        icon: 'pi pi-fw pi-power-off',
        command: () => { this.logout() }
      }
    ]

    //这个需要用户确认后，才实施
    // const local = this.i18n.currentLang
    // this.changeLanguage(local)
  }

  public configure() {
    this.oauthService.configure({
      issuer: Oauth2Config.issuer,
      redirectUri: window.location.origin + "/loading",
      clientId: Oauth2Config.clientId,
      //dummyClientSecret: '2ea5dc66-8e1c-4e7c-aac0-52a42594a6ac',
      scope: 'openid email profile',
      responseType: Oauth2Config.responseType,
      requireHttps: Oauth2Config.requireHttps,
      // at_hash is not present in JWT token
      disableAtHashCheck: Oauth2Config.disableAtHashCheck,
      //postLogoutRedirectUri: window.location.origin + "/heroes",
      showDebugInformation: Oauth2Config.showDebugInformation
    });
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
  @action changeLanguage(locale){
    this.i18n.use(locale)
    //这个需要用户确认后，才实施
    /* this.i18n.get("menu").subscribe((res) => {
      this.resetMenu(res)
    }); */
  }
  resetMenu(data) {
    this.otherItems.forEach(item => {
      const diff = item.name
      item.label = data[diff].title
      item.items.forEach(subItem => {
        if(subItem.name in data[diff]) {
          subItem.label = data[diff][subItem.name]
        }
      })
    })
    this.store.menuArr[0].items.forEach(item => {
      item.label = data.stock[item.name]
      if(item.items){
        item.items.forEach(subItem => {
          if(subItem.name in data.stock) {
            subItem.label = data.stock[subItem.name]
          }
        })
      }
    })
    this.stockItems = this.store.menuArr[0].items
  }
  /**
   * 登出(logout)
   */
  @action logout() {
    this.commonService.logout()
    // this.clearSession().then(()=>{
    //   LocalStorageHelper.removeAll()
    //   sessionStorage.clear()
    //   this.oauthService.logOut()
    //   this.r.navigate([""])
    // })
  }

  @action changepassword() {
    this.commonService.changepassword()
  }
  // /**
  //  * 调API删除redis中的session信息
  //  */
  // clearSession(){
  //   return HttpHelper.post(URLDICT.SYSTEM_LOGOUT, {}).then(
  //     res => {
  //       console.log(res)
  //     }
  //   )
  // }

  ngOnInit(): void {
    //离开当前页或关闭浏览器
    window.onbeforeunload = () => {
      this.store.clearSession().then(() => {
        return
      })
    }
  }
  redirectTo(itemUrl){
    // CustomReuseStrategy.deleteAllRouteCache()
    let url = 'main/' + itemUrl
    // console.log(url); // Used for testing
    this.r.navigate([url])
  }
}
