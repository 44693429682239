import { DemandCycleStore } from './DemandCycleStore';
import { makeAutoObservable, reaction } from 'mobx';
import { Injectable } from "@angular/core";
import { MENU } from '../../../config';
import { INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { CommonMethod } from '@/util/CommonMethod';

@Injectable()
export class CreateStore {
  breadcrumb = MENU.DEMAND_CYCLE
  currentYear=new Date().getFullYear()
  currentMonth=new Date().getMonth()
  currentDate=new Date().getDate()
  queryData = {
    ipts: [],
    btns: [],
    btnsclass: 'p-col-12 p-text-right',
    change: function(...e){
    }
  }
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  loading:boolean = false
  currentDemandCycleType = null
  cycleNameIpt = null
  poolTypeIpt = null
  cycleTypeIpt = null
  startDateIpt = null
  // endDateIpt = null
  
  submissionDeadlineIpt = null

  constructor(
    private common: DemandCycleStore,
    private router: Router,
    private route: ActivatedRoute,
    private service: StockDemandCycleService) {
    makeAutoObservable(this)
    this.setQueryData()
    this.getPermission(route)
    this.initQueryIpt()
    this.checkCycleChange()
    this.initQueryData()
  }

  get forecastCycleName() {
    return this.cycleNameIpt.value
  }
  // get stockPoolType() {
  //   return this.poolTypeIpt.value
  // }
  get stockCycleType() {
    return this.cycleTypeIpt.value
  }
  get forecastStartDate() {
    return this.startDateIpt.value
  }
  // get forecastEndDate() {
  //   return this.endDateIpt.value
  // }
  get submissionDeadline() {
    return this.submissionDeadlineIpt.value
  }
  /* get showU() {
    // tslint:disable-next-line: no-bitwise
    return (this.permission & this.defaultPermission.u) === this.defaultPermission.u
  }
  get showC() {
    // tslint:disable-next-line: no-bitwise
    return (this.permission & this.defaultPermission.c) === this.defaultPermission.c
  }
  get showD() {
    // tslint:disable-next-line: no-bitwise
    return (this.permission & this.defaultPermission.d) === this.defaultPermission.d
  } */

  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title === 'Save' && (item.disabled = !this.permission.includes("Create Demand Cycle"))
      item.title === 'Cancel' && (item.disabled = !this.permission.includes("Create Demand Cycle"))
    })
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  checkCycleChange() {
    reaction(
      () => this.cycleTypeIpt.value,
      (data) => this.changeCreateOptions(data)
    )
  }
    //将默认的时间从 00:00:00 改为 23:59:59
    changeDefaultTime() {
      const time = 24 * 60 * 60 * 1000 - 1000
      return time
    }
  changeCreateOptions(id) {
    this.currentDemandCycleType = this.common.cycleTypes.find(item => item.id === id)
    this.startDateIpt.disabled = true
    // this.endDateIpt.disabled = true
    this.submissionDeadlineIpt.disabled = true
    if (this.currentDemandCycleType) {
      if(this.currentDemandCycleType.hvStartEndDate === 'Y') {
        this.startDateIpt.disabled = false
        // this.endDateIpt.disabled = false
      }
      if(this.currentDemandCycleType.hvDeadLine === 'Y') {
        this.submissionDeadlineIpt.disabled = false
      }else{
        this.submissionDeadlineIpt.value = null
      }
    } else {
      this.startDateIpt.value = null
      // this.endDateIpt.value = null
      this.submissionDeadlineIpt.value = null
    }

  }
  initQueryIpt() {
    this.cycleNameIpt = this.getQueryByTitle(this.common.TITLE.FORECAST_CYCLE_NAME)
    // this.poolTypeIpt = this.getQueryByTitle(this.common.TITLE.STOCK_POOL_TYPE)
    this.cycleTypeIpt = this.getQueryByTitle(this.common.TITLE.DEMAND_CYCLE_TYPE)
    this.startDateIpt = this.getQueryByTitle(this.common.TITLE.FORECAST_START_DATE)
    // this.endDateIpt = this.getQueryByTitle(this.common.TITLE.FORECAST_END_DATE)
    this.submissionDeadlineIpt = this.getQueryByTitle(this.common.TITLE.SUBMISSION_DEADLINE)
  }
  setQueryData(){
    let that = this;
    this.queryData = {
      ipts: [
        {
          title: this.common.TITLE.FORECAST_CYCLE_NAME,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-3 p-lg-3",
          value: null,
          error: false,
        }, 
        // {
        //   title: this.common.TITLE.STOCK_POOL_TYPE,
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: [],
        //   optionLabel: 'name',
        //   optionValue: 'id',
        // }, 
        {
          title: this.common.TITLE.DEMAND_CYCLE_TYPE,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-3 p-lg-3",
          value: null,
          options: [],
          optionLabel: 'typeName',
          optionValue: 'id',
        }, {
          title: this.common.TITLE.FORECAST_CYCLE_ID,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-3 p-lg-3",
          disabled: true,
          value: null,
          error: false,
        }, {
          title: this.common.TITLE.FORECAST_START_DATE,
          type: INPUT_TYPE.DATETIME,
          class: "p-col-12 p-md-3 p-lg-3",
          disabled: true,
          value: null,
        }, {
        //   title: this.common.TITLE.FORECAST_END_DATE,
        //   type: INPUT_TYPE.DATETIME,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   disabled: true,
        //   value: null,
        // }, {
          title: this.common.TITLE.SUBMISSION_DEADLINE,
          type: INPUT_TYPE.DATETIME,
          class: "p-col-12 p-md-3 p-lg-3",
          disabled: true,
          showTime: true,
          lastSecond: false,
          hourFormat:"24",
          stepMinute: 30,
          defaultDate: new Date(this.currentYear,this.currentMonth,this.currentDate,0,0,0,0),
          hideSeconds:true,
          value: null
        },
      ],
      btns: [
        {
          title: 'Back',
          class: "p-button-outlined p-mr-1",
          show: true,
          handler: { click: () => this.back() }
        }, {
          title: 'Clear',
          class: "p-button-outlined p-mr-1",
          show: true,
          handler: { click: () => this.clear() }
        }, {
          title: 'Save',
          class: "p-mr-1",
          permissionType: 'c',
          show: true,
          handler: { click: () => this.readyToSave() }
        },
      ],
      btnsclass: 'p-col-12 p-text-right',
      change: function(...e){
        if(e[2]&&e[2].title == that.common.TITLE.DEMAND_CYCLE_TYPE) that.demandTypeChange(e[2])
      }
    }
  }
  initQueryData() {
    // this.setPoolType()
    this.setCycleType()
    this.checkForecastCycleName()
    this.checkSubmissionDeadline()
    this.checkForecastStartDate()
    // this.checkForecastEndDate()
    // this.checkStockPoolType()
  }
  // setPoolType() {
  //   this.poolTypeIpt.options = LocalStorageHelper.getObject('POOL_TYPE')
  // } 
  setCycleType() {
    if(this.common.cycleTypes.length === 0) {
      this.common.cycleTypes = LocalStorageHelper.getObject('DEMAND_CYCLE_TYPE')
    }
    this.cycleTypeIpt.options = this.common.cycleTypes
  }
  // checkStockPoolType() {
  //   reaction (
  //     () => this.stockPoolType,
  //     (data) => { this.poolTypeIpt.error = false }
  //   );
  // }
  checkForecastStartDate() {
    reaction (
      () => this.forecastStartDate,
      (data) => { this.startDateIpt.error = false }
    );
  }
  // checkForecastEndDate() {
  //   reaction (
  //     () => this.forecastEndDate,
  //     (data) => { this.endDateIpt.error = false }
  //   );
  // }
  checkForecastCycleName() {
    reaction (
      () => this.forecastCycleName,
      (data) => { this.cycleNameIpt.error = false }
    );
  }
  checkSubmissionDeadline() {
    reaction (
      () => this.submissionDeadline,
      (data) => { this.submissionDeadlineIpt.error = false }
    );
  }

  clear() {
    this.cycleNameIpt.error = false
    // this.poolTypeIpt.error = false
    this.startDateIpt.error = false
    // this.endDateIpt.error = false
    this.cycleTypeIpt.error = false

    this.cycleTypeIpt.value = null
    this.cycleNameIpt.value = null
    // this.poolTypeIpt.value = null
    this.startDateIpt.value = null
    // this.endDateIpt.value = null
    this.submissionDeadlineIpt.value = null
  }

  validationData() {
    let flag = true
    if(!this.forecastCycleName) {
      this.cycleNameIpt.error = true
      this.common.showMessage('warn', this.common.info.title,
        this.common.info.required.replace('name', this.common.TITLE.FORECAST_CYCLE_NAME))
      flag = false
    }
    // if(!this.stockPoolType) {
    //   this.poolTypeIpt.error = true
    //   this.common.showMessage('warn', this.common.info.title,
    //     this.common.info.required.replace('name', this.common.TITLE.STOCK_POOL_TYPE))
    //   flag = false
    // }
    if(!this.stockCycleType) {
      this.cycleTypeIpt.error = true
      this.common.showMessage('warn', this.common.info.title,
        this.common.info.required.replace('name', this.common.TITLE.DEMAND_CYCLE_TYPE))
      flag = false
    }
    if(this.currentDemandCycleType) {
      if(!this.forecastStartDate) {
        this.startDateIpt.error = true
        this.common.showMessage('warn', this.common.info.title,
          this.common.info.required.replace('name', this.common.TITLE.FORECAST_START_DATE))
        flag = false
      }
      // if(!this.forecastEndDate) {
      //   this.endDateIpt.error = true
      //   this.common.showMessage('warn', this.common.info.title,
      //     this.common.info.required.replace('name', this.common.TITLE.FORECAST_END_DATE))
      //   flag = false
      // }

      if(this.currentDemandCycleType.hvDeadLine === 'Y') {
        if(!this.submissionDeadline) {
          this.submissionDeadlineIpt.error = true
          this.common.showMessage('warn', this.common.info.title,
            this.common.info.required.replace('name', this.common.TITLE.SUBMISSION_DEADLINE))
          flag = false
        }

        const gtNum = this.common.comparisonDate(this.submissionDeadline, this.forecastStartDate)
        if(gtNum >= 0) {
          flag = false
          this.startDateIpt.error = true
          this.common.showMessage('error', this.common.info.title,
            this.common.info.lt.replace('date1', this.common.TITLE.SUBMISSION_DEADLINE).replace('date2', this.common.TITLE.FORECAST_START_DATE))
        }
      }

      // const ltNum = this.common.comparisonDate(this.forecastEndDate, this.forecastStartDate)
      // if(ltNum < 0) {
      //   flag = false
      //   this.endDateIpt.error = true
      //   this.common.showMessage('error', this.common.info.title,
      //     this.common.info.gt.replace('date1', this.common.TITLE.FORECAST_END_DATE).replace('date2', this.common.TITLE.FORECAST_START_DATE))
      // }
    }
    return flag
  }
  readyToSave() {
    if(!this.validationData()) {
      return
    }

    this.loading = true
    let data = {
      accountName: this.common.account,
      demandCycleName: this.forecastCycleName,
    }
    this.service.exist(data).subscribe(res => {
      if (res.status === 200) {
        this.save()
      } else {
        this.loading = false
        this.common.showMessage('error', this.common.info.title, res.error.message)
      }
    })
  }

  save() {
    let data = {
      accountName: this.common.account,
      demandCycleName: this.forecastCycleName,
      demandCycleTypeId: this.stockCycleType,
      // stockPoolTypeId: this.stockPoolType,
      ...(this.submissionDeadline && {submissionDeadline: this.submissionDeadline.getTime()}),
      ...(this.forecastStartDate && {demandStartDate: this.forecastStartDate.getTime()}),
      // ...(this.forecastEndDate && {demandEndDate: this.forecastEndDate.setHours(23, 59, 59)}),
    }
    this.service.create(data).subscribe(res => {
      this.loading = false
      if (res.code === '000') {
        this.common.showMessage('success', this.common.info.title, this.common.info.success)
        this.redirect()
      } else {
        this.common.showMessage('error', this.common.info.title, res.msg || res.message || res.error.message || this.common.info.unknown)
      }
    })
  }
  redirect() {
    setTimeout(() => {
      this.router.navigate(["main/demand_cycle/search"])
    }, 200);
  }
  stopEvent(event){
    event.stopPropagation()
  }
  back() {
    window.history.back()
  }

  demandTypeChange(ipt){
    this.submissionDeadlineIpt.disabled = ipt.value == this.common.DEMANDCYCLETYPE.HKTHomeChannel
    if(ipt.value == this.common.DEMANDCYCLETYPE.HKTHomeChannel) this.submissionDeadlineIpt.value = CommonMethod.getDateFromToday(-1)
  }
}
