import { makeObservable, observable, computed, action } from 'mobx';
import { ElementRef, EventEmitter, Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { ReplenishmentStore } from './ReplenishmentStore';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SpreadsheetComponent } from 'src/app/components/spreadsheet-table/spreadsheet-table.component';
import { DemandStore } from '../demand/DemandStore';
import { DatePipe } from '@angular/common';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PreAllocateCommonComponent } from '@/pages/stock/replenishment/components/pre-allocate-common/pre-allocate-common.component';
import { StockCommonService } from '@/service/stock/stock-common.service';
import { FilterCommonMethod } from '@/util/FilterCommonMethod';
import { CommonService } from '@/service/common/common-service';
import { CommonMethod } from '@/util/CommonMethod';
import { VirtualScroller } from 'primeng/virtualscroller';
@Injectable()
export class CreateSpreadsheetStore extends ReplenishmentStore {
  breadcrumb = this.info.title
  subBreadcrumb = 'Create'
  toggleable = true
  preAllocateDate = new Date()
  clonePreAllocateDate = null
  overlayName = null
  skusSelect
  skusCount
  reposSelect
  reposCount
  selectedOption=""
  ptable={}
  onClick: EventEmitter<any> = new EventEmitter();
  parentData = {
    ipts: [
      {
        title: this.TITLE.DEMAND_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      },
      /*  {
        title: this.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-2",
        disabled: true,
        value: null,
      },  */
      {
        title: this.TITLE.DEMAND_CYCLE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.TITLE.DEMAND_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.TITLE.DEMAND_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData:any = {
    ipts: [
      {
        title: this.QUERY_TITLE.CHANNEL,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'id',
        error: false,
      }, {
        title: this.QUERY_TITLE.REFERENCE_NUMBER,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        maxLength: CONFIG.REPLENISH_REFNO_MAX_LENGTH
      }, {
        title: this.QUERY_TITLE.STATUS,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'value',
      }, {
        title: this.QUERY_TITLE.NEED_BY_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        minDate: null
      }, {
        title: this.QUERY_TITLE.REMARKS,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-4 p-lg-9",
        rows: 3,
        maxLength: 70,
        value: null,
      },
    ],
    extended: {
      show: false,
      title: this.QUERY_TITLE.FIELDSET, // BYOD
      type: INPUT_TYPE.FIELDSET,
      class: "p-col-12 p-md-4 p-lg-9",
      ipts:[
        {
          title: this.QUERY_TITLE.ROADSHOW_CHANNEL,
          type: INPUT_TYPE.TREESELECT,
          class: "p-col-12 p-md-6 p-lg-6",
          disabled: true,
          value: null,
          showValue: null,
          options: [],
          optionLabel: 'label',
          optionValue: 'id'
        }, {
          title: this.QUERY_TITLE.SCHOOL_NUMBER,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          value: null,
        }, {
          title: this.QUERY_TITLE.CONTACT_PERSON,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          maxLength: 39,
          value: null,
          required: true,
        }, {
          title: this.QUERY_TITLE.CONTACT_PHONE,
          type: INPUT_TYPE.INPUTLIMIT,
          inputType: 'tel',
          blockRegExp: /[^\d]/,
          class: "p-col-12 p-md-6 p-lg-6",
          maxLength: 10,
          value: null,
          required: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_1,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          maxLength: 50,
          value: null,
          required: true,
          // disabled: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_2,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          maxLength: 50,
          value: null,
          required: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_3,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12",
          options: [],
          optionLabel: 'label',
          optionValue: 'code',
          value: null,
          required: true,
        }
      ]
    },
    btns: [
      {
        title: this.BUTTON_TITLE.CANCEL,
        class: "p-ml-auto p-button-outlined p-order-2 p-mr-1 p-as-end",
        show: true,
        handler: { click: () => this.clear() }
      }, {
        title: this.BUTTON_TITLE.SAVE,
        class: "p-order-3 p-mr-1 p-as-end",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.save() }
      }
    ],
    btnsclass: 'p-d-flex p-lg-3 p-md-4 p-sm-12 vertical-container',
    change: (i,event,ipt)=>{
      // console.log({i}, {event}, {ipt});
      if(event?.target && ipt.title == this.QUERY_TITLE.REFERENCE_NUMBER){
        this.exportXlsxName = `Replenish_worksheet_${event.target.value}`
      }
      // if demand cycle type = 'RoadShow', When the courier is selected, change the value of address 1
      if(this.demandData.hasCourierData &&  ipt.title == this.QUERY_TITLE.ROADSHOW_CHANNEL ) {
        this.impactOfChangingRoadShow(ipt.value)
      }
    }
  }
  checkBox ={
    title: this.QUERY_TITLE.ONLY_INCLUDE,
    name: "only",
    type: INPUT_TYPE.CHECKBOX,
    // class: "p-col-12 p-md-4 p-lg-3",
    group: 'only',
    value: null,
  }
  isAodoIpt = {
    title: this.QUERY_TITLE.IS_AODO,
    name: "isAodo",
    type: INPUT_TYPE.CHECKBOX,
    // class: "p-col-12 p-md-4 p-lg-3",
    group: 'isAodo',
    value: null,
  }
  isSerializedIpt = {
    title: this.QUERY_TITLE.IS_SERIALIZED,
    name: "isSerialized",
    type: INPUT_TYPE.CHECKBOX,
    // class: "p-col-12 p-md-4 p-lg-3",
    group: 'isSerialized',
    value: null,
  }
  buCodeForAllChannelIpt = {
    title: this.QUERY_TITLE.BUCODE,
    name: 'bucodeallchannel',
    type: INPUT_TYPE.SELECT,
    options:[],
    optionValue: 'value',
    value: null,
  }
  bucodeIpt = {
    title: this.QUERY_TITLE.BUCODE,
    name: 'bucode',
    type: INPUT_TYPE.MUTIPLESELECT,
    options:[],
    // class: "p-col-12 p-md-4 p-lg-3",
    value: null,
    // optionLabel: 'label',
    // optionValue: 'value',
  }
  lobcodeIpt={
    title: this.QUERY_TITLE.LOBCODE,
    name: 'lobcode',
    type: INPUT_TYPE.MUTIPLESELECT,
    options: [],
    value: null,
  }
  maincatIpt={
    title: this.QUERY_TITLE.MAINCAT,
    name: 'maincat',
    type: INPUT_TYPE.MUTIPLESELECT,
    value: null,
    options: [],
  }
  subcatIpt={
    title: this.QUERY_TITLE.SUBCAT,
    name: 'subcat',
    type: INPUT_TYPE.MUTIPLESELECT,
    value: null,
    options: [],
  }
  brandIpt = {
    title: this.QUERY_TITLE.BRAND,
    name: 'brand',
    type: INPUT_TYPE.MUTIPLESELECT,
    options:[],
    // class: "p-col-12 p-md-4 p-lg-3",
    value: null,
    // optionLabel: 'label',
    // optionValue: 'value',
  }
  subsubcatIpt = {
    title: this.QUERY_TITLE.SUBSUBCAT,
    name: 'subsubcat',
    type: INPUT_TYPE.MUTIPLESELECT,
    options:[],
    // class: "p-col-12 p-md-4 p-lg-3",
    value: null,
    // optionLabel: 'label',
    // optionValue: 'value',
  }
  tableData = {
    btns: [],
    data: [],
    basicData: [], // 用于对比qty Planned原值，修改后的值必须大于等于原值，并且小于w003的值
    columns: [
      {field: 'itemBuCode', title: this.TABLE_FIELD.BU, width: '70px'},
      {field: 'itemLobCode', title: this.TABLE_FIELD.LOB, width: '70px'},
      {field: 'channelNature', title: this.TABLE_FIELD.CHANNEL_NATURE, width: '150px'},
      {field: 'channel', title: this.TABLE_FIELD.CHANNEL, width: '130px', type: 'repo'},
      {field: 'icCode', title: this.TABLE_FIELD.IC_CODE, width: '170px'},
      {field: 'itemNumber', title: this.TABLE_FIELD.ITEM_NUMBER, width: '160px', type: 'sku'},
      {field: 'desc', title: this.TABLE_FIELD.DESCRIPTION, width: '130px'},
      {field: 'model', title: this.TABLE_FIELD.MODEL, width: '90px'},
      {field: 'uom', title: this.TABLE_FIELD.UOM, width: '80px'},
      {field: 'p30Sales', title: this.TABLE_FIELD.P30, width: '70px'},
      {field: 'p21Sales', title: this.TABLE_FIELD.P21, width: '70px'},
      {field: 'p14Sales', title: this.TABLE_FIELD.P14, width: '70px'},
      {field: 'p07Sales', title: this.TABLE_FIELD.P07, width: '70px'},
      {field: 'qtyDoc', title: this.TABLE_FIELD.DOC, width: '70px'},
      {field: 'qtyAvailable', title: this.TABLE_FIELD.AVAILABLE, width: '120px'},
      {field: 'qtyReserved', title: this.TABLE_FIELD.RESERVED, width: '120px'},
      {field: 'qtyAo', title: this.TABLE_FIELD.AO, width: '70px'},
      {field: 'qtyTransit', title: this.TABLE_FIELD.TRANSIT_IN, width: '120px'},
      {field: 'qtyDo', title: this.TABLE_FIELD.DO, width: '70px'},
      {field: 'qtyTotalAvailable', title: this.TABLE_FIELD.TOTAL_AVAILABLE, width: '150px'},
      {field: 'qtyOnhand', title: this.TABLE_FIELD.TOTAL_ON_HAND, width: '150px'},
      {field: 'qtyRequest', title: this.TABLE_FIELD.CHANNEL_REQUEST, width: '150px'},
      {field: 'qtyPreAllocate', title: this.TABLE_FIELD.PRE_ALLOCATE_QTY, width: '140px'},
      {field: 'qtyUserInput', title: this.TABLE_FIELD.USERINPUT_QTY, width: '140px', type: 'number'},
      {field: 'qtyAllocate', title: this.TABLE_FIELD.ALLOCATE_QTY, width: '140px'},
      {field: 'qtyW003', title: this.TABLE_FIELD.W003, width: '80px'},
      {field: 'qtyW003Demand', title: this.TABLE_FIELD.W003_DEMANDED, width: '160px'},
      {field: 'qtyW005', title: this.TABLE_FIELD.W005, width: '80px'},
      {field: 'qtyW006', title: this.TABLE_FIELD.W006, width: '80px'},
      {field: 'qtyW007', title: this.TABLE_FIELD.W007, width: '80px'},
      {field: 'qtyW008', title: this.TABLE_FIELD.W008, width: '80px'},
      {field: 'qtyW009', title: this.TABLE_FIELD.W009, width: '80px'},
      {field: 'qtyW010', title: this.TABLE_FIELD.W010, width: '80px'},
      {field: 'qtyW011', title: this.TABLE_FIELD.W011, width: '80px'},
      {field: 'qtyW012', title: this.TABLE_FIELD.W012, width: '80px'},
      {field: 'qtyW013', title: this.TABLE_FIELD.W013, width: '80px'},
      {field: 'qtyPsw', title: this.TABLE_FIELD.PSW, width: '80px'},
      // {field: 'interfaceStatus', title: this.TABLE_FIELD.INTERFACED_STATUS, width: '160px'},
      // {field: 'interfaceDate', title: this.TABLE_FIELD.INTERFACED_DATE, width: '150px', type: 'date'},
      {field: 'updateBy', title: this.TABLE_FIELD.UPDATED_BY, width: '130px', type: 'name' },
      {field: 'updateDate', title: this.TABLE_FIELD.UPDATED_DATETIME, width: '160px', type: 'datetime'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {field: '', title: 'Action', width: '100px', type: 'action'},
    ],
    frozenWidth: '100px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "updateDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'updateDate',
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  }
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  scrollable:boolean = true
  loading:boolean = false
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  cycleTypeIpt = null
  startDateIpt = null
  // endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  referenceNumberIpt = null
  statusIpt = null
  buCodeIpt = null
  lobCodeIpt=null
  mainCatIpt=null
  subCatIpt=null
  needByDateIpt = null
  remarksIpt = null
  onlyIncludeIpt = null
  // schoolCodeIpt = null
  // schoolLocationCodeIpt = null
  extendChannelIpt = null
  courierIpt = null
  schoolNumberIpt = null
  contactPersonIpt = null
  contactPhoneIpt = null
  address1Ipt = null
  address2Ipt = null
  address3Ipt = null
  channels = []
  demandLineList = []
  idx = 0
  buCodes = []
  lobCodes=[]
  mainCats=[]
  subCats=[]
  selectBUCodes = []
  subSubCat = []
  selectedSubSubCat = []
  mfgBrand = []
  selectedMfgBrand = []
  isAODO = false;

  repos = []
  allRepos = []
  skus = []
  allSkus=[]
  replenishChannel; // for ic code display

  reservationType = []
  currentLine:any = {}
  currentDay:Date
  cloneRepoData
  cloneSkuData
  hasCreate = false
  modalConfig: any = {
    title: 'Reservation',
    visible: false,
    warehouseTitle: 'W003',
    schoolTitle: 'School Number',
    rangeDate: '01/01/2022 - 01/05/2022',
    totalCount: '10(200)',
    selTotalCount: 0,
    changeCount: null,
    schoolNumber: null,
    items: [],
    selectedItem: '',
    sourceRefNoFilter: '',
    filteredIdList: [],
    table: {
      columns: [
      ],
      frozenColumns:[
        {field: 'action', title: '', type: 'action', width: '50px'},
      ],
      frozenWidth: '50px',
      rawData: [],
      data: [],
      selected: [],
    },
    nopItem: {
      columns: [
        {field: 'itemCode', title: 'Item', width: '90px'},
        {field: 'totalCount', title: this.info.popupTotalCountPatten, width: '90px'},
        {field: 'preAllocateQty', title: 'Pre-Allocate Qty', width: '70px', type: 'number'},
        {field: 'pickupFromDate', title: 'Pickup From Date', width: '70px'},
        {field: 'pickupToDate', title: 'Pickup To Date', width: '70px', type: 'date'},
        {field: 'planAllocateQty', title: 'Planned Allocte Qty', width: '70px'},
      ],
      data: []
    }
  }
  demandLine:any;
  exportXlsxName='Replenish_worksheet'

  replenishId = null;
  replenish = null;
  replenishCreateData = null;
  preAllocateReplenishmentCreated = false;
  preAllocateCreated = false;
  replenishSaved = false;

  preAllocateAllItemCode = [];
  eleRef: ElementRef<HTMLElement>;

  channelMultiTreeOptions = [];
  addItemByBuChannelIpt
  getOverlayVirtualScrollerFn=():VirtualScroller=>null;

  get isChannelFixed(){return this.demandData.hasSchoolData || this.demandData.hasCourierData}

  getDemandLine(){
    // return this.demandLine
    return JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
  }
  setDemandLine(e){
    sessionStorage.setItem('DEMAND_LINE', JSON.stringify(e))
    this.demandLine=e
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    service: StockDemandCycleService,
    msg: MessageService,
    conf: ConfirmationService,
    private datePipe: DatePipe,
    private demandStore: DemandStore,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
  ) {
    super(router, route, service, msg, conf, commonService)
    makeObservable(this, {
      breadcrumb: observable,
      parentData: observable,
      queryData: observable,
      tableData: observable,
      // skuCode: observable,
      cycleNameIpt: observable,
      poolTypeIpt: observable,
      cycleTypeIpt: observable,
      startDateIpt: observable,
      // endDateIpt: observable,
      submissionDeadlineIpt: observable,
      channelIpt: observable,
      referenceNumberIpt: observable,
      statusIpt: observable,
      buCodeIpt: observable,
      lobCodeIpt: observable,
      mainCatIpt: observable,
      subCatIpt: observable,
      remarksIpt: observable,
      onlyIncludeIpt: observable,
      needByDateIpt: observable,
      // schoolCodeIpt: observable,
      // schoolLocationCodeIpt: observable,
      extendChannelIpt: observable,
      courierIpt: observable,
      schoolNumberIpt: observable,
      contactPersonIpt: observable,
      contactPhoneIpt: observable,
      address1Ipt: observable,
      address2Ipt: observable,
      address3Ipt: observable,
      channelId: computed,
      referenceNumber: computed,
      status: computed,
      // buCode: computed,
      needByDate: computed,
      remarks: computed,
      // onlyInclude: computed,
      showU: computed,
      initOriginIpt: action,
      initQueryIpt: action,
      initQueryData: action,
      addDataToTable: action
    })
    this.tableData.selectedColumns = [...this.tableData.columns]
    this.tableData.basicData = []
    this.setPreAllocateDate()
    this.getResolverData()
    this.getPermission(route)
    // this.getBUCode()
    this.getItemMasterColumn().subscribe(res=>{
      this.buCodeForAllChannelIpt.options = this.buCodes
      this.brandIpt.options = this.mfgBrand
      this.bucodeIpt.options = this.buCodes
      this.lobcodeIpt.options = this.lobCodes
      this.maincatIpt.options = this.mainCats
      this.subcatIpt.options = this.subCats
      this.subsubcatIpt.options = this.subSubCat
    })
    this.getChannels()
    this.initChannelMultiTreeSelect()
    this.getItems()
    this.getReservationType()
    this.initOriginIpt()
    this.initQueryIpt()
    this.setExtendDataAccordingToType()

    this.loadDataByParams().subscribe(
      (res)=>{
        if(res.action){
          this.initQueryIpt()
          this.setExtendDataAccordingToType()
          this.initQueryData(res);
          this.initSpreadsheet()
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
    // this.initQueryData()

    this.initSpreadsheet()
  }

  get channelId() {
    return this.channelIpt.value
  }
  get referenceNumber() {
    return this.referenceNumberIpt.value
  }
  get status() {
    return this.statusIpt.value
  }
  get buCode() {
    return this.buCodeIpt.value
  }
  get lobCode(){
    return this.lobCodeIpt.value
  }

  get mainCat(){
    return this.mainCatIpt.value
  }

  get subCat(){
    return this.subCatIpt.value
  }

  get needByDate() {
    return this.needByDateIpt.value
  }
  get remarks() {
    return this.remarksIpt.value
  }
  get onlyInclude() {
    return this.onlyIncludeIpt.value
  }
  get showU() {
    return this.permission?.includes('Create Replenishment')
  }
  get editChannel() {
    return this.demandData.hasSchoolData || this.demandData.hasCourierData
  }
  get isNop() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.NOP
  }
  get isBYOD() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.BYOD
  }
  get isRoadshow() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.MOB_RoadShow
  }


  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title !== 'Cancel' && (item.disabled = !this.permission.includes("Create Replenishment"))
    })
  }
  getOriginByTitle(title) {
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title) {
    return this.queryData.ipts.find(item => item.title === title)
  }
  getSchoolByTitle(title) {
    return this.queryData.extended.ipts.find(item => item.title === title)
  }
  getCourierOptions() {
    this.courierIpt  = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_COURIER)
    const couriers = LocalStorageHelper.getObject('REPLENISH_COURIER')
    this.courierIpt.options =  couriers
  }
  // If hv_school_data="Y", then default display "ELG" and set as "READ-ONLY" (not allow to edit)
  getChannelOptions(hasDefaultValue=false) {
    this.extendChannelIpt  = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_CHANNEL)
    const shop =  JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo => repo.children.length)
    this.extendChannelIpt .options = shop
    if(hasDefaultValue) {
      try{
        let channelList:string[] = CONFIG.REPLENISH_BYOD_CHANNEL_LIST
        // let targetChannelCode = channelList[0] || 'ELG';
        let elxChannelList = shop.map(channel=>channel.children).flat().filter(channel=>channelList.includes(channel.label))
        let elgChannel
        let elgChannelConfigOrder
        elxChannelList.forEach(element => {
          let configIdx = channelList.indexOf(element.label)
          if(elgChannelConfigOrder == null || elgChannelConfigOrder > configIdx) {
            elgChannel = element
            elgChannelConfigOrder = configIdx
          }
        });
        this.extendChannelIpt .showValue = elgChannel.label
        this.extendChannelIpt .value = elgChannel
        this.extendChannelIpt.disabled = false
        this.extendChannelIpt.options = elxChannelList
        this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_1)
        this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_2)
        this.impactOfChangingRoadShow(elgChannel)
      }catch(e){console.error(e)}
    }
  }

  // hardcode split address to match lis max length
  impactOfChangingRoadShow(info) {
    this.address1Ipt.value = info?.adress
    try{
      let fullAddress: string = info?.adress
      if(fullAddress){
        let address1 = ''
        let address2 = ''
        address1 = fullAddress
        if(fullAddress.length>50){
          address1 = fullAddress.slice(0, 50)
          address2 = fullAddress.slice(50, fullAddress.length)
          if(address2.length>50){
            address2 = fullAddress.slice(50, 100)
          }
        }
          this.address1Ipt.value = address1
          this.address2Ipt.value = address2
      }
    }catch(e){console.error(e)}
  }
  setExtendDataAccordingToType() {
    this.demandData = JSON.parse(sessionStorage.getItem("DEMAND"))
    if(this.demandData?.hasCourierData) {
      this.queryData.extended = {
        show: false,
        title: this.QUERY_TITLE.ROADSHOW_FIELDSET, // roadshow
        type: INPUT_TYPE.FIELDSET,
        class: "p-col-12 p-md-4 p-lg-9",
        ipts: [
          {
            title: this.QUERY_TITLE.ROADSHOW_CHANNEL,
            type: INPUT_TYPE.TREESELECT,
            class: "p-col-12 p-md-6 p-lg-6",
            value: null,
            showValue: null,
            options: [],
            optionLabel: 'label',
            optionValue: 'id'
          }, {
            title: this.QUERY_TITLE.ROADSHOW_COURIER,
            type: INPUT_TYPE.SELECT,
            class: "p-col-12 p-md-6 p-lg-6",
            value: null,
            options: [],
            optionLabel: 'code',
            optionValue: 'id'
          }, {
            title: this.QUERY_TITLE.CONTACT_PERSON,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12 p-md-6 p-lg-6",
            maxLength: 39,
            value: null,
          }, {
            title: this.QUERY_TITLE.CONTACT_PHONE,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12 p-md-6 p-lg-6",
            maxLength: 10,
            value: null,
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_1,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12",
            maxLength: 50,
            value: null,
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_2,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12",
            maxLength: 50,
            value: null,
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_3,
            type: INPUT_TYPE.SELECT,
            class: "p-col-12",
            options: [],
            optionLabel: 'label',
            optionValue: 'code',
            value: null,
          }
        ]
      }
      this.courierIpt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_COURIER)
      this.contactPersonIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PERSON)
      this.contactPhoneIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PHONE)
      this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_1)
      // this.address1Ipt.disabled = true
      this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_2)
      this.address3Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_3)
      this.address3Ipt.options = this.address
      this.getChannelOptions()
      this.getCourierOptions()
    }
  }
  setPreAllocateDate() {
    let today = new Date()
    // let prevDate = currDate.setDate(currDate.getDate() - 1)
    let tmr = today.getTime() + 1000*60*60*24
    if(
      this.demandData?.demandCycleTypeId &&
      (
        this.demandData.demandCycleTypeId === this.DEMANDTYPEID.CHANNEL ||
        this.demandData.demandCycleTypeId === this.DEMANDTYPEID.MOB_RoadShow
      )
    ){
      this.preAllocateDate = new Date(tmr)
    }else{
      this.preAllocateDate = new Date(today)
    }
  }
  setClonePreAllocateDate(dateStr) {
    this.clonePreAllocateDate = dateStr
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_NAME)
    this.cycleTypeIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_START_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.QUERY_TITLE.CHANNEL)
    this.referenceNumberIpt = this.getQueryByTitle(this.QUERY_TITLE.REFERENCE_NUMBER)
    this.statusIpt = this.getQueryByTitle(this.QUERY_TITLE.STATUS)
    this.needByDateIpt = this.getQueryByTitle(this.QUERY_TITLE.NEED_BY_DATE)
    this.currentDay = new Date()
    this.needByDateIpt.minDate = this.currentDay
    this.needByDateIpt.value = this.currentDay
    this.remarksIpt = this.getQueryByTitle(this.QUERY_TITLE.REMARKS)
    this.initSchoolIpt()
    this.setQueryIptDisabled()
  }

  setQueryIptDisabled(){
    // only dim ref no input when is nop and in edit page
    // this.referenceNumberIpt.disabled = this.isNop
  }

  initSchoolIpt() {
    this.extendChannelIpt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_CHANNEL)
    this.getChannelOptions(true)
    this.schoolNumberIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_NUMBER)
    this.contactPersonIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PERSON)
    this.contactPhoneIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PHONE)
    this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_1)
    this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_2)
    this.address3Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_3)
    this.address3Ipt.options = this.address

  }
  initQueryData(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))){
    this.demandData = demandData
    this.queryData.extended.show = this.editChannel
    const list = LocalStorageHelper.getObject('POOL_TYPE')

    this.cycleNameIpt.value = this.demandData.demandCycleName
    this.cycleTypeIpt.value = this.demandData.demandCycleType
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    this.submissionDeadlineIpt.value = this.demandData.submissionDeadline ? new Date(this.demandData.submissionDeadline) : null
    this.setPreAllocatePopupTable()
    this.setInitSearchData()
    this.setPreAllocateDate()
  }
  setInitSearchData() {
    this.channels = LocalStorageHelper.getObject('WAREHOUSE')
    this.channelIpt.options = this.channels
    this.channelIpt.value = 1 // w003 id

    this.replenishChannel = this.channels.find(warehouse=>warehouse.id==this.channelIpt.value)?.label

    const status = LocalStorageHelper.getObject('REPLENISHMENT_STATUS')
    this.statusIpt.options = status
    this.statusIpt.value = status.find(item => item.label === 'Draft').value;

    this.skus = LocalStorageHelper.getObject('SKU')
  }
  getParams(spreadsheetdata, replenishmentLine=null) {
    return {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      fromChannelId: this.channelId,
      referenceNumber: this.referenceNumber,
      sdlReplenishStatusId: this.status,
      ...(replenishmentLine && {id: replenishmentLine.id}),
      ...(this.schoolNumberIpt?.value && {schNum: this.schoolNumberIpt.value}),
      ...(this.address1Ipt.value && {address1: this.address1Ipt.value}),
      ...(this.address2Ipt.value && {address2: this.address2Ipt.value}),
      ...(this.address3Ipt.value && {address3: this.address3Ipt.value}),
      ...(this.courierIpt?.value && {overrideCourierId: this.courierIpt.value}),
      ...(this.contactPersonIpt.value && {contactPersonName: this.contactPersonIpt.value}),
      ...(this.contactPhoneIpt.value && {contactPhoneNo: this.contactPhoneIpt.value}),
      ...(this.extendChannelIpt.value && {channelId: this.extendChannelIpt.value.id}),
      needByDate: this.needByDate.getTime(),
      ...(this.remarks && {remarks: this.remarks}),
      ...(spreadsheetdata.length && {lines: spreadsheetdata})
    }
  }
  update(fromPreAllocate = false) {
    const replenishmentLine = this.getDemandLine()
    let spreadsheetdata = this.spreadsheet.value
    const data = this.getParams(spreadsheetdata, replenishmentLine)
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentEdit(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        !fromPreAllocate && this.showMessage('success', this.info.title,  this.info.success)
        this.saveDataToStorage(res.data[0], fromPreAllocate)
      } else {
        if (res.error) {
          this.showMessage('error', this.info.title,  res.error.message)
        } else {
          this.showMessage('warn', this.info.title,  res.msg)
        }
        this.idx = 0
      }
    })
  }
  updateObs(fromPreAllocate = false, skipSaveDataToStorage = false) {
    const replenishmentLine = this.getDemandLine()
    let spreadsheetdata = this.spreadsheet.value
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      fromChannelId: this.channelId,
      referenceNumber: this.referenceNumber,
      sdlReplenishStatusId: this.status,
      id: replenishmentLine.id,
      schNum: this.schoolNumberIpt.value,
      contactPersonNo: this.contactPersonIpt.value,
      address1: this.address1Ipt.value,
      address2: this.address2Ipt.value,
      address3: this.address3Ipt.value,
      needByDate: this.needByDate.getTime(),
      remarks: this.remarks,
      ...(spreadsheetdata.length && {lines: spreadsheetdata})
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    return this.service.replenishmentEdit(data).pipe(map(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        !fromPreAllocate && this.showMessage('success', this.info.title,  this.info.success)
        if(!skipSaveDataToStorage)this.saveDataToStorage(res.data[0], fromPreAllocate)
      } else {
        if (res.error) {
          this.showMessage('error', this.info.title,  res.error.message)
        } else {
          this.showMessage('warn', this.info.title,  res.msg)
        }
        this.idx = 0
      }
      return res
    }))
  }
  changeItem() {
    this.setPreAllocateTableData()
  }
  change(val, disabled = false) {
    if(!this.isNop && disabled){
      return
    }
    let selectItem = null
    // 如果this.isNop为真, 则 selectItem 的内容是 source ref no, 否则为 item
    if(this.isNop) {
      selectItem = this.modalConfig.nopItem.data.find(item => item.itemCode === val.itemCode)
    }else{
      selectItem = this.modalConfig.table.rawData.find(item => item.itemCode === this.modalConfig.selectedItem)
    }
    let itemCode = this.modalConfig.selectedItem
    if(this.modalConfig.currentDataBuffer==null) this.modalConfig.currentDataBuffer = JSON.parse(JSON.stringify(selectItem))
    if(this.modalConfig.savedItemCodeList.indexOf(itemCode)>-1) {
      this.modalConfig.statusBuffer = 'saved';
    }else if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1) {
      this.modalConfig.statusBuffer = 'changed';
    }else{
      this.modalConfig.statusBuffer = 'normal';
    }

    if(selectItem.totalCount === 0) {
      return
    }
    if(this.modalConfig.changedItemCodeList.indexOf(this.modalConfig.selectedItem)==-1){
      this.modalConfig.changedItemCodeList.push(this.modalConfig.selectedItem)
    }
    if(this.modalConfig.savedItemCodeList.indexOf(this.modalConfig.selectedItem)>-1){
      this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(this.modalConfig.selectedItem), 1)
    }
    let resolt = this.modalConfig.table.selected.find((item) => item.id === val.id);
    // reset changeCountList to null, not pass to backend when save single item
    if(this.modalConfig.changeCountList[this.modalConfig.selectedItem]!=null)this.modalConfig.changeCountList[this.modalConfig.selectedItem]=null;
    // reset changeCount on change table checkbox
    this.modalConfig.changeCount = null;
    val.qtyPlanToAllocate = resolt ? val.qtyPending : 0
    if (this.isNop) {
      let changedNum = val.qtyPlanToAllocate || -val.qtyPending
      selectItem.planAllocateQty  +=  changedNum
    } else {
      this.changeTotal(selectItem)
    }
  }
  nopOnRowSelect(e){
    let rowData = e.data
    if(this.modalConfig.changedTableData==null) this.modalConfig.changedTableData = []
    this.modalConfig.changedTableData.push(rowData)
    if(this.modalConfig.selectedReservationId==null) this.modalConfig.selectedReservationId = []
    this.modalConfig.selectedReservationId.push(rowData.id)
  }
  nopOnRowUnSelect(e){
    let rowData = e.data
    if(this.modalConfig.changedTableData==null) this.modalConfig.changedTableData = []
    this.modalConfig.changedTableData.push(rowData)
    if(this.modalConfig.unSelectedReservationId==null) this.modalConfig.unSelectedReservationId = []
    this.modalConfig.unSelectedReservationId.push(rowData.id)
  }
  setModalVisible(state) {
    this.modalConfig.visible = state
    if(!state) {
      this.changeTick(true)
      this.resetModalConfigField()
    }
  }
  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy/MM/dd')
  }
  fetchPreAllocate(params) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let data = {
      replenishId: params.id,
      demandCycleType: this.demandData.demandCycleType,
      reservationEndDate: this.formatDate(this.preAllocateDate),
      ...(this.demandData.demandCycleType === 'BYOD' && {schoolNumber: this.schoolNumberIpt.value})
    }

    this.service.fetchPreAllocate(data).subscribe(res => {
      if(res.code === '000') {
        // if (res.data.length === 0 || (this.isNop && !res.data[0].reservationsList.length)) {
        //   this.showMessage('warn', this.info.title, this.info.noPreAllocate)
        // } else {

        // pre allocate created, need remove pre-allocate if cancel replenishment / cancel pre allocate
        this.preAllocateCreated = true;

        let _data = res.data
        this.modalConfig.changeCountList = []

        // for nop
        if(this.isNop) this.preAllocateAllItemCode = _data[0].itemsList.map(item=>item.itemCode)

        if(!this.isNop) _data = _data.map(data=>{
          if(data.preAllocateQty)this.modalConfig.changeCountList[data.itemCode]=data.preAllocateQty
          return {
            ...data,
            allocateCount: data.preAllocateQty,
            reservationEndDate: data.reservationEndDate,
          }
        })
        this.modalConfig.table.rawData = _data
        this.modalConfig.preAllocateDate = this.preAllocateDate
        if(this.modalConfig.visible && !this.isNop){
          let selectedItemPreAllocateData = res.data.find((item)=>item.itemCode==this.modalConfig.selectedItem)
          this.modalConfig.preAllocateDate = selectedItemPreAllocateData.reservationEndDate?new Date(selectedItemPreAllocateData.reservationEndDate):this.preAllocateDate;
        }
        this.setWareHouseTitle()
        this.setRangeDate(res.data)
        this.setPreAllocateItems()
        // }
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
      this.loading = false
      this.tableData.loadingSwitch = false
    })
  }
  fetchTargetItemPreAllocate(params) {
    this.fetchTargetItemPreAllocateObs(params, true).subscribe()
  }
  fetchTargetItemPreAllocateObs(params, allowEmpty = false, resetToDefaultDate = false) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let targetItemCode = this.modalConfig.selectedItem;
    let preAllocateDate = this.modalConfig.preAllocateDate
    let data = {
      replenishId: params.id,
      demandCycleType: this.demandData.demandCycleType,
      reservationEndDate: this.formatDate(preAllocateDate),
      itemCode: targetItemCode,
      ...(this.demandData.demandCycleType === 'BYOD' && {schoolNumber: this.schoolNumberIpt.value})
    }

    return this.service.fetchPreAllocate(data).pipe(map(res => {
      if(res.code === '000') {
        if (res.data.length === 0 && !allowEmpty) {
          this.showMessage('warn', this.info.title, this.info.noPreAllocate)
        } else {
          let index = this.modalConfig.table.rawData.findIndex(data=>data.itemCode == targetItemCode)
          let newDataIdx = res.data.findIndex(data=>data.itemCode == targetItemCode)
          if(newDataIdx>-1){
            this.modalConfig.table.rawData[index] = {...this.modalConfig.table.rawData[index], ...res.data[newDataIdx], reservationEndDate: res.data[newDataIdx].reservationEndDate}
          }else{
            // item not found in result, item reservation is empty, keep previous value but empty reservation list
            this.modalConfig.table.rawData[index] = {...this.modalConfig.table.rawData[index], reservationsList: []}
          }

          // this.modalConfig.table.rawData = res.data
          if(this.modalConfig.selectedItem == targetItemCode){
            // let selectedItemPreAllocateData = res.data.find((item)=>item.itemCode==this.modalConfig.selectedItem)
            // this.modalConfig.preAllocateDate = preAllocateDate;
            if(this.modalConfig.table.rawData[index].reservationEndDate==null){
              this.modalConfig.table.rawData[index].reservationEndDate = preAllocateDate
            }
          }
          this.setWareHouseTitle()
          this.setRangeDate(res.data)
          this.setPreAllocateItems()
          // this.setClonePreAllocateDate(data.reservationEndDate)
        }
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
      this.loading = false
      this.tableData.loadingSwitch = false
      return res
    }))
  }
  getLines(isUpdate=false, from=false, skipUpdate=false) {
    this.getLinesObs(isUpdate, from, skipUpdate).subscribe()
    // const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    // // let spreadsheetdata = this.spreadsheetComponent?this.spreadsheetComponent.getData():null;
    // const data = {
    //   accountName: this.account,
    //   pageIndex: this.tableData.pageIndex,
    //   pageSize: this.tableData.pageSize,
    //   sortEvent: this.tableData.sortField,
    //   replenishId: replenishmentLine.id,
    //   demandCycleId: replenishmentLine.demandCycleId
    // }
    // this.loading = true
    // this.tableData.loadingSwitch = true
    // this.service.replenishmentLineSearch(data).subscribe(res => {
    //   this.loading = false
    //   this.tableData.loadingSwitch = false
    //   if(res.code === '000') {
    //     this.tableData.basicData = JSON.parse(JSON.stringify(res.data))
    //     // 如果存在reservationEndDate，则记录这个日期
    //     res.data[0].reservationEndDate && this.setClonePreAllocateDate(res.data[0].reservationEndDate)
    //     res.data.forEach(item => {
    //       let valIdx
    //       let tableItem = this.spreadsheet.value.find((tItem, idx) => {
    //         let res = tItem.channel === item.channel && tItem.itemNumber === item.itemNumber
    //         if(res) valIdx = idx
    //         return res
    //       })
    //       let qtyUserInput = !isNaN(tableItem.qtyUserInput)?tableItem.qtyUserInput:0
    //       // at create page, qtyAllocate should be preAllocate + user input
    //       // user input only in frontend, use pre allocate qty + frontend input value to calculate qtyAllocate
    //       // ignore qtyAllocate change by isFirstPreAllocation logic in backend
    //       tableItem = {...item}
    //       tableItem.qtyAllocate = item.qtyPreAllocate+qtyUserInput
    //       tableItem.qtyPreAllocate = item.qtyPreAllocate
    //       tableItem.qtyPreRequest = item.qtyPreAllocate // display
    //       // tableItem.qtyUserInput = item.qtyAllocate==item.qtyPreAllocate?tableItem.qtyUserInput:item.qtyAllocate - item.qtyPreAllocate
    //       tableItem.qtyW003 = item.qtyW003
    //       tableItem.id = item.id
    //       this.spreadsheet.value[valIdx] = tableItem
    //     });
    //     this.spreadsheet.value = [...this.spreadsheet.value];
    //     !skipUpdate && !isUpdate && this.setModalVisible(false)
    //     !skipUpdate && isUpdate && this.update(from)

    //   }
    // })
  }

  getLinesObs(isUpdate=false, from=false, skipUpdate=false) {
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    // let spreadsheetdata = this.spreadsheetComponent?this.spreadsheetComponent.getData():null;
    const data = {
      accountName: this.account,
      pageIndex: 0, // this.tableData.pageIndex,
      pageSize: 2147483647, // this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: replenishmentLine.id,
      demandCycleId: replenishmentLine.demandCycleId
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    return this.service.replenishmentLineSearch(data).pipe(map(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        this.tableData.basicData = JSON.parse(JSON.stringify(res.data))
        // 如果存在reservationEndDate，则记录这个日期
        res.data[0]?.reservationEndDate && this.setClonePreAllocateDate(res.data[0].reservationEndDate)
        res.data.forEach(item => {
          let valIdx
          let tableItem = this.spreadsheet.value.find((tItem, idx) => {
            let res = tItem.channel === item.channel && tItem.itemNumber === item.itemNumber
            if(res) valIdx = idx
            return res
          })
          let qtyUserInput = !isNaN(tableItem?.qtyUserInput) ? tableItem.qtyUserInput : 0
          // at create page, qtyAllocate should be preAllocate + user input
          // user input only in frontend, use pre allocate qty + frontend input value to calculate qtyAllocate
          // ignore qtyAllocate change by isFirstPreAllocation logic in backend
          tableItem = {...item}
          tableItem.qtyAllocate = item.qtyPreAllocate+qtyUserInput
          tableItem.qtyPreAllocate = item.qtyPreAllocate
          tableItem.qtyPreRequest = item.qtyPreAllocate // display
          tableItem.qtyUserInput = qtyUserInput; // item.qtyAllocate==item.qtyPreAllocate?tableItem.qtyUserInput:item.qtyAllocate - item.qtyPreAllocate
          tableItem.qtyW003 = item.qtyW003
          tableItem.id = item.id
          this.spreadsheet.value[valIdx] = tableItem
        });
        this.spreadsheet.value = [...this.spreadsheet.value];
        !skipUpdate && !isUpdate && this.setModalVisible(false)
        !skipUpdate && isUpdate && this.update(from)

      }
    }))
  }
  // save changed pre allocate
  changePreAllocate(itemCode = [...this.modalConfig.changedItemCodeList], force = false, includeCurrentPageList = null, currentPageMode = null) {
    // save changed item
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    // if selected item manually changed, selected item remove from changedItemCodeList when saveItemPreAllocateObs success in saveAllPreAllocate
    // if selected item is from calculate all, currentDataBuffer, endDateChanged is not changed, will skip saveItemPreAllocateObs in saveAllPreAllocate
    let changedRawData = this.modalConfig.table.rawData.filter(data=>{return this.modalConfig.changedItemCodeList.indexOf(data.itemCode)>-1 })
    if(force) changedRawData = this.modalConfig.table.rawData;

    let list = this.getPreAllocateData(changedRawData)

    if(includeCurrentPageList && currentPageMode && currentPageMode == 'sourceTxnRefHeaderNo'){
      let currentPageList = this.modalConfig.table.data;
      currentPageList.forEach(currentDataRow => {
        let target = list.find(rawData=>rawData.id==currentDataRow.id)
        target.qtyPlanToAllocate = currentDataRow.qtyPlanToAllocate
      });
    }

    let data = {
      replenishId: replenishmentLine.id,
      reservationEndDate:  this.formatDate(this.preAllocateDate),
      list: list,
      itemCode: itemCode
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.savePreAllocate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        if(itemCode&&itemCode.length>0){
          this.getLines()
        }else{
          // no changedItemCodeList
          this.setModalVisible(false)
        }
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Change Pre Allocate'))
      }
    })
  }

  nopSaveAllPreAllocate(itemCode = [...this.modalConfig.changedItemCodeList], force = false, includeCurrentPageList = null, currentPageMode = null){
    const replenishmentLine = this.getDemandLine()
    let changedRawData = this.modalConfig.table.rawData.filter(data=>{return this.modalConfig.changedItemCodeList.indexOf(data.itemCode)>-1 })
    if(force) changedRawData = this.modalConfig.table.rawData;
    let list = this.getPreAllocateData(changedRawData)
    if(includeCurrentPageList && currentPageMode && currentPageMode == 'sourceTxnRefHeaderNo'){
      let currentPageList = this.modalConfig.table.data;
      currentPageList.forEach(currentDataRow => {
        let target = list.find(rawData=>rawData.id==currentDataRow.id)
        target.qtyPlanToAllocate = currentDataRow.qtyPlanToAllocate
      });
    }
    let data = {
      replenishId: replenishmentLine.id,
      reservationEndDate:  this.formatDate(this.preAllocateDate),
      list: list,
      itemCode: itemCode
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.deletePreAllocate({replenishId:this.replenishId}).pipe(switchMap(res=>{
      return this.service.savePreAllocate(data)
    }))
    // this.service.savePreAllocate(data).subscribe(res => {
    .subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        if(itemCode&&itemCode.length>0){
          this.getLines()
        }else{
          // no changedItemCodeList
          this.setModalVisible(false)
        }
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Change Pre Allocate'))
      }
    })
  }

  saveAllPreAllocate(){
    if(!this.isNop && (this.modalConfig.endDateChanged || this.modalConfig.currentDataBuffer)){
      // manually changed date / tick / calculate selected item
      // selected item have changed, save this item first
      this.saveItemPreAllocateObs().subscribe(res=>{
        if(res!=null){
          // save selected pre allocate success, save all other pre allocate calculated by calculate all button
          this.changePreAllocate()
        }
      })
    }else if(this.isNop){
      // is nop
      this.nopSaveAllPreAllocate(this.preAllocateAllItemCode, true, true, 'sourceTxnRefHeaderNo')
    } else {
      this.changePreAllocate()
    }
  }

  getPreAllocateData(list) {
    return list.map((item) => item.reservationsList).flat()
  }
  setWareHouseTitle() {
    let warehouse = this.channels.find(channel => channel.id === this.channelId )
    this.modalConfig.warehouseTitle = warehouse.label
  }
  setRangeDate(list) {
    let dateList =  list.map((item) => item.reservationsList || []).flat().map(date => date.reservationDate)
    dateList = [...new Set(dateList)].sort()
    // let toDate = this.datePipe.transform(this.preAllocateDate, 'yyyy-MM-dd')
    this.modalConfig.rangeDate = dateList[0]; // + ' to ' + toDate
  }
  setPreAllocateItems() {
    // Nop 的 item 内容是: source ref no.
    if (this.isNop) {
      this.setPreAllocateSourceRef()
      return
    }
    let _lastItems = this.modalConfig.items
    let _items = this.modalConfig.table.rawData.map(item => {
      if (item.reservationsList && item.reservationsList.length>0) {
        let desc = item.reservationsList[0].itemDesc
        if(!item.itemCode) {
          item.itemCode = item.reservationsList[0].itemCode
        }
        return {name: item.itemCode + '~' + desc, code: item.itemCode, id: item.reservationsList[0].itemId}
      } else {
        return {name: item.itemCode, code: item.itemCode, id: -1}
      }
    })
    if(_lastItems&&_lastItems.length){
      _items.forEach((item, idx) => {
        if(item.id == -1){
          let lastItem = _lastItems.find(_item => item.code == _item.code)
          if(lastItem) _items[idx] = lastItem
        }
      });
    }
    this.modalConfig.items = _items
    this.modalConfig.changedItemCodeList = [];
    this.modalConfig.savedItemCodeList = [];
    this.modalConfig.filteredCodeList = [];
    this.modalConfig.changeCountList = {};
    this.modalConfig.table.rawData.forEach(rawData => {
      // count all qtyPlanToAllocate > 0 item as saved, change color in popup left tab
      // qtyPlanToAllocate can only set one times from save pre allocate api
      let havePlanToAllocate = rawData.reservationsList.some(data=>data.qtyPlanToAllocate>0)
      if(havePlanToAllocate) this.modalConfig.savedItemCodeList.push(rawData.itemCode)
    });
    if(this.modalConfig.visible == false){
      // modal not visible => this code load when open modal, init selectedItem
      this.modalConfig.selectedItem = this.modalConfig?.items[0]?.code||null
    }else{
      // modal visible => this code load by reload / save, selectedItem keep previous value
    }
    this.setPreAllocateTableData()
  }
  changeTotal(selectItem) {
    let count = 0
    let pendingCount = 0;
    let aoCount = 0;
    let doCount = 0;
    if (selectItem && selectItem.reservationsList) {
      let aoTypeDesc = this.reservationType.filter(type=>[2,13,12,9,11].indexOf(type.id)>-1).map(type=>type.description)
      let doTypeDesc = this.reservationType.filter(type=>[3].indexOf(type.id)>-1).map(type=>type.description)
      selectItem.reservationsList.forEach((cur) => {
        count += cur.qtyPlanToAllocate
        pendingCount += cur.qtyPending
        if(aoTypeDesc.indexOf(cur.reservationType) > -1){aoCount+=cur.qtyPending}
        if(doTypeDesc.indexOf(cur.reservationType) > -1){doCount+=cur.qtyPending}
      });
      // count = selectItem.reservationsList.reduce((prev, cur) => {
      //   prev += cur.qtyPlanToAllocate
      //   return prev
      // }, 0);
    }

    let totalCount = selectItem?.totalCount || 0
    let allocateCount = selectItem?.allocateCount || 0

    this.modalConfig.selTotalCount = totalCount
    this.modalConfig.changeCount = allocateCount
    this.modalConfig.schoolNumber = this.schoolNumberIpt.value || null
    this.modalConfig.totalCount = (totalCount - count) + '(' + totalCount + ')'
    this.modalConfig.totalCountBefore = totalCount
    this.modalConfig.totalCountAfter = totalCount - count
    this.modalConfig.totalPending = pendingCount
    this.modalConfig.totalAOPending = aoCount
    this.modalConfig.totalDOPending = doCount
    let rightSideInputElementAmt = 5; // 3;
    this.modalConfig.schoolNumber&&rightSideInputElementAmt++
    // this.modalConfig.totalAOPending&&rightSideInputElementAmt++
    // this.modalConfig.totalDOPending&&rightSideInputElementAmt++
    this.modalConfig.rightSideInputElementAmt = rightSideInputElementAmt
  }
  changeTick(clear=null) {
    this.modalConfig.table.selected = []
    if (!clear && this.modalConfig.table.data) {
      this.modalConfig.table.data.forEach(item => {
        if(item.qtyPlanToAllocate > 0) {
          let selItem = this.modalConfig.table.selected.find(sel => sel.id === item.id)
          if(!selItem) {
            this.modalConfig.table.selected.push(item)
          }
        }
      });
    }
  }
  setPreAllocateTableData() {
    let selectItem = this.modalConfig.table.rawData.find(item => item.itemCode === this.modalConfig.selectedItem)
    if(selectItem){
      this.modalConfig.table.data = selectItem.reservationsList
      this.modalConfig.table.changeCount = selectItem.allocateCount
      this.modalConfig.schoolNumber = this.schoolNumberIpt.value || null
      this.modalConfig.preAllocateDate = selectItem.reservationEndDate?new Date(selectItem.reservationEndDate):this.preAllocateDate;
      this.modalConfig.changeCount = this.modalConfig.changeCountList[this.modalConfig.selectedItem]?this.modalConfig.changeCountList[this.modalConfig.selectedItem]:null
    }else{
      this.modalConfig.table.data = []
      this.modalConfig.table.changeCount = 0
      this.modalConfig.schoolNumber = null
      this.modalConfig.preAllocateDate = this.preAllocateDate
      this.modalConfig.changeCount = null
    }
    this.modalConfig._currentDataPreAllocateDate = new Date(this.modalConfig.preAllocateDate)

    this.changeTick()
    this.changeTotal(selectItem)
    this.setModalVisible(true)
  }
  // getBUCode() {
  //   // this.service.replenishmentGetBuCode().subscribe(res => {
  //   //   this.buCodes = []
  //   //   if(res.code === '000'){
  //   //     res.data.forEach((item) => {
  //   //       this.buCodes.push({label: item,  value: item})
  //   //     });
  //   //   }
  //   // })
  // }
  getChannels() {
    const list = LocalStorageHelper.getObject('REPO_ACTIVE')
    let REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST:string[] = CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST?CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST:[]
    this.repos = list.filter(item => item.id > 0).filter(item=>!REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST.includes(item.label))
    this.allRepos = list.filter(item => item.id > 0);
    this.cloneRepoData = JSON.parse(JSON.stringify(this.repos))
  }
  initChannelMultiTreeSelect(){
    // wip: uam channel
    this.channelMultiTreeOptions = JSON.parse(localStorage.getItem("REPOTREE_ACTIVE"))
      .filter(repo=>repo.key!='Warehouse')
      .filter(repo=>repo.children.length>0)
    let REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST = CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST?CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST:[]
    this.channelMultiTreeOptions.forEach(opt=>{
      opt.children = opt.children.filter(child=>{
        return !REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST.includes(child.key)
      })
    })
    this.addItemByBuChannelIpt = {
      title: 'Channel',
      name: 'channel',
      value: null,
      showValue: null,
      type:INPUT_TYPE.MUTIPLETREESELECT,
      options: this.channelMultiTreeOptions,
      beforeSubmit: function(value) { return value?value.map(val=>val.id):null },
      change: function(value, ipt) {
        if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
      },
    }
  }
  getItems() {
    this.skus = LocalStorageHelper.getObject('SKU')
    this.allSkus = LocalStorageHelper.getObject('SKU')
    this.cloneSkuData = JSON.parse(JSON.stringify(this.skus))
  }

  getAddItemByBuChannelListForApi(){
    return this.addItemByBuChannelIpt.value?.map(e=>e.key) || []
  }
  getBrandListForApi(){
    return this.brandIpt.value?.map(e=>e.value) || []
  }
  getSubsubcatForApi(){
    return this.subsubcatIpt.value?.map(e=>e.value) || []
  }
  getBUListForApi(){
    return this.bucodeIpt.value?.map(e=>e.value) || []
  }
  getBUListAllChannelForApi(){
    return [this.buCodeForAllChannelIpt.value]
  }

  getLOBListForApi(){
    return this.lobcodeIpt.value?.map(e=>e.value) || []
  }

  getMainCatListForApi(){
    return this.maincatIpt.value?.map(e=>e.value) || []
  }

  getSubCatListForApi(){
    return this.subcatIpt.value?.map(e=>e.value) || []
  }

  getItemsByBU() {
    if(this.loading) return;
    this.demandLineList = this.tableData.data
    this.selectBUCodes = this.bucodeIpt.value
    if(!this.selectBUCodes || !this.selectBUCodes.length) {
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'BU Code'))
      return
    }
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      buList: this.getBUListForApi(),
      lobList: this.lobcodeIpt.value && this.lobcodeIpt.value.length > 0 ? this.getLOBListForApi() : null,
      maincatList:this.maincatIpt.value && this.maincatIpt.value.length > 0 ? this.getMainCatListForApi() : null,
      brandList: this.brandIpt.value && this.brandIpt.value.length > 0 ? this.getBrandListForApi() : null,
      subsubcatList: this.subsubcatIpt.value && this.subsubcatIpt.value.length > 0 ? this.getSubsubcatForApi() : null,
      subcatList: this.subcatIpt.value && this.subcatIpt.value.length > 0 ? this.getSubCatListForApi() : null,
      channelCodeList: this.addItemByBuChannelIpt.value && this.addItemByBuChannelIpt.value.length > 0 ? this.getAddItemByBuChannelListForApi() : null,
      isAodo: this.isAodoIpt.value && this.isAodoIpt.value.length > 0 ? true : null,
      isSerialize: this.isSerializedIpt.value && this.isSerializedIpt.value.length > 0 ? true : null,
      isHaveDemandOnly: this.checkBox.value && this.checkBox.value.length ? true : false
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemByBU(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data)
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }
  getItemsByBuAndExportXlsx() {
    if(this.loading) return;
    this.demandLineList = this.tableData.data
    this.selectBUCodes = this.bucodeIpt.value
    if(!this.selectBUCodes || !this.selectBUCodes.length) {
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'BU Code'))
      return
    }
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      buList: this.getBUListForApi(),
      lobList: this.getLOBListForApi() && this.lobcodeIpt.value && this.lobcodeIpt.value.length > 0 ? this.getLOBListForApi() : null,
      maincatList:this.getMainCatListForApi() && this.maincatIpt.value && this.maincatIpt.value.length > 0 ? this.getMainCatListForApi() : null,
      brandList: this.brandIpt.value && this.brandIpt.value.length > 0 ? this.getBrandListForApi() : null,
      subsubcatList: this.subsubcatIpt.value && this.subsubcatIpt.value.length > 0 ? this.getSubsubcatForApi() : null,
      channelCodeList: this.addItemByBuChannelIpt.value && this.addItemByBuChannelIpt.value.length > 0 ? this.getAddItemByBuChannelListForApi() : null,
      subcatList: this.subcatIpt.value && this.subcatIpt.value.length > 0 ? this.getSubCatListForApi() : null,
      isAodo: this.isAodoIpt.value && this.isAodoIpt.value.length > 0 ? true : null,
      isSerialize: this.isSerializedIpt.value && this.isSerializedIpt.value.length > 0 ? true : null,
      isHaveDemandOnly: this.checkBox.value && this.checkBox.value.length ? true : false
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    // this.service.replenishmentLineAddItemByBU(data).subscribe(res => {
    //   this.loading = false
    //   this.tableData.loadingSwitch = false
    //   if(res.code === '000') {
    //     if (res.data.length) {
    //       let data = this.getDataWithSpreadsheetData(res.data)
    //       let col = [...this.spreadsheet.column]
    //       CommonMethod.downloadXlsxWithOptions(data, col, `${this.exportXlsxName}_add_items_by_bu`, this.datePipe, {fieldKey:'field'})
    //     } else {
    //       this.showMessage('info', this.info.title,  this.info.noRecord)
    //     }
    //   } else {
    //     this.showMessage('error', this.info.title, res.msg || res.message)
    //   }
    // })

    this.service.replenishmentLineAddItemByBuAsCsv(data).pipe(
      switchMap(res=>{
        // return CommonMethod.downloadFileFromResponse(res)
        return CommonMethod.downloadFileFromResponse(res, `replenishment_add_items_by_bu.csv`)
      })
    ).subscribe(res=>{
      this.loading = false
      this.tableData.loadingSwitch = false
      this.showMessage('success', this.info.title, 'Export Success')
    },err=>{
      this.loading = false
      this.tableData.loadingSwitch = false
      this.showMessage('error', this.info.title, err)
    })
  }

  exportItemsByBu(){
    let bu = this.getBUListAllChannelForApi()
    if(bu&&bu.length>1) {
      this.showMessage('warn', this.info.title, this.info.onlyAcceptOneBuCode);
      return;
    }
    if(!bu||bu.length==0) {
      this.showMessage('warn', this.info.title, this.info.oneBuCodeRequired);
      return;
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineExportItemsByBu(bu[0]).pipe(
      switchMap(res=>{
        // return CommonMethod.downloadFileFromResponse(res)
        return CommonMethod.downloadFileFromResponse(res, `replenishment_add_items_by_bu.csv`)
      })
    ).subscribe(res=>{
      this.loading = false
      this.tableData.loadingSwitch = false
      this.showMessage('success', this.info.title, 'Export Success')
    },err=>{
      this.loading = false
      this.tableData.loadingSwitch = false
      this.showMessage('error', this.info.title, err)
    })
  }

  // logic copy from addDataToTable, use for getItemsByBuAndExportXlsx
  getDataWithSpreadsheetData(list, type=null) {
    // new line
    let idx = this.idx
    list.forEach(item => {
      let _qtyAllocate = item.qtyAllocate?item.qtyAllocate:0
      let _qtyPreAllocate = item.qtyPreAllocate?item.qtyPreAllocate:0
      item.rowId = idx++
      item.qtyAllocate = _qtyAllocate
      item.qtyPreAllocate = item.qtyPreAllocate ? item.qtyPreAllocate:0
      item.qtyUserInput = _qtyAllocate - _qtyPreAllocate // new line, use qtyallocate and preallocate to calculate auto userinput
    });
    return [...list]
  }

  addDataToTable(list, type=null) {
    let data = this.spreadsheet.value
    if(data.length) {
      if (type === 'school') {
        list = list.filter(item => {
          const hasExist = data.some(line => {
            const exist = line.itemBuCode === item.itemBuCode && line.itemBuCode !== '' && line.channel === item.channel && line.itemNumber === item.itemNumber && line.itemNumber !==""
            if(exist) {
              // line.qtyAllocate = item.qtyAllocate
              // exist line, qtyUserInput may already changed, calculate diff and update qtyAllocate by diff
              let _qtyAllocate = item.qtyAllocate?item.qtyAllocate:0
              let _qtyPreAllocate = item.qtyPreAllocate?item.qtyPreAllocate:0
              let _nopDeliveryQty = item.nopDeliveryQty?item.nopDeliveryQty:0
              let _qtyUserInput = line.qtyUserInput
              // let qtyUserInputDiff = (_qtyAllocate - _qtyPreAllocate) - _qtyUserInput
              let qtyUserInputDiff = (_qtyAllocate - _qtyPreAllocate) - _qtyUserInput
              line.qtyAllocate = _qtyAllocate - qtyUserInputDiff
            }
            return exist
          })
          if(!hasExist) {
            return item
          }
        });
      } else {
        if(!(list.length === 1 && !list[0].channel && !list[0].itemNumber && !list[0].itemBuCode)) {
          list = list.filter(item => {
            const hasExist = data.some(line =>{
              const exist = line.itemBuCode === item.itemBuCode && line.itemBuCode !== '' && line.channel === item.channel && line.itemNumber === item.itemNumber && line.itemNumber !==""
              if(exist) {
                // line.qtyAllocate = item.qtyAllocate
                // exist line, qtyUserInput may already changed, calculate diff and update qtyAllocate by diff
                let _qtyAllocate = item.qtyAllocate?item.qtyAllocate:0
                let _qtyPreAllocate = item.qtyPreAllocate?item.qtyPreAllocate:0
                let _nopDeliveryQty = item.nopDeliveryQty?item.nopDeliveryQty:0
                let _qtyUserInput = line.qtyUserInput
                // let qtyUserInputDiff = (_qtyAllocate - _qtyPreAllocate) - _qtyUserInput
                let qtyUserInputDiff = (_qtyAllocate - _qtyPreAllocate) - _qtyUserInput
                line.qtyAllocate = _qtyAllocate - qtyUserInputDiff
              }
              return exist
            })
            if(!hasExist) {
              return item
            }
          });
        }
      }
    }
    // new line
    list.forEach(item => {
      let _qtyAllocate = item.qtyAllocate?item.qtyAllocate:0
      let _qtyPreAllocate = item.qtyPreAllocate?item.qtyPreAllocate:0
      item.rowId = this.idx++
      item.qtyAllocate = _qtyAllocate
      item.qtyPreAllocate = item.qtyPreAllocate ? item.qtyPreAllocate:0
      item.qtyUserInput = _qtyAllocate - _qtyPreAllocate // new line, use qtyallocate and preallocate to calculate auto userinput
    });
    this.demandLineList = [...this.spreadsheet.value, ...list]
    // 针对roadshow类型， 需要更改channel的值为query面板中的选择的channel值
   /*  if(this.demandData.hasCourierData) {
      this.demandLineList.forEach(item => {
        item.channel = this.extendChannelIpt.showValue || ''
      })
    } */
    this.spreadsheet.value = [...this.demandLineList];
  }
  // 此功能移除
  /* addItemBySchool() {
    // const schCode = this.schoolCodeIpt.value
    // const schLocCode = this.schoolLocationCodeIpt.value
    // if(!schCode && !schLocCode) {
    //   this.showMessage('warn', this.info.title, this.info.hasValue)
    //   return
    // }
    const schNum = this.schoolNumberIpt.value
    const data = {
      // schCode,
      // schLocCode,
      schNum,
      demandCycleId: this.demandData.demandCycleId,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemBySchoolCode(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data, 'school')
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
      }
    })
  } */
  addItem() {
    // console.log(this.demandData, this.extendChannelIpt.value, this.extendChannelIpt.showValue);
    let channel = ''
    if(this.demandData.hasSchoolData) {
      // let channelList:string[] = CONFIG.REPLENISH_BYOD_CHANNEL_LIST
      // let targetChannelCode = channelList[0] || 'ELG';
      // channel = targetChannelCode; // 'ELG'
      channel = this.extendChannelIpt?.value?.label
    }

    if(this.demandData.hasCourierData) {
      channel = this.extendChannelIpt.showValue || ''
    }
    if(!channel && this.editChannel) {
      this.showMessage('warn', this.info.title, this.info.selectChannel)
      return
    }

    let data:any = this.tableData.columns.reduce((t, v) => {
      t[v.field] = v.field === 'channel' ? channel : ''
      return t
    }, {});

    this.addDataToTable([data])
  }

  /* showDataToTable(lineData) {
    console.log(this.demandLineList, lineData);
    this.demandLineList = [...this.demandLineList, lineData]
    this.tableData.data = this.demandLineList
  } */

  delete(data) {
    this.demandLineList = this.tableData.data
    this.restoreData('confirmDelete', this, data, this.info.delete)
  }

  confirmDelete(data) {
    // console.log(this.demandLineList, data);
    this.demandLineList = this.demandLineList.filter(item => item.rowId !== data.rowId)
    this.tableData.data = this.demandLineList
    this.spreadsheet.value = [...this.demandLineList];
  }

  clear() {
    this.restoreData('confirmCancel', this, {}, this.info.cancel)
  }
  confirmCancel() {
    this.removeReplenishmentObs().subscribe(res=>{
      this.back()
    });
    // this.back()
    // this.router.navigate(['main/demand_cycle/replenishment/search'])
  }
  searchRepo(str) {
    this.repos = this.cloneRepoData.filter(item => item.label.toLowerCase().includes(str.toLowerCase()))
    this.reposCount=0
    if(this.repos.length>this.reposCount){
      this.reposSelect=this.repos[this.reposCount].data
      this.OnListboxScoll(this.repos[this.reposCount].label)
    }
  }
  searchSku(str) {
    this.skus = this.cloneSkuData.filter((item) =>
      item.name.toLowerCase().includes(str.toLowerCase()) && (item.parameters === null || item.parameters === "Y") && (item.isActive === null || item.isActive === "Y")
    );
    this.skusCount = 0;
    if (this.skus.length > this.skusCount) {
      this.skusSelect = this.skus[this.skusCount].code;
      // this.OnListboxScoll(this.skus[this.skusCount].name);
      this.virtualScrollToIndex(this.skusCount)
    }
  }
  selRepo(event, data, el) {
    this.overlayName = 'repo'
    this.currentLine = data
    this.repos = this.cloneRepoData
    this.reposSelect=this.repos[0].id
    el.toggle(event)
  }

  changeRepo(event, el) {
    const repo = this.repos.find(item => item.id === event.value)
    this.currentLine.channel = repo.label
    this.currentLine.toChannelId = repo.id
    this.spreadsheet.value = [...this.spreadsheet.value];
    el.hide()
    if(this.currentLine.toChannelId && this.currentLine.itemId) {
      this.getLineData(this.currentLine)
    }
  }

  selSku(event, data, el) {
    this.overlayName = 'sku'
    this.currentLine = data
    this.skus = this.cloneSkuData
    this.skusSelect = this.skus[0].code
    el.toggle(event)
  }

  changeSku(event, el) {
    const sku = this.skus.find(item => item.code === event.value)
    this.currentLine.itemNumber = sku.name
    this.currentLine.itemId = sku.code
    // this.spreadsheet.value = [...this.spreadsheet.value];
    el.hide()
    if(!this.currentLine.toChannelId && this.currentLine.channel) {
      const shop =  LocalStorageHelper.getObject('REPOMODULEBYUAMTREE').filter(repo => repo.children.length).map(repo=>repo.children).flat().find(repo=>repo.label===this.currentLine.channel)
      this.currentLine.toChannelId = shop.id
    }
    if(this.currentLine.toChannelId && this.currentLine.itemId) {
      this.getLineData(this.currentLine)
    }else{
      this.spreadsheet.value = [...this.spreadsheet.value];
    }
  }
  getLineData(currentLine = this.currentLine, options?) {
    if(this.loading) return;
    const data = {
      accountName: this.account,
      // channelReplenishId: replenishmentLine.replenishId,
      demandCycleId: this.demandData.demandCycleId,
      itemId: currentLine.itemId,
      itemCode: currentLine.itemNumber,
      channelId:  currentLine.toChannelId,
      channelCode: currentLine.channel
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItem(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        // tslint:disable-next-line: no-unused-expression
        res.data.length && this.changeTableRowData(res.data[0], currentLine, options)
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
        // this.showMessage('warn', this.info.title, this.info.fail.replace('Save', 'get data'))
      }
    })
  }

  // // for import large amt data
  // currentDataBuffer: any = {}

  // for import large amt data
  itemChannelToDataIdx: any = {}


  getLineDataForMultiRow(newRowIdx: number[]) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let lineDataList = this.spreadsheet.value;
    let needToUpdateIndex : number[] = [...newRowIdx];
    let channelItemMapping = {};
    needToUpdateIndex.forEach(idx=>{
      let channelId = lineDataList[idx].toChannelId
      channelItemMapping[channelId]=channelItemMapping[channelId]?channelItemMapping[channelId]:[];
      channelItemMapping[channelId].push(lineDataList[idx].itemId)
    })
    let channelItemList = Object.keys(channelItemMapping).map(key=>{
      return {channelId: Number.parseInt(key), itemIdList: channelItemMapping[key]}
    })
    let data = {
      demandCycleId: this.demandData.demandCycleId,
      channelAndItemList: channelItemList,
      pageIndex: 0,
      pageSize: 2147483647, // get all
    }
    let channelItemIdxMapper = {};
    this.spreadsheet.value.forEach((val, idx) => channelItemIdxMapper[`${val.toChannelId}_${val.itemId}`] = idx )
    this.service.replenishmentLineAddItems(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      // if (res.code === '000') {
      //   // tslint:disable-next-line: no-unused-expression
      //   res.data.length && this.changeTableRowData(res.data[0], currentLine, options)
      // } else {
      //   this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
      //   // this.showMessage('warn', this.info.title, this.info.fail.replace('Save', 'get data'))
      // }
      if (res.code === '000'){
        res.data.forEach(data => {
          let idx = channelItemIdxMapper[`${data.channel_id}_${data.item_id}`]
          let options = {qtyUserInput: this.spreadsheet.value[idx].qtyUserInput, qtyAllocate: this.spreadsheet.value[idx].qtyUserInput}
          let _data = this.preProcessLineData(idx, data, options)
          this.demandLineList[idx] = {...this.spreadsheet.value[idx], ..._data}
        });
        this.spreadsheet.value = [...this.demandLineList]
      }
    })
  }
  changeTableRowData(data, currentLine, options?) {
    // tslint:disable-next-line: forin
    // call by getLineData after add item => always new record here
    for (const key in data) {
      if(data.hasOwnProperty(key)){
        currentLine[key] = data[key]
      }
    }
    const hasExist = this.tableData.data.some(line => line.rowId !==  currentLine.rowId
      && line.itemBuCode === currentLine.itemBuCode && line.channel === currentLine.channel
      && line.itemNumber === currentLine.itemNumber)
    if(hasExist) {
      this.showMessage('warn', this.info.title, this.info.duplicate)
    }
    // currentLine.icCode = "S" + currentLine.channel
    // if(
    //   currentLine.icCode == null // icCode == null
    //   || (currentLine.icCode!=null && currentLine.icCode.toUpperCase().indexOf('-FG') < 0) // not include -FG in icCode
    // ){
    //   currentLine._icCode = this.replenishChannel; // currentLine.icCode
    // }else{
    //   // not null & include -FG
    //     currentLine._icCode = currentLine.icCode.toUpperCase().replace('-FG', this.replenishChannel);
    // }
    if(!options){
      currentLine.qtyAllocate = 0
      currentLine.qtyPreAllocate = 0
      currentLine.qtyUserInput = 0
    }else{
      currentLine.qtyAllocate = options.qtyAllocate?options.qtyAllocate:0
      currentLine.qtyPreAllocate = options.qtyPreAllocate?options.qtyPreAllocate:0
      currentLine.qtyUserInput = options.qtyUserInput?options.qtyUserInput:0
    }
    if(this.isNop)currentLine.qtyNopDelivery = 0;
    const idx = this.demandLineList.findIndex(item => item.rowId ===  currentLine.rowId)
    this.demandLineList[idx] = currentLine
    this.spreadsheet.value = [...this.spreadsheet.value];
  }

  preProcessLineData(idx, newData, options){
    let currentLine = newData;
    if(!options){
      currentLine.qtyAllocate = 0
      currentLine.qtyPreAllocate = 0
      currentLine.qtyUserInput = 0
    }else{
      currentLine.qtyAllocate = options.qtyAllocate?options.qtyAllocate:0
      currentLine.qtyPreAllocate = options.qtyPreAllocate?options.qtyPreAllocate:0
      currentLine.qtyUserInput = options.qtyUserInput?options.qtyUserInput:0
    }
    if(this.isNop)currentLine.qtyNopDelivery = 0;
    return currentLine;
  }

  validationData(data) {
    let flag = true
    if(!this.channelId) {
      this.channelIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Channel'))
      flag = false
    }
    if(!this.referenceNumber) {
      this.referenceNumberIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Reference NumberIpt'))
      flag = false
    } else if(this.referenceNumber.length > CONFIG.REPLENISH_REFNO_MAX_LENGTH) {
      this.referenceNumberIpt.error = true
      this.showMessage('warn', this.info.title, `${this.referenceNumberIpt.title} ` + this.info.maxLength.replace('${1}', CONFIG.REPLENISH_REFNO_MAX_LENGTH))
      flag =  false
    }

    if(CONFIG.REPLENISH_REMARK_MAX_LENGTH && this.remarks.length > CONFIG.REPLENISH_REMARK_MAX_LENGTH){
      this.showMessage('warn', this.info.title, `${this.remarksIpt.title}` + this.info.maxLength.replace('${1}', CONFIG.REPLENISH_REMARK_MAX_LENGTH))
      return
    }

    if(!this.needByDate) {
      this.needByDateIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Need By Date'))
      flag = false
    } else {
      /* // 判断选择的日期是否大于系统当天的日期
      const day = 24 * 60 * 60 * 1000
      const diff = Math.floor((this.needByDate.getTime() - this.currentDay.getTime()) / day)
      if(diff <= 0) {
        this.needByDateIpt.error = true
        this.showMessage('warn', this.info.title, this.info.dateError)
        flag = false
      } */
    }
    if(data.length) {
      const channel = data.some(item => !item.channel)
      if(channel) {
        this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Channel of Item'))
        flag = false
      }
      const itemNumber = data.some(item => !item.itemNumber)
      if(itemNumber) {
        this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Item Number of Item'))
        flag = false
      }
      const result = data.some(item => item.qtyAllocate === null || item.qtyAllocate < 0 )
      if(result) {
        flag = false
        this.showMessage('warn', this.info.title, this.info.gt)
      }

      let warehouse = this.channels.find(channel => channel.id === this.channelId )
      let wKey = `qty${warehouse.label}`; // qtyW003, qtyW001 etc
      // const ltResult = data.some(item => item.qtyAllocate > item[wKey] )
      // if(ltResult) {
      //   flag = false
      //   // this.showMessage('warn', this.info.title, this.info.lt)
      //   this.showMessage('warn', this.info.title, this.info.lt.replace('Allocate', ' Allocate').replace('qtyW003', warehouse.label))

      // }

      if(!this.isNop){
        let buCodeList = {};
        let buCodeCounter = 0
        const diffBuCodeItem = data.some(item => {
          if(!buCodeList[item.itemBuCode]){
            buCodeCounter++;
            buCodeList[item.itemBuCode]=true;
          }
          return buCodeCounter > 1
        } )
        if(diffBuCodeItem) {
          flag = false
          this.showMessage('warn', this.info.title, this.info.diffBuCodeFound)
        }
      }

    }
    return flag
  }
  verifyQty(list) {
    return list.find(item => {
      // let selItem = this.tableData.basicData.find(bItem => bItem.channel === item.channel && bItem.itemNumber === item.itemNumber)
      // if(selItem && selItem.qtyAllocate > item.qtyAllocate) {
      //   let name = item.channel + ' + ' + item.itemNumber
      //   this.showMessage('warn', this.info.title, this.info.valueError.replace('The', name))
      // }
      let warehouse = this.channels.find(channel => channel.id === this.channelId )
      let wKey = `qty${warehouse.label}`; // qtyW003, qtyW001 etc
      // if(item.qtyAllocate > item[wKey]) {
      //   let name = item.channel + ' + ' + item.itemNumber
      //   this.showMessage('warn', this.info.title, this.info.lt.replace('Allocate', name + ' Allocate').replace('qtyW003', warehouse.label))
      // }
      // return selItem ? selItem.qtyAllocate > item.qtyAllocate || item.qtyAllocate > item.qtyW003 : false
      return true // item.qtyAllocate > item[wKey]
    })
  }
  showChangeDate() {
    this.conf.confirm({
      message: 'If the date is changed, the previous pre allocate action will be removed?',
      header: 'Change Date Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => { this.resetPreAllocate() },
      reject: () => { },
      key: "preAllocate"
    });
  }
  //
  readyToBePreAllocated() {
    // 检测 pre allocate date 的日期是否变化，有变化时出提示信息。
    // skip date checking because date input moved into the pre allocate popup
    // if(this.clonePreAllocateDate && this.clonePreAllocateDate !== this.formatDate(this.preAllocateDate)) {
    //   this.showChangeDate()
    // } else {
    this.save(true)
    // }
  }
  resetPreAllocate() {
    const data = {
      accountName: this.account,
      replenishId: this.getDemandLine().id,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.deletePreAllocate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      this.save(true)
    })
  }
  resetSelectedItemPreAllocate() {
    const data = {
      accountName: this.account,
      replenishId: this.getDemandLine().id,
      itemCode: this.modalConfig.selectedItem,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.deletePreAllocate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      let item = this.modalConfig.selectedItem
      if(this.modalConfig.changedItemCodeList.indexOf(item)>-1){
        this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(item),1)
      }
      if(this.modalConfig.savedItemCodeList.indexOf(item)>-1){
        this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(item),1)
      }
      if(this.formatDate(this.modalConfig.preAllocateDate)!=this.formatDate(this.preAllocateDate)){
        this.modalConfig.endDateChanged = true
      }
      this.save(true)
    })
  }

  rollbackSelectedItemToDefaultDateEmptySelect(){
    const data = {
      accountName: this.account,
      replenishId: this.getDemandLine().id,
      itemCode: this.modalConfig.selectedItem,
    }
    this.loading = true
    this.modalConfig.preAllocateDate = new Date(this.preAllocateDate)
    this.tableData.loadingSwitch = true
    return this.service.deletePreAllocate(data).pipe(
      switchMap(res => {
        this.loading = false
        this.tableData.loadingSwitch = false
        let item = this.modalConfig.selectedItem
        if(this.modalConfig.changedItemCodeList.indexOf(item)>-1){
          this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(item),1)
        }
        if(this.modalConfig.savedItemCodeList.indexOf(item)>-1){
          this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(item),1)
        }

        // this.modalConfig.endDateChanged = true
        // this.save(true)
        return this.getLinesObs(this.hasCreate, true, true)
      }),
      switchMap(res=>{
        // edit complete
        return this.updateObs(true, true)
      }),
      switchMap(res=>{
        return this.fetchTargetItemPreAllocateObs(res.data[0], false, true)
      })
    )
  }


  save(fromPreAllocate = false) {
    if(!(this.address3Ipt.value!=null||this.demandData.demandCycleType !== 'BYOD')){
      this.showMessage('error', this.info.title, 'create fail Address line 3 is required.')
      return
    }
    if(this.demandData.demandCycleType == 'BYOD'){
      if(
        this.address1Ipt.value != null
        && this.address1Ipt.maxLength
        && this.address1Ipt.value.length > this.address1Ipt.maxLength
      ){
        this.showMessage('error', this.info.title, `Address 1 max length ${this.address1Ipt.maxLength}.`)
        return
      }
      if(
        this.address2Ipt.value != null
        && this.address2Ipt.maxLength
        && this.address2Ipt.value.length > this.address2Ipt.maxLength
      ){
        this.showMessage('error', this.info.title, `Address 2 max length ${this.address1Ipt.maxLength}.`)
        return
      }
      if(
        this.address3Ipt.value != null
        && this.address3Ipt.maxLength
        && this.address3Ipt.value.length > this.address3Ipt.maxLength
      ){
        this.showMessage('error', this.info.title, `Address 3 max length ${this.address1Ipt.maxLength}.`)
        return
      }
    }
    if(this.demandData.demandCycleType === 'BYOD' && fromPreAllocate && !this.schoolNumberIpt.value) {
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', this.QUERY_TITLE.SCHOOL_NUMBER))
      return
    }
    if(this.demandData.demandCycleType === 'BYOD' && this.contactPersonIpt&&(this.contactPersonIpt.value==null || this.contactPersonIpt.value.length==0)) {
      this.showMessage('error', this.info.title, this.info._required.replace('${1}', this.QUERY_TITLE.CONTACT_PERSON))
      return
    }
    if((this.demandData.demandCycleType === 'BYOD' || this.demandData.demandCycleType === 'MOB_RoadShow') && this.contactPersonIpt&&(this.contactPersonIpt.value!=null && this.contactPersonIpt.value.length!=0 && this.getByteLength(this.contactPersonIpt.value))) {
      this.showMessage('error', this.info.title, this.info._invalidFormat.replace('${1}', this.QUERY_TITLE.CONTACT_PERSON))
      return
    }
    if(this.demandData.demandCycleType === 'BYOD' && this.contactPhoneIpt&&(this.contactPhoneIpt.value==null || this.contactPhoneIpt.value.length==0)) {
      this.showMessage('error', this.info.title, this.info._required.replace('${1}', this.QUERY_TITLE.CONTACT_PHONE))
      return
    }
    if(this.demandData.demandCycleType === 'BYOD' && this.contactPhoneIpt&&this.contactPhoneIpt.value && /[^\d]/.test(this.contactPhoneIpt.value)) {
      // phone num ipt already set /[^\d]/ to input event beforeinput, should not have non numeric char input as normal way (keyboard / copy paste)
      // add this checking to make sure phone value must be numeric
      this.showMessage('warn', this.info.title, this.info.phoneNumericFail)
      return
    }
    if((this.demandData.demandCycleType === 'BYOD' || this.demandData.demandCycleType === 'MOB_RoadShow') && this.address1Ipt&&(this.address1Ipt.value!=null && this.address1Ipt.value.length!=0 && this.getByteLength(this.address1Ipt.value))) {
      this.showMessage('error', this.info.title, this.info._invalidFormat.replace('${1}', this.QUERY_TITLE.ADDRESS_1))
      return
    }
    if((this.demandData.demandCycleType === 'BYOD' || this.demandData.demandCycleType === 'MOB_RoadShow') && this.address2Ipt&&(this.address2Ipt.value!=null && this.address2Ipt.value.length!=0 && this.getByteLength(this.address2Ipt.value))) {
      this.showMessage('error', this.info.title, this.info._invalidFormat.replace('${1}', this.QUERY_TITLE.ADDRESS_2))
      return
    }
    if((this.demandData.demandCycleType === 'BYOD' || this.demandData.demandCycleType === 'MOB_RoadShow') && this.address3Ipt&&(this.address3Ipt.value!=null && this.address3Ipt.value.length!=0 && this.getByteLength(this.address3Ipt.value))) {
      this.showMessage('error', this.info.title, this.info._invalidFormat.replace('${1}', this.QUERY_TITLE.ADDRESS_3))
      return
    }
    /* if(this.demandData.demandCycleType === 'NOP' && fromPreAllocate) {
      this.showMessage('warn', this.info.title, this.info.notPreAllocate.replace('type', this.demandData.demandCycleType + ' type'))
      return
    } */
    let spreadsheetdata = this.spreadsheetComponent?this.spreadsheetComponent.getData():null;
    if(!this.validationData(spreadsheetdata)){
      return
    }
    if(this.demandData.hasCourierData) {
      const currentChannel = this.extendChannelIpt.showValue
      const result = spreadsheetdata.some(item => item.channel !== currentChannel)
      if (result) {
        this.showMessage('error', this.info.title, this.info.consistent)
        return
      }
    }
    if(this.hasDuplicate(spreadsheetdata)) {
      this.showMessage('warn', this.info.title, this.info.duplicate)
      return
    }
    if(!this.spreadsheetComponent){
      return
    }
    // jira#22832 : allow save qty > warehouse qty line
    // if(this.tableData.basicData.length > 0) {
    //   let resolt = this.verifyQty(spreadsheetdata)
    //   if(resolt) {
    //     return
    //   }
    // }
    if(this.hasCreate) {
      // this.update(fromPreAllocate)
      this.getLines(this.hasCreate, fromPreAllocate)
      return
    }
    /* const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      fromChannelId: this.channelId,
      referenceNumber: this.referenceNumber,
      sdlReplenishStatusId: this.status,
      // buCode: this.buCode,
      // isHaveDemandOnly: this.isHaveDemandOnly,
      // schCode: this.schoolCodeIpt.value,
      // schLocCode: this.schoolLocationCodeIpt.value,
      ...(this.schoolNumberIpt.value && {schNum: this.schoolNumberIpt.value}),
      // contactPersonNo: this.contactPersonIpt.value,
      ...(this.address1Ipt.value && {address1: this.address1Ipt.value}),
      ...(this.address2Ipt.value && {address2: this.address2Ipt.value}),
      ...(this.address3Ipt.value && {address3: this.address3Ipt.value}),
      ...(this.courierIpt?.value && {overrideCourierId: this.courierIpt.value}),
      ...(this.contactPersonIpt.value && {contactPersonName: this.contactPersonIpt.value}),
      ...(this.contactPhoneIpt.value && {contactPhoneNo: this.contactPhoneIpt.value}),
      ...(this.extendChannelIpt.value && {channelId: this.extendChannelIpt.value.id}),
      needByDate: this.needByDate.getTime(),
      ...(this.remarks && {remarks: this.remarks}),
      // ...(this.tableData.data.length && {lines: this.tableData.data})
      ...(spreadsheetdata.length && {lines: spreadsheetdata})
    } */
    const data = this.getParams(spreadsheetdata)
    this.loading = true
    this.tableData.loadingSwitch = true
    this.replenishCreateData = data;
    this.service.replenishmentCreate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        if(fromPreAllocate&&res.data[0]){
          this.preAllocateReplenishmentCreated = true;
          this.replenishId = res.data[0].id;
          this.replenish = res.data[0]
          this.replenishSaved = true;
        }
        !fromPreAllocate && this.showMessage('success', this.info.title,  this.info.success)
        this.saveDataToStorage(res.data[0], fromPreAllocate)
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
        this.idx = 0
      }
    })
  }

  saveDataToStorage(params, fromPreAllocate=false) {
    let line:any = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    if (line) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          line[key] = params[key]
        }
      }
      line.status = "DRAFT"
    }else{
      line = params
    }
    // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(line))
    this.setDemandLine(line)
    if (fromPreAllocate) {
      this.hasCreate = true

      // set ref no dim after click pre allocate when is nop
      if(this.isNop) this.referenceNumberIpt.disabled = this.isNop

      if(this.modalConfig.visible == false){
        this.fetchPreAllocate(params)
      }else{
        this.fetchTargetItemPreAllocate(params)
      }
    } else {
      this.redirect()
    }
  }
  redirect(){
    setTimeout(() => {
      let line: any = this.getDemandLine()
      this.router.navigate(['main', 'demand_cycle', 'replenishment', 'detail'], {queryParams:{id:this.demandData.demandCycleId, replenishId:line.id}})
    }, 200);
  }

  stopEvent(event){
    event.stopPropagation()
  }

  sortSearch(field) {
    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
  }
  // back() {
  //   window.history.back()
  // }
  back() {
    if(this.demandData){
      this.router.navigate(['main', 'demand_cycle', 'replenishment', 'search'],{queryParams:{id:this.demandData.demandCycleId}})
    }else{
      this.router.navigate(['main', 'demand_cycle', 'search'])
    }
  }
  // The current date + 1 day
  getNextDay(date) {
    const nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    return nextDay
  }


  // spreadsheet start

  spreadsheet = {
    value: [],
    column: [],
    checkRowDeleteAvailableFn: null,
  }
  spreadsheetComponent: SpreadsheetComponent;
  overlayPanel = {}

  initSpreadsheet(){
    let that = this;
    this.spreadsheet = {
      value: [],
      column: [
        {
          field: "channel",
          title: "Channel",
          disabled: false,
          externalDropdown: true,
          unableDelete: true,
          exportCellEditable: true,
          validationFn: (value)=>{return that.repos.some((repo)=>repo.label == value)}
        },
        {
          field: "itemNumber",
          title: "Item Number",
          format: "text",
          disabled: false,
          externalDropdown: true,
          unableDelete: true,
          exportCellEditable: true,
          validationFn: (value)=>{return that.skus.some((sku)=>sku.name == value)}
        },
        {
          field: "desc",
          title: "Description",
        },
        {
          field: "itemBuCode",
          title: "BU",
        },
        {
          field: "itemLobCode",
          title: "LOB",
        },
        {
          field: "channelNature",
          title: "Channel Nature",
        },
        {
          field: "icCode",
          title: "IC Code (LIS subinv)",
        },


        {
          field: "model",
          title: "Model",
        },
        {
          field: "uom",
          title: "UOM",
        },
        {
          field: "p30Sales",
          title: "P30",
          format: "common",
        },
        {
          field: "p21Sales",
          title: "P21",
          format: "common",
        },
        {
          field: "p14Sales",
          title: "P14",
          format: "common",
        },
        {
          field: "p07Sales",
          title: "P07",
          format: "common",
        },
        {
          field: 'qtyDoc',
          title: this.TABLE_FIELD.DOC,
          format: "common",
        },
        {
          field: "qtyAvailable",
          title: "Available",
          format: "common",
        },
        {
          field: "qtyReserved",
          title: "Reserved",
          format: "common",
        },
        {
          field: "qtyAo",
          title: "AO",
          format: "common",
        },
        {
          field: "qtyTransit",
          title: "Transit-in",
          format: "common",
        },
        {
          field: "qtyDo",
          title: "DO",
          format: "common",
        },
        {
          field: "qtyTotalAvailable",
          title: "Total FG",
          format: "common",
        },
        {
          field: "qtyOnhand",
          title: "Total On-hand",
          format: "common",
        },
        {
          field: "qtyRequest",
          title: "Channel Request",
          format: "common",
        },
        {
          field: "qtyPreAllocate",
          title: "Pre-Allocate Qty",
          format: "common",
        },
        {
          field: "qtyUserInput",
          title: this.TABLE_FIELD.USERINPUT_QTY,
          format: "common",
          disabled: false,
          type: "number",
          exportCellEditable: true,
        },
        {
          field: "qtyAllocate",
          title: "Replenish Qty",
          format: "common",
          // disabled: false,
          // type: "number",
          formula: {
            sign: '+',
            children: [
              'qtyPreAllocate',
              'qtyUserInput',
            ]
          }
        },
        ...(this.demandData&&this.isNop?[{
          field: "nopDeliveryQty",
          title: "NOP Delivery Qty",
          format: "common",
        }]:[]),
        {
          field: "qtyW003",
          title: "W003",
          format: "common",
        },
        {
          field: "qtyW003Demand",
          title: "W003 Demanded",
          format: "common",
        },
        {
          field: "qtyW005",
          title: "W005",
          format: "common",
        },
        {
          field: "qtyW006",
          title: "W006",
          format: "common",
        },
        {
          field: "qtyW007",
          title: "W007",
          format: "common",
        },
        {
          field: "qtyW008",
          title: "W008",
          format: "common",
        },
        {
          field: "qtyW009",
          title: "W009",
          format: "common",
        },
        {
          field: "qtyW010",
          title: "W010",
          format: "common",
        },
        {
          field: "qtyW011",
          title: "W011",
          format: "common",
        },
        {
          field: "qtyW012",
          title: "W012",
          format: "common",
        },
        {
          field: "qtyW013",
          title: "W013",
          format: "common",
        },
        {
          field: "qtyPsw",
          title: "PSW",
          format: "common",
        },
        {
          field: "lisReservationStatus",
          title: this.TABLE_FIELD.LIS_RESERVATION_STATUS,
          format: "common",
        },
        {
          field: "sourceSysReservationId",
          title: this.TABLE_FIELD.LIS_RESERVATION_ID,
          format: "common",
        },
        {
          field: "interfaceStatus",
          title: this.TABLE_FIELD.INTERFACED_STATUS,
          format: "common",
        },
        {
          field: "interfaceDate",
          title: this.TABLE_FIELD.INTERFACED_DATE,
          format: "common",
          type: "datetime",
        },
        {
          field: "sourceSysOrderNumber",
          title: this.TABLE_FIELD.ISO_NUMBER,
          format: "common",
        },
        {
          field: "irBatch",
          title: this.TABLE_FIELD.IR_BATCH,
          format: "common",
        },
        {
          field: "updateBy",
          title: "Updated By",
          format: "common",
        },
        {
          field: "updateDate",
          title: "Updated Datetime",
          type: "datetime",
        },
      ],
      checkRowDeleteAvailableFn: (data)=>{return !data._disableDelete}
    }
  }
  setMainComponent(e){

  }
  setSpreadSheet(e){
    this.spreadsheetComponent = e;
  }
  setelRef(e){
    this.eleRef = e;
  }
  setOverlayPanel(target, elementRef){
    this.overlayPanel[target] = elementRef
  }
  spreadsheetValueChange(e){
    this.spreadsheet.value = e;
    this.demandLineList = this.spreadsheet.value;
  }
  setFnGetOverlayVirtualScroller(e){
    this.getOverlayVirtualScrollerFn = e;
  }
  preAllocateCommonComponent: PreAllocateCommonComponent
  setPreAllocateCommonComponent(e){this.preAllocateCommonComponent=e}
  onCellValueChanged(e){
    // this.spreadsheet.value[e.index][e.field] = e.newValue
    if(e.field == 'channel') {
      const repo = this.repos.find(item => item.label === e.newValue)
      this.spreadsheet.value[e.index].toChannelId = repo.id
    }
    if(e.field == 'itemNumber') {
      const sku = this.skus.find(item => item.name === e.newValue)
      this.spreadsheet.value[e.index].itemId = sku.code
    }
    if(e.field == 'channel' || e.field == 'itemNumber') {
      if(this.spreadsheet.value[e.index].toChannelId && this.spreadsheet.value[e.index].itemId) {
        this.getLineData(this.spreadsheet.value[e.index])
      }
    }
    if(e.field == 'qtyUserInput'){
      let qtyPreAllocate = !isNaN(this.spreadsheet.value[e.index].qtyPreAllocate)
        &&this.spreadsheet.value[e.index].qtyPreAllocate!=null
        &&this.spreadsheet.value[e.index].qtyPreAllocate!=''
        ?this.spreadsheet.value[e.index].qtyPreAllocate:0
      let qtyUserInput = !isNaN(this.spreadsheet.value[e.index].qtyUserInput)
        &&this.spreadsheet.value[e.index].qtyUserInput!=null
        &&this.spreadsheet.value[e.index].qtyUserInput!=''
        ?this.spreadsheet.value[e.index].qtyUserInput:0
      this.spreadsheet.value[e.index].qtyAllocate = qtyPreAllocate + qtyUserInput
      this.spreadsheet.value = [...this.spreadsheet.value]
    }
  }
  onEditStart(e){
    //console.log('----- edt start -----', {e});
    if(e.field === 'channel' && this.editChannel){
      this.showMessage('warn', this.info.title, this.info.notEdit)
      return
    }
    if(e.field === 'channel' && e.isHeader === false) {
      if(!e.isHeader) this.selRepo(e.dummyEvent, this.spreadsheet.value[e.index], this.overlayPanel['overlay']);

    }
    if(e.field === 'itemNumber' && e.isHeader === false) {
      if(!e.isHeader) this.selSku(e.dummyEvent, this.spreadsheet.value[e.index], this.overlayPanel['overlay']);
    }
  }
  // spreadsheet end
  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
  calculate() {
    let that = this;
    if (!this.isNop) {
      if(this.modalConfig.selTotalCount === 0) {
        this.showMessage('warn', this.info.title, this.info.notCalculate)
        return
      }
      if(!this.modalConfig.changeCount || this.modalConfig.changeCount === 0) {
        this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Pre-Allocate amount'))
        return
      }
      if(this.modalConfig.changeCount > this.modalConfig.selTotalCount) {
        this.showMessage('warn', this.info.title, this.info.calculate)
        return
      }
    } else {
      let result = this.modalConfig.nopItem.data.some(item =>{
        if (isNaN(Date.parse(item.pickupFromDate))) {
          this.showMessage('warn', this.info.title, this.info.dateFail.replace('Date', item.itemCode + " Pickup From Date"))
        }
        if (isNaN(Date.parse(item.pickupToDate))) {
          this.showMessage('warn', this.info.title, this.info.dateFail.replace('Date',  item.itemCode + " Pickup To Date"))
        }
        return isNaN(Date.parse(item.pickupFromDate)) || isNaN(Date.parse(item.pickupToDate))
      })
      if(result) {
        return
      }
    }
    // set currentDataBuffer when first time calculate / tick
    let itemCode = this.modalConfig.selectedItem
    let selectItem = this.isNop ? this.modalConfig.table.data : this.modalConfig.table.rawData.find(item => item.itemCode === itemCode)
    if(this.modalConfig.currentDataBuffer==null) this.modalConfig.currentDataBuffer = JSON.parse(JSON.stringify(selectItem))
    if(this.modalConfig.statusBuffer==null){
      if(this.modalConfig.savedItemCodeList.indexOf(itemCode)>-1) {
        this.modalConfig.statusBuffer = 'saved';
      }else if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1) {
        this.modalConfig.statusBuffer = 'changed';
      }else{
        this.modalConfig.statusBuffer = 'normal';
      }
    }
    let _currentDataPreAllocateDate = this.modalConfig._currentDataPreAllocateDate
    if (this.isNop) {
      let list = JSON.parse(JSON.stringify(this.modalConfig.nopItem.data));
      list.forEach(item =>{
        item.pickupFromDate = new Date(item.pickupFromDate).getTime()
        // item.pickupToDate = 'YYYY/MM/DD' string, get time always 00:00:00.000 +timezone (+0800)
        // set to 23:59:59.999
        item.pickupToDate = new Date(item.pickupToDate).getTime()+24*60*60*1000-1
        let _preAllocateQty = Number.parseInt(item.preAllocateQty)
        item.preAllocateQty = !Number.isNaN(_preAllocateQty)?_preAllocateQty:item.preAllocateQty;
      })
      let data = {
        replenishId: this.getDemandLine().id,
        demandCycleType: this.demandData.demandCycleType,
        reservationEndDate: this.formatDate(this.preAllocateDate),
        list
      }
      this.conf.confirm({
        message: this.info.nopCalculateAllResetWarning,
        header: this.info.title,
        accept: ()=>that.fetchDataFromNop(data)
      })
      // this.fetchDataFromNop(data)
      return
    }
    let data = {
      replenishId: this.getDemandLine().id,
      demandCycleType: this.demandData.demandCycleType,
      reservationEndDate: this.formatDate(_currentDataPreAllocateDate),
      itemCode: this.modalConfig.selectedItem,
      allocateCount: this.modalConfig.changeCount
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    // change to /channel_replenish/preAllocate
    this.service.fetchPreAllocateWithItem(data).subscribe(res => {
      if(res.code === '000') {
        res.data[0].reservationEndDate = _currentDataPreAllocateDate
        this.modalConfig.table.rawData = this.mapDataByItemCode(this.modalConfig.table.rawData, res.data);
        this.setPreAllocateTableData()
        res.data.forEach(_resData => {
          let haveQtyPlanToAllocateNotZero = _resData.reservationsList.some(element => {
            return element.qtyPlanToAllocate > 0
          });
          if(haveQtyPlanToAllocateNotZero && this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode)==-1){
            this.modalConfig.changedItemCodeList.push(_resData.itemCode)
          }
          if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
            this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode), 1)
          }
          // set changeCountList, pass to backend when save single item
          this.modalConfig.changeCountList[this.modalConfig.selectedItem] = this.modalConfig.changeCount;
        });
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
      this.loading = false
      this.tableData.loadingSwitch = false
    })
  }

  mapDataByItemCode(oldDatas:any[], newDatas){
    // calculate, calculate all function return target item
    // other item should keep user changed value (user manually change / calculate button)
    let res = oldDatas
    newDatas.forEach(_newData => {
      let targetIdx
      res.some((_oldData,idx)=>{
        if(_oldData.itemCode == _newData.itemCode){
          targetIdx = idx
          _oldData = _newData;
          return true
        }else{
          return false
        }
      })
      if(targetIdx!=null) {
        res[targetIdx] = _newData
      }else{
        res.push(_newData)
      }
    });
    return res
  }

  onImport(e){
    if(this.loading) {
      this.showMessage('warn', 'System', "Please wait until the execution is completed")
      return;
    }
    if(e.isColumnMatch){
      let _value = [...this.spreadsheet.value]
      let _max = {}
      let _sum = {}
      let keyList = [];
      let warehouse = this.channels.find(channel => channel.id === this.channelId )
      let wKey = `qty${warehouse.label}`; // qtyW003, qtyW001 etc
      let newRowIdx = [];

      let channelItemIdxMapper = {};
      let duplicateChannelItem = [];
      let excelInvalid = false
      let errMsg = null

      _value.forEach((val, idx) => channelItemIdxMapper[`${val.channel}_${val.itemNumber}`] = idx)

      let newDataChannelItemIdxMapper : any = {} // to find any duplicated channel item in excel
      let REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST:string[] = CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST?CONFIG.REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST:[]

      e.data.some((data, idx) => {
        if(REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST.includes(data.channel))return false

        if(this.isChannelFixed && data.channel!=this.extendChannelIpt.showValue) {
          excelInvalid = true
          errMsg = `Invalid channel ${data.channel} found, only ${this.extendChannelIpt.showValue} is allowed.`
          return true; // break loop
        }

        if(newDataChannelItemIdxMapper[`${data.channel}_${data.itemNumber}`]==null){
          newDataChannelItemIdxMapper[`${data.channel}_${data.itemNumber}`] = idx
        }else{
          duplicateChannelItem.push(`${data.channel}.${data.itemNumber}`)
          excelInvalid = true
          // return true // break loop
        }

        let target = _value[channelItemIdxMapper[`${data.channel}_${data.itemNumber}`]] || null;
        // .find(originValue=>{
        //   if(originValue.channel == data.channel && originValue.itemNumber == data.itemNumber){
        //     return true
        //   }else{
        //     return false
        //   }
        // })
        if(target){
          target.qtyUserInput = data.qtyUserInput?data.qtyUserInput:0;
          target.qtyAllocate = target.qtyPreAllocate + target.qtyUserInput;
          let targetKey = `${target.itemNumber}`;
          if(keyList.indexOf(targetKey)==-1) keyList.push(targetKey);
          _max[targetKey] = target[wKey]; // warehouse balance should be same per item
          if(_sum[targetKey]==null) _sum[targetKey]=0;
          _sum[targetKey]+=target.qtyAllocate; // count all same item allocate qty
        }else{
          if(data.channel&&data.itemNumber){
            let channelCode = data.channel
            let itemNumber = (typeof data.itemNumber == 'number')?data.itemNumber.toString():data.itemNumber;
            let channel = this.allRepos.find(item => item.label === channelCode)
            let item = this.allSkus.find(item => item.name === itemNumber)
            if(channel&&item){
              newRowIdx.push(_value.push({
                channel: data.channel,
                toChannelId: channel.data,
                itemNumber: data.itemNumber,
                itemId: item.code,
                qtyUserInput: data.qtyUserInput?data.qtyUserInput:0,
              })-1) // push (_value.length - 1 after push) to newRowIdx
            }else{
              let _errMsg = ''
              if(!channel){
                _errMsg+=`${_errMsg.length>0?', ':''}Channel ${channelCode} Not Found`
              }
              if(!item){
                _errMsg+=`${_errMsg.length>0?', ':''}Item ${itemNumber} Not Found`
              }
              this.showMessage('warn', this.info.title, `${_errMsg}, ${channelCode} ${itemNumber} Row Insert Failed, Please Try Again.`)
            }
          }
        }
      });

      if(duplicateChannelItem.length>0) {
        // duplicate excel row write message if no other err msg
        excelInvalid = true;
        let limit = 20
        let limitedDuplicateChannelItem = duplicateChannelItem.slice(0, limit)

        let duplicateErr = 'Data import failed due to duplicated rows found in excel file. </br>' + limitedDuplicateChannelItem.toString().replace(/,/g, '</br>')
        if(duplicateChannelItem.length > limit) duplicateErr += `</br>(Total: ${duplicateChannelItem.length}, displayed: ${limitedDuplicateChannelItem.length})`
        this.showMessage('error', this.info.title, `${duplicateErr}`, true)
      }

      if(errMsg){
        this.showMessage('error', this.info.title, `${this.info.importFail}, ${errMsg}`)
        // return
      }

      if(excelInvalid) return

      let errorItemCode;
      let warehouseQtyInvalid = keyList.some(key=>{
        let res = _sum[key] > _max[key]
        if(res) errorItemCode = key;
        return res;
      })
      if(warehouseQtyInvalid){
        this.showMessage('warn', this.info.title, `${this.info.importFail}, Item ${errorItemCode} (total: ${_sum[errorItemCode]}) don't have enough balance in ${warehouse.label} (balance: ${_max[errorItemCode]}), please reduce Additional Qty or change Warehouse.`)
      }else{
        this.spreadsheet.value = [..._value]
        this.demandLineList = [...this.spreadsheet.value]
        if(newRowIdx.length>0){
          // newRowIdx.forEach(idx=>{
          //   this.getLineData(this.spreadsheet.value[idx], {qtyUserInput: this.spreadsheet.value[idx].qtyUserInput, qtyAllocate: this.spreadsheet.value[idx].qtyUserInput})
          // })
          this.getLineDataForMultiRow(newRowIdx)
        }
        this.showMessage('success', this.info.title, this.info.importSuccess)
      }
    }
  }

  searchReservation(){
    // search by date button in popup, reload reservation by to date in popup
    // this action lost all manually unsave ticked item / calculate value
    this.conf.confirm({
      message: 'This action will discard Pre-Allocate change on this item. Are you sure?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {this.resetSelectedItemPreAllocate()},
      reject: () => { },
      key: "preAllocate"
    });
    // this.resetPreAllocate()
  }
  popupLeftItemPanelOnClick(e){
    // popup left tab item onclick
    let that = this;
    if(this.loading){
      // ignore click if loading
      return
    }
    if(that.isNop) {
      this.modalConfig.selectedItem = e.code;
      that.setNopPreAllocateTableData()
      return
    }
    if(this.modalConfig.endDateChanged){
      this.conf.confirm({
        message: 'Reservation To Date Changed, this action will reset Reservation To Date to ' + this.datePipe.transform( this.preAllocateDate, 'YYYY/MM/dd') + '. Are you sure?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          that.rollbackSelectedItemToDefaultDateEmptySelect().subscribe(res=>{
            that.modalConfig.currentDataBuffer = null;
            that.modalConfig.statusBuffer = null;
            that.modalConfig.selectedItem = e.code;
            that.modalConfig.endDateChanged = null
            that.modalConfig.sourceRefNoFilter = null
            that.isNop ? that.setNopPreAllocateTableData() : that.setPreAllocateTableData()
          })
        },
        reject: () => { },
        key: "preAllocate"
      });
    }else if(this.modalConfig.currentDataBuffer){
      this.conf.confirm({
        message: 'Your change is not saved, this action will discard Pre-Allocate change on this item. Are you sure?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          that.resetRawData().subscribe(res=>{
            that.modalConfig.currentDataBuffer = null;
            that.modalConfig.statusBuffer = null;
            that.modalConfig.selectedItem = e.code;
            that.modalConfig.endDateChanged = null
            that.modalConfig.sourceRefNoFilter = null
            that.isNop ? that.setNopPreAllocateTableData() : that.setPreAllocateTableData()
          })
        },
        reject: () => { },
        key: "preAllocate"
      });
    }else{
      this.modalConfig.currentDataBuffer = null;
      that.modalConfig.statusBuffer = null;
      this.modalConfig.selectedItem = e.code;
      that.modalConfig.endDateChanged = null
      that.modalConfig.sourceRefNoFilter = null
      that.isNop ? that.setNopPreAllocateTableData() : that.setPreAllocateTableData()
    }
  }
  resetRawData(){
    return of(null).pipe(switchMap(res=>{
      // if(!this.modalConfig.endDateChanged){
        if(this.modalConfig.currentDataBuffer){
          let idx = this.modalConfig.table.rawData.findIndex(data=>data.itemCode == this.modalConfig.selectedItem)
          this.modalConfig.table.rawData[idx] = this.modalConfig.currentDataBuffer
        }
        let itemCode = this.modalConfig.selectedItem
        if(this.modalConfig.statusBuffer != null){
          // reset changedItemCodeList, savedItemCodeList if manual changed
          switch(this.modalConfig.statusBuffer){
            case 'saved':
              if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1){
                this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(itemCode),1)
              }
              if(this.modalConfig.savedItemCodeList.indexOf(itemCode)==-1){
                this.modalConfig.savedItemCodeList.push(itemCode)
              }
              break;
            case 'changed':
              if(this.modalConfig.changedItemCodeList.indexOf(itemCode)==-1){
                this.modalConfig.changedItemCodeList.push(itemCode)
              }
              if(this.modalConfig.savedItemCodeList.indexOf(itemCode)>-1){
                this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(itemCode),1)
              }
              break;
            case 'normal':
              if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1){
                this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(itemCode),1)
              }
              if(this.modalConfig.savedItemCodeList.indexOf(itemCode)>-1){
                this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(itemCode),1)
              }
              break;
          }
        }
        return of(null)
      // }else{
      //   // end date changed, old date removed, reset to t-1 data
      //   return this.resetItemObs()
      // }
      // return of()
    }))
  }

  resetToDate(){
    return this.resetItemObs(this.preAllocateDate)
  }
  calculateAll(){
    // calculate all unchange items pre allocate value
    // get unchange item code
    let calculateCodeList = []
    this.modalConfig.table.rawData.forEach(rawData => {
      // changedItemCodeList set by setPreAllocateItems, qtyPlanToAllocate > 0 items
      if(this.modalConfig.changedItemCodeList.indexOf(rawData.itemCode)==-1 &&
        this.modalConfig.savedItemCodeList.indexOf(rawData.itemCode)==-1
      ){
        calculateCodeList.push(rawData.itemCode)
      }
    });

    this.loading = true
    this.tableData.loadingSwitch = true
    let data = {
      replenishId: this.getDemandLine().id,
      demandCycleType: this.demandData.demandCycleType,
      reservationEndDate: this.formatDate(this.preAllocateDate),
      itemCodeList: calculateCodeList
    }

    this.service.fetchPreAllocateWithItem(data).subscribe(res => {
      if(res.code === '000') {
        this.modalConfig.table.rawData = this.mapDataByItemCode(this.modalConfig.table.rawData, res.data);
        this.setPreAllocateTableData()
        res.data.forEach(_resData => {
          let haveQtyPlanToAllocateNotZero = _resData.reservationsList.some(element => {
            return element.qtyPlanToAllocate > 0
          });
          if(haveQtyPlanToAllocateNotZero && this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode)==-1){
            this.modalConfig.changedItemCodeList.push(_resData.itemCode)
          }
          if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
            this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode), 1)
          }
        });
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
      this.loading = false
      this.tableData.loadingSwitch = false
    })
  }

  saveItem(){
    this.saveItemPreAllocateObs().subscribe()
    // const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    // let data = {
    //   replenishId: replenishmentLine.id,
    //   reservationEndDate:  this.formatDate(this.modalConfig._currentDataPreAllocateDate),
    //   list: this.getPreAllocateData(this.modalConfig.table.rawData.filter(_data=>_data.itemCode == this.modalConfig.selectedItem)),
    //   itemCode: this.modalConfig.selectedItem,
    //   // set preAllocateQty if user direct click save item after calculate, set this field to null when user manually tick / untick
    //   preAllocateQty: this.modalConfig.changeCountList[this.modalConfig.selectedItem]?this.modalConfig.changeCountList[this.modalConfig.selectedItem]:null
    // }
    // this.loading = true
    // this.tableData.loadingSwitch = true
    // this.service.savePreAllocate(data).subscribe(res => {
    //   this.loading = false
    //   this.tableData.loadingSwitch = false
    //   if (res.code === '000') {
    //     // this.modalConfig.savedItemCodeList.push(this.modalConfig.selectedItem)
    //     let itemCode = this.modalConfig.selectedItem
    //     if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1){
    //       this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(itemCode),1)
    //     }
    //     if(this.modalConfig.savedItemCodeList.indexOf(itemCode)==-1){
    //       this.modalConfig.savedItemCodeList.push(itemCode)
    //     }
    //     this.modalConfig.currentDataBuffer = null;
    //     this.modalConfig.statusBuffer = null;
    //     this.modalConfig.endDateChanged = null
    //     this.showMessage('success', this.info.title, res.msg || this.info.success.replace('Saved', 'Change Pre Allocate'))
    //     this.getLines(true, true, true) // load replenishment line new value
    //   } else {
    //     this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Change Pre Allocate'))
    //   }
    // })
  }
  getNopList() {
    return this.modalConfig.table.rawData[0].reservationsList
  }
  getNormalList() {
    return  this.getPreAllocateData(this.modalConfig.table.rawData.filter(_data=>_data.itemCode == this.modalConfig.selectedItem))
  }
  saveItemPreAllocateObs(){
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    let data = {
      replenishId: replenishmentLine.id,
      reservationEndDate:  this.formatDate(this.modalConfig._currentDataPreAllocateDate),
      list: this.isNop ? this.getNopList() :  this.getNormalList(),
      itemCode: [this.modalConfig.selectedItem],
      // set preAllocateQty if user direct click save item after calculate, set this field to null when user manually tick / untick
      preAllocateQty: this.modalConfig.changeCountList[this.modalConfig.selectedItem]?this.modalConfig.changeCountList[this.modalConfig.selectedItem]:null
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    return this.service.savePreAllocate(data).pipe(map(res=>{
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        // this.modalConfig.savedItemCodeList.push(this.modalConfig.selectedItem)
        let itemCode = this.modalConfig.selectedItem
        if(this.modalConfig.changedItemCodeList.indexOf(itemCode)>-1){
          this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(itemCode),1)
        }
        if(this.modalConfig.savedItemCodeList.indexOf(itemCode)==-1){
          this.modalConfig.savedItemCodeList.push(itemCode)
        }
        this.modalConfig.currentDataBuffer = null;
        this.modalConfig.statusBuffer = null;
        this.modalConfig.endDateChanged = null
        this.showMessage('success', this.info.title, res.msg || this.info.success.replace('Saved', 'Change Pre Allocate'))
        this.getLines(true, true, true) // load replenishment line new value
        return res
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Change Pre Allocate'))
        return null
      }
    }))
  }

  cancelPreAllocate(){
    let haveUnSaveItem = this.modalConfig.changedItemCodeList.some((CCode)=>{
      if(this.modalConfig.savedItemCodeList.indexOf(CCode)==-1){
        return true
      }else{
        return false
      }
    })
    if(haveUnSaveItem){
      this.conf.confirm({
        message: 'Found unsaved item, are you sure to cancel?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {this.setModalVisible(false)},
        reject: () => { },
        key: "preAllocate"
      });
    }else{
      this.setModalVisible(false)
    }
  }
  resetItem(){
    // this.loading = true
    // this.tableData.loadingSwitch = true
    // let data = {
    //   replenishId: this.getDemandLine().id,
    //   demandCycleType: this.demandData.demandCycleType,
    //   reservationEndDate: this.formatDate(this.modalConfig._currentDataPreAllocateDate),
    //   itemCode: this.modalConfig.selectedItem,
    //   allocateCount: 0
    // }
    // this.service.fetchPreAllocateWithItem(data).subscribe(res => {
    //   if(res.code === '000') {
    //     res.data[0].allocateCount = null; // set to null to set calculate input to empty
    //     res.data[0].reservationEndDate = this.modalConfig._currentDataPreAllocateDate
    //     this.modalConfig.table.rawData = this.mapDataByItemCode(this.modalConfig.table.rawData, res.data);
    //     this.setPreAllocateTableData()
    //     res.data.forEach(_resData => {
    //       if(this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode)>-1){
    //         this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode),1)
    //       }
    //       if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
    //         this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode),1)
    //       }
    //     });
    //   }
    //   this.loading = false
    //   this.tableData.loadingSwitch = false
    // })
    this.resetItemObs().subscribe()
  }
  resetItemObs(todate = null){
    this.loading = true
    this.tableData.loadingSwitch = true
    if(todate == null){
      todate = this.modalConfig._currentDataPreAllocateDate
    }
    let data = {
      replenishId: this.getDemandLine().id,
      demandCycleType: this.demandData.demandCycleType,
      reservationEndDate: this.formatDate(todate),
      itemCode: this.modalConfig.selectedItem,
      allocateCount: 0
    }
    return this.service.fetchPreAllocateWithItem(data).pipe(tap((res) => {
      if(res.code === '000') {
        res.data[0].allocateCount = null; // set to null to set calculate input to empty
        res.data[0].reservationEndDate = todate
        this.modalConfig.table.rawData = this.mapDataByItemCode(this.modalConfig.table.rawData, res.data);
        this.setPreAllocateTableData()
        res.data.forEach(_resData => {
          if(this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode)>-1){
            this.modalConfig.changedItemCodeList.splice(this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode),1)
          }
          if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
            this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode),1)
          }
        });
      }
      this.loading = false
      this.tableData.loadingSwitch = false
    }))
  }

  resetModalConfigField(){
    this.modalConfig.currentDataBuffer = null;
    this.modalConfig.statusBuffer = null;
    this.modalConfig.endDateChanged = null
    this.modalConfig.filteredCodeList = [];
    this.modalConfig.leftPanelFilterText = null
  }

  leftPanelFilter(e){
    if(e&&e.length){
      let _e = e.toLowerCase()
      let _list = this.modalConfig.items.filter(item=>item.name.toLowerCase().indexOf(_e)>-1).map(item=>item.code)
      this.modalConfig.filteredCodeList = _list?_list:[]
    }else{
      this.modalConfig.filteredCodeList = [];
    }
  }

  preAllocateTableSourceRefNoFilter(){
    let e = this.modalConfig?.sourceRefNoFilter?.toLowerCase()
    this.modalConfig.filteredIdList = this.modalConfig.sourceRefNoFilter&&this.modalConfig.sourceRefNoFilter.length>0?
      this.modalConfig.table.data.filter(data=>{
        return data.sourceTxnRefHeaderNo.toLowerCase().indexOf(e)>-1
      }).map(data=>data.id)
      :[];
    this.OnTableScoll();
  }
  OnTableScoll(){
    let scollTarget =((<HTMLElement>(this.preAllocateCommonComponent.ptable).el.nativeElement).querySelectorAll(".p-datatable .p-datatable-tbody tr.filtered"))
    scollTarget[0].scrollIntoView();
  }
  getReservationType(){
    this.reservationType = LocalStorageHelper.getObject('RESSTOCKRESERVATIONTYPE')
  }

  removePreAllocate(){
    this.removePreAllocateObs().subscribe(res=>{
      this.setModalVisible(false);
      this.getLines()
    })
  }
  removeReplenishment(){
    this.removeReplenishmentObs().subscribe()
  }
  removeReplenishmentLine(){
    this.removeReplenishmentLineObs().subscribe()
  }

  removePreAllocateObs(){
    if(
      this.preAllocateReplenishmentCreated&&
      // this.preAllocateCreated&&
      this.replenishId!=null
    ){
      this.loading = true
      this.tableData.loadingSwitch = true
      return this.service.deletePreAllocate({replenishId:this.replenishId}).pipe(tap(res=>{
        this.preAllocateCreated = false;
        this.loading = false
        this.tableData.loadingSwitch = false
      }))
    }else{
      return of(null)
    }
  }

  removeReplenishmentLineObs(){
    if(this.preAllocateReplenishmentCreated&&this.replenishId!=null){
      return this.service.replenishmentEdit({
        ...this.replenishCreateData,
        id: this.replenishId,
        lines:null
      })
    }else{
      return of(null)
    }
  }

  removeReplenishmentObs(){
    // remove replenishment api also remove line , pre-allocate
    if(this.preAllocateReplenishmentCreated&&this.replenishId!=null){
      return this.service.replenishmentDelete({
        ids:[this.replenishId]
      })
    }else{
      return of(null)
    }
  }

  beforeunload(e){
    if(this.preAllocateReplenishmentCreated){
      this.removeReplenishmentObs().subscribe();
    }
  }
  // ----------  NOP logic module start ----------
  addItemByReservationChannel(){
    this.addItemByReservation(this.CHANNELFLAG_RESERVATION.CHANNEL)
  }
  addItemByReservationWarehouse(){
    this.addItemByReservation(this.CHANNELFLAG_RESERVATION.WAREHOUSE)
  }
  addItemByReservation(channelFlag) {
    if(this.loading) return;
    const data = {
      accountName: this.account,
      channel: channelFlag,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemByReservation(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data)
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }
  setPreAllocateSourceRef() {
    // sourceTxnRefHeaderNo
    let list = this.modalConfig.table.rawData.map(item => item.reservationsList).flat().map(item=>item.sourceTxnRefHeaderNo)
    list = [...new Set(list)].map(item => ({name: item, code: item, id: -1}))
    this.modalConfig.items = list
    this.modalConfig.changedItemCodeList = [];
    this.modalConfig.savedItemCodeList = [];
    this.modalConfig.filteredCodeList = [];
    this.modalConfig.changeCountList = {};
    this.modalConfig.table.rawData.forEach(rawData => {
      // count all qtyPlanToAllocate > 0 item as saved, change color in popup left tab
      // qtyPlanToAllocate can only set one times from save pre allocate api
      let havePlanToAllocate = rawData.reservationsList.some(data=>data.qtyPlanToAllocate>0)
      if(havePlanToAllocate) this.modalConfig.savedItemCodeList.push(rawData.itemCode)
    });
    if(this.modalConfig.visible == false){
      // modal not visible => this code load when open modal, init selectedItem
      this.modalConfig.selectedItem = this.modalConfig?.items[0]?.code||null
    }else{
      // modal visible => this code load by reload / save, selectedItem keep previous value
    }
    this.setNopPreAllocateTableData()
  }
  setNopPreAllocateTableData() {
    let sourceData = this.modalConfig.table.rawData
    let itemsList = sourceData[0].itemsList
    let selectList = sourceData.map(item => item.reservationsList).flat()
      .filter(item=>{
        if(item.sourceTxnRefHeaderNo === this.modalConfig.selectedItem) {
          let sku = itemsList.find(sku => sku.itemCode === item.itemCode)
          item.hasCheckbox =  sku.totalCount - sku.planAllocateQty >= 0
          item.nopItemIdx = itemsList.findIndex(_data=>_data.itemCode == sku.itemCode)
        }
        return item.sourceTxnRefHeaderNo === this.modalConfig.selectedItem
      })
    this.modalConfig.table.data = selectList
    this.setTopItemData(itemsList)
    // this.modalConfig.table.changeCount = selectItem.allocateCount
    // this.modalConfig.schoolNumber = this.schoolNumberIpt.value || null
    // this.modalConfig.preAllocateDate = selectItem.reservationEndDate?new Date(selectItem.reservationEndDate):this.preAllocateDate;
    // this.modalConfig._currentDataPreAllocateDate = new Date(this.modalConfig.preAllocateDate)
    // this.modalConfig.changeCount = this.modalConfig.changeCountList[this.modalConfig.selectedItem]?this.modalConfig.changeCountList[this.modalConfig.selectedItem]:null
    this.changeTick()
    // this.changeTotal(selectItem)
    this.changeColumnNameOfTopTable()
    this.setModalVisible(true)
  }
  changeColumnNameOfTopTable() {
    let qtyBefore = this.modalConfig.nopItem.columns.find(item => item.field === 'totalCount')
    qtyBefore.title = this.info.popupTotalCountPatten.toString().replace('${warehouse}', this.modalConfig.warehouseTitle)
  }
  setTopItemData(list) {
    // top item data
    let dayTime = 24*60*60*1000
    let originDataCodeIdxMapping = {}
    if(this.modalConfig.nopItem.data.length>0){
      this.modalConfig.nopItem.data.forEach((data, idx) => {
        originDataCodeIdxMapping[data.itemCode] = idx
      });
    }
    list.forEach(item => {
      let originPickupToDate = null
      let originPreAllocateQty = null
      if(originDataCodeIdxMapping[item.itemCode]!=null) {
        originPickupToDate = this.modalConfig.nopItem.data[originDataCodeIdxMapping[item.itemCode]].pickupToDate
        originPreAllocateQty = this.modalConfig.nopItem.data[originDataCodeIdxMapping[item.itemCode]].preAllocateQty
      }
      item.pickupFromDate = item.pickupFromDate ? this.datePipe.transform(new Date(item.pickupFromDate), 'yyyy/MM/dd') : ''
      item.pickupToDate = (
        originPickupToDate ||
        // item.pickupToDate ||
        (item.lastPickupDate?this.datePipe.transform(new Date(item.lastPickupDate), 'yyyy/MM/dd'):null) ||
        this.datePipe.transform(new Date(Date.now() - dayTime), 'yyyy/MM/dd')
      )
      item.preAllocateQty = originPreAllocateQty || item.preAllocateQty || item.totalCount
      return item
    });
    this.modalConfig.nopItem.data = list
  }

  fetchDataFromNop(data) {
    this.loading = true
    this.tableData.loadingSwitch = true

    // this.service.deletePreAllocate(data)

    this.service.deletePreAllocate({replenishId:this.replenishId}).pipe(tap(res=>{
      this.preAllocateCreated = false;
      this.getLines(null, false, true);
    }))
    .pipe(switchMap(res=>{
      return this.service.fetchPreAllocateWithItemFromNop(data)
    }))
    // this.service.fetchPreAllocateWithItemFromNop(data)
    .subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        console.log({res});
        // res.data[0].reservationEndDate = _currentDataPreAllocateDate
        this.modalConfig.changedTableData = []; // reset
        this.modalConfig.table.rawData = this.mapDataByItemCode(this.modalConfig.table.rawData, res.data);
        this.setNopPreAllocateTableData()
        // res.data.forEach(_resData => {
        //   let haveQtyPlanToAllocateNotZero = _resData.reservationsList.some(element => {
        //     return element.qtyPlanToAllocate > 0
        //   });
        //   if(
        //     // haveQtyPlanToAllocateNotZero &&
        //     this.modalConfig.changedItemCodeList.indexOf(_resData.itemCode)==-1
        //   ){
        //     this.modalConfig.changedItemCodeList.push(_resData.itemCode)
        //   }
        //   if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
        //     this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode), 1)
        //   }
        //   this.modalConfig.changeCountList[this.modalConfig.selectedItem] = this.modalConfig.changeCount;
        // })

        // nop pre allocate api response differ with normal pre allocate api
        // nop pre allocate response data: [{itemList:[],reservationList:[]}]
        // set changedItemCodeList to all item code to force update all item in replenishment for pre allocate mapping when save pre allocate

        let _resData = res.data[0]
        this.preAllocateAllItemCode = _resData.itemsList.map(item=>item.itemCode)
        _resData.itemsList.forEach(item => {
          if(
            this.modalConfig.changedItemCodeList.indexOf(item.itemCode)==-1
          ){
            this.modalConfig.changedItemCodeList.push(item.itemCode)
          }

          if(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode)>-1){
            this.modalConfig.savedItemCodeList.splice(this.modalConfig.savedItemCodeList.indexOf(_resData.itemCode), 1)
          }
        });
        //让左侧sourceTxnRefHeaderNo栏变色
        let matchingsourceTxnRefHeaderNo = this.nopOrderWhetherFullyPreAllocated(_resData.reservationsList)
        _resData.reservationsList.forEach(reservation => {
          if(
            this.modalConfig.changedItemCodeList.indexOf(reservation.sourceTxnRefHeaderNo)==-1 && matchingsourceTxnRefHeaderNo.indexOf(reservation.sourceTxnRefHeaderNo) >= 0
          ){
            this.modalConfig.changedItemCodeList.push(reservation.sourceTxnRefHeaderNo)
          }
        });
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
    })
  }

  nopOrderWhetherFullyPreAllocated(reservationsList) {
    // 使用Map来跟踪每个订单号的预订和销售总和
    let orderSums = new Map()
    // 遍历reservation列表，累加每个sourceTxnRefHeaderNo的qtyPending和qtyPlanToAllocate
    reservationsList.forEach(reservation => {
    let sourceTxnRefHeaderNo = reservation.sourceTxnRefHeaderNo;
      if (!orderSums.has(sourceTxnRefHeaderNo)) {
        orderSums.set(sourceTxnRefHeaderNo, { qtyPending: 0, qtyPlanToAllocate: 0 })
      }
      let sum = orderSums.get(sourceTxnRefHeaderNo);
      sum.qtyPending += reservation.qtyPending;
      sum.qtyPlanToAllocate += reservation.qtyPlanToAllocate
      orderSums.set(sourceTxnRefHeaderNo, sum)
    });
    // 找出预订数量和销售数量相等的订单号
    let matchingsourceTxnRefHeaderNo = [];
    orderSums.forEach((sum, sourceTxnRefHeaderNo) => {
      if (sum.qtyPending === sum.qtyPlanToAllocate) {
        matchingsourceTxnRefHeaderNo.push(sourceTxnRefHeaderNo)
      }
    })
      return  matchingsourceTxnRefHeaderNo
    }
 // ---------- NOP logic module end ----------
  onOverlayShow(){
    //id
    // this.spreadsheetComponent.spreadSheet
    /* try{
      //if PRIMENG updated this may error,please delete it.
     (<OverlayPanel>this.overlayPanel['overlay']).isContainerClicked = false
    }catch(ex){
      console.error(ex);
    } */
    let inputEl = (<HTMLElement>(<HTMLElement>(<OverlayPanel>this.overlayPanel['overlay']).el.nativeElement).getElementsByClassName('SKUFilter')[0])
    let inputEl2= (<HTMLElement>(<HTMLElement>(<OverlayPanel>this.overlayPanel['overlay']).el.nativeElement).getElementsByClassName("REPOFilter")[0])
    if(this.overlayName==='sku'&&inputEl){
      // item number
      /* setTimeout(() => { */
        inputEl.click()
        inputEl.focus({preventScroll:true})
     /*  }, 0); */
      this.skusCount=0; // reset when exit item number listbox
    }

    if(this.overlayName==="repo"&&inputEl2){
      // channel
     /*  setTimeout(() => { */
        inputEl2.click()
        inputEl2.focus({preventScroll:true})
     /*  }, 0); */
      this.reposCount=0; // reset when exit channel listbox
    }
  }

  onOptionKeyDown(event: KeyboardEvent) {
    let item = <HTMLLIElement>event.currentTarget;
    switch (event.key) {
      //down
      case "ArrowDown":
        this.OnDown();
        event.preventDefault();
        break;

      //up
      case "ArrowUp":
        this.OnUp();
        event.preventDefault();
        break;

      //enter
      case "Enter":
        this.OnEnter();
        break;
      //esc
      case "Escape":
        this.OnEsc();
        break;
    }
  }
  OnUp() {
    //Up key
    if (this.overlayName === "sku") {
      this.skusCount--;
      if (this.skusCount < 0) {
        this.skusCount = 0;
        this.skusCount = this.skus.length - 1; //down to bottom when user select top of the item number listbox
        if (this.skus.length) {
          this.skusSelect = this.skus[this.skusCount].code;
          // this.OnListboxScoll(this.skus[this.skusCount].name);
          this.virtualScrollToIndex(this.skusCount)
        }
      } else {
        this.skusSelect = this.skus[this.skusCount].code;
        // this.OnListboxScoll(this.skus[this.skusCount].name);
        this.virtualScrollToIndex(this.skusCount)
      }
    }

    if (this.overlayName === "repo") {
      this.reposCount--;
      if (this.reposCount < 0) {
        this.reposCount = 0;
        this.reposCount = this.repos.length - 1; //down to bottom when user select top of the channel listbox
        if (this.repos.length) {
          this.reposSelect = this.repos[this.reposCount].data;
          this.OnListboxScoll(this.repos[this.reposCount].label);
        }
      } else {
        this.reposSelect = this.repos[this.reposCount].data;
        this.OnListboxScoll(this.repos[this.reposCount].label);
      }
    }
  }

  OnDown() {
    //Down key
    if (this.overlayName === "sku") {
      this.skusCount++;
      if (this.skusCount >= this.skus.length) {
        this.skusCount = 0; //reset when down to bottom of the item number listbox
        if (this.skus.length) {
          this.skusSelect = this.skus[this.skusCount].code;
          // this.OnListboxScoll(this.skus[this.skusCount].name);
          this.virtualScrollToIndex(this.skusCount)
        }
      } else {
        this.skusSelect = this.skus[this.skusCount].code;
        // this.OnListboxScoll(this.skus[this.skusCount].name);
        this.virtualScrollToIndex(this.skusCount)
      }
    }
    if (this.overlayName === "repo") {
      this.reposCount++;
      if (this.reposCount >= this.repos.length) {
        this.reposCount = 0; //reset when down to bottom of the channel listbox
        if (this.repos.length) {
          this.reposSelect = this.repos[this.reposCount].data;
          this.OnListboxScoll(this.repos[this.reposCount].label);
        }
      } else {
        this.reposSelect = this.repos[this.reposCount].data;
        this.OnListboxScoll(this.repos[this.reposCount].label);
      }
    }
  }
  OnEnter() {
    //Enter key
    let eventForEnter = <OverlayPanel>this.overlayPanel["overlay"];
    if (this.overlayName === "sku") {
      if (this.skusCount < 0) {
        this.skusCount = 0;
      }
      if (this.skus.length) {
        //build object have value to store sku code for changeSku fumction
        let skudata = {
          value: this.skus[this.skusCount].code,
        };
        this.changeSku(skudata, eventForEnter);
      }
    }
    if (this.overlayName === "repo") {
      if (this.reposCount < 0) {
        this.reposCount = 0;
      }
      if (this.repos.length) {
        //build object have value to store repos label for changeSku fumction
        let repodata = {
          value: this.repos[this.reposCount].data,
        };
        this.changeRepo(repodata, eventForEnter);
      }
    }
  }
  OnEsc() {
    // ESC KEY
    let eventForEsc = <OverlayPanel>this.overlayPanel["overlay"];
    eventForEsc.hide();
    this.spreadsheetComponent.forceSelectSelectedCell();
  }
  OnListboxScoll(AriaLabel){
    let scollTarget =((<HTMLElement>(<OverlayPanel>this.overlayPanel['overlay']).el.nativeElement).querySelectorAll("li[aria-label='"+AriaLabel+"'],div[aria-label='"+AriaLabel+"']"))
    if(scollTarget.length===1){
      scollTarget[0].scrollIntoView({block: "nearest", inline: "nearest"});
    }
  }
  virtualScrollToIndex(idx){
    let height = 250
    let itemHeight = 40
    let viewport = this.getOverlayVirtualScrollerFn()?.viewport
    let viewportEl = viewport.elementRef.nativeElement
    let scrollTop = viewportEl.scrollTop
    let scrollBottom = scrollTop + height
    let targetTop = idx * itemHeight
    let targetBottom = targetTop + itemHeight
    if(targetTop < scrollTop){
      viewport.scrollToOffset(targetTop)
    }
    if(targetBottom > scrollBottom){
      viewport.scrollToOffset(targetBottom - height) // ?.scrollToIndex(idx)
    }
  }

  // load add item by bu options
  getItemMasterColumn(){
    return this.stockCommonService.getItemMasterColumn().pipe(map(res => {
      if(res.code === '000'){
        // res.data.forEach((item) => {
        //   this.buCodes.push({name: item,  value: item})
        // });
        if(res.data[0]){
          let data = res.data[0];
          this.buCodes = data['buCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.lobCodes = data['lobCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.mainCats = data['mainCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subCats =data['subCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subSubCat = data['subSubCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.mfgBrand = data['mfgBrand']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
        }
        return res.data
      }else{
        return null;
      }
    }))
  }

  addItemByReservationForBYOD() {
    if(this.loading) return;
    // const data = {
    //   accountName: this.account,
    // }
    const schNum = this.schoolNumberIpt.value
    const channelId = this.extendChannelIpt.value?.id
    if(!schNum || schNum.length==0 || channelId==null){
      this.showMessage('warn', this.info.title,  'Channel, School Code / Location ID is required')
      return
    }
    const data = {
      // schCode,
      // schLocCode,
      schNum,
      channelId: channelId,
      demandCycleId: this.demandData.demandCycleId,
      accountName: this.account,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemBySchoolCode(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data)
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }

  setPreAllocatePopupTable(){
    this.modalConfig.table.columns = [
      {field: 'reservationDate', title: 'Reservation Date', width: '10rem'},
      {field: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '10rem'},
      {field: 'itemCode', title: 'Item Code', width: '8rem'},
      {field: 'itemDesc', title: 'Description', width: '16rem'},
      {field: 'reservationType', title: 'Reservation Type', width: '10rem'},
      {field: 'channelCode', title: 'Release Channel', width: '10rem'},
      {field: 'qtyRequested', title: 'Request Reserve Qty', width: '11rem'},
      {field: 'qtyPending', title: 'Pending Qty', width: '8rem'},
      {field: 'qtyPlanToAllocate', title: 'Plan Allocate Qty', width: '11rem'},
      {field: 'qtyCancelled', title: 'Cancel Qty', width: '8rem'},
      {field: 'sensitiveRemarks', title: 'Remarks', width: '15rem'},
      {field: 'id', title: 'Reservation ID', width: '9rem'},
      {field: 'sourceSystem', title: 'Source System', width: '10rem'},
      {field: 'sourceTxnRefHeaderId', title: 'Source Ref. Id', width: '12rem'},
      {field: 'createDate', title: 'Create Date', width: '8rem'},
      ...!this.isNop?[{field: 'unallocatedDate', title: 'Unallocate Date', width: '10rem', type: 'date'}]:[],
      {field: 'updateDate', title: 'Update Date', width: '8rem'},
    ]
  }
}
