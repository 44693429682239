import {INPUT_TYPE, BUTTON_TYPE, NOTI_TITLE, NOTI_MSG, URLDICT} from '../../base/BaseStore'
import { Inject, Injectable } from '@angular/core';
import {BaseSearchStoreForQuota} from "../../base/BaseSearchStoreForQuota";
import { FUNCTION_CODE } from '@/service/common/permission.type';

@Injectable()
export class SearchStore extends BaseSearchStoreForQuota {
  constructor(@Inject('object') params) {
    var queryPannel = {
      ipts:[
        {
          title: "Channel",
          name: 'channelId',
          placeholder: 'Channel',
          type: INPUT_TYPE.TREESELECT,
          options: "REPOTREE",
          expandKeys: ['0'],
          class: "p-col-12 p-md-4 p-lg-3",
          required: false
        },
        {
          title: "Item",
          name: 'itemCode',
          placeholder: 'Item',
          type: INPUT_TYPE.MUTIPLESELECT,
          options: "SKU",
          dataKey: 'code',
          skipAllCheckMatch: true,
          class: "p-col-12 p-md-4 p-lg-3",
          required: false
        }],
      btnsclass: "p-d-flex p-col-12 p-md-4 p-lg-6",
      btns: [{
          type: BUTTON_TYPE.BUTTON,
          title: 'Search',
          icon: '',
          show: true,
          class: "p-order-1",
          handler: {
            "click": () => {
              // this.search(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_SEARCH)
              this.searchFromFilter()
            }
          }
      },{
          type: BUTTON_TYPE.BUTTON,
          title: 'Clear',
          show: true,
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          handler: {
            'click': () => {
              this.clear(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_SEARCH)
            }
          }
      }]
    }
    var addPannel = {
      ipts:[
        {
          colspan: 8,
          title: "Channel",
          name: 'channelId',
          linkageToItem: true,
          type: INPUT_TYPE.TREESELECT,
          options: "REPOMODULEBYUAMTREE",
          class: "p-col-12 p-md-4 p-lg-3",
          value: null
        },
        {
          colspan: 8,
          title: "Item",
          name: 'itemCode',
          type: INPUT_TYPE.MUTIPLESELECT,
          options: [],
          dataKey: 'code',
          skipAllCheckMatch: true,
          class: "p-col-12 p-md-4 p-lg-3",
          value: []
        },
        {
          colspan: 8,
          title:"UOM",
          name:'uom',
          placeholder:'UOM',
          defaultValue:'!!',
          type:INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          options:[{code:"EA",name:"EA"}],
          optionLabel: 'name',
          optionValue: 'code',
        },
        {
          colspan: 12,
          title:"Max Trans.Qty Per Day",
          name:'maxTransQtyPerDay',
          class: "p-col-12 p-md-4 p-lg-2",
          placeholder:'',
          type:INPUT_TYPE.INPUT
        }],
      btnsclass: "p-d-flex p-col-12 p-md-8 p-lg-1",
      btns:[{
        type:BUTTON_TYPE.BUTTON,
        title:'Add',
        class: "p-mr-1 p-ml-auto",
        handler:{
          "click":()=>{
            this.create(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_CREATE)
          }
        }
      }],
      show:false
    }
    var tablePannel = {
      // selectedProducts: [],
      btns:[
        {
        type:BUTTON_TYPE.BUTTON,
        title:'Edit',
        show: true,
        handler:{
          'click':()=>{
            this.edit(['main','item_transfer_quota','edit'])
          }
        }
      },{
        type:BUTTON_TYPE.BUTTON,
        title:'Delete',
        show: true,
        handler:{
          'click':()=>{
            this.delete(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_DELETE)
          }
        }
      }],
      data: [],
      head:[
        {key:'repoCode',title:'Channel'},
        {key:'skuCode',title:'Item'},
        {key:'uom',title:'UOM'},
        {key:'maxTransQtyPerDay',title:'Max Trans.Qty Per Day'}
        ],
      //detailUrl:["main","stock_threshold","detail"],
      sortSearchUrl:URLDICT.CONFIG_ITEM_TRANSQUOTA_SORTSEARCH
    }

    super(queryPannel,tablePannel,addPannel,params);
    try{
    this.search(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_SEARCH).then(()=>this.changeTableSize())
    }catch(e){console.error(e)}
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  page(e){
    // console.log(e);s
    this.pageIndex=e.first/e.rows
    this.pageSize=e.rows
    if(this.isSortSearch) this.sortPageSearch(this.sortSearchUrl,this.field)
    else this.search(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_SEARCH)
    this.resetSearch = false
  }

  delete(url) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    }
    // if(this.judgeIsActive(obj)==false){
      //模态框确定
      this.$MODAL.confirm({
        message: `Do you want to delete these ${len} items?`,
        header: 'Delete Confirmation',
        accept: () => {
          postDeleteRequest();
        }
      });
    // }
    //删除请求
    const postDeleteRequest = () => {
      this.tablePannel.setLoading(true)
      //请求API
      this.$HTTP.post(url, obj)
        .then(res => {
          var data = []
          this.tablePannel.data.forEach((d, index) => {
            if (!this.tablePannel.mapOfCheckedId[d.id]) {
              data.push(d)
            }
          })
          //清空选中并重赋数据源
          this.tablePannel.selectedProducts = []
          this.tablePannel.setData(data)
          this.tablePannel.display_data = this.toTreeData(data,this.tablePannel)
          this.tablePannel.allCheckBoxValue = false
          this.tablePannel.mapOfCheckedId = {}
        })
        .catch(err => {
          this.$NOTICE.error(NOTI_TITLE, err)
          this.showMessage('error', NOTI_TITLE, err);
        })
        .finally(() => {
          this.tablePannel.setLoading(false)
          // if(u != null){
          //   this.loadToCache(u,m)
          // }
        })
    }
  }
  create(url, index = 0) {
    let dataCreate = []
    var data = this.addPannel.format()
    if (data.itemCode.length < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, "No items!");
      this.showMessage('warn', NOTI_TITLE, 'No items!');
      return
    }
    data.itemCode.forEach((item) => {
      dataCreate.push({
        channelId: data.channelId,
        itemCode: item.code,
        uom: data.uom,
        maxTransQtyPerDay: data.maxTransQtyPerDay
      })
    })

    this.$HTTP.post(url, dataCreate)
      .then(res => {
        if (res.code == '000' && res.data.length > 0){
          // this.$NOTICE.success(NOTI_TITLE, "Create Successful")
          this.showMessage('success', NOTI_TITLE, 'Create Successful');
          this.search(this.$URLDICT.CONFIG_ITEM_TRANSQUOTA_SEARCH)
          this.resetValue()
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        // this.queryPannel.setBtnProperty(index, "loading", false)
        // this.tablePannel.setLoading(false)
      })
  }

  private resetValue() {
    this.addPannel.clear()
  }
  showMessage(severity, summary, detail){
    this.$NOTICE.add({severity, summary, detail});
  }
  setPermission(...e){
    let permissionService = this.$COMMONSTORE.permissionService
    this.addPannel && this.addPannel.btns.length>0 && this.addPannel.btns.forEach(item=>{
      item.title=='Add' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.ITEM_TRANSFER_QUOTA_EDIT))
      item.title=='Edit' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.ITEM_TRANSFER_QUOTA_EDIT))
      item.title=='Delete' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.ITEM_TRANSFER_QUOTA_EDIT))
    })
  }
}

