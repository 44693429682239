import { Injectable } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { from } from 'rxjs';
import { URLDICT } from 'src/app/stores/base/BaseStore';
import { HttpHelper } from 'src/app/util/HttpHelper';

@Injectable({
  providedIn: 'root'
})
export class StockBalanceService {
  TITLE = {
    CHANNEL: "Channel",
    ITEM: "Item",
    LIS_RT: "Include LIS RT Stock Balance",
  }

  BUTTON_TITLE = {
    SEARCH: "Search",
    CLEAR: "Clear",
  }

  info = {
    title: 'Cross Shop Pickup Quota',
    confirmSave: 'Are you sure you want to save the changes?',
    confirmCancel: 'Are you sure you want to cancel the modification?',
    confirmDelete: 'Are you sure you want to delete this data?',
    noItems: 'The selected channel has no associated items!',
    reqiuired: 'This is a required!',
    noChange: 'The data has not been modified and does not need to be saved!',
    coexist: 'After selecting channel, you must select item!',
    success: 'Created successfully!',
    failed: 'The operation failed'
  }
  constructor(private msg: MessageService, private conf: ConfirmationService) { }

  searchBalance(data) {
    return from(HttpHelper.post(URLDICT.STOCK_CURD_SEARCH, data))
  }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  showDialog(msg, data, self,  callback) {
    this.conf.confirm({
      message: msg,
      header: this.info.title,
      accept: () => self[callback](data)
    });
  }
}
