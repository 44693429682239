import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @description: 该组件是select下拉选组件（多选）
 * @author: 陈述承
 */
@Component({
  selector: 'app-select-multiple-cst',
  templateUrl: './select-multiple-cst.component.html',
  styles:[]
})
export class SelectMultipleCustom {
    filter = false
    @Input() ipt
    @Output() change = new EventEmitter()
    onChange(v){
      this.ipt.error = null
      this.change.emit(v.value)
    }
    onPanelShow(){
      this.filter = true
    }
    onPanelHide(){
      this.filter = false
    }
}
