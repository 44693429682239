import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { URLDICT } from 'src/app/stores/base/BaseStore';
import { HttpHelper } from 'src/app/util/HttpHelper';
import {CommonService} from "@/service/common/common-service";

@Injectable({
  providedIn: 'root'
})
export class StockMovementHistoryService {

  constructor(private commonService: CommonService,) { }
  search(data) {
    return from(HttpHelper.post(URLDICT.STOCK_MOVEMENT_HISRORY_SEARCH, data))
  }

  searchForExport(data) {
      return  this.commonService.postForExcelObs(URLDICT.STOCK_MOVEMENT_HISRORY_EXPORT, data)
    }
}
