import {Injectable} from "@angular/core"
import {Router} from "@angular/router"
import {makeAutoObservable, reaction} from "mobx"
import {ConfirmationService, MessageService} from "primeng/api"
import {HttpHelper} from "src/app/util/HttpHelper"
import {LocalStorageHelper} from "src/app/util/LocalStorageHelper"
import {INPUT_TYPE, URLDICT} from "../../base/BaseStore"
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})
export class RequestDetailStore {
  //observable
  queryData = {
    ipts: [
      {
        title: "Reservation ID",
        name: "Reservation ID",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: "Reservation Date",
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      // {
      //   title: "Reservation Status",
      //   type: INPUT_TYPE.SELECT,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: null,
      //   options: [],
      //   iptionLabel: 'name',
      //   iptionValue: 'code',
      // },
      {
        title: "Source System",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Source System Txn Type",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Source Reference No",
        name: 'orderId',
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Reservation Type",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        iptionLabel: 'name',
        iptionValue: 'code',
      },
      {
        title: "Reserved For",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: "Reason",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: []
      },
      {
        title: "Item",
        name: 'item',
        placeholder: 'Choose Item',
        value: null,
        type: INPUT_TYPE.SELECT,
        options: [],
        class: "p-col-12 p-md-4 p-lg-3",
      },
      {
        title: "Description",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: "Request Reserve Qty",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: "Cancel Qty",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Pending Qty",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Expiry Date",
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "No.of Extension",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Allocate Qty",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Release Qty",
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Release Channel",
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        showValue: null,
        value: null,
        error: null,
        options: []
      },
      {
        title: "Remark(Please do not enter any personal information)",
        name: "remark",
        value: '',
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-4 p-lg-6",
      },
    ],
    btns: [
      {
        title: 'Back',
        class: "p-order-2 p-mr-1",
        show: true,
        handler: {click: () => this.back()}
      },
      {
        title: 'Save',
        class: "p-order-4 p-mr-1",
        show: true,
        disabled: false,
        handler: {
          click: () => this.onSubmit()
        }
      },
      {
        title: 'Extend',
        class: "p-order-2 p-mr-1",
        show: true,
        disabled: false,
        handler: {
          click: () => this.onExtend()
        }
      },
      {
        title: 'Allocate',
        class: "p-order-3 p-mr-1",
        show: true,
        disabled: false,
        handler: {
          click: () => this.onAllocate()
        }
      },
      {
        title: 'Cancel',
        class: "p-order-1 p-mr-1",
        show: true,
        disabled: false,
        handler: {
          click: () => this.onCancel()
        }
      }],
    btnsclass: 'p-d-flex p-col-6',
    toggleable: true
  }

  skus = []
  items
  createLine = []
  selectRequest = null
  searchAll = true
  dialogTitle = ""
  resStatusOpts
  logicOpts
  skuList
  reasonPanel
  resTypeOpts
  index = 0
  info = {
    title: "System",
    emptyItem: "Line cannot be empty.",
    errorData: "Data error, please contact administrator",
    notChannelData: "There is data that is not the current channel！",
    totalNumber: "The total number of items in channel is not correct！",
    success: "Request data created successfully!",
    selectLine: "Please select a Line!",
    selectChannel: "Please select a Channel!",
    notChannel: "There is no item in the current channel!",
    duplicateData: "There are duplicate data!",
    isLT0: "Qty must be greater than 0, please correct and upload again！",
    exist: "Record(s) found in the assortment request.",
    empty: "This is an empty excel, please input data and then upload."
  }

  constructor(private router: Router, private msg: MessageService, private conf: ConfirmationService,private i18n: TranslateService) {
    makeAutoObservable(this)
    this.initQueryData()
  }

  get showU() {
    return true
  }

  initQueryData() {
    //Channel
    const channelList = LocalStorageHelper.getObject('REPOTREE')
    const relChanelOpts = this.queryData.ipts.find(item => item.title === 'Release Channel')
    relChanelOpts.options = channelList

    //Reason
    this.reasonPanel = LocalStorageHelper.getObject('RESERVATION_REASON').map(
      (item) => {
        let{code: id, name: description, ...other} = item
        return {code: item.id, name: item.description, ...other}
      }
    )
    this.queryData.ipts.find(item => item.title === 'Reason').options = this.reasonPanel

    //Reservation Type
    this.resTypeOpts = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").filter((item)=> item.isManualEntryAllowed === "Y").map(
      (item) => {
        let{code: id, name: description, ...other} = item
        return {code: item.id, name: item.description, ...other}
    })
    this.queryData.ipts.find(item => item.title === 'Reservation Type').options = this.resTypeOpts

    reaction(
      () => this.selectRequest,
      (data) => {
        this.getItemByChannelIdAndConditionId(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, data.channelId)
        this.loadDetailData(data)
      }
    );
  }

  loadDetailData(data: any) {
    console.log('loadDetailData:', data);
    //row 1
    this.queryData.ipts.find(item => item.title === 'Reservation ID').value = data.id
    this.queryData.ipts.find(item => item.title === 'Reservation Date').value = data.createdDate || new Date()
    this.queryData.ipts.find(item => item.title === 'Source System').value = data.sourceSystem

    //row 2
    this.queryData.ipts.find(item => item.title === 'Source Reference No').value = data.sourceTxnRefHeaderNo
    this.queryData.ipts.find(item => item.title === 'Reservation Type').value = this.resTypeOpts.find(item => item.name === data.reservationType).code
    this.queryData.ipts.find(item => item.title === 'Reserved For').value = ""//data.reserveSourceName
    this.queryData.ipts.find(item => item.title === 'Reason').value = this.reasonPanel.find(item => item.name === data.reason).code

    //row 3
    this.queryData.ipts.find(item => item.title === 'Item').value = data.itemId
    this.queryData.ipts.find(item => item.title === 'Description').value = ""//data.description
    this.queryData.ipts.find(item => item.title === 'Request Reserve Qty').value = data.qtyRequested
    this.queryData.ipts.find(item => item.title === 'Cancel Qty').value = data.qtyCancelled

    //row 4
    this.queryData.ipts.find(item => item.title === 'Pending Qty').value = data.qtyPending
    this.queryData.ipts.find(item => item.title === 'Expiry Date').value = data.endDateActive || new Date()
    this.queryData.ipts.find(item => item.title === 'No.of Extension').value = data.extensionCount
    this.queryData.ipts.find(item => item.title === 'Allocate Qty').value = data.qtyAllocated

    //row 5
    this.queryData.ipts.find(item => item.title === 'Release Qty').value = data.qtyReleased
    this.queryData.ipts.find(item => item.title === 'Release Channel').showValue = data.channelCode
    this.queryData.ipts.find(item => item.title === 'Release Channel').value = data.channelCode
    this.queryData.ipts.find(item => item.name === 'remark').value = data.sensitiveRemarks

    // this.initData()
    // this.queryData.ipts.find(item => item.title === 'Reservation Status').value = this.resStatusOpts.find(item => item.name === data.reservationStatus)
    // this.queryData.ipts.find(item => item.title === 'Allocation Date').value = data.fulfilledDate || new Date()
    // this.queryData.ipts.find(item => item.title === 'Request Channel').value = data.sourceChannelId
    // this.setChannelContent(data.requestChannel)
    // this.queryData.ipts.find(item => item.title === 'Reservation Status').value = this.resStatusOpts.find(item => item.name === data.reservationStatus).code
    // this.queryData.ipts.find(item => item.title === 'Assignment Logic').value = this.logicOpts.find(item => item.name === data.assignmentLogic).code
  }

  // setChannelContent(val) {
  //   const channelObj = this.getQueryByTitle('Request Channel')
  //   const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE')
  //   channelObj.options = repoList
  //   let list = []
  //   repoList.forEach(item => {
  //     list = [...list, item, ...item.children]
  //   });
  //
  //   const result = list.find(item => item.label === val);
  //   // console.log(result);
  //   channelObj.showValue = val
  //   channelObj.value = result
  // }

  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }

  convertDate(d) {
    var timeZone: number = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date: Date = new Date(parseInt(d) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }

  onSubmit() {
    const parmId = this.queryData.ipts.find(item => item.title === 'Reservation ID').value
    const parmOrderDate = new Date((this.queryData.ipts.find(item => item.title === 'Reservation Date').value)).getTime()
    const parmSourceSystem = this.queryData.ipts.find(item => item.title === 'Source System').value

    // const parmSourceRefNo = this.queryData.ipts.find(item => item.title === 'Source Reference No').value
    const parmReservationTypeId = this.queryData.ipts.find(item => item.title === 'Reservation Type').value
    // const parmReservedFor = this.queryData.ipts.find(item => item.title === 'Reserved For').value
    const parmReason = this.queryData.ipts.find(item => item.title === 'Reason').value

    const parmItem = this.queryData.ipts.find(item => item.title === 'Item').value
    const parmDescription = this.queryData.ipts.find(item => item.title === 'Description').value
    const parmQty = this.queryData.ipts.find(item => item.title === 'Request Reserve Qty').value
    const parmCancelQty = this.queryData.ipts.find(item => item.title === 'Cancel Qty').value

    const parmPendingQty = this.queryData.ipts.find(item => item.title === 'Pending Qty').value
    const parmExpiryDate = new Date((this.queryData.ipts.find(item => item.title === 'Expiry Date').value)).getTime()
    // const parmNoOfExtension = this.queryData.ipts.find(item => item.title = 'No.of Extension').value
    const parmAllocateQty = this.queryData.ipts.find(item => item.title === 'Allocate Qty').value

    const parmReleaseQty = this.queryData.ipts.find(item => item.title === 'Release Qty').value
    const parmReleaseChannel = this.queryData.ipts.find(item => item.title === 'Release Channel').value.id
    const parmRemark = this.queryData.ipts.find(item => item.name === 'remark').value


    let data = {
      "id": parmId,
      "reserveDate": parmOrderDate,
      "sourceSystem": parmSourceSystem,
      "sourceRefNo": null, //parmSourceRefNo,
      "reservationTypeId": parmReservationTypeId,
      "reservedFor":null, //parmReservedFor,
      "udlReasonId": parmReason,
      "itemId": parmItem,
      "reserveDescription": parmDescription,
      "qtyRequested": parmQty,
      "qtyPending": parmPendingQty,
      "qtyCancelled": parmCancelQty,
      "qtyAllocated": parmAllocateQty,
      "qtyReleased": parmReleaseQty,
      "autoReleaseDate": parmExpiryDate,
      "reserveChannelId": parmReleaseChannel,
      "extensionCount": 0, //default 0 when create
      "sensitiveRemarks": parmRemark
    }

    HttpHelper.post(URLDICT.STOCK_RESERVATION_CREATE, data).then(res => {
      // console.log('create finishfully', res);
      if (res.code === '000') {
        this.showMessage('success', this.info.title, this.info.success)
        this.router.navigate(["main", 'stock_reservation', 'search']);
      } else {
        this.showMessage('error', this.info.title, res.msg)
      }
    }).catch(e => console.log('error', e)).finally(() => {
      console.log('Create a Researvation completed!')
    })
  }

  getItemByChannelIdAndConditionId(url, channelId){
    if (channelId){
      const data = {channelId: channelId, conditionCode: "1001"}  //default FG now
      HttpHelper.post(url, data).then(res => {
        if(res.code == '000' && res.data.length > 0){
          this.items = []
          res.data.forEach(element => {
            this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc})
          });
          const itemList = this.queryData.ipts.find((item) => item.name == "item");
          itemList.options = this.items;
        } else {
          // this.n.warning("System", "There is no sku in the current channel!")
          this.showMessage('warn','System', 'There is no sku in the current channel!')
          this.items = []
        }
      })
    }
  }

  onCancel() {
    this.conf.confirm({
      message: 'Are you sure you want to cancel the reservation?',
      header: 'Cancel the Reservation',
      accept: () => {
        //TODO •	Reservation status will be changed to "Cancelled"
        // •	Cancelled quantity will be saved in cancelled_qty (new column)
        // •	Validation: cancelled_qty cannot be greater than reserved_qty
        // •	Partial cancellation will be supported in other phase
      }
    });

  }
  onExtend(){
    this.conf.confirm({
      message: 'Exceed the maximum number of extension!',
      header: 'Extend the Reservation',
      accept: () => {
        //TODO •	Reservation status will be changed to "Cancelled"
        // •	Cancelled quantity will be saved in cancelled_qty (new column)
        // •	Validation: cancelled_qty cannot be greater than reserved_qty
        // •	Partial cancellation will be supported in other phase
      }
    });
  }

  onAllocate(){
    //TODO •	Reservation status will be changed to "Cancelled"
    // •	Cancelled quantity will be saved in cancelled_qty (new column)
    // •	Validation: cancelled_qty cannot be greater than reserved_qty
    // •	Partial cancellation will be supported in other phase
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  back() {
    window.history.back()
  }
}
