import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Paginator } from 'primeng/paginator';
import { SearchStore } from 'src/app/stores/stock/demandCycle/SearchStore';

@Component({
  providers: [SearchStore],
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements AfterViewInit {
  // @ViewChild('op', {static:false}) op: OverlayPanel
  @ViewChild('paginator', {static:false}) paginator: Paginator
  constructor(
    public store: SearchStore, 
    public cdRef: ChangeDetectorRef
  ) {
    this.store.cdRef = cdRef
  }
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
    this.store.afterViewInit()
  }
  ngOnDestroy(): void {
    this.store.onDestroy()
  }
  onShowCalender(e, data, field) {
    this.store.showTime = field === 'submissionDeadline' ? true : false
    this.store.currentRow = { data, field }
    // const lastDate = this.getDay(new Date(data[field]))
    // this.store.currentDate = field === 'submissionDeadline' ? new Date(lastDate) : new Date(data[field])
    this.store.currentDate = new Date(data[field])
    if(field === 'submissionDeadline') {
      this.store.originalDate = this.store.currentDate
    }
    // this.op.toggle(e)
  }
  getDay(date) {
    date.setHours(23, 59, 59, 999)
    return date
  }

  getDiff(date1, date2) {
    const diff = date1.getTime() - date2.getTime()
    return Math.floor(diff / (24 * 3600 * 1000))
  }

}
