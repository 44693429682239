import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MobxAngularModule} from 'mobx-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FileUploadModule} from 'primeng/fileupload';
import {OAuthModule} from "angular-oauth2-oidc";
import { MENU } from "./config";
import {CalendarModule} from 'primeng/calendar';

import {QueryPannelComponent} from './pages/base/query-pannel/query-pannel.component'
import {AddPannelComponent} from "./pages/config/item_transfer_quota/add-pannel/add-pannel.component";

import {CommonTablePanelComponent} from "./pages/base/common-table-panel/common-table-panel.component";
import {TablePannelComponent as primeTable} from "./pages/base/primeUI/table-pannel/table-pannel.component";
import {TablePannelComponent as colTable} from "./pages/base/primeUI/colGroup-pannel/table-pannel.component";
import {EditTablePannelComponent as editTable} from "./pages/base/primeUI/edit-table-pannel/edit-table-pannel.component";

import {InputCustom} from "./pages/base/input-cst/input-cst.component";
import {SelectCustom} from "./pages/base/select-cst/select-cst.component";
import {CheckboxCustom} from "./pages/base/checkbox-cst/checkbox-cst.component";
import {RadioCustom} from "./pages/base/radio-cst/radio-cst.component";
import {SelectMultipleCustom} from "./pages/base/select-multiple-cst/select-multiple-cst.component";
import {TextareaCustom} from "./pages/base/textarea-cst/textarea-cst.component";
import {DateTimeCustomComponent} from "./pages/base/datetime-cst/datetime-cst.component";
import {DateRangeCustomComponent} from "./pages/base/daterange-cst/daterange-cst.component";
import {TreeSelectCustom} from "./pages/base/treeselect-cst/treeselect-cst.component";
import {TreeSelectMultipleCustom} from "./pages/base/treeselect-multiple-cst/treeselect-multiple-cst.component";
import {SwitchCustom} from "./pages/base/switch-cst/switch-cst.component";

import {InputnumberCstComponent} from "./pages/base/inputnumber-cst/inputnumber-cst.component";
import {IllustrationComponent} from "./pages/base/illustration/illustration-component";
import {PageNotFoundComponent} from './pages/base/404/page-not-found/page-not-found.component';
import {SelectGroupComponent} from './pages/base/select-group/select-group.component';
import { CompareSerialComponent } from './pages/base/compare-serial/compare-serial.component';
import { InvalidAccountComponent } from './pages/base/invalid-account/invalid-account.component';

import {LoginComponent} from './pages/system/login/login.component';
import {MainComponent} from './pages/main/main.component';
import {LoadingComponent} from "./pages/loading/loading.component";

import {SearchComponent as ItemTransferQuotaComponent_Search} from "./pages/config/item_transfer_quota/search/search.component";
import {EditComponent as ItemTransferQuotaComponent_Edit} from "./pages/config/item_transfer_quota/edit/edit.component";
import {SearchComponent as DayOfCoverComponent_Search} from "./pages/config/day_of_cover/search.component";
import {SearchComponent as Balance_Search} from './pages/stock/balance/search/search.component';
import {IntegrationComponent as History_Integration} from "./pages/stock/movement/integration.component";
import {CreateComponent as Transfer_Order_Create} from "./pages/stock/transfer_order/create/create.component";
import {SearchComponent as Transfer_Order_Search} from './pages/stock/transfer_order/search/search.component';
import {SearchComponent as stockTake_Search} from "./pages/stock/take/search/search.component";
import {CreateComponent as stockTake_Create} from "./pages/stock/take/create/create.component";
import {EditComponent as stockTake_Edit} from "./pages/stock/take/edit/edit.component";
import {TakeDetailComponent} from "./pages/stock/take/detail/detail.component";
import {TakeCheckComponent} from "./pages/stock/take/check/check.component";
import {SearchComponent as Transaction_Summary_Search} from "./pages/stock/transaction_summary/search.component";
import {IntegrationComponent as Reservation_Integration} from "./pages/stock/reservation/integration.component";
import {CreateComponent as Reservation_Integration_Create} from "./pages/stock/reservation/create/create.component";
import { SearchComponent as DemandSearchComponent } from './pages/stock/demand/search/search.component';
import { CreateComponent as DemandCreateComponent } from './pages/stock/demand/create/create.component';
import { SearchComponent as DemandCycleSearchComponent} from './pages/stock/demandCycle/search/search.component';
import { CreateComponent as DemandCycleCreateComponent} from './pages/stock/demandCycle/create/create.component';
import { SearchComponent as ReplenishmentSearchComponent } from './pages/stock/replenishment/search/search.component';
import { CreateComponent as ReplenishmentCreateComponent} from './pages/stock/replenishment/create/create.component';
import { EditComponent as ReplenishmentEditComponent} from './pages/stock/replenishment/edit/edit.component';
import { DetailComponent as ReplenishmentDetailComponent} from './pages/stock/replenishment/detail/detail.component';
import {ToTypePipe} from './pages/stock/reservation/to-type.pipe';
import {ItemComponent} from './pages/stock/item/item.component';
import {AuthService} from "./util/CheckRightHelper";
import { CheckChangePasswordGuard } from './util/CheckChangePasswordRightGuard';
import {SearchComponent as UAM_USERROLE_SEARCH} from "./pages/uam/user_role/search.component";
import {SearchComponent as UAMRoleMapping_Search} from "./pages/uam/rolemapping/search.component";
import {SearchComponent as UAMRole_Search} from "./pages/uam/role/search.component";
import {NoticeComponent} from './pages/notice/notice/notice.component';
import {ShareModule} from './share.module';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TransactionBackendComponent} from './pages/stock/transaction-backend/transaction-backend.component';
import { ConfigurationTablesMaintenanceComponent } from './pages/config/configuration-tables-maintenance/configuration-tables-maintenance.component';
import { CreateComponent as ctm_Create } from './pages/config/configuration-tables-maintenance/create/create.component';
import { CrossShopPickupQuotaComponent } from './pages/config/cross-shop-pickup-quota/cross-shop-pickup-quota.component';
import { StockAvailabilityLevelComponent } from './pages/config/stock-availability-level/stock-availability-level.component';
import {RolemappingResolverService} from './service/uam/rolemapping-resolver.service';
import {ConfigurationTablesMaintenanceResolverService} from './service/channelItemControl/configuration-tables-maintenance-resolver.service';
import {TranslateModule} from "@ngx-translate/core";
import {ResearQueryPannelComponent} from "./pages/base/researQuery-pannel/query-pannel.component";
import { SerialCstComponent } from './pages/base/serial-cst/serial-cst.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxFileDropModule } from 'ngx-file-drop';
import { InputScannerCustomComponent } from './components/input-scanner-cst/input-scanner-cst.component';
import { SpreadsheetComponent } from './components/spreadsheet-table/spreadsheet-table.component';
import { DatePipe } from '@angular/common';
import { ReplenishmentCreateSpreadsheetComponent } from './pages/stock/replenishment/create-spreadsheet/create.component';
import { TransferOrderEditComponent } from './pages/stock/transfer_order/edit/edit.component';
import { ButtonRadioGroupComponent } from './components/button-radio-group/button-radio-group.component';
import { CasAppointmentComponent } from './pages/stock/cas-appointment/cas-appointment.component';
import { AllocationCreateComponent } from './pages/stock/transfer_order/allocate-create/create.component';
import { GetValueByPathComponent } from './components/get-value-by-path/get-value-by-path.component';
import { TableColumnTabsComponent } from './components/table-column-tabs/table-column-tabs.component';
import { DemoTableTabComponent } from './pages/demo/table-tab/table-tab.component';
import { DemoComponent } from './pages/stock/balance/demo/demo.component';
import { PTableCustomDirective } from './components/directives/p-table-custom.directive';
import { AllocationSearchComponent } from './pages/stock/allocation/search/allocation-search.component';
import { TaskComponent } from './pages/task/task.component';
import { PposComponent } from './pages/stock/ppos_daily_stock_movement_history/ppos.component';
import { AccessFunctionComponent } from './pages/uam/access-function/access-function.component';
import { AccessFunctionResolverService } from 'src/app/service/uam/access-function-resolver.service';
import { LisInterfaceComponent } from './pages/stock/lis-interface/lis-interface.component';
import { ReservationInterfaceComponent } from './pages/stock/reservation-interface/reservation-interface.component';
import { PreAllocateLeftItemPanelComponent } from './components/pre-allocate-left-item-panel/pre-allocate-left-item-panel.component';
import { ResizeSensorDirective } from './components/directives/resize-sensor.directive';
import { BalanceResolverService } from 'src/app/service/resolvers/balance-resolver.service';
import { TransactionHistoryResolverService } from 'src/app/service/resolvers/transaction-history-resolver.service';
import { PposResolverService } from 'src/app/service/resolvers/ppos-resolver.service';
import { TakeResolverService } from 'src/app/service/resolvers/take-resolver.service';
import { DemandCycleResolverService } from 'src/app/service/resolvers/demand-cycle-resolver.service';
import { ReplenishmentResolverService } from 'src/app/service/resolvers/replenishment-resolver.service';
import { ReservationResolverService } from 'src/app/service/resolvers/reservation-resolver.service';
import { LisInterfaceResolverService } from 'src/app/service/resolvers/stock-lis-interface-resolver.service';
import { ReservationInterfaceResolverService } from 'src/app/service/resolvers/reservation-interface-resolver.service';
import { OrderResolverService } from 'src/app/service/resolvers/order-resolver.service';
import { AllocationResolverService } from 'src/app/service/resolvers/allocation-resolver.service';
import { ReportResolverService } from 'src/app/service/resolvers/report-resolver.service';
import { BackendResolverService } from 'src/app/service/resolvers/backend-resolver.service';
import { QtyOnhandSerialResolverService } from 'src/app/service/resolvers/qty-onhand-serial-resolver.service';
import { DemandResolverService } from 'src/app/service/resolvers/demand-resolver.service';
import { ItemMasterResolverService } from 'src/app/service/resolvers/item-master-resolver.service';
import { ItemTransferQuotaResolverService } from 'src/app/service/channelItemControl/item-transfer-quota-resolver.service';
import { DocCalculationFactorResolverService } from 'src/app/service/channelItemControl/doc-calculation-factor-resolver.service';
import { CusPInputEventDirective } from './components/directives/cus-p-input-event.directive';
import { PreAllocateCommonComponent } from './pages/stock/replenishment/components/pre-allocate-common/pre-allocate-common.component';
import { PreAllocateNopComponent } from './pages/stock/replenishment/components/pre-allocate-nop/pre-allocate-nop.component';
import { QtyOnhandSerialComponent } from './pages/stock/qty-onhand-serial/qty-onhand-serial.component';
import { getValueByKeyPath } from './util/GetValueByKeyPath.pipe';
import { InputLimitCustom } from './pages/base/input-limit-cst/input-limit-cst.component';
import { ExtApiRequestLogComponent } from './pages/stock/ext_api_request_log/ext_api_request_log.component';
import { ExtApiRequestLogResolverService } from './service/resolvers/ext-api-request-log-resolver.service';
import { SerialScanAllCstComponent } from './pages/base/serial-scanall-cst/serial-scanall-cst.component';
import { PposDataMigrationComponent } from './pages/system/ppos-data-migration/ppos-data-migration.component';

import { ApiCallLogComponent } from './pages/stock/api_call_log/api_call_log.component';
import { ApiCallLogResolverService } from './service/resolvers/api-call-log-resolver.service';
import { ChangepasswordComponent } from './pages/system/changepassword/changepassword.component';
import { JsonDisplayPopUpComponent } from './components/json-display-pop-up/json-display-pop-up.component';

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "loading",
    component: LoadingComponent,
  },
  {
    path: "main",
    component: MainComponent,
    children: [
      {
        path: "uam_access_function",
        data: { name: MENU.SECURITY_FUNCTION, code: 18 },
        resolve: { query: AccessFunctionResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: AccessFunctionComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "uam_role",
        data: { name: MENU.SECURITY_ROLE, code: 19 },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: UAMRole_Search,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "uam_role_mapping",
        data: { name: MENU.SECURITY_ROLE_FUNCTION_MAPPING, code: 16 },
        resolve: { data: RolemappingResolverService, },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: UAMRoleMapping_Search,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "uam_user_role",
        data: { name: MENU.SECURITY_USER_ROLE_MAPPING, code: 17 },
        resolve: { data: RolemappingResolverService, },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: UAM_USERROLE_SEARCH,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_balance",
        data: { name: MENU.STOCK_BALANCE, code: 1 },
        canActivate: [AuthService],
        resolve: {query: BalanceResolverService},
        children: [
          {
            path: "demo",
            component: DemoComponent,
          },
          {
            path: "search",
            component: Balance_Search,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "lis_interface",
        data: { name: MENU.STOCK_TRANSACTION_LIS_INTERFACE, code: 104 },
        canActivate: [AuthService],
        resolve: {query: LisInterfaceResolverService},
        children: [
          {
            path: "search",
            component: LisInterfaceComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "reservation_interface",
        data: { name: MENU.RESERVATION_INTERFACE, code: 160 },
        canActivate: [AuthService],
        resolve: {query: ReservationInterfaceResolverService},
        children: [
          {
            path: "search",
            component: ReservationInterfaceComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "item_master",
        data: { name: MENU.STOCK_ITEM_MASTER, code: 9 },
        resolve: { query: ItemMasterResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: ItemComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_ppos",
        data: { name: MENU.STOCK_MOVEMENT_HISTORY, code: 101 },
        resolve: { query: PposResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: PposComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "configuration_tables_maintenance",
        data: { name: MENU.CIC_CONFIGURATION_TABLES_MAINTENANCE, code: 14 },
        canActivate: [AuthService],
        resolve: { query: ConfigurationTablesMaintenanceResolverService },
        component: ConfigurationTablesMaintenanceComponent,
      },
      {
        path: "item_transfer_quota",
        data: { name: MENU.CIC_ITEM_TRANSFER_QUOTA, code: 15 },
        resolve: { query: ItemTransferQuotaResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: ItemTransferQuotaComponent_Search,
          },
          {
            path: "edit",
            canActivateChild: [AuthService],
            component: ItemTransferQuotaComponent_Edit,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "day_of_cover",
        data: { name: MENU.CIC_DOC_CALCULATION_FACTOR, code: 11 },
        resolve: { query: DocCalculationFactorResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: DayOfCoverComponent_Search,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "cross_shop_pickup_quota",
        data: { name: MENU.CIC_CROSS_SHOP_PICKUP_QUOTA, code: 12 },
        resolve: { query: ItemTransferQuotaResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: CrossShopPickupQuotaComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_availability_level",
        data: { name: MENU.CIC_STOCK_AVAILABILITY_LEVEL, code: 13 },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: StockAvailabilityLevelComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "demand_cycle",
        data: { name: MENU.DEMAND_CYCLE, code: 7 },
        canActivate: [AuthService],
        resolve: { query: DemandCycleResolverService },
        children: [
          {
            path: "search",
            component: DemandCycleSearchComponent,
          },
          {
            path: "create",
            canActivateChild: [AuthService],
            component: DemandCycleCreateComponent,
          },
          {
            path: "demand",
            resolve: { query: DemandResolverService },
            children: [
              {
                path: "search",
                component: DemandSearchComponent,
              },
              {
                path: "create",
                canActivateChild: [AuthService],
                component: DemandCreateComponent,
              },
              {
                path: "",
                redirectTo: "search",
                pathMatch: "full",
              },
            ],
          },
          {
            path: "replenishment",
            resolve: { query: ReplenishmentResolverService },
            children: [
              {
                path: "search",
                component: ReplenishmentSearchComponent,
              },
              {
                path: "createspreadsheet",
                canActivateChild: [AuthService],
                component: ReplenishmentCreateSpreadsheetComponent,
              },
              {
                path: "edit",
                canActivateChild: [AuthService],
                component: ReplenishmentEditComponent,
              },
              {
                path: "detail",
                component: ReplenishmentDetailComponent,
              },
              {
                path: "",
                redirectTo: "search",
                pathMatch: "full",
              },
            ],
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_movement",
        data: { name: MENU.STOCK_TRANSACTION_HISTORY , code: 2},
        resolve: { query: TransactionHistoryResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: History_Integration,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_take",
        data: { name: MENU.STOCK_TAKE, code: 3 },
        canActivate: [AuthService],
        resolve: { query: TakeResolverService, },
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: stockTake_Search,
          },
          {
            path: "create",
            canActivateChild: [AuthService],
            component: stockTake_Create,
          },
          {
            path: "edit",
            canActivateChild: [AuthService],
            component: stockTake_Edit,
          },
          {
            path: "detail",
            canActivateChild: [AuthService],
            component: TakeDetailComponent,
          },
          {
            path: "check",
            canActivateChild: [AuthService],
            component: TakeCheckComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_reservation",
        data: { name: MENU.STOCK_RESERVATION, code: 4 },
        canActivate: [AuthService],
        resolve: { query: ReservationResolverService, },
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: Reservation_Integration,
          },
          {
            path: "create",
            canActivateChild: [AuthService],
            component: Reservation_Integration_Create,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "stock_allocation",
        data: { name: MENU.STOCK_ALLOCATION, code: 5 },
        resolve: { query: AllocationResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: AllocationSearchComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ]
      },
      {
        path: "transfer_order",
        data: { name: MENU.STOCK_TRANSACTION, code: 6 },
        resolve: { query: OrderResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: Transfer_Order_Search,
          },
          {
            path: "create",
            canActivateChild: [AuthService],
            component: Transfer_Order_Create,
          },
          {
            path: "edit",
            canActivateChild: [AuthService],
            component: TransferOrderEditComponent,
          },
          {
            /** auto-allocate */
            path: "allocate_create",
            canActivateChild: [AuthService],
            component: AllocationCreateComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "transaction_summary",
        data: { name: MENU.STOCK_DAILY_TRANSACTION_HISTORY_SUMMARY_REPORT, code: 8 },
        resolve: { query: ReportResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            component: Transaction_Summary_Search,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "transaction_backend",
        data: { name: MENU.STOCK_BACKEND_TRANSACTION, code: 10 },
        resolve: { query: BackendResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "create",
            canActivateChild: [AuthService],
            component: TransactionBackendComponent,
          },
          {
            path: "",
            redirectTo: "create",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "qty_onhand_serial",
        data: { name: MENU.QTY_ONHAND_SERIAL, code: 117 },
        resolve: { query: QtyOnhandSerialResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: QtyOnhandSerialComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "ext_api_request_log",
        data: { name: MENU.EXT_API_REQUEST_LOG, code: 138 },
        resolve: { query: ExtApiRequestLogResolverService },
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: ExtApiRequestLogComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "api_call_log",
        data: { name: MENU.API_CALL_LOG, code: 153 },
        resolve: { query: ApiCallLogResolverService},
        canActivate: [AuthService],
        children: [
          {
            path: "search",
            canActivateChild: [AuthService],
            component: ApiCallLogComponent,
          },
          {
            path: "",
            redirectTo: "search",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "ppos_data_migration",
        data: { name: MENU.PPOS_DATA_MIGRATION, code: -1 },
        component: PposDataMigrationComponent,
      },
      {
        path: "notice",
        data: { name: MENU.NOTICE, code: -1 },
        component: NoticeComponent,
      },
      {
        path: "task",
        data: { name: MENU.TASK, code: -1 },
        component: TaskComponent,
      },
      {
        path: "stock/cas-appointment",
        data: {name: MENU.STOCK, code: -1},
        component: CasAppointmentComponent,
      },
      {
        path: "system/changepassword",
        data: {name: MENU.CHANGE_PASSWORD, code: 158},
        canActivate: [CheckChangePasswordGuard],
        component: ChangepasswordComponent,
      },
      {
        path: "demo",
        data: {name: MENU.STOCK_RESERVATION, code: -1},
        children: [
          {
            path: "table-tab",
            component: DemoTableTabComponent,
          },
        ]
      },
      {
        path: "",
        redirectTo: "stock_balance",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "invalid",
    component: InvalidAccountComponent,
  },
  {
    path: "404",
    component: PageNotFoundComponent,
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    MobxAngularModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CalendarModule,
    BrowserAnimationsModule,
    FileUploadModule,
    // IconsProviderModule,
    ShareModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://localhost:8080'],
        sendAccessToken: true
      }
    }),
    TranslateModule.forChild(),
    ZXingScannerModule,
    NgxFileDropModule,
  ],
  exports: [RouterModule, TranslateModule],
  declarations: [
    UAM_USERROLE_SEARCH,
    History_Integration,
    Reservation_Integration,
    Reservation_Integration_Create,
    QueryPannelComponent,
    ResearQueryPannelComponent,
    AddPannelComponent,
    primeTable,
    colTable,
    editTable,
    colTable,
    InputCustom,
    InputLimitCustom,
    SelectCustom,
    CheckboxCustom,
    RadioCustom,
    SelectMultipleCustom,
    TextareaCustom,
    DateTimeCustomComponent,
    DateRangeCustomComponent,
    TreeSelectCustom,
    TreeSelectMultipleCustom,
    SwitchCustom,
    InputnumberCstComponent,
    LoadingComponent,
    LoginComponent,
    MainComponent,
    stockTake_Search,
    stockTake_Create,
    stockTake_Edit,
    TakeCheckComponent,
    TakeDetailComponent,
    Transfer_Order_Search,
    Transaction_Summary_Search,
    Transfer_Order_Create,
    Balance_Search,
    ItemTransferQuotaComponent_Search,
    ItemTransferQuotaComponent_Edit,
    DayOfCoverComponent_Search,
    UAMRole_Search,
    UAMRoleMapping_Search,
    IllustrationComponent,
    NoticeComponent,
    PageNotFoundComponent,
    ToTypePipe,
    CommonTablePanelComponent,
    SelectGroupComponent,
    ItemComponent,
    TransactionBackendComponent,
    ConfigurationTablesMaintenanceComponent,
    ctm_Create,
    DemandSearchComponent,
    DemandCreateComponent,
    DemandCycleSearchComponent,
    DemandCycleCreateComponent,
    ReplenishmentSearchComponent,
    ReplenishmentCreateComponent,
    ReplenishmentEditComponent,
    ReplenishmentDetailComponent,
    SerialCstComponent,
    AllocationCreateComponent,
    InputScannerCustomComponent,
    SpreadsheetComponent,
    ReplenishmentCreateSpreadsheetComponent,
    CompareSerialComponent,
    InvalidAccountComponent,
    TransferOrderEditComponent,
    ButtonRadioGroupComponent,
    CasAppointmentComponent,
    CrossShopPickupQuotaComponent,
    GetValueByPathComponent,
    TableColumnTabsComponent,
    DemoTableTabComponent,
    DemoComponent,
    StockAvailabilityLevelComponent,
    AllocationSearchComponent,
    PTableCustomDirective,
    TaskComponent,
    PposComponent,
    AccessFunctionComponent,
    LisInterfaceComponent,
    PreAllocateLeftItemPanelComponent,
    ResizeSensorDirective,
    CusPInputEventDirective,
    PreAllocateCommonComponent,
    PreAllocateNopComponent,
    QtyOnhandSerialComponent,
    getValueByKeyPath,
    ExtApiRequestLogComponent,
    ApiCallLogComponent,
    SerialScanAllCstComponent,
    PposDataMigrationComponent,
    ChangepasswordComponent,
    ReservationInterfaceComponent,
    JsonDisplayPopUpComponent,
  ],
  providers: [AuthService, MessageService, ConfirmationService, DatePipe, CheckChangePasswordGuard,JsonDisplayPopUpComponent]
})
export class AppRoutingModule {
}
