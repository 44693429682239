import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, computed, observable} from "mobx";
import {HttpHelper} from "../../../../util/HttpHelper";
import {BUTTON_TYPE, CONFIG, INPUT_TYPE, URLDICT} from "../../../../stores/base/BaseStore";
// import {CommonMethod} from "../../../../util/CommonMethod";
import {Table} from 'primeng/table';
import {LocalStorageHelper} from 'src/app/util/LocalStorageHelper';
import {MessageService, ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {RequestDetailStore} from "../../../../stores/stock/reservation/requestDetailStore";
import { StockReservationService } from 'src/app/service/stock/stock-reservation.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { FilterCommonMethod } from 'src/app/util/FilterCommonMethod';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { CommonService } from 'src/app/service/common/common-service';
import { DatePipe } from '@angular/common';
import { Paginator } from 'primeng/paginator';
import { CommonMethod } from '@/util/CommonMethod';
import { SearchFieldRecordor } from '@/service/common/search-record';
import { CommonStore } from '@/service/common/common-store-service';

const QUERYTITLE = {
  SOURCESYSTEM: 'Source System',
  STOCKCHANNEL: "Stock Channel",
  ITEMCODE: "Item Code",
  RESERVATIONTYPE: "Reservation Type",
  RESERVATIONID: "Reservation ID",
  RESERVATIONDATE: "Reservation Date",
  SOURCEREFNO: "Source Ref. No.",
}

@Component({
  selector: 'allocation-search',
  templateUrl: './allocation-search.component.html',
  styleUrls: ['./allocation-search.component.css']
})
export class AllocationSearchComponent implements OnInit {
  @observable permission: string []
  // @observable defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false
  // @observable loadingSwitch2 = false
  @observable selectedProducts: any[] = []

  @observable queryPannel: any = {}
  @observable lisOfData: any[] = []

  @action changeitem = (idx, data) => {
    this.lisOfData[idx] = data
  }
  backupData: any[] = [];
  // @observable isVisible:boolean = false

  columns = [
    // {header: 'action', title: 'Action', type: 'action'},
    // {header: 'itemCode', title: 'Item', width: '90px'},
    {header: 'itemDesc', title: 'Item Description', width: '250px'},
    {header: 'id', title: 'Reservation ID', width: '150px'},
    {header: 'reservationType', title: 'Reservation Type', width: '150px'},
    {header: 'reservationDate', title: 'Reservation Date', width: '150px', type: 'date'},
    {header: 'reason', title: 'Reason', width: '100px'},
    {header: 'releaseChannel', title: 'Stock Channel', width: '150px'},
    {header: 'qtyAvaliableForSales', title: 'Available for Sales', width: '170px'},
    {header: 'qtyPending', title: 'Pending Qty', width: '120px'},
    {header: 'qtyAllocated', title: 'Allocate Qty', width: '120px', type: 'number', min: 0},
    {header: 'sensitiveRemarks', title: 'Remark', width: '220px', type: 'txt'},
    {header: 'qtyPlanToAllocate', title: 'Planned Allocate Qty', width: '170px'},
    {header: 'qtyRequested', title: 'Request Reserve Qty', width: '170px'},
    {header: 'qtyCancelled', title: 'Cancel Qty', width: '120px'},
    {header: 'qtyReleased', title: 'Release Qty', width: '120px'},
    // {header: 'expiryDate', title: 'Expiry Date', width: '130px', type: 'date'},
    // {header: 'extensionCount', title: 'No. of Extension', width: '150px'},
    {header: 'sourceSystem', title: 'Source System', width: '140px'},
    {header: 'sourceTxnType', title: 'Source System Txn Type', width: '200px'},
    {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '150px'},
    // {header: 'schNum', title: 'School Number', width: '150px'},
    // {header: 'schLocCode', title: 'School Location Code', width: '190px'},
    {header: 'reserveDescription', title: 'Reserve By', width: '120px'},
    {header: 'unallocatedDate', title: 'Unallocate Date', width: '10rem', type: 'date'},
    {header: 'updateUser', title: 'Update By', width: '120px'},
    {header: 'updateDate', title: 'Update Date', width: '150px', type: 'datetime'},
  ]

  frozenColumns: any = [
    {header: 'expandControl', title: '', type: 'expandControl', width: '60px', hideInExpandRow: true, sortable: false},
    {header: 'action', title: 'Action', type: 'action', width: '100px'},
    {header: 'itemCode', title: 'Item Code', width: '120px'},
  ]
  frozenWidth = 60+100+120+'px';

  // editDialog = false
  dialogTitle = ""
  channels: any[] = []
  channels2: any[] = []
  skus: any[] = []
  allSku: any[] = []
  sourceSystemList = [];
  catalogs
  orderCreationDate
  reservationID
  resDate:any[]
  SourceRefNo
  assignmentLogic
  reservationType
  reservedBy
  orderId
  value: null
  balance
  seSku = [""]
  channel
  reservationStatus
  sechannelValue = ""
  _selectedColumns: any[];
  sourceSystem
  // orderStatus
  // assignmentlogics
  resstockreservationtypes
  selectedValue
  remark
  field = 'reservationDate'
  order = -1;
  isSortSearch
  initSortField = 'reservationDate'
  initSortOrder = -1
  channelOptions = [];
  totalRecords
  pageIndex = 0
  pageSize = 20
  searchParams = {}
  editShow: boolean = true;
  clonedLines = {}
  rawData = [] // Data backup for search api
  channelsDisableEdit = []

  selectedChannel = null;
  selectedChannelDisplay = '';

  @ViewChild('ptable') private ptable: Table
  
  // not calling storeSearchParams in this component because no requirement
  // import this class only for get params data at NavigationEnd event
  searchFieldRecordor: SearchFieldRecordor;

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.columns.filter(col => val.includes(col));
  }

  // tslint:disable-next-line:max-line-length
  constructor(
    public n: MessageService,
    public r: Router,
    private detail: RequestDetailStore,
    public p: ActivatedRoute,
    public m: ConfirmationService,
    private i18n: TranslateService,
    public stockReservationService: StockReservationService,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public cdRef: ChangeDetectorRef,
    public commonStore: CommonStore,
  ) {
    this._selectedColumns = this.columns;
    this.getPermission(p)
    this.initQueryData()
    this.searchFieldRecordor = new SearchFieldRecordor(this.commonStore, this.r, this.p, CONFIG.SEARCHPARAMSKEY.STOCKALLOCATION)
    this.initRouterEvent()
  }
  
  initRouterEvent() {
    this.searchFieldRecordor.setRouterEventForSearchParams(this.r)
    .subscribe(res=>{
      this.getSnapshotData(res)
      if(LocalStorageHelper.isEmptyValue(res)){
        this.channels = FilterCommonMethod.getRepoTreeForFilterInput();
        this.initDefaultChannel()
      }else{
        this.search(true,true)
      }
    })
  }

  getSnapshotData(params){
    let data = params
    this.queryPannel.ipts.forEach(ipt=>{
      if(ipt.type==INPUT_TYPE.DATETIME){
        if(data[ipt.name]){
          let val = data[ipt.name]
          ipt.value = new Date(val)
        }
      }else {
        if(data[ipt.name]){
          let val = data[ipt.name]
          ipt.value = val
        }
      }
    })
    if(data.pageSize){
      this.pageSize = Number.parseInt(data.pageSize)
    }
    if(data.sortEvent){
      let sortEvent = data.sortEvent
      this.field = sortEvent.field
      this.order = sortEvent.order
    }
  }

  initQueryDataOptions(){
    let channelIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.STOCKCHANNEL)
    channelIpt.options = this.channelOptions
    
    let itemIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.ITEMCODE)
    itemIpt.options = this.allSku

    let typeIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.RESERVATIONTYPE)
    typeIpt.options = this.resstockreservationtypes
    
    let sourceSystemIpt = this.queryPannel.ipts.find(e=>e.title==QUERYTITLE.SOURCESYSTEM)
    sourceSystemIpt.options = this.sourceSystemList
  }

  initQueryData() {
    let that = this
    this.queryPannel = {
      hasQuantity: "All",
      ipts: [
       {
          title: QUERYTITLE.STOCKCHANNEL,
          name: 'channel',
          placeholder: 'Choose Stock Channel',
          value: this.selectedChannel,
          showValue: this.selectedChannelDisplay,
          type:INPUT_TYPE.MUTIPLETREESELECT,
          options: this.channelOptions,
          class: "p-col-12 p-md-4 p-lg-3",
          beforeSubmit: function(value) { return value?value.map(val=>val.id).filter(id=>id>0):null },
          change: function(value, ipt) {
            if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          },
        },
        {
          title: QUERYTITLE.ITEMCODE,
          name: 'skuId',
          placeholder: 'Choose Item',
          value: null,
          type: INPUT_TYPE.SELECT,
          options: this.allSku,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: QUERYTITLE.RESERVATIONTYPE,
          name: 'reservationType',
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          options: this.resstockreservationtypes,
          // iptionLabel: 'name',
          // iptionValue: 'code',
        },
        {
          title: QUERYTITLE.RESERVATIONID,
          name: 'reservationId',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          options: [],
        },{
          title: QUERYTITLE.RESERVATIONDATE,
          name:'ResDate',
          type: INPUT_TYPE.DATERANGE,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        }, {
          title: QUERYTITLE.SOURCEREFNO,
          name: 'sourceTxnRefHeaderNo',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT
        },
        {
          title: QUERYTITLE.SOURCESYSTEM,
          type: INPUT_TYPE.SELECT,
          options: this.sourceSystemList,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          optionValue: 'value',
        },
      ],
      btnsclass: 'p-d-flex p-col-12 p-justify-end',
      btns: [
        {
          type: BUTTON_TYPE.BUTTON,
          title: 'Clear',
          class: "p-button-outlined p-mr-1",
          show: true,
          handler: {
            'click': () => {
              this.refresh()
            }
          }
        },{
          type: BUTTON_TYPE.BUTTON,
          title: 'Export',
          icon: '',
          class: "p-mr-1",
          show: true,
          handler: {
            "click": () => {
              this.export()
            }
          }
        },{
          type: BUTTON_TYPE.BUTTON,
          title: 'Search',
          icon: '',
          show: this.showSearch,
          class: "",
          handler: {
            "click": () => {
              if (this.loadingSwitch) return
              this.search(true,true)
            }
          }
        }
      ],
      change: (idx, value, ipt)=>{
        if(ipt.name == 'channel'){
          if(ipt.change){
            ipt.change(value, ipt)
          }
        }
      }
    };
    this.setQueryButtonShow()
  }

  @action getPermission(p) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
  }

  @computed get showSearch() {
    return this.permission?.includes('Search Allocation')
  }

  @computed get showEdit() {
    return this.permission?.includes('Edit Allocation (staff reservation only)')
  }
  setQueryButtonShow() {
    this.queryPannel.btns.forEach(element => {
      element.title === 'Search' && (element.disabled = !this.showSearch)
    });
  }

  onChange() {
    const len = this.seSku.length;
    this.seSku = len > 0 ? (this.seSku[len - 1] === '' ? [""] : this.seSku.filter((item) => item)) : [""]
  }

  warehouseIdList = {};

  initDefaultChannel(){
    try{
      let channel = this.commonService.getDefaultChannel(this.channels)
      if(channel){this.selectedChannel = [channel];}else{this.selectedChannel=null}
      this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
      let channelIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.STOCKCHANNEL);
      channelIpt.value = this.selectedChannel;
      channelIpt.showValue = this.selectedChannelDisplay;
    }catch(e){console.error(e)}
  }


  ngOnInit(): void {
    this.initPaginatorSub()
    this.selectedValue = "All"
    this.channels = FilterCommonMethod.getRepoTreeForFilterInput();
    // default channel init when NavigationEnd event in SearchFieldRecordor, trigger after constructor and before ngInit
    // this.initDefaultChannel()
    this.channelOptions = JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo=>repo.children.length)
    this.allSku = JSON.parse(localStorage.getItem("SKU")).map(item=>{
      return {
        code: item.code,
        name: `${item.name} - ${item.flag}`,
        _data: item
      }
    })
    this.sourceSystemList = JSON.parse(localStorage.getItem("SOURCESYSTEM")).map(val=>{return {name: val.code, value: val.code, _data: val}})
    this.i18n.get('reservation').pipe(tap(res=>{
      this.resstockreservationtypes = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").map(item => {
        item.label = res[item.label]
        let {stockReservationTypeCode: code, description: name, ...other} = item
        return {code, name, ...other}
      })
    })).subscribe(res => {
      this.initQueryDataOptions()
      // this.search(true,true)
    })
  }
  
  setDatePipe(datepipe){
    this.datepipe = datepipe
  }
  convertData(data){
    let _data = data
    if(_data) {
      data[1] = data[1] || data[0]
      _data = [
        new Date(data[0].setHours(0, 0, 0, 0)),
        new Date(new Date(data[1].setHours(0, 0, 0, 0)).getTime()+24*60*60*1000)
      ];
      _data = _data.map((date)=>{
        return this.dateFormatToApi(date)
      })
    }
    return _data
  }
  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss:SSS" )
  }
  search(isSort: boolean = true, isPage = false) {
    this.editShow = true
    // this.sechannelValue = this.queryPannel.ipts.find(item => item.title === "Channel").value?.data;
    this.seSku = this.queryPannel.ipts.find(item => item.title === "Item Code").value;
    // this.seSku = this.seSku ? this.seSku.map(item => item['code']) : null;
    this.reservationType = this.queryPannel.ipts.find(item => item.title === "Reservation Type").value;
    this.reservationID = this.queryPannel.ipts.find(item => item.title === "Reservation ID")?.value?.toString();
    this.resDate = this.convertData(this.queryPannel.ipts.find(item => item.title === "Reservation Date").value);
    this.SourceRefNo = this.queryPannel.ipts.find(item => item.title === "Source Ref. No.").value;
    let _channelIpt = this.queryPannel.ipts.find(item => item.name === "channel");
    let _channel = _channelIpt.beforeSubmit?_channelIpt.beforeSubmit(_channelIpt.value): _channelIpt.value;
    this.channel = _channel;
    let sourceSystemIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.SOURCESYSTEM);
    let _sourceSystem = sourceSystemIpt.value;
    let valid = this.validation()
    if(!valid) {
      return
    }
    this.loadReservations(
      isSort, 
      _channel, 
      this.seSku,
      this.reservationType, 
      this.reservationID,
      this.resDate,
      this.SourceRefNo,
      _sourceSystem,
      isPage
    )
    this.isSortSearch = isSort;
    if (!isSort) {
      this.ptable && this.ptable.reset()
      this.order = undefined
    }
  }

  validation(){
    let seSku = this.queryPannel.ipts.find(item => item.title === "Item Code")
    let _channelIpt = this.queryPannel.ipts.find(item => item.name === "channel");
    let reservationIdIpt = this.queryPannel.ipts.find(item => item.title === QUERYTITLE.RESERVATIONID);
    let reservationId=reservationIdIpt?reservationIdIpt.value:null
    let skuVal = seSku.value?seSku.value:null;
    let _channel = _channelIpt.beforeSubmit?_channelIpt.beforeSubmit(_channelIpt.value): _channelIpt.value;
    if(
      !_channel&&!reservationId
    ) {
      this.showMessage('warn','System', 'Channel is required')
      return false;
    }
    return true;
  }

  transferDate(date) {
    let dateNew
    if (date){
      var year = date.getFullYear()

      var m = (date.getMonth() + 1).toString();
      var d = (date.getDate()).toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      if (d.length == 1) {
        d = '0' + d;
      }
      dateNew = year + '-' + m + '-' + d

    }else dateNew = null
    return dateNew
  }
  refresh() {
    this.seSku = ['']
    this.sechannelValue = null
    this.ptable.reset()
    this.ptable.sortOrder = this.initSortOrder
    this.ptable.sortField = this.initSortField
    this.order = this.initSortOrder
    this.field = this.initSortField
    this.queryPannel.ipts.forEach((item) => {
      item.value = null
      item.showValue = null
    });
    // this.search(true)
  }
  /*
   * Request qty = Pending + Allocated + Cancel
   */
  verifyDate(data) {
    let rawItem = this.rawData.find(item => item.id === data.id)
    if(rawItem.qtyAllocated > data.qtyAllocated) {
      return true
    }
    return false
  }
  save(d, idx) {
    const {newid, edit, pickupDate, createDate, updateDate, reservationDate, ...swapDate} = d; // key need to remove from update payload
    const data = {...swapDate, id: d.id};
    if(data.qtyAllocated == null) {
      this.showMessage('error', 'System', 'Allocate Qty is required')
      return
    }
    /* if(data.qtyPlanToAllocate > data.qtyPending){
      this.showMessage('error', 'System', 'Allocate Qty should not more than Pending Qty')
      return
    } */
    /* if(data.qtyPlanToAllocate > data.qtyAvaliableForSales){
      this.showMessage('error', 'System', 'Plan Allocate Qty should not more than Available for Sales')
      return
    } */
    if(data.qtyPending + data.qtyAllocated + data.qtyCancelled + data.qtyReleased < data.qtyRequested) {
      this.showMessage('error', 'System', 'The sum of pending qty, allocated qty, cancelled qty and released qty cannot be less than requested qty')
      return
    }
    if(this.verifyDate(data)) {
      this.showMessage('error', 'System', 'Allocate qty should not be less than before modification')
      return
    }
    this.editShow = true;
    this.loadingSwitch = true

    let _data = {
      ...data,
      // qtyAllocated: data.qtyAllocated + data.qtyPlanToAllocate,
      // qtyPending: data.qtyPending - data.qtyPlanToAllocate,
    }

    delete _data['qtyPlanToAllocate'];
    delete _data['qtyAvaliableForSales'];

    // return
    // this.stockReservationService.saveReservationQtysUpdate(data)
    this.commonService.httpPost(URLDICT.STOCK_RESERVATION_UPDATE, _data)
    // HttpHelper.post(URLDICT.STOCK_RESERVATION_UPDATE, _data)
    .then(res => {
      if (res.code === '000') {
        this.search()
        // this.loadingSwitch = true
        // this.stockReservationService.searchChannelItemBalance([_data.channelId], [_data.itemId]).pipe(
        //   map(res=>res.data),
        //   // switchMap(itemBalance=>{
        //   //   let balanceQty = itemBalance[0]?.fg || 0
        //   //   // return this.updateRowData(d.id, balanceQty).pipe(map(updateRowDataRes=>{
        //   //   //   return [updateRowDataRes, itemBalance]
        //   //   // }))
        //   // })
        // ).subscribe(([row, itemBalance])=>{
        //   this.loadingSwitch = false
        // }, err=>{
        //   console.error(err)
        //   this.loadingSwitch = false
        // }, ()=>{
        // })
        // this.updateRowData(d.id)

        // .subscribe(()=>{

        // },(err)=>{},()=>{this.loadingSwitch = false;});
        // this.search()
        // this.clear()
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', res.msg)
        this.resetQty(d, idx)
        this.loadingSwitch = false
      }
    }).catch(e => {
      // this.n.error("System",e.toString())
      this.showMessage('error', 'System', e.toString())
      this.resetQty(d, idx)
      this.loadingSwitch = false
    }).finally(
      () => {}
    )
  }

  editDetail(data) {
    // this.detail.currentState = 'edit'
    this.clonedLines[data.id] = {...data};
    data.edit = true
  }

  edit(data) {

    if (data.createdDate == null) {
      data.createdDate = new Date(data.createdDate)
    }
    if (data.fulfilledDate == null) {
      data.fulfilledDate = new Date(data.fulfilledDate)
    }
    if (data.fulfilledDate == null) {
      data.endDateActive = new Date(data.endDateActive)
    }
    this.channels = JSON.parse(localStorage.getItem("REPOTREE"))[0].children
    if (data.id) {
      data.newid = data.id
      delete data.id
    }
    this.editShow = false;
  }

  cancel(data, idx) {
    data.edit = false
    data.qtyPlanToAllocate = this.clonedLines[data.id].qtyPlanToAllocate
    data.qtyPending = this.clonedLines[data.id].qtyPending
    data.qtyAllocated = this.clonedLines[data.id].qtyAllocated
    data.sensitiveRemarks = this.clonedLines[data.id].sensitiveRemarks
    delete this.clonedLines[data.id];
  }

  resetQty(data, idx){
    if(this.clonedLines[data.id]){
      data.qtyPlanToAllocate = this.clonedLines[data.id].qtyPlanToAllocate
    }
  }

  sortSearch(key) {
    if (this.field && this.field !== key) {
      this.order = undefined
    }
    this.field = key;
    this.isSortSearch = true
    this.order = !this.order ? 1 : -this.order
    this.search(true)
  }

  page(e) {
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    let data: any = {...this.searchParams}
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    this.search(this.isSortSearch, true);
  }

  delete() {
    const len = this.selectedProducts.length
    if (len < 1) {
      return
    } else {
      const obj = {
        ids: this.selectedProducts.map(item => item.id + '')
      }
      this.m.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.loadingSwitch = true
          this.commonService.httpPost(URLDICT.STOCK_RESERVATION_DELETE, obj)
          // HttpHelper.post(URLDICT.STOCK_RESERVATION_DELETE, obj)
          .then(res => {
            if (res.code == '000') {
              this.search()
              this.selectedProducts = null;
              //清空选中并重赋数据源
              // this.allCheckBoxValue = false
              // this.mapOfCheckedId = {}
            } else {
              this.showMessage('error', 'System', 'Submit Fail!');
            }
            this.loadingSwitch = false
          }).catch(e => {
            this.showMessage('error', 'System', e.toString());
            this.loadingSwitch = false
          })
        }
      });
    }
  }

  showMessage(severity, summary, detail) {
    this.n.add({severity, summary, detail});
  }
  onTableValueInput(event, data, col){
    let value = event.value!=null?event.value:0;
    this.onTableValueChange(value, data, col)
  }
  onTableValueChange(event, data, col){
    let value = event!=null&&event>=0?event:0;
    switch(col.header){
      case 'qtyAllocated':
        let rawItem = this.rawData.find(item => item.id === data.id)
        data.qtyPending = rawItem.qtyRequested - value - data.qtyCancelled - data.qtyReleased
        // data.qtyPlanToAllocate = rawItem.qtyPlanToAllocate - value
        break;
    }
  }

  updateRowData(id, qtyAvaliableForSales?){
    return this.stockReservationService.searchReservationById(id).pipe(tap(
      res=>{
        if(res.code=='000'){
          let _rowIdx = this.lisOfData.findIndex(row=>row.id == id);
          if(_rowIdx>-1) {
            let data = res.data[0];
            if (data.createdDate){
              data.createdDate = new Date(data.createdDate)
            }
            if (data.fulfilledDate) {
              data.fulfilledDate = new Date(data.fulfilledDate)
            }
            if (data.endDateActive) {
              data.endDateActive = new Date(data.endDateActive)
            }
            if(qtyAvaliableForSales!=null){
              data.qtyAvaliableForSales = qtyAvaliableForSales;
            }
            data.allowEdit = data.isAllocateAllowed == 'Y'
            data.edit = false
            this.lisOfData[_rowIdx] = {
              ...this.lisOfData[_rowIdx],
              ...data
            };
            this.lisOfData = [...this.lisOfData];
          }
        }
      }
    ))
  }
  
  lastSearchData = null
  loadReservations(
    isSort, 
    channel,
    seSku,
    reservationType = null,
    reservationID=this.reservationID,
    resDate=this.resDate,
    SourceRefNo=this.SourceRefNo,
    sourceSystem,
    isPage
  ){
    this.loadingSwitch = true
    let data = {
      itemId: seSku?[seSku]:[],
      haveQuantity: "Pending",
      reservationType: reservationType? [reservationType]: null,
      reservationId: reservationID&&reservationID.length>0?reservationID:null,
      resDate:resDate,
      sourceReferenceNo:SourceRefNo&&SourceRefNo.length>0?SourceRefNo:null,
      channel: channel,
      sourceSystem: sourceSystem,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: isSort ? {field: this.field, order: this.order} : null,
    }
    this.lastSearchData = data
    this.stockReservationService.searchReservation(data).pipe(
      map(res=>[res.data, res.msg])
    )
    .pipe(
      switchMap(([reservations, total])=>{
        if(reservations.length==0) return of([[reservations, total], []])
        let channels = CommonMethod.arrayUnique(reservations.map(e=>e.channelId)); // channel is unique & mandatory without reservation id
        let itemIds = CommonMethod.arrayUnique(reservations.map(e=>e.itemId));
        return this.stockReservationService.searchChannelItemBalance(channels, itemIds).pipe(
          map(res=>res.data),
          map(res=>[[reservations, total], res])
        );
      }),
      map(([[reservations, total], itemBalance])=>{
      // map(([reservations, total])=>{
      if(this.paginatorSubject && isPage) this.paginatorSubject.next('reset');
      let _reservations = reservations.map(reservation=>{
        let item = itemBalance.find((_item)=>{return reservation.channelId == _item.repoId&&reservation.itemId == _item.skuId})
        let qtyAvaliableForSales = item?item.fg: 0
        let _res = {
          ...reservation,
          qtyAvaliableForSales: qtyAvaliableForSales || reservation.qtyAvaliableForSales || 0
        }
        if (_res.createdDate){
          _res.createdDate = new Date(_res.createdDate)
        }
        if (_res.fulfilledDate) {
          _res.fulfilledDate = new Date(_res.fulfilledDate)
        }
        if (_res.endDateActive) {
          _res.endDateActive = new Date(_res.endDateActive)
        }
        if (
          // ['CSTK'].includes(<string>item.sourceSystem)
          // _res.isEditingAllowed == 'Y'
          !_res.reservationType.includes(<string[]>CONFIG.ALLOCATION_EDIT_BUTTON_CHECK_BLACKLIST)&&
          _res.isAllocateAllowed == 'Y'
          // type.isEditingAllowed == "Y"
        ){
          _res.allowEdit = true;
        }else{
          _res.allowEdit = false;
        }
        return _res
      })
      return [_reservations, total]
    }))
    .subscribe(([res, total])=>{
      this.loadingSwitch = false
      this.rawData = JSON.parse(JSON.stringify(res))
      this.lisOfData = res
      if (total !== '') {
        this.totalRecords = total
      }
    }, err=>{
      console.error(err)
      this.showMessage('error', 'System', err.toString())
      this.loadingSwitch = false
    }, ()=>{
    })
  }

  loadItemList(channelId){
    return this.stockCommonService.getItemsByChannelIdAndCondition(
      channelId,
      "1001", // FG
    ).pipe(
      map(res=>{
        if(res.code == '000'){
          let _items = []
          res.data.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          return _items
        }else{
          return []
        }
      }))
  }

  paginatorSubject = new Subject()

  @ViewChild('paginator', {static: false}) paginator: Paginator;
  resetPaginator(){
    // if(this.paginator) this.paginator.changePage(0);
    // this.pageIndex = 0;
    if(!this.paginator) return;
    var pc = this.paginator.getPageCount();
    this.paginator.first = 0;
    var state = {
      page: 0,
      first: this.paginator.first,
      rows: this.paginator.rows,
      pageCount: pc
    };
    this.paginator.updatePageLinks();
    this.paginator.onPageChange.emit(null); // paginator display not update without this line
    this.paginator.updatePaginatorState();
    this.paginator.pageLinks = [...this.paginator.pageLinks]
    this.cdRef.markForCheck();
    // this.paginator.changePage(0);
  }
  paginatorSub: Subscription
  initPaginatorSub(){
    if(this.paginatorSubject){
      if(this.paginatorSub) {
        this.paginatorSub.unsubscribe();
        this.paginatorSub = null;
      }
      this.paginatorSub = this.paginatorSubject.subscribe(e=>{
        if(e=='reset'){
          this.resetPaginator()
        }
      })
    }
  }
  ngOnDestroy() { 
    if(this.paginatorSub)this.paginatorSub.unsubscribe() 
    if(this.searchFieldRecordor)this.searchFieldRecordor.onDestory()
  }
  
  export(){
    if(!this.lastSearchData){
      // this.showMessage('warn', 'System', 'Please Search First')
      return
    }
    
    let data = {
      ...this.lastSearchData,
      pageIndex: 0,
      pageSize: 2147483647,
    }
    let columns = [
      ...this.frozenColumns
        .filter(col=>!['expandControl', 'action']
        .includes(col.type))
        .map(col=>{return {title: col.title, field: col.header}}),
      ...this.columns
        .map(col=>{return {title: col.title, field: col.header}}),
    ]
    this.loadingSwitch = true;
    this.stockReservationService.searchReservation(data).pipe(
      map(res=>[res.data, res.msg])
    )
    .pipe(
      switchMap(([reservations, total])=>{
        let channels = CommonMethod.arrayUnique(reservations.map(e=>e.channelId)); // channel is unique & mandatory without reservation id
        let itemIds = CommonMethod.arrayUnique(reservations.map(e=>e.itemId));
        return this.stockReservationService.searchChannelItemBalance(channels, itemIds).pipe(
          map(res=>res.data),
          map(res=>[[reservations, total], res])
        );
      }),
      map(([[reservations, total], itemBalance])=>{
      // map(([reservations, total])=>{
      let _reservations = reservations.map(reservation=>{
        let item = itemBalance.find((_item)=>{return reservation.channelId == _item.repoId&&reservation.itemId == _item.skuId})
        let qtyAvaliableForSales = item?item.fg: 0
        let _res = {
          ...reservation,
          qtyAvaliableForSales: qtyAvaliableForSales || reservation.qtyAvaliableForSales || 0
        }
        if (_res.createdDate){
          _res.createdDate = new Date(_res.createdDate)
        }
        if (_res.fulfilledDate) {
          _res.fulfilledDate = new Date(_res.fulfilledDate)
        }
        if (_res.endDateActive) {
          _res.endDateActive = new Date(_res.endDateActive)
        }
        if (
          _res.isAllocateAllowed == 'Y'
        ){
          _res.allowEdit = true;
        }else{
          _res.allowEdit = false;
        }
        return _res
      })
      return [_reservations, total]
    }))
    .subscribe(([res, total])=>{
      this.loadingSwitch = false;
      CommonMethod.downloadXlsx(res, columns, `Allocation_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`, this.datepipe)
    }, e=>{
      this.loadingSwitch = false;
      this.showMessage('error', 'System', e.toString())
    })
  }
}
