import { ORDERTYPEID } from "@/pages/stock/transfer_order/StockOrderShare";
import { FUNCTION_CODE } from "@/service/common/permission.type";

export const SerialCompareValue = {
  YES: "Yes",
  NO: "No",
}
export const STATUS_TITLE = {
  DRAFT: "Draft",
  PRE_STOCK_TAKE: "Pending For Stock Take",
  STOCK_TAKE_IN_PROGRESS_1: "Stock Take In Progress (1st count)",
  STOCK_TAKE_IN_PROGRESS_2: "Stock Take In Progress (2nd count)",
  STOCK_TAKE_IN_PROGRESS_1_INPUT: "Stock Take In Progress (1st count input complete)",
  STOCK_TAKE_IN_PROGRESS_2_INPUT: "Stock Take In Progress (2nd count input complete)",
  STOCK_TAKE_IN_PROGRESS_1_COMPLETE: "Stock Take In Progress (1st count complete)",
  STOCK_TAKE_IN_PROGRESS_2_COMPLETE: "Stock Take In Progress (2nd count complete)",
  STOCK_TAKE_IN_PROGRESS: "Stock Take In Progress",
  REVIEW_IN_PROGRESS: "Review In Progress",
  COMPLETED: "Completed",
  PARTIAL_COMPLETED: "Adjustment in Progress",
}
export const TAKE_STATUS_ID = {
  DRAFT     : 12,
  PREST     : 61,
  STIP1C    : 82,
  STIP1CIC  : 83,
  STIP1CC   : 84,
  STIP2C    : 85,
  STIP2CIC  : 86,
  STIP2CC   : 87,
  PARTIAL   : 78,
  COMPLETED : 14,
  CANCELLED : 137,

  READY     : 47, // deprecated
  REVIEW    : 13, // deprecated
}
export const TITLE = {
  STOCK_TAKE_REF_NO: "Stock Take Ref. No.",
  STOCK_ADJUSTMENT_REF_NO: "Stock Adjustment Ref. No.",
  CHANNEL: "Channel",
  STATUS: "Status",
  STOCK_TAKE_TYPE: "Stock Take Type",
  COUNTBY:"Serialized Item Count By",
  FILTERASSIGNBY: "Filter Assign By",
  SEARCH: "Search",
  CREATE: "Create",
  CREATE_SAVE: "Save",
  CREATE_BACK: "Back",
  CREATE_ADD: "Add",
  TAKEBU: 'BU',
  TYPE:"Stock Take Type",
  STOCKTAKEREFNO:"Stock Take Ref. No.",
  STOCKADJREFNO:"Stock Adjustment Ref. No.",
  TARGETDATE:"Target Stock Take Date",
  BULKADD_FILTER:"Filter",
  BULKADD_BRAND:"Brand",
  BULKADD_ITEM:"Item",
  BU: 'BU Code',
  LOB: 'LOB Code',
  AVAILABLE: 'Have Available Stock',
  SUBSUBCAT: 'Sub-Sub Cat',
  MAINCAT: 'Main cat',
  SUBCAT: 'Sub cat',
  ACTIONBY: 'Action By',
  SELECT_ITEM:"Additional Items",
  SERIALIZED_ITEM: 'Serialized Items',
  REMARKS: 'Remarks',
  CANCEL_REASON: 'Close Reason',
  DEVICE_MANAGEMENT: 'Device Management:',
  NAME_IN_FULL: 'Name in Full:',
  STAFF_ID: 'Staff ID:',
  EXPORT_DATE: 'Date:',
  SNAPSHOT_DATE: 'Snapshot Date',
}
// export const TITLE = {
//     // TYPE:"Stock Take Type",
//     // CHANNEL:"Channel",
//     // STOCKTAKEREFNO:"Stock Take Ref. No.",
//     // STOCKADJREFNO:"Stock Adjustment Ref. No.",
//     // TARGETDATE:"Target Stock Take Date",
//     // BULKADD_FILTER:"Filter",
//     // BULKADD_BRAND:"Brand",
//     // BULKADD_ITEM:"Item",
//     // COUNTBY:"Serialized Item Count By",
//     // BU: 'BU Code',
//     // LOB: 'LOB Code',
//     // AVAILABLE: 'Have Available Stock',
//     // SUBSUBCAT: 'Sub-Sub Cat',
//     // ACTIONBY: 'Action By',
//     // SELECT_ITEM:"Additional Items",
//     // SERIALIZED_ITEM: 'Serialized Items',
//     // TAKEBU: 'BU',
// }
export const ADJUSTMENT_STATUS = {
  COMPLETE: 1,
  NO_ADJUSTMENT:2,
  NOT_COMPLETE: 3,
}
export const TAKE_ADJUSTMENT_TXN_TYPE = 'STOCK-TAKE'

// need stock take type table in db to store which type is suprise
// hardcode here for make function work for now
export const SURPRISE_STOCK_TAKE_CODE = ['BKSUR'];
export const FRONTLINE_STOCK_TAKE_CODE = ['FRSCH'];

export const BTN_TITLE = {
  PRE_STOCK_TAKE: "Pending For Stock Take",
  READY_FOR_STOCK_TAKE: "Ready for Stock Take",
  READY_FOR_SECOND_COUNT: "Ready For 2nd count",
  CHECK_VARIANCE: "Check Variance",
  EDIT: "Edit",
  MARK_AS_FIRST_COUNT: "Mark As 1st Count",
  VIEW_VARIANCE_REPORT: "View Variance Report",
  EXPORT: "Export",
  BACK: "Back",
  ONE_COUNT_COMPLETE:"1st Count Input Complete",
  TWO_COUNT_COMPLETE:"2nd Count Input Complete",
  VIEW_ADJUSTMENT: "View Stock Adjustment",
  UPLOAD: "Attachment",
  EDIT_SAVE: "Save",
  CANCEL_TAKE: 'Close With Reason',
  IMPORT_QTY_FROM_XLSX: 'Import Qty From Excel',
  EXPORT_SNAPSHOT_DATA: "Export Snapshot Qty",
}

export const TAKEBUID_ADJTYPEID = {
  129: ORDERTYPEID.ADJUSTMENTHKTHOME, // HKT Home
  130: ORDERTYPEID.ADJUSTMENT, // MOBILE
  DEFAULT: ORDERTYPEID.ADJUSTMENT,
}

export const TAKEBUID_ADJSEARCH_PERMISSION = {
  129: FUNCTION_CODE.SO_TYPE27_SEARCH,
  130: FUNCTION_CODE.SO_TYPE1_SEARCH,
  DEFAULT: FUNCTION_CODE.SO_TYPE1_SEARCH,
}
