import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, observable, computed} from "mobx";
import {HttpHelper} from "../../../util/HttpHelper";
import {LocalStorageHelper} from "../../../util/LocalStorageHelper";
import {CONFIG, INPUT_TYPE, NOTI_TITLE, URLDICT} from "../../../stores/base/BaseStore";
import {ConfirmationService, MessageService, SortEvent} from "primeng/api";
import {CommonMethod} from "../../../util/CommonMethod";
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { FilterCommonMethod } from '../../../util/FilterCommonMethod';
import { DatePipe } from '@angular/common';
import { Paginator } from 'primeng/paginator';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from 'src/app/service/common/common-service';

const FILTER_TITLE = {
  ITEM: "Item Code",
  SOURCESYSTEM: "Source System",
  SOURCEREFNO: "Source Header Ref.",
  CHANNEL: "Channel",
  TRANSACTIONTYPE: "Transaction Type",
  STOCKCONDITION: "Stock Condition",
  DATE: "Date",
  FROMDATE: "From Date",
  TODATE: "Top Date",
  SOURCEHEADERNO: "Source Ref. No.",
  WAYBILL: "WayBill No.",
  ISONO: "ISO No.",
  SERIALNO: "Serial No."
}

@Component({
  selector: 'nz-test',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  @observable permission: string[]
  // @observable defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false

  @observable lisOfData: any[] = []

  @observable isVisible: boolean = false
  @observable dyHeight: string = '500px'

  channels: any[] = []
  channels2: any[] = []
  skus: any[] = []
  orderTypeList = [];
  sourceSystemList = [];

  queryPannel
  skuNum = []
  createAt
  stockStatus
  sourceSystem
  sourceTxnRefHeaderRef
  sourceRefNo
  repoId = ''
  toRepoId = ''
  logOrderNature = ''
  selectedChannel = [];
  selectedChannelDisplay = '';
  orderType: any[];
  wayBill
  isoNo
  serialNo
  pageIndex: number = 0
  pageSize: number = 20
  totalRecords = 0

  field
  order
  isSortSearch

  balance

  lastSearchData = {};

  _selectedColumns: any[] = [];
  cloneLine = {}
  line = {}
  searchParams = {}
  stock_status = [
    // {name: "FG", code: 1001, flag: null, other: null},
    // {name: "DEFECT", code: 1002, flag: null, other: null},
    // {name: "INTRA", code: 1003, flag: null, other: null},
    // {name: "RETURN-FG", code: 1004, flag: null, other: null},
    // {name: "RETURN-DEFECT", code: 1005, flag: null, other: null},
    // {name: "DEMO", code: 1006, flag: null, other: null}
  ]

  sourceHeaderNoLinkOrderTypeCodeList=[
    'CHANNEL-TRANSFER',
    'CHANNEL-TRANSFER-COURIER',
    'REPLENISHMENT',
    'RETURN',
    'WAREHOUSE-HOMED',
    'CHANNEL-HOMED',
    'CHANNEL-HOMED-RETURN',
    'WAREHOUSE-HOMED-RETURN'
  ]

  // natures = [
  //   {value: "", label: "ALL"},
  //   {label: "Assign(ASG)", value: "Assign(ASG)"}, {label: "Stock Threshold(STHR)", value: "Stock Threshold(STHR)"},
  //   {label: "Return(RET)", value: "Return(RET)"}, {label: "Exchange(EXC)", value: "Exchange(EXC)"},
  //   {label: "Advanced Reserve(ARS)", value: "Advanced Reserve(ARS)"}, {
  //     label: "Cancel Advance Reserve(CARS)",
  //     value: "Cancel Advance Reserve(CARS)"
  //   },
  //   {label: "Advance Pick Up(APU)", value: "Advance Pick Up(APU)"}, {
  //     label: "Replenishment Request(RREQ)",
  //     value: "Replenishment Request(RREQ)"
  //   },
  //   {label: "Replenishment Recvice(RREC)", value: "Replenishment Recvice(RREC)"}, {
  //     label: "Stock Out To Shop(SOTS)",
  //     value: "Stock Out To Shop(SOTS)"
  //   },
  //   {label: "Stock In From Shop(SIFS)", value: "Stock In From Shop(SIFS)"}, {
  //     label: "Stock Out To Warehouse(SOTW)",
  //     value: "Stock Out To Warehouse(SOTW)"
  //   },
  //   {
  //     label: "Stock In From Warehouse(SIFW)",
  //     value: "Stock In From Warehouse(SIFW)"
  //   }, {label: "Stock In Without PO(SIWPO)", value: "Stock In Without PO(SIWPO)"},
  //   {label: "Stock Take(ST)", value: "Stock Take(ST)"}, {
  //     label: "Stock Take Adjustment(STA)",
  //     value: "Stock Take Adjustment(STA)"
  //   },
  //   {label: "Stock Transfer Out(TRO)", value: "Stock Transfer Out(TRO)"}, {
  //     label: "Stock Transfer In(TRI)",
  //     value: "Stock Transfer In(TRI)"
  //   },
  //   {
  //     label: "Stock Transfer Warehouse(TRW)",
  //     value: "Stock Transfer Warehouse(TRW)"
  //   }, {label: "Stock Change Category(SCC)", value: "Stock Change Category(SCC)"},
  //   {label: "Stock Reserve(RES)", value: "Stock Reserve(RES)"}, {
  //     label: "Stock Cancel Reserve(CRES)",
  //     value: "Stock Cancel Reserve(CRES)"
  //   }
  // ]
  frozenWidth = "466px"
  frozenCols: any = [
    //   {header:'temp',title:'',width:'60px'},
    {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
    {header: 'sku', title: 'Item Code', width: '110px'},
    {header: 'skuDesc', title: 'Item Description', width: '300px'},
    // {header: 'uom', title: 'UOM', width: '100px'}
  ]
  cols = [
    {header: 'channelCode', title: 'Channel', width: '100px'},
    {header: 'createAt', title: 'Transaction Date', width: '170px', type: 'date'},
    {header: 'trxTypeName', title: 'Transaction Type', width: '200px'},
    {header: 'stockStatus', title: 'Stock Condition', width: '150px'},
    {header: 'uom', title: 'UOM', width: '100px'},
    {header: 'qty', title: 'Qty', width: '80px', type: 'qty'},
    // {header: 'logTxtBum', title: 'Transaction Number', width: '200px'},
    {header: 'sourceSystem', title: 'Source System', width: '140px'},
    {header: 'approvalDate', title: 'Approval Time', width: '150px', type: 'date'},
    {header: 'approvalBy', title: 'Approval By', width: '140px'},
    {header: 'staff', title: 'Staff', width: '100px'},
    {header: 'deliveryDate', title: 'Delivery Date', width: '140px', type: 'date'},
    {header: 'courier', title: 'courier', width: '100px'},
    {header: 'lot_expiry_date', title: 'lot_expiry_date', width: '100px'},
    {header: 'lot_number', title: 'lot_number', width: '100px'},
    {header: 'ccc', title: 'ccc', width: '100px'},
    {header: 'works_order', title: 'works_order', width: '100px'},
    {header: 'revenue_account_group', title: 'revenue_account_group', width: '100px'},
    {header: 'reason', title: 'Reason', width: '120px'},
    {header: 'remark', title: 'Remark', width: '150px'},
    // {header: 'stock_status_id', title: 'stock_status_id', width: '100px'},
    {header: 'interface_to_query_count', title: 'interface_to_query_count', width: '100px'},
    {header: 'interface_to_return_ref', title: 'interface_to_return_ref', width: '100px'},
    {header: 'interface_to_query_ref', title: 'interface_to_query_ref', width: '100px'},
    {header: 'interface_to_query_date', title: 'interface_to_query_date', width: '100px'},
    {header: 'interface_to_count', title: 'interface_to_count', width: '100px'},
    {header: 'interface_to_date', title: 'interface_to_date', width: '100px'},
    {header: 'interface_to_error_msg', title: 'interface_to_error_msg', width: '100px'},
    {header: 'interface_to_status', title: 'interface_to_status', width: '100px'},
    {header: 'interface_to_system', title: 'interface_to_system', width: '100px'},
    // {header:'sku',title:'Item',width:'100px'},
    // {header:'skuDesc',title:'Item Desc',width:'200px'},
    // {header:'uom',title:'UOM',width:'100px'},
    // {header: 'skuDesc', title: 'Item Desc', width: '260px'},
    // {header: 'toChannel', title: 'To Channel', width: '150px'},
    // {header: 'createAt', title: 'Date Time', width: '180px', type: 'date'},
    // {header: 'fromStatus', title: 'From Status', width: '150px'},
    // {header: 'toStatus', title: 'To Status', width: '150px'},
    // {header:'remark',title:'Desc'},
    // {header:'repoName',title:'Transaction Type'},
    // {header:'createAccountName',title:'CreateBy'},
    //{header:'trxTypeName',title:'Transaction Type',width:'140px'},
    {header: 'source_system_id', title: 'source_system_id', width: '100px'},
    {header: 'txnHeader', title: 'Source Ref. No.', width: '170px', type:'link', isClickableKey:'_txnHeaderLink', onClick:($e,data)=>{$e.stopPropagation();this.onDnRefNoClick(data)} },
    // {header: 'txnLine', title: 'Source Line', width: '130px'}
    {header: 'txnLine', title: 'Source Txn Line', width: '170px'},
    {header: 'source_txn_ref_header_ref', title: 'Source Header Ref.', width: '170px'},
    {header: 'group_id', title: 'Waybill No.', width: '170px'},
    {header: 'replenishReference', title: 'Replenish Reference', width: '170px'},
    {header: 'isoNo', title: 'ISO No.', width: '170px'},
    {header: 'deliveryBatchNo', title: 'Delivery Batch No.', width: '170px'},
    {header: 'id', title: 'ID', width: '80px'},
  ]
  colsDefalut = [
    {header: 'channelCode', title: 'Channel', width: '100px'},
    {header: 'createAt', title: 'Transaction Date', width: '170px', type: 'date'},
    {header: 'trxTypeName', title: 'Transaction Type', width: '200px'},
    {header: 'stockStatus', title: 'Stock Condition', width: '150px'},
    {header: 'uom', title: 'UOM', width: '100px'},
    {header: 'qty', title: 'Qty', width: '80px', type: 'qty'},
    {header: 'sourceSystem', title: 'Source System', width: '140px'},
    {header: 'txnHeader', title: 'Source Ref. No.', width: '170px', type:'link', isClickableKey:'_txnHeaderLink', onClick:($e,data)=>{$e.stopPropagation();this.onDnRefNoClick(data)} },
    {header: 'txnLine', title: 'Source Txn Line', width: '170px'},
    {header: 'source_txn_ref_header_ref', title: 'Source Header Ref.', width: '170px'},
    {header: 'group_id', title: 'Waybill No.', width: '170px'},
    {header: 'replenishReference', title: 'Replenish Reference', width: '170px'},
    {header: 'isoNo', title: 'ISO No.', width: '170px'},
    {header: 'deliveryBatchNo', title: 'Delivery Batch No.', width: '170px'},
    {header: 'id', title: 'ID', width: '80px'},
  ]
  // lineCols = [
  //   {header: 'courier', title: 'Courier'},
  //   {header: 'serial', title: 'Serial'},
  //   {header: 'expiryDate', title: 'Expiry Date'},
  // ]

  serialModalConfig: any = {
    header: '',
    itemCode: '',
    itemDesc: '',
    qty: 0,
    serialList: [],
    visible: false,
  };

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    let _valList = val.map(value=>JSON.stringify(value))
    this._selectedColumns = this.cols.filter(col => _valList.includes(JSON.stringify(col)));
    this.collapseColumns = [...this.frozenCols, ...this.selectedColumns];
  }
  collapseColumns

  constructor(public n: MessageService, r: Router, p: ActivatedRoute, public m: ConfirmationService,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public cdRef: ChangeDetectorRef,
  ) {
    this.selectedColumns = this.colsDefalut
    this.getPermission(p)
  }

  @action getPermission(p) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // console.log('>-- get permission --<', p.snapshot.data.name, this.permission);
  }

  onChange(e) {
    const len = this.skuNum.length;
    this.skuNum = len > 0 ? (this.skuNum[len - 1] === '0' ? ["0"] : this.skuNum.filter((item) => item !== "0")) : ["0"]
  }

  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss"  )
  }

  sortPageSearch(filterChanged = false) {
    if(this.loadingSwitch) {
      this.showMessage('warn', 'System', "Please wait until the execution is completed")
      return
    }
    let data: any = {}
    if(!filterChanged){
      data = this.lastSearchData
    }else if(filterChanged){
      let createAtIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.DATE);
      // let fromIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.FROMDATE);
      // let toIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.TODATE);
      let channelIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.CHANNEL);
      let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.ITEM);
      let sourceSystemIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.SOURCESYSTEM);
      let sourceTxnRefHeaderRef = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.SOURCEREFNO);
      let sourceRefNo = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.SOURCEHEADERNO);
      let stockStatusIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.STOCKCONDITION);
      let orderTypeIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.TRANSACTIONTYPE);
      let wayBill = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.WAYBILL);
      let isoNo = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.ISONO);
      let serialNo = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.SERIALNO);
      // if (fromIpt && toIpt && fromIpt.value && toIpt.value) {
      //   this.createAt = []
      //   this.createAt[0] = this.dateFormatToApi(fromIpt.value)
      //   this.createAt[1] = this.dateFormatToApi(toIpt.value)
      // }

      let _date = CommonMethod.sameDayAdd(createAtIpt.value)// [1,1]
      if (_date&&_date[0]&&_date[1]) {
        this.createAt = []
        this.createAt[0] = CommonMethod.dateToTime(_date[0]) + ' 00:00:00'
        // this.createAt[0] = this.dateFormatToApi(_date[0])
        this.createAt[1] = CommonMethod.dateToTime(_date[1]) + ' 23:59:59'
        // this.createAt[1] = this.dateFormatToApi(new Date(new Date(_date[1]).setHours(23, 59, 59))) // add 1 day
      }else{
        this.createAt = []
      }

      this.repoId = channelIpt.beforeSubmit?channelIpt.beforeSubmit(channelIpt.value):channelIpt.value
      this.skuNum = itemIpt.value ? itemIpt.value.map(item => item.code) : null
      this.stockStatus = stockStatusIpt.beforeSubmit?stockStatusIpt.beforeSubmit(stockStatusIpt.value):stockStatusIpt.value
      this.sourceSystem = sourceSystemIpt.beforeSubmit ? sourceSystemIpt.beforeSubmit(sourceSystemIpt.value): sourceSystemIpt.value
      this.sourceTxnRefHeaderRef = sourceTxnRefHeaderRef.value ? sourceTxnRefHeaderRef.value : null
      this.sourceRefNo = sourceRefNo.value ? sourceRefNo.value : null
      this.orderType = orderTypeIpt.beforeSubmit?orderTypeIpt.beforeSubmit(orderTypeIpt.value):orderTypeIpt.value
      this.wayBill = wayBill.value ? wayBill.value : null
      this.isoNo = isoNo.value ? isoNo.value : null
      this.serialNo = serialNo.value ? serialNo.value : null
      // let data = {
      //   "pageIndex": this.pageIndex, "pageSize": this.pageSize, "skuNum": this.skuNum,
      //   "createAt": this.createAt, "repoId": this.repoId, "toRepoId": this.toRepoId, "logOrderNature": this.logOrderNature
      // }
      data = {
        ...data,
        accountName: this.commonService.getAccount(),
        // pageIndex: this.pageIndex,
        pageIndex: !filterChanged?this.pageIndex:0,
        pageSize: this.pageSize,
        ...(this.skuNum && {skuNum: this.skuNum}),
        ...(this.createAt && {createAt: this.createAt}),
        ...(this.repoId && {channelId: this.repoId}),
        ...(this.logOrderNature && {logOrderNature: this.logOrderNature}),
        ...(this.stockStatus && {stockStatusId: this.stockStatus}),
        ...(this.sourceSystem && {sourceSystem: this.sourceSystem}),
        ...(this.sourceTxnRefHeaderRef && {sourceTxnRefHeaderRef: this.sourceTxnRefHeaderRef}),
        ...(this.sourceRefNo && {sourceRefNo: this.sourceRefNo}),
        ...(this.orderType && {sourceTxnType: this.orderType}),
        ...(this.wayBill && {groupId: this.wayBill}),
        ...(this.isoNo && {isoNo: this.isoNo}),
        ...(this.serialNo && {serialNo: this.serialNo}),
      }
    }
    data = {
      ...data,
      accountName: this.commonService.getAccount(),
      pageIndex: !filterChanged?this.pageIndex:0,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order},
    };
    this.lastSearchData = data;
    this.loadingSwitch = true
    this.commonService.httpPost(URLDICT.STOCK_MOVEMENT_SORTSEARCH, data)
    // HttpHelper.post(URLDICT.STOCK_MOVEMENT_SORTSEARCH, data)
    .then(res => {
      if (res.code == '000') {
        if(this.paginatorSubject && filterChanged) this.paginatorSubject.next('reset');
        this.lisOfData = res.data.map(res1=>{return {
          ...res1,
          _serialList: res1.serial?res1.serial.split(',').map(serial=>serial.trim()):null
        }})
        this.lisOfData = this.preProcessData(this.lisOfData)
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch = false
    }).catch(e => console.log(e)).finally(
      () => this.loadingSwitch = false
    )
  }

  doSortSearchBySortFunction(key, order){
    if(this.field==key && order!=null && this.order == order) return
    this.sortSearch(key, order)
  }

  sortSearch(key, order) {
    this.isSortSearch = true
    this.field = key
    if(order!=null){
      this.order = order
    }else if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    this.sortPageSearch()
  }

  refresh() {
    this.skuNum = []
    // this.createAt = null
    // this.initCreateAt();
    this.repoId = null
    this.toRepoId = null
    this.logOrderNature = null
    this.queryPannel.ipts.forEach((item) => {
      item.value = null
      if (item.showValue) {
        item.showValue = null
      }
    });

    // let stockConditionIpt = this.queryPannel.ipts.find(item => item.title === FILTER_TITLE.STOCKCONDITION);
    // stockConditionIpt.value = [this.stock_status.find(status=>status.name=='FG')]
    // let dateIpt = this.queryPannel.ipts.find(item => item.title === FILTER_TITLE.DATE);
    // dateIpt.value = this.createAt;
    // dateIpt.showValue = this.createAt;
    // this.search()
    // this.sortPageSearch()
  }

  inintSelectData(list) {
    return list.map((item) => {
      let {label: name, value: code, ...other} = item
      return {name, code, ...other}
    });
  }

  ngOnInit(): void {
    this.initPaginatorSub()
    this.channels = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
    this.channels2 = JSON.parse(localStorage.getItem("REPOTREE_ACTIVE"))
    this.skus = JSON.parse(localStorage.getItem("SKU"))
    this.sourceSystemList = JSON.parse(localStorage.getItem("SOURCESYSTEM")).map(val=>{return {name: val.code, value: val.code, _data: val}})
    this.initCreateAt()
    this.initChannel()
    this.queryPannel = {
      ipts: [
        {
          title: FILTER_TITLE.ITEM,
          value: "",
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-6",
          dataKey: 'code',
          skipAllCheckMatch: true,
          // options: this.inintSelectData(this.skus),
          options: [],
        },
        {
          title: FILTER_TITLE.CHANNEL,
          value: this.selectedChannel,
          showValue: this.selectedChannelDisplay,
          type: INPUT_TYPE.MUTIPLETREESELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          options: this.channels,
          beforeSubmit: function(value) { return value?value.map(val=>val.id).filter(id=>id>0):null },
          change: function(value, ipt) {
            if(Array.isArray(value) ){
              ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
            }
          }
        },
        {
          title: FILTER_TITLE.DATE,
          type: INPUT_TYPE.DATERANGE,
          class: "p-col-12 p-md-4 p-lg-3",
          // showTime: true,
          // hideSeconds: true,
          // defaultDate: new Date(new Date().setHours(0, 0, 0)),
          value: this.createAt
          // showButtonBar: true,
          // todayButtonStyleClass: "p-d-none",
          // clearButtonStyleClass: "p-button-text p-ml-auto",
        },
        {
          title: FILTER_TITLE.TRANSACTIONTYPE,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-6",
          value: null,
          options: this.orderTypeList,
          // optionValue: 'value',
          beforeSubmit: function(value) { return value? value.map(val=>val.value):null },
        },
        {
          title: FILTER_TITLE.STOCKCONDITION,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          options: this.stock_status,
          optionValue: 'code',
          beforeSubmit: function(value) { return value?value.map(val=>val.code):null },
        },
        // {
        //   title: "Stock Condition",
        //   type: INPUT_TYPE.INPUT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        // },
        {
          title: FILTER_TITLE.WAYBILL,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: FILTER_TITLE.SOURCESYSTEM,
          type: INPUT_TYPE.SELECT,
          options: this.sourceSystemList,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          optionValue: 'value',
          beforeSubmit: function(value) { return value?value:null },
        },
        {
          title: FILTER_TITLE.SOURCEHEADERNO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: FILTER_TITLE.SOURCEREFNO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: FILTER_TITLE.ISONO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: FILTER_TITLE.SERIALNO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        // {
        //   title: FILTER_TITLE.TODATE,
        //   type: INPUT_TYPE.DATETIME,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   showTime: true,
        //   hideSeconds: true,
        //   defaultDate: new Date(new Date().setHours(23, 59, 59)),
        //   // value: this.createAt[1]
        //   showButtonBar: true,
        //   todayButtonStyleClass: "p-d-none",
        //   clearButtonStyleClass: "p-button-text p-ml-auto",
        // },
        // {
        //   title: "Stock Status",
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: this.stock_status,
        //   optionValue: 'code'
        // },

      ],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-12",
      btns: [
        {
          title: 'Clear',
          class: "p-button-outlined p-ml-auto p-mr-1",
          handler: {
            'click': () => {
              this.refresh()
              // this.search()
              // this.sortPageSearch()
            }
          }
        },
        {
          category: 'Export',
          title: 'Export',
          class: "p-mr-1",
          handler: {
            "click": () => {
              this.exportXlsx()
            }
          }
        },
        {
          category: 'primary',
          title: 'Search',
          class: "p-order-1",
          handler: {
            "click": () => {
              // this.search()
              this.sortPageSearch(true)
              // console.log('coumst search');
            }
          }
        },
      ],
    }
    this.initOrderType()
    this.field = "id"
    this.order = -1
    // this.search()
    this.getItemListBySkuModule().subscribe(res=>{
      if(res){
        let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.title==FILTER_TITLE.ITEM);
        itemIpt.options = res;
      }
    })
    this.stockCommonService.getStockConditions().subscribe(res=>{
      this.stock_status = res.map(item=>{
        return {name: item.description, code: item.code, _data: item}
      })
      let ipt = this.queryPannel.ipts.find(item => item.title === FILTER_TITLE.STOCKCONDITION);
      ipt.options = this.stock_status;
      ipt.value = [this.stock_status.find(status=>status.code==CONFIG['CONDITION_CODE']['FG'])]
      //this.sortPageSearch(true)
    })
  }

  onClose() {
    const createDate = this.queryPannel.ipts.find(item => item.title === FILTER_TITLE.DATE);
    if (!createDate.value) {
      // skip show message when datepicker close when value is empty
      // this.showMessage("warn", "System", "Please select the time!");
    } else {
      // if (!createDate.value[1]) {
      //   this.showMessage("warn", "System", "Please select the end time!");
      // }
    }
  }

  change(idx, event, ipt) {
    // console.log('change data', idx, event, ipt);
    if(ipt.change){
      ipt.change(event, ipt)
    }
  }

  convertDate(d) {
    return CommonMethod.convertDate(d)
  }


  customSort(event: SortEvent) {
    // this.lisOfData = event.data.slice().sort((data1, data2) => {
    //   let value1 = data1[event.field];
    //   let value2 = data2[event.field];
    //   let result = null;

    //   if (value1 == null && value2 != null)
    //     result = event.order;
    //   else if (value1 != null && value2 == null)
    //     result = -1 * event.order;
    //   else if (value1 == null && value2 == null)
    //     result = 0;
    //   else if (typeof value1 === 'string' && typeof value2 === 'string')
    //     result = value1.localeCompare(value2);
    //   else
    //     result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //   return (event.order * result);
    // });
    this.doSortSearchBySortFunction(event.field, event.order)
  }

  // timestampToTime(timestamp) {
  //   return CommonMethod.timestampToTime(timestamp)
  // }

  page(e) {
    // console.log(e);
    if(e===null)return
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    let data: any = {...this.searchParams}
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    this.sortPageSearch();
    // if (this.isSortSearch) this.sortPageSearch()
    // else this.getDataFromServer(data)
  }

  showMessage(severity, summary, detail) {
    this.n.clear()
    this.n.add({severity, summary, detail});
  }

  setSerialModalVisible(e){
    this.serialModalConfig.visible = e;
  }

  showTxnSerial(rowData){
    if(rowData._serialList){
      this.serialModalConfig.serialList = rowData._serialList.map(serial=>{
        return {"serial" : serial}
      });
      this.serialModalConfig.itemCode = {value: rowData.sku, disabled: true, title: 'Item Code'};
      this.serialModalConfig.itemDesc = {value: rowData.skuDesc, disabled: true, title: 'Item Desc.'};
      this.serialModalConfig.qty = {value: rowData.qty, disabled: true, title: 'Item Qty'};
      this.serialModalConfig.header = `Transaction ID: ${rowData.txnHeader?rowData.txnHeader:'N/A'}`;
      this.setSerialModalVisible(true);
    }
  }

  getItemListBySkuModule(){
    return this.stockCommonService.getItemListBySkuModule()
  }

  initChannel(){
    try{
      let channel = CommonMethod.getDefaultChannel(this.channels)
      if(channel){
        this.selectedChannel = [channel];
      }else{
        this.selectedChannel = null;
      }
      this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
    }catch(e){console.error(e)}
  }

  initCreateAt(){
    let today = new Date(new Date().setHours(0,0,0,0))
    //let startDate = new Date(today.getTime() - 30*24*60*60*1000)
    this.createAt = [today]
  }

  initOrderType(){
    // if(this.selectedChannel.length>0){return this.stockCommonService.getAllOrderType().subscribe(
    //   res=>{
    //     this.orderTypeList = res.map(type=>{ return {name: type.description, value: type.id, code: type.orderTypeCode}});
    //     let ipt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.TRANSACTIONTYPE);
    //     ipt.options = this.orderTypeList;
    //   },
    //   e=>{
    //     this.showMessage('error', 'System', e.toString())
    //   }
    // )}else{
    //   return {name: '', value: '', code: ''}
    // }
    this.orderTypeList = LocalStorageHelper.getObject("HISTORY_TRANSACTION_TYPE").map(type=>{ return {name: type.description, value: type.id, code: type.orderTypeCode}});
    let ipt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.TRANSACTIONTYPE);
    ipt.options = this.orderTypeList;
  }

  paginatorSubject = new Subject()

  @ViewChild('paginator', {static: false}) paginator: Paginator;
  resetPaginator(){
    if(!this.paginator) return;
    var pc = this.paginator.getPageCount();
    this.paginator.first = 0;
    this.paginator.updatePageLinks();
    this.paginator.onPageChange.emit(null); // paginator display not update without this line
    this.paginator.updatePaginatorState();
    this.paginator.pageLinks = [...this.paginator.pageLinks]
    this.cdRef.markForCheck();
  }
  paginatorSub: Subscription
  initPaginatorSub(){
    if(this.paginatorSubject){
      if(this.paginatorSub) {
        this.paginatorSub.unsubscribe();
        this.paginatorSub = null;
      }
      this.paginatorSub = this.paginatorSubject.subscribe(e=>{
        if(e=='reset'){
          this.resetPaginator()
        }
      })
    }
  }
  ngOnDestroy() { if(this.paginatorSub)this.paginatorSub.unsubscribe() }

  onDnRefNoClick(e){
    this.stockCommonService.getDmDeliveryDoc({trxHistId:e.id}).subscribe(res=>{
      if(res.code=='000'){
        // let blob = this.base64toBlob(res.data[0].fileBase64)
        // this.saveData(blob,`DM_Delivery_Note_${e.sourceRefNo}.pdf`)
        let blob = CommonMethod.base64toBlob(res.data[0].fileBase64)
        CommonMethod.saveBlobData(blob,`DM_Delivery_Note_${e.txnHeader}.pdf`)
        // window.open(url);
      }else{
        this.showMessage('error', NOTI_TITLE, res.message || res.msg)
      }
    },err=>{
      this.showMessage('error', NOTI_TITLE, 'An Unexpected Error Occurred')
    },()=>{
    })
  }
  preProcessData(data){
    data.forEach(e => {
      let txnTypeList = CONFIG.TXNHISTORY_SOURCEREFNO_HYPERLINK_FILTER_TXN_TYPE_WHITELIST;
      let sourceSystemList = CONFIG.TXNHISTORY_SOURCEREFNO_HYPERLINK_FILTER_SOURCE_SYSTEM_WHITELIST;
      e._data = e;
      e._txnHeaderLink = (txnTypeList.indexOf(e.source_txn_type)>-1) && (sourceSystemList.indexOf(e.sourceSystem)>-1)
      e.trxTypeName = this.orderTypeList.find((type)=>{return type.code==e._data.trxTypeName})?.name||''
    });
    return data
  }

  exportXlsx(){
    let data = {
      ...this.lastSearchData,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT,
    }

    if (!data.hasOwnProperty('createAt') || data['createAt'].length == 0){
      this.showMessage('warn', NOTI_TITLE, 'Transaction Date should be input!')
      return;
    }

    this.loadingSwitch = true
    this.stockCommonService.getTransHistory(data).subscribe(res=>{
      this.loadingSwitch = false
      if (res.code == '000') {
        let lisOfData = res.data.map(res1=>{return {
          ...res1,
          _serialList: res1.serial?res1.serial.split(',').map(serial=>serial.trim()):null
        }})
        lisOfData = this.preProcessData(lisOfData)
        let serialList = this.splitSerialListForExportXlsx(lisOfData)
        let _cols = [...this.frozenCols, ...this.selectedColumns].filter(e=>e.title!='').map(e=>{
          return {
            ...e,
            ...(e.type=='date'?{type:'datetime'}:{})
          }
        })
        let serialCols = [..._cols, {header: '_splitedSerial', title: 'Serial'}]
        CommonMethod.downloadXlsxWithMultiSheetWithOptions(
          [lisOfData, serialList],
          [_cols, serialCols],
          ['Transaction History', 'Serial'],
          `transaction_history_${this.datepipe.transform(new Date(), 'YYYYMMDDhhmmss')}`,
          this.datepipe,
          [{fieldKey: 'header'},{fieldKey: 'header'}]
        )
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', NOTI_TITLE, res.msg)
      }
    },err=>{
      this.showMessage('error', NOTI_TITLE, err)
      this.loadingSwitch = false
    })
  }

  splitSerialListForExportXlsx(list){
    let res = []
    list.forEach(history=>{
      history._serialList?.forEach(serial => {
        res.push({
          ...history,
          _splitedSerial: serial,
        })
      });
    })
    return res
  }
}
