import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { makeAutoObservable } from "mobx";
import { MessageService, ConfirmationService } from "primeng/api";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { HttpHelper } from "src/app/util/HttpHelper";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { CONFIG, INPUT_TYPE, URLDICT } from "../../base/BaseStore";
import { CommonMethod } from "@/util/CommonMethod";
import { DatePipe } from "@angular/common";

@Injectable()
export class ItemMasterStore {
  queryData = {
    ipts: [
      {
        title: "Item Code",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        name: "skuNum",
        dataKey: "code",
        skipAllCheckMatch: true,
        value: null,
        options: [],
      }, {
        title: "Item Desc",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null
      }
    ],
    btns: [
      {
        title: 'Search',
        class: "p-order-2",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.searchFromFilter() }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      }
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-6'
  }
  tableData = {
    btns: [
    ],
    data: [],
    columns: [
      {field: 'itemCode', title: 'Item Code', width: '120px'},
      {field: 'description', title: 'Description', width: '120px'},
      {field: 'bu', title: 'BU', width: '80px'},
      {field: 'lob', title: 'LOB', width: '80px'},
      {field: 'epcAdminGroup', title: 'EPC Admin Group', width: '150px'},
      {field: 'manuBrand', title: 'Manu Brand', width: '120px'},
      {field: 'model', title: 'Model', width: '80px'},
      {field: 'productType', title: 'Product Type', width: '120px'},
      {field: 'mainCategory', title: 'Main Category', width: '150px'},
      {field: 'subCategory', title: 'Sub Category', width: '120px'},
      {field: 'subSubCategory', title: 'Sub Sub Category', width: '150px'},
      {field: 'uom', title: 'UOM', width: '80px'},
      {field: 'sourceSystem', title: 'Source System', width: '150px'},
      {field: 'isInventoryAsset', title: 'Is Inventory Asset', width: '150px'},
      {field: 'isStockable', title: 'Is Stockable', width: '120px'},
      {field: 'isReservableInCSTK', title: 'Is Reservable in CSTK', width: '180px'},
      {field: 'isReservableInLIS', title: 'Is Reservable in LIS', width: '160px'},
      {field: 'isReturnable', title: 'Is Returnable', width: '120px'},
      {field: 'isTangible', title: 'Is Tangible', width: '120px'},
      {field: 'isConsigned', title: 'Is Consigned', width: '120px'},
      {field: 'isLotControlled', title: 'Is Lot Controlled', width: '150px'},
      {field: 'isSerialControl', title: 'Is Serial Control', width: '150px'},
      {field: 'planner', title: 'Planner', width: '120px'},
      {field: 'creationDate', title: 'Creation Date', width: '150px', type: 'datetime'},
      {field: 'createdUserName', title: 'Created By', width: '120px'},
      {field: 'updatedDate', title: 'Updated Date', width: '150px', type: 'datetime'},
      {field: 'updatedUserName', title: 'Updated By', width: '120px'},
      {field: 'itemId', title: 'Item Id', width: '120px'},
      {field: 'lisCatalogGroup', title: 'Lis Catalog Group', width: '150px'},
      {field: 'lisProductFamily', title: 'Lis Product Family', width: '150px'},
      {field: 'lisItemType', title: 'Lis Item Type', width: '120px'},
      {field: 'lisItemCategory', title: 'Lis Item Category', width: '150px'},
      {field: 'lisBudgetCategory', title: 'Lis Budget Category', width: '170px'},
      {field: 'lisInventoryType', title: 'Lis Inventory Type', width: '150px'},
      {field: 'lisProductNature', title: 'Lis Product Nature', width: '150px'},
      {field: 'reservationDaysAllowed', title: 'Reservation Days Allowed', width: '200px'},
      {field: 'hktBrand', title: 'hkt Brand', width: '150px'},
      {field: 'isDropShipment', title: 'is Drop Shipment', width: '150px'},
      {field: 'revAccGroupCode', title: 'rev Account Group Code', width: '150px'},
      {field: 'productSubType', title: 'Product Sub Type', width: '150px'},
      {field: 'isReplenishable', title: 'is Replenishable', width: '150px'},
      {field: 'sdlEpcStatusId', title: 'sdl Epc Status Id', width: '150px'},
      {field: 'channelStockControl', title: 'channel Stock Control', width: '180px'},
      {field: 'isActive', title: 'is Active', width: '150px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [],
    frozenWidth: '',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "creationDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
  }
  paginator = null
  selectRequest = null
  searchAll = true
  resetSearch = false
  searchCache = {}
  requestDialog = false
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission:string[]
  dialogTitle = ""
  lastSearchParams = {};
  constructor(private router: Router, private route: ActivatedRoute, private msg: MessageService, private conf: ConfirmationService,
    private stockCommonService: StockCommonService,
    public datePipe: DatePipe,
  ) {
    makeAutoObservable(this)
    this.getPermission(route)
    this.setSelectedColumns()
    this.initQueryData()
  }
  //computed
  get skuNum() {
    return this.queryData.ipts[0].value
  }
  get skuDesc() {
    return this.queryData.ipts[1].value
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
  }
  setSelectedColumns() {
    this.tableData.visibleColumns = this.tableData.columns
  }
  initQueryData() {
    this.getItemListBySkuModule().subscribe(res=>{
      if(res) this.queryData.ipts[0].options = res
    })

    this.search(true)
  }
  getSearchParams() {
    const params = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      ...(this.skuDesc && {skuDesc: this.skuDesc}),
      ...(this.skuNum && {skuNum: this.skuNum.map(item => item.code)}),
      sortEvent: this.tableData.sortField
    }
    return params
  }
  // comeFrom 用于识别search是否来自filter面板
  search(comeFrom = false) {
    let data: any = this.getSearchParams()
    if(comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }

    this.tableData.loadingSwitch = true
    this.lastSearchParams = data;
    HttpHelper.post(URLDICT.STOCK_ITEM_MASTER_SEARCH, data).then(res =>{
      if(res.code === '000'){
        this.tableData.data = res.data
        this.tableData.totalRecords = res.msg
      } else {
        this.showMessage('error', 'System', res.message || res.msg)
      }
    }).catch(e => {
      this.showMessage('error', e.error, e.message)
    }).finally(
      () => this.tableData.loadingSwitch = false
    )
  }
  clear() {
    this.queryData.ipts.forEach(item => {
      item.value = null
    })

    this.tableData.pageIndex = 0
    this.tableData.pageSize = 20
    this.tableData.sortField = { field: "creationDate", order: -1 }
  }
  toDetailPage(url, data ) {
    this.router.navigate(url, { queryParams: data })
  }
  create() {
    const info = {
      type: 'create',
      value: null
    }
    sessionStorage.setItem('allocationDetailData', JSON.stringify(info))
    this.toDetail(info)
  }
  viewDetail(data, idx) {
    const info = {
      type: 'view',
      value: data
    }
    data.type = 'view'
    sessionStorage.setItem('allocationDetailData', JSON.stringify(info))
    this.toDetail(data)
  }

  toDetail(data) {
    this.toDetailPage(['main','stock_allocation','detail'], data)
  }

  sortSearch(field, order) {
    let key = this.tableData.sortField.field
    let _order = this.tableData.sortField.order
    if(field==key && _order!=null && order == _order) return
    this.tableData.sortField.field = field
    this.tableData.sortField.order = order
    this.search()
  }
  // 当从搜索区点击search时, 将分页设置为从0开始
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search(this.resetSearch ? true : false)
    this.resetSearch = false
  }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  getItemListBySkuModule(){
    return this.stockCommonService.getItemListBySkuModule()
  }
  tableSelectedColumnsChange(e){
    this.tableData.visibleColumns = this.tableData.columns.filter(col => e.includes(col));
  }
  setLoading(e){
    this.tableData.loadingSwitch = e
  }
  exportXlsx(){
    let params = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT-1,
    }
    this.setLoading(true)
    this.stockCommonService.getItemMaster(params).subscribe(res=>{
      this.setLoading(false)
      CommonMethod.downloadXlsxWithOptions(
        res,
        this.tableData.columns,
        `Item_Master_${this.datePipe.transform(new Date(),'yyyyMMddHHmmss')}`,
        this.datePipe,
        {fieldKey: 'field'}
      )
    },err=>{
      this.setLoading(false)
      this.showMessage('error', 'System', err)
    })
  }
}
