import { Injectable } from "@angular/core";
import { INPUT_TYPE , NOTI_MSG, NOTI_TITLE, URLDICT} from "src/app/stores/base/BaseStore";
import { MENU } from "src/app/config";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { ExtApiRequestLogService } from "src/app/service/stock/ext-api-request-log.service";
import { DatePipe } from "@angular/common";
import { find, pluck, switchMap } from "rxjs/operators";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import {observable} from "mobx";
import { ItemMasterStore } from "../item/itemMasterStore";
import { of } from "rxjs";
import { JsonDisplayPopUpComponent } from "@/components/json-display-pop-up/json-display-pop-up.component";

@Injectable()
export class ExtApiRequestLogStore {
  @observable selectedRow: any[] = []
  @observable loadingSwitch = false
  orderId
  requestJson
  reponseJson
  popUpVisable = false
  jsonResult
  breadcrumb = MENU.EXT_API_REQUEST_LOG
  queryData = {
    ipts: [
      {
        title: "Request date",
        type: INPUT_TYPE.DATERANGE,
        class: "p-col-12 p-md-4 p-lg-3",
      }, {
        title: "action (api method)",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: "Source System",
        name: "sourceSystem",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: "Source Reference No",
        name: 'orderId',
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Request url",
        name: 'requesturl',
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        option:[]
      },
      {
        title: "Request Body",
        name: 'RequestJson',
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
   },
   {
     title: "Response Json",
     name: 'ReponseJson',
     type: INPUT_TYPE.INPUT,
     class: "p-col-12 p-md-4 p-lg-3",
     value: null,
   },
    ],
    btns: [
      {
        title: 'Search',
        class: "p-order-4",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.searchFromFilter() }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'action', title: 'Action(API Method)', width: '150px'},
      {field: 'request_url', title: 'Request Url', width: '150px',type:'link'},
      {field: 'request_body', title: 'Request Body', width: '120px',type:'link'},
      {field: 'request_datetime', title: 'Request Date', width: '150px'},
      {field: 'end_date_time', title: 'End Date', width: '150px'},
      {field: 'run_date_time', title: 'Run Date', width: '150px'},
      {field: 'response_json', title: 'Response Json', width: '80px',type:'link'},
      {field: 'source_system_id', title: 'Source system', width: '160px'},
      {field: 'source_system_name', title: 'Source system name', width: '160px'},
      {field: 'source_ref_header_no', title: 'Source System Header No', width: '150px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    initSortField:  'request_date',
    initSortOrder:  1,
    sortField: { field: 'request_date', order: 1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
  }
  showEdit: false
  table = null
  paginator = null
  ActionOptions = [
    {name: 'get', code: 'get'},
    {name: 'post', code: 'post'},
    {name: 'put', code: 'put'},
    {name: 'patch', code: 'patch'},
    {name: 'delete', code: 'delete'},
    {name: 'copy', code: 'copy'},
    {name: 'head', code: 'head'},
    {name: 'options', code: 'options'},
    {name: 'link', code: 'link'},
    {name: 'unlink', code: 'unlink'},
    {name: 'purge', code: 'purge'},
    {name: 'lock', code: 'lock'},
    {name: 'unlock', code: 'unlock'},
    {name: 'propfind', code: 'propfind'},
    {name: 'view', code: 'view'},
  ]
  UrlOptions = []
  defaultSourceSystem = []
  defaultUrl=[]
  apiSourceSystemOptions = []
  sourceSystem = []
  requesturl=[]
  requestUrlOptions=[]

  //source_system_name
  dateIpt = null
  permission = null
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private datePipe: DatePipe,
    private conf: ConfirmationService,
    private serive: ExtApiRequestLogService) {
    // makeAutoObservable(this)
    this.getPermission()
    this.getResolverData();
    this.setSelectedColumns()
    this.initQueryData()
  }
  get requestDate() {
    return this.dateIpt.value;
  }

  get action() {
    let actionIpt = this.getIptByQueryData('action (api method)')
    return actionIpt.value;
  }

  get apiSourceSystem() {
    let sourceSystemIpt = this.getIptByQueryData('Source System')
    return sourceSystemIpt.value;
  }
   get apiRequesturl() {
    let requestUrlIpt = this.getIptByQueryData('Request url')
    return requestUrlIpt.value;
  }
  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
  }

  getResolverData() {
      let sourceSystem = LocalStorageHelper.getObject('SOURCESYSTEM')
      this.sourceSystem = sourceSystem
      this.apiSourceSystemOptions = sourceSystem.map(item => {
        let id =  item.sys_def_lookup_id
        let code=item.code
        return {name:code, value:id}
      })

      let requestUrl = LocalStorageHelper.getObject('REQUESTURL')
      this.requesturl=requestUrl
      this.requestUrlOptions = requestUrl.map(item => {
        let url=item.request_url
        return {name:url}
      })
    }

  getIptByQueryData(name) {
    return this.queryData.ipts.find( ipt =>  ipt.title === name)
  }
  setSelectedColumns() {
    this.tableData.selectedColumns = this.tableData.columns
  }
  initSort() {
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder
  }
  initQueryData() {
    this.dateIpt = this.getIptByQueryData('Request date')
    let ActionIpt = this.getIptByQueryData('action (api method)')
    ActionIpt.options = this.ActionOptions
    //ActionIpt.value = ''

    let URLIpt = this.getIptByQueryData('Request url')
    URLIpt.options = this.requestUrlOptions
    URLIpt.value = this.requestUrlOptions.filter(item => this.defaultUrl.includes(item.name))

    let SourceSystemIpt = this.getIptByQueryData('Source System')
    SourceSystemIpt.options = this.apiSourceSystemOptions

    SourceSystemIpt.value = this.apiSourceSystemOptions.filter(item => this.defaultSourceSystem.includes(item.code))
    //this.search()
  }

  searchFromFilter() {
    this.table.reset()
    this.initSort()
    this.paginator ? this.paginator.changePage(0) : this.search()
  }

  clear() {
    for (const ipt of this.queryData.ipts) {
if (ipt.title === 'Source System') {
        ipt.value = this.apiSourceSystemOptions.filter(item => this.defaultSourceSystem.includes(item.code))
      } else {
        ipt.value = null
      }
    }
  }

  sortSearch(field) {
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = -this.tableData.sortField.order;
    this.search()
  }

  search() {
    this.orderId = this.queryData.ipts.find(item => item.title === "Source Reference No").value;
    //this.requesturl=this.queryData.ipts.find(item => item.title === "Request url").value;
    // 如果用户只选择了一个日期（from date），则将to date也定为同一天
    this.requestJson = this.queryData.ipts.find(item => item.title === "Request Body").value;
    this.reponseJson = this.queryData.ipts.find(item => item.title === "Response Json").value;
    if(this.requestDate && !this.requestDate[1]) {
      this.requestDate[1] = this.requestDate[0]
    }

    let data = {
      ...(this.requestDate && {request_datetime: this.requestDate.map(item=>this.formatDate(item))}),
      ...(this.action && this.action.length>0 && {ifaction: this.action.map(item=>item.name)}),
      ...(this.apiSourceSystem && {source_system_id: this.apiSourceSystem.map(item=>item.value)}),
      ...(this.orderId && {source_ref_header_no: this.orderId}),
      ...(this.apiRequesturl && {requesturl: this.apiRequesturl.map(item=>item.name)}),
      ...(this.requestJson && {requestJson: this.requestJson}),
      ...(this.reponseJson && {reponseJson: this.reponseJson}),
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
    }
    this.tableData.loadingSwitch = true
    this.serive.search(data).subscribe(res => {
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        let data=res.data
        data.forEach((item, idx) => {
          let SourceSystemResult=this.sourceSystem.find((x) => x.sys_def_lookup_id == item.source_system_id)
          if(SourceSystemResult)item.source_system_name=SourceSystemResult.code
        });
        this.tableData.totalRecords = res.msg
        this.tableData.data = data
        //console.log(res.data)
      } else {
        this.tableData.data = []
        this.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    })
  }

  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search()
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }
  jsonShowDetail(json){
    this.popUpVisable=true
    this.jsonResult=json
  }

  popupHide(e){this.popUpVisable = e}
}
