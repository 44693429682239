import { Component, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SpreadsheetComponent } from 'src/app/components/spreadsheet-table/spreadsheet-table.component';
import { CreateSpreadsheetStore } from 'src/app/stores/stock/replenishment/CreateSpreadsheetStore';
import { PreAllocateCommonComponent } from '../components/pre-allocate-common/pre-allocate-common.component';
import { VirtualScroller } from 'primeng/virtualscroller';

@Component({
  providers: [CreateSpreadsheetStore],
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class ReplenishmentCreateSpreadsheetComponent implements AfterViewInit {

  @ViewChild("spreadSheet") spreadSheet: SpreadsheetComponent;
  @ViewChild("overlay") overlay: OverlayPanel;
  @ViewChild(PreAllocateCommonComponent) preAllocateCommonComponent: PreAllocateCommonComponent;
  @ViewChild("overlayVirtualScroller") overlayVirtualScroller: VirtualScroller;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.store.beforeunload(event)
  }

  constructor(public store: CreateSpreadsheetStore, public cdr: ChangeDetectorRef, public elRef: ElementRef) { 
    let that = this;
    this.store.setFnGetOverlayVirtualScroller(()=>that.overlayVirtualScroller)
  }

  ngAfterViewInit(){
    this.store.setSpreadSheet(this.spreadSheet)
    this.store.setOverlayPanel('overlay', this.overlay)
    // this.store.setOverlayPanel('sku', this.sku)
    this.cdr.detectChanges()
    this.store.setPreAllocateCommonComponent(this.preAllocateCommonComponent)
    this.store.setelRef(this.elRef)
  }
}
