import { Component } from '@angular/core';
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {Oauth2Config} from "./config/Oauth2Config";
import * as packageJson from '../../package.json';
import { CommonStore } from './service/common/common-store-service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  constructor(private oauthService: OAuthService, private commonStore: CommonStore) {
    this.configure()
    this.commonStore.setVersion(packageJson.version)
  }

  async login() {
     this.oauthService.initLoginFlow();
  }

  public configure() {
    this.oauthService.configure({
      issuer: Oauth2Config.issuer,
      redirectUri: window.location.origin + "/loading",
      // loginUrl:'http://localhost:4200/api/system/login',
      // logoutUrl:'http://localhost:8080/system/logout',
      clientId: Oauth2Config.clientId,
      //dummyClientSecret: '2ea5dc66-8e1c-4e7c-aac0-52a42594a6ac',
      scope: 'openid email profile',
      responseType: Oauth2Config.responseType,
      requireHttps: Oauth2Config.requireHttps,
      // at_hash is not present in JWT token
      disableAtHashCheck: Oauth2Config.disableAtHashCheck,
      //postLogoutRedirectUri: window.location.origin + "/heroes",
      showDebugInformation: Oauth2Config.showDebugInformation
    });
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
