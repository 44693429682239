import { Injectable } from "@angular/core";
import { INPUT_TYPE } from "src/app/stores/base/BaseStore";
import { MENU } from "src/app/config";
import { HistoryStore } from "src/app/stores/stock/movement/HistoryStore";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { StockMovementHistoryService } from "src/app/service/stock/stock-movement-history.service";
import { makeObservable, observable, action } from "mobx";
import { DatePipe } from '@angular/common';
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { CommonService } from "@/service/common/common-service";
import { CommonMethod } from "@/util/CommonMethod";
import { LoadingService } from '@/service/common/loading-service';
import {forkJoin, of} from "rxjs";
import {DataMigrationService} from "@/service/common/data-migration-service";
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DailyStockMovementHistoryStore extends HistoryStore{
  breadcrumb = MENU.STOCK_MOVEMENT_HISTORY

  loadingService = new LoadingService();

  queryData = {
    ipts: [
      {
        title: this.QUERY_TITLE.BATCH_ID,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        maxLength: 17
      }, {
        title: this.QUERY_TITLE.IMPORT_STATUS,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'value',
        error: false,
      }, {
        title: this.QUERY_TITLE.SHOP_CODE,
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        showValue: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'id',
      },
    ],
    btns: [
      {
        title: this.BUTTON_TITLE.CLEAR,
        class: "p-ml-auto p-button-outlined p-order-2 p-mr-1 p-as-end",
        show: true,
        disabled: false,
        handler: { click: () => this.clear() }
      }, {
        title: this.BUTTON_TITLE.EXPORT,
        class: "p-order-3 p-mr-1 p-as-end",
        show: true,
        disabled: false,
        handler: { click: () => this.export() }
      }, {
        title: this.BUTTON_TITLE.SEARCH,
        class: "p-order-3 p-mr-1 p-as-end",
        show: true,
        disabled: false,
        handler: { click: () => this.search() }
      }
    ],
    btnsclass: 'p-d-flex p-col-3 vertical-container'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'batchId', title: this.TABLE_FIELD.BATCH_ID, parent: { title: this.TABLE_FIELD.CSTK, colspan: 5 }, width: '130px', exportValue: true},
      {field: 'importId', title: this.TABLE_FIELD.TRANSACTION_ID, parent: true, width: '150px', exportValue: true},
      {field: 'importStatus', title: this.TABLE_FIELD.IMPORT_STATUS, parent: true, width: '145px', exportValue: true},
      {field: 'processDatetime', title: this.TABLE_FIELD.PROCESS_DATE_TIME, parent: true, width: '175px', exportValue: true},
      {field: 'errorMsg', title: this.TABLE_FIELD.ERROR_MESSAGE, parent: true, width: '145px', exportValue: true},
      {field: 'stockTrxHistoryId', title: this.TABLE_FIELD.WAYBILL, parent: { title: this.TABLE_FIELD.PPOS, colspan: 13 }, width: '100px', exportValue: true},
      {field: 'shopCode', title: this.TABLE_FIELD.SHOP_CODE, parent: true, width: '130px', exportValue: true},
      {field: 'smNum', title: this.TABLE_FIELD.SM_NUMBER, parent: true, width: '140px', exportValue: true},
      {field: 'orderType', title: this.TABLE_FIELD.ORDER_TYPE, parent: true, width: '130px', exportValue: true},
      {field: 'sku', title: this.TABLE_FIELD.ITEM_CODE, parent: true, width: '120px', exportValue: true},
      {field: 'productLine', title: this.TABLE_FIELD.PRODUCT_LINE, parent: true, width: '140px', exportValue: true},
      {field: 'qty', title: this.TABLE_FIELD.QTY, parent: true, width: '85px', exportValue: true},
      {field: 'serialNum', title: this.TABLE_FIELD.SERIAL_NUMBER, parent: true, width: '150px', exportValue: true},
      {field: 'dateTime', title: this.TABLE_FIELD.TRANSACTION_DATE_TIME, parent: true, width: '195px', exportValue: true},
      {field: 'stockCategory', title: this.TABLE_FIELD.STOCK_CATEGORY, parent: true, width: '150px', exportValue: true},
      {field: 'costccc', title: this.TABLE_FIELD.CCC, parent: true, width: '80px', exportValue: true},
      {field: 'costwo', title: this.TABLE_FIELD.WORK_ORDER_NUMBER, parent: true, width: '175px', exportValue: true},
      {field: 'resubmit', title: this.TABLE_FIELD.RE_SUBMIT, parent: true, width: '130px', exportValue: true},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [],
    frozenWidth: '',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'importId',
    initSortOrder: -1,
    sortField: { field: "importId", order: -1 },
    selectedProducts: null,
    editShow: true,
  }
  // statusToFields = {
  //   Y: 'Succeed',
  //   E: 'Error'
  // }
  batchIdIpt = null
  importStatusIpt = null
  shopCodeIpt = null
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  loading:boolean = false
  constructor (
    datePipe: DatePipe,
    router: Router,
    route: ActivatedRoute,
    service: StockMovementHistoryService,
    msg: MessageService,
    conf: ConfirmationService,
    public commonService: CommonService,
    public dataMigrationService: DataMigrationService,
    public messageService: MessageService,
    ) {
    super(datePipe, router, route, service, msg, conf, commonService)
    makeObservable(this, {
      breadcrumb: observable,
      queryData: observable,
      tableData: observable,
      // statusToFields: observable,
      batchIdIpt: observable,
      importStatusIpt: observable,
      shopCodeIpt: observable,
      loading: observable,
      search: action,
      export: action,
      clear: action,
      sortSearch: action,
      page: action,
    })
    this.getPermission(route)
    this.initQueryIpt()
    this.search()
  }
  get batchId() {
    return this.batchIdIpt.value
  }
  get importStatus() {
    return this.importStatusIpt.value
  }
  get shopCode() {
    return this.shopCodeIpt.value
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }
  setPermission(list) {
    list.forEach(item => {
      if(item.permissionType) {
        item.title === this.BUTTON_TITLE.EXPORT && (item.disabled = !this.permission.includes('Export'))
        item.title === this.BUTTON_TITLE.SEARCH && (item.disabled = !this.permission.includes('Search'))
      }
    })
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  initQueryIpt() {
    this.batchIdIpt = this.getQueryByTitle(this.QUERY_TITLE.BATCH_ID)
    this.importStatusIpt = this.getQueryByTitle(this.QUERY_TITLE.IMPORT_STATUS)
    const status = [
      {label: 'All', value: ''},
      {label: 'Succeed', value: 'Y'},
      {label: 'Error', value: 'E'},
    ]
    this.importStatusIpt.options = status
    this.shopCodeIpt = this.getQueryByTitle(this.QUERY_TITLE.SHOP_CODE)
    const shop =  JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo => repo.children.length)
    this.shopCodeIpt.options = shop
  }
  search() {
    this.loading = true
    this.tableData.loadingSwitch = true
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      accountName: this.account,
      ...(this.batchId && {batchId: this.batchId}),
      ...(this.importStatus && {importStatus: this.importStatus}),
      ...(this.shopCode && {shopCode: this.shopCode.id})
    }
    this.service.search(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        res.data.forEach(item => {
          item.importStatus = item.importStatus && item.importStatus === "Y" ? "Succeed" : "Error"
          item.processDatetime = item.processDatetime && this.dateFormatToApi(new Date(item.processDatetime))
          item.dateTime = item.dateTime && this.dateFormatToApi(new Date(item.dateTime))
        });
        list = res.data
        this.tableData.totalRecords = res.msg
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message || this.info.apiError)
      }
      this.tableData.data = list
    })
  }

  export() {
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      accountName: this.account,
      ...(this.batchId && {batchId: this.batchId}),
      ...(this.importStatus && {importStatus: this.importStatus}),
      ...(this.shopCode && {shopCode: this.shopCode.id})
    }
    // alert(data)
    this.service.searchForExport(data).pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'PPOS_stock_movement_history_CSTK.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', '', 'PPOS_stock_movement_history_CSTK - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', 'this.title', err)
    })
  }

  addMessage(severity, summary, detail){
    this.messageService.add({severity:severity,summary:summary,detail:detail})
  }

  clear() {
    this.queryData.ipts.forEach(item => {
      item.value = null
      if(item.showValue) {
        item.showValue = null
      }
    })
  }
  sortSearch(field) {
    console.log(field);

    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
    this.search()
  }
  page(e){
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search()
  }
}
