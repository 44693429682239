import { LocalStorageHelper } from "@/util/LocalStorageHelper";
import { Injectable, Injector } from "@angular/core";
import { FUNCTION_CODE } from "./permission.type";

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  permission
  permissionCodeDetails: {[string: string]: any[]}
  constructor(private injector: Injector) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS');
    this.permissionCodeDetails = LocalStorageHelper.getObject('UAM_FUNCTION_PERMISSIONS');
  }

  clear(){
    this.permission=[]
    this.permissionCodeDetails={}
  }

  setPermission(){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS');
    this.permissionCodeDetails = LocalStorageHelper.getObject('UAM_FUNCTION_PERMISSIONS');
  }

  getPermissionDetails(key: FUNCTION_CODE){return this.permissionCodeDetails[(<string>key)]}

  havePermission(key: FUNCTION_CODE){
    let permissionList = this.getPermissionDetails(key)
    return permissionList!=undefined && permissionList.length > 0
  }
}
