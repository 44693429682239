import { CONFIG } from "@/stores/base/BaseStore";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
/**
 * @description: 输入框
 * @author:  ken
 */
@Component({
  selector: "app-input-scanner-cst",
  templateUrl: "./input-scanner-cst.component.html",
  styleUrls: ["./input-scanner-cst.component.scss"],
})
export class InputScannerCustomComponent {
  @Input() ipt;
  @Input() style;
  @Output() change = new EventEmitter();

  @Input() tryHarder = CONFIG.SCANNERTRYHARDER

  modalConfig: any = {};
  init = false
  deviceSelected: string;
  deviceCurrent: MediaDeviceInfo;
  availableDevices: MediaDeviceInfo[];
  hasPermission;
  hasDevices;
  isScannerEnable = false;
  tryharder = false

  closeScannerTimer

  @ViewChild("scanner", { static: false }) scanner: ZXingScannerComponent;
  scannerObj = null;

  formats = CONFIG.SCANNERFORMAT
  SCANNERTRYHARDER = CONFIG.SCANNERTRYHARDER

  ngOnChanges(changes){
    if(changes['tryHarder'] && this.tryHarder != null){
      this.SCANNERTRYHARDER = this.tryHarder
    }
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null;
    if (selectedDevice&&this.deviceSelected === selectedDevice.deviceId) {
      return;
    }
    if(selectedDevice)this.deviceSelected = selectedDevice.deviceId;
    this.deviceCurrent = device || undefined;
  }

  onCodeResult(res: string) {
    this.ipt.value = res;
    this.setModalVisible(false);
    this.onInput(res);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = [...devices];
    this.hasDevices = Boolean(devices && (devices.length));
    this.deviceCurrent = this.availableDevices[0];
    this.tryharder = false;
  }

  onDeviceSelectChange(selected) {
    const selectedStr = selected.value || "";
    let device = this.availableDevices.find(
      (x) => x.deviceId === selectedStr
    );
    device = (device.deviceId == ''? undefined:device=device)
    this.deviceCurrent = device || undefined;
  }
  
  toggleScanner(e){
    this.isScannerEnable = e;
    if(e==false && this.scanner&&this.scanner.enable==true){
      this.scanner.enable = false;
    }else if(e==false && this.scannerObj&&this.scannerObj.enable==true){
      this.scannerObj.enable = false;
    }
  }

  setModalVisible(e) {
    let that = this;
    if (this.scanner) {
      this.scannerObj = this.scanner;
      this.scanner.reset();
    }
    if(e) this.init = true;
    if(!e) this.deviceCurrent = undefined
    this.modalConfig.visible = e;
    this.toggleScanner(e)
    if(!e){
      this.autoCloseScannerIfModalClosed()
    }else{
      if(this.closeScannerTimer){
        clearTimeout(this.closeScannerTimer)
      }
    }
  }
  
  onInput(v) {
    this.change.emit(v);
  }

  autoCloseScannerIfModalClosed(){
    if(this.closeScannerTimer){
      clearTimeout(this.closeScannerTimer)
    }
    if(!this.modalConfig.visible&&this.scannerObj&&this.scannerObj.isAutostarting){
      if(this.scannerObj&&this.scannerObj._enabled == true){
        this.closeScannerTimer = setTimeout(()=>{
          if(!this.modalConfig.visible){
            if(this.scannerObj.isAutostarting){
              this.autoCloseScannerIfModalClosed()
              return
            }
            if(this.scannerObj&&this.scannerObj._enabled == true){
              this.scannerObj.reset();
              this.scannerObj.enable = false;
            }
          }
        }, 500)
      }
    }
  }
  scanFailure(e){
  }
  scanError(e){
  }
  scanComplete(e){
  }
}
