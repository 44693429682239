import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
  * @description: 多选框
  * @author:  wtw
  */
@Component({
  selector: 'app-checkboxt-cst',
  templateUrl: './checkbox-cst.html',
  styles:[]
})
export class CheckboxCustom {
  @Input() ipt
  @Output() change = new EventEmitter()
  onChange(e){
    console.log('ipt',this.ipt, this.ipt.value);
    this.change.emit(this.ipt.value)
  }
}
