import { Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE , NOTI_MSG, NOTI_TITLE } from "src/app/stores/base/BaseStore";
import { MENU } from "src/app/config";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { StockLisInterfaceService } from "src/app/service/stock/stock-lis-interface.service";
import { DatePipe } from "@angular/common";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import {observable} from "mobx";
import { CommonMethod } from "@/util/CommonMethod";
import { LoadingService } from "@/service/common/loading-service";
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";

const INTERFACESTATUS={
  TIMEOUT:79,
  PENDING:55,
  SUCCESSFUL:56,
  FAILURE:57
}

const TITLE = {
  transDate: "Transaction Date",
  process: "Process",
  lisInterfaceStatus: "LIS Interface Status",
  itemCode: "Item Code",
  fromWarehouse: "From Warehouse",
  toWarehouse: "To Warehouse",
  transType: "Transaction Type",
  shipmentNo: "Shipment No.",
  waybill: "Waybill No."
}

@Injectable()
export class LisInterfaceStore {
  @observable selectedRow: any[] = []
  @observable loadingSwitch = false
  public m: ConfirmationService
  public loadingService = new LoadingService();
  popUpVisable = false
  jsonResult
  lisEditEnable
  breadcrumb = MENU.STOCK_TRANSACTION_LIS_INTERFACE
  queryData = {
    ipts: [
      {
        title: TITLE.transDate,
        type: INPUT_TYPE.DATERANGE,
        class: "p-col-12 p-md-4 p-lg-3",
      }, {
        title: TITLE.process,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionValue: 'code',
      }, {
        title: TITLE.lisInterfaceStatus,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.itemCode,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        appendTo: 'body',
      }, {
        title: TITLE.fromWarehouse,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.toWarehouse,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.transType,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.shipmentNo,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.waybill,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }
    ],
    btns: [
      {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      },
      {
        title: 'Export',
        class: "p-mr-1",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.export() }
      },
      {
        title: 'Search',
        class: "",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.searchFromFilter() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'waybill', title: 'Waybill No.', width: '120px'},
      {field: 'api_run_time', title: 'LIS Interface Time', width: '180px', type: 'datetime'},
      {field: 'apiRunStatus', title: TITLE.lisInterfaceStatus, width: '180px'},
      {field: 'error_msg', title: 'Error Message', width: '250px', tooltip:true, type:'link'},
      {field: 'transaction_type', title: 'Transaction Type', width: '160px'},
      {field: 'item_code', title: 'Item Code', width: '120px'},
      {field: 'transaction_qty', title: 'Qty', width: '80px'},
      {field: 'from_warehouse', title: 'From Warehouse', width: '150px'},
      {field: 'override_from_warehouse', title: 'Over-ride From Warehouse', width: '220px', type:'input',},
      {field: 'to_warehouse', title: 'To Warehouse', width: '150px'},
      {field: 'override_to_warehouse', title: 'Over-ride To Warehouse', width: '200px', type:'input',},
      {field: 'cost_code', title: 'CCC', width: '90px'},
      {field: 'override_cost_code', title: 'Over-ride CCC', width: '140px', type:'input', maxLength: 4 },
      {field: 'work_order', title: 'Work Order', width: '120px'},
      {field: 'override_work_order', title: 'Over-ride Work Order', width: '180px', type:'input', maxLength: 8 },
      {field: 'shipment_num', title: 'Shipment No.', width: '190px'},
      {field: 'transaction_date', title: 'Transaction Date', width: '150px'},
      {field: 'is_process', title: 'CSTK Process(Y/N)', width: '180px'},
      {field: 'retry_count', title: 'LIS Interface Retry count', width: '210px'},
      {field: 'is_interface', title: 'LIS interface (Y/N)', width: '170px'},
      {field: 'lis_txn_id', title: 'LIS Txn ID', width: '120px'},
      {field: 'lis_import_time', title: 'LIS Import Time', width: '160px', type: 'datetime'},
      {field: 'id', title: 'Trx ID', width: '80px'},
      {field: 'update_date', title: 'Update Date', width: '160px', type: 'datetime'},
      {field: 'delete_remark', title: 'Delete Remark', width: '160px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
      {header: 'CheckBox', field:'checkBox', title: '', type: 'CheckBox', width: '64px', sortable: false},
      {header: 'action', title: 'Action', type: 'action', width: '100px', sortable: false},
    ],
    frozenWidth: '220px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    initSortField:  'update_date',
    initSortOrder:  -1,
    sortField: { field: 'update_date', order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
  }
  //showEdit: false
  table = null
  paginator = null
  processOptions = [
    {name: 'All', code: 'All'},
    {name: 'Y', code: 'Y'},
    {name: 'N', code: 'N'},
  ]
  defaultStatus = [55, 57, 79] // default of lis interface status
  apiRunStatusOptions = []
  _interfaceStatusMapping = {}
  dateIpt = null
  permission = null
  SysDefLookupDescResult
  sku=[]
  txnType=[]
  lastSearchParams
  
  clonedLines = {}; // use for reset line input value when cancel edit
  editShow: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private datePipe: DatePipe,
    private conf: ConfirmationService,
    private serive: StockLisInterfaceService,
    private permissionService: PermissionService
    ) {
    // makeAutoObservable(this)
    this.getPermission()
    this.getResolverData();
    this.setSelectedColumns()
    this.initQueryData()
    this.resetDeleteModalConfig()
  }
  get transactionDate() {
    return this.dateIpt.value;
  }

  get process() {
    let processIpt = this.getIptByQueryData(TITLE.process)
    return processIpt.value;
  }

  get apiRunStatus() {
    let statusIpt = this.getIptByQueryData(TITLE.lisInterfaceStatus)
    return statusIpt.value;
  }

  get itemCode() {
    let ipt = this.getIptByQueryData(TITLE.itemCode)
    return ipt.value;
  }

  get transactionTypeList() {
    let ipt = this.getIptByQueryData(TITLE.transType)
    return ipt.value;
  }

  get fromWarehouse() {
    let ipt = this.getIptByQueryData(TITLE.fromWarehouse)
    return ipt.value;
  }

  get toWarehouse() {
    let ipt = this.getIptByQueryData(TITLE.toWarehouse)
    return ipt.value;
  }

  get shipmentNum() {
    let ipt = this.getIptByQueryData(TITLE.shipmentNo)
    return ipt.value;
  }

  get waybill() {
    let ipt = this.getIptByQueryData(TITLE.waybill)
    return ipt.value;
  }
  
  get loading(){return this.loadingService.loading}

  getPermission() {
    //this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
    this.setPermission()
  }

  getResolverData() {
    try{
    // this.route.data.pipe(pluck('query')).subscribe(res => {
      let status = LocalStorageHelper.getObject('INTERFACE_STATUS')
      this.apiRunStatusOptions = status.map(item => {
        item.label =  item.label.toLocaleUpperCase()
        let { label:name, value: code} = item
        return {name, code}
      })
      this._interfaceStatusMapping = {}
      this.apiRunStatusOptions.forEach(option=>{
        this._interfaceStatusMapping[option.code]=option
      })
    // })

    // this.route.data.pipe(pluck('query')).subscribe(res => {
      let LookUpDesc = LocalStorageHelper.getObject('SYS_DEF_LOOKUP_DESC')
      this.SysDefLookupDescResult=LookUpDesc.map(item => {
        item.value =  item.value
        item.name = item.name
        let { value:value, name: name} = item
        return {value, name}
      })
    // })

    this.sku = LocalStorageHelper.getObject('SKU').map(sku=>{return {name:`${sku.name}~${sku.flag}`,code:sku.code, itemCode: sku.name}});

    this.txnType = LocalStorageHelper.getObject('LIS_INTERFACE_TXN_TYPE').map(type=>{return {name:type.code,code:type.code}});
    }catch(e){console.error(e)}
  }
  getIptByQueryData(name) {
    return this.queryData.ipts.find( ipt =>  ipt.title === name)
  }
  setSelectedColumns() {
    this.tableData.selectedColumns = this.tableData.columns
  }
  initSort() {
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder
  }
  initQueryData() {
    this.dateIpt = this.getIptByQueryData(TITLE.transDate)

    let processIpt = this.getIptByQueryData(TITLE.process)
    processIpt.options = this.processOptions
    processIpt.value = 'All'

    let statusIpt = this.getIptByQueryData(TITLE.lisInterfaceStatus)
    statusIpt.options = this.apiRunStatusOptions

    let itemIpt = this.getIptByQueryData(TITLE.itemCode)
    itemIpt.options = this.sku
    let txnTypeIpt = this.getIptByQueryData(TITLE.transType)
    txnTypeIpt.options = this.txnType

    statusIpt.value = this.apiRunStatusOptions.filter(item => this.defaultStatus.includes(item.code))
    //this.search()
  }

  searchFromFilter() {
    // this.table.reset()
    // this.initSort()
    this.paginator ? this.paginator.changePage(0) : this.search()
  }

  clear() {
    for (const ipt of this.queryData.ipts) {
      if(ipt.title === TITLE.process) {
        ipt.value = 'All'
      } else if (ipt.title === TITLE.lisInterfaceStatus) {
        ipt.value = this.apiRunStatusOptions.filter(item => this.defaultStatus.includes(item.code))
      } else {
        ipt.value = null
      }
    }
  }

  sortSearch(_field) {
    let order = this.tableData.sortField.order
    let field = this.tableData.sortField.field
    if(order == -1 || field != _field || order == null){
      order = 1
    } else if(order == 1) {
      order = -1
    }
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = order;
    this.search()
  }



  search() {
    //console.log(this.SysDefLookupDescResult)
    // 如果用户只选择了一个日期（from date），则将to date也定为同一天
    if(this.transactionDate && !this.transactionDate[1]) {
      this.transactionDate[1] = this.transactionDate[0]
    }
    let itemCode = this.sku.find(_sku=>_sku.code==this.itemCode)?.itemCode
    let data = {
      ...(this.transactionDate && {transactionDate: this.transactionDate.map(item=>this.formatDate(item))}),
      ...(this.process && {isProcess: this.process}),
      ...(this.apiRunStatus.length && {apiRunStatus: this.apiRunStatus.map(item=>item.code)}),
      ...(this.fromWarehouse && this.fromWarehouse.length && {fromWarehouse: this.fromWarehouse}),
      ...(this.toWarehouse && this.toWarehouse.length && {toWarehouse: this.toWarehouse}),
      ...(this.shipmentNum && this.shipmentNum.length && {shipmentNum: this.shipmentNum}),
      ...(this.waybill && this.waybill.length && {waybill: this.waybill}),
      ...(this.transactionTypeList && this.transactionTypeList.length && {transactionTypeList: this.transactionTypeList.map(type=>type.name)}),
      ...(itemCode && itemCode.length && {itemCode: itemCode}),
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
    }
    if(data.isProcess === 'All') {
      delete data.isProcess
    }
    this.setLoading(true);
    this.serive.search(data).subscribe(res => {
      this.setLoading(false);
      if (res.code === '000') {
        this.lastSearchParams = data;
        this.clonedLines = {};
        res.data.forEach((item, idx) => {
          item.transaction_date = item.transaction_date.substring(0, 4) + '/' + item.transaction_date.substring(4, 6) + '/'+item.transaction_date.substring(6)
          //retry_count cannot hardcode, should change it later
          if(item.api_run_status==INTERFACESTATUS.FAILURE){
            item.isEdit = true
            item.isInput=true
          }else{
            item.isEdit = false
            item.isInput = false
          }
          if((item.api_run_status==INTERFACESTATUS.FAILURE||item.api_run_status==INTERFACESTATUS.TIMEOUT) /* ||
            (item.is_process === 'N' && item.is_interface === 'N') ||
            (item.is_process === 'Y' && item.is_interface === 'Y' && !item.lis_txn_id) */) {
            item.isSubmit=true
          }else{
            item.isSubmit=false
          }

          if(item.api_run_status==INTERFACESTATUS.SUCCESSFUL||item.api_run_status==INTERFACESTATUS.TIMEOUT||item.api_run_status==INTERFACESTATUS.PENDING){
            item.isInput=false
          }else{
            item.isInput=true
          }

          // item.id = item.id || idx
          item.apiRunStatus = this.apiRunStatusOptions.find(el => el.code === item.api_run_status).name
        });
        this.tableData.totalRecords = res.msg
        this.tableData.data = res.data
        this.selectedRow=null
      } else {
        this.tableData.data = []
        this.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    },err=>{
      this.setLoading(false);
    })
  }

  // (1) (is_process = 'Y' and is_interface = 'N') or (is_process='N' and is_interface='N'), when click, call LIS api 10u
  // (2) (is_process='Y' and is_interface='Y' and lis_txn_id=null), when click, call LIS api 10e
  save(data, index) {
    //old code for submit button
    /* this.setLoading(true);
    const call = {
      u10: {
        name: 'submitCall10u',
        desc: 'call LIS api 10u'
      },
      e10: {
        name: 'submitCall10e',
        desc: 'call LIS api 10e'
      }
    }
    let selType = null
    if ((data.is_process === 'Y' && data.is_interface === 'N') || (data.is_process === 'N' && data.is_interface === 'N')) {
      selType = call.u10
    }
    if (data.is_process === 'Y' && data.is_interface === 'Y' && !data.lis_txn_id) {
      selType = call.e10
    }
    if(selType) {
      this.serive[selType.name](data.id).subscribe(res => {
        this.setLoading(false);
        if (res.code === '000') {
          this.showMessage('info', 'submit', selType.desc + ' successful')
          this.searchFromFilter()
        } else {
          this.showMessage('warn', 'submit', selType.desc + ' failed')
        }
      })
    } */

  }
    SuccessCounter = 0
    //self call based on checkbox ticked index
    reSubmit (list, index){
      let data = list[index]
      const call = {
        u10: {
          name: 'submitCall10u',
          desc: 'call LIS api 10u'
        },
        e10: {
          name: 'submitCall10e',
          desc: 'call LIS api 10e'
        }
      }

      let selType = null
      
      if ((data.is_process === 'Y' && data.is_interface === 'N') || (data.is_process === 'N' && data.is_interface === 'N')) {
        selType = call.u10
      }
      if (data.is_process === 'Y' && data.is_interface === 'Y' && !data.lis_txn_id) {
        selType = call.e10
      }
      if(selType) {
        this.setLoading(true);
        this.serive[selType.name](data.id)
        //console.log(list[index], index)//for test
        //of({code:'000',msg:'',state:'success',data:[]})//fot test
        .subscribe(res => {
          this.SuccessCounter++
          this.setLoading(false);
          if (res.code === '000') {
            if(list[index+1]){
                this.reSubmit(list,index+1)//self call based on checkbox ticked index
                this.showMessage('info','waybill: '+list[index+1].waybill +'  Re-submited',' successful')       
            }else{
              this.selectedRow = []
              this.showMessage('info', 'submit', selType.desc + ' successful')
              //this.showMessage('info',this.SuccessCounter +'  Record inputed,call api completed',' successful')
              this.searchFromFilter()
            }
          } else {
            this.selectedRow = []
            this.searchFromFilter()
            this.showMessage('info', 'Submitd', this.SuccessCounter + ' successful')
            this.showMessage('warn', 'Submit', selType.desc + ' failed')
          }
        })
      }
  }   

  save2() {
    this.SuccessCounter=0
    let filtedSelectedRow = this.selectedRow.filter(item=>item.isSubmit)
    let len = filtedSelectedRow.length
    if (len < 1) {
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    }else {
      this.conf.confirm({
        message: `Do you want to resubmit these ${len} record?</br> (Please don't close this page, it might take serveral minutes to complete.)`,
        header: 'Resubmit Confirmation',
         accept: () => {
           this.reSubmit(filtedSelectedRow, 0)//send data to resubmit
        } 
      });
    }
  }



  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search()
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyyMMdd')
  }

  sortFunction(e){
    if(
      this.tableData.sortField.field == e.field &&
      this.tableData.sortField.order == e.order
    ) {
      return
    }else{
      this.tableData.sortField.field = e.field
      this.tableData.sortField.order = e.order
      this.search()
    }
  }

  export(){
    let data = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT,
    }
    this.serive.search(data).subscribe(res=>{
      let data = res.data.map(data=>{
        return {
          ...data,
          apiRunStatus: this._interfaceStatusMapping[data.api_run_status].name
        }
      })
      let col = this.tableData.selectedColumns
      CommonMethod.downloadXlsx(data,col,`LIS_Interface_${( this.datePipe.transform(new Date(), 'yyyyMMddHHmmss'))}`, this.datePipe)
    })
  }
  
  editDetail(data) {
    this.clonedLines[data.id] = {...data};
    data.isEdit = false
    data.isCancel = true;
    data.isSave = true;
    //console.log('view request data', this.clonedLines);
  }

  cancel(data, idx) {
    data.isEdit = true
    data.isCancel = false;
    data.isSave = false;
    this.tableData.columns.filter(e=>e.type=='input').forEach(col=>{
      data[col.field] = this.clonedLines[data.id][col.field]
    })
    // data.override_cost_code = this.clonedLines[data.id].override_cost_code
    delete this.clonedLines[data.id];
  }
  saveLineData(d, idx) {
    d.isEdit = true
    d.isCancel = false;
    d.isSave = false;

    const {newid, edit, ...swapDate} = d;
    let data = {...swapDate, id: d.id};
    /* data.createdDate = data.createdDate.getTime()
        data.fulfilledDate = data.fulfilledDate.getTime()
        data.endDateActive = data.endDateActive.getTime() */
    delete data._data;
    this.editShow = true;
    this.setLoading(true);
    this.serive.updateLisInterfaceLine(data).subscribe(res => {
      this.setLoading(false);
      if (res.code === '000') {
        // d.edit = false
        // d.id = data.id
        // delete d.newid
        // this.changeitem(idx, data)
        //this.updateRowData(d.id).subscribe(()=>{},(err)=>{},()=>{this.setLoading(false);;});
        // this.search()
        // this.clear()
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', res.msg)
        this.resetQty(d, idx)
      }
    },err=>{
      this.showMessage('error', 'System', err.toString())
      this.resetQty(d, idx)
      this.setLoading(false);
    })
  }
  resetQty(data, idx){
    if(this.clonedLines[data.id]){
      this.tableData.columns.filter(e=>e.type=='input').forEach(col=>{
        data[col.field] = this.clonedLines[data.id][col.field]
      })
    }
  }

  setLoading(e){
    this.loadingService.setLoadingBoolean(e);
  }
  
  // delete lis interface item
  deleteModalConfig
  resetDeleteModalConfig(){
    this.deleteModalConfig={
      visible: false,
      title: 'Confirm Delete With Remark',
      remarkIpt: {
        title: "Delete Remark",
        value: null,
        disabled: false,
        // type: INPUT_TYPE.TEXTAREA,
        rows: 5,
        class: "textarea-min-width-100 textarea-min-height-1 p-mt-2 p-d-block",
      },
    }
  }

  onDeleteModalHide(){
    this.resetDeleteModalConfig()
  }
  openConfirmDeleteModal(){
    let _selectedRow = this.selectedRow
    if (!_selectedRow || _selectedRow.length <= 0) {
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    }
    this.deleteModalConfig.visible = true
  }
  hideConfirmDeleteModal(){
    this.deleteModalConfig.remarkIpt.value = ''
    this.deleteModalConfig.visible = false
  }
  delete(){
    let _selectedRowIds = this.selectedRow?this.selectedRow.map(e=>e.id):null
    if (!_selectedRowIds || _selectedRowIds.length <= 0) {
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    }
    let remark = this.deleteModalConfig.remarkIpt.value
    if(!remark || remark.length == 0){
      this.showMessage('warn', NOTI_TITLE, 'Remark is required.')
      return
    }
    
    this.loadingService.setLoadingBoolean(true);
    this.serive.deleteByIds(_selectedRowIds, remark).subscribe(res=>{
      this.showMessage('info', 'Delete Success', `${_selectedRowIds.length} Stock Transaction LIS interface Marked As Deleted`)
      this.loadingService.setLoadingBoolean(false);
      this.hideConfirmDeleteModal()
      this.searchFromFilter()
    },err=>{
      this.loadingService.setLoadingBoolean(false);
      this.showMessage('warn', NOTI_TITLE, err)
    })
  }

  jsonShowDetail(json){
    this.popUpVisable=true
    this.jsonResult=json
  }

  popupHide(e){this.popUpVisable = e}
  setPermission(...e){
    let permissionService = this.permissionService
    if(permissionService.havePermission(FUNCTION_CODE.LIS_INTERFACE_EDIT)){
      this.lisEditEnable=true;
    }
  }

}
