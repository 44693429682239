import { QtyOnhandSerialStore } from "./QtyOnhandSerialStore";
import { makeAutoObservable } from "mobx";
import { Injectable } from "@angular/core";
import { MENU } from "../../../config";
import { INPUT_TYPE } from "../../base/BaseStore";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { ActivatedRoute } from "@angular/router";
import { QtyOnhandSerialService } from "@/service/stock/qty-onhand-serial.service";
import { CommonMethod } from "@/util/CommonMethod";

@Injectable()
export class SearchStore {
  breadcrumb = MENU.QTY_ONHAND_SERIAL;
  queryData = {
    ipts: [
      {
        title: this.common.TITLE.CHANNEL,
        type: INPUT_TYPE.MUTIPLETREESELECT,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        showValue: null,
        options: [],
        optionLabel: 'typeName',
        optionValue: 'id',
      }, {
        title: this.common.TITLE.ITEM_CODE,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        skipAllCheckMatch: true,
        dataKey: 'code',
        options: [],
      } , {
        title: this.common.TITLE.SERIAL_NO,
        type: INPUT_TYPE.INPUTSCAN,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        options: [],
      }
    ],
    btns: [
      {
        title: "Search",
        class: "p-order-3",
        show: true,
        disabled: true,
        handler: { click: () => this.searchFromFilter() },
      },
      {
        title: "Clear",
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clear() },
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-md-12",
  };
  tableData = {
    btns: [],
    data: [],
    columns: [
      { field: "channelCode", title: "Channel", width: "200px" },
      { field: "itemCode", title: "item Code", width: "200px" },
      { field: "stockConditionCode", title: "Stock Condition", width: "200px" },
      { field: "serialNumber", title: "Serial Number", width: "200px" }
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [],
    frozenWidth: "",
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "channelCode", order: 1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: "single",
    initSortField: "channelCode",
    initSortOrder: 1,
    selectedProducts: null,
    editShow: true,
  };

  permission: string[];
  loading: boolean = false;
  searchCache = {}
  resetSearch = false
  paginator = null
  constructor(
    private common: QtyOnhandSerialStore,
    private route: ActivatedRoute,
    private service: QtyOnhandSerialService
  ) {
    makeAutoObservable(this);
    this.getPermission(route);
    this.tableData.selectedColumns = [...this.tableData.columns];
    this.getResolverData();
    this.setSelectedColumns()
    // this.search(true);
  }
  get channel() {
    const ipt = this.getQueryByTitle(this.common.TITLE.CHANNEL);
    return ipt.value;
  }
  get itemCode() {
    const ipt = this.getQueryByTitle(this.common.TITLE.ITEM_CODE);
    return ipt.value;
  }
  get serialNo() {
    const ipt = this.getQueryByTitle(this.common.TITLE.SERIAL_NO);
    return ipt.value;
  }
  setSelectedColumns() {
    this.tableData.visibleColumns = this.tableData.columns
  }
  getQueryByTitle(title) {
    return this.queryData.ipts.find((item) => item.title === title);
  }
  getPermission(p) {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[p.snapshot.data.code];
    this.setPermission(this.queryData.btns);
  }

  setPermission(list) {
    list.forEach((item) => {
      // 这里的权限是强行指定的（需后端配置）
      item.title === 'Search' && (item.disabled = false )//!this.permission.includes("Search"))
    });
  }
  getResolverData() {
    this.common.getResolverData(this.route);
    let channelIpt = this.getQueryByTitle(this.common.TITLE.CHANNEL);
    channelIpt.options = this.common.channels
    let itemIpt = this.getQueryByTitle(this.common.TITLE.ITEM_CODE);
    itemIpt.options = this.common.items
  }

  clear() {
    this.queryData.ipts.forEach((item) => {
      item.value = null;
      item.showValue = null
    });
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder;
    this.tableData.pageIndex = 0;
    this.tableData.pageSize = 20;
  }

  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }

  lastSearchParams

  validate(data){
    if(
      !(
        ( data.serialNo && data.serialNo.length > 0 ) ||
        ( data.channels && data.channels.length > 0 ) ||
        ( data.items && data.items.length > 0 )
      )
    ){
      this.common.showMessage('warn', this.common.info.title, 'Channel / Item Code / Serial No. is required!')
      return false
    }
    return true
  } 

  search(comeFrom = false) {
    let data: any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      ...(this.channel && { channels: this.channel.map(item=>item.id) }),
      ...(this.itemCode && { items: this.itemCode.map(item=>item.code) }),
      ...(this.serialNo&& { serialNo: this.serialNo}),
    };
    let isValid = this.validate(data)
    if(!isValid) return
    this.loading = true;
    this.tableData.loadingSwitch = true;
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }
    this.lastSearchParams = data;
    this.service.search(data).subscribe((res) => {
      this.loading = false;
      let list = [];
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {

        list = res.data.map(item => {
          let{channelcode: channelCode,  itemcode: itemCode, stockconditioncode: stockConditionCode, serialnumber: serialNumber} = item
          return {channelCode, itemCode, stockConditionCode, serialNumber}
        });
        this.tableData.totalRecords = res.msg;
      } else {
        this.common.showMessage('error', this.common.info.title, res.msg || res.message || res.error.message || this.common.info.unknown)
      }
      this.tableData.data = list;
    });
  }
  sortSearch(field) {
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = -this.tableData.sortField.order;
    this.search();
  }
  stopEvent(event) {
    event.stopPropagation();
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search(this.resetSearch ? true : false );
    this.resetSearch = false
  }
  tableSelectedColumnsChange(e){
    this.tableData.visibleColumns = this.tableData.columns.filter(col => e.includes(col));
  }
  exportXlsx(){
    let data = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: 2147483647,
    }
    let isValid = this.validate(data)
    if(!isValid) return
    this.service.search(data).subscribe((res) => {
      this.loading = false;
      let list = [];
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        list = res.data.map(item => {
          let{channelcode: channelCode,  itemcode: itemCode, stockconditioncode: stockConditionCode, serialnumber: serialNumber} = item
          return {channelCode, itemCode, stockConditionCode, serialNumber}
        });

        let col = [
          { field: "channelCode", title: "Channel" },
          { field: "itemCode", title: "item Code" },
          { field: "stockConditionCode", title: "Stock Condition" },
          { field: "serialNumber", title: "Serial Number" }
        ]
        CommonMethod.downloadXlsx(list, col, `Qty_Onhand_Serial`)
        // this.tableData.totalRecords = res.msg;
      } else {
        this.common.showMessage('error', this.common.info.title, res.msg || res.message || res.error.message || this.common.info.unknown)
      }
    },err=>{
      this.common.showMessage('error', this.common.info.title, err)
    });
  }
}
