import { Component, Input, OnInit } from '@angular/core'
import { SerialCompareValue } from 'src/app/stores/stock/take/TakeShare'

@Component({
  selector: 'app-compare-serial',
  templateUrl: './compare-serial.component.html',
  styleUrls: ['./compare-serial.component.scss'],
})
export class CompareSerialComponent implements OnInit {
  switchTitle = 'Show matched serial(s)'
  @Input() store
  constructor() {}

  ngOnInit(): void {}
  handleChange(e) {
    if (!e.checked) {
      this.store.serialList = this.store.serialCloneList.filter(
        (item) => item.inQtyOnHandSerial === SerialCompareValue.NO || item.inStockTakeSerial === SerialCompareValue.NO
      )
    } else {
      this.store.serialList = this.store.serialCloneList
    }
  }
}
