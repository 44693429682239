import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { action, computed, observable } from "mobx";
import {HttpHelper} from "../../../util/HttpHelper";
import {INPUT_TYPE, NOTI_MSG, NOTI_TITLE, URLDICT} from "../../../stores/base/BaseStore";
import {ConfirmationService, MessageService} from "primeng/api";
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Paginator } from 'primeng/paginator';
import { FilterCommonMethod } from '@/util/FilterCommonMethod';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @observable permission: string[]
  // @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false

  @observable lisOfData:any[] = []
  @observable selectedProducts:any[] = []
  @observable queryPannel: any
  @observable createPannel: any

  queryTitle = 'UAM - User Role Mapping'
  users:any[]
  roles:any[]
  channels:any[]
  // crechannels:any[]
  // tabchannels:any[]
  userRoleMappingEdit
  userId
  roleId
  repoId
  staffId
  seUserId
  seRoleId
  seRepoId
  disabled: boolean = false
  editing: boolean = false
  // ids:number[]=[]
  // mapOfCheckedId: { [key: string]: boolean } = {};
  // showCheckboxSwitch = false
  // allCheckBoxValue = false
  // isChecked

  _selectedColumns: any[] = [];
  functions: any[]

  pageIndex: number = 0
  pageSize: number = 20
  totalRecords = 0

  field = 'userId'
  order = -1
  isSortSearch

  cols= [
    {header: 'userId',title:'User', width: '270px'},
    {header: 'roleId',title:'Role', width: '270px'},
    {header: 'repoId',title:'Channel', width: '270px'},
    {header: 'staffId',title:'Staff Id', width: '270px'},
  ]
  searchCache = {}
  resetSearch = false

  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public n:MessageService,public m:ConfirmationService, private p:ActivatedRoute,private permissionService: PermissionService){
    this._selectedColumns = this.cols
    this.channels  = LocalStorageHelper.getObject("REPOTREE")
    // this.crechannels = LocalStorageHelper.getObject("REPOTREE")
    // this.tabchannels = LocalStorageHelper.getObject("REPOGROUP")
    // change w003
    // this.tabchannels[3]= { label: "", name: "", items: [this.tabchannels[3]] }

    // this.users  = LocalStorageHelper.getObject("UAM_USER")
    this.loadUser()
    this.roles  = LocalStorageHelper.getObject("UAM_ROLE")

    this.initQueryData()
    this.getPermission(p)
  }

  initQueryData() {
    this.queryPannel = {
      ipts:[
        {
          title: "User",
          type: INPUT_TYPE.SELECT,
          options: this.users,
          optionValue: 'code',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
        }, {
          title: "Role",
          type: INPUT_TYPE.SELECT,
          options: this.roles,
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
        }, {
          title: "Channel",
          type: INPUT_TYPE.TREESELECT,
          options: this.channels,
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
        },{
          title: "Staff Id",
          type: INPUT_TYPE.INPUT,
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-12",
      btns:[{
        title: 'Search',
        show: true,
        class: "p-order-1",
        handler:{
          "click":()=>{
            this.searchFromFilter()
          }
        }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        show: true,
        handler:{
          "click":()=>{
            this.refresh()
          }
        }
      }],
      handler:{
        "click":(e)=>{
          this.onHasUser(e)
        }
      }
    }
    this.createPannel = {
      ipts:[
        {
          title: "User",
          type: INPUT_TYPE.SELECT,
          options: this.users,
          optionValue: 'code',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3"
        }, {
          title: "Role",
          type: INPUT_TYPE.SELECT,
          options: this.roles,
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
        }, {
          title: "Channel",
          type: INPUT_TYPE.MUTIPLETREESELECT,
          options: this.channels,
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
          beforeSubmit: function(value) {
            return value?value.map(val=>{return {id: val.id, key: val.key}}):null },
          change: function(value, ipt) {
            if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-3",
      btns:[{
        title: 'Create',
        class: "p-order-1",
        handler:{
          "click":()=>{
            this.save()
          }
        }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        handler:{
          "click":()=>{
            this.clear()
          }
        }
      }],
      handler:{
        "click":(e)=>{
          this.onHasUser(e)
        }
      },
      change: (idx, e, ipt)=>{
        if(ipt.title=='Channel'){
          if(ipt.change) ipt.change(e, ipt)
        }
      }
    }
  }

  @action getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')['UAM Permission Setting']
    // console.log('>-- get permission --<', p.snapshot.data.name, this.permission);
    this.setPermission()
  }
  @computed get showU(){
    return true // this.permission?.includes('Update')
  }
  @computed get showC(){
    return true // this.permission?.includes('Create')
  }
  @computed get showD(){
    return true // this.permission?.includes('Delete')
  }

  ngOnInit(): void {
    this.sortPageSearch(true)
  }

  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.sortPageSearch(true)
  }

  /* search(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.pageIndex
      data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    this.loadingSwitch=true
    HttpHelper.post(URLDICT.UAM_USER_ROLE_SEARCH,data).then(res =>{
      if(res.code=='000'){
        this.lisOfData = res.data

        this.lisOfData.forEach(d => {
          // 之所以这么处理是，后端修改表后，字段本应该是id，却直接返回了表字段名
          d.uam_user_role_channel_mapping_id && (d.id = d.uam_user_role_channel_mapping_id)
          d.repoId = d.repoId + ''
          d.ipt = {
            title:'',
            showValue: d.repoName,
            options: this.channels
          }
        })
        if(res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch=false
    }).catch(e => console.log(e)).finally(
      () =>this.loadingSwitch=false
    )
  } */
  showMessage(severity, summary, detail){
    this.n.add({severity, summary, detail});
  }
  refresh(){
    this.seUserId=null
    this.seRoleId=null
    this.seRepoId=null
    this.queryPannel.ipts[0].value = null
    this.queryPannel.ipts[1].value = null
    this.queryPannel.ipts[2].value = null
    this.queryPannel.ipts[2].showValue = null
    this.queryPannel.ipts[3].value = null
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  clear() {
    this.createPannel.ipts[0].value = null
    this.createPannel.ipts[1].value = null
    this.createPannel.ipts[2].value = null
    this.createPannel.ipts[2].showValue = null
  }

  channelChange(e){
    let show = true
    if(e === '10' || e === '9' || e === '8' || !e){
      this.repoId = null
      show = false
    }
    if(!show){
      setTimeout(() => {
        const el:any = document.querySelector('.repo .ant-select-selection-selected-value')
        el && (el.style.opacity = 0)
      }, 0);
    }
    // console.log(this.repoId);
  }

  inintSelectData(list){
    return list.map((item) => {
      let {label:name, value:code, ...other} = item
      return {name, code, ...other}
    })
  }
  onHasUser(e){
    if (e.title === 'User') {
      this.loadUser()
    }
  }
  //TODO:
  loadUser(){
    HttpHelper.get(URLDICT.COMMON_UAM_USER_MODULE).then(res =>{
        if(res['code']=='000') {
          this.users = res['data'].map(item => {
            const {label:name, value:code, ...other} = item
            return {name, code, ...other}
          })
          this.queryPannel.ipts[0].options = this.users
          this.createPannel.ipts[0].options = this.users
        }
    }).catch(e => console.log(e.toString()))
  }

  save() {
    this.userId = this.createPannel.ipts[0].value
    this.roleId = this.createPannel.ipts[1].value
    this.repoId = this.createPannel.ipts[2].beforeSubmit(this.createPannel.ipts[2].value).map(e=>e.id).filter(e=>e>=0)
    let valid = true
    if(!this.userId) {
      this.showMessage('warn', 'System', 'the User can not be empty,please choose it!')
      valid = false
      return valid
    }
    if(!this.roleId) {
      this.showMessage('warn', 'System', 'the Role can not be empty,please choose it!')
      valid = false
      return valid
    }
    if(!this.repoId||this.repoId.length==0) {
      this.showMessage('warn', 'System', 'the Channel can not be empty,please choose it!')
      valid = false
      return valid
    }
    if(valid){
      this.loadingSwitch = true
      HttpHelper.post(URLDICT.UAM_USER_ROLE_MULTI_CREATE,{userId:this.userId,roleId:this.roleId,repoIdList:this.repoId}).then(res =>{
        if(res.code=='000'){
          this.clear()
          this.sortPageSearch()
        } else {
          if(res.data[0].errorMsg) {
            // this.n.error("System",res.data[0].errorMsg)
            this.showMessage('error', 'System', res.data[0].errorMsg)
          } else {
            this.showMessage('error', 'System', 'Submit Fail!')
        }
        this.loadingSwitch=false
        }
      }).catch(e =>{
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
        // console.log(e)
      }).finally(
        () =>this.loadingSwitch=false
      )
    }
  }

  cancel(d) {
    if(d.newid){
      d.id = d.newid
      delete d.newid
    }
    this.editing = false
    this.sortPageSearch()
  }

  edit(d) {
    if(d.id){
      d.newid = d.id
      delete d.id
    }
    this.editing = true
    // console.log(d)
  }

  page(e){
    // console.log(e);
    this.pageIndex=e.first/e.rows
    this.pageSize=e.rows
    // if(this.isSortSearch) this.sortPageSearch(this.resetSearch ? true : false)
    // else this.search(this.resetSearch ? true : false)
    this.sortPageSearch(this.resetSearch ? true : false)
    this.resetSearch = false
  }

  sortSearch(key){
    // console.log(key);
    this.field = key
    this.isSortSearch=true
    if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    this.sortPageSearch()
  }
  getSearchParams() {
    return {
      ...(this.queryPannel.ipts[0].value && {userId: this.queryPannel.ipts[0].value}),
      ...(this.queryPannel.ipts[1].value && {roleId: this.queryPannel.ipts[1].value}),
      ...(this.queryPannel.ipts[2].value && {repoId: this.queryPannel.ipts[2].value.data}),
      ...(this.queryPannel.ipts[3].value && {staffId: this.queryPannel.ipts[3].value}),
      // pageIndex:this.pageIndex,
      // pageSize:this.pageSize
    }
  }
  sortPageSearch(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      // data.pageIndex = this.pageIndex
      // data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    let sortParams = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order}
    }
    data = {...data, ...sortParams}
    // data['sortEvent'] = {field:this.field,order:this.order}
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_USER_ROLE_SORTSEARCH,data).then(res =>{
      if(res.code === '000'){
        this.lisOfData = res.data
        this.lisOfData.forEach(d => {
          d.uam_user_role_channel_mapping_id && (d.id = d.uam_user_role_channel_mapping_id)
          d.repoId = d.repoId+''
          d.ipt = {
            title:'',
            showValue: d.repoName,
            options: this.channels
          }
        })

        if(res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
    }).catch(e => console.log(e)).finally(
      () =>this.loadingSwitch=false
    )
  }

  editSave(data){
    if(data.newid){
      data.id = data.newid
      delete data.newid
    }
    if(data.ipt.value) {
      data.repoId = data.ipt.value.id
      data.repoName = data.ipt.value.label
      delete data.ipt
    }
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_USER_ROLE_EDIT,data).then(res =>{
      if(res.code=='000'){
        this.sortPageSearch()
        // console.log(res);
      } else {
        if(res.data[0].errorMsg) {
          // this.n.error("System",res.data[0].errorMsg)
          this.showMessage('error', 'System', res.data[0].errorMsg)
        } else {
          // this.n.error("System","Submit Fail!")
          this.showMessage('error', 'System', 'Submit Fail!')
        }
      }
    }).catch(e => {
      // this.n.error("System","Submit Fail!")
      this.showMessage('error', 'System', 'Submit Fail!')
      // console.log(e)
    }).finally(
      () =>{
        this.loadingSwitch = false
        this.editing = false
      }
    )
  }

  /* selectOnePNCheckBox(id){
    if(this.mapOfCheckedId[id]){
      delete this.mapOfCheckedId[id]
      if(this.allCheckBoxValue) this.allCheckBoxValue = false
    }else{
      this.mapOfCheckedId[id] = true
    }
  } */

  /* selectAllPNCheckbox(v){
    this.selectAllCheckbox(!this.allCheckBoxValue)
  } */

  /**
   * 设置全选或者全闭
   * @param v => true|false
   */
  /* selectAllCheckbox(v) {
    this.allCheckBoxValue = v
    if(v) {
      this.lisOfData.forEach(d => {
        this.mapOfCheckedId[d.id] = v
        if (d.children != null && d.children.length > 0) {
          d.children.forEach(c => {
            this.mapOfCheckedId[c.id] = v
            // this.checkParentCheckBox(v,d.id,c.id)
          })
        }
      })
    }else{
      this.mapOfCheckedId={}
    }
    toJS(this.mapOfCheckedId)
  } */

  delete() {
    const len = this.selectedProducts.length
    if(len <= 0){
      // this.n.warning(NOTI_TITLE,NOTI_MSG.MORE_SELECTED_NEED)
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
    } else {

      this.m.confirm({
        message: `Do you want to delete these user role mapping record?`,
        header: 'Delete Confirmation',
        accept: () => {
          const obj = {
            ids: this.selectedProducts.map(item =>  item.id + "")
          }
          this.loadingSwitch = true
          HttpHelper.post(URLDICT.UAM_USER_ROLE_DELETE, obj).then(res =>{
            if(res.code=='000'){
              this.sortPageSearch()
              this.selectedProducts = []
            } else {
              // this.n.error("System","Submit Fail!")
              this.showMessage('error', 'System', 'Submit Fail!')
            }
          }).catch(e => {
            // this.n.error("System",e.toString())
            this.showMessage('error', 'System', e.toString())
          }).finally(
            () =>this.loadingSwitch=false
          )
        }
      });
    }
  }
  setPermission(...e){
    let permissionService = this.permissionService
    this.createPannel && this.createPannel.btns.length>0 && this.createPannel.btns.forEach(item=>{
      item.title=='Create' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.USER_ROLE_MAPPING_EDIT))
    })
    if(permissionService.havePermission(FUNCTION_CODE.USER_ROLE_MAPPING_EDIT)){
      this.userRoleMappingEdit=true;
    }
  }
}
