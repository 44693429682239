import { makeAutoObservable, reaction } from "mobx";
import { Injectable } from "@angular/core";
import { MENU } from "../../../config";
import { INPUT_TYPE } from "../../base/BaseStore";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { ActivatedRoute } from "@angular/router";
import { DocCalculationFactoService } from "src/app/service/channelItemControl/doc-calculation-facto.service";
import { BlockScrollStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";
@Injectable()
export class SearchStore {
  breadcrumb = MENU.CIC_DOC_CALCULATION_FACTOR;
  days:any[] = [
    {name: '7', code: '7'},
    {name: '14', code: '14'},
    {name: '21', code: '21'},
    {name: '30', code: '30'}
  ]
  queryData = {
    ipts: [
      {
        title: this.service.TITLE.CHANNEL,
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        showValue: null,
        options: []
      },
      {
        title: this.service.TITLE.ITEM,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        dataKey: 'code',
        skipAllCheckMatch: true,
        options: []
      },
      {
        title: this.service.TITLE.SALES_ON_DAY,
        type: INPUT_TYPE.SELECT,
        options: this.days,
        optionLabel: 'name',
        optionValue: 'code',
        value: null,
        class: "p-col-12 p-md-4 p-lg-3",
      }
    ],
    btns: [
      {
        title: this.service.BUTTON_TITLE.SEARCH,
        class: "p-order-1",
        show: true,
        handler: { click: () => this.searchFromFilter() },
      },
      {
        title: this.service.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clearOfQuery() },
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-md-4 p-lg-3",
  };
  addData = {
    ipts: [
      {
        title: this.service.TITLE.CHANNEL,
        type: INPUT_TYPE.SELECTGROUP,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: "name",
        optionValue: "id",
        required: true,
      },
      {
        title: this.service.TITLE.ITEM,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        dataKey: 'code',
        skipAllCheckMatch: true,
        required: true,
      },      {
        title: this.service.TITLE.MIN_DOC,
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-2",
        value: null,
        min: 1,
        required: true,
      },
      {
        title: this.service.TITLE.SALES_ON_DAY,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-2",
        options: this.days,
        optionLabel: 'name',
        optionValue: 'code',
        value: null,
        required: true,
      },
    ],
    btns: [
      {
        title: this.service.BUTTON_TITLE.ADD,
        class: "p-order-1",
        show: true,
        disabled:true,
        handler: { click: () => this.add() },
      },
      {
        title: this.service.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clearOfAdd() },
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-lg-2",
    show: true,
    loading: 'hidden'
  }
  tableData = {
    btns: [],
    data: [],
    display_data: [],
    head: [],
    columns: [
      { field: "channelCode", title: this.service.TABLE_FIELD.CHANNEL, width: "120px" },
      { field: "itemCode", title: this.service.TABLE_FIELD.ITEM, width: "190px" },
      { field: "systemDoc", title: this.service.TABLE_FIELD.SYSTEM_DOC, width: "150px" },
      { field: "numberOfDays", title: this.service.TABLE_FIELD.SALES_ON_DAY, width: "160px" , type: "select"},
      // { field: "percentageOfThreshold", title: this.service.TABLE_FIELD.PERCENTAGE_OF_THRESHOLD, width: "190px" , type: "number"}
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [{ field: "", title: "Action", width: "160px", type: "action" },],
    frozenWidth: "150px",
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "channel", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: "single",
    initSortField: "channel",
    initSortOrder: -1,
    selectedProducts: [],
    editShow: true,
  };
  totalRecords: 0
  channelIpt: any = {}
  itemIpt: any = {}
  channelByAddIpt: any = {}
  itemByAddIpt: any = {}
  monDocByAddIpt: any = {}
  salesOnDayIpt: any = {}

  currentRow: any = {}
  cloneData: any = {}
  changeData: any = {}
  skus: any = []
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };
  permission: string[];
  loading: boolean = false;
  showSearchLoading = 'hidden'
  systemDoc
  selected
  selectDoc: any = {}
  configType = 1
  private overlayRef: OverlayRef | undefined
  private scrollStrategy: BlockScrollStrategy;
  docEditEnable = false
  searchCache = {}
  resetSearch = false
  paginator = null

  constructor(
    private route: ActivatedRoute,
    private overlay: Overlay,
    private service: DocCalculationFactoService,
    private permissionService: PermissionService
  ) {
    makeAutoObservable(this);
    this.getPermission();
    this.tableData.head = [...this.tableData.columns];
    this.tableData.selectedColumns = [...this.tableData.columns];
    // this.getResolverData();
    this.initdata();
  }
  get channelByQuery() {
    const ipt = this.getQueryByTitle(this.service.TITLE.CHANNEL);
    return ipt.value;
  }
  get itemByQuery() {
    const ipt = this.getQueryByTitle(this.service.TITLE.ITEM);
    return ipt.value;
  }
  get salesByQuery() {
    const ipt = this.getQueryByTitle(this.service.TITLE.SALES_ON_DAY);
    return ipt.value;
  }
  get channelByAdd() {
    const ipt = this.getTitleByAdd(this.service.TITLE.CHANNEL);
    return ipt.value;
  }
  get itemByAdd() {
    const ipt = this.getTitleByAdd(this.service.TITLE.ITEM);
    return ipt.value;
  }
  get minDocByAdd() {
    const ipt = this.getTitleByAdd(this.service.TITLE.MIN_DOC);
    return ipt.value;
  }
  get salesOnDay() {
    const ipt = this.getTitleByAdd(this.service.TITLE.SALES_ON_DAY);
    return ipt.value;
  }

  get showSearch() {
    return true //this.permission.includes('Search')
  }

  getQueryByTitle(title) {
    return this.queryData.ipts.find((item) => item.title === title);
  }
  getTitleByAdd(title) {
    return this.addData.ipts.find((item) => item.title === title);
  }

  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.name];
    // this.addData.show = (this.permission & this.defaultPermission.c) === this.defaultPermission.c
    this.setPermission(this.queryData.btns);
  }

  /* setPermission(list) {
    list.forEach((item) => {
      if (item.permissionType) {
        let permission = this.defaultPermission[item.permissionType];
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permission & permission) === permission;
      }
    });
  } */
  initQueryData() {
    this.channelIpt = this.getQueryByTitle(this.service.TITLE.CHANNEL);
    this.itemIpt = this.getQueryByTitle(this.service.TITLE.ITEM);
    const channels = JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo=>repo.children.length)
    this.channelIpt.options = channels
    const skus = JSON.parse(localStorage.getItem("SKU"))
    this.itemIpt.options = skus
  }
  queryItems(channelId) {
    if(channelId) {
      // const positionStrategy = this.overlay.position().global()
      this.scrollStrategy = this.overlay.scrollStrategies.block();
      this.overlayRef = this.overlay.create()

      const div = document.createElement('div')
      div.classList.add('mask')
      const doc = document.createDocumentFragment()
      doc.appendChild(div)
      this.overlayRef.overlayElement.appendChild(doc)
      this.scrollStrategy.enable()
      // this.showSearchLoading= 'visible'
      this.addData.loading = 'visible'
      this.service.getItems({dtlRepoId: channelId}).subscribe(res => {
        this.overlayRef.dispose()
        // this.scrollStrategy.enable()
        this.addData.loading = 'hidden'
        this.itemByAddIpt.value = null
        let list = res.code === '000' ? res.data : []
        if(list.length) {
          list = list.map(item => {
            item.name = item.skuCode + ' ~ ' + item.skuDesc
            let {dtlSkuId:code, ...other} = item
            return {code, ...other}
          });
        } else {
          this.service.showMessage('warn', this.breadcrumb, this.service.info.noItems)
        }
        this.itemByAddIpt.options = list
      })
    } else {
      this.itemByAddIpt.value = null
      this.itemByAddIpt.options = this.skus
    }
  }
  initCreateData() {
    this.channelByAddIpt = this.getTitleByAdd(this.service.TITLE.CHANNEL);
    this.itemByAddIpt = this.getTitleByAdd(this.service.TITLE.ITEM);
    this.monDocByAddIpt = this.getTitleByAdd(this.service.TITLE.MIN_DOC);
    this.salesOnDayIpt = this.getTitleByAdd(this.service.TITLE.SALES_ON_DAY);
    const channles = JSON.parse(localStorage.getItem("REPOMODULEBYUAMGROUP")).filter(repo=>repo.items.length)
    this.channelByAddIpt.options = channles
    this.skus = JSON.parse(localStorage.getItem("SKU")).map(sku => {
      sku.name += ' ~ ' +  sku.flag
      return sku
    });
    this.itemByAddIpt.options = this.skus

    reaction (
      () => this.channelByAddIpt.value,
      (data) => {
        // runInAction(() => {
          this.queryItems(data)
        // })
      }
    );
  }
  initdata() {
    this.initQueryData()
    this.initCreateData()
    this.getAllTypes()

  }

  reset() {
    // empty
  }
  verify() {
    let flag = true
    if(!this.channelByAdd) {
      this.channelByAddIpt.error = true
      this.service.showMessage('error', this.breadcrumb, this.service.info.reqiuired.replace('This', this.service.TITLE.CHANNEL))
      flag = false
    } else {
      this.channelByAddIpt.error = false
    }
    console.log(this.minDocByAdd);

    if(!this.itemByAdd) {
      this.itemByAddIpt.error = true
      this.service.showMessage('error', this.breadcrumb, this.service.info.reqiuired.replace('This', this.service.TITLE.ITEM))
      flag = false
    } else {
      this.itemByAddIpt.error = false
    }
    if(!this.minDocByAdd) {
      this.monDocByAddIpt.error = true
      this.service.showMessage('error', this.breadcrumb, this.service.info.reqiuired.replace('This', this.service.TITLE.MIN_DOC))
      flag = false
    } else {
      this.monDocByAddIpt.error = false
    }
    if(!this.salesOnDay) {
      this.salesOnDayIpt.error = true
      this.service.showMessage('error', this.breadcrumb,
        this.service.info.reqiuired.replace('This', this.service.TITLE.SALES_ON_DAY))
      flag = false
    } else {
      this.salesOnDayIpt.error = false
    }
    return flag
  }
  add() {
    if(this.verify()) {
      let data = []
      this.itemByAdd.forEach(item => {
        const params = {
          ...(this.systemDoc && { systemDoc: this.systemDoc }),
          channelId: this.channelByAdd,
          itemId: item.code,
          doc: this.minDocByAdd,
          numberOfDays: this.salesOnDay,
          channelItemConfigTypeId: this.configType,
        }
        data.push(params)
      });
      this.loading = true;
      this.tableData.loadingSwitch = true;
      this.service.create(data).subscribe(res => {
        this.loading = false;
        this.tableData.loadingSwitch = false;
        if (res.code === '000') {
          this.service.showMessage('info', this.breadcrumb, this.service.info.success)
          this.search()
        } else {
          this.service.showMessage('error',
            this.breadcrumb, res.msg || res.message || this.service.info.failed.replace('The', "Create Data"))
        }
      })
    }
  }
  selectDay(e, op) {
    this.selectDoc.numberOfDays = e.value.code
    op.hide();
  }
  openSelect(e, data, op) {
    this.selectDoc = data
    op.toggle(e)
  }
  clearOfQuery() {
    this.queryData.ipts.forEach((item) => {
      item.value = null;
      if(item.showValue) {
        item.showValue = null;
      }
    });
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder;
    this.tableData.pageIndex = 0;
    this.tableData.pageSize = 20;
    // this.search();
  }

  clearOfAdd() {
    this.addData.ipts.forEach((item) => {
      item.value = null;
    })
    this.itemByAddIpt.options = this.skus
    if(this.monDocByAddIpt.error) {
      this.monDocByAddIpt.error = false
    }
    if(this.salesOnDayIpt.error) {
      this.salesOnDayIpt.error = false
    }
    if(this.itemByAddIpt.error) {
      this.itemByAddIpt.error = false
    }
  }
  rowSave(data) {
    const tempData = this.changeData[data.id]
    if (tempData.numberOfDays === data.numberOfDays) {
      this.service.showMessage('info', this.breadcrumb, this.service.info.noChange)
    } else {
      this.loading = true;
      this.tableData.loadingSwitch = true;
      data.channelItemConfigTypeId = this.configType
      this.service.edit(data).subscribe(res => {
        this.loading = false;
        this.tableData.loadingSwitch = false;
        if (res.code === "000") {
          this.service.showMessage('success', this.breadcrumb, this.service.info.success.replace('Created', 'Edit'))
          this.search();
        } else {
          this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
        }
      })
    }
  }
  confirmSave(data) {

  }
  rowCancel(data, index) {
    const tempData = this.changeData[data.id]
    if (tempData.numberOfDays === data.numberOfDays) {
      this.confirmCancel(data)
    } else {
      this.service.showDialog(this.service.info.confirmCancel, data, this, 'confirmCancel')
    }
  }
  confirmCancel(data) {
    data.numberOfDays = this.changeData[data.id].numberOfDays
    delete this.changeData[data.id]
    data.edit = false
    data.isEdit = false
  }
  rowEdit(data, index) {
    this.changeData[data.id] = JSON.parse(JSON.stringify(data));
    data.edit = true
    data.isEdit = true
  }
  rowDelete(data, index) {
    this.service.showDialog(this.service.info.confirmDelete, data, this, 'confirmDelete')
  }
  confirmDelete (data) {
    const props = {
      channelItemConfigTypeId: this.configType,
      ids: [data.id]
    }
    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.delete(props).subscribe(res => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        this.service.showMessage('success', this.breadcrumb, this.service.info.success.replace('Created', 'Delete'))
        this.search();
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    })
  }
  getAllTypes(){
    this.service.getType({}).subscribe((res) => {
      if (res.code === "000") {
        this.configType = res.data.find(item => item.description === this.service.defaultConfigDesc).id
        this.search(true);
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    });
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  search(comeFrom = false) {
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      channelItemConfigTypeId: this.configType,
      ...(this.channelByQuery && { channel: this.channelByQuery?.id }),
      ...(this.itemByQuery && { items: this.itemByQuery.map(item=>item?.code) }),
      ...(this.salesByQuery && { numberOfDays: this.salesByQuery }),
    };
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }
    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.search(data).subscribe((res) => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        this.tableData.data = res.data
        this.tableData.display_data = res.data
        this.totalRecords = res.msg
        this.tableData.totalRecords = res.msg
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    });
  }
  sortSearch(field) {
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = -this.tableData.sortField.order;
    this.search();
  }

  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search(this.resetSearch ? true : false );
    this.resetSearch = false
  }
  openDet(rowData) {
    // 这里不需要跳转到详情页
  }
  setPermission(...e){
    let permissionService = this.permissionService
    if(permissionService.havePermission(FUNCTION_CODE.DOC_CALCULATION_FACTOR_EDIT)){
      this.docEditEnable=true;
    }
    this.addData && this.addData.btns.length>0 && this.addData.btns.forEach(item=>{
      item.title==this.service.BUTTON_TITLE.ADD && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.DOC_CALCULATION_FACTOR_EDIT))
    })
  }
}
