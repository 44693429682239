import { Pipe, PipeTransform } from '@angular/core';
import { CommonMethod } from 'src/app/util/CommonMethod';

@Pipe({
  name: 'getValueByKeyPath'
})
export class getValueByKeyPath implements PipeTransform {

  transform(value: any, pathKeys: string[]): unknown {
    // console.log(name);
    return CommonMethod.getValueByKeyPath(value, pathKeys);
  }

}
