import { Component } from '@angular/core';
import { DetailStore } from 'src/app/stores/stock/replenishment/DetailStore';

@Component({
  providers: [DetailStore],
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent {

  constructor(public store: DetailStore) { }

}
