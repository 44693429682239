import {Component, OnInit} from '@angular/core';
import { CreateStore } from 'src/app/stores/stock/reservation/CreateStore';
@Component({
  selector: 'app-create',
  providers: [CreateStore],
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit{
  constructor(public store: CreateStore) {}
  ngOnInit(): void {

  }
}
