import { LocalStorageHelper } from "./LocalStorageHelper";

export class FilterCommonMethod {
  public static getRepoTreeForFilterInput() {
    return LocalStorageHelper.getObject("REPOTREE");
  }

  public static getRepoTreeActiveForSearchFilter(){
    return LocalStorageHelper.getObject("REPOTREE_ACTIVE");
  }

  public static getRepoMultiSelectShowValue(repoArray: any[]) {
    let res = [];
    if(!repoArray||!Array.isArray(repoArray))return null
    let repoIdList = repoArray.filter(e=>e).map((repo) => repo.id);
    repoArray.filter(e=>e).forEach((repo) => {
      if (repoIdList.indexOf(repo.pid) == -1) {
        res.push(repo);
      }
    });
    return res.map((data) => data.label).join(",");
  }
}
