import { HttpHelper } from '../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from '../../config';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { CommonService } from '../common/common-service';

@Injectable({
  providedIn: 'root'
})
export class StockDemandCycleService {
  constructor(private commonService: CommonService){}
  getPoolType() {
    return from(HttpHelper.get(URLDICT.STOCK_DEMAND_CYCLE_POOLTYPE))
  }
  search(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_SEARCH, data))
  }
  create(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_CREATE, data))
  }
  edit(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_EDIT, data))
  }
  exist(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_NAME_EXIST, data))
  }
  demandSearch(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_SEARCH, data))
  }
  searchRepo(data) {
    return from(HttpHelper.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, data))
  }
  demandCreate(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CREATE, data))
  }
  demandEdit(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_EDIT, data))
  }
  demandDelete(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_DELETE, data))
  }
  demandAddItem(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_ADDITEM, data))
  }
  replenishmentSearch(data) {
    return of(null).pipe(switchMap(res=>from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SEARCH, data))))
  }
  replenishmentCreate(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_CREATE, data))
  }
  replenishmentEdit(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_EDIT, data))
  }
  replenishmentVerify(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_VERIFY, data))
  }
  replenishmentReserve(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RESERVE, data))
  }
  replenishmentReReserve(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RERESERVE, data))
  }
  replenishmentReReserveByLineId(data) {
    return of(null).pipe(switchMap(res=>from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RERESERVEBYLINEID, data))))
  }
  replenishmentReserveForNop(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RESERVE_NOP, data))
  }
  replenishmentSubmit(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SUBMIT, data))
  }
  replenishmentReSubmit(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RESUBMIT, data))
  }
  replenishmentSubmitToDm(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SUBMITTODM, data))
  }
  replenishmentDelete(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_DELETE, data))
  }
  replenishmentGetBuCode() {
    return from(HttpHelper.get(URLDICT.STOCK_DEMAND_REPLENISHMENT_GET_BU_CODE))
  }
  replenishmentLineSearch(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_SEARCH, data))
  }
  replenishmentNopOrderSearch(data):Observable<any> {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_SEARCH_DM_LIST, data))
  }
  replenishmentLineSchoolSearch(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_SCHOOL_SEARCH, data))
  }
  replenishmentLineAddItem(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEM, data))
  }
  replenishmentLineAddItems(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMS, data))
  }
  replenishmentLineAddItemByBU(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMBYBU, data))
  }
  replenishmentLineAddItemByBuAsCsv(data) {
    return this.commonService.postForExcelObs(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMBYBU_AS_CSV, data)
  }
  replenishmentLineExportItemsByBu(buCode) {
    return this.commonService.postForExcelObs(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_EXPORTITEMSBYBU, {bu: buCode})
  }
  replenishmentLineAddItemByReservation(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_RESERVATION, data))
  }
  replenishmentLineAddItemByReservationForBYOD(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_RESERVATION_BYOD, data))
  }
  replenishmentLineCancelReserveLIS(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_CANCEL_RESERVE_LIS, data))
  }
  replenishmentLineAddItemBySchoolCode(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_SCHOOL_CODE, data))
  }
  fetchPreAllocate(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_PRE_ALLOCATE, data))
  }
  savePreAllocate(data) {
    return of(null).pipe(mergeMap(()=>{
      return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SAVE_PRE_ALLOCATE, data))
    }))
  }
  deletePreAllocate(data) {
    return of(null).pipe(mergeMap(()=>{
      return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_DELETE_PRE_ALLOCATE, data))
    }))
  }
  fetchPreAllocateWithItem(data) {
    return of(null).pipe(mergeMap(()=>{
      return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_PRE_ALLOCATE_ITEM, data))
    }))
  }
  fetchPreAllocateWithItemFromNop(data) {
    return from(HttpHelper.post(URLDICT.CHANNEL_REPLENISH_NOP_CALCULATE_ALL, data))
  }
  getPreAllocatedStockReservation(replenishId) {
    return from(HttpHelper.post(URLDICT.CHANNEL_REPLENISH_GET_PRE_ALLOCATED_RESERVATION, {replenishId: replenishId}))
  }
  getPreAllocatedStockReservationBySourceTxnRefHeaderNo(replenishId) {
    return from(HttpHelper.post(URLDICT.CHANNEL_REPLENISH_GET_PRE_ALLOCATED_RESERVATION_BY_SOURCEREFHEADERNO, {replenishId: replenishId}))
  }
  getReplenishmentLineItemList(replenishId) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_ITEMINFO_BY_CHANNEL_REPLENISH, {replenishId: replenishId}))
  }
  getReplenishmentLineItemListWithReplenishItemStatus(replenishId, status, isCompareWarehouse) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_ITEMINFO_BY_CHANNEL_REPLENISH, {replenishId: replenishId, replenishItemStatus: status, isCompareWarehouse: isCompareWarehouse}))
  }
  getUnReserveReplenishLineByReplenishId(replenishId){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNRESERVE_LINE_ID, {replenishId: replenishId}))
  }
  getUnReserveReplenishLineByReplenishIdAndChannel(replenishId, channelId = null){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNRESERVE_LINE_ID, {replenishId: replenishId, channelId: channelId}))
  }
  getReservedNonSubmitReplenishLineByReplenishId(replenishId){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNSUBMIT_LINE_ID, {id: replenishId}))
  }
  getReservedNonSubmitReplenishLineByReplenishIdAndChannel(replenishId, channelId = null){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNSUBMIT_LINE_ID, {replenishId: replenishId, channelId: channelId}))
  }
  replenishmentSubmitByLineId(data) {
    return of(null).pipe(switchMap(res=>from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SUBMIT_BY_ID, data))))
  }
  replenishmentUpdateToReserved(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_RESERVE, data))
  }
  replenishmentUpdateToSubmitted(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_SUBMIT, data))
  }
  getAllChannelIdsByReplenishId(replenishId){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_GET_CHANNELID_IN_REPLENISH, {id: replenishId}))
  }
  updateOnhandQty(warehouseId, itemIdList){
    return from(HttpHelper.post(URLDICT.LIS_ONHAND_UPDATE_ONHAND_QTY, {channelId: warehouseId, itemIdList: itemIdList}))
  }
  updateReplenishLineQty(replenishId){
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_UPDATE_REPLENISH_LINE_QTY, {id: replenishId}))
  }
  replenishmentUpdateToReserving(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_RESERVING, data))
  }
  replenishmentUpdateToVerify(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_VERIFY, data))
  }
  getReplenishmentStatusById(replenishId) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_GET_STATUS_BY_ID, replenishId))
  }
  replenishmentReserveLiS(replenishId) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_RESERVE_LIS, replenishId))
  }
  replenishmentSubmitToLis(replenishId) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_REPLENISHMENT_SUBMIT_TO_LIS, replenishId))
  }
}
