// this component use to fix primeng 11 multiselect allChecked performance issue in angular 11
// https://github.com/primefaces/primeng/blob/11.1.0/src/app/components/multiselect/multiselect.ts#L821C32-L821C32

import { trigger, transition, style, animate } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewEncapsulation,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FilterService } from "primeng/api";
import { MultiSelect } from "primeng/multiselect";

export const _MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true,
};
@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  animations: [
    trigger("overlayAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "scaleY(0.8)" }),
        animate("{{showTransitionParams}}"),
      ]),
      transition(":leave", [
        animate("{{hideTransitionParams}}", style({ opacity: 0 })),
      ]),
    ]),
  ],
  host: {
    "[class.p-inputwrapper-filled]": "filled",
    "[class.p-inputwrapper-focus]": "focus",
  },
  providers: [_MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./multi-select.component.scss"],
})
export class MultiSelectComponent
  extends MultiSelect
  implements ControlValueAccessor
{
  // set to true for use custom logic, only check selected option length when render select all checkbox style
  @Input() skipAllCheckMatch = false;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    public filterService: FilterService
  ) {
    super(el, renderer, cd, filterService);
  }

  // override origin allChecked function for skipAllCheckMatch logic
  get allChecked(): boolean {
    let optionsToRender = this.optionsToRender;
    if (!optionsToRender || optionsToRender.length === 0) {
      return false;
    } else if (this.skipAllCheckMatch) {
      return (this.value?.length || 0) == optionsToRender.length;
    } else {
      let selectedDisabledItemsLength = 0;
      let unselectedDisabledItemsLength = 0;
      let selectedEnabledItemsLength = 0;

      for (let option of optionsToRender) {
        let disabled = this.isOptionDisabled(option);
        let selected = this.isSelected(option);

        if (disabled) {
          if (selected) selectedDisabledItemsLength++;
          else unselectedDisabledItemsLength++;
        } else {
          if (selected) selectedEnabledItemsLength++;
          else return false;
        }
      }

      return (
        optionsToRender.length === selectedDisabledItemsLength ||
        optionsToRender.length === selectedEnabledItemsLength ||
        (selectedEnabledItemsLength &&
          optionsToRender.length ===
            selectedEnabledItemsLength +
              unselectedDisabledItemsLength +
              selectedDisabledItemsLength)
      );
    }
  }

  updateLabel() {
    if (
      this.value &&
      this.options &&
      this.value.length &&
      this.displaySelectedLabel
    ) {
      let label = "";

      if (this.value.length <= this.maxSelectedLabels) {
        for (let i = 0; i < this.value.length; i++) {
          let itemLabel = this.findLabelByValue(this.value[i]);
          if (itemLabel) {
            if (label.length > 0) {
              label = label + ", ";
            }
            label = label + itemLabel;
          }
        }
        this.valuesAsString = label;
      } else {
        let pattern = /{(.*?)}/;
        if (pattern.test(this.selectedItemsLabel)) {
          this.valuesAsString = this.selectedItemsLabel.replace(
            this.selectedItemsLabel.match(pattern)[0],
            this.value.length + ""
          );
        } else {
          this.valuesAsString = this.selectedItemsLabel;
        }
      }
    } else {
      this.valuesAsString = this.placeholder || this.defaultLabel;
    }
  }
  
  // this.value = [""] when uncheck
  updateFilledState() {
    this.filled = (this.valuesAsString && this.valuesAsString.length > 0);
  }

  clearAll(event){
    this.uncheckAll()
    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event, value: this.value });        
    this.updateLabel();
    this.updateFilledState();
    event.preventDefault();
    event.stopPropagation()
  }
  toggleAll(event) {
    if (this.disabled || this.toggleAllDisabled || this.readonly) {
        return;
    }
    let allChecked = this.allChecked;
    if (allChecked)
        this.uncheckAll();
    else
        this.checkAll();
    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event, value: this.value });
    this.updateLabel();
    this.updateFilledState();
    event.preventDefault();
  }

  onOverlayAnimationDone(e){
    if (this.filterInputChild && this.filterInputChild.nativeElement) {
      this.preventModelTouched = true;

      if (this.autofocusFilter) {
        if(e.toState == "visible") this.filterInputChild.nativeElement.focus();
      }
    }
  }
}

@Component({
    selector: 'p-multiSelectItem',
    template: `
        <li class="p-multiselect-item" (click)="onOptionClick($event)" (keydown)="onOptionKeydown($event)" [attr.aria-label]="label" 
            [attr.tabindex]="disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'p-highlight': selected, 'p-disabled': disabled}" pRipple>
            <div class="p-checkbox p-component">
                <div class="p-checkbox-box" [ngClass]="{'p-highlight': selected}">
                    <span class="p-checkbox-icon" [ngClass]="{'pi pi-check': selected}"></span>
                </div>
            </div>
            <span *ngIf="!template">{{label}}</span>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
        </li>
    `,
    encapsulation: ViewEncapsulation.None
})
export class CusMultiSelectItem {

    @Input() option: any;

    @Input() selected: boolean;

    @Input() label: any;

    @Input() disabled: boolean;

    @Input() itemSize: number;

    @Input() template: TemplateRef<any>;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onKeydown: EventEmitter<any> = new EventEmitter();

    onOptionClick(event: Event) {
        this.onClick.emit({
            originalEvent: event,
            option: this.option
        });
    }

    onOptionKeydown(event: Event) {
        this.onKeydown.emit({
            originalEvent: event,
            option: this.option
        });
    }
}