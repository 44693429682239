import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonMethod } from 'src/app/util/CommonMethod';

@Directive({
  selector: '[appCusPInputEvent]'
})
export class CusPInputEventDirective {

  @Output() onEnter = new EventEmitter()
  @Input() appCusPInputEvent;
  public inputEl;

  constructor(
    public elRef: ElementRef<HTMLElement>,
  ) { }

  ngAfterViewInit(){
    let inputEl = this.elRef.nativeElement.getElementsByClassName(this.appCusPInputEvent)
    this.inputEl = inputEl;
    inputEl[0].addEventListener('keydown', (e:any)=>{
      if(e.key === 'Enter') this.onEnter.emit({event: e, element: inputEl})
    })
  }
}
