import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { SearchStore } from 'src/app/stores/config/stock_availability_level/SearchStore';

@Component({
  selector: 'app-stock-availability-level',
  providers: [SearchStore],
  templateUrl: './stock-availability-level.component.html',
  styles: [
    ':host ::ng-deep .p-datatable table { table-layout: auto; }',
    ':host ::ng-deep .p-button.p-button-sm { border: 1px solid; }',
    ':host ::ng-deep .p-datatable .p-datatable-tbody > tr > td { padding: 0.1rem 0.75rem; }',
    '.expand-row { background: #fff !important; }',
  ]
})
export class StockAvailabilityLevelComponent implements AfterViewInit {
  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public store: SearchStore) { }
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
  }

}
