import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.RESSTOCKRESERVATION_TYPE,
      this.baseResolver.resolverDataList.SOURCE,
      this.baseResolver.resolverDataList.REPO,
      this.baseResolver.resolverDataList.DEFAULT_CHANNEL,
      this.baseResolver.resolverDataList.GETSKU,
      this.baseResolver.resolverDataList.RESERVATION_REASON
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList); 
  }
}
