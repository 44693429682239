import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
  * @description: 提示信息
  * @author:  ChenShuCheng
  */
@Component({
  selector: 'app-switch-cst',
  templateUrl: './switch-cst.html',
  styles:[]
})
export class SwitchCustom {
  @Input() ipt
  @Output() change = new EventEmitter()
  handleChange(e){
    this.change.emit(e.checked)
  }
}
