import { Component } from '@angular/core';
import { ConfigurationTablesMaintenanceStore } from 'src/app/stores/config/configuration_tables_maintenance/configuration-tables-maintenance';

@Component({
  selector: 'app-configuration-tables-maintenance',
  providers: [ConfigurationTablesMaintenanceStore],
  styleUrls: ['./configuration-tables-maintenance.component.scss'],
  template: `
    <div>
      <illustration name="Configuration Tables Maintenance"></illustration>
      <p-progressBar mode="indeterminate" *ngIf="store.loading"></p-progressBar>
      <app-query-pannel [store]="store.queryData"></app-query-pannel>
      <edit-table-pannel class="tableData" [store]="store.tableData" [searchStore]="store"></edit-table-pannel>
    </div>
    <p-dialog [(visible)]="store.addDialog" [style]="{width: '50vw'}" [header]="store.dialogTitle|titlecase" [modal]="true" styleClass="p-fluid" (onHide)="store.hideDialog()">
      <ng-template pTemplate="content">
        <ng-container *ngFor="let item of store.dialogData">
          <div class="p-field" *ngIf="!item.notShow">
            <label for="name">{{item.title}}</label>
            <input type="text" pInputText [id]="item.title" [(ngModel)]="item.value" required autofocus />
          </div>
        </ng-container>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="store.hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store.addNewData()"></button>
      </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
    `,
  styles: []
})
export class ConfigurationTablesMaintenanceComponent {
  constructor(public store: ConfigurationTablesMaintenanceStore){}
}
