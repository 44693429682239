import { Injectable } from "@angular/core";
import { CommonService } from "./common-service";
import { URLDICT } from "./../../config";

@Injectable({
  providedIn: "root",
})
export class DataMigrationService {
  constructor(
    private commonService: CommonService,
  ) {
  }

  uploadStockBalanceFileFromPpos(e){
    let data = this.commonService.getFormData(e)
    return this.commonService.formPostObs(URLDICT.DATA_MIGRATION_UPLOADSTOCKBALANCEFILEFROMPPOS, data)
  }
  stockBalanceFromPpos(){
    return this.commonService.httpPostObs(URLDICT.DATA_MIGRATION_STOCKBALANCEFROMPPOS, null)
  }
  exportCompareQtyFile(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTCOMPAREQTYFILE, null)
  }
  exportExceptionFile(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTEXCEPTIONFILE, null)
  }

  uploadStockSerialNumberFileFromPpos(e){
    let data = this.commonService.getFormData(e)
    return this.commonService.formPostObs(URLDICT.DATA_MIGRATION_UPLOADSTOCKSERIALNUMBERFILEFROMPPOS, data)
  }
  stockSerialNumberFromPpos(){
    return this.commonService.httpPostObs(URLDICT.DATA_MIGRATION_STOCKSERIALNUMBERFROMPPOS, null)
  }
  exportSerialItemSummary(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTSERIALITEMSUMMARY, null)
  }
  exportSerialNumberList(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTSERIALNUMBERLIST, null)
  }
  exportSerialReconciliationReport(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTSERIALRECONCILIATIONREPORT, null)
  }
  exportSerialExceptionReport(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTSERIALEXCEPTIONREPORT, null)
  }

  createStockVarianceReport(){
    return this.commonService.httpPostObs(URLDICT.DATA_MIGRATION_CREATESTOCKVARIANCEREPORT, null)
  }

  exportStockVarianceReport(){
    return this.commonService.postForExcelObs(URLDICT.DATA_MIGRATION_EXPORTSTOCKVARIANCEREPORT, null)
  }
}
