import { Component, Input, EventEmitter, Output } from '@angular/core';
import { INPUT_TYPE } from 'src/app/stores/base/BaseStore';

/**
 * @description: 查询条件页面组件
 * @author: ken
 */
@Component({
  selector: 'app-query-pannel',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './query-pannel.component.html',
  styleUrls: ['./query-pannel.component.css']
})
export class QueryPannelComponent {
  @Input() headerTilte = 'FILTER';
  @Input() store
  @Input() disableBtn
  @Output() heightEvent = new EventEmitter();
  public INPUT_TYPE = INPUT_TYPE;
  ngOnChanges(e){
  }
}
