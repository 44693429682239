import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';
@Injectable({
  providedIn: 'root'
})
export class BackendResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.REPO
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
