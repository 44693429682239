import { Component, OnInit, ViewChild } from '@angular/core';
import { TakeCheckStore } from 'src/app/stores/stock/take/takeCheckStore';

@Component({
  selector: 'app-take-check',
  providers: [TakeCheckStore],
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class TakeCheckComponent implements OnInit {

  @ViewChild('popupPUploadEl') popupPUploadEl;

  constructor(public store: TakeCheckStore) { }

  ngOnInit(): void {
    // 'ngOnInit
  }
  ngAfterViewInit(){
    this.store.popupPUploadEl = this.popupPUploadEl
  }
}
