import {Component, EventEmitter, Input, Output} from '@angular/core';
/**
  * @description: 数字输入框
  * @author:  tianxinzhi
  */
@Component({
  selector: 'app-inputnumber-cst',
  templateUrl: './inputnumber-cst.html',
  styleUrls:['./inputnumber-cst.css']
})
export class InputnumberCstComponent {
  @Input() ipt
  @Input() active
  @Output() change = new EventEmitter()
  onChange(v){
    event.stopPropagation();
    this.change.emit(v)
  }
}
