/**
 * replenishment pre-allocate popup
 */

import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-pre-allocate-common',
  templateUrl: './pre-allocate-common.component.html',
  styleUrls: ['./pre-allocate-common.component.css']
})
export class PreAllocateCommonComponent implements OnInit {
  @HostBinding("style.--popup-body-height") popsupBodyHeight; // use for popup left panel height
  @Input() store
  @ViewChild('ptable') ptable: Table
  constructor() { }

  ngOnInit(): void {
  }
  popupBodyResized(e){
    // get popup body height for left panel scroll height
    // use popup main part to extend body height, left part use this event and css to set height with css variable
    this.popsupBodyHeight = e.height;
  }
}
