import { Paginator } from 'primeng/paginator';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {action, computed, observable} from "mobx";

import {HttpHelper} from "../../../util/HttpHelper";
import {BaseStore, INPUT_TYPE, NOTI_MSG, NOTI_TITLE, URLDICT} from "../../../stores/base/BaseStore";
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { ActivatedRoute } from '@angular/router';
import { FUNCTION_CODE } from '@/service/common/permission.type';
import { PermissionService } from '@/service/common/permission-service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @observable permission: string[]
  // @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false

  @observable lisOfData: any[] = []
  @observable selectedProducts: any[] = []
  @observable queryPannel: any
  @observable createPannel: any

  queryTitle = 'UAM - Role'
  roles: any[]
  channels: any[]
  description: any[]
  roleId
  descriptionId
  seRoleId
  seDescriptionId
  roleEditEnable = false
  // ids: number[] = []
  // mapOfCheckedId: { [key: string]: boolean } = {};
  // showCheckboxSwitch = false
  // allCheckBoxValue = false


  _selectedColumns: any[] = [];

  pageIndex: number = 0
  pageSize: number = 20
  totalRecords = 0

  field = 'roleId'
  order = -1
  isSortSearch

  cols = [
    {header: 'roleId', title: 'Role Name', width: '410px'},
    {header: 'descriptionId', title: 'Desc', width: '410px'}
  ]
  searchCache = {}
  resetSearch = false

  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public n: MessageService, private m: ConfirmationService, private p:ActivatedRoute,private permissionService: PermissionService) {
    this._selectedColumns = this.cols
    this.roles = JSON.parse(localStorage.getItem("UAM_ROLE"))
    this.initQueryData()
    this.getPermission(p)
  }

  initQueryData() {
    this.queryPannel = {
      ipts:[
        {
          title: "Role Name",
          type: INPUT_TYPE.INPUT,
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }, {
          title: "Description",
          type: INPUT_TYPE.INPUT,
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-4",
      btns:[{
        title: 'Search',
        show: true,
        class: "p-order-1",
        handler:{
          "click":()=>{
            this.searchFromFilter()
          }
        }
      }, {
        title: 'Clear',
        show: true,
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        handler:{
          "click":()=>{
            this.refresh()
          }
        }
      }]
    }
    this.createPannel = {
      ipts:[
        {
          title: "Role Name",
          type: INPUT_TYPE.INPUT,
          value: null,
          class: "p-col-12 p-md-4 p-lg-4"
        }, {
          title: "Description",
          type: INPUT_TYPE.INPUT,
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-4",
      btns:[{
        title: 'Create',
        class: "p-order-1",
        handler:{
          "click":()=>{
            this.save()
          }
        }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        handler:{
          "click":()=>{
            this.clear()
          }
        }
      }]
    }
  }

  @action getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')['UAM Permission Setting']
    // console.log('>-- get permission --<', p.snapshot.data.name, this.permission);
    this.setPermission();
  }
  @computed get showU(){
    return true // this.permission?.includes('Update')
  }
  @computed get showC(){
    return true // this.permission?.includes('Create')
  }
  @computed get showD(){
    return true // this.permission?.includes('Delete')
  }

  ngOnInit(): void {
    this.sortPageSearch(true)
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.sortPageSearch(true)
  }
  search(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.pageIndex
      data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_SEARCH, data).then(res => {
      if (res.code == '000') {
        // console.log("data:" + JSON.stringify(res.data))
        this.lisOfData = res.data
        // this.lisOfData.forEach(d => {
        //   d.repoId = d.repoId + ''
        // })
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System", "Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch = false
    }).catch(e => console.log(e)).finally(
      () => this.loadingSwitch = false
    )
  }

  refresh() {
    this.seRoleId = null
    this.seDescriptionId = null
    this.queryPannel.ipts[0].value = null
    this.queryPannel.ipts[1].value = null
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  clear() {
    this.roleId = null
    this.descriptionId = null
    this.createPannel.ipts[0].value = null
    this.createPannel.ipts[1].value = null
  }

  save() {
    let valid = true
    this.roleId = this.createPannel.ipts[0].value ? this.createPannel.ipts[0].value : null
    this.descriptionId = this.createPannel.ipts[1].value ? this.createPannel.ipts[1].value : null


    if (!this.roleId) {
      this.showMessage('warn', 'System', 'please input correct role name!')
      valid = false
      return valid
    }

    if (valid) {
      this.loadingSwitch = true
      // this.roleId=this.roleId.replace(/(^\s*)|(\s*$)/g, "")
      HttpHelper.post(URLDICT.UAM_ROLE_CREATE, {
        roleName: this.roleId.trim(),
        description: this.descriptionId
        // pageIndex: this.pageIndex,
        // pageSize: this.pageSize
      }).then(res => {

        if (res.code == '000') {
          // this.n.success("System", "Create Successful!")
          this.showMessage('success', 'System', 'Create Successful!')
          this.clear()
          this.sortPageSearch()
        } else{
          // this.n.error("System", res.data[0].errorMsg)
          this.showMessage('error', 'System', res.data[0].errorMsg)
        }
        this.loadingSwitch = false
      }).catch(e => console.log(e.toString())).finally(
        () => {
          new BaseStore(null).loadToCache(URLDICT.COMMON_UAM_ROLE_MODULE, "UAM_ROLE");
          this.loadingSwitch = false
        }
      )
    }
  }

  cancel(d) {
    if (d.newid) {
      d.id = d.newid
      delete d.newid
    }
    this.sortPageSearch()
  }

  edit(d) {
    if (d.id) {
      d.newid = d.id
      delete d.id
    }
    // console.log(d)
  }

  page(e) {
    // console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    // if (this.isSortSearch) this.sortPageSearch(this.resetSearch ? true : false)
    // else this.sortPageSearch(this.resetSearch ? true : false)
    this.sortPageSearch(this.resetSearch ? true : false)
    this.resetSearch = false
  }

  sortSearch(key) {
    // console.log(key);
    this.field = key
    this.isSortSearch = true
    if (!this.order) {
      this.order = -1
    } else if (this.order == -1) {
      this.order = 1
    } else if (this.order == 1) {
      this.order = -1
    }
    this.sortPageSearch()
  }
  getSearchParams() {
    return {
      ...(this.queryPannel.ipts[0].value && {roleName: this.queryPannel.ipts[0].value}),
      ...(this.queryPannel.ipts[1].value && {description: this.queryPannel.ipts[1].value}),
      // pageIndex: this.pageIndex,
      // pageSize: this.pageSize
    }
  }
  sortPageSearch(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      // data.pageIndex = this.pageIndex
      // data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    let sortParams = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order}
    }
    data = {...data, ...sortParams}

    // data['sortEvent'] = {field: this.field, order: this.order}
    console.log("comeFrom:" + comeFrom, 'data:', data)
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_SORTSEARCH, data).then(res => {
      if (res.code == '000') {
        this.lisOfData = res.data
        // this.lisOfData.forEach(d => {
        //   d.repoId = d.repoId + ''
        // })
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System", "Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
    }).catch(e => console.log(e)).finally(
      () => this.loadingSwitch = false
    )
  }

  editSave(data) {
    if (data.newid) {
      data.id = data.newid
      delete data.newid
    }
    // console.log("edit data:" + JSON.stringify(data));
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_EDIT, {id: data.id, roleName: data.roleName, description: data.description}).then(res => {
      if (res.code == '000') {
        // console.log(res);
      } else {
        // this.n.error("System", res.data[0].errorMsg)
        this.showMessage('error', 'System', res.data[0].errorMsg)
      }
      this.sortPageSearch()
    }).catch(e => console.log(e)).finally(
      () => {
        new BaseStore(null).loadToCache(URLDICT.COMMON_UAM_ROLE_MODULE, "UAM_ROLE");
        this.loadingSwitch = false
      }
    )
  }

  /* selectOnePNCheckBox(id) {
    if (this.mapOfCheckedId[id]) {
      delete this.mapOfCheckedId[id]
      if (this.allCheckBoxValue) this.allCheckBoxValue = false
    } else {
      this.mapOfCheckedId[id] = true
    }
  } */

  /* selectAllPNCheckbox(v) {
    this.selectAllCheckbox(!this.allCheckBoxValue)
  } */

  /**
   * 设置全选或者全闭
   * @param v => true|false
   */
  /* selectAllCheckbox(v) {
    this.allCheckBoxValue = v
    if (v) {
      this.lisOfData.forEach(d => {
        this.mapOfCheckedId[d.id] = v
        if (d.children != null && d.children.length > 0) {
          d.children.forEach(c => {
            this.mapOfCheckedId[c.id] = v
            // this.checkParentCheckBox(v,d.id,c.id)
          })
        }
      })
    } else {
      this.mapOfCheckedId = {}
    }
    toJS(this.mapOfCheckedId)
  } */

  delete() {
    let len = this.selectedProducts.length
    if (len < 1) {
      // this.n.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      // return
    } else {
      const obj = {
        ids: this.selectedProducts.map(item => item.id + "")
      }
      this.m.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.loadingSwitch = true
          HttpHelper.post(URLDICT.UAM_ROLE_DELETE, obj).then(res => {
            if (res.code == '000' && res.data.length < 1) {
              this.selectedProducts = null
            } else {
              // this.n.error("System", res.data[0].errorMsg)
              this.showMessage('error', 'System', res.data[0].errorMsg)
            }
            this.sortPageSearch()
          }).catch(e => {
            // this.n.error("System", e.toString())
            this.showMessage('error', 'System', e.toString())
          }).finally(
            () => {
              new BaseStore(null).loadToCache(URLDICT.COMMON_UAM_ROLE_MODULE, "UAM_ROLE");
              this.loadingSwitch = false
            }
          )
        }
      });
    }
  }

  showMessage(severity, summary, detail){
    this.n.add({severity, summary, detail});
  }
  setPermission(...e){
    let permissionService = this.permissionService
    this.createPannel && this.createPannel.btns.length>0 && this.createPannel.btns.forEach(item=>{
      item.title=='Create' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.ROLE_EDIT))
    })
    if(permissionService.havePermission(FUNCTION_CODE.ROLE_EDIT)){
      this.roleEditEnable=true;
    }
  }
}
