import { CusMultiSelectItem, MultiSelectComponent } from "@/components/multi-select/multi-select.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "primeng/api";
import { MultiSelect, MultiSelectItem, MultiSelectModule } from "primeng/multiselect";
import { RippleModule } from "primeng/ripple";
import { TooltipModule } from "primeng/tooltip";


@NgModule({
    // imports: [CommonModule,SharedModule,ScrollingModule,TooltipModule,RippleModule],
    // exports: [MultiSelectComponent,MultiSelect,SharedModule,ScrollingModule],
    // declarations: [MultiSelectComponent,MultiSelect,MultiSelectItem]
    
    imports: [CommonModule,SharedModule,ScrollingModule,TooltipModule,RippleModule],
    exports: [MultiSelectComponent,SharedModule,ScrollingModule],
    declarations: [MultiSelectComponent,CusMultiSelectItem]
})
export class CusMultiSelectModule { }