import { BasePannel } from './BasePannel';




/**
 *  class for AddPannelStore
 *  新增面板
 */
export class AddPannelStore extends BasePannel{
    constructor(qp){
        super(qp.ipts,qp.btns,qp.btnsclass)
    }
}
