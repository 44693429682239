export class LocalStorageHelper {
   
      /**
       * 写入缓存
       * @param key 
       * @param value 
       */
      public static setObject(key:string, value:any):void {
        localStorage[key] = JSON.stringify(value);
      }
     
      /**
       * 读取缓存
       * @param key 
       */
      public static getObject(key:string):any {
        if(localStorage[key]){
          // try catch to handle error value inputed to localstorage, e.g. 'undefined' string
          try{
            return JSON.parse(localStorage[key] || '{}');
          }catch(e){}
          return {}
        }else{
          return {}
        }
      }
      /**
       * 获取缓存中所有的key
       */
      public static getAllObj():any{
        let getkeys = []
        for(let i=0;i<localStorage.length;i++){
            getkeys.push(localStorage.key(i)) 
        }
        return getkeys
      }
      /**
       * 删除对应key的缓存
       * @param key 
       */
      public static remove(key:string):any {
        localStorage.removeItem(key);
      }

      /**
       * 清除所有缓存
       */
      public static removeAll():any{
     
        localStorage.clear();
      }

      public static isEmptyValue(val):boolean{
        return typeof val == 'object' && JSON.stringify(val).length == 2
      }
    
  
}
