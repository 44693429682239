import { CommonService } from 'src/app/service/common/common-service';
import { LocalStorageHelper } from '@/util/LocalStorageHelper';
import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { HttpHelper } from '@/util/HttpHelper';
import { URLDICT } from '@/stores/base/BaseStore';
import { StockCommonService } from '../stock/stock-common.service';
import { Router } from '@angular/router';
import { CommonStore } from '../common/common-store-service';

@Injectable({
  providedIn: 'root',
})
export class BaseResolverService {
  // keys inited in this session, reset when reload page / new session / logout
  private initedKey = [];

  public resolverDataList: {[key:string]: RESOLVERDATA} = {
    ORDER_TYPE: {
      resolverKey: 'ORDER_TYPE',
      storeKeyList: ['ORDER_TYPE'],
      getApiObservable: () =>
        from(HttpHelper.post(URLDICT.STOCK_TRANSFER_ORDERTYPE, {})),
      apiCallback: (res) =>
        this.commonService.orderTypeHandle(res, 'ORDER_TYPE'),
    },
    ALL_ORDER_TYPE: {
      resolverKey: 'ALL_ORDER_TYPE',
      storeKeyList: ['ALL_ORDER_TYPE'],
      getApiObservable: () =>
        from(HttpHelper.post(URLDICT.STOCK_TRANSFER_ALLORDERTYPE, {})),
      apiCallback: (res) =>
        this.commonService.orderTypeHandle(res, 'ALL_ORDER_TYPE'),
    },
    HISTORY_TRANSACTION_TYPE: {
      resolverKey: 'HISTORY_TRANSACTION_TYPE',
      storeKeyList: ['HISTORY_TRANSACTION_TYPE'],
      getApiObservable: () =>
        from(HttpHelper.post(URLDICT.STOCK_HISTORY_TRANSACTION_TYPE, {})),
      apiCallback: (res) =>
        this.commonService.orderTypeHandle(res, 'HISTORY_TRANSACTION_TYPE'),
    },
    All_STATUS: {
      resolverKey: 'All_STATUS',
      storeKeyList: [
        'TRANSFER_STATUS',
        'INTERFACE_STATUS',
        'REPLENISHMENT_STATUS',
        'TAKE_STATUS',
        'ORDER_STATUS',
        'ORDER_ADJUSTMENT_STATUS',
      ],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_REQUESTSTATUSMODULE)),
      apiCallback: (res) => this.commonService.allHandle(res),
    },

    REASON: {
      resolverKey: 'REASON',
      storeKeyList: ['REASONS', 'ADJ_REASONS'],
      getApiObservable: () =>
        from(
          HttpHelper.get(
            URLDICT.REASONTYPE_GETALL
          )
        ),
      apiCallback: (res) => this.commonService.adjreasonHandle(res, { STOCK_ADJUSTMENT: 'REASONS', COLOR_ADJUSTMENT: 'ADJ_REASONS' }),
    },

    // ADJREASON: {
    //   resolverKey: 'ADJREASON',
    //   storeKeyList: ['ADJ_REASONS'],
    //   getApiObservable: () =>
    //     from(
    //       HttpHelper.get(
    //         URLDICT.REASONTYPE_GETALL + '?' + new URLSearchParams({ reasonType: 'COLOR_ADJUSTMENT' }).toString()
    //       )
    //     ),
    //   apiCallback: (res) =>
    //     this.commonService.defaultHandle(res, 'ADJ_REASONS'),
    // },

    REPO: {
      resolverKey: 'REPO',
      storeKeyList: ['REPO', 'WAREHOUSE', 'REPOTREE', 'REPOGROUP', 'REPOMODULEBYUAMGROUP', 'REPOMODULEBYUAM', 'REPOMODULEBYUAMTREE'],
      versionKeyList: ['channel'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_REPO_MODULE_BY_MERGE)),
      apiCallback: (res) => this.commonService.newRepoHandle(res),
    },

    GETSKU: {
      resolverKey: 'sku',
      storeKeyList: ['SKU'],
      versionKeyList: ['item'],
      getApiObservable: () => from(HttpHelper.get(URLDICT.COMMON_SKU_MODULE)),
      apiCallback: (res) => this.commonService.skuHandle(res, 'SKU'),
    },
    GETSKUBYUAM: {
      resolverKey: 'skuByUam',
      storeKeyList: ['SKUMODULEBYUAM'],
      versionKeyList: ['item'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_SKUBYUAM_MODULE)),
      apiCallback: (res) => this.commonService.skuHandle(res, 'SKUMODULEBYUAM'),
    },
    // GETSKUBYUAM2: {
    //   resolverKey: 'GETSKUBYUAM2',
    //   storeKeyList: ['SKUMODULEBYUAM'],
    //   getApiObservable: () => from(HttpHelper.get(URLDICT.COMMON_SKU_MODULE)),
    //   apiCallback: (res) => this.commonService.skuHandle(res, 'SKUMODULEBYUAM'),
    // },
    RESSTOCKRESERVATION_TYPE: {
      resolverKey: 'RESSTOCKRESERVATION_TYPE',
      storeKeyList: ['RESSTOCKRESERVATIONTYPE'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_RESSTOCKRESERVATIONTYPE)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'RESSTOCKRESERVATIONTYPE'),
    },
    // 'ORDER_STATUS':{
    //   resolverKey: 'status',
    //   storeKeyList: ['ORDER_STATUS'],
    //   getApiObservable: ()=>from(HttpHelper.get(URLDICT.COMMON_SOURCESYSTEM)),
    //   apiCallback: res => this.commonService.defaultHandle(res, 'SOURCESYSTEM')
    // },
    SOURCE: {
      resolverKey: 'SOURCE',
      storeKeyList: ['SOURCESYSTEM'],
      getApiObservable: () => from(HttpHelper.get(URLDICT.COMMON_SOURCESYSTEM)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'SOURCESYSTEM'),
    },
    API_TYPE: {
      resolverKey: 'API_TYPE',
      storeKeyList: ['API_TYPE'],
      getApiObservable: () => from(HttpHelper.get(URLDICT.COMMON_API_TYPE)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'API_TYPE'),
    },
    RESERVATION_REASON: {
      resolverKey: 'RESERVATION_REASON',
      storeKeyList: ['RESERVATION_REASON'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_RESERVATION_REASON)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'RESERVATION_REASON'),
    },
    // REPOMODULEBYUAMGROUP: {
    //   resolverKey: 'repo',
    //   storeKeyList: ['REPOMODULEBYUAMGROUP'],
    //   getApiObservable: () =>
    //     from(HttpHelper.get(URLDICT.COMMON_REPO_MODULE_BY_MERGE)),
    //   apiCallback: (res) => this.commonService.newRepoHandle(res),
    // },
    // REPOMODULEBYUAM: {
    //   resolverKey: 'repo',
    //   storeKeyList: ['REPOMODULEBYUAM'],
    //   getApiObservable: () =>
    //     from(HttpHelper.get(URLDICT.COMMON_REPO_MODULE_BY_MERGE)),
    //   apiCallback: (res) => this.commonService.newRepoHandle(res),
    // },
    POOL: {
      resolverKey: 'pool',
      storeKeyList: ['POOL_TYPE'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.STOCK_DEMAND_CYCLE_POOLTYPE)),
      apiCallback: (res) => this.commonService.poolHandle(res, 'POOL_TYPE'),
    },
    CYCLE: {
      resolverKey: 'cycle',
      storeKeyList: ['DEMAND_CYCLE_TYPE'],
      getApiObservable: () =>
        from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_TYPE, {})),
      apiCallback: (res) =>
        this.commonService.reasonHandle(res, 'DEMAND_CYCLE_TYPE'),
    },
    REQUESTURL: {
      resolverKey: 'requestURL',
      storeKeyList: ['REQUESTURL'],
      getApiObservable: () => from(HttpHelper.get(URLDICT.COMMON_REQUESTURL)),
      apiCallback: (res) => this.commonService.defaultHandle(res, 'REQUESTURL'),
    },
    ADDRESS: {
      resolverKey: 'ADDRESS',
      storeKeyList: ['REPLENISH_ADDRESS'],
      getApiObservable: () =>
        from(HttpHelper.post(URLDICT.CHANNEL_REPLENISH_GET_ADDRESS3_LIST, {})),
      apiCallback: (res) =>
        this.commonService.addressHandle(res, 'REPLENISH_ADDRESS'),
    },
    COURIES: {
      resolverKey: 'COURIES',
      storeKeyList: ['REPLENISH_COURIER'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.CHANNEL_REPLENISH_GET_COURIER_NAME)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'REPLENISH_COURIER'),
    },
    SYSDEFLOOKUPDESC: {
      resolverKey: 'SYSDEFLOOKUPDESC',
      storeKeyList: ['SYS_DEF_LOOKUP_DESC'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'LIS_10U_MAXRETRY',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'SYS_DEF_LOOKUP_DESC'),
    },
    SYSDEFLOOKUPDESCSOURCE: {
      resolverKey: 'SYSDEFLOOKUPDESCSOURCE',
      storeKeyList: ['SYS_DEF_LOOKUP_DESC'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'SOURCE_SYSTEM',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'SYS_DEF_LOOKUP_DESC'),
    },
    COM_INTERFACE_STATUS: {
      resolverKey: 'COM_INTERFACE_STATUS',
      storeKeyList: ['COM_INTERFACE_STATUS'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'COM_INTERFACE_STATUS',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'COM_INTERFACE_STATUS'),
    },
    IMS_INTERFACE_STATUS: {
      resolverKey: 'IMS_INTERFACE_STATUS',
      storeKeyList: ['IMS_INTERFACE_STATUS'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'IMS_INTERFACE_STATUS',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'IMS_INTERFACE_STATUS'),
    },
    BOM_INTERFACE_STATUS: {
      resolverKey: 'BOM_INTERFACE_STATUS',
      storeKeyList: ['BOM_INTERFACE_STATUS'],
        getApiObservable: () =>
          from(
            HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
              lookupType: 'BOM_INTERFACE_STATUS',
        })
      ),
      apiCallback: (res) =>
      this.commonService.defaultHandle(res, 'BOM_INTERFACE_STATUS'),
    },
    LIS_INTERFACE_TXN_CODE: {
      resolverKey: 'LIS_INTERFACE_TXN_TYPE',
      storeKeyList: ['LIS_INTERFACE_TXN_TYPE'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'LIS_INTERFACE_TXN_TYPE',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'LIS_INTERFACE_TXN_TYPE'),
    },
    STOCKTAKELABEL: {
      resolverKey: 'STOCKTAKELABEL',
      storeKeyList: ['TAKE_LABEL'],
      getApiObservable: () => from(HttpHelper.get(URLDICT.STOCK_TAKE_LABEL)),
      apiCallback: (res) =>
        this.commonService.takeLabelHandle(res, 'TAKE_LABEL'),
    },
    CONDITIONS: {
      resolverKey: 'condition',
      storeKeyList: ['CONDITIONS'],
      getApiObservable: () =>
        this.stockCommonService.getStockConditionsResponse(),
      apiCallback: (res) => this.commonService.defaultHandle(res, 'CONDITIONS'),
    },
    STOCKTAKECOUNTBY: {
      resolverKey: 'STOCKTAKECOUNTBY',
      storeKeyList: ['STOCK_TAKE_COUNT_BY'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_STOCK_TAKE_COUNT_BY)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'STOCK_TAKE_COUNT_BY'),
    },
    SYSTEM_MASTER: {
      resolverKey: 'SYSTEM_MASTER',
      storeKeyList: ['SYSTEM_MASTER'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_SYSTEMMASTER)),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'SYSTEM_MASTER'),
    },
    ACCESS_FUNCTION: {
      resolverKey: 'ACCESS_FUNCTION',
      storeKeyList: ['ACCESS_FUNCTION'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_UAM_FUNCTION_LIST)),
      apiCallback: (res) => this.commonService.defaultHandle(res, 'ACCESS_FUNCTION'),
    },
    UAM_FUNCTION: {
      resolverKey: 'UAM_FUNCTION',
      storeKeyList: ['UAM_FUNCTION'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_UAM_ROLEFUNCTION_MODULE)),
      apiCallback: (res) => this.commonService.functionHandle(res, 'UAM_FUNCTION'),
    },
    UAM_USER: {
      resolverKey: 'UAM_USER',
      storeKeyList: ['UAM_USER'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_UAM_USER_MODULE)),
      apiCallback: (res) => this.commonService.defaultHandle(res, 'UAM_USER'),
    },
    UAM_ROLE: {
      resolverKey: 'UAM_ROLE',
      storeKeyList: ['UAM_ROLE'],
      getApiObservable: () =>
        from(HttpHelper.get(URLDICT.COMMON_UAM_ROLE_MODULE)),
      apiCallback: (res) => this.commonService.roleHandle(res, 'UAM_ROLE'),
    },
    DEFAULT_CHANNEL: {
      resolverKey: 'DEFAULT_CHANNEL',
      storeKeyList: ['DEFAULT_CHANNEL'],
      getApiObservable: () =>
        // of({code:'000',data:[]}),
        from(HttpHelper.get(URLDICT.COMMON_GET_DEFAULT_CHANNEL)),
      apiCallback: (res) => this.commonService.roleHandle(res, 'DEFAULT_CHANNEL'),
    },
    STOCK_TAKE_BU: {
      resolverKey: 'STOCK_TAKE_BU',
      storeKeyList: ['STOCK_TAKE_BU'],
      getApiObservable: () =>
        from(
          HttpHelper.post(URLDICT.SYS_DEF_LOOKUP_DESC, {
            lookupType: 'STOCK_TAKE_BU',
          })
        ),
      apiCallback: (res) =>
        this.commonService.defaultHandle(res, 'STOCK_TAKE_BU'),
    },
  };

  constructor(
    public commonService: CommonService,
    public stockCommonService: StockCommonService,
    public router: Router,
    public commonStore: CommonStore,
  ) {
  }

  // return null if current session never get target localstore data from api
  // to force resolver call api
  getResolverKeyObject(key) {
    let data = LocalStorageHelper.getObject(key);
    if (
      // this key is loaded in this session, dont need to load again
      this.initedKey.includes(key) &&
      // wip, return true now, add logic here for more logic, e.g. version number etc
      this.isReusable(key) &&
      // ignore empty obj {} return by LocalStorageHelper getObject from empty localstore,
      // force reload config when this key is empty / == {} in localstore
      data != null &&
      JSON.stringify(data) != '{}'
    ) {
      return data;
    }
    return null;
  }
  isReusable(key) {
    return true;
  }

  /*
    return of(null).pipe(
      switchMap( switch map to get version api )
      switchMap(
        check which resolver data need to reload
        and switch map to forkjoin(
          call api
          call callback in resolver data
          store initKey
          store version
        )
      )
    )
  */
  getResolverResult(e: RESOLVERDATA[]) {
    let _obs = of(null).pipe(
      // get kafka version to force reload target data without f5
      switchMap(res=>{
        return this.getVersionObs()
      }),
      catchError(err=>{
        this.router.navigate(["/404"]);
        throw err
      }),
      switchMap(versionRes=>{
        let res: { [key: string]: Observable<any> } = { local: from('1') };
        let version = Object.assign({}, ...(<any[]>versionRes.data).map(obj=>({[obj.objectName]: obj.objectVersion})))
        e.forEach((data) => {
          let haveKeyReturnNull = data.storeKeyList.some((key) => {
            return this.getResolverKeyObject(key) == null;
          });
          let needToChangeVersionList = this.getVersionNeedToUpdate(data, version)
          let _res;
          if (haveKeyReturnNull || needToChangeVersionList.length > 0) {
            _res = {
              [data.resolverKey]: of(null).pipe(
                // switchmap to getApiObservable function result (observable for call api)
                switchMap(() => data.getApiObservable()),
                // call back function for set key value to store / localstore
                // tap(res=>{if(res.status==207&&res.message=='Account Expired'){
                //   this.router.navigate(['404'])
                //   throw null
                // }}),
                tap((e)=>data.apiCallback(e)),
                // initedKey use for f5 reload data config logic
                tap(() => {
                  this.initedKey.push(...data.storeKeyList);
                  this.storeVersion(needToChangeVersionList)
                }),
              ),
            };
            // wip: may need to add error handle here, any api failed mean resolver failed,
            // may need to route to error page to prevent page stuck in current page / loading page
            // or show a error msg
            res = { ...res, ..._res };
          }
        });
        return forkJoin(res).pipe(tap(res=>{
          return res
        }));
      }),
      catchError(err=>{
        this.router.navigate(["/404"]);
        throw err
      }),
    )

    return _obs
  }

  getVersionObs(){
    return this.commonService.getKafkaVersions()
  }

  storeVersion(needToChangeVersionList: any[]){
    needToChangeVersionList.forEach(versionObject => {
      // this.commonStore.setObject(versionObject.key, versionObject.value)
      LocalStorageHelper.setObject(versionObject.key, versionObject.value)
    });
  }

  getStoreVersionKey(storeKey, verstionKey){
    return `${storeKey}_${verstionKey}_version`
  }

  // return [{ key: 'storekey_versionkey_version', value: new version number }]
  getVersionNeedToUpdate(resolverData: RESOLVERDATA, newVersionData: any){
    let versionKeyList = resolverData.versionKeyList
    let storeKeyList = resolverData.storeKeyList

    if(!versionKeyList || versionKeyList.length == 0) return [];
    let needToChangeVersionList = []
    versionKeyList.forEach(vKey => {
      storeKeyList.forEach(sKey => {
        let combineKey = this.getStoreVersionKey(sKey, vKey)
        let oldData = LocalStorageHelper.getObject(combineKey)
        if(newVersionData[vKey]!=null && (LocalStorageHelper.isEmptyValue(oldData) || oldData < 0 || oldData < newVersionData[vKey])){
          needToChangeVersionList.push({key: combineKey, value: newVersionData[vKey]})
        }
      });
    });
    return needToChangeVersionList
  }

  // logout reset localstore, session, oauth, but keep the same session / angular provide in root obj, etc,
  // manually call this reset function to reset initedKey
  reset() {
    this.initedKey = [];
  }
}

export interface RESOLVERDATA {
  // resolver jorkjoin key list
  resolverKey: string;

  // localstorage key list, also use to know which key already init in current session
  storeKeyList: string[];

  // map with storeKeyList, store version data, work with /kafka_object_version/getAll objectName, key: {storeKey}_{versionKey}_version, value: {number}
  versionKeyList?: string[];

  // function to get api call observable
  getApiObservable: () => Observable<any>;

  // callback function after getApiObservable api call complete
  apiCallback: (x: any) => void;
}
