
import { observable, action } from 'mobx';
import { Inject, Injectable } from '@angular/core';
import { BaseStore } from '../base/BaseStore';
import { MENU } from "../../config";
@Injectable()
export class MainStore extends BaseStore {
  private subMenuList = [
    MENU.CATEGORY.ENQUIRIES,
    MENU.CATEGORY.CHANNEL_OPERATIONS,
    MENU.CATEGORY.CHANNEL_REPLENISHMENT,
    // MENU.CATEGORY.STOCK_ASSORTMENT,
    MENU.CATEGORY.REPORTS,
    MENU.CATEGORY.SYSTEM
  ];
  private welcomeText;

  /**
   *
   */
  constructor(@Inject({}) params) {
    super(params)
    // this.init_inject(params)
    if(this.$CACHE.getObject("MENU")) {
      this.menuArr  = this.$CACHE.getObject("MENU")
      // const subMenu = this.menuArr.find(item => item.label === "Stock")
      if(this.menuArr && !this.$CACHE.isEmptyValue(this.menuArr)){
      let stockMenu = this.menuArr.find(item => item.label === "Stock");
      let list = [];
      this.subMenuList.forEach(name => {
        const result = stockMenu.items.filter(subMenu => subMenu.category === name);
        const isShow = result.every(item => !item.visible);
        if (result && !isShow) {
          list.push({
            visible: true,
            label: name,
            name,
            items: result,
          });
        }
      });
      stockMenu.items = list;}
    }
    this.welcomeText = this.$COMMONSTORE.getAccount()

  }

  /**
   * current selected id
   */
  @observable selectedMenuId = '0-0';

  /**
   * data of menu
   */
  @observable menuArr;
  /**
   * menu collapsed
   */
  @observable isCollapsed = false;

  // /**
  //  * 登出(logout)
  //  */
  // @action logout() {
  //   this.clearSession().then(()=>{
  //     this.$CACHE.removeAll();
  //     this.$ROUTER.navigate(["/login"])
  //   })
  // }

  // /**
  //  * 调API删除redis中的session信息
  //  */
  // clearSession(){
  //   return this.$HTTP.post(this.$URLDICT.SYSTEM_LOGOUT,{}).then(
  //     res=>{
  //       console.log(res.data[0])
  //     }
  //   )
  // }
}
