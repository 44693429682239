import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
  selector: 'app-json-display-pop-up',
  templateUrl: './json-display-pop-up.component.html',
  styleUrls: ['./json-display-pop-up.component.css']
})
export class JsonDisplayPopUpComponent implements OnInit {
  public title = 'Detail' 
  @Input() visable = false;
  @Input() jsResult = '';
  @Output() visableChange = new EventEmitter();
  constructor(private clipboard: Clipboard, public messageService: MessageService,) { }
  
  ngOnInit(): void {
  }

  onHide(e){this.visableChange.emit(false)}
  hide(e){this.visableChange.emit(false)}
  addMessage(severity, summary, detail){
    this.messageService.add({severity:severity,summary:summary,detail:detail})
  }

  clickToCopy(){
    if(this.jsResult!=null){
      this.clipboard.copy(this.jsResult)
      this.addMessage('success', this.title, 'Copied the result')
    }else{
      this.addMessage('error', this.title, 'No Result')
    }
  }
}
