import { BaseStore, URLDICT } from '../base/BaseStore';
import { HttpHelper } from "../../util/HttpHelper";
import { MenuStore } from '../menu/MenuStore';
import { LocalStorageHelper } from "../../util/LocalStorageHelper";
import { Message } from 'primeng/api';
import { filter } from 'rxjs/operators';


export class LoadingStore extends BaseStore {
  // private percent;
  private menuStore: MenuStore = null;
  msgs: Message[] = [];
  private res: any = null;
  private dialogFlag: boolean = false;
  constructor(params) {
    super(params)
    this.menuStore = new MenuStore()
    // this.percent = 0;
    const account = this.$COMMONSTORE.getAccount() //this.$PARAMS.queryParams.value['account']
    !!account ? this.dataLoad() : this.checkKeycloak(params.oauthService) //this.loginAdmin()
  }
  checkKeycloak(oauthService) {
    oauthService.events.pipe(filter((e:Event) => e.type === 'token_received')).subscribe((_) => {
      // console.log('=> get Identity Claims:', oauthService.getIdentityClaims());
      const data = oauthService.getIdentityClaims()
      // get name
      const name = data.name || ''
      // get password
      const pwd = data.preferred_username
      this.loginAdmin(name, pwd)
    });
  }

  /**登录跳转**/
  loginAdmin(name='', pwd='') {
    //let sto = window.localStorage
    const username = name
    const password = pwd
    const isKeycloak = 1
    sessionStorage.removeItem('loginError')
    this.$CACHE.setObject("ISKEYCLOAK",isKeycloak);
    HttpHelper.post(URLDICT.SYSTEM_LOGIN, { username, password, isKeycloak }).then((res => {
        if (res.status === 203) {
          let info = res.statusText || 'Non-Authoritative Information'
          sessionStorage.setItem('loginError',  info)
          this.$ROUTER.navigate(['invalid'], {queryParams: {from: 'keycloak'}})
        }
        if (res.status === 200) {
          // 设置token
          let resolt = res.json()
          resolt.then(item => {
            let token = item.token
            let permissions = item.menu
            // console.log('----- auth info -----')
            // console.table(permissions)
            // console.log('----- login info -----', 'token:', token);
            // console.log('----- login keyclock info -----', 'token:', token, 'auth:', permissions);
            // sessionStorage.setItem(
            //   'account', 
            //   username
            //   // 'admin'
            // )
            this.$COMMONSTORE.setAccount(username)
            // console.table(JSON.parse(permissions));
            if(permissions) {
              this.$CACHE.setObject("TOKEN",token);
              this.$CACHE.setObject("AUTH", permissions);
              this.$CACHE.setObject("ISKEYCLOAK",isKeycloak);
              this.menuStore.setUserCode(permissions)
              this.$COMMONSTORE.permissionService.setPermission()
              this.dataLoad()
            } else {
              // this.$NOTICE.error('ERROR', NOTI_MSG.ERROR_ACCOUNTORPASSWORD)
            }
          })
        }
      })
    ).catch((e) => {
      console.log(e.toString());
    })
  }

  upgrade() {
    //找到stock模块下第一个有权限的模块，并进入其页面，如果没有则进入main page
    const menuList = this.$CACHE.getObject("MENU")
    // console.log('menuList', menuList);
    // this.loadOtherApi()
    const role = LocalStorageHelper.getObject("USERSESSION")[0].role
    for (let index = 0, len = menuList.length; index < len; index++) {
      const module = menuList[index].items.find(item => item.visible)
      // console.log('=> module: ', module, 'role',role);
      if(module) {
        // this.$ROUTER.navigate([module.routerLink[0]], {queryParams: {account: this.$PARAMS.queryParams.value['account'] || 'admin'}})
        this.dialogFlag = role.some(item => item === 79)
        if (this.dialogFlag) {
          // 查询是否还有transfer orders
          let transfer =  LocalStorageHelper.getObject("TRANSFER_CONFIRM");
          // console.log('TRANSFER_CONFIRM', transfer.count);

          if (transfer.count) {
            // this.transferFlag = true
            this.$MODAL.confirm({
              message: 'You have '+ transfer.count + ' stock transaction(s) pending to receive. Would you like to book-in ?',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.navigate(transfer)
                this.msgs = [{severity:'info', summary:'Confirmed', detail:'Record deleted'}];
              },
              reject: () => {
                this.startJump(module.routerLink[0])
                this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
              }
            });
          } else {
            this.startJump(module.routerLink[0])
          }
        }else{
          this.startJump(module.routerLink[0])
        }
        return
      }else{
        this.startJump('**')
      }
    }
  }
  // start jump
  startJump(url) {
    setTimeout(() => {
      this.$ROUTER.navigate([url])
    }, 0);
  }
  navigate(obj){
    // this.$ROUTER.navigate([module.routerLink[0]], {queryParams: {account: this.$PARAMS.queryParams.value['account'] || 'admin'}})
    // console.log("data:", obj)
    // this.$ROUTER.navigate(["main", "transfer_order", "search"], {queryParams: this.res})
    // this.$ROUTER.navigate( ["/main/transfer_order/search"],{queryParams: this.res})
    this.$ROUTER.navigate(["/main/transfer_order/search"], {queryParams: obj})

  }
  dataLoad() {
    const apiList = [
      {name: 'USERSESSION', url: this.$URLDICT.COMMON_GET_USERSESSION},
      {name: 'TRANSFER_CONFIRM', url: this.$URLDICT.STOCK_TRANSFER_CONFIRM},
      // {name: 'SKU', url: this.$URLDICT.COMMON_SKU_MODULE},
      // {name: 'SKUMODULEBYUAM', url: this.$URLDICT.COMMON_SKUBYUAM_MODULE},
      // {name: 'REPO', url: this.$URLDICT.COMMON_REPO_MODULE},
      // {name: 'REPOMODULEBYUAM', url: this.$URLDICT.COMMON_SEARCH_REPOMODULEBYUAM},
      // {name: 'SOURCESYSTEM', url: this.$URLDICT.COMMON_SOURCESYSTEM},
    ]
    const totalNum = apiList.length
    let count = 0
    apiList.forEach(item => {
      this.loadToCache(item.url, item.name).then(() => {
        count++
        // this.percent = Math.floor(count / totalNum * 100)
        // tslint:disable-next-line: no-unused-expression
        count === totalNum && this.upgrade()
      })
    })
  }
  // loadOtherApi() {
    // const apiList = [
      // {name: 'ALL_STATUS', url: this.$URLDICT.COMMON_REQUESTSTATUSMODULE},
      // {name: 'ORDER_TYPE', url: this.$URLDICT.STOCK_TRANSFER_ORDERTYPE},
      // {name: 'REPOMODULEBYUAM', url: this.$URLDICT.COMMON_SEARCH_REPOMODULEBYUAM},
      // {name: 'REASONS', url: this.$URLDICT.STOCK_ADJUSTMENT_GETRESONS},
      // {name: 'ASSIGNMENTLOGIC', url: this.$URLDICT.COMMON_ASSIGNMENTLOGIC},
      // {name: 'RESSTOCKRESERVATIONTYPE', url: this.$URLDICT.COMMON_RESSTOCKRESERVATIONTYPE},
      // {name: 'RESERVATION_REASON', url: this.$URLDICT.COMMON_RESERVATION_REASON},
      // {name: 'STOCK_TAKE_BULK_ADD_FILTER', url: this.$URLDICT.COMMON_STOCK_TAKE_BULK_ADD_FILTER},
      // {name: 'BRAND_NAME', url: this.$URLDICT.COMMON_BRAND_NAME},
      // {name: 'STOCK_TAKE_COUNT_BY', url: this.$URLDICT.COMMON_STOCK_TAKE_COUNT_BY},
      // {name: 'UAM_FUNCTION', url: this.$URLDICT.COMMON_UAM_ROLEFUNCTION_MODULE},
      // {name: 'UAM_USER', url: this.$URLDICT.COMMON_UAM_USER_MODULE},
      // {name: 'UAM_ROLE', url: this.$URLDICT.COMMON_UAM_ROLE_MODULE},
      // {name: 'STOCK_TYPE', url: this.$URLDICT.COMMON_STOCK_TYPE_MODULE},
      // {name: 'TRX_TYPE', url: this.$URLDICT.COMMON_TRX_TYPE_MODULE},
    // ]
    // apiList.forEach(item => {
      // this.loadToCache(item.url, item.name).then(() => {
        // console.log('loading api: ', item.name, 'succeeded!');
      // })
    // })
  // }
}
