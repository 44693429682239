import {Component, Input, ViewChild} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'edit-table-pannel',
  templateUrl:'./edit-table-pannel.component.html',
  styleUrls: ['./edit-table-pannel.component.css']
})
export class EditTablePannelComponent {
  @Input() productStore
  @Input() store
  @Input() searchStore
  @Input() tableHeaderCheckbox = true
  @Input() rowEditable = false
  @Input() scrollable = true
  @Input() isHover = 'true'
  @ViewChild('op', {static:false}) op: OverlayPanel
  onShowCalender(e, data, field) {
    this.searchStore.selectRows =  { data, field }
    this.searchStore.currentDate = new Date(data[field])
    this.op.toggle(e)
  }
}
