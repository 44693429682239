import { FUNCTION_CODE } from "@/service/common/permission.type";

export const TITLE = {
  TRANSACTION_TYPE: "Transaction Type",
  ORDER_NUMBER: "Stock Order No.",
  ORDER_STATUS: "Stock Order Status",
  CREATION_DATE: "Creation Date",
  FROM: "From",
  FROM_STATUS_CONDITION: "From Status Condition",
  TO: "To",
  TO_STATUS_CONDITION: "To Status Condition",
  REFERENCE_NUMBER: "Reference Number",
  REASON: "Reason",
  sourceTxnRefHeaderNo: "Source Ref No.",
  REMARK: "Remark",
  internalRemarks: "Workflow Subject",
  internalReference: "Reference Number",
  NO_OF_CARTON_BOX: "No. of Carton Box",
  netAmount: "Total Net Amount",
  APPOINTMENTDATE: "Appointment Date",
  replenishReference: "Replenish Reference",
  isoNo: "ISO No.",
  deliveryBatchNo: "Delivery Batch No.",
  UPDATEBY: "Update By",
  CRREATEBY: "Create By",
  UPDATEDATE: "Update Date",
};

export const REASON = {
  SE: 1,
  HE: 5,
  CA: 6,
  AHM: 4,
  AHA: 3,
  WO: 2,
};

export const SELECTEDTYPE = {
  CHANGECONDITION: "CHANGE-CONDITION", // change stock condition
  ADJUSTMENT: "ADJUSTMENT", // Stock adjustment
  CHANNELTRANSFER: "CHANNEL-TRANSFER", // Channel to Channel By Staff
  ASSORTMENT: "REPLENISHMENT", // Warehouse to Channel
  RETURN: "RETURN", // Channel to Warehouse
  CHANNELTRANSFERCOURIER: "CHANNEL-TRANSFER-COURIER", //Channel to Channel By Courier
  WAREHOUSEHOMED: "WAREHOUSE-HOMED",
  CHANNELHOMED: "CHANNEL-HOMED",
  CHANNELHOMEDRETURN: "CHANNEL-HOMED-RETURN", // Return to Channel from Customer
  WAREHOUSEHOMEDRETURN: "WAREHOUSE-HOMED-RETURN", // Return to Warehouse from Customer
  COLORADJUSTMENT: "COLOR-ADJUSTMENT",
  NOPREPLENISHMENT: "NOP-REPLENISHMENT",
  RETURNWOCOURIER: "RETURN-WO-COURIER", // Channel to Warehouse
  NOPSALESRETURN: "NOP-SALES-RETURN",
  ADJUSTMENTHKTHOME: "ADJUSTMENT-HKTHOME",
};

export const ORDERTYPEID = {
  CHANGECONDITION: 3, // change stock condition
  ADJUSTMENT: 1, // Stock adjustment
  CHANNELTRANSFER: 5, // Channel to Channel By Staff
  ASSORTMENT: 4, // Warehouse to Channel
  RETURN: 2, // Channel to Warehouse
  CHANNELTRANSFERCOURIER: 16, // Channel to Channel By Courier
  WAREHOUSEHOMED: 15, // Warehouse to Customer
  CHANNELHOMED: 14, // Channel to Customer
  CHANNELHOMEDRETURN: 20, // Return to Channel from Customer
  WAREHOUSEHOMEDRETURN: 21, // Return to Warehouse from Customer
  COLORADJUSTMENT: 23,
  NOPREPLENISHMENT: 24,
  RETURNWOCOURIER: 25,
  NOPSALESRETURN: 26,
  ADJUSTMENTHKTHOME: 27,
};

export const PANELBTNTAG = {
  SAVEASDRAFT: "SaveAsDraft",
  UPDATETONEXTSTATUS: "UpdateToNextStatus",
  CLEARALL: "ClearAll",
  BACKTOHISTORYPAGE: "BackTOHistoryPage",
  EXPORTDN: "ExportDN",
  EDIT: "Edit",
  RETURN: "Return",
  Attachment: "Attachment",
  ADJ_REJECT: "AdjustmentReject",
  CANCEL: "Cancel",
  EXPORT: "Export",
  ADJ_CANCEL: "AdustmentCancel",
};

export const STATUSID = {
  DRAFT: 7,
  ALLOCATED: 8,
  TRANSFERRED: 9,
  RECEIVED: 10,
  COMPLETED: 11,
  PARTIAL: 63,
  ADJ_DRAFT: 102,
  ADJ_REVIEWING: 103,
  ADJ_SUBMITTED: 104,
  ADJ_VERIFIED: 105,
  ADJ_APPROVEIP: 106,
  ADJ_APPROVED: 107,
  ADJ_REJECTED: 108,
  ADJ_ADJUSTIP1: 109,
  ADJ_ADJUSTIP2: 110,
  ADJ_COMPLETED: 111,
  ADJ_PENDING_START_K2: 115,
  WAITING_TO_COMPLETE: 132,
  FAIL_TO_COMPLETE: 150,
};

export const BTN_LABEL = {
  EXPORT_DN: "Export DN",
  ADJUST: "Adjust",
  ALLOCATE: "Allocate",
  STOCK_IN: "Stock In",
  TRANSFER: "Transfer",
  RECEIVE_FOR_INSPECTION: "Receive for Inspection",
  COMPLETE: "Complete",
  CONFIRM: "Confirm",
  RETURN: "Return",
  SAVE: "Save",
  CLEAR: "Clear",
  EDIT: "Edit",
  BACK: "Back",
  Attachment: "Attachment",
  ADJ_REVIEW: "Review",
  ADJ_SUBMIT: "Submit",
  ADJ_REJECT: "Reject",
  ADJ_VERIFY: "Verify",
  ADJ_FIN_REVIEW: "Final Review",
  ADJ_ADJUST: "Adjust",
  ADJ_TOADJUSTIP1: "Ready For Final Review",
  ADJ_REWORK: "Re-Work",
  EXPORT: "Export",
  ADJ_CANCEL: "Cancel",
};

export const ORDER_STATUS_UPDATE_OPERATION = {
  ALLOCATED: "ALLOCATED",
  TRANSFERRED: "TRANSFERRED",
  RECEIVED: "RECEIVED",
  COMPLETED: "COMPLETED",
  RETURN: "RETURNED",
  ADJ_REVIEWING: "REVIEWING",
  ADJ_SUBMITTED: "SUBMITTED",
  ADJ_VERIFIED: "VERIFIED",
  ADJ_ADJUSTIP1: "ADJUSTIP1",
  ADJ_ADJUSTIP2: "ADJUSTIP2",
  ADJ_COMPLETED: "COMPLETED",
  REJECT_TO_DRAFT: "DRAFT",
  ADJ_DRAFT: "DRAFT",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
};

export const ORDER_CONFIRM_MSG = {
  ADJ_COMPLETED: "Confirm to proceed stock balance adjustment",
  COLORADJ_NETAMT: "Net Amount not match, Confirm to proceed color adjustment",
  COLORADJ_CONFIRM: "Confirm to proceed color adjustment",
  ADJ_TAKE_COMPLETED:
    "Confirm to Adjust? This also mark stock take ${1} as Complete.",
  ATTACHMENT_ADJ_SE_ERROR:"Missing attachment if reason is System Error",
};

export const SEARCH_PERMISSION_CODE = {
  1: FUNCTION_CODE.SO_TYPE1_SEARCH,
  2: FUNCTION_CODE.SO_TYPE2_SEARCH,
  3: FUNCTION_CODE.SO_TYPE3_SEARCH,
  4: FUNCTION_CODE.SO_TYPE4_SEARCH,
  5: FUNCTION_CODE.SO_TYPE5_SEARCH,
  14: FUNCTION_CODE.SO_TYPE14_SEARCH,
  15: FUNCTION_CODE.SO_TYPE15_SEARCH,
  16: FUNCTION_CODE.SO_TYPE16_SEARCH,
  23: FUNCTION_CODE.SO_TYPE23_COL_SEARCH,
  24: FUNCTION_CODE.SO_TYPE24_SEARCH,
  25: FUNCTION_CODE.SO_TYPE25_SEARCH,
  20: FUNCTION_CODE.SO_TYPE20_SEARCH,
  21: FUNCTION_CODE.SO_TYPE21_SEARCH,
  27: FUNCTION_CODE.SO_TYPE27_SEARCH,
};

export const CREATE_PERMISSION_CODE = {
  1: FUNCTION_CODE.SO_TYPE1_CREATE,
  2: FUNCTION_CODE.SO_TYPE2_CREATE,
  3: FUNCTION_CODE.SO_TYPE3_CREATE,
  4: FUNCTION_CODE.SO_TYPE4_CREATE,
  5: FUNCTION_CODE.SO_TYPE5_CREATE,
  14: FUNCTION_CODE.SO_TYPE14_CREATE,
  15: FUNCTION_CODE.SO_TYPE15_CREATE,
  16: FUNCTION_CODE.SO_TYPE16_CREATE,
  23: FUNCTION_CODE.SO_TYPE23_COL_CREATE,
  // 24: FUNCTION_CODE.SO_TYPE24_CREATE,
  25: FUNCTION_CODE.SO_TYPE25_CREATE,
  // 20: FUNCTION_CODE.SO_TYPE20_CREATE,
  // 21: FUNCTION_CODE.SO_TYPE21_CREATE,
  27: FUNCTION_CODE.SO_TYPE27_CREATE,
};
