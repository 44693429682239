import { Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE , NOTI_MSG, NOTI_TITLE } from "src/app/stores/base/BaseStore";
import { MENU } from "src/app/config";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { ReservationInterfaceService } from "src/app/service/stock/reservation-interface.service";
import { DatePipe } from "@angular/common";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import {observable} from "mobx";
import { CommonMethod } from "@/util/CommonMethod";
import { LoadingService } from "@/service/common/loading-service";
import { JsonDisplayPopUpComponent } from "@/components/json-display-pop-up/json-display-pop-up.component";

enum INTERFACESTATUS{
  PENDING=70,
  SUCCESSFUL=71,
  FAILURE=72,
}
enum IMSINTERFACESTATUS{
  PENDING=99,
  SUCCESSFUL=100,
  FAILURE=101,
}
enum BOMINTERFACESTATUS{
  PENDING=176,
  SUCCESSFUL=175,
  FAILURE=174,
}

const TITLE = {
  transDate: "Api Run Time",
  process: "Process",
  reservationInterfaceStatus: "Reservation Interface Status",
  itemCode: "Item Code",
  sourceSystem: "Source System.",
  sourceTxnRefHeaderId: "Source Ref. ID",
  sourceTxnTefDetailId: "Source Tef. Detail ID",
  reservationId: "Reservation No.",
  sourceTxnRefHeaderNo:"source Txn Ref Header No"
}

@Injectable()
export class ReservationInterfaceStore {
  @observable selectedRow: any[] = []
  @observable loadingSwitch = false
  public m: ConfirmationService
  public loadingService = new LoadingService();
  popUpVisable = false
  jsonResult

  breadcrumb = MENU.RESERVATION_INTERFACE
  queryData = {
    ipts: [
      {
        title: TITLE.transDate,
        type: INPUT_TYPE.DATERANGE,
        class: "p-col-12 p-md-4 p-lg-3",
      }, {
        title: TITLE.process,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionValue: 'code',
      }, {
        title: TITLE.reservationInterfaceStatus,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.itemCode,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        appendTo: 'body',
      }, {
        title: TITLE.sourceTxnTefDetailId,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.sourceSystem,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.sourceTxnRefHeaderId,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: TITLE.reservationId,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      },{
        title: TITLE.sourceTxnRefHeaderNo,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }
    ],
    btns: [
      {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      },
      {
        title: 'Export',
        class: "p-mr-1",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.export() }
      },
      {
        title: 'Search',
        class: "",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.searchFromFilter() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'reservation_id', title: 'Reservation No.', width: '160px'},
      {field: 'api_run_time', title: 'Reservation Interface Time', width: '220px', type: 'datetime'},
      {field: 'api_run_status', title: TITLE.reservationInterfaceStatus, width: '230px'},
      {field: 'error_msg', title: 'Error Message', width: '250px', tooltip:true, type:'link'},
      {field: 'item_code', title: 'Item Code', width: '120px'},
      {field: 'source_system', title: 'Source System', width: '160px'},
      {field: 'expiry_date', title: 'Expiry Date', width: '150px'},
      {field: 'source_txn_ref_header_id', title: TITLE.sourceTxnRefHeaderId, width: '190px'},
      {field: 'source_txn_ref_detail_id', title: TITLE.sourceTxnTefDetailId, width: '280px'},
      {field: 'is_process', title: 'CSTK Process(Y/N)', width: '180px'},
      {field: 'retry_count', title: 'Reservation Interface Retry count', width: '260px'},
      {field: 'qty_requested', title: 'Requested Qty', width: '160px'},
      {field: 'qty_pending', title: 'Pending Qty', width: '160px'},
      {field: 'qty_allocated', title: 'Allocated Qty', width: '160px'},
      {field: 'qty_cancelled', title: 'Cancel Qty', width: '160px'},
      {field: 'create_date', title: 'Create Date', width: '160px', type: 'datetime'},
      {field: 'reservation_interface_id', title: 'Reservation Interface ID', width: '230px'},
      {field: 'update_date', title: 'Update Date', width: '160px', type: 'datetime'},
      {field: 'description', title: 'Description', width: '160px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
      {header: 'CheckBox', field:'checkBox', title: '', type: 'CheckBox', width: '64px', sortable: false},
    ],
    frozenWidth: '120px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    initSortField:  'update_date',
    initSortOrder:  -1,
    sortField: { field: 'update_date', order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
  }
  //showEdit: false
  table = null
  paginator = null
  processOptions = [
    {name: 'All', code: 'All'},
    {name: 'Y', code: 'Y'},
    {name: 'N', code: 'N'},
  ]
  defaultStatus = [
    INTERFACESTATUS.PENDING,
    INTERFACESTATUS.FAILURE,
    IMSINTERFACESTATUS.PENDING,
    IMSINTERFACESTATUS.FAILURE,
    BOMINTERFACESTATUS.PENDING,
    BOMINTERFACESTATUS.FAILURE,
  ] // default of interface status
  apiRunStatusOptions = []
  _interfaceStatusMapping = {}
  dateIpt = null
  permission = null
  SysDefLookupDescResult
  sku=[]
  sourceSystem=[]
  lastSearchParams
  clonedLines = {}; // use for reset line input value when cancel edit
  editShow: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private datePipe: DatePipe,
    private conf: ConfirmationService,
    private serive: ReservationInterfaceService) {
    this.getResolverData();
    this.setSelectedColumns()
    this.initQueryData()
  }
  get apiRunTime() {
    return this.dateIpt.value;
  }

  get process() {
    let processIpt = this.getIptByQueryData(TITLE.process)
    return processIpt.value;
  }

  get apiRunStatus() {
    let statusIpt = this.getIptByQueryData(TITLE.reservationInterfaceStatus)
    return statusIpt.value;
  }

  get itemCode() {
    let ipt = this.getIptByQueryData(TITLE.itemCode)
    return ipt.value;
  }

  get sourceSystemName() {
    let ipt = this.getIptByQueryData(TITLE.sourceSystem)
    return ipt.value;
  }

  get sourceTxnTefDetailId() {
    let ipt = this.getIptByQueryData(TITLE.sourceTxnTefDetailId)
    return ipt.value;
  }

  get sourceTxnRefHeaderId() {
    let ipt = this.getIptByQueryData(TITLE.sourceTxnRefHeaderId)
    return ipt.value;
  }

  get reservationId() {
    let ipt = this.getIptByQueryData(TITLE.reservationId)
    return ipt.value;
  }

  get sourceTxnRefHeaderNo() {
    let ipt = this.getIptByQueryData(TITLE.sourceTxnRefHeaderNo)
    return ipt.value;
  }

  get loading(){return this.loadingService.loading}

  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
  }

  getResolverData() {
    try{
    // this.route.data.pipe(pluck('query')).subscribe(res => {
      let status = LocalStorageHelper.getObject('COM_INTERFACE_STATUS')
      let imsStatus = LocalStorageHelper.getObject('IMS_INTERFACE_STATUS')
      let bomStatus = LocalStorageHelper.getObject('BOM_INTERFACE_STATUS')
      status.map(item => {
        item.description =  item.description.toLocaleUpperCase()+ " - COM"
      })
      imsStatus.map(item => {
        item.description =  (item.description.toLocaleUpperCase())+ " - IMS"
      })
      bomStatus.map(item => {
        item.description =  (item.description.toLocaleUpperCase())+ " - BOM"
      })
      this.apiRunStatusOptions = [...status, ...imsStatus, ...bomStatus].map(item => {
        let {description:name, id: code} = item
        return {name, code}
      })
      this._interfaceStatusMapping = {}
      this.apiRunStatusOptions.forEach(option=>{
        this._interfaceStatusMapping[option.code]=option
      })
    // })

    // this.route.data.pipe(pluck('query')).subscribe(res => {
      let LookUpDesc = LocalStorageHelper.getObject('SYS_DEF_LOOKUP_DESC')
      this.SysDefLookupDescResult=LookUpDesc.map(item => {
        item.value =  item.value
        item.name = item.name
        let { value:value, name: name} = item
        return {value, name}
      })
    // })

    this.sku = LocalStorageHelper.getObject('SKU').map(sku=>{return {name:`${sku.name}~${sku.flag}`,code:sku.code, itemCode: sku.name}});

    this.sourceSystem = LocalStorageHelper.getObject('SYS_DEF_LOOKUP_DESC').map(type=>{return {name:type.code,code:type.code}});
    }catch(e){console.error(e)}
  }
  getIptByQueryData(name) {
    return this.queryData.ipts.find( ipt =>  ipt.title === name)
  }
  setSelectedColumns() {
    this.tableData.selectedColumns = this.tableData.columns
  }
  initSort() {
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder
  }
  initQueryData() {
    this.dateIpt = this.getIptByQueryData(TITLE.transDate)

    let processIpt = this.getIptByQueryData(TITLE.process)
    processIpt.options = this.processOptions
    processIpt.value = 'All'

    let statusIpt = this.getIptByQueryData(TITLE.reservationInterfaceStatus)
    statusIpt.options = this.apiRunStatusOptions

    let itemIpt = this.getIptByQueryData(TITLE.itemCode)
    itemIpt.options = this.sku
    let sourceSystemIpt = this.getIptByQueryData(TITLE.sourceSystem)
    sourceSystemIpt.options = this.sourceSystem

    statusIpt.value = this.apiRunStatusOptions.filter(item => this.defaultStatus.includes(item.code))
    //this.search()
  }

  searchFromFilter() {
    // this.table.reset()
    // this.initSort()
    this.paginator ? this.paginator.changePage(0) : this.search()
  }

  clear() {
    for (const ipt of this.queryData.ipts) {
      if(ipt.title === TITLE.process) {
        ipt.value = 'All'
      } else if (ipt.title === TITLE.reservationInterfaceStatus) {
        ipt.value = this.apiRunStatusOptions.filter(item => this.defaultStatus.includes(item.code))
      } else {
        ipt.value = null
      }
    }
  }

  sortSearch(_field) {
    let order = this.tableData.sortField.order
    let field = this.tableData.sortField.field
    if(order == -1 || field != _field || order == null){
      order = 1
    } else if(order == 1) {
      order = -1
    }
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = order;
    this.search()
  }



  search() {
    //console.log(this.SysDefLookupDescResult)
    // 如果用户只选择了一个日期（from date），则将to date也定为同一天
    let formatedDate=[]
    if(this.apiRunTime && !this.apiRunTime[1]) {
      this.apiRunTime[1] = this.apiRunTime[0]
    }
    if(this.apiRunTime!=null&&this.apiRunTime.length){
    formatedDate.push(this.formatDate(this.apiRunTime[0])+ ' 00:00:00')
    formatedDate.push(this.formatDate(this.apiRunTime[1])+ ' 23:59:59')
    }

    // let itemCodes=[]
    // if(this.itemCode!=null&&this.itemCode.length){
    //   itemCodes.push(this.itemCode.map(item=>item.itemCode))
    // }
    let data = {
      ...(this.apiRunTime && {apiRunTime: formatedDate}),
      ...(this.process && {isProcess: this.process}),
      ...(this.apiRunStatus.length && {apiRunStatus: this.apiRunStatus.map(item=>item.code)}),
      ...(this.sourceTxnRefHeaderId && this.sourceTxnRefHeaderId.length && {sourceTxnRefHeaderId: this.sourceTxnRefHeaderId}),
      ...(this.sourceTxnTefDetailId && this.sourceTxnTefDetailId.length && {sourceTxnTefDetailId: this.sourceTxnTefDetailId}),
      ...(this.reservationId && this.reservationId.length && {reservationId: this.reservationId}),
      ...(this.sourceSystemName && this.sourceSystemName.length && {sourceSystem: this.sourceSystemName}),
      ...(this.itemCode && this.itemCode.length && {itemCode: this.itemCode.map(item=>item.itemCode)}),
      ...(this.sourceTxnRefHeaderNo && this.sourceTxnRefHeaderNo.length && {sourceTxnRefHeaderNo: this.sourceTxnRefHeaderNo}),
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
    }
    if(data.isProcess === 'All') {
      delete data.isProcess
    }
    this.setLoading(true);
    this.serive.search(data).subscribe(res => {
      this.setLoading(false);
      if (res.code === '000') {
        this.lastSearchParams = data;
        this.clonedLines = {};
        res.data.forEach((item, idx) => {
          //item.transaction_date = item.transaction_date.substring(0, 4) + '/' + item.transaction_date.substring(4, 6) + '/'+item.transaction_date.substring(6)
          //retry_count cannot hardcode, should change it later
          if(item.api_run_status==INTERFACESTATUS.FAILURE||item.api_run_status==IMSINTERFACESTATUS.FAILURE || item.api_run_status == BOMINTERFACESTATUS.FAILURE){
            item.isEdit = true
            item.isInput=true
          }else{
            item.isEdit = false
            item.isInput = false
          }
          if((item.api_run_status==INTERFACESTATUS.FAILURE||item.api_run_status==IMSINTERFACESTATUS.FAILURE || item.api_run_status == BOMINTERFACESTATUS.FAILURE) /* ||
            (item.is_process === 'N' && item.is_interface === 'N') ||
            (item.is_process === 'Y' && item.is_interface === 'Y' && !item.lis_txn_id) */) {
            item.isSubmit=true
          }else{
            item.isSubmit=false
          }

          if(item.api_run_status==INTERFACESTATUS.SUCCESSFUL||item.api_run_status==INTERFACESTATUS.PENDING||
            item.api_run_status==IMSINTERFACESTATUS.SUCCESSFUL||item.api_run_status==IMSINTERFACESTATUS.PENDING){
            item.isInput=false
          }else{
            item.isInput=true
          }

          // item.id = item.id || idx
          if(item.api_run_status){
            item.api_run_status = this.apiRunStatusOptions.find(el => el.code === item.api_run_status).name
          }
        });
        this.tableData.totalRecords = res.msg
        this.tableData.data = res.data
        this.selectedRow=null
      } else {
        this.tableData.data = []
        this.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    },err=>{
      this.setLoading(false);
    })
  }

    SuccessCounter = 0
    //self call based on checkbox ticked index
    reSubmit (list, index){
      let data = list[index]
      const call = {
        retry: {
          name: 'retry',
          desc: 're submit'
        }
      }

      let selType = call.retry
      if(selType) {
        let resInterfaceIdForResubmit = []
        resInterfaceIdForResubmit.push(data.reservation_interface_id)
        this.setLoading(true);
        this.retry(resInterfaceIdForResubmit)
        //this.serive[selType.name](resInterfaceIdForResubmit)
        //console.log(list[index], index)//for test
        //.of({code:'000',msg:'',state:'success',data:[]})//fot test
        .subscribe(res => {
          this.SuccessCounter++
          this.setLoading(false);
          if (res.code === '000') {
            if(list[index+1]){
                this.reSubmit(list,index+1)//self call based on checkbox ticked index
                this.showMessage('info','Reservation Interface Id: '+list[index+1].reservation_interface_id +'  Re-submited',' successful')
            }else{
              this.selectedRow = []
              this.showMessage('info', 'submit', selType.desc + ' successful')
              //this.showMessage('info',this.SuccessCounter +'  Record inputed,call api completed',' successful')
              this.searchFromFilter()
            }
          } else {
            this.selectedRow = []
            this.searchFromFilter()
            this.showMessage('info', 'Submitd', this.SuccessCounter + ' successful')
            this.showMessage('warn', 'Submit', selType.desc + ' failed')
          }
        })
      }
  }

  save2() {
    this.SuccessCounter=0
    let filtedSelectedRow = this.selectedRow?.filter(item=>item.isSubmit)
    let len = filtedSelectedRow?.length
    if (len < 1 || len==null) {
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    }else {
      this.conf.confirm({
        message: `Do you want to resubmit these ${len} record?</br> (Please don't close this page, it might take serveral minutes to complete.)`,
        header: 'Resubmit Confirmation',
         accept: () => {
           this.reSubmit(filtedSelectedRow, 0)//send data to resubmit
        }
      });
    }
  }



  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search()
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd')
  }

  sortFunction(e){
    if(
      this.tableData.sortField.field == e.field &&
      this.tableData.sortField.order == e.order
    ) {
      return
    }else{
      this.tableData.sortField.field = e.field
      this.tableData.sortField.order = e.order
      this.search()
    }
  }

  export(){
    let data = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT,
    }
    this.serive.search(data).subscribe(res=>{
      let data = res.data.map(data=>{
        return {
          ...data,
          apiRunStatus: this._interfaceStatusMapping[data.api_run_status].name
        }
      })
      let col = this.tableData.selectedColumns
      CommonMethod.downloadXlsx(data,col,`Reservation_Interface_${( this.datePipe.transform(new Date(), 'yyyyMMddHHmmss'))}`, this.datePipe)
    })
  }

  resetQty(data, idx){
    if(this.clonedLines[data.id]){
      this.tableData.columns.filter(e=>e.type=='input').forEach(col=>{
        data[col.field] = this.clonedLines[data.id][col.field]
      })
    }
  }

  setLoading(e){
    this.loadingService.setLoadingBoolean(e);
  }

  retry(e){
    return this.serive.retry(e)
  }
  jsonShowDetail(json){
    this.popUpVisable=true
    this.jsonResult=json
  }

  popupHide(e){this.popUpVisable = e}
}
