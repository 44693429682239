/**
 * replenishment pre-allocate popup for nop demand
 */

import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-allocate-nop',
  templateUrl: './pre-allocate-nop.component.html',
  styleUrls: ['./pre-allocate-nop.component.css']
})
export class PreAllocateNopComponent implements OnInit {
  @HostBinding("style.--popup-body-height") popsupBodyHeight; // use for popup left panel height
  @Input() store

  saved = false;
  removed = false;
  
  constructor() { }

  ngOnInit(): void {
  }
  popupBodyResized(e){
    // get popup body height for left panel scroll height
    // use popup main part to extend body height, left part use this event and css to set height with css variable
    this.popsupBodyHeight = e.height;
  }

  // trigger when dialog hide
  // dialog set to hide when save / remove complete
  // trigger remove if save / remove is not called
  onHide(){
    if(!this.removed && !this.saved) this.remove();
    this.resetStatus();
  }

  save(){
    this.store.saveAllPreAllocate();
    this.saved = true;
  }

  remove(){
    this.store.removePreAllocate();
    this.removed = true;
  }

  hide(){
    this.store.setModalVisible(false);
  }

  resetStatus(){
    this.saved = false;
    this.removed = false;
  }
}
