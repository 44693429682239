import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { makeAutoObservable } from 'mobx'
import { MessageService, ConfirmationService, SortEvent } from 'primeng/api'
import { from, of, forkJoin, throwError, Observable } from 'rxjs'
import { map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { StockCommonService } from 'src/app/service/stock/stock-common.service'
import { StockTakeService } from 'src/app/service/stock/stock-take.service'
import { CommonMethod, EXPORT_XLSX_OPTIONS, TABLE_BORDER_OOPTIONS } from 'src/app/util/CommonMethod'
import { HttpHelper } from 'src/app/util/HttpHelper'
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper'
import { INPUT_TYPE, URLDICT, CONFIG } from '../../base/BaseStore'
// import * as XLSX from 'xlsx';
import { FileUpload } from 'primeng/fileupload'
import { ADJUSTMENT_STATUS, FRONTLINE_STOCK_TAKE_CODE, STATUS_TITLE, SURPRISE_STOCK_TAKE_CODE, TAKEBUID_ADJSEARCH_PERMISSION, TAKEBUID_ADJTYPEID, TAKE_ADJUSTMENT_TXN_TYPE, TAKE_STATUS_ID, TITLE } from './TakeShare'
import { PermissionService } from '@/service/common/permission-service'
import { FUNCTION_CODE } from '@/service/common/permission.type'
import { DatePipe } from '@angular/common'

const BTN_TITLE = {
  EXPORT: "Export Variance Report",
  BACK: "Back",
  // SUBMIT: "Submit",
  SUBMIT: "Complete",
  // CONFIRM: "Confirm",
  MARK_AS_FIRST_COUNT: "Mark As 1st Count Complete",
  MARK_AS_SECOND_COUNT: "Mark As 2nd Count Complete",
  PENDING_FOR_MARK_2 : "Pending for mark as 2nd count",
  CREATE_ADJUSTMENT: "Create Stock Adjustment",
  VIEW_ADJUSTMENT: "View Stock Adjustment",
  UPLOAD: "Attachment",
  FIRST_COUNT_MARK_TAKE_COMPLETE: "Complete"
}

const FOUND_SERIAL_VARIANCE_MSG = 'Serial Number Not Match'

// const STATUS_TITLE = {
//   DRAFT: "Draft",
//   PRE_STOCK_TAKE: "Pending For Stock Take",
//   STOCK_TAKE_IN_PROGRESS_1: "Stock Take In Progress (1st count)",
//   STOCK_TAKE_IN_PROGRESS_2: "Stock Take In Progress (2nd count)",
//   STOCK_TAKE_IN_PROGRESS_1_INPUT: "Stock Take In Progress (1st count input complete)",
//   STOCK_TAKE_IN_PROGRESS_2_INPUT: "Stock Take In Progress (2nd count input complete)",
//   STOCK_TAKE_IN_PROGRESS_1_COMPLETE: "Stock Take In Progress (1st count complete)",
//   STOCK_TAKE_IN_PROGRESS_2_COMPLETE: "Stock Take In Progress (2nd count complete)",
//   STOCK_TAKE_IN_PROGRESS: "Stock Take In Progress",
//   REVIEW_IN_PROGRESS: "Review In Progress",
//   COMPLETED: "Completed",
//   PARTIAL_COMPLETED: "Adjustment in Progress",
// }

@Injectable()
export class TakeCheckStore {
  queryData: any = {
    ipts: [
      // {
      //   title: 'Stock Take Ref. No.',
      //   type: INPUT_TYPE.INPUT,
      //   class: 'p-col-12 p-md-4 p-lg-3',
      //   value: null,
      //   options: [],
      //   disabled: true,
      // },

      {
        title: TITLE.STOCK_TAKE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-6",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.CHANNEL,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: false,
        disabled: true
      },
      {
        title: TITLE.STATUS,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.STOCK_TAKE_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3 p-xlg-3",
        value: null,
        options: [],
        disabled: true
      },
      {
        title: TITLE.STOCK_ADJUSTMENT_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.COUNTBY,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.TARGETDATE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.TAKEBU,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true,
        optionLabel: "label",
        optionValue: "value"
      },
      {
        title: TITLE.REMARKS,
        value: null,
        disabled: true,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-12 p-lg-6 textarea-min-width-100",
      },
    ],
    btns: [
      {
        title: BTN_TITLE.EXPORT,
        alias: 'EXPORT',
        class: 'p-order-1 p-mr-1',
        show: false,
        // unchanged: true,
        handler: { click: () => this.export() },
      },
      {
        title: BTN_TITLE.UPLOAD,
        alias: 'UPLOAD',
        class: 'p-order-1 p-mr-1',
        show: true,
        unchanged: true,
        handler: { click: () => this.showUploadModal() },
      },
      {
        title: BTN_TITLE.CREATE_ADJUSTMENT,
        alias: 'CREATE_ADJUSTMENT',
        class: 'p-order-2 p-mr-1',
        show: false,
        handler: {
          click: () => {
            this.createStockAdjustmentAndRedirectToTransferOrder()
          },
        },
      },
      {
        title: BTN_TITLE.VIEW_ADJUSTMENT,
        alias: 'VIEW_ADJUSTMENT',
        class: 'p-order-2 p-mr-1',
        show: false,
        handler: {
          click: () => {
            this.viewStockAdjustment()
          },
        },
      },
      {
        title: BTN_TITLE.BACK,
        alias: 'BACK',
        class: 'p-button-outlined p-ml-auto p-order-0 p-mr-1',
        show: true,
        unchanged: true,
        handler: { click: () => this.back() },
      },
      {
        title: BTN_TITLE.MARK_AS_FIRST_COUNT,
        alias: 'MARK_AS_FIRST_COUNT',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: { click: () => this.markAsFirstCount() }
      },
      {
        title: BTN_TITLE.FIRST_COUNT_MARK_TAKE_COMPLETE,
        alias: 'FIRST_COUNT_MARK_TAKE_COMPLETE',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: {
          click: () => this.markAsFirstCount() // backend will handle what status should change into
        }
      },
      {
        title: BTN_TITLE.PENDING_FOR_MARK_2,
        alias: 'PENDING_FOR_MARK_2',
        class: 'p-order-3 p-mr-1',
        show: false,
        handler: { click: () => this.pendingForMark2nd() },
      },
      {
        title: BTN_TITLE.MARK_AS_SECOND_COUNT,
        alias: 'MARK_AS_SECOND_COUNT',
        class: 'p-order-3 p-mr-1',
        show: false,
        handler: { click: () => this.markAsSecondCount() },
      },
      {
        title: BTN_TITLE.SUBMIT,
        alias: 'SUBMIT',
        class: 'p-order-3 p-mr-1',
        show: false,
        handler: { click: () => this.isSubmit(2) },
      },
      /* {
        title: BTN_TITLE.CONFIRM,
        alias: 'CONFIRM',
        class: 'p-order-4 p-mr-1',
        show: false,
        handler: { click: () => this.isSubmit(3) },
      }, */
    ],
    btnsclass: 'p-d-flex p-col-12',
  }
  tableData = {
    btns: [],
    data: [],
    showCheckboxSwitch: false,
    loadingSwitch: false,
    head: [
      { key: 'normal_1', type: 'text', keyPath: ['countData', ['1'], 'normal'], count: '1', title: 'Stock Take (FG)', parent: { title: '1st Count', colspan: 4 },  width: '12rem', height: '60px' },
      { key: 'nv_1', keyPath: ['countData', ['1'], 'nv'], count: '1', title: 'FG Variance', parent: true, width: '12rem', height: '60px', cellClass: 'variance-highlight',
        subfix: {iconClass:"pi pi-exclamation-triangle serial-variance", keyPath: ['countData', ['1'], 'normalSerialHaveVarianceDisplay']}
      },
      { key: 'faulty_1', type: 'text', keyPath: ['countData', ['1'], 'faulty'], count: '1', title: 'Stock Take (Faulty)', parent: true, width: '12rem', height: '60px'},
      { key: 'fv_1', keyPath: ['countData', ['1'], 'fv'], count: '1', title: 'Faulty Variance', parent: true, width: '12rem', height: '60px', cellClass: 'variance-highlight',
        subfix: {iconClass:"pi pi-exclamation-triangle serial-variance", keyPath: ['countData', ['1'], 'faultySerialHaveVarianceDisplay']}
      },
      { key: 'normal_2', type: 'text', keyPath: ['countData', ['2'], 'normal'], count: '2', title: 'Stock Take (FG)', parent: { title: '2nd Count', colspan: 4 },  width: '12rem', height: '60px'},
      { key: 'nv_2', keyPath: ['countData', ['2'], 'nv'], count: '2', title: 'FG Variance', parent: true, width: '12rem', height: '60px', cellClass: 'variance-highlight',
        subfix: {iconClass:"pi pi-exclamation-triangle serial-variance", keyPath: ['countData', ['2'], 'normalSerialHaveVarianceDisplay']}
      },
      { key: 'faulty_2', type: 'text', keyPath: ['countData', ['2'], 'faulty'], count: '2', title: 'Stock Take (Faulty)', parent: true, width: '12rem', height: '60px' },
      { key: 'fv_2', keyPath: ['countData', ['2'], 'fv'], count: '2', title: 'Faulty Variance', parent: true, width: '12rem', height: '60px', cellClass: 'variance-highlight',
        subfix: {iconClass:"pi pi-exclamation-triangle serial-variance", keyPath: ['countData', ['2'], 'faultySerialHaveVarianceDisplay']}
      },
    ],
    frozenColumns: [
      { key: 'item', title: 'Item', width: '5rem', height: '120px' },
      { key: 'itemDesc', title: 'Item Desc', width: '8rem', height: '120px', textOverflow: 'ellipsis' },
      { key: 'actionBy', title: 'Action By', width: '8rem', height: '120px', textOverflow: 'ellipsis' },
      { key: 'normalBalance', title: 'Stock Balance (FG)', width: '8rem', height: '120px', wrap: true },
      { key: 'faultyBalance', title: 'Stock Balance (Faulty)', width: '8rem', height: '120px', wrap: true },
    ],
    frozenWidth: '39rem',
    sortField: 'item',
    sortOrder: 1,
  }
  serialModalConfig: any = {
    itemCode: {
      title: 'Item Code',
      type: INPUT_TYPE.INPUT,
      value: null,
    },
    itemDesc: {
      title: 'Item Desc',
      type: INPUT_TYPE.INPUT,
      value: null,
    },
    actionQty: {
      title: 'Action Qty',
      type: INPUT_TYPE.INPUT,
      value: null,
    },

    qty: 0,
    serialList: [],
    existList: [],
    deleteList: [],
    header: 'Input Serial Number',
    newSerialValue: null,
    range: {},
    index: null,
    currentLIne: null,
    type: null,
  }
  stockTakeLineId = null
  isScannerEnable = false
  deviceSelected
  deviceCurrent
  hasPermission
  availableDevices
  hasDevices

  detailData: any = {}
  selectRequest = null
  searchAll = true
  requestDialog = false
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 }
  permission: string[]
  info = {
    title: 'Stock Take Variance',
    inputSerial: 'Please enter the serial number!',
    notChange: 'The data did not change!',
    saveSuccess: 'Variance successfully!',
    saveFail: 'Failed to variance!',
    download: 'Start downloading Excel files',
    emptyFile: 'File content cannot be empty!',
    submitSuccess: 'Submit Successfully!',
    submitFail: 'Submit Failed!',
    confirmSuccess: 'Confirm Successfully!',
    confirmSuccessPartialComplete: 'Stock Take Adjustment in Progress, Please Complete Adjustment',
    confirmFail: 'Confirm Failed!',
    addItems: 'Please add the item content!',
    labelRequired: 'Label is required!',
    exisitVarance: 'No related adjustment record exists. Do you want to submit stock take?',
    firstCountFoundVariance: `Found Variance, Please second count`,
    // confirmUpload: 'Confirm to upload'
  }
  varianceData
  orderType
  shopAndWarehouse
  orderStatus
  reasons
  compareSerialModalConfig = {
    ipts: [
      {
        title: 'Channel',
        alias: 'channel',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Condition',
        alias: 'stockCondition',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Onhand Serial Qty',
        alias: 'onhandSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Code',
        alias: 'itemCode',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Desc',
        alias: 'itemDesc',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Take Serial Qty',
        alias: 'stockTakeSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
    ],
    serialCloneList: [],
    serialList: [],
    columns: [
      { key: 'serial', title: 'Serial Number', width: 'auto' },
      { key: 'inStockTakeSerial', title: 'Found in stock take', width: 'auto' },
      { key: 'inQtyOnHandSerial', title: 'Found in system record', width: 'auto', },
    ],
    header: 'Compare Serial Number',
    checked: false,
    loading: false,
    visible: false,
  }
  isView: boolean = true;
  stockTakeCount
  headId
  haveVariance: boolean = false;
  popupPUploadEl: FileUpload;

  adjustmentStatus // adjustment order status, ADJUSTMENT_STATUS.COMPLETE = completed
  takeStatus
  _stockTakeTypeValue: any
  currStatusId: any
  
  countByList
  _countByList={}
  takeTypeDescription
  targetDateDescription

  get isSurprise(){
    return SURPRISE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)
  }
  get isFrontline(){
    return FRONTLINE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)
  }
  
  get isUploadable(){
    let permission = false
    if(this.isSurprise) {
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_READY_2ND)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_PENDING_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE_AJ))
      )
    }else if(this.isFrontline) {
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY_2ND)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING_MARK_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE_AJ))
      )
    }else{
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY_2ND)) || 
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV) && 
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING_MARK_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE_AJ))
      )
    }
    return permission && !([TAKE_STATUS_ID.COMPLETED].indexOf(this.currStatusId)>-1)
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private conf: ConfirmationService,
    private stockCommonService: StockCommonService,
    private takeService: StockTakeService,
    public permissionService: PermissionService,
    public datepipe: DatePipe,
  ) {
    makeAutoObservable(this)
    this.init()
    this.getPermission(route)
    // const isView = this.route.snapshot.queryParams.view || false
    // this.initQueryData(isView)
    this.loadDetailDataById()
    this.resetUploadModalConfig()
  }
  //computed
  get showU() {
    // return this.permission?.includes('Check Variance (Stock Take)')
    return true
  }

  getQueryIptByLabel(name) {
    return this.queryData.ipts.find((ipt) => ipt.title === name)
  }
  getButtonByLabel(name) {
    return this.queryData.btns.find((btn) => btn.title === name)
  }
  export() {
    this.exportAsExcel()
    // this.tableData.loadingSwitch = true
    // const taleData = this.detailData;
    // // const taleData = LocalStorageHelper.getObject('takeDetail');
    // const data = {
    //   id: taleData.id,
    //   channelCode: taleData.channelCode,
    //   statusCode: taleData.statusCode,
    //   stockTakeNumber: taleData.stockTakeNumber,
    // }
    // HttpHelper.postForExcel(URLDICT.STOCK_TAKE_DOWNLOAD, data)
    //   .then((res) => {
    //     const name = res.headers
    //       .get('content-disposition')
    //       .split('filename=')[1]
    //       .split('.')[0]
    //     res.blob().then((res1) => {
    //       this.showMessage('info', this.info.title, this.info.download)
    //       CommonMethod.downloadFile(res1, name, 'xls')
    //     })
    //   })
    //   .catch((e) => console.log('error', e))
    //   .finally(() => {
    //     console.log('print completed!')
    //     this.tableData.loadingSwitch = false
    //   })
  }
  async exportAsExcel() {
    let data = [...this.tableData.data];
    let col: any[] = [...this.tableData.frozenColumns, ...this.tableData.head, ];
    let actionBySheets = {};
    let actionBySheetNames = [];
    let filteredTableData = {}

    data.forEach(e=>{
      col.forEach(column=>{
        if(column.keyPath){
          e[column.key] = CommonMethod.keyPathParseValue(e, column.keyPath)
        }
      })
    })

    data.forEach(data=>{
      if(data.actionBy&&data.actionBy.length>0){
        if(actionBySheetNames.indexOf(data.actionBy)==-1){
          actionBySheetNames.push(data.actionBy)
          filteredTableData[data.actionBy] = [];
        };
        filteredTableData[data.actionBy].push(data)
      }
    })

    // worksheet with all data without group by action by
    // let allDataWs = this.getWorkSheet(data, col)
    let dataList = [
      data
    ]
    let colList = [
      col
    ]
    let sheetNameList = ['ALL', ...actionBySheetNames]
    let options: EXPORT_XLSX_OPTIONS[] = [{haveParent:true, fieldKey: 'key', tableBorder: TABLE_BORDER_OOPTIONS.leftright, tableHeaderBorderBottom: true, 
      headerWrapText: true, colWidth: [22,30,10,10,10,10,10,10,10,10,10,10,10],
      autoPrintTitle: true, pageSetup: CONFIG.PRINT_CONFIG_FIT_COLUMN_LANDSCAPE
    }]
    
    // worksheet group by action by
    actionBySheetNames.forEach(name=>{
      // actionBySheets[name] = this.getWorkSheet(filteredTableData[name], col)
      dataList.push(filteredTableData[name])
      colList.push(col)
      options.push({haveParent:true, fieldKey: 'key', tableBorder: TABLE_BORDER_OOPTIONS.leftright, tableHeaderBorderBottom: true, 
        headerWrapText: true, colWidth: [22,30,10,10,10,10,10,10,10,10,10,10,10],
        autoPrintTitle: true, pageSetup: CONFIG.PRINT_CONFIG_FIT_COLUMN_LANDSCAPE
      })
    })

    // await getWorkSheetSerialDiff, 
    // this process will stop here unless getWorkSheetSerialDiff returned promise resolver called
    // resolver call after api call complete, subscribe finish
    let serialDiff = await this.getWorkSheetSerialDiffDataColumn(data);
    if(serialDiff){
      dataList.push(serialDiff.data)
      colList.push(serialDiff.column)
      options.push({haveParent:false, fieldKey: 'key'})
      sheetNameList.push('Serials Diff')
    }
    let header = [
      { key: TITLE.STOCK_TAKE_TYPE, value: this.takeTypeDescription },
      { key: TITLE.TARGETDATE, value: this.targetDateDescription },
      { key: TITLE.CHANNEL, value: this.detailData.channelCode },
      { key: TITLE.STOCK_TAKE_REF_NO, value: this.detailData.stockTakeNumber },
      { key: TITLE.SNAPSHOT_DATE, value: this.datepipe.transform(this.detailData.snapshotDate, "yyyy/MM/dd HH:mm:ss") },
    ]
    // wip footer
    let footer = [
      { key: TITLE.DEVICE_MANAGEMENT, value: "" },
      { key: TITLE.NAME_IN_FULL, value: this.detailData.createByUserDisplayName || 'N/A' },
      { key: TITLE.STAFF_ID, value: this.detailData.createByUserDomainId || 'N/A' },
      { key: TITLE.EXPORT_DATE, value: this.detailData.create_at?CommonMethod.convertDate_YYYYMMDD(this.detailData.create_at):'N/A' }, 
    ]
    let _options = options.map(e=>{return {
      ...e,
      header: header,
      footer: footer,
      fieldKey: 'key',
      // haveParent: false,
    }})

    // let sheetNames = ['ALL', ...actionBySheetNames]
    // let sheets:any = {
    //   ALL: allDataWs,
    //   ...actionBySheets,
    // }
    // if(serialDiff!=null){sheetNames=[...sheetNames, 'Serials Diff']}
    // if(serialDiff!=null){sheets={...sheets, 'Serials Diff': serialDiff,}}

    // XLSX.writeFile({
    //   SheetNames:sheetNames,
    //   Sheets: sheets
    // }, `Stock_Take_Detail_${this.detailData.stockTakeNumber}_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}.xlsx`);

    CommonMethod.downloadXlsxWithMultiSheetWithOptions(
      dataList, 
      colList,
      sheetNameList,
      `Stock_Take_Detail_${this.detailData.stockTakeNumber}_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`,
      this.datepipe,
      _options
    )
  }

  // getWorkSheet(_data, _col){
  //   let data = [..._data];
  //   let col = [..._col];

  //   let ws_data = [];
  //   let merges: XLSX.Range[] = [];
  //   let header = []
  //   let rows = [...col].map(head=>head['parent']?2:1)
  //   let maxRow = Math.max(...rows)
  //   for(let i=0;i<maxRow;i++){
  //     header.push([])
  //   }
  //   col.forEach((head,idx)=>{
  //     for(let i = 0;i<maxRow;i++){
  //       if(!head.parent){
  //         if(i==0) merges.push({s: {r: 0, c: header[0].length }, e: {r: maxRow - 1, c: header[0].length}})
  //         header[i].push(head.title)
  //       }else if(head.parent){
  //         if(i==0 && head.parent!==true){
  //           merges.push({s: {r: 0, c: header[0].length }, e: {r: 0, c: header[0].length - 1 + head.parent.colspan}})
  //           for(let k = 0; k<head.parent.colspan;k++){
  //             header[i].push(head.parent.title)
  //           }
  //         }
  //         if(i!=0){
  //           header[i].push(head.title)
  //         }
  //       }
  //     }
  //   })

  //   let dataRow = []

  //   data.forEach(row=>{
  //     let _row = []
  //     col.forEach(head => {
  //       if(!head.keyPath){
  //         _row.push(row[head.key])
  //       }else{
  //         _row.push(this.getValueByKeyPath(row, head.keyPath))
  //       }
  //     });
  //     dataRow.push(_row)
  //   })

  //   ws_data = [...header, ...dataRow]
  //   let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //   let objectMaxLength = [];

  //   for (let i = 0; i < ws_data.length; i++) {
  //     let value = <any>Object.values(ws_data[i]);
  //     for (let j = 0; j < value.length; j++) {
  //       if (typeof value[j] == "number") {
  //         // objectMaxLength[j] = 10;
  //         objectMaxLength[j] =
  //           objectMaxLength[j] >= 10
  //             ? objectMaxLength[j]
  //             : 10;
  //       } else {
  //         objectMaxLength[j] =
  //           objectMaxLength[j] >= (value[j]?value[j].length:0)
  //             ? objectMaxLength[j]
  //             : value[j].length;
  //       }
  //     }
  //   }

  //   if(!ws['!merges']) ws['!merges'] = [];
  //   ws['!merges'].push(...merges);
  //   if(!ws['!cols']) ws['!cols'] = [];
  //   ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));

  //   return ws
  // }

  getWorkSheetSerialDiffDataColumn(_data):any{
    let obsList = [];
    let resolver;
    let promise = new Promise(resolve=>{
      resolver = resolve;
    })
    of(null).pipe(
      switchMap((res)=>{        
        let haveSerialControl = this.varianceData.some((variance, idx) => {
          return variance.isSerialControl=='Y'
        })
        if(haveSerialControl){
          this.tableData.loadingSwitch = true
          return this.takeService.compareAllSerial({stockTakeId: this.detailData.id, countType: 2}).pipe(map(res=>{
            this.tableData.loadingSwitch = false
            return res.data
          }))
        }else{
          return of(null)
        }
      }),
    ).subscribe((res:any)=>{
      this.tableData.loadingSwitch = false
      if(!res){
        resolver(null)
        return
      }
      let conditions = LocalStorageHelper.getObject('CONDITIONS')
      let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
      let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY' 
      let conditionCodeMapping = {'FG': fgCode, 'FAULTY': faultyCode}
      let headerColumn = [
        {title: 'Channel',key:'channel'},
        {title: 'Item Code',key:'itemCode'},
        {title: 'Condition',key:'condition'},
        {title: 'Serial Number',key:'serial'},
        {title: 'Found In Stock Take',key:'inStockTakeSerial'},
        {title: 'Found In System Record',key:'inQtyOnHandSerial'},
      ]
      let _data = []
      let lineIdChannelItemConditionMapping = this.varianceData?.map(e=>{
        return {...e, ...e.countData[this.stockTakeCount]}
      })?.reduce((map,obj)=>{
        map[obj.normalLineId] = {itemCode: obj.item, condition: 'FG'}
        map[obj.faultyLineId] = {itemCode: obj.item, condition: 'FAULTY'}
        return map
      },{}) || {}
      _data = [...res].map(e=>{
        return {...e, 
          channel: this.detailData.channelCode, 
          itemCode: lineIdChannelItemConditionMapping[e.stockTakeLineId].itemCode, 
          condition: conditionCodeMapping[lineIdChannelItemConditionMapping[e.stockTakeLineId].condition] 
        }
      })
      if(_data.length>0){
        resolver({column: headerColumn, data: _data})
      }else{
        resolver(null)
      }
    },err=>{
      this.tableData.loadingSwitch = false
      this.showMessage('error', this.info.title, 'Error Encountered, generated Excel file not include Serials Diff')
      resolver(null)
    })
    return promise
  }

  // getWorkSheetSerialDiff(_data){
  //   let obsList = [];
  //   let resolver;
  //   let promise = new Promise(resolve=>{
  //     resolver = resolve;
  //   })
  //   of(null).pipe(
  //     switchMap((res)=>{
  //       // gen line data
  //       this.varianceData.forEach((variance) => {
  //         let _variance = {
  //           ...variance,
  //           ...variance.countData[this.stockTakeCount]
  //         }
  //         let itemId = this.detailData.lines.find( (e) => e.itemCode == _variance.item ).itemId
  //         if(_variance.isSerialControl!='Y'){
  //         }else{
  //           // item is serial control
  //           let _obsList:Observable<any[]>[] = [
  //             this.getCompareSerialForExportXlsx(_variance.normalLineId,
  //               itemId, '1001'),
  //             this.getCompareSerialForExportXlsx(_variance.faultyLineId,
  //               itemId, '1002'),
  //           ]
  //           let obs = forkJoin(_obsList)
  //           .pipe(map((res:any[])=>{
  //             return {[_variance.item]: {'1001':res[0],'1002':res[1]}}
  //           }))
  //           obsList.push(obs)
  //         }
  //       })
  //       if(obsList.length>0){
  //         this.tableData.loadingSwitch = true;
  //         return forkJoin(obsList)
  //           .pipe(map((res:any[])=>{
  //             let _res = {}
  //             res.forEach(data=>{
  //               _res = {
  //                 ..._res,
  //                 ...data,
  //               }
  //             })
  //             return _res
  //           }))
  //       }else{
  //         return of(null)
  //       }
  //     }),
  //   ).subscribe((res:any)=>{
  //     this.tableData.loadingSwitch = false
  //     if(!res){
  //       resolver(null)
  //       return
  //     }
  //     let header = ['Channel', 'Item Code', 'Condition', 'Serial Number', 'Found In Stock Take', 'Found In System Record']
  //     let dataRow = []
  //     let conditions = LocalStorageHelper.getObject('CONDITIONS')
  //     let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
  //     let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY' 
  //     Object.keys(res).forEach(itemCode=>{
  //       let itemSerial = res[itemCode]
  //       itemSerial['1001'].forEach(_serial => {
  //         // _serial: {itemId: 105, inStockTakeSerial: 'Yes', inQtyOnHandSerial: 'No', serial: '705384'}
  //         dataRow.push([this.detailData.channelCode, itemCode, fgCode, _serial.serial, _serial.inStockTakeSerial, _serial.inQtyOnHandSerial ])
  //       });
  //       itemSerial['1002'].forEach(_serial => {
  //         dataRow.push([this.detailData.channelCode, itemCode, faultyCode, _serial.serial, _serial.inStockTakeSerial, _serial.inQtyOnHandSerial ])
  //       });
  //     })
  //     if(dataRow.length>0){
  //       let ws_data = [header, ...dataRow]
  //       let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //       let objectMaxLength = [];
  //       for (let i = 0; i < ws_data.length; i++) {
  //         let value = <any>Object.values(ws_data[i]);
  //         for (let j = 0; j < value.length; j++) {
  //           if (typeof value[j] == "number") {
  //             // objectMaxLength[j] = 10;
  //             objectMaxLength[j] =
  //               objectMaxLength[j] >= 10
  //                 ? objectMaxLength[j]
  //                 : 10;
  //           } else {
  //             objectMaxLength[j] =
  //               objectMaxLength[j] >= (value[j]?value[j].length:0)
  //                 ? objectMaxLength[j]
  //                 : value[j].length;
  //           }
  //         }
  //       }

  //       if(!ws['!cols']) ws['!cols'] = [];
  //       ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));  
  //       resolver(ws)
  //     }else{
  //       resolver(null)
  //     }
  //   },err=>{
  //     this.tableData.loadingSwitch = false
  //     this.showMessage('error', this.info.title, 'Error Encountered, generated Excel file not include Serials Diff')
  //     resolver(null)
  //   })


  //   // let data = [..._data];
  //   // let col = [..._col];

  //   // let ws_data = [];
  //   // let merges: XLSX.Range[] = [];
  //   // let header = []
  //   // let rows = [...col].map(head=>head['parent']?2:1)
  //   // let maxRow = Math.max(...rows)
  //   // for(let i=0;i<maxRow;i++){
  //   //   header.push([])
  //   // }
  //   // col.forEach((head,idx)=>{
  //   //   for(let i = 0;i<maxRow;i++){
  //   //     if(!head.parent){
  //   //       if(i==0) merges.push({s: {r: 0, c: header[0].length }, e: {r: maxRow - 1, c: header[0].length}})
  //   //       header[i].push(head.title)
  //   //     }else if(head.parent){
  //   //       if(i==0 && head.parent!==true){
  //   //         merges.push({s: {r: 0, c: header[0].length }, e: {r: 0, c: header[0].length - 1 + head.parent.colspan}})
  //   //         for(let k = 0; k<head.parent.colspan;k++){
  //   //           header[i].push(head.parent.title)
  //   //         }
  //   //       }
  //   //       if(i!=0){
  //   //         header[i].push(head.title)
  //   //       }
  //   //     }
  //   //   }
  //   // })

  //   // let dataRow = []

  //   // data.forEach(row=>{
  //   //   let _row = []
  //   //   col.forEach(head => {
  //   //     if(!head.keyPath){
  //   //       _row.push(row[head.key])
  //   //     }else{
  //   //       _row.push(this.getValueByKeyPath(row, head.keyPath))
  //   //     }
  //   //   });
  //   //   dataRow.push(_row)
  //   // })

  //   // ws_data = [...header, ...dataRow]
  //   // let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //   // let objectMaxLength = [];

  //   // for (let i = 0; i < ws_data.length; i++) {
  //   //   let value = <any>Object.values(ws_data[i]);
  //   //   for (let j = 0; j < value.length; j++) {
  //   //     if (typeof value[j] == "number") {
  //   //       // objectMaxLength[j] = 10;
  //   //       objectMaxLength[j] =
  //   //         objectMaxLength[j] >= 10
  //   //           ? objectMaxLength[j]
  //   //           : 10;
  //   //     } else {
  //   //       objectMaxLength[j] =
  //   //         objectMaxLength[j] >= (value[j]?value[j].length:0)
  //   //           ? objectMaxLength[j]
  //   //           : value[j].length;
  //   //     }
  //   //   }
  //   // }

  //   // if(!ws['!merges']) ws['!merges'] = [];
  //   // ws['!merges'].push(...merges);
  //   // if(!ws['!cols']) ws['!cols'] = [];
  //   // ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));

  //   // return ws
  //   return promise
  // }

  getValueByKeyPath(data, keyPath) {
    // if(keyPath.length > 1) {
    //   if(!data[keyPath[0]]) return null
    //   return this.getValueByKeyPath(data[keyPath[0]], keyPath.slice(1))
    // } else {
    //   return (data[keyPath[0]] || data[keyPath[0]] === 0) ? data[keyPath[0]] : null
    // }
    return CommonMethod.getValueByKeyPath(data, keyPath);
  }

  setChannelContent(val) {
    const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE')
    let list = []
    repoList.forEach((item) => {
      list = [...list, item, ...item.children]
    })
    const result = list.find((item) => item.label === val)
    return result.id
  }
  redirect(routerRule) {
    const takeData = this.detailData;
    // const takeData = JSON.parse(sessionStorage.getItem('takeDetail'))
    const data = {
      amount_variance: null,
      channel_id: this.setChannelContent(takeData.channelCode) || null,
      complete_time: takeData.complete_time || null,
      createAtNoTime: takeData.createAtNoTime || null,
      createTime: takeData.createTime || null,
      create_at: takeData.create_at || null,
      create_by: takeData.create_by || null,
      status_id: takeData.status || null,
      stock_adj_reference_number:
      takeData.stockAdjustmentReferenceNumber || null,
      stock_adjustment_id: takeData.stock_adjustment_id || null,
      stock_take_id: takeData.stockTakeNumber || null,
      update_at: takeData.update_at || null,
      update_by: takeData.update_by || null,
      from: 'take',
    }
    this.router.navigate(routerRule, { queryParams: data })
  }
  getPermission(p) {
    this.permission =
      LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // this.setPermission(this.queryData.btns)
  }
  setPermission(list?) {
    let permissionService = this.permissionService
    let prefix = this.detailData.stockTakeTypeId === 45 ? 'Surprise - ' : ''
    let btns = this.queryData.btns
    let adjSearchPermission = TAKEBUID_ADJSEARCH_PERMISSION[this.detailData.bu] || TAKEBUID_ADJSEARCH_PERMISSION.DEFAULT;
    if(this.isSurprise){
      if(btns) btns.forEach((item) => {
        item.title === BTN_TITLE.EXPORT && (
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_VIEW_REPORT)
        )
        item.title === BTN_TITLE.SUBMIT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_COMPLETE))
        );
        item.title === BTN_TITLE.MARK_AS_FIRST_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.FIRST_COUNT_MARK_TAKE_COMPLETE && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.CREATE_ADJUSTMENT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE_AJ))
        );
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (
          item.disabled = !adjSearchPermission
        );
        item.title === BTN_TITLE.MARK_AS_SECOND_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_2ND_COMPLETE))
        );
        item.title === BTN_TITLE.PENDING_FOR_MARK_2 && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_PENDING_2ND))
        );
      })
    }else if(this.isFrontline){
      if(btns) btns.forEach((item) => {
        item.title === BTN_TITLE.EXPORT && (
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_VIEW_REPORT)
        )
        item.title === BTN_TITLE.SUBMIT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_COMPLETE))
        );
        item.title === BTN_TITLE.MARK_AS_FIRST_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.FIRST_COUNT_MARK_TAKE_COMPLETE && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.CREATE_ADJUSTMENT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE_AJ))
        );
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (
          item.disabled = !adjSearchPermission
        );
        item.title === BTN_TITLE.MARK_AS_SECOND_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE))
        );
        item.title === BTN_TITLE.PENDING_FOR_MARK_2 && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING_MARK_2ND))
        );
      })
    }else{
      if(btns) btns.forEach((item) => {
        item.title === BTN_TITLE.EXPORT && (
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_VIEW_REPORT)
        )
        item.title === BTN_TITLE.SUBMIT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_COMPLETE))
        );
        item.title === BTN_TITLE.MARK_AS_FIRST_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.FIRST_COUNT_MARK_TAKE_COMPLETE && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST_COMPLETE))
        );
        item.title === BTN_TITLE.CREATE_ADJUSTMENT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE_AJ))
        );
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (
          item.disabled = !adjSearchPermission
        );
        item.title === BTN_TITLE.MARK_AS_SECOND_COUNT && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE))
        );
        item.title === BTN_TITLE.PENDING_FOR_MARK_2 && (
          item.disabled = (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING_MARK_2ND))
        );
      })
    }
  }
  setTableData(list) {
    this.tableData.data = list
  }
  changeBtnStatus(params) {
    for (const btn of  this.queryData.btns) {
      if (btn.title ===  BTN_TITLE.CREATE_ADJUSTMENT) {
        this.showU && !btn.unchanged && params.HAVE_VARIANCE && (btn.show = params[btn.alias])
      } else {
        this.showU && !btn.unchanged && (btn.show = params[btn.alias])
      }
    }
  }

  setSubmitStatus() {
    const takeData = this.detailData;
    let data = {}
    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)

    if(
      statusObj.value == TAKE_STATUS_ID.STIP1C
    ) {
      data = {
        MARK_AS_FIRST_COUNT: true,
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.STIP1CIC
    ) {
      data = {
        MARK_AS_FIRST_COUNT: this.haveVariance,
        FIRST_COUNT_MARK_TAKE_COMPLETE: !this.haveVariance,
        EXPORT: true,
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.STIP2C
    ) {
      data = {
        PENDING_FOR_MARK_2: true,
        MARK_AS_SECOND_COUNT: true,
        // CREATE_ADJUSTMENT: true,
        // HAVE_VARIANCE: this.haveVariance // 针对按钮：CREATE_ADJUSTMENT
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.STIP2CIC
    ) {
      data = {
        PENDING_FOR_MARK_2: true,
        MARK_AS_SECOND_COUNT: true,
        EXPORT: true,
        // CREATE_ADJUSTMENT: true,
        // HAVE_VARIANCE: this.haveVariance // 针对按钮：CREATE_ADJUSTMENT
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.STIP2CC
    ) {
      data = {
        SUBMIT: true && !this.haveVariance,
        EXPORT: true,
        CREATE_ADJUSTMENT: true,
        HAVE_VARIANCE: this.haveVariance
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.COMPLETED
    ) {
      data = {
        VIEW_ADJUSTMENT: this.haveVariance && this.adjustmentStatus != ADJUSTMENT_STATUS.NO_ADJUSTMENT,
        // CREATE_ADJUSTMENT: true,
        // SUBMIT: true,
        EXPORT: true,
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.PARTIAL
    ) {
      data = {
        // CREATE_ADJUSTMENT: true,
        VIEW_ADJUSTMENT: true,
        SUBMIT: this.adjustmentStatus == ADJUSTMENT_STATUS.COMPLETE,
        HAVE_VARIANCE: this.haveVariance,
        EXPORT: true,
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.REVIEW
    ) {
      data = {
        // CONFIRM: true,
      }
    }
    this.changeBtnStatus(data)
    this.setPermission()
    // const takeData = LocalStorageHelper.getObject('takeDetail')
    // const backBtn = this.getButtonByLabel('Back')
    // backBtn.disabled = false;
    // backBtn.show = true;
    // this.queryData.btns[3].disabled = false
    // const adjustmentBtn = this.getButtonByLabel( BTN_TITLE.CREATE_ADJUSTMENT )
    // const backBtn = this.getButtonByLabel(BTN_TITLE.BACK)
    // const secondBtn = this.getButtonByLabel(BTN_TITLE.MARK_AS_SECOND_COUNT)
    // backBtn.disabled = false;

    // if(takeData.status == STATUS_TITLE.STOCK_TAKE_IN_PROGRESS){
    /* if(takeData.status.includes(STATUS_TITLE.STOCK_TAKE_IN_PROGRESS)) {
      const submitBtn = this.getButtonByLabel(BTN_TITLE.SUBMIT)
      if(this.haveVariance && takeData.stockTakeCount < 2){
        // 1st count + have variance
        submitBtn.disabled = true;
        submitBtn.show = false;
        adjustmentBtn.disabled = true;
        adjustmentBtn.show = false;
        secondBtn.disabled = true;
        secondBtn.show = false;
      }else{
        secondBtn.disabled = false;
        secondBtn.show = true;
        submitBtn.disabled = false;
        submitBtn.show = true
      }
      if(takeData.stockTakeCount < 2) {
        adjustmentBtn.disabled = true;
        adjustmentBtn.show = false;
        const markAsFirstCountBtn = this.getButtonByLabel(BTN_TITLE.MARK_AS_FIRST_COUNT)
        markAsFirstCountBtn.disabled = false;
        markAsFirstCountBtn.show = true;
        if(takeData.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1_complete) {
          const secondCountBtn = this.getButtonByLabel(BTN_TITLE.MARK_AS_SECOND_COUNT)
          secondCountBtn.disabled = false;
          secondCountBtn.show = true;
        }
      } else if (takeData.stockTakeCount == 2) {
        if(this.haveVariance){
          adjustmentBtn.disabled = false;
          adjustmentBtn.show = true;
        }else{
          adjustmentBtn.disabled = true;
          adjustmentBtn.show = false;
        }
        const markAsFirstCountBtn = this.getButtonByLabel(BTN_TITLE.MARK_AS_FIRST_COUNT)
        markAsFirstCountBtn.disabled = true;
        markAsFirstCountBtn.show = false;
      }
    }else{
      const markAsFirstCountBtn = this.getButtonByLabel(BTN_TITLE.MARK_AS_FIRST_COUNT)
      markAsFirstCountBtn.disabled = true;
      markAsFirstCountBtn.show = false;
    }
    if (takeData.status === 'Review In Progress') {
      adjustmentBtn.disabled = true;
      adjustmentBtn.show = false;
      const submitBtn = this.getButtonByLabel('Submit')
      submitBtn.show = false
      const confirmBtn = this.getButtonByLabel('Confirm')
      confirmBtn.show = true
      confirmBtn.disabled = false
    }
    if (takeData.status === 'Completed') {
      adjustmentBtn.disabled = false;
      adjustmentBtn.show = true;
      const submitBtn = this.getButtonByLabel('Submit')
      submitBtn.show = false
      const confirmBtn = this.getButtonByLabel('Confirm')
      confirmBtn.show = false
      confirmBtn.disabled = true
      adjustmentBtn.disabled = true;
      adjustmentBtn.show = false;
    }
    if (takeData.status === STATUS_TITLE.PARTIAL_COMPLETED) {
      adjustmentBtn.disabled = false;
      adjustmentBtn.show = true;
      const submitBtn = this.getButtonByLabel('Submit')
      submitBtn.show = false
      const confirmBtn = this.getButtonByLabel('Confirm')
      confirmBtn.show = true
      confirmBtn.disabled = false
    } */
  }
  loadDetailDataById(){
    const isView = this.route.snapshot.queryParams.view || false
    this.isView = isView;
    this.tableData.loadingSwitch = true
    this.route.queryParams.subscribe(params=>{
      if(params['id']){
        this.headId = params['id']
        this.takeService.searchDetailById(params["id"]).subscribe(res=>{
          if(res?.data[0]){
            this.detailData = res.data[0]
            // this.setPermission(this.queryData.btns)
            forkJoin([
              from(this.initQueryData(isView)),
              this.getAdjustmentStatus()
            ]).subscribe(res=>{
              this.setSubmitStatus()
            })
          }
        })
      }
    })
  }
  reloadStockDetail(){
    this.takeService.searchDetailById(this.headId).subscribe(res=>{
      if(res?.data[0]){
        this.detailData = res.data[0]
        this.setQueryDataValue()
        // this.setPermission(this.queryData.btns)
        this.getAdjustmentStatus().subscribe(res=>{
          this.setSubmitStatus()
        })
      }
    })
  }
  initQueryData(view) {
    // this.detailData = LocalStorageHelper.getObject('takeDetail')
    // this.queryData.btns[5].show = false
    // const submitBtn = this.getButtonByLabel('Submit')
    // submitBtn.disabled = true;
    // submitBtn.show = false;
    // this.stockTakeCount = this.detailData.stockTakeCount
    // this.queryData.ipts[0].value = this.detailData.stockTakeNumber
    this.setQueryDataValue()
    const params = {
      id: this.detailData.id,
      stockTakeNumber: this.detailData.stockTakeNumber,
      statusCode: this.detailData.statusCode,
      channelCode: this.detailData.channelCode,
      isSurpriseStockTake: this.detailData.isSurpriseStockTake,
    }

    const url = view ? URLDICT.STOCK_TAKE_VIEW_VARIANCE_REPORT : URLDICT.STOCK_TAKE_CHECK_VARIANCE
    return HttpHelper.post(url, params)
      .then((res) => {
        if (res.code === '000') {
          // const exportBtn = this.getButtonByLabel('Export')
          // exportBtn.disabled = this.detailData.status !== 'Draft' ? false : true
          // const adjustmentBtn = this.getButtonByLabel( BTN_TITLE.CREATE_ADJUSTMENT )
          // adjustmentBtn.disabled = view
          // adjustmentBtn.disabled = this.detailData.stockAdjustmentReferenceNumber ? true : false
          let _resObj: {[key:string]: MergedVariancesModel} = {}
          this.haveVariance = false;
          res.data.forEach(data => {
            if(!_resObj[data.item]){
              let _itemLine = this.detailData.lines.find(line=>line.itemCode == data.item)
              _resObj[data.item] = {
                item: data.item,
                itemDesc: data.itemDesc,
                isSerialControl: data.isSerialControl,
                normalBalance: data.normalBalance,
                faultyBalance: data.faultyBalance,
                actionBy: _itemLine?_itemLine.actionBy:'',
                countData: {},
                _data: [],
              }
            }else{
            }
            _resObj[data.item].countData[data.countType] = {
              normalLineId: data.normalLineId,
              normal: data.normal,
              nv: data.nv,
              normalSerialCount: data.normalSerialCount,
              faultyLineId: data.faultyLineId,
              faulty: data.faulty,
              fv: data.fv,
              faultySerialCount: data.faultySerialCount,
              normalSerialHaveVariance: data.normalSerialHaveVariance,
              normalSerialHaveVarianceDisplay: data.normalSerialHaveVariance == 'Y' && data.nv==0 && !(data.countType == 1 && this.detailData.countBy == this._countByList['QTY']) ?FOUND_SERIAL_VARIANCE_MSG:null,
              faultySerialHaveVariance: data.faultySerialHaveVariance,
              faultySerialHaveVarianceDisplay: data.faultySerialHaveVariance == 'Y' && data.fv==0 && !(data.countType == 1 && this.detailData.countBy == this._countByList['QTY']) ?FOUND_SERIAL_VARIANCE_MSG:null,
            }
            _resObj[data.item]._data.push(data)
            if(data.countType == this.stockTakeCount && 
              (data.nv!=0 || data.fv!=0 || 
                (
                  !(data.countType == 1 && this.detailData.countBy == this._countByList['QTY']) && 
                  (data.normalSerialHaveVariance == 'Y' || data.faultySerialHaveVariance == 'Y')
                )
              )
            ){
              this.haveVariance = true;
            }
          });
          this.setSubmitStatus()
          let _res: MergedVariancesModel[] = []
          for(let key in _resObj) {
            if (_resObj.hasOwnProperty(key)) {
              _res.push(_resObj[key])
            }
          }

          this.setTableData(_res)
          this.varianceData = _res
        } else {
          this.showMessage('error', this.info.title, this.info.saveFail)
        }
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        this.tableData.loadingSwitch = false
      })
  }

  setQueryDataValue(){
    this.stockTakeCount = this.detailData.stockTakeCount >= 2 ? 2 : this.detailData.stockTakeCount;
    let takeNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_REF_NO)
    // let adjRefNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_ADJUSTMENT_REF_NO)
    let channelIpt = this.queryData.ipts.find(item => item.title === TITLE.CHANNEL)
    let statusIpt = this.queryData.ipts.find(item => item.title === TITLE.STATUS)
    let dateIpt = this.queryData.ipts.find(item => item.title === TITLE.TARGETDATE)
    let countByIpt = this.queryData.ipts.find(item => item.title === TITLE.COUNTBY)
    let takeBuIpt = this.queryData.ipts.find(item => item.title === TITLE.TAKEBU)

    takeNoIpt.value = this.detailData.stockTakeNumber
    // adjRefNoIpt.value = this.detailData.stockAdjustmentReferenceNumber
    channelIpt.value = this.detailData.channelCode
    countByIpt.value = this.detailData.countBy
    takeBuIpt.value = this.detailData.bu

    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)

    if(
      // this.detailData.status == STATUS_TITLE.STOCK_TAKE_IN_PROGRESS
      statusObj.value == TAKE_STATUS_ID.READY
    ){
      if(this.stockTakeCount == 1){
        statusIpt.value = this.takeStatus.find(status=>status.value == TAKE_STATUS_ID.STIP1C).label
      }
      if(this.stockTakeCount == 2){
        statusIpt.value = this.takeStatus.find(status=>status.value == TAKE_STATUS_ID.STIP2C).label
      }
    }else{
      statusIpt.value = statusObj.label
    }

    this.loadCurrStatusId()

    const createat = this.detailData.stockTakeDate
    dateIpt.value = CommonMethod.convertDate_YYYYMMDD(createat)
    this.targetDateDescription = dateIpt.value
    this.setLabels()
  }

  isSubmit(num) {
    if (!this.tableData.data || this.tableData.data.length === 0) {
      this.showMessage('warn', this.info.title, this.info.addItems)
      return
    }
    if(this.info.firstCountFoundVariance && this.stockTakeCount == 1 && this.haveVariance){
      this.showMessage('warn', '', this.info.firstCountFoundVariance)
      return
    }
    // complete button hide when have variance / no completed adjustment
    // if (num === 2 && !this.detailData.stockAdjustmentReferenceNumber && this.haveVariance) {
    //   this.conf.confirm({
    //     message: this.info.exisitVarance,
    //     header: 'Submit',
    //     accept: () => {
    //       this.submit(num)
    //     },
    //   })
    //   return
    // } else {
    this.submit(num)
    // }
  }
  // 当没有传入line时，说明是edit状态下，并未上传过excel。type的作用是接受用户选择的数据处理方式（append or override）
  submit(num) {

    const data = {
      id: this.detailData.id,
      statusId: num,
    }
    this.tableData.loadingSwitch = true
    HttpHelper.post(URLDICT.STOCK_TAKE_CONFORSUBMIT, data)
      .then((res) => {
        this.tableData.loadingSwitch = false
        if (res.code === '000') {
          if (num === 2) {
            // this.detailData.status = 'Review In Progress'
            // this.detailData.statusCode = 'ST-Review'
            // const submitBtn = this.getButtonByLabel('Submit')
            // submitBtn.show = false
            // const confirmBtn = this.getButtonByLabel('Confirm')
            // confirmBtn.show = true
            // confirmBtn.disabled = false
            this.isView = true;
            this.loadDetailDataById()
            // this.setSubmitStatus()
            this.showMessage( 'success', this.info.title, this.info.submitSuccess )
          } else {
            // this.detailData.status = 'Completed'
            // this.detailData.statusCode = 'ST-Conp'
            // const confirmBtn = this.getButtonByLabel('Confirm')
            // confirmBtn.disabled = true
            this.isView = true;
            this.loadDetailDataById()
            // this.setSubmitStatus()
            if(res.data[0]&&res.data[0].sdlStatusId == 78){
              // partial complete
              this.showMessage( 'success', this.info.title, this.info.confirmSuccessPartialComplete )
            }else{
              this.showMessage( 'success', this.info.title, this.info.confirmSuccess )
            }
          }
          // sessionStorage.setItem('takeDetail', JSON.stringify(this.detailData))
        } else {
          if (res.state === 'failed') {
            this.showMessage('error', this.info.title, res.msg)
          } else {
            if (num === 2) {
              this.showMessage('error', this.info.title, this.info.submitFail)
            } else {
              this.showMessage('error', this.info.title, this.info.confirmFail)
            }
          }
        }
      })
      .catch((e) => {
        console.log('error', e)
        this.showMessage('error', this.info.title, e)
        this.tableData.loadingSwitch = false
      })
      .finally(() => console.log('submit or confirm completed!'))
  }
  back() {
    // if (this.detailData.status.includes('2nd') && !this.detailData.status.includes('count complete')) {
    //   this.tableData.loadingSwitch = true
    //   this.takeService.backTo2ndCountDetail(this.detailData.id).subscribe(res => {
    //     this.tableData.loadingSwitch = false
    //     this.startBack()
    //   })
    // } else {
    this.startBack()
    // }
  }

  startBack() {
    this.router.navigate(['main', 'stock_take', 'detail'], {
      queryParams: {id: this.headId},
    })
  }
  showErrorDialog(title, info) {
    this.conf.confirm({ message: info, header: title, })
  }

  showMessage(severity, summary, detail) {
    this.msg.add({ severity, summary, detail })
  }
  init() {
    this.orderType = LocalStorageHelper.getObject('ORDER_TYPE')
    this.shopAndWarehouse = LocalStorageHelper.getObject('REPOTREE')
    this.orderStatus = LocalStorageHelper.getObject('ORDER_STATUS')
    this.reasons = LocalStorageHelper.getObject('REASONS')
    this.takeStatus = LocalStorageHelper.getObject('TAKE_STATUS')
    this.initCountBy()
  }

  getCreateAdjustmentParams(){
    return {
      line: [],
      dmDnNumber: '',
      orderNumber: CommonMethod.genTranNum(new Date(), 'TF', ''),
      orderTypeId: this.orderType.find(e => e.orderTypeCode == 'ADJUSTMENT').id,
      fromChannelId: this.getConditionFromShopAndWarehouseByKeyValue('key', this.detailData.channelCode, this.shopAndWarehouse).id,
      toChannelId: null,
      fromStatusNature: null,
      toStatusNature: null,
      orderStatusCode: this.orderStatus.find(e => e.label.toUpperCase() == 'DRAFT').value,
      remarks: '',
      sourceTxnType: TAKE_ADJUSTMENT_TXN_TYPE,
      sourceTxnRefHeaderId: this.detailData.id,
      sourceTxnRefHeaderNo: this.detailData.stockTakeNumber,
      creationDate: new Date(),
      internalRemarks: `stock take variance [stock take order no. "${this.detailData.stockTakeNumber}"]`
    }
  }

  // checkAllowCreateAdjustment(params=this.getCreateAdjustmentParams()){
  //   // params = {
  //   //   line: [],
  //   //   dmDnNumber: '',
  //   //   orderNumber: CommonMethod.genTranNum(new Date(), 'TF', ''),
  //   //   orderTypeId: this.orderType.find(e => e.orderTypeCode == 'ADJUSTMENT').id,
  //   //   fromChannelId: this.getConditionFromShopAndWarehouseByKeyValue('key', this.detailData.channelCode, this.shopAndWarehouse).id,
  //   //   toChannelId: null,
  //   //   fromStatusNature: null,
  //   //   toStatusNature: null,
  //   //   orderStatusCode: this.orderStatus.find(e => e.label.toUpperCase() == 'DRAFT').value,
  //   //   remarks: '',
  //   //   sourceTxnType: TAKE_ADJUSTMENT_TXN_TYPE,
  //   //   sourceTxnRefHeaderId: this.detailData.id,
  //   //   sourceTxnRefHeaderNo: this.detailData.stockTakeNumber,
  //   //   creationDate: new Date(),
  //   //   internalRemarks: `stock take variance [stock take order no. "${this.detailData.stockTakeNumber}"]`
  //   // }
  //   let fromNatures = []
  //   let obsList = [];
  //   this.tableData.loadingSwitch = true;
  //   let lineNumber = 1;

  //   return this.stockCommonService
  //     .getFromNature(params.orderTypeId, params.fromChannelId)
  //     // gen create adjustment payload by get serial variance
  //     // return created adjustment if exist
  //     // return exist order array [] / [{xxx}]
  //     .pipe(
  //       tap((res) => {
  //         fromNatures = res
  //       }),
  //       // get created adjustment
  //       switchMap((res) => {
  //         return this.stockCommonService.getTransferOrderBySourceTxnData(
  //           params.sourceTxnType,
  //           params.sourceTxnRefHeaderId,
  //           params.sourceTxnRefHeaderNo
  //         )
  //       }),
  //       // if no created adjustment, gen get serial variance observable, calculate line, serial
  //       tap(order=>{
  //         let reasonId = this.reasons.find((e) => e.reasonCode == 'WO').id
  //         if (order && order.length == 0) {
  //           // gen line data
  //           this.varianceData.forEach((variance) => {
  //             let _variance = {
  //               ...variance,
  //               ...variance.countData[this.stockTakeCount]
  //             }
  //             let itemId = this.detailData.lines.find( (e) => e.itemCode == _variance.item ).itemId
  //             if(_variance.isSerialControl!='Y'){
  //               // item not serial control
  //               if (_variance.fv != 0) {
  //                 params.line.push({
  //                   itemId,
  //                   quantity: _variance.fv,
  //                   fromStockConditionId: '1002',
  //                   reasonId: reasonId,
  //                   finalReasonId: reasonId,
  //                   serial: [],
  //                   lineNumber: lineNumber++
  //                 })
  //               }
  //               if (_variance.nv != 0) {
  //                 params.line.push({
  //                   itemId,
  //                   quantity: _variance.nv,
  //                   fromStockConditionId: '1001',
  //                   reasonId: reasonId,
  //                   finalReasonId: reasonId,
  //                   serial: [],
  //                   lineNumber: lineNumber++
  //                 })
  //               }
  //             }else{
  //               // item is serial control
  //               // check serial variance count same as qty variance
  //               // qty variance calculate by snapshot qty onhand qty value, serial variance get from snapshot qty onhand serial (compare serial api)
  //               let _obsList:Observable<any[]>[] = [
  //                 this.getCompareSerialForAdjustment(_variance.normalLineId,
  //                   itemId, '1001',_variance.nv),
  //                 this.getCompareSerialForAdjustment(_variance.faultyLineId,
  //                   itemId, '1002',_variance.fv),
  //               ]
  //               let obs = forkJoin(_obsList).pipe(tap((res:any[])=>{
  //                 res.forEach(lines=>{
  //                   let _lines = lines.map(line=>{return {...line, lineNumber: lineNumber++}})
  //                   params.line = [...params.line, ..._lines]
  //                 })
  //               }))
  //               obsList.push(obs)
  //             }
  //           })
  //         }
  //       }),
  //       // emit get serial variances api call, gen line data from serial variances
  //       // params add line serial value after this
  //       switchMap(order=>{
  //         if(obsList.length>0){
  //           // emit observable for gen serial control line data
  //           return forkJoin(obsList).pipe(switchMap(res=>of(order)))
  //         }else{
  //           return of(order)
  //         }
  //       }),
  //       tap(res=>{
  //       })
  //     )
  //     // check can reserve / serial exist
  //     // skip this checking if adjustment exist
  //     // return switchmap of([exist order array, error msg]), error msg == '' if no error
  //     .pipe(
  //       switchMap(order=>{
  //         // adjustment exist, skip checking
  //         if(order.length>0){
  //           return of([order, null, null])
  //         }else{
  //           // check can reserve / serial exist
  //           return forkJoin([
  //             this.stockCommonService.checkReservable(params),
  //             this.stockCommonService.checkSerialExist(params),
  //           ]).pipe(
  //             switchMap(([reserve, serial])=>{
  //               // if(reserve.code != '000'){
  //               //   // when not 000, backend error
  //               //   return throwError(reserve.msg)
  //               // }
  //               if(serial.code != '000' && !serial.data){
  //                 // when not 000 & data undefined, backend error, e.g. 207 Account Expired
  //                 return throwError(serial.msg)
  //               }

  //               let confirmWarningMsg = ''
  //               let remarkMsg = ''; // for set value in adjustment order remark if any those error case happen
  //               if(reserve.data[0].canreserve!='true'){
  //                 // reserve return code 000, can reserve = 'true' / 'false'
  //                 confirmWarningMsg += `${confirmWarningMsg.length>0?', ':''}Balance not enough to reserve`;
  //                 remarkMsg += `${remarkMsg.length>0?', ':''}Balance not enough to reserve`;
  //               }
  //               if(serial.code!='000'&&serial.data){
  //                 // serial return code 000 / 888, data always [] if process success in backend
  //                 confirmWarningMsg += `${confirmWarningMsg.length>0?', ':''}Serial Number Not Match`;
  //                 remarkMsg += `${remarkMsg.length>0?', ':''}${serial.msg}`;
  //               }

  //               if(confirmWarningMsg.length<=0){
  //                 // no problem return [] (no exist adjustment)
  //                 return of([order, null, null])
  //               }else{
  //                 // return throwError(`${msg}`)
  //                 return of([order, confirmWarningMsg, remarkMsg])
  //               }
  //             })
  //           )
  //         }
  //       })
  //     )
  //     // if found problem (confirmWarningMsg.length > 0) show confirm dialog + skip origin process(create adjustment, update stock take status, redirect)
  //     // switch map to useless of() (to skip all process if found problem) / getCreateAdjAndRouteObs() return observable
  //     // better dont add pipe after this pipe, or check confirm dialog is showing
  //     .pipe(
  //       switchMap(([order, confirmWarningMsg, remarkMsg])=>{
  //         if(confirmWarningMsg != null && confirmWarningMsg.length>0){
  //           // need to show valid msg
  //           // skip to end
  //           // when this call, observable should completed to subscribe, turn off loading switch
  //           // create adjustment process continue after confirm
  //           // this.conf.confirm({
  //           //   message: `Confirm to Create Adjustment? This Adjustment may not complete whole order process. ${confirmWarningMsg}.`,
  //           //   header: 'Confirmation',
  //           //   accept: () => { 
  //           //     this.tableData.loadingSwitch = true;
  //           //     params.remarks = `${remarkMsg}` // remarkMsg should have value if confirmWarningMsg.length>0, 
  //           //     this.getCreateAdjAndRouteObs(order, params).subscribe(res=>{
  //           //       this.tableData.loadingSwitch = false;
  //           //     })  
  //           //   },
  //           // });
  //           throw remarkMsg
  //           // return of(false)
  //         }else{
  //           // no valid msg
  //           // continue process, create adjustment, route to order
  //           return of(true)
  //         }
  //       })
  //     )
  //     // .subscribe(res=>{
  //     //   this.tableData.loadingSwitch = false;
  //     //   if(res){

  //     //   }
  //     // },
  //     // err=>{
  //     //   this.showMessage('error', this.info.title, err)
  //     //   this.tableData.loadingSwitch = false;
  //     // })
  // }

  createStockAdjustmentAndRedirectToTransferOrder() {
    let orderTypeId = TAKEBUID_ADJTYPEID[this.detailData.bu] || TAKEBUID_ADJTYPEID.DEFAULT
    let params = {
      line: [],
      dmDnNumber: '',
      orderNumber: CommonMethod.genTranNum(new Date(), 'TF', ''),
      orderTypeId: orderTypeId,
      fromChannelId: this.getConditionFromShopAndWarehouseByKeyValue('key', this.detailData.channelCode, this.shopAndWarehouse).id,
      toChannelId: null,
      fromStatusNature: null,
      toStatusNature: null,
      orderStatusCode: this.orderStatus.find(e => e.label.toUpperCase() == 'DRAFT').value,
      remarks: '',
      sourceTxnType: TAKE_ADJUSTMENT_TXN_TYPE,
      sourceTxnRefHeaderId: this.detailData.id,
      sourceTxnRefHeaderNo: this.detailData.stockTakeNumber,
      creationDate: new Date(),
      internalRemarks: `stock take variance [stock take order no. "${this.detailData.stockTakeNumber}"]`
    }
    let fromNatures = []
    let obsList = [];
    this.tableData.loadingSwitch = true;
    let lineNumber = 1;

    this.stockCommonService
      .getFromNature(params.orderTypeId, params.fromChannelId)
      // gen create adjustment payload by get serial variance
      // return created adjustment if exist
      // return exist order array [] / [{xxx}]
      .pipe(
        tap((res) => {
          fromNatures = res
        }),
        // get created adjustment
        switchMap((res) => {
          return this.stockCommonService.getTransferOrderBySourceTxnData(
            params.sourceTxnType,
            params.sourceTxnRefHeaderId,
            params.sourceTxnRefHeaderNo
          )
        }),
        // if no created adjustment, gen get serial variance observable, calculate line, serial
        switchMap(order=>{
          return this.takeService.compareAllSerial({stockTakeId: this.detailData.id, countType: this.stockTakeCount}).pipe(map(res=>{
            if(res.code != '000') throw res.msg || res.message
            return [order, res.data]
          }))
        }),
        tap(([order, serialCompareResultList])=>{
          // map serial list by line id
          let serialListMappedByTakeLineId = {}
          serialCompareResultList.forEach(serial => {
            if(!serialListMappedByTakeLineId[serial.stockTakeLineId]) serialListMappedByTakeLineId[serial.stockTakeLineId] = []
            serialListMappedByTakeLineId[serial.stockTakeLineId].push(serial)
          });

          let reasonId = this.reasons.find((e) => e.reasonCode == 'WO').id
          if (order && order.length == 0) {
            // gen line data
            this.varianceData.forEach((variance) => {
              let _variance = {
                ...variance,
                ...variance.countData[this.stockTakeCount]
              }
              let itemId = this.detailData.lines.find( (e) => e.itemCode == _variance.item ).itemId
              if(_variance.isSerialControl!='Y'){
                // item not serial control
                if (_variance.fv != 0) {
                  params.line.push({
                    itemId,
                    quantity: _variance.fv,
                    fromStockConditionId: '1002',
                    reasonId: reasonId,
                    finalReasonId: reasonId,
                    serial: [],
                    lineNumber: lineNumber++
                  })
                }
                if (_variance.nv != 0) {
                  params.line.push({
                    itemId,
                    quantity: _variance.nv,
                    fromStockConditionId: '1001',
                    reasonId: reasonId,
                    finalReasonId: reasonId,
                    serial: [],
                    lineNumber: lineNumber++
                  })
                }
              }else{
                // item is serial control
                // check serial variance count same as qty variance
                // qty variance calculate by snapshot qty onhand qty value, serial variance get from snapshot qty onhand serial (compare serial api)
                let _obsList:Observable<any[]>[] = [
                  this.getCompareSerialForAdjustment(_variance.normalLineId,
                    itemId, '1001',_variance.nv, serialListMappedByTakeLineId[_variance.normalLineId]),
                  this.getCompareSerialForAdjustment(_variance.faultyLineId,
                    itemId, '1002',_variance.fv, serialListMappedByTakeLineId[_variance.faultyLineId]),
                ]
                let obs = forkJoin(_obsList).pipe(tap((res:any[])=>{
                  res.forEach(lines=>{
                    let _lines = lines.map(line=>{return {...line, lineNumber: lineNumber++}})
                    params.line = [...params.line, ..._lines]
                  })
                }))
                obsList.push(obs)
              }
            })
          }
        }),
        // emit get serial variances api call, gen line data from serial variances
        // params add line serial value after this
        switchMap(([order, serialCompareResultList])=>{
          if(obsList.length>0){
            // emit observable for gen serial control line data
            return forkJoin(obsList).pipe(switchMap(res=>of(order)))
          }else{
            return of(order)
          }
        }),
        tap(res=>{
        })
      )
      // check can reserve / serial exist
      // skip this checking if adjustment exist
      // return switchmap of([exist order array, error msg]), error msg == '' if no error
      .pipe(
        switchMap(order=>{
          // adjustment exist, skip checking
          if(order.length>0){
            return of([order, null, null])
          }else{
            // check can reserve / serial exist
            return forkJoin([
              this.stockCommonService.checkReservable(params),
              this.stockCommonService.checkSerialExist(params),
            ]).pipe(
              switchMap(([reserve, serial])=>{
                if(reserve.code != '000'){
                  // when not 000, backend error
                  return throwError(reserve.msg)
                }
                if(serial.code != '000' && !serial.data){
                  // when not 000 & data undefined, backend error, e.g. 207 Account Expired
                  return throwError(serial.msg)
                }

                let confirmWarningMsg = ''
                let remarkMsg = ''; // for set value in adjustment order remark if any those error case happen
                if(reserve.data[0].canreserve!='true'){
                  // reserve return code 000, can reserve = 'true' / 'false'
                  confirmWarningMsg += `${confirmWarningMsg.length>0?', ':''}Balance not enough to reserve`;
                  remarkMsg += `${remarkMsg.length>0?', ':''}Balance not enough to reserve`;
                }
                if(serial.code!='000'&&serial.data){
                  // serial return code 000 / 888, data always [] if process success in backend
                  confirmWarningMsg += `${confirmWarningMsg.length>0?', ':''}Serial Number Not Match`;
                  remarkMsg += `${remarkMsg.length>0?', ':''}${serial.msg}`;
                }

                if(confirmWarningMsg.length<=0){
                  // no problem return [] (no exist adjustment)
                  return of([order, null, null])
                }else{
                  // return throwError(`${msg}`)
                  return of([order, confirmWarningMsg, remarkMsg])
                }
              })
            )
          }
        })
      )
      // if found problem (confirmWarningMsg.length > 0) show confirm dialog + skip origin process(create adjustment, update stock take status, redirect)
      // switch map to useless of() (to skip all process if found problem) / getCreateAdjAndRouteObs() return observable
      // better dont add pipe after this pipe, or check confirm dialog is showing
      .pipe(
        switchMap(([order, confirmWarningMsg, remarkMsg])=>{
          if(confirmWarningMsg != null && confirmWarningMsg.length>0){
            // need to show valid msg
            // skip to end
            // when this call, observable should completed to subscribe, turn off loading switch
            // create adjustment process continue after confirm
            this.conf.confirm({
              message: `Confirm to Create Adjustment? This Adjustment may not complete whole order process. ${confirmWarningMsg}.`,
              header: 'Confirmation',
              accept: () => { 
                this.tableData.loadingSwitch = true;
                params.remarks = `${remarkMsg}` // remarkMsg should have value if confirmWarningMsg.length>0, 
                this.getCreateAdjAndRouteObs(order, params).subscribe(res=>{
                  this.tableData.loadingSwitch = false;
                })  
              },
            });
            return of(order)
          }else{
            // no valid msg
            // continue process, create adjustment, route to order
            return this.getCreateAdjAndRouteObs(order, params)
          }
        })
      )
      .subscribe(res=>{
        this.tableData.loadingSwitch = false;
      },
      err=>{
        this.showMessage('error', this.info.title, err)
        this.tableData.loadingSwitch = false;
      })
  }

  getCreateAdjAndRouteObs(data, params){
    return of(data).pipe(
      // create order
       switchMap((order) => {
        if (order && order.length == 0) {
          return this.stockCommonService.transferOffer(params)
        } else if (order && order.length == 1) {
          return of(order)
        }
      }),
      // change stock take status
      switchMap(order=>{
        return this.takeService.markAsAdjustInProgress(this.detailData.id).pipe(map(adjRes=>{return[order,adjRes]}))
      }),
      // redirect to order
      tap(res=>{
        let [order, take] = res
        if (order && order.length == 1) {
          this.router.navigate(['main', 'transfer_order', 'create'], {
            queryParams: { headId: order[0].headId },
          })
        }
      }) 
    )
  }

  viewStockAdjustment() {
    let params = {
      line: [],
      dmDnNumber: '',
      orderNumber: CommonMethod.genTranNum(new Date(), 'TF', ''),
      orderTypeId: this.orderType.find(e => e.orderTypeCode == 'ADJUSTMENT').id,
      fromChannelId: this.getConditionFromShopAndWarehouseByKeyValue('key', this.detailData.channelCode, this.shopAndWarehouse).id,
      toChannelId: null,
      fromStatusNature: null,
      toStatusNature: null,
      orderStatusCode: this.orderStatus.find(e => e.label.toUpperCase() == 'DRAFT').value,
      remarks: '',
      sourceTxnType: TAKE_ADJUSTMENT_TXN_TYPE,
      sourceTxnRefHeaderId: this.detailData.id,
      sourceTxnRefHeaderNo: this.detailData.stockTakeNumber,
      creationDate: new Date(),
      internalRemarks: `stock take variance [stock take order no. "${this.detailData.stockTakeNumber}"]`
    }
    let fromNatures = []
    this.stockCommonService
      .getFromNature(params.orderTypeId, params.fromChannelId)
      .pipe(
        tap((res) => {
          fromNatures = res
          // let reasonId = this.reasons.find((e) => e.code == 'HE').id
          this.varianceData.forEach((variance) => {
            let _variance = {
              ...variance,
              ...variance.countData[this.stockTakeCount]
            }
            let itemId = this.detailData.lines.find( (e) => e.itemCode == _variance.item ).itemId
            if (_variance.fv != 0) {
              params.line.push({
                itemId,
                quantity: _variance.fv,
                fromStockConditionId: '1002',
                // reason: reasonId,
                serial: [],
              })
            }
            if (_variance.nv != 0) {
              params.line.push({
                itemId,
                quantity: _variance.nv,
                fromStockConditionId: '1001',
                // reason: reasonId,
                serial: [],
              })
            }
          })
        }),
        switchMap((res) => {
          return this.stockCommonService.getTransferOrderBySourceTxnData(
            params.sourceTxnType,
            params.sourceTxnRefHeaderId,
            params.sourceTxnRefHeaderNo
          )
        }),
        switchMap((res) => {
          if (res && res.length == 0) {
            return of(null)
          } else if (res && res.length == 1) {
            return of(res)
          }
        }),
      )
      .subscribe((order) => {
        if (order && order.length == 1) {
          this.router.navigate(['main', 'transfer_order', 'create'], {
            queryParams: { headId: order[0].headId },
          })
        }
      })
  }
  getConditionFromShopAndWarehouseByKeyValue(key, value, list: any[]) {
    let target = null
    list.forEach((child) => {
      if (child[key] == value) {
        target = child
      } else if (child.children && child.children.length > 0) {
        let res = this.getConditionFromShopAndWarehouseByKeyValue( key, value, child.children )
        if (res) target = res
      }
    })
    return target
  }

  add(data, field, count?) {
    this.tableData.loadingSwitch = true
    this.stockTakeLineId =
      field === 'fv' || field === 'faulty' ? data.faultyLineId : data.normalLineId
    this.takeService
      .getSerial({ stockTakeLineId: this.stockTakeLineId })
      .subscribe((res) => {
        this.tableData.loadingSwitch = false
        let list = []
        if (res.code === '000') {
          list = res.data
        }
        this.showSerials(list, data, field, 'add')
      })
  }

  view(data, field, count) {
    this.tableData.loadingSwitch = true
    let _lineData = data.countData[count]
    this.stockTakeLineId = field === 'faulty' ? _lineData.faultyLineId : _lineData.normalLineId
    this.takeService
      .getSerial({ stockTakeLineId: this.stockTakeLineId })
      .subscribe((res) => {
        this.tableData.loadingSwitch = false
        let list = []
        if (res.code === '000') {
          list = res.data
        }
        this.showSerials(list, data, field, 'view')
      })
  }

  showSerials(list, data, field, type) {
    const items = LocalStorageHelper.getObject('SKU')
    const item = items.find((sku) => sku.name === data.item)
    const num = field === 'faulty' ? data.faulty : data.normal
    this.serialModalConfig = {
      ...this.serialModalConfig,
      itemCode: { value: item.name, disabled: true, title: 'Item Code' },
      itemDesc: { value: item.flag, disabled: true, title: 'Item Desc.' },
      actionQty: { value: `${list.length} / ${num}`, disabled: true, title: 'Action Qty', },
      qty: num,
      serialList: type === 'add' ? [] : list.map((item1) => item1.serial),
      newSerialValue: null,
      range: {},
      index: +data.item,
      existList: type === 'add' ? [] : list.length ? JSON.parse(JSON.stringify(list)) : [],
      deleteList: [],
      currentLIne: field,
      type,
    }
    this.setSerialModalVisible(true)
  }
  compareStockTakeLineSerial(rowData, key, count) {
    this.tableData.loadingSwitch = true
    let _lineData = rowData.countData[count]
    const data = {
      stockTakeLineId: key.indexOf('nv')>-1 ? _lineData.normalLineId : _lineData.faultyLineId
    }
    this.takeService.compareSerial(data).subscribe((res) => {
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        list = res.data
      }
      const values = {
        channel: this.detailData.channelCode,
        stockCondition: key.indexOf('nv')>-1 ? 'FG' : 'Faulty',
        onhandSerialQty:  key.indexOf('nv')>-1 ? rowData.normalBalance : rowData.faultyBalance,
        itemCode: rowData.item,
        itemDesc: rowData.itemDesc,
        stockTakeSerialQty: key.indexOf('nv')>-1 ? rowData.normalSerialCount : rowData.faultySerialCount,
      }
      this.compareSerialModalConfig.ipts.forEach((item) => { item.value = values[item.alias] })
      this.compareSerialModalConfig.serialCloneList = JSON.parse( JSON.stringify(list) )
      this.compareSerialModalConfig.serialList = list
      this.compareSerialModalConfig.checked = true
      this.compareSerialModalConfig.visible = true
    })
  }
  // ----- dialog content -----
  addSerialByInput() {
    const newSerial = this.serialModalConfig.newSerialValue
    if (newSerial) {
      this.addToSerialList(newSerial)
      this.serialModalConfig.newSerialValue = null
    } else {
      this.showMessage('warn', 'Serial Input', this.info.inputSerial)
    }
  }
  removeDeleteData(data) {
    const serial = this.serialModalConfig.existList.find( (item) => item.serial === data )
    if (serial) {
      let idx = this.serialModalConfig.deleteList.findIndex( (e) => serial.stockTakeLineSerialId === e );
      (<any[]>this.serialModalConfig.deleteList).splice(idx, 1)
    }
  }
  addToSerialList(e, force = false) {
    let duplicate = false
    // if (!force) {
    //   duplicate = this.serialModalConfig.serialList.includes(e)
    // }
    // if (!duplicate) {
    //   this.removeDeleteData(e)
    //   this.serialModalConfig.serialList.push(e)
    // }
    // this.changeActionQty()
    return !duplicate
  }
  changeActionQty() {
    this.serialModalConfig.actionQty = {
      value: `${this.serialModalConfig.serialList.length} / ${this.serialModalConfig.qty}`,
      disabled: true,
      title: 'Action Qty',
    }
  }
  serialFileDropped(e: any) {
    let reader = new FileReader()
    e[0].fileEntry.file((file) => {
      reader.readAsText(file)
      reader.onload = () => {
        let res = (<string>reader.result)
          .split(/\r?\n/)
          .filter((content) => content.length > 0)
        let successCount = 0
        let duplicateCount = 0
        res.forEach((val) => {
          let success = this.addToSerialList(val)
          if (success) {
            successCount++
          } else {
            duplicateCount++
          }
        })
        if (successCount)
          this.showMessage( 'info', 'Add Success', `${successCount} Serial Number Added` )
        if (duplicateCount)
          this.showMessage( 'error', 'Add Fail', `${duplicateCount} Serial Number Duplicated` )
      }
    })
  }
  onDeviceSelectChange(selected) {
    const selectedStr = selected.value.deviceId || ''
    const device = this.availableDevices.find( (x) => x.deviceId === selectedStr )
    this.deviceCurrent = device || null
  }
  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null
    if (this.deviceSelected === selectedDevice) {
      return
    }
    this.deviceSelected = selectedDevice
    this.deviceCurrent = device || undefined
  }
  onCodeResult(res: string) {
    let duplicateScan = this.serialModalConfig.serialList.some( (e) => e.serial == res )
    if (!duplicateScan) {
      this.addToSerialList(res)
      this.showMessage('info', 'Scan Success', `Serial Number ${res} added`)
    }
  }
  onHasPermission(has: boolean) {
    this.hasPermission = has
  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices
    this.hasDevices = Boolean(devices && devices.length)
  }
  serialModalRangeChange() {
    let from = this.serialModalConfig.range.from
    let to = this.serialModalConfig.range.to
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)
    if ( from && to && from.length === to.length && !isNaN(fromInt) && !isNaN(toInt) && fromInt <= toInt ) {
      this.serialModalConfig.range.total = toInt - fromInt
    } else {
      this.serialModalConfig.range.total = 0
    }
  }
  addSerialByRange() {
    let prefix = this.serialModalConfig.range.prefix ?? ''
    let suffix = this.serialModalConfig.range.suffix ?? ''
    let from = this.serialModalConfig.range.from
    let to = this.serialModalConfig.range.to
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)

    if ( from && to && from.length === to.length && !isNaN(fromInt) && !isNaN(toInt) && fromInt <= toInt ) {
      let successCount = 0
      let duplicateCount = 0
      for (let i = fromInt; i <= toInt; i++) {
        let value = String(i).padStart(from.length, '0')
        let success = this.addToSerialList(`${prefix}${value}${suffix}`)
        if (success) {
          successCount++
        } else {
          duplicateCount++
        }
      }
      if (successCount)
        this.showMessage( 'info', 'Add Success', `${successCount} Serial Number Added` )
      if (duplicateCount)
        this.showMessage( 'error', 'Add Fail', `${duplicateCount} Serial Number Duplicated` )
      this.serialModalConfig.range = {}
    }
  }

  onSerialRowDelete(data) {
    // 判断是否删除的是从服务器传递过来的数据
    const serial = this.serialModalConfig.existList.find( (item) => item.serial === data )
    if (serial) {
      // 判断是否是重复的删除数据
      if (!this.serialModalConfig.deleteList.includes(serial.stockTakeLineSerialId)) {
        this.serialModalConfig.deleteList.push(serial.stockTakeLineSerialId)
      }
    }
    let idx = this.serialModalConfig.serialList.findIndex((e) => data === e);
    (<any[]>this.serialModalConfig.serialList).splice(idx, 1)
    this.changeActionQty()
  }

  setSerialModalVisible(e) {
    this.serialModalConfig.visible = e
  }
  saveSerial() {
    this.tableData.loadingSwitch = true
    if (this.serialModalConfig.type !== 'add') {
    // } else {
      // 和服务器端数据对比，检索新加数据
      const existlist = this.serialModalConfig.existList.map( (item) => item.serial )
      const appendList = this.difference(this.serialModalConfig.serialList, existlist)
      // 存在新数据时
      if (appendList.length) {
        this.confirmToAppendSerialNumber(appendList)
      } else {
        // 存在删除的数据时
        if (this.serialModalConfig.deleteList.length) {
          this.confirmToDeleteSerialNumber()
        } else {
          this.tableData.loadingSwitch = false
          this.showMessage('warn', 'Serial Number', this.info.notChange)
        }
      }
    }
  }
  confirmToAppendSerialNumber(list) {
    const data = {
      stockTakeLineId: this.stockTakeLineId,
      serials: list,
    }
    this.takeService.appendSerial(data).subscribe((res) => {
      if (res.code === '000') {
        //存在删除的数据时
        if (this.serialModalConfig.deleteList.length) {
          this.confirmToDeleteSerialNumber()
        } else {
          this.confirmToSave()
        }
      } else {
        this.tableData.loadingSwitch = false
        this.showMessage('error', 'Add Fail', res.msg)
      }
    })
  }
  confirmToDeleteSerialNumber() {
    const data = {
      stockTakeLineSerialId: this.serialModalConfig.deleteList,
    }
    this.takeService.deleteSerial(data).subscribe((res) => {
      if (res.code === '000') {
        this.confirmToSave()
      } else {
        this.tableData.loadingSwitch = false
        this.showMessage('error', 'Delete Fail', res.msg)
      }
    })
  }
  confirmToSave() {
    this.showMessage('success', 'submit success', this.info.submitSuccess)
    this.tableData.loadingSwitch = false
    this.resetSerialModalConfig()
    this.setSerialModalVisible(false)
    // const isView = this.route.snapshot.queryParams.view || false
    this.loadDetailDataById()
  }
  resetSerialModalConfig() {
    this.serialModalConfig = {
      itemCode: {},
      itemDesc: {},
      actionQty: {},
      qty: 0,
      existList: [],
      serialList: [],
      deleteList: [],
      header: 'Input Serial Number',
      newSerialValue: null,
      range: {},
      index: null,
    }
  }
  //数组过滤
  difference(arr = [], oarr = []) {
    return arr.reduce((t, v) => (!oarr.includes(v) && t.push(v), t), [])
  }

  markAsFirstCount(){
    this.tableData.loadingSwitch = true
    this.takeService.markAsFirstCount(this.detailData.id).subscribe(preStockTakeRes=>{
      this.tableData.loadingSwitch = false;
      if(preStockTakeRes.code == '000' && preStockTakeRes?.data[0]?.id){
        this.back()
      }else{
        this.showMessage('warn', this.info.title, this.info.confirmFail.replace('Confirm',  'Mark as 1st Count '))
      }
    }, err=>{
      this.tableData.loadingSwitch = false;
      this.showMessage('warn', this.info.title, this.info.confirmFail.replace('Confirm',  'Mark as 1st Count '))
    })
  }
  markAsSecondCount() {
    this.tableData.loadingSwitch = true
    this.takeService.markAs2ndCount(this.detailData.id).subscribe(res => {
      this.tableData.loadingSwitch = false;
      if (res.code === '000' && res?.data[0]?.id) {
        // this.back()
        this.reloadStockDetail()
      } else {
        this.showMessage('warn', this.info.title, this.info.confirmFail.replace('Confirm',  'Mark as 2nd Count '))
      }
    }, err=>{
      this.tableData.loadingSwitch = false;
      this.showMessage('warn', this.info.title, this.info.confirmFail.replace('Confirm',  'Mark as 2nd Count '))
    })
  }
  pendingForMark2nd() {
    this.tableData.loadingSwitch = true
    this.takeService.backTo2ndCountDetail(this.detailData.id).subscribe(res => {
      this.tableData.loadingSwitch = false
      this.router.navigate(["main",'stock_take','edit'], {queryParams: {id: this.detailData.id}})
    })
  }
  // attachment start
  uploadModalConfig
  resetUploadModalConfig(){
    this.uploadModalConfig={
      visible: false,
      title: "Attachment",
      readonly: false,
      table: {
        data:[],
        head:[
          {key:'fileName',title:'File Name', width: '8rem',type:"filename" },
          {key:'createDate',title:'Upload Date', width: '8rem',type:'datetime' },
          {key:'delete',title:'Action', width: '8rem',type:'delete' },
        ],
        loading: false,
      }
    }
  }
  showUploadModal(){
    this.loadFileDetails()
    // let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    this.uploadModalConfig.readonly = !this.isUploadable;
    this.setUploadModalVisible(true)
  }
  hideUploadModal(){
    this.setUploadModalVisible(false)
  }
  setUploadModalVisible(e){
    this.uploadModalConfig.visible=e
  }
  downloadFile(e){
    this.takeService.getEdmsFile(
        e.edmsFileMappingId
      ).subscribe(res=>{
        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        a.href = url;
        a.download = e.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
  }

  confirmUpload(e, el){
    this.conf.confirm({
      message: `Confirm to upload ${e.files[0].name}?`,
      header: 'Submit',
      accept: () => {
        this.uploadFile(e, el)
      },
    })
  }

  uploadFile(e, popupPUploadEl){
    if(popupPUploadEl)this.popupPUploadEl = popupPUploadEl;
    this.uploadModalConfig.table.loading = true;
    this.takeService.uploadEdmsFile({
      file:e.files[0],
      body:JSON.stringify({"moduleName":"StockTake","relatedId":this.detailData.id,"fileName":e.files[0].name})
    }).subscribe(res=>{
      this.loadFileDetails()
      this.resetUploader()
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }
  loadFileDetails(){
    this.uploadModalConfig.table.loading = true;
    return this.takeService.getEdmsFileIds({
      "moduleName": "StockTake",
      "relatedId": this.detailData.id
    }).subscribe(res=>{
      if(res.code=='000'){
        let data = res.data.sort((a,b)=>{
          return a.createDate>b.createDate?-1:a.createDate<b.createDate?1:0
        })
        this.uploadModalConfig.table.data = data;
      }
    },err=>{},()=>{
      this.uploadModalConfig.table.loading = false;
    })
  }
  resetUploader(){
    if(this.popupPUploadEl)this.popupPUploadEl.clear()
  }
  onUploadModalHide(){
    this.resetUploader()
    this.resetUploadModalConfig()
  }
  deleteFile(edms){
    this.uploadModalConfig.table.loading = true;
    this.takeService.deleteEdmsFIle([edms.edmsFileMappingId]).subscribe(res=>{
      this.uploadModalConfig.table.loading = false;
      this.loadFileDetails()
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }

  attachmentDeleteOnClick(edms){
    this.conf.confirm({
      message: `Confirm to delete ${edms.fileName}?`,
      header: 'Confirmation',
      accept: () => { this.deleteFile(edms) },
    });
  }
  // attachment end
  getAdjustmentStatus(){
    return this.stockCommonService.getTransferOrderBySourceTxnData(
      TAKE_ADJUSTMENT_TXN_TYPE,
      this.detailData.id,
      this.detailData.stockTakeNumber
    ).pipe(map(res=>{
      if(res[0]){
        let statusList = LocalStorageHelper.getObject('ORDER_ADJUSTMENT_STATUS');
        let resStatus = res[0].status;
        let status = statusList.find(_status=>_status.label == resStatus)
        let adjRefNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_ADJUSTMENT_REF_NO)
        adjRefNoIpt.value = res[0].orderNumber
        if(status.value == 111){
          // complete
          return ADJUSTMENT_STATUS.COMPLETE
        }else{
          // not complete
          return ADJUSTMENT_STATUS.NOT_COMPLETE
        }
      }else{
        // no adjustment
        return ADJUSTMENT_STATUS.NO_ADJUSTMENT
      }
    }),tap(res=>{
      this.adjustmentStatus = res;
    }))
  }

  getCompareSerialForAdjustment(lineId, itemId, conditionCode,varianceQty, serialList){
    let _serialList = serialList || []
    // return this.takeService.compareSerial({stockTakeLineId:lineId})
    return of(null).pipe(
      map(res=>{
      // if (res.code === '000') {
        let list = _serialList
        let _removeSerialList = [];
        let _newSerialList = [];
        let serialChangeTotalQty;
        list.forEach(serial => {
          if(serial.inStockTakeSerial=='Yes'&&serial.inQtyOnHandSerial=='No'){
            _newSerialList.push(({serial:serial.serial}))
          }else if(serial.inStockTakeSerial=='No'&&serial.inQtyOnHandSerial=='Yes'){
            _removeSerialList.push({serial:serial.serial})
          }
        }
        );
        serialChangeTotalQty=_newSerialList.length - _removeSerialList.length;
        if(serialChangeTotalQty!=varianceQty){
          throw "Serial compare result not match with variances qty";
        }
        return {newSerial:_newSerialList, removeSerial:_removeSerialList}
      // }else{
      //   throw res.msg;
      // }
      }),
      map(res=>{
        let lines = [];
        let reasonId = this.reasons.find((e) => e.reasonCode == 'WO').id
        if(res.newSerial.length>0){
          lines.push({
            itemId,
            quantity: res.newSerial.length,
            fromStockConditionId: conditionCode,
            reasonId: reasonId,
            finalReasonId: reasonId,
            serials: res.newSerial,
          })
        }
        if(res.removeSerial.length>0){
          lines.push({
            itemId,
            quantity: -res.removeSerial.length,
            fromStockConditionId: conditionCode,
            reasonId: reasonId,
            finalReasonId: reasonId,
            serials: res.removeSerial,
          })
        }
        return lines
      })
    )
  }

  
  getCompareSerialForExportXlsx(lineId, itemId, conditionCode){
    return this.takeService.compareSerial({stockTakeLineId:lineId}).pipe(map(res=>{
      if (res.code === '000') {
        let list = res.data
        // let _removeSerialList = [];
        // let _newSerialList = [];
        // list.forEach(serial => {
        //   if(serial.inStockTakeSerial=='Yes'&&serial.inQtyOnHandSerial=='No'){
        //     _newSerialList.push(({serial:serial.serial}))
        //   }else if(serial.inStockTakeSerial=='No'&&serial.inQtyOnHandSerial=='Yes'){
        //     _removeSerialList.push({serial:serial.serial})
        //   }
        // });

        let _res = list.filter(serial=>{return serial.inStockTakeSerial=='No'||serial.inQtyOnHandSerial=='No'})
        return _res

        // return {newSerial:_newSerialList, removeSerial:_removeSerialList}
      }else{
        throw res.msg
      }
    }))
    // .pipe(map(res=>{
    //   let lines = [];
    //   if(res.newSerial.length>0){
    //     lines.push({
    //       itemId,
    //       quantity: res.newSerial.length,
    //       fromStockConditionId: conditionCode,
    //       // reason: reasonId,
    //       serials: res.newSerial,
    //     })
    //   }
    //   if(res.removeSerial.length>0){
    //     lines.push({
    //       itemId,
    //       quantity: -res.removeSerial.length,
    //       fromStockConditionId: conditionCode,
    //       // reason: reasonId,
    //       serials: res.removeSerial,
    //     })
    //   }
    //   return lines
    // }))
  }

  setLabels() {
    const list = LocalStorageHelper.getObject('TAKE_LABEL')
    const takeType = list.find(item => item.id === this.detailData.stockTakeTypeId)
    this._stockTakeTypeValue = takeType.code

    let ipt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_TYPE)
    ipt.value = takeType.name
    this.takeTypeDescription = takeType.name

    const takeBuList = LocalStorageHelper.getObject('STOCK_TAKE_BU').map(item=>{return {
      label: item.code,
      value: item.id,
    }})
    let takeBuipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TAKEBU)
    takeBuipt.options = takeBuList
  }
  
  customSort(event: SortEvent) {
    let keyPath
    let isKeyPath = /^normal_|nv_|faulty_|fv_/g.test(event.field)
    if(isKeyPath){
      let column = this.tableData.head.find(col=>col.key==event.field)
      keyPath = column.keyPath
    }
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];

      try{
        if(isKeyPath && keyPath){
          value1 = this.getValueByKeyPath(data1, keyPath)
          value2 = this.getValueByKeyPath(data2, keyPath)
        }
      }catch(e){console.error(e)}
      let result = null;

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }
  loadCurrStatusId(){
    let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    this.currStatusId = currStatus.value
  }

  initCountBy(){
    this.countByList = LocalStorageHelper.getObject('STOCK_TAKE_COUNT_BY')
    this.countByList.forEach(item=>{
      this._countByList[item.code]=item.description
    })
  }
}

interface MergedVariancesModel {
  countData: {
    [key: string]: {
      normalLineId: number;
      normal: number;
      nv: number;
      normalSerialCount: number;
      faultyLineId: number;
      faulty: number;
      fv: number;
      faultySerialCount: number;
      normalSerialHaveVariance: string,
      faultySerialHaveVariance: string,
      normalSerialHaveVarianceDisplay: string,
      faultySerialHaveVarianceDisplay: string,
    };
  };
  normalBalance: number,
  faultyBalance: number,
  isSerialControl: string,
  item: string,
  itemDesc: string,
  actionBy: string,
  _data: any[],
}
