/**
 * auto allocate method
 * route to this page when some stock order stock in completed
 * use order num to get pending to allocate reservation
 * pre-fill adj allocate qty by reservation plan to allocate qty
 * do allocate with adj allocate qty when click complete
*/

import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { action, computed, observable } from "mobx";
import { MessageService, ConfirmationService } from "primeng/api";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { CommonService } from "src/app/service/common/common-service";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { INPUT_TYPE, URLDICT } from "src/app/stores/base/BaseStore";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { CommonMethod } from "src/app/util/CommonMethod";
import { HttpHelper } from "src/app/util/HttpHelper";
import {LoadingService} from "@/service/common/loading-service";

const TITLE = {
  CHANNEL: 'Channel',
  RESERVATION_TYPE: 'Reservation Type',
  RESERVATION_DATE: 'Reservation Date',
  ITEM: 'Item',
  SOURCE_REF_NO: 'Source Reference No',
  SCHOOL_CODE: 'School Code',
  SCHOOL_LOC_CODE: 'School Location Code',
  SCHOOL_NUMBER: 'School Number',
  RESERVATION_ID: 'Reservation Id',
  REMARKS: 'Remarks',
};
@Component({
  selector: "stock-allocation-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class AllocationCreateComponent implements OnInit, OnDestroy {

  @observable _loading = 0;
  @computed get loadingSwitch(){return this._loading};
  set loadingSwitch(e) {
    this._loading = e > 0? e: 0;
  };

  @observable permission: string[];
  // @observable defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };

  @observable queryPannel: any = {};
  @observable lisOfData: any[] = [];

  @observable isVisible: boolean = false;

  items: any[] = [];
  reasons: any[] = [];
  seItems: any[] = [];
  channels: any[] = [];
  channels2: any[] = [];
  sechannels: any[] = [];
  sechannels2: any[] = [];
  orderTypes = [];
  status: any[] = [];
  statusItem1;
  statusItem2;
  orderTypeItem;
  orderTypeItemLabel;
  orderStatus: any[] = [];
  orderStatusItem: any = "DRAFT";
  channelValue;
  channelValue2;
  itemValue;
  balance;
  seItem;
  itemDesc;
  itemId;
  qty;
  StockInQty;
  creationDate;
  remark = "";
  quantity;
  fromChannel;
  toWareHouse;
  deliveryNote = "";
  orderNumber = "";
  fromStatus: any[] = [];
  fromNatures: any = [];
  toStatus: any[] = [];
  shopAndWarehouse: any[] = [];
  btnLabel = "Confirm";
  data: any = { line: [] };
  updateStatusData = { orderNumber: "", operation: "", updateType: "" };
  lineData: any[] = [];
  allocationDataList;
  _allocationDataListBuffer;
  filteredAllocationDataList = [];
  orderId;

  cols = [
    { header: "item_code", title: "Item", width: "200px" },
    { header: "item_desc", title: "Description", width: "200px", class:"word-break-break-word" },
    {
      header: "source_txn_ref_header_no",
      title: "Source Ref. No ",
      width: "200px",
    },
    {
      header: "reservationTypeDesc",
      title: "Reservation Type",
      width: "200px",
    },
    {
      header: "reserve_date",
      title: "Reservation Date",
      width: "200px",
      type: "date",
    },
    { header: "qty_requested", title: "Request Reserve Qty", width: "200px" },
    { header: "qty_pending", title: "Pending Qty", width: "200px" },
    {
      header: "qty_plan_to_allocate",
      title: "Planned Allocation Qty",
      width: "200px",
    },
    {
      header: "qty_adjust_allocate",
      title: "Adjusted Allocation Qty",
      width: "200px",
      type: "number",
    },
    {
      header: "stockAllocationTypeDesc",
      title: "Allocation Type",
      width: "200px",
    },
    { header: "allocation_planner_id", title: "Allocation By", width: "200px" },
  ];

  pageIndex: number = 0;
  pageSize: number = 20;
  totalRecords = 0;

  _selectedColumns: any[];
  originColList = [];

  selectedType = "";
  fromChannelShowValue;
  toChannelShowValue;
  reservationTypeList;
  stockAllocationTypeList;

  isEdit = true;
  private loadingService: LoadingService;
  constructor(
    public n: MessageService,
    public r: Router,
    public p: ActivatedRoute,
    public m: ConfirmationService,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
    private i18n: TranslateService,
  ) {
    this.loadingService = new LoadingService()
    this._selectedColumns = this.cols;
    this.originColList = this.cols;
    this.getPermission(p);
  }

  initQueryData() {
    this.queryPannel = {
      loadingSwitch: false,
      ipts: [
        {
          title:TITLE.CHANNEL,
          name: "channel",
          value: this.toWareHouse,
          showValue: this.toChannelShowValue,
          type: INPUT_TYPE.TREESELECT,
          options: this.shopAndWarehouse,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "ToStatusNature",
        },
        {
          title: TITLE.RESERVATION_TYPE,
          name: "reservation_type_id",
          value: null,
          type: INPUT_TYPE.SELECT,
          optionValue: 'id',
          class: "p-col-12 p-md-4 p-lg-3",
          options: [],
        },
        {
          title: TITLE.RESERVATION_DATE,
          name: 'reserve_date',
          type: INPUT_TYPE.DATETIME,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: TITLE.ITEM,
          name: 'item_id',
          type: INPUT_TYPE.MUTIPLESELECT,
          options:"SKU",
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: TITLE.SOURCE_REF_NO,
          name: 'source_txn_ref_header_no',
          type: INPUT_TYPE.INPUT,
          options: [],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        // {
        //   title: TITLE.SCHOOL_CODE,
        //   name: 'school_code',
        //   type: INPUT_TYPE.INPUT,
        //   options: [],
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        // },
        // {
        //   title: TITLE.SCHOOL_LOC_CODE,
        //   name: 'school_loc_code',
        //   type: INPUT_TYPE.INPUT,
        //   options: [],
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        // },
        {
          title: TITLE.SCHOOL_NUMBER,
          name: 'sch_num',
          type: INPUT_TYPE.INPUT,
          options: [],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: TITLE.RESERVATION_ID,
          name: 'stock_reservation_id',
          type: INPUT_TYPE.INPUT,
          options: [],
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: TITLE.REMARKS,
          name: "remark",
          value: this.remark,
          type: INPUT_TYPE.TEXTAREA,
          class: "p-col-12 p-md-12 p-lg-6",
        },
      ],
      btnsclass:
        // "p-d-flex p-col-12 p-md-offset-4 p-md-4 p-lg-offset-3 p-lg-3 filterBtn p-text-right",
        "p-col-12 p-md-12 p-lg-6 filterBtn p-text-right",
      btns: [
        {
          category: "primary",
          icon: "",
          title: "Reset Filter",
          class: "p-button-outlined p-order-1 p-mr-1 p-mb-1",
          show: true,
          handler: {
            click: () => {
              // this.submit(this.data);
              this.resetFilter();
            },
          },
        },
        {
          category: "primary",
          icon: "",
          title: "Apply Filter",
          class: "p-order-1 p-mr-1 p-mb-1",
          show: true,
          handler: {
            click: () => {
              // this.submit(this.data);
              this.applyFilter();
            },
          },
        },
        {
          category: "primary",
          icon: "",
          title: "Cancel",
          class: "p-button-outlined p-order-1 p-mr-1 p-mb-1",
          show: true,
          handler: {
            click: () => {
              // this.submit(this.data);
              this.confirmCancel();
            },
          },
        },
        {
          title: "Reset",
          icon: "",
          show: true,
          class: "p-button-outlined p-order-1 p-mr-1 p-mb-1",
          handler: {
            click: () => {
              // this.refresh();
              // this.resetAllocationDataList();
              this.confirmReset();
            },
          },
        },
        {
          title: "Complete",
          class: "p-order-1 p-mr-1 p-mb-1",
          icon: "",
          show: true,
          handler: {
            click: () => {
              // this.back();
              this.confirmSubmit();
            },
          },
        },
      ],
    };

    //Reservation Type
    this.i18n.get('reservation').subscribe(res => {
      /* this.assignmentlogics = LocalStorageHelper.getObject("ASSIGNMENTLOGIC").map(item => {
        item.label = res[item.label]
        let {value: code, label: name, ...other} = item
        return {code, name, ...other}
      }) */
      let resstockreservationtypes = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").map(item => {
        item.label = res[item.label]
        let {stockReservationTypeCode: code, description: name, ...other} = item
        return {code, name, ...other}
      })
      this.queryPannel.ipts.find(item => item.title === TITLE.RESERVATION_TYPE).options = resstockreservationtypes
    })

    this.queryPannel.ipts.find(item => item.title === TITLE.ITEM).options = []
    this.stockCommonService.getItemListBySkuModule().subscribe(res=>{
      if(res){
        let ipt = this.queryPannel.ipts.find(ipt1=>ipt1.title==TITLE.ITEM);
        ipt.options = res;
      }
    })

  }

  ngOnDestroy(): void {
    LocalStorageHelper.remove("line");
  }
  @action getPermission(p) {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[p.snapshot.data.code];
    // console.log('>----------', p.snapshot.data.name, this.permission);
  }

  @computed get showU() {
    return true // this.permission.includes('')
  }
  ngOnInit(): void {
    this.orderTypes = LocalStorageHelper.getObject("ORDER_TYPE").map((item) => {
      let { value: code, label: name, ...other } = item;
      return { code, name, ...other };
    });
    this.channels = JSON.parse(localStorage.getItem("SHOP"));
    this.channels2 = JSON.parse(localStorage.getItem("WAREHOUSE"));
    this.sechannels = JSON.parse(localStorage.getItem("SHOP"));
    this.sechannels2 = JSON.parse(localStorage.getItem("SHOP"));
    this.reasons = LocalStorageHelper.getObject("REASONS");
    // this.items = LocalStorageHelper.getObject("ITEM");
    // this.seItems = JSON.parse(localStorage.getItem("ITEM"));
    this.orderStatus = LocalStorageHelper.getObject("ORDER_STATUS").map(
      (item) => {
        let { value: code, label: name, ...other } = item;
        return { code, name, ...other };
      }
    );
    this.orderStatusItem = this.orderStatus.find(
      (item) => item.alias === "Draft"
    ).code;
    this.status = JSON.parse(localStorage.getItem("STOCK_TYPE"));
    this.shopAndWarehouse = JSON.parse(localStorage.getItem("REPOTREE"));
    this.creationDate = new Date();
    // this.commonService.getAllReservationType().subscribe(res=>{
    //   this.reservationTypeList = res;
    // })
    this.p.queryParams.subscribe((params) => {
      if (
        // !params["orderNumber"]&&
        !params['headId']
      ) {
        // gentrannum if no current order number
        // this.orderNumber = CommonMethod.genTranNum(new Date(), "TF", "");
        // this.saveFlag = true; // set to true when no ordernumber in params to show button
        // this.proceedFlag = true;
        this.r.navigate(["main", "transfer_order", "search"]);
      } else {
        // this.isNew = false;
        this.initQueryData();
        this.setDisabled();
        if(params['headId']){
          this.stockCommonService.getOrderByHeadId(params['headId']).subscribe(res=>{
            if(res){
              this.loadData(res)
              this.initQueryData()
              this.setDisabled()
            }
          })
          forkJoin([
            this.commonService.getAllReservationType().pipe(tap(res=>{
              this.reservationTypeList = res;
            })),
            this.commonService.getAllStockAllocationType().pipe(tap(res=>{
              this.stockAllocationTypeList = res;
            })),
          ]).subscribe(res=>{
            this.loadItemAllocation(params['headId']);
          })
        }
        // if (params["orderNumber"]) {
        //   this.stockCommonService
        //     .getOrderByOrderNumber(params["orderNumber"])
        //     .subscribe((res) => {
        //       if (res) {
        //         this.loadData(res);
        //         this.initQueryData();
        //         this.setDisabled();
        //       }
        //     });
        //   this.getItemAllocation();
        // }
      }
    });
  }

  getType(v) {
    console.log(typeof v);
    return typeof v;
  }

  setDisabled() {}
  getQueryIptByLabel(name) {
    return this.queryPannel.ipts.find((item) => item.title === name);
  }
  setIptDisabled(obj) {}
  loadData(da) {
    console.log("da:", da);
    // this.isNew = false;
    // this.saveFlag = false;
    // this.proceedFlag = false;
    this.orderNumber = da.orderNumber;
    this.orderTypeItem = this.orderTypes.find(
      (item) => item.id === +da.orderTypeId
    )?.code; //Number(da.orderTypeId)
    this.selectedType = this.orderTypes.find(
      (item) => item.id === +da.orderTypeId
    )?.orderTypeCode;
    // this.setTableColBySelectedType(this.selectedType);
    this.orderTypeItemLabel = da.orderType;
    this.fromChannel = isNaN(Number.parseInt(da.fromChannelId))
      ? null
      : Number.parseInt(da.fromChannelId); // shopAndWarehouse>item>data is int
    let fromChannelObj = this.getConditionFromShopAndWarehouseById(
      this.fromChannel
    );
    this.fromChannelShowValue = fromChannelObj ? fromChannelObj.label : null;
    this.toWareHouse = isNaN(Number.parseInt(da.toChannelId))
      ? null
      : Number.parseInt(da.toChannelId); // shopAndWarehouse>item>data is int
    let toChannelObj = this.getConditionFromShopAndWarehouseById(
      this.toWareHouse
    );
    this.toChannelShowValue = toChannelObj ? toChannelObj.label : null;
    //this.orderStatusItem = this.orderStatus.find(item=>item.alias === da.orderStatus)//
    this.orderStatusItem = this.orderStatus.find(
      (item) => item.name.toUpperCase() === da.orderStatus
    )?.code;

    // this.loadFromNature({orderTypeId: this.orderTypeItem.code, fromChannelId: this.fromChannel}, URLDICT.STOCK_TRANSFER_GETFROMNATURE)
    // this.loadToNature({orderTypeId: this.orderTypeItem.code, toChannelId: this.toWareHouse}, URLDICT.STOCK_TRANSFER_GETTONATURE)
    // this.statusItem1 = {'name':da.fromStatusNature, 'code':da.fromConditionCode}
    this.statusItem1 = da.fromConditionCode
      ? +da.fromConditionCode
      : da.fromConditionCode;
    this.fromStatus = da.fromConditionCode
      ? [{ name: da.fromStatusNature, code: +da.fromConditionCode }]
      : [];
    // this.statusItem2 = {'name':da.toStatusNature, 'code':da.toConditionCode}
    this.statusItem2 = da.toConditionCode
      ? +da.toConditionCode
      : da.toConditionCode;
    this.toStatus = da.toConditionCode
      ? [{ name: da.toStatusNature, code: +da.toConditionCode }]
      : [];

    this.creationDate = new Date(+da.creationDate);

    this.deliveryNote = da.dmDnNuber;
    this.remark = da.remark;
    // this.lineData = da.lines;
    // this.items = LocalStorageHelper.getObject("ITEM");
    this.reasons = LocalStorageHelper.getObject("REASONS");

    // this.lineData.forEach((da) => {
    //   da.itemId = da.itemId;
    //   da.itemDesc = da.itemDesc;
    //   da.quantity = da.qty;
    // });

    Object.assign(this.data, da);
    // this.data["line"] = this.lineData;
  }

  toChannelLabel(v) {
    return CommonMethod.toSelectedLabel("REPO", v);
  }

  initOrderType() {
    this.commonService.httpPost(URLDICT.STOCK_TRANSFER_ORDERTYPE, {})
    // HttpHelper.post(URLDICT.STOCK_TRANSFER_ORDERTYPE, {})
    .then((res) => {
      if (res.code == "000" && res.data.length > 0) {
        this.orderTypes = [];
        res.data.forEach((element) => {
          this.orderTypes.push({
            value: element.id,
            label: element.description,
            flag: null,
            other: null,
            _data: element,
          });
        });
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.orderTypes = [];
      }
      console.log(this.orderTypes);
    });
  }

  /* search() {
    let data = {
      "orderTypeItem": this.orderTypeItem,
      "orderNumber": this.orderNumber,
      "orderStatusItem": this.orderStatusItem,
      "fromChannel": this.fromChannel,
      "toWareHouse": this.toWareHouse,
      "creationDate": this.creationDate,
      "statusItem1": this.statusItem1,
      "statusItem2": this.statusItem2,
      "deliveryNote": this.deliveryNote,
      "pageIndex": this.pageIndex,
      "pageSize": this.pageSize,
    }
    //console.log(data);
    this.loadingSwitch = true

  } */

  refresh() {
    const list = [
      "orderType",
      "from",
      "to",
      "fromStatusNature",
      "toStatusNature",
      "remark",
    ];
    this.queryPannel.ipts.forEach((item) => {
      if (list.includes(item.name)) {
        item.showValue = "";
        item.value = null;
      }
    });
    this.items = null;
    this.reasons = null;
    this.seItem = null;
    this.fromChannel = null;
    this.toWareHouse = null;
    this.orderTypeItem = null;
    // this.orderStatusItem = null
    this.statusItem1 = null;
    this.statusItem2 = null;
    // this.orderNumber = null
    // this.deliveryNote = null
    // this.btnLabel = this.btnLabel;
    this.setDisabled();
  }

  dealType(e) {
    // console.log("type:", e)
    // this.orderTypeItem = e;
    this.setDisabled();
    this.dealItem();
    this.dealToItem();
  }

  //根据channel联动sku数据
  dealItem() {
    if (this.orderTypeItem && this.fromChannel) {
      this.loadFromNaturePre(
        { orderTypeId: this.orderTypeItem, fromChannelId: this.fromChannel },
        URLDICT.STOCK_TRANSFER_GETFROMNATURE,
        this.fromChannel,
        this.fromNatures
      );
    }
  }

  dealToItem() {
    if (this.orderTypeItem && this.toWareHouse) {
      this.loadToNature(
        { orderTypeId: this.orderTypeItem, toChannelId: this.toWareHouse },
        URLDICT.STOCK_TRANSFER_GETTONATURE
      );
    }
  }

  loadFromNature(pa, url) {
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then((res) => {
      this.fromStatus = [];
      if (res.code == "000" && res.data.length > 0) {
        res.data.forEach((element) => {
          this.fromStatus.push({ value: element, label: element });
        });
      }
    });
    // console.log(this.fromNatures);
  }

  loadFromNaturePre(pa, url, channelId, conditionId) {
    console.log("loadSKU(fromChange)", channelId, this.statusItem1);
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then((res) => {
      this.fromStatus = [];
      if (res.code == "000" && res.data.length > 0) {
        this.fromNatures = res.data;
        //this.loadFromNaturePost(URLDICT.COMMON_SEARCH_SKUBYCHANNELID, {channelId: v, statusNatures: this.fromNatures})
        //sku only showed if channelid and conditionid are selected
        if (channelId && this.statusItem1)
          this.loadFromNaturePost(
            URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID,
            { channelId: channelId, conditionCode: this.statusItem1 }
          );
        res.data.forEach((element) => {
          this.fromStatus.push({
            code: element.code,
            description: element.description,
            value: element.code,
            name: element.description,
          });
        });
        const fromStatus = this.queryPannel.ipts.find(
          (item) => item.name === "fromStatusNature"
        );
        fromStatus.options = this.fromStatus;
        // console.log(this.fromStatus, fromStatus.options);
      }
    });
  }

  loadFromNaturePost(url, pa) {
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then((res) => {
      if (res.code == "000" && res.data.length > 0) {
        this.items = [];
        res.data.forEach((element) => {
          this.items.push({
            code: element.itemid,
            name: element.codedesc,
            flag: element.itemdesc,
            _data: element,
          });
        });
        // console.log("skus:" + this.skus)
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.showMessage(
          "warn",
          "System",
          "There is no sku in the current channel!"
        );
        this.items = [];
      }
    });
    // console.log(this.fromNatures);
  }

  loadToNature(pa, url) {
    this.toStatus = [];
    this.commonService.httpPost(url, pa)
    // HttpHelper.post(url, pa)
    .then((res) => {
      if (res.code == "000" && res.data.length > 0) {
        //sku only showed if channelid and conditionid are selected
        this.toStatus = [];
        res.data.forEach((element) => {
          this.toStatus.push({
            code: element.code,
            description: element.description,
            value: element.code,
            name: element.description,
          });
        });
      } else {
        this.toStatus = [];
      }
      const toStatus = this.queryPannel.ipts.find(
        (item) => item.name === "toStatusNature"
      );
      toStatus.options = this.toStatus;
    });
    // console.log(this.toStatus);
  }

  //根据item联动desc
  itemChange(e, data) {
    this.items.forEach((ele) => {
      if (ele.code == e.value) {
        console.log(ele);
        data.itemDesc = ele.flag; //this.itemDesc
        data.itemId = ele.code; //this.itemId
        // this.itemDesc = ele.flag
        // this.itemId = ele.code
        // this.selectedItems.push(ele)
      }
    });
    // data.itemDesc = this.itemDesc
    // data.itemId = this.itemId
    // console.log("skuDesc:" + this.skuDesc)
  }

  adjustmentItemChange(e, data, items) {
    items.forEach((ele) => {
      if (ele.code == e.value) {
        // console.log(ele);
        data.itemDesc = ele.flag; //this.itemDesc
        data.itemId = ele.code; //this.itemId
        // this.itemDesc = ele.flag
        // this.itemId = ele.code
        // this.selectedItems.push(ele)
      }
    });
    // data.itemDesc = this.itemDesc
    // data.itemId = this.itemId
    // console.log("skuDesc:" + this.skuDesc)
  }
  /* page(e) {
    console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    this.search()
  } */

  edit(d) {
    // console.log(this)
    this.items = JSON.parse(localStorage.getItem("SKU"));
    this.channels = JSON.parse(localStorage.getItem("SHOP"));
    this.channels2 = JSON.parse(localStorage.getItem("WAREHOUSE"));
    this.itemValue = d["itemId"];
    this.channelValue = d["fromChannel"] + "";
    this.channelValue2 = d["toWareHouse"] + "";
    this.qty = d["qty"];
    this.status = d["status"];
    if (d.id) {
      d.newid = d.id;
      delete d.id;
    }
    // console.log(d);
  }

  // cancel() {
  //   this.r.navigate(["main", "transfer_order", "search"]);
  // }

  // delete(data) {
  //   if (data.id) {
  //     this.m.confirm({
  //       message: `Do you want to delete the transaction record?`,
  //       header: "Delete Confirmation",
  //       accept: () => {
  //         HttpHelper.post(URLDICT.STOCK_RETURN_DELETE, data)
  //           .then((res) => {
  //             if (res.code == "000") {
  //               // this.search()
  //             } else {
  //               // this.n.error("System", "Submit Fail!")
  //               this.showMessage("error", "System", "Submit Fail!");
  //             }
  //           })
  //           .catch((e) => {
  //             // this.n.error("System", e.toString())
  //             this.showMessage("error", "System", e.toString());
  //           });
  //       },
  //     });
  //   }
  // }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    // console.log(this.selectedColumns)
    this._selectedColumns = this.cols.filter((col) => val.includes(col));
    this.selectedColumnsLength = this._selectedColumns.length;
    this.rowexpansionColspan = this.selectedColumnsLength + 2;
  }
  selectedColumnsLength = 3;
  rowexpansionColspan = 5;

  timestampToTime(timestamp) {
    return CommonMethod.timestampToTime(timestamp);
  }

  line = {};
  cloneLine = {};

  onRowEditInit(line) {
    this.cloneLine[line.id] = { ...line };
  }

  onRowEditSave(line) {
    // if (line.price > 0) {
    delete this.cloneLine[line.id];
    // this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
    // }
    // else {
    // this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
    // }
  }

  onRowEditCancel(line, index: number) {
    this.line[index] = this.cloneLine[line.id];
    delete this.line[line.id];
  }

  onRowDelete(index1, index2) {
    // console.log(index1, index2)
    // this.lineData[index1].serials.splice(index2, 1);
  }

  create() {}

  createLine(index) {}

  save(d = null, index=null) {
    // console.log("lineIndex:" + this.lineIndex)
    // let line: any[] = []
    // let serials: any[] = []
    // Object.assign(this.data, d)
    this.data["dmDnNumber"] = this.deliveryNote;
    this.data["orderNumber"] = this.orderNumber;
    this.data["orderTypeId"] = this.orderTypeItem;
    this.data["fromChannelId"] = this.fromChannel;
    this.data["toChannelId"] = this.toWareHouse;
    this.data["fromStatusNature"] = this.statusItem1;
    this.data["toStatusNature"] = this.statusItem2;
    this.data["orderStatusCode"] = this.orderStatusItem; //?.code // orderStatusItem already save as code
    this.data["remarks"] = this.remark;
    this.data["creationDate"] = this.creationDate;

    // this.data["line"] = this.lineData;
    console.log(this.data);

    // this.n.info("Transfer", "Save Successful")
    d && this.showMessage("info", "Transfer", "Save Successful");
  }
  validationData() {
    this.save();
    console.log("validationData: ", this.data);
    return true;
  }

  //提交数据
  submit(data) {
    // data = this.getSubmitData()
    if(this.loadingSwitch) return
    this.loadingSwitch++
    if(data){
      this.stockCommonService.updateStockAllocation(data).subscribe(res=>{
        this.loadingSwitch--
        this.showMessage('success', null, 'Save Success')
        const btn = this.queryPannel.btns.find(item=>item.title==="Complete")
        btn.disabled = false
        //保留在当前页面,重新查询数据
        this.loadItemAllocation(this.orderId);
        //重定向页面到order
        //this.r.navigate(["main", "transfer_order", "search"]);
      },err=>{
        this.loadingSwitch--
        const btn = this.queryPannel.btns.find(item=>item.title==="Complete")
        btn.disabled = false
        if(err[0]){
          this.showMessage('error', null, err[0])
          if(err[1]){
            let res = err[1];
            if(res.code == '400' && res.data && res.data.length>0){
              let data = res.data
              data.forEach(el => {
                if(el.error && el.error.message){
                  this.showMessage('error', null, el.error.message)
                  this.loadItemAllocation(this.orderId);
                }
              });
            }
          }
        }else{
          this.showMessage('error', null, err)
          this.loadItemAllocation(this.orderId);
        }
      })
    }
  }

  getSubmitData(){
    let data = []
    //只提交当前页的数据
    let pagefilteredList = this.filteredAllocationDataList

    pagefilteredList.forEach(allocationData => {
      let plannerType = this.stockAllocationTypeList.find(type=>type.value == allocationData.sdl_allocation_planner_type_id)
      let plannerTypeLabel = plannerType?plannerType.label:null;
      let plannerTypeRes = allocationData.sdl_allocation_planner_type_id;
      if(plannerTypeLabel !== "Manual Assignment" && allocationData.qty_plan_to_allocate!=allocationData.qty_adjust_allocate){
        let manulyType = this.stockAllocationTypeList.find(type=>type.label == "Manual Assignment");
        plannerTypeRes = manulyType?manulyType.value:plannerTypeRes;
      }
      data.push({
        "reservationId": allocationData.stock_reservation_id, // wip
        "plannedAllocationQty": allocationData.qty_plan_to_allocate,
        "adjAllocationQty": allocationData.qty_adjust_allocate==null?0:allocationData.qty_adjust_allocate,
        "allocationPlannerTypeId": plannerTypeRes
      })
    });
    return data
  }

  //更新状态
  update(data) {}
  customSort(e) {}
  /**
   * 返回上一页
   */
  @action back() {
    window.history.back();
  }
  showMessage(severity, summary, detail) {
    // console.error(`trace showMessage: ${severity} | ${summary} | ${detail}`)
    this.n.add({ severity, summary, detail });
  }

  setTableColBySelectedType(selectedType = this.selectedType) {}

  handleIptWithSelectedType(
    selectedType = this.selectedType,
    setDisabled = true
  ) {}

  onAdjustmentItemStatusConditionChange(e, data) {
    // call api /common/searchItemByChannelIdAndCondition, update row item options by result
  }

  clearTable() {}

  loadAdjustmentItemsByStatusCondition(fromChannel, stockConditionId) {}

  getConditionFromShopAndWarehouseById(
    id,
    list: any[] = this.shopAndWarehouse
  ) {
    if (id) {
      let target = null;
      list.forEach((child) => {
        if (child.data == id) {
          target = child;
        } else if (child.children && child.children.length > 0) {
          let res = this.getConditionFromShopAndWarehouseById(
            id,
            child.children
          );
          if (res) target = res;
        }
      });
      return target;
    } else {
      return null;
    }
  }
  loadItemAllocation(headId) {
    this.orderId = headId;
    this.stockCommonService
      .getSpecAllocationForReservationList(headId)
      .subscribe((res) => {
        if(res){
          this.allocationDataList = res.map((e) => {
            let _reservationObj = this.reservationTypeList?this.reservationTypeList.find(type=>type.id==e.reservation_type_id):null;
            let _allocationTypeObj = this.stockAllocationTypeList?this.stockAllocationTypeList.find(type=>type.value==e.sdl_allocation_planner_type_id):null;
            return {
              ...e,
              qty_adjust_allocate: e.qty_plan_to_allocate,
              reservationTypeDesc: _reservationObj?_reservationObj.description:'',
              stockAllocationTypeDesc: _allocationTypeObj?_allocationTypeObj.label:'',
              _data: e
            };
          });
          this._allocationDataListBuffer = JSON.parse(JSON.stringify(this.allocationDataList));
          //this.filteredAllocationDataList = this.allocationDataList
          this.pageFilter(this.allocationDataList);
        }else{
          // this.showMessage("error", "System", "Error");
        }
      });
  }

  //分页组件触发查询
  pageSpecAllocationForReservationList(e){
      this.pageIndex = e.first / e.rows;
      this.pageSize = e.rows;
      this.applyFilter();
  }

  resetAllocationDataList() {
    this.allocationDataList = JSON.parse(JSON.stringify(this._allocationDataListBuffer));
  }

  onTableValueChange(e, data, col) {}

  onTableValueInput(e, data, col) {}

  confirmCancel() {
    this.m.confirm({
      message: `Confirmation to cancel?`,
      header: "Cancel Confirmation",
      accept: () => {
        this.r.navigate(["main", "transfer_order", "search"]);
      },
    });
  }
  confirmReset() {
    this.m.confirm({
      message: `Confirmation to reset the adjusted allocation qty to planned allocation qty?`,
      header: "Reset Confirmation",
      accept: () => {
        this.resetAllocationDataList();
      },
    });
  }
  confirmSubmit() {
    let data = this.getSubmitData()
    // haveAdjValue default true if have value, show confirm
    let haveAdjValue = true
    try{
      // haveAdjValue set to false if all adjAllocationQty == 0
      haveAdjValue = data.some(_data=>_data.adjAllocationQty!=0)
    }catch(e){console.error(e)}
    if(haveAdjValue){
      this.m.confirm({
        message: `Confirmation to allocate stock to reservation?`,
        header: "Allocate Confirmation",
        accept: () => {
          const filteredData = data.filter(item => item.adjAllocationQty > 0);
          const btn = this.queryPannel.btns.find(item=>item.title==="Complete")
          btn.disabled = true
          this.submit(filteredData);
        },
      });
    }else{
      this.m.confirm({
        message: `No changes made, please confirm.`,
        header: "Allocate Confirmation",
        accept: () => {
        },
      });
    }
  }
  sortSearch(data) {}
  editDetail(data) {}
  cancel(data, rowIndex) {}

  // filter
  resetFilter(){
    this.queryPannel.ipts.forEach(ipt => {
      if(ipt.title!=TITLE.CHANNEL && ipt.value!=null && ipt.value.length!=0){
        switch(ipt.title){
          default:
            ipt.value = null;
            break;
        }
      }
    });
    //this.filteredAllocationDataList = this.allocationDataList;
    this.pageFilter(this.allocationDataList);
  }
  applyFilter(){
    this.filteredAllocationDataList = [];
    let _filteredList = [];
    let filter = {}
    this.queryPannel.ipts.forEach(ipt => {
      if(ipt.title!=TITLE.CHANNEL && ipt.value!=null && ipt.value.length!=0){
        switch(ipt.title){
          case TITLE.ITEM:
            filter[ipt.name] = ipt.value.map(val=>val.code)
            break;
          case TITLE.RESERVATION_DATE:
            filter[ipt.name] = ipt.value.getTime()
            break;
          default:
            filter[ipt.name] = ipt.value
            break;
        }
      }
    });
    _filteredList = this.allocationDataList.filter((data)=>{
      let valid = true;
      Object.keys(filter).forEach((key)=>{
        switch(key){
          case 'item_id':
            if(filter[key].indexOf(data[key])==-1) {
              valid = false;
              // return true;
            }
            break;
          case 'reserve_date':
            let timeDiff = (data[key] - filter[key])
            if(timeDiff < 0 || timeDiff > 1*24*60*60*1000){
              valid = false;
              // return true;
            }
            break;
          default:
            if(filter[key]!=data[key]){
              valid = false;
              // return true;
            }
            break;
        }
        // return false
      })
      return valid;
    })
    //this.filteredAllocationDataList = _filteredList;
    this.pageFilter(_filteredList);
  }

  //根据分页参数生成分页数据
  pageFilter(list){
    let startIndex = this.pageIndex * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    let pagefilteredList = list.slice(startIndex,endIndex)
    this.totalRecords = list.length
    this.filteredAllocationDataList = pagefilteredList;
  }
  // filter end
}
