import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {makeAutoObservable} from "mobx";
import {ConfirmationService, MessageService} from "primeng/api";
import {LocalStorageHelper} from "src/app/util/LocalStorageHelper";
import {INPUT_TYPE, URLDICT} from "../../base/BaseStore";
import {HttpHelper} from "src/app/util/HttpHelper";
import { TranslateService } from "@ngx-translate/core";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { StockReservationService } from "src/app/service/stock/stock-reservation.service";
import { CommonService } from "src/app/service/common/common-service";
import { switchMap, tap } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { CommonMethod } from "@/util/CommonMethod";
import { FilterCommonMethod } from "@/util/FilterCommonMethod";

const QUERYTITLE = {
  TYPE: 'Reservation Type',
  ID: 'Reservation ID',
  SOURCESYSTEM: 'Source System',
  CHANNEL: 'Stock Channel',
  RESERVEDFOR: 'Reserved For',
  RESERVEDBY: 'Reserved By',
  REASON: 'Reason',
  CREATIONDATE: 'Reservation Creation Date',
  ITEM: 'Item Code',
  REQUESTQTY: 'Request Reserve Qty',
  REMARK: 'Remark (Please do not enter any personal information)',
  STAFFIDORNAME: 'Staff ID / Name',
}
@Injectable()
export class CreateStore {
  queryData = {
    ipts: [
      {
        title: QUERYTITLE.TYPE,
        name: "Reservation Type",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        required: true,
        options: []/*,
        iptionLabel: 'name',
        iptionValue: 'code', */
      },
      {
        title: QUERYTITLE.ID,
        name: "Reservation ID",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: QUERYTITLE.SOURCESYSTEM,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: "CSTK",
        // required: true,
        disabled: true
      },
      {
        title: QUERYTITLE.CHANNEL,
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: null,
        showValue: null,
        options: [],
        required: true
      },
      {
        title: QUERYTITLE.RESERVEDBY,
        placeholder: QUERYTITLE.STAFFIDORNAME,
        name: "reservedBy",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: false
      },
      {
        title: QUERYTITLE.RESERVEDFOR,
        name: "reservedFor",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: false
      },
      {
        title: QUERYTITLE.REASON,
        name: "Reason",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        required: true,
        // subset: true,
        // subsetClass: "p-d-none p-d-md-none p-d-lg-block p-md-3"
      },
      {
        title: QUERYTITLE.CREATIONDATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: new Date(), // new Date => `${Y}-${M}-${D}` => Date format
      },
      // {
      //   title: "Source Reference No",
      //   name: 'orderId',
      //   type: INPUT_TYPE.INPUT,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: null,
      //   disabled: true,
      //   subset: true,
      //   subsetClass: "p-d-none p-d-md-block p-d-lg-none p-md-8"
      // },
      // {
      //   title: "Description",
      //   type: INPUT_TYPE.INPUT,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: null,
      // },
      {
        title: QUERYTITLE.ITEM,
        name: 'item',
        placeholder: 'Choose Item',
        value: null,
        type: INPUT_TYPE.SELECT,
        options: [],
        class: "p-col-12 p-md-4 p-lg-3",
        required: true
      },
      {
        title: QUERYTITLE.REQUESTQTY,
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        required: true,
        subset: true,
        subsetClass: "p-d-none p-d-md-none p-d-lg-block p-md-3"
      },
      // {
      //   title: "Expiry Date",
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: "",
      // },
      // {
      //   title: "No.of Extension",
      //   name: "noOfExtension",
      //   type: INPUT_TYPE.INPUTNUMBER,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: 0,
      //   disabled: true
      // },
      {
        title: QUERYTITLE.REMARK,
        name: "remark",
        value: '',
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-12 p-lg-6",
      },
    ],
    btns: [{
      title: 'Back',
      class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
      show: true,
      handler: {click: () => this.back()}
    },{
      title: 'Clear',
      class: "p-button-outlined p-mr-1",
      show: true,
      handler: {click: () => this.clear()}
    }, {
      title: 'Create',
      class: "p-order-3 p-mr-1",
      permissionType: 'c',
      show: true,
      disabled: false,
      handler: {click: () => this.createResearvation()}
    }, {
      title: 'Create & Allocate',
      class: "p-order-3 p-mr-1",
      permissionType: 'c',
      show: true,
      disabled: false,
      handler: {click: () => this.createAndAllocateResearvation()}
    }],
    btnsclass: 'p-d-flex p-col-12',
    change: (idx, value, data) => {
      console.log('---- change ----', idx, value, data);
      console.log(this.queryData.ipts.find(item => item.title === QUERYTITLE.CHANNEL))
      // if(data.title === "Stock Channel"){
      //   this.getItemByChannelIdAndConditionId(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, value.id)
      // }
      if(data.title == "Reservation Type"){
        // let reservedForIpt = this.queryData.ipts.find(ipt=>ipt.title == "Reserved For");
        // let selectedType = data.options.find(opt=>opt.id == data.value);
        // if(selectedType){
        //   reservedForIpt.disabled = selectedType.name !== "CORP Customer";
        // }else{
        //   reservedForIpt.disabled = true
        // }
        // if(reservedForIpt.disabled){reservedForIpt.value = null}
      }
    }
  }
  tableData = {
    btns: [],
    data: [],
    showCheckboxSwitch: false,
    loadingSwitch: false,
    head: [
      {key: 'itemCode', title: 'Item Code', width: "10%"},
      {key: 'itemDesc', title: 'Item Desc', width: "75%"},
      {key: 'normal', title: 'Normal', width: "5%", edit: true},
      {key: 'faulty', title: 'Faulty', width: "5%", edit: true},
      // {key:'total',title:'Total', width:"5%"}
    ],
  }
  items: any[] = []
  releaseChannel
  selectRequest = null
  searchAll = true
  requestDialog = false
  defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  permission: number
  channels: any[] = []
  channels2: any[] = []
  skus: any[] = []
  assignmentLogic
  reservationType
  orderId
  balance
  reservationStatus
  reason = []
  public selectedChannel
  public selectedChannelDisplay

  _selectedColumns: any[];

  dialogTitle = ""
  info = {
    title: 'Reservation Create',
    selectType: "Please select a Reservation Type!",
    selectItem: "Please select a Item!",
    selectChannel: "Please select a Channel!",
    selectSoureceSystem: "SoureceSystem Required",
    selectReqResQty: "Request Reserve Qty Required!",
    selectReqResQtyRequirePositive: "Request Reserve Qty must be greater than 0!",
    reasonReq: "Reason Required!",
    createSuccess: 'Data created successfully!',
    createFail: 'Failed to create data!',
    allocateTitle: 'Allocation',
    allocateSuccess: 'Allocation Success!',
    allocateFail: 'Allocation Failed!',
  }

  constructor(private route: ActivatedRoute, public r: Router,private msg: MessageService, private conf: ConfirmationService,
    private i18n: TranslateService,
    private stockCommonService: StockCommonService,
    private stockReservationService: StockReservationService,
    private commonService: CommonService,
    private datePipe: DatePipe,
  ) {
    this.channels = FilterCommonMethod.getRepoTreeForFilterInput();
    this.initDefaultChannel()
    makeAutoObservable(this)
    this.getPermission(this.route)
    this.initQueryData()
  }

  get showU() {
    return (this.permission & this.defaultPermission.u) === this.defaultPermission.u
  }

  get showC() {
    return (this.permission & this.defaultPermission.c) === this.defaultPermission.c
  }

  get showD() {
    return (this.permission & this.defaultPermission.d) === this.defaultPermission.d
  }

  convertDate(d) {
    var timeZone: number = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date: Date = new Date(parseInt(d) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }

  getPermission(p) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
  }

  initDefaultChannel(){
    try{
    let channel = CommonMethod.getDefaultChannel(this.channels)
    if(channel){ this.selectedChannel = [channel];
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
    }else{
      this.selectedChannel = null;
      this.selectedChannelDisplay = null;
    }
    }catch(e){
      console.error(e)
    }
  }

  initQueryData() {
    let repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE')
    // const reqChanelOpts = this.queryData.ipts.find(item => item.title === 'Request Channel')
    const relChanelOpts = this.queryData.ipts.find(item => item.title === QUERYTITLE.CHANNEL)
    const itemList = this.queryData.ipts.find((item) => item.title == QUERYTITLE.ITEM);
    //Channel
    // reqChanelOpts.options = repoList
    repoList = repoList.filter(child=>child.key.toLowerCase()!='warehouse').filter(repo=>repo.children.length)
    relChanelOpts.options = repoList
    relChanelOpts.showValue=this.selectedChannelDisplay
    relChanelOpts.value=this.selectedChannel
    //Reason
    const reasonPanel = this.queryData.ipts.find(item => item.title === QUERYTITLE.REASON)
    reasonPanel.options = LocalStorageHelper.getObject('RESERVATION_REASON').map(
      (item) => {
        let{code: id, name: description, ...other} = item
        return {code: item.id, name: item.description, ...other}
      }
    )

    //Reservation Type
    const type = this.queryData.ipts.find(item => item.title === QUERYTITLE.TYPE)
    type.options = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").filter((item)=> item.isManualEntryAllowed === "Y").map(
      (item) => {
        let{code: id, name: description, ...other} = item
        return {code: item.id, name: item.description, ...other}
    })
    if(type.options.length == 1){
      type.value = type.options[0].code
    }

    this.getItemListBySkuModule().subscribe(res=>{
      if(res){
        itemList.options = res
      }
    })

    // let channelIpt = this.queryData.ipts.find(item => item.title === QUERYTITLE.CHANNEL)
    // if(channelIpt.value!=null){
    // channelIpt.value = this.commonService.getDefaultChannel(channelIpt.options)
    // channelIpt.showValue = channelIpt.value.key
    // }else{
    //   channelIpt.value = ''
    //   channelIpt.showValue = ''
    // }
    /* this.queryData.ipts[2].options = LocalStorageHelper.getObject("RESERVATION_STATUS").map(
      (item) => {
        let {value: code, label: name, ...other} = item
        return {code, name, ...other}
      }
    ) */

    // const reservationStatus = this.queryData.ipts.find(item => item.title === 'Reservation Status')
    // reservationStatus.options = LocalStorageHelper.getObject("RESERVATION_STATUS").map(
    //   (item) => {
    //     let {value: code, label: name, ...other} = item
    //     return {code, name, ...other}
    //   }
    // )
    /* this.i18n.get('reservation').subscribe(res => {
      // const logic = this.queryData.ipts.find(item => item.title === 'Assignment Logic')
      // logic.options = LocalStorageHelper.getObject("ASSIGNMENTLOGIC").map(item => {
      //   item.label = res[item.label]
      //   let {value: code, label: name, ...other} = item
      //   return {code, name, ...other}
      // })

      const type = this.queryData.ipts.find(item => item.title === 'Reservation Type')
      //console.log(LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE"))
      type.options = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").map(item => {
         *//* item.label = res[item.label]
        let {value: code, label: name, ...other} = item
        return {code, name, ...other} *//*
        let{code: id, name: description, ...other} = item
        console.log({code: item.id, name: item.description, ...other})
        return {code: id, name: item.description, ...other}
      })
    }) */
    this.channels2 = JSON.parse(localStorage.getItem("REPOTREE"))
  }

  clear(){
    this.queryData.ipts.forEach(ipt=>{
      let _clearQuery = [
        QUERYTITLE.TYPE,
        QUERYTITLE.RESERVEDFOR,
        QUERYTITLE.REASON,
        QUERYTITLE.ITEM,
        QUERYTITLE.REQUESTQTY,
        QUERYTITLE.REMARK,
        QUERYTITLE.CHANNEL
      ]
      if(_clearQuery.indexOf(ipt.title)>-1){
        ipt.value = null
      }
    })
  }

  validationData() {
    let flag = true
    this.queryData.ipts.forEach(ipt => {
      if (ipt.title === QUERYTITLE.TYPE && (!ipt.value || ipt.value.length === 0)) {
        this.showMessage('warn', this.info.title, this.info.selectType)
        flag = false
      }
      if (ipt.title === QUERYTITLE.ITEM && (!ipt.value || ipt.value.length === 0)) {
        this.showMessage('warn', this.info.title, this.info.selectItem)
        flag = false
      }
      if (ipt.title === QUERYTITLE.SOURCESYSTEM && !ipt.value ) {
        this.showMessage('warn', this.info.title, this.info.selectSoureceSystem)
        flag = false
      }
      if (ipt.title === QUERYTITLE.CHANNEL && (!ipt.value || ipt.value.length === 0)){
        this.showMessage('warn', this.info.title, this.info.selectChannel)
        flag = false
      }
      if (ipt.title === QUERYTITLE.REQUESTQTY){
        if(ipt.value==null){
          this.showMessage('warn', this.info.title, this.info.selectReqResQty)
          flag = false
        }else if(ipt.value <= 0){
          this.showMessage('warn', this.info.title, this.info.selectReqResQtyRequirePositive)
          flag = false
        }
      }
      if (ipt.title === QUERYTITLE.REASON){
        if(ipt.value==null){
          this.showMessage('warn', this.info.title, this.info.reasonReq)
          flag = false
        }
      }

    })
    return flag
  }

  getApiData(){


    const parmOrderDate = new Date((this.queryData.ipts.find(item => item.title === QUERYTITLE.CREATIONDATE).value)).getTime()
    const parmSourceSystem = this.queryData.ipts.find(item => item.title === QUERYTITLE.SOURCESYSTEM).value
    // const parmSourceRefNo = this.queryData.ipts.find(item => item.title === 'Source Reference No').value
    const parmReservationTypeId = this.queryData.ipts.find(item => item.title === QUERYTITLE.TYPE).value
    const parmReservedFor = this.queryData.ipts.find(item => item.title === QUERYTITLE.RESERVEDFOR).value
    const parmReason = this.queryData.ipts.find(item => item.title === QUERYTITLE.REASON).value
    const parmItem = this.queryData.ipts.find(item => item.title === QUERYTITLE.ITEM).value
    // const parmDescription = this.queryData.ipts.find(item => item.title === 'Description')?this.queryData.ipts.find(item => item.title === 'Description').value:null
    const parmQty = this.queryData.ipts.find(item => item.title === QUERYTITLE.REQUESTQTY).value
    // const parmExpiryDate = new Date((this.queryData.ipts.find(item => item.title === 'Expiry Date').value)).getTime()
    const parmReleaseChannel = this.queryData.ipts.find(item => item.title === QUERYTITLE.CHANNEL).value.id
    // const parmNoOfExtension = this.queryData.ipts.find(item => item.name = 'noOfExtension').value
    const parmRemark = this.queryData.ipts.find(item => item.title === QUERYTITLE.REMARK).value
    const reservedBy = this.queryData.ipts.find(item => item.title === QUERYTITLE.RESERVEDBY).value

    let data = {
      "reserveDate": parmOrderDate,
      "sourceSystem": parmSourceSystem,
      "sourceRefNo": null, //parmSourceRefNo,
      "reservationTypeId": parmReservationTypeId,
      // "reservedFor":null, //parmReservedFor,
      "reserveDescription": parmReservedFor,
      "udlReasonId": parmReason,
      "itemId": parmItem,
      // "reserveDescription": parmDescription,
      "qtyRequested": parmQty,
      // "autoReleaseDate": parmExpiryDate,
      "reserveChannelId": parmReleaseChannel,
      "extensionCount": 0, //default 0 when create
      "sensitiveRemarks": parmRemark,
      "reservedBy": reservedBy,
    }

    return data;
  }

  createResearvation() {
    if (!this.validationData()) {
      return
    }

    let data = this.getApiData();
    this.setQueryButtonDisabled(true);
    this.stockReservationService.createReservation(data).subscribe(res=>{
      if(res){
        this.showMessage('success', this.info.title, this.info.createSuccess + ` Reservation ID: ${res.data[0].id}`)
        this.r.navigate(["main", 'stock_reservation', 'search']);
      }else{
        if(res.message){
          this.showMessage('error', this.info.title, this.info.createFail + ` ${res.message}`)
        }else{
          this.showMessage('error', this.info.title, this.info.createFail)
        }
        this.setQueryButtonDisabled(false);
      }
      this.tableData.loadingSwitch = false
    }, err=>{
      this.showMessage('error', this.info.title, err)
      this.setQueryButtonDisabled(false);
      this.tableData.loadingSwitch = false
    })
  }

  createAndAllocateResearvation() {
    if (!this.validationData()) {
      return
    }
    let data = this.getApiData();
    this.setQueryButtonDisabled(true);
    this.stockReservationService.createReservation(data).pipe(
      tap(res=>{
        if(res&&res.code == '000'){
          if(res.data && res.data.length==1 && res.data[0].id!=null){
            let reservationId = res.data[0].id;
            this.showMessage('success', this.info.title, this.info.createSuccess + ` Reservation ID: ${reservationId}`)
          }else{
            this.showMessage('success', this.info.title, this.info.createSuccess)
          }
        }else{
          if(res.message){
            this.showMessage('error', this.info.title, this.info.createFail + ` ${res.message}`)
          }else{
            this.showMessage('error', this.info.title, this.info.createFail)
          }
          throw {code: 'create', msg: res.message}
        }
      }),
      switchMap(res=>{
        if(res&&res.data&&res.data.length==1){
          let allocateData = res.data[0]
          allocateData.qtyAllocated = allocateData.qtyPending;
          allocateData.qtyPending = 0;
          return this.stockReservationService.saveReservationQtysUpdate(allocateData)
        }
      }),
      tap(res=>{
        if(res&&res.code=='000'){
          if(res.data && res.data.length==1 && res.data[0].autoReleaseDate!=null){
            this.showMessage('success', this.info.allocateTitle, this.info.allocateSuccess + ` Expiry Date: ${this.datePipe.transform(res.data[0].autoReleaseDate, 'yyyy/MM/dd')}`)
          }else{
            this.showMessage('success', this.info.allocateTitle, this.info.allocateSuccess)
          }
        }else{
          if(res.msg){
            this.showMessage('error', this.info.allocateTitle, this.info.allocateFail + ` ${res.msg}`)
          }else{
            this.showMessage('error', this.info.allocateTitle, this.info.allocateFail)
          }
          throw {code: 'allocate', msg: res.msg}
        }
      })
    )
    .subscribe(res=>{
      this.r.navigate(["main", 'stock_reservation', 'search']);
      this.tableData.loadingSwitch = false
    }, err=>{
      if(err.code && ['create', 'allocate'].includes(err.code)){
        if(err.code == 'allocate'){
          // create success, allocate failed, route to search
          this.r.navigate(["main", 'stock_reservation', 'search']);
        }
      }else{
        this.showMessage('error', this.info.title, err)
      }
      this.setQueryButtonDisabled(false);
      this.tableData.loadingSwitch = false
    })
  }

  getItemByChannelIdAndConditionId(url, channelId){
    // deprecated, item options should be all items instead of item already in channel
    // if (channelId){
    //   const data = {channelId: channelId, conditionCode: "1001"}  //default FG now
    //   HttpHelper.post(url, data).then(res => {
    //     if(res.code == '000' && res.data.length > 0){
    //       this.items = []
    //       res.data.forEach(element => {
    //         this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc})
    //       });
    //       const itemList = this.queryData.ipts.find((item) => item.name == "item");
    //       itemList.options = this.items;
    //     } else {
    //       // this.n.warning("System", "There is no sku in the current channel!")
    //       this.showMessage('warn','System', 'There is no sku in the current channel!')
    //       this.items = []
    //     }
    //   })
    // }
  }

  back() {
    window.history.back()
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  getItemListBySkuModule(){
    return this.stockCommonService.getItemListBySkuModule()
  }

  setQueryButtonDisabled(disabled){
    this.queryData.btns.forEach(btn=>{
      btn.disabled = disabled
    })
  }
}
