// import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @description: 树形选择组件
 * @author: 陈述承
 */
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { Tree } from 'primeng/tree';
@Component({
  selector: 'app-multipleTreeSelect-cst',
  templateUrl: './treeselect-multiple-cst.component.html',
  styleUrls: ['./treeselect-multiple-cst.component.scss'],
  animations: [
    trigger("overlayAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "scaleY(0.8)" }),
        animate("{{showTransitionParams}}"),
      ]),
      transition(":leave", [
        animate("{{hideTransitionParams}}", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TreeSelectMultipleCustom implements OnInit, OnDestroy {
  isSelect: boolean = false
  isOpen: boolean = false
  outside$: Subscription = null
  // files: TreeNode[];
  level1= []
  @Input() ipt
  @Output() change = new EventEmitter()

  @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input() hideTransitionOptions: string = '.1s linear';

  @ViewChild('treeWrapper') treeWrapper: ElementRef<HTMLElement>;

  constructor(@Inject(DOCUMENT) private doc: Document, private el: ElementRef) { }

  ngOnInit(): void {
    if(!this.ipt.options[0]?.children) return
    this.level1 = this.ipt.options[0].children.map(item=>item.label)
    this.outside$ = fromEvent(this.doc, 'click').pipe(pluck('target')).subscribe(el=>{
      let isShow = this.el.nativeElement.contains(el);
      if (!isShow) {
        this.isOpen = false
      }
    })
  }

  ngOnDestroy(): void {
    this.outside$ && this.outside$.unsubscribe()
  }

  clearSelectData() {
    this.isSelect = false
    this.ipt.value = null
    this.ipt.showValue = null
    this.change.emit(null)
  }

  openSelectPanel() {
    if (!this.ipt.disabled) {
      this.isOpen = !this.isOpen;
    }
  }
  setSelectChannel(){
    const nameList = this.ipt.value.map(item =>item.label)
    let tempList = []
    //如果选择的是all，直接返回all
    if(nameList.includes('ALL')) {
      tempList.push({label:'ALL', id: 0})
      return tempList
    }
    //判断是否选择了1级的channel （群组）
    let pidList = []
    this.level1.forEach(item => {
      if (nameList.includes(item)) {
        tempList.push(item)
        const el = this.ipt.value.find((sub:any) => sub.label === item)
        pidList.push(el['id'])
      }
    })
    //提取1级的channel名称和单独的channel名称
    let selList = this.ipt.value.map((item:any) => {
      if (!pidList.includes(item.pid)) {
        return item
      }
    })
    //排除空值
    selList = selList.filter(item => item)
    return selList
  }
  nodeSelect(event) {
    const list = this.setSelectChannel()
    this.ipt.error = null
    // this.ipt.value = event.node
    this.ipt.showValue = list.map(item=>item.label).join(',') //event.node.label
    this.ipt.selValue = list.map(item=>item.id)
    this.isSelect = true
    // this.isOpen = false
    this.change.emit(this.ipt.value)
  }

  nodeUnselect(event) {
    const list = this.setSelectChannel()
    this.ipt.showValue = list.map(item=>item.label).join(',')
    this.ipt.selValue = list.map(item=>item.id)
    this.change.emit(this.ipt.value)
  }
  
  onKeydown(event: KeyboardEvent) {
    switch (event.code) {
      //down
      case "ArrowDown":
        if (!this.isOpen && event.altKey) {
          this.isOpen = true;
          event.preventDefault();
        }
        break;

      //space
      case "Space":
        if (!this.isOpen) {
          this.isOpen = true;
          event.preventDefault();
        }
        break;

      //escape
      case "Escape":
        this.isOpen = false;
        break;
    }
  }

  onOverlayAnimationDone(e){
    if(e.toState == "visible"){
      (<HTMLInputElement>(<HTMLElement>this.treeWrapper?.nativeElement)?.querySelector('input.p-tree-filter'))?.focus();
    }
  }
  onOverlayAnimationStart(e){
  }
}
