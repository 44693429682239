import { Directive, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';

@Directive({
  selector: '[appResizeSensor]'
})
export class ResizeSensorDirective {

  private resizeSensor: ResizeSensor;
  @Input() appResizeSensor: boolean = true; // set true to target elRef.nativeElement.parentElement
  @Output() onResize = new EventEmitter();

  constructor(
    public elRef: ElementRef<HTMLElement>,
  ) { 
  }

  onSizeChanged(e){
    this.onResize.emit(e)
  }

  ngOnChanges(changes){
    if(changes['appResizeSensor']){
      // keep triggering onsizechanged before popup panel animation end, 
      // skip init in ngonchanges and moved to ngafterviewinit and add 200 delay
      // init call before ngAfterViewInit, onSizeChanged trigger after ngAfterViewInit
      // ngAfterViewInit this.elRef.nativeElement.parentElement.clientHeight get 477
      // onSizeChanged after ngAfterViewInit get 3xx (random xx)
      
      // this.init(); 
    }
  }

  init(useParent = this.appResizeSensor){
     // settimeout to prevent resizesensor emit event before ui init complete (primeng p-dialog render animation)
    setTimeout(()=>{
      if (this.resizeSensor) {
        this.resizeSensor.detach();
      }
      if (ResizeSensor) {
        let el = this.elRef.nativeElement
        if(useParent){
          el = el.parentElement
        }
        this.resizeSensor = new ResizeSensor(el, (e) => this.onSizeChanged(e));
      }
    }, 200)
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      if(this.appResizeSensor){
        this.onResize.emit({
          height: this.elRef.nativeElement.parentElement.clientHeight,
          width: this.elRef.nativeElement.parentElement.clientWidth
        })
      }else{
        this.onResize.emit({
          height: this.elRef.nativeElement.clientHeight,
          width: this.elRef.nativeElement.clientWidth
        })
      }
      this.init();
    })
  }

  ngOnDestroy() {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
  }

}
