export class InjectHelper{
    static start(obj){
        return {
            n:obj["n"],
            r:obj["r"],
            p:obj["p"],
            m:obj["m"],
            oauthService:obj["oauthService"],
            commonStore: obj['commonStore'],
        }
    }
}
