import { Directive, ElementRef, HostBinding, HostListener, NgZone } from '@angular/core';

@Directive({
  selector: 'p-table[p-table-custom]',
})
export class PTableCustomDirective {
  // @HostListener('window:resize', ['$event'])
  onResize(event?) {
    try{
      // generate element width(100% - ?px) into px and store as css variable for p-table rowexpansion sticky div width
      let scrollElement = this.getScrollElement()
      if(scrollElement) scrollElement.style.setProperty('--p-table-custom-inner-width', scrollElement.clientWidth + 'px')
      // if((scrollElement.offsetHeight - scrollElement.clientHeight)>0)this.scrollbarHeight = (scrollElement.offsetHeight - scrollElement.clientHeight) + 'px';

    }catch(e){console.error(e)}
  }
  
  resizeDetector: ResizeDetector;
  // @HostBinding('style.--cusScrollbarHeight') scrollbarHeight;
  
  constructor(
    public elRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    let that = this
    this.onResize();
    this.resizeDetector = new ResizeDetector(this.getScrollElement(), ()=>{that.onResize()})
  }

  getScrollElement(){
    let tableElement = (<HTMLElement>this.elRef.nativeElement.getElementsByClassName("p-datatable-scrollable-view p-datatable-unfrozen-view")[0])
    if (tableElement == null) tableElement = (<HTMLElement>this.elRef.nativeElement.getElementsByClassName("p-datatable-scrollable-view")[0])
    if(tableElement != null){
      let scrollElement = (<HTMLElement>tableElement.getElementsByClassName("p-datatable-scrollable-body")[0])
      return scrollElement
    }else{
      return null
    }
  }

  ngOnDestroy(){
    this.resizeDetector.disconnect()
  }
}

class ResizeDetector {
  prevWidth
  observer;
  constructor(element, callback){
    this.observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect?.width;
        if (typeof width === 'number' && width !== this.prevWidth) {
          this.prevWidth = width;
          callback()
        }
      }
    });
    this.observer.observe(element);
  }

  disconnect(){
    this.observer.disconnect()
  }
}
