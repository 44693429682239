import { observable, action } from 'mobx';
import {BaseStore, NOTI_TITLE, INPUT_TYPE} from './BaseStore';
import { InputPannelStore } from './InputPannelStore'

/**
 * BaseEditStore
 */

export class BaseEditStore extends BaseStore {

    @observable inputPannel;

    constructor(ip, params) {

        super(params)
        this.inputPannel = new InputPannelStore({ipts:this.init_ipts(ip.ipts),btns:this.init_btns(ip.btns),collapse:ip.collapse||[], btnsclass:ip.btnsclass})

    }

    /**
     * 清除输入值
     * @param url
     */
    @action clear() {
        this.inputPannel.clear()
    }
    /**
     * 修改数据
     * @param url
     */
    @action edit(url,u,m) {
        this.checkWhenSubmit();
        if(!this.isSubmitAvailable()) return
        //获取格式化json数据
        var data = this.inputPannel.format()
        let data1=this.$PARAMS.queryParams.value
        Object.assign(data,{createBy:data1['createBy'],createAt:data1['createTime']})
        this.inputPannel.setBtnProperty(0, "loading", true)
        //API交互
        return this.$HTTP.post(url, data)
            .then(res => {
              if(res.code=='000'){
                this.back()
              }else{
                this.$NOTICE.error(NOTI_TITLE,"Submit Failed")
              }
            })
            .catch(err => {
                this.$NOTICE.error(NOTI_TITLE, err)
            })
            .finally(() => {
                this.inputPannel.setBtnProperty(0, "loading", false)
              if(u != null){
                this.loadToCache(u,m)
              }
            })
    }
    /**
     * 返回上一页
     */
    @action back() {
        window.history.back()
    }

    /**
   * 必输项校验和正则校验
   * @param store
   */
    @action isSubmitAvailable() {
        return this.inputPannel.isSubmitAvailable()
    }

  @action attrExpland() {
    let data=this.$PARAMS.queryParams.value
    this.inputPannel.ipts.forEach((i,idx)=>{
      if(i.type == INPUT_TYPE.SELECTWITHDESC){
        this.inputPannel.openDes(i,data['verId'],idx-1)
      }
      if(i.type == INPUT_TYPE.SELECTWITHTABLEMUTI){
        i.tableMultiData=JSON.parse(data.tableMultiData)
        i.tableData = i.tableMultiData.tableData
      }
    })


  }

  /**
   * 判断是否有子数据，如果有，编辑时则不能编辑父类
   */
  @action ifChildren(){
    let arr:any[] =this.$PARAMS.queryParams.value
    if(arr["children"]){
      this.inputPannel.ipts.forEach(ipt=>{
        if (ipt.name == 'parentClassId') {
          ipt.type = INPUT_TYPE.INPUT
          ipt.disabled = true
        }
      })
    }
  }

    /**
   * 动态表单提交必填验证
   */
  private checkWhenSubmit() {
    this.inputPannel.required(this.inputPannel.ipts);
    this.inputPannel.ipts.forEach(ipt => {
      if (ipt.type == 14) {
        ipt.ipts.forEach(arr=>{
          this.inputPannel.required(arr);
        })
      }
    });
  }

}
