import { SELECTEDTYPE } from '@/pages/stock/transfer_order/StockOrderShare';
import { SearchFieldRecordor } from '@/service/common/search-record';
import { LocalStorageHelper } from '@/util/LocalStorageHelper';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent,  } from '@angular/router';
import { action } from 'mobx';
import { Paginator } from 'primeng/paginator';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CommonStore } from 'src/app/service/common/common-store-service';
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { CommonMethod } from 'src/app/util/CommonMethod';
import { BaseSearchStore } from '../../base/BaseSearchStore'
import { INPUT_TYPE, BUTTON_TYPE, URLDICT, NOTI_TITLE } from '../../base/BaseStore'
import { FilterCommonMethod } from '@/util/FilterCommonMethod';
import { DatePipe } from '@angular/common';

export class SearchStore extends BaseSearchStore {

  isSortSearch;

  routerSub: Subscription[];
  restoredStateId;
  routePath;
  stockCommonService: StockCommonService;
  defaultField

  sourceHeaderNoLinkOrderTypeCodeList
  searchFieldRecordor: SearchFieldRecordor;

  public commonService
  public channels
  public selectedChannel
  public selectedChannelDisplay

  cdRef: ChangeDetectorRef;
  forceSearch: boolean;
  public datepipe: DatePipe;

  constructor(params) {
    let queryPannel = {
      ipts: [
        {
          title: "Transaction Type", // for display, can be chinese or english
          name: "orderType",  // for database colume
          nickName: "orderTypeId",
          value: null,
          defaultValue: '',
          type: INPUT_TYPE.SELECT,
          options: "ORDER_TYPE",
          optionLabel: 'name',
          optionValue: 'code',
          linkage: "All",
          // colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
          // addtionOption: {value: [""], other: "", label: "ALL"}
        },
        {
          title: "Stock Order Status",
          name: "orderStatusItem",
          nickName: "orderStatusCode",
          value: null,
          // defaultValue: '',
          type: INPUT_TYPE.SELECT,
          options: 'ORDER_STATUS',
          // options: [],
          optionLabel: 'name',
          // optionValue: 'name',
          // colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: "Stock Order No.",
          name: 'orderNumber',
          nickName: "orderNumber",
          value: "",
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT
        },
        {
          title: "Creation Date",
          name: 'creationDate',
          nickName: "createAt",
          // placeholder: 'Choose Creation Date',
          value: null,
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          showButtonBar: true,
          todayButtonStyleClass: 'cus-opacity-0',
          type: INPUT_TYPE.DATERANGE
        }, {
          title: "From",
          name: "from",
          nickName: "fromChannelId",
          value: null,
          // placeholder: 'Choose From',
          type: INPUT_TYPE.TREESELECT,
          options: 'REPOTREE_ACTIVE',
          // colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "FromStatusNature",
          // exclude: 'ALL' // options里需要移除的项
        },
        // {
        //   title: "From Status Condition",
        //   name: "fromStatusNature",
        //   nickName: "fromStatusNature",
        //   value: null,
        //   type: INPUT_TYPE.SELECT,
        //   options: [],
        //   colspan: 7,
        //   class: "p-col-12 p-md-4 p-lg-3",
        // },
        {
          title: "To",
          name: "to",
          nickName: "toChannelId",
          value: null,
          // placeholder: 'Choose To',
          type: INPUT_TYPE.TREESELECT,
          options: 'REPOTREE_ACTIVE',
          // colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "ToStatusNature",
          // exclude: 'ALL' // options里需要移除的项
        },
        // {
        //   title: "To Status Condition",
        //   name: "toStatusNature",
        //   nickName: "toStatusNature",
        //   value: null,
        //   type: INPUT_TYPE.SELECT,
        //   options: [],
        //   colspan: 7,
        //   class: "p-col-12 p-md-4 p-lg-3",
        // },
        //   title: "Delivery Note",
        //   name: 'deliveryNote',
        //   nickName: "dmDnNumber",
        //   value: "",
        //   defaultValue: '!!',
        //   colspan: 10,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   type: INPUT_TYPE.INPUTSCAN
        // }, {
        {
          title: "Source Ref No.",
          name: 'sourceTxnRefHeaderNo',
          nickName: "sourceTxnRefHeaderNo",
          value: "",
          defaultValue: '!!',
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUTSCAN
        },
        {
          title: "Replenish Reference",
          name: 'replenishReference',
          nickName: "replenishReference",
          value: "",
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT
        },
        {
          title: "ISO No.",
          name: 'isoNo',
          nickName: "isoNo",
          value: "",
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT
        },
        {
          title: "Delivery Batch No.",
          name: 'deliveryBatchNo',
          nickName: "deliveryBatchNo",
          value: "",
          // colspan: 10,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT
        },
        {
          title: "Item Code", // for display, can be chinese or english
          name: "skuNum",  // for database colume
          placeholder: 'Item',
          value: [],
          defaultValue: '',
          dataKey: 'code',
          skipAllCheckMatch: true,
          // multiselect-cus-lg-right set position right 0 when view width > lg, please find in style.css file
          class: "p-col-12 p-md-4 p-lg-6 multiselect-cus-col multiselect-cus-lg-right multiselect-cus-md multiselect-cus",
          // dataKey: 'code',
          type: INPUT_TYPE.MUTIPLESELECT,
          options: "SKUMODULEBYUAM",
          // appendTo: 'body',
          colspan: 7,
          beforeSubmit: (e, ipt)=>{
            let res = e
            if(e) res = e.map(val=>{
              let sku = ipt.options.find(opt=>opt.code==val.code)
              return {id: val.code, code: sku.name.split("~")[0]}
            });
            return res
          }
        },
      ],
      btns: [
        {
          type: BUTTON_TYPE.BUTTON,
          title: 'Clear',
          class: "p-button-outlined p-ml-auto p-mr-1",
          icon: '',
          show: true,
          handler: {
            'click': () => {
              this.queryPannel.clear();
              // this.sortPageSearch(this.$URLDICT.STOCK_TRANSFER_SORTORDERINFO, this.field)
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          icon: '',
          title: 'Create',
          class: "p-mr-1",
          permissionType: 'c',
          show: true,
          handler: {
            "click": () => {
              this.create(["main", 'transfer_order', 'create'])
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          icon: '',
          title: 'Export',
          class: "p-mr-1",
          permissionType: 'c',
          show: true,
          handler: {
            "click": () => {
              this.export()
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          icon: '',
          title: 'Export Details',
          class: "p-mr-1",
          permissionType: 'c',
          show: true,
          handler: {
            "click": () => {
              this.exportAllOrderDetails()
            }
          }
        }, 
        {
          type: BUTTON_TYPE.BUTTON,
          icon: 'search',
          title: 'Search',
          show: true,
          class: "",
          handler: {
            "click": () => {
              this.searchFromFilter()
              // this.sortPageSearch(this.$URLDICT.STOCK_TRANSFER_SORTORDERINFO, this.field)
            }
          }
        },
      ],
      btnsclass: 'p-d-flex p-col-12  p-lg-12',
    };
    let tablePannel = {
      type: 'normal',
      btns: [],
      data: [],
      head: [
        {key: 'orderType', title: 'Transaction Type', width: '200px'},
        {key: 'orderNumber', title: 'Stock Order No.', width: '140px'},
        {key: 'from', title: 'From', width: '120px'},
        // {key: 'fromStatusNature', title: 'From Status Condition', width: '150px'},
        {key: 'to', title: 'To', width: '120px'},
        // {key: 'toStatusNature', title: 'To Status Condition', width: '150px'},
        {key: 'status', title: 'Stock Order Status', width: '160px'},
        //{key: 'skuId', title: 'item Code', width: '160px'},
        {key: 'sourceRefNo', title: 'Source Ref No.', width: '260px', type:'link', isClickableKey:'_sourceRefNoLink', onClick:($e,data)=>{$e.stopPropagation();this.onDnRefNoClick(data)}},
        {key: 'replenishReference', title: 'Replenish Reference', width: '180px'},
        {key: 'isoNo', title: 'ISO No.', width: '160px'},
        {key: 'deliveryBatchNo', title: 'Delivery Batch No.', width: '180px'},
        {key: 'creationDate', title: 'Creation Date', width: '140px', type: 'date'},
        {key: 'createBy', title: 'Create By', width: '140px'},
        {key: 'update_at', title: 'Update Date', width: '140px', type: 'date'},
        {key: 'updateBy', title: 'Update By', width: '140px'},
        
      ],
      // frozenColumns: [],
      lineCols: [
        {header: 'serialNumber', title: 'Serial'},
      ],
      // frozenWidth: null,
      // detailUrl:[],
      detailUrl: ["main", "transfer_order", "create"],
      // showCheckboxSwitch: false,
      sortSearchUrl: URLDICT.STOCK_TRANSFER_SORTORDERINFO
    };
    super(queryPannel, tablePannel, params);

    this.channels = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
    this.initDefaultChannel()
    this.defaultField = 'creationDate'
    this.field = 'creationDate'
    this.order = -1
    this.routerSub = [];
    this.restoredStateId = null
    this.routePath = null
    this.sourceHeaderNoLinkOrderTypeCodeList=[
      // 'CHANNEL-TRANSFER',
      // 'CHANNEL-TRANSFER-COURIER',
      // 'REPLENISHMENT',
      // 'RETURN',
      // 'WAREHOUSE-HOMED',
      // 'CHANNEL-HOMED',
      // 'CHANNEL-HOMED-RETURN',
      // 'WAREHOUSE-HOMED-RETURN'
      SELECTEDTYPE.CHANNELTRANSFER,
      SELECTEDTYPE.CHANNELTRANSFERCOURIER,
      SELECTEDTYPE.ASSORTMENT,
      SELECTEDTYPE.RETURN,
      // SELECTEDTYPE.WAREHOUSEHOMED,
      // SELECTEDTYPE.CHANNELHOMED,
      SELECTEDTYPE.RETURNWOCOURIER,
      SELECTEDTYPE.NOPREPLENISHMENT,
    ]

    let statusIpt = this.queryPannel.ipts.find(ipt=>ipt.name == 'orderStatusItem')
    statusIpt.options = this.genStatusOptions(['ORDER_STATUS', 'ORDER_ADJUSTMENT_STATUS'])
      .filter(item=>!item.name.includes('(deprecated)'));

    // this.getSnapshotData()
    this.searchFieldRecordor = new SearchFieldRecordor(this.$COMMONSTORE, this.$ROUTER, this.$PARAMS, this.$CONFIG.SEARCHPARAMSKEY.STOCKORDER)
    this.initRouterEvent()

  }

  initDefaultChannel(){
    try{
    let channel = CommonMethod.getDefaultChannel(this.channels)
    if(channel){ this.selectedChannel = [channel];
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
    }else{
      this.selectedChannel = null;
      this.selectedChannelDisplay = null;
    }
    }catch(e){
      console.error(e)
    }
  }

  genStatusOptions(keys:string[]){
    // LocalStorageHelper.getObject()
    let res = [];
    let resDesc = [];
    keys.forEach(key=>{
      let store = LocalStorageHelper.getObject(key)
      store.forEach(status => {
        if(resDesc.indexOf(status.label)==-1){
          res.push({
            name: status.label,
            code: status.value,
            flag: status.flag,
            alias: status.label,
            // alias: status.alias,
            // ...status
          })
          resDesc.push(status.label)
        }
      });
    })
    return res
  }

  onDnRefNoClick(e){
    this.tablePannel.setLoading(true);
    this.stockCommonService.getDmDeliveryDoc({orderId:e.headId}).subscribe(res=>{
      if(res.code=='000'){
        // let blob = this.base64toBlob(res.data[0].fileBase64)
        // this.saveData(blob,`DM_Delivery_Note_${e.sourceRefNo}.pdf`)
        let blob = CommonMethod.base64toBlob(res.data[0].fileBase64)
        CommonMethod.saveBlobData(blob,`DM_Delivery_Note_${e.sourceRefNo}.pdf`)
        // window.open(url);
      }else{
        this.showMessage('error', NOTI_TITLE, res.message || res.msg)
      }
    },err=>{
      this.showMessage('error', NOTI_TITLE, 'An Unexpected Error Occurred')
    },()=>{
      this.tablePannel.setLoading(false);
    })
  }

  @action openDet(data) {
    // this.$HTTP.post(URLDICT.STOCK_TRANSFER_LOADTOSTOCKIN, data).then(res => {
    //   if (res.code === '000' && res.data.length > 0) {
    //     LocalStorageHelper.setObject("line", res.data[0].lines)
    //     this.$ROUTER.navigate(["main", "transfer_order", "create"], {queryParams: res.data[0]});
    //   }
    //   // this.loadingSwitch = false
    // })
    // this.$COMMONSTORE.setObject('transfer_order_search_params', this.searchParams)
    this.$ROUTER.navigate(["main", "transfer_order", "create"], {queryParams: {headId: data.headId}});
  }
  getQueryDataByName(name){
    return this.queryPannel.ipts.find(ipt => ipt.name === name)
  }
  getSnapshotData(params) {
    // const data = this.$PARAMS.snapshot.queryParams
    const data = params;
    // if(JSON.stringify(data) !== '{}') {
      const orderType = this.getQueryDataByName('orderType')
      const orderStatus = this.getQueryDataByName('orderStatusItem')
      //type赋值
      const typeList1 = orderType.options
      const typeList2 = orderStatus.options
      if(data.orderTypeId){
        let _type = typeList1.find(item => item.id === +data.orderTypeId)
        if(_type){orderType.value = _type.id}else{orderType.value = null}
      }else{
        orderType.value = null
      }

      if(data.orderStatusCode){
        let _status = typeList2.find(item => item.alias === data.orderStatusCode)
        if(_status){orderStatus.value = _status.code} else {orderStatus.value = null}
      }else{
        orderStatus.value = null
      }

      let _orderNumberIpt = this.getQueryDataByName('orderNumber')
      if(_orderNumberIpt){
        if(data.orderNumber){
          _orderNumberIpt.value = data.orderNumber
        }else{
          _orderNumberIpt.value = null
        }
      }

      let _createAtIpt = this.getQueryDataByName('creationDate');
      if(data.createAt && data.createAt[0] && data.createAt[1]){
        _createAtIpt.value = [new Date(data.createAt[0]), new Date(data.createAt[1])]
      }else{
        _createAtIpt.value = null
      }

      let _fromIpt = this.getQueryDataByName('from')
      let _from = CommonMethod.findInChildren(_fromIpt.options, 'id', Number.parseInt(data.fromChannelId), 'children')
      if(data.fromChannelId){
        if(_from){
          _fromIpt.value = _from;
          _fromIpt.showValue = _fromIpt.value.label;
        }else{
          if(Object.keys(params).length==0&&sessionStorage.getItem('NOTICE_MESSAGE')==null){
            _fromIpt.value = this.selectedChannel[0].id;
            _fromIpt.showValue = this.selectedChannelDisplay;
          }
        }
      }else{
        if(Object.keys(params).length==0&&sessionStorage.getItem('NOTICE_MESSAGE')==null){
          if(this.selectedChannel)_fromIpt.value = this.selectedChannel[0].id;
          _fromIpt.showValue = this.selectedChannelDisplay;
        }
      }

      let _toIpt = this.getQueryDataByName('to')
      let _to = CommonMethod.findInChildren(_toIpt.options, 'id', Number.parseInt(data.toChannelId), 'children')
      if(data.toChannelId){
        if(_to){
          _toIpt.value = _to;
          _toIpt.showValue = _toIpt.value.label;
        }else{
          _toIpt.value = null;
          _toIpt.showValue = null;
        }
      }else{
        _toIpt.value = null;
        _toIpt.showValue = null;
      }

      let _sourceTxnRefHeaderNoIpt = this.getQueryDataByName('sourceTxnRefHeaderNo')
      if(_sourceTxnRefHeaderNoIpt){
        if(data.sourceTxnRefHeaderNo){
          _sourceTxnRefHeaderNoIpt.value = data.sourceTxnRefHeaderNo
        }else{
          _sourceTxnRefHeaderNoIpt.value = null
        }
      }
      let _ItemCodeIpt = this.getQueryDataByName('skuNum')
      if(data.skuNum){
        // _ItemCodeIpt.value = _ItemCodeIpt.options.find(option=>option.code==data.skuNum.)
        let ids = data.skuNum.map(sku=>sku.id)
        _ItemCodeIpt.value = _ItemCodeIpt.options.filter(option=>ids.indexOf(option.code)>=0)
      }else{
        _ItemCodeIpt.value = null
      }
      if(data.pageSize){
        this.pageSize = Number.parseInt(data.pageSize)
        // if(this.paginator) this.paginator.changeTableSize(this.pageSize)
      }
      if(data.pageIndex){
        // this.pageIndex = Number.parseInt(data.pageIndex)
        // if(this.paginator) this.paginator.changePage(Number.parseInt(data.pageIndex))
      }
      if(data.sortEvent){
        let sortEvent = data.sortEvent
        this.field = sortEvent.field
        this.order = sortEvent.order
      }

    // }
    // this.sortSearch('creationDate')
    // this.searchFromFilter(this.pageIndex)
  }
  searchFromFilter(page=0) {
    let isValid = this.validation();
    if(!isValid) return
    this.resetSearch = true
    if(this.paginator){
      this.forceSearch = true;
      this.paginator.changePage(page);
    }else{
      this.sortPageSearch(this.sortSearchUrl, this.field).then((res)=>{
        this.changeQuery(this.searchParams)
      })
    }
    // this.paginator ? (this.forceSearch = true; this.paginator.changePage(page)) : this.sortPageSearch(this.sortSearchUrl, this.field).then((res)=>{
    //   this.changeQuery(this.searchParams)
    // })
  }
  page(e) {
    let isValid = this.validation();
    if(!isValid) return
    let pageIndex = e.first / e.rows;
    // no page change, don search again
    // this could trigger by page loaded, params pageIndex trigger paginator.pageChange to change ui showing page
    // search first to get total record amt, use total amt then paginator can know page count
    // then able to call page change to change ui
    // add this checking to prevent search 2 times
    // this also stop search again by click same page
    if(!this.forceSearch && this.pageSize != 0 &&this.pageSize == e.rows && this.pageIndex == pageIndex) return
    this.forceSearch = false;
    this.pageIndex = pageIndex;
    this.pageSize = e.rows;
    let data: any = {...this.searchParams};
    data.pageIndex = this.pageIndex;
    data.pageSize = this.pageSize;
    if(this.field==null){this.field = this.defaultField}
    this.isSortSearch = true;
    if (this.isSortSearch) this.sortPageSearch(this.sortSearchUrl, this.field).then((res)=>{
      this.changeQuery(this.searchParams)
    });
    else this.getDataFromServer(this.$URLDICT.STOCK_TRANSFER_ORDERINFO, 0, data).then()
  }
  convertData(data){
    // if(data.skuNum){
    //   data.skuNum = data.skuNum.map(item =>{return item.code});

    //   const skuNum = data.skuNum.map(item => {
    //     const skus = LocalStorageHelper.getObject('SKU')
    //     const sku = skus.find(sub => sub.code === item)
    //     sku.name = sku.name.split(" ~ ")[0]
    //     const {code:id, name:code} = sku
    //     return {id, code}
    //   });
    //   data.skuNum = skuNum
    // }

    let _data = data
    if(data.orderStatusCode) data.orderStatusCode = this.queryPannel.ipts.find(ipt=>ipt.name=='orderStatusItem').options.find(item => item.code === data.orderStatusCode).alias;
    if(this.getQueryDataByName('creationDate').value!=null && this.getQueryDataByName('creationDate').value.length == 2){
      let _createAtIpt = this.getQueryDataByName('creationDate')
      let _day = CommonMethod.sameDayAdd(_createAtIpt.value)
      if(!_day){
        _data.createAt = null
      }else{
        let createAt = [];
        createAt[0] = CommonMethod.dateToTime(_day[0]) + ' 00:00:00'
        createAt[1] = CommonMethod.dateToTime(_day[1]) + ' 23:59:59'
        _data.createAt = createAt
      }
    }else{
      _data.createAt = null
    }
    return _data
  }

  validation() {
    let flag = true;
    let _createDate = this.getQueryDataByName('creationDate').value;
    // if(
    //   (_createDate!=null && _createDate.length!=2) ||
    //   (_createDate!=null && _createDate.length == 2 && (_createDate[0]==null || _createDate[1]==null))
    // ){
    //   this.showMessage('warn','System', 'Creation Date is Invalid, Please Select Date Range')
    //   flag = false;
    // }
    return flag
  }

  changeQuery(e) {
    // this.$ROUTER.navigate([], { relativeTo: this.$PARAMS, queryParams: e });
  }

  initRouterEvent(){
    this.searchFieldRecordor.setRouterEventForSearchParams(this.$ROUTER)
    .subscribe(res=>{
      this.getSnapshotData(res)
    })
  }

  onDestroy(){
    if(this.searchFieldRecordor)this.searchFieldRecordor.onDestory()
  }

  preProcessData(data){
    data.forEach(e => {
      // e._sourceRefNoLink = (e.sourceSystemId == 6)
      e._sourceRefNoLink = (this.sourceHeaderNoLinkOrderTypeCodeList.indexOf(e.orderTypeName)>-1)
    });
    return data
  }

  sortPageSearch(url,sortKey,index=0) {
    if (!this.isSubmitAvailable()) return
    //判断之前是否有请求未响应
    if (this.executeWait()) return
    let data = this.queryPannel.format()
    data = this.convertData(data)
    let _data = {...data}
    // data.createAt = new Date(data.createAt)
    data.pageIndex = url.includes('notice') ? this.pageIndex + 1: this.pageIndex
    data.pageSize = this.pageSize
    data['sortEvent'] = {field:this.field,order:this.order}
    data.accountName = this.$COMMONSTORE.getAccount();

    // -----------
    // 获取从notice传递的数据
    if(url.includes('stock_transfer_order')) {
      let noticeMsg = JSON.parse(sessionStorage.getItem('NOTICE_MESSAGE'))
      if(noticeMsg) {
        data.orderNumber = noticeMsg.orderNumber;
        const ipt = this.queryPannel.ipts.find(ipt=>ipt.title === 'Stock Order No.');
        ipt.value = data.orderNumber
        sessionStorage.removeItem('NOTICE_MESSAGE')
      }
    }
    // -----------

    this.tablePannel.setLoading(true);
    // 注意 notice 传递的是字符串
    if(data.eventType && !url.includes('notice')){
      data.eventType = data.eventType[0]
    }
    // console.log('sort search params:', data);
    return this.$HTTP.post(url, data)
      .then(res => {
        if (res.code === '000') {
          let data = this.preProcessData(res.data);
          this.tablePannel.setData(data)
          // console.log("SORTSEARCHDATA");
          // console.log(res);
          this.tablePannel.mapOfCheckedId = {}
          this.tablePannel.sortField = this.field
          this.tablePannel.sortOrder = this.order
          //   this.tablePannel.mapOfChildrenCheckedId = {}
          this.tablePannel.allCheckBoxValue = false
          this.convertDateToTable(res)
          this.setTreeDate(res)
          /* this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
          this.tablePannel.data = this.tablePannel.display_data */
          if(res.msg !== '') {
            this.totalRecords = res.msg
          }
        } else {
          this.showMessage('error', NOTI_TITLE, res.message || res.msg)
        }
      })
      .catch(err => {
        // console.log(err);
        // this.$NOTICE.error(NOTI_TITLE, err)
        this.showMessage('error', NOTI_TITLE, err)
      })
      .finally(() => {
        this.queryPannel.setBtnProperty(index, "loading", false)
        this.tablePannel.setLoading(false)
        // let _data = this.queryPannel.format()
        this.searchParams = {..._data}
        this.lastSuccessSearchPayload = data

        this.searchFieldRecordor.storeSearchParams(
          data,
          this.queryPannel.ipts,
        )
      })
  }

  afterViewInit(){
    let data = this.searchFieldRecordor.getRouteParams()
    this.pageIndex = Number.parseInt(data.pageIndex)||0
/*    this.sortPageSearch(this.sortSearchUrl, this.field).then((res)=>{
      if(this.cdRef) this.cdRef.detectChanges()
      if(this.paginator) this.paginator.changePage(Number.parseInt(data.pageIndex))
    })*/
    // }else{
    //   this.paginator.changePage(Number.parseInt(data.pageIndex))
    // }
  }

  @action sortSearch(key, order){
    this.isSortSearch=true
    this.field = key
    if(order!=null){
      this.order = order
    }else if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    return this.sortPageSearch(this.sortSearchUrl,key)
  }

  export(){
    let data = {
      ...this.lastSuccessSearchPayload,
      pageSize: this.$CONFIG.XLSX_ROW_LIMIT - 1
    }
    this.stockCommonService.getOrder(data).subscribe(res=>{
      let data = res
      let col = this.tablePannel._selectedColumns.map(_col=>{return {
        field: _col.key,
        title: _col.title,
        type: _col.type,
      }})
      CommonMethod.downloadXlsx(data, col, `Stock_Order_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`, this.datepipe)
    })
  }

  setLoading(e){
    this.tablePannel.setLoading(e)
  }

  exportAllOrderDetails(){
    this.setLoading(true)
    let data = {
      ...this.lastSuccessSearchPayload,
      pageSize: this.$CONFIG.XLSX_ROW_LIMIT - 1
    }
    this.stockCommonService.getOrder(data).pipe(
      map(res=>{
        return res.map(e=>e.headId)
      }),
      switchMap(idList=>{
        return forkJoin([
          this.stockCommonService.getOrderDetailToExcel(idList),
          this.stockCommonService.getOrderItemsToExcel(idList),
          this.stockCommonService.getOrderSerialsToExcel(idList),
        ])
      }),
      map(([details, items, serials])=>{
        return {details: details, items: items, serials: serials}
      })
    )
    .subscribe(res=>{
      this.setLoading(false)
      let detailCols = [
        {key: 'transactiontype', title: 'Transaction Type'},
        {key: 'orderstatus', title: 'Stock Order Status'},
        {key: 'ordernumber', title: 'Stock Order No.'},
        {key: 'creationdate', title: 'Creation Date', type: 'date'},
        {key: 'fromchannel', title: 'From'},
        {key: 'fromstatuscondition', title: 'From Status Condition'},
        {key: 'tochannel', title: 'To'},
        {key: 'tostatuscondition', title: 'To Status Condition'},
        {key: 'sourcerefno', title: 'Source Ref No.'},
        {key: 'remark', title: 'Remark'},
        {key: 'replenishreference', title: 'Replenish Reference'},
        {key: 'isono', title: 'ISO No.'},
        {key: 'deliverybatchno', title: 'Delivery Batch No.'},
      ]
      let itemCols = [
        {key: 'transactiontype', title: 'Transaction Type'},
        {key: 'orderstatus', title: 'Stock Order Status'},
        {key: 'ordernumber', title: 'Stock Order No.'},
        {key: 'creationdate', title: 'Creation Date', type: 'date'},
        {key: 'fromchannel', title: 'From'},
        {key: 'fromstatuscondition', title: 'From Status Condition'},
        {key: 'tochannel', title: 'To'},
        {key: 'tostatuscondition', title: 'To Status Condition'},
        {key: 'sourcerefno', title: 'Source Ref No.'},
        {key: 'remark', title: 'Remark'},
        {key: 'replenishreference', title: 'Replenish Reference'},
        {key: 'isono', title: 'ISO No.'},
        {key: 'deliverybatchno', title: 'Delivery Batch No.'},
        {key: 'itemcode', title: 'Item Code'},
        {key: 'itemdesc', title: 'Item Description'},
        {key: 'qty', title: 'Qty'},
        {key: 'qtyactual', title: 'Received Qty'},
        {key: 'qtycancelled', title: 'Cancelled Qty'},
        {key: 'qtywriteoff', title: 'Write-Off Qty'},
        {key: 'reason', title: 'Reason'},
        {key: 'finalreason', title: 'Final Reason'},
        {key: 'linereplenishreference', title: 'Item Replenish Reference'},
        {key: 'lineisono', title: 'Item ISO No.'},
        {key: 'linedeliverybatchno', title: 'Item Delivery Batch No.'},
      ]
      let serialCols = [
        {key: 'transactiontype', title: 'Transaction Type'},
        {key: 'orderstatus', title: 'Stock Order Status'},
        {key: 'ordernumber', title: 'Stock Order No.'},
        {key: 'creationdate', title: 'Creation Date', type: 'date'},
        {key: 'fromchannel', title: 'From'},
        {key: 'fromstatuscondition', title: 'From Status Condition'},
        {key: 'tochannel', title: 'To'},
        {key: 'tostatuscondition', title: 'To Status Condition'},
        {key: 'sourcerefno', title: 'Source Ref No.'},
        {key: 'remark', title: 'Remark'},
        {key: 'replenishreference', title: 'Replenish Reference'},
        {key: 'isono', title: 'ISO No.'},
        {key: 'deliverybatchno', title: 'Delivery Batch No.'},
        {key: 'itemcode', title: 'Item Code'},
        {key: 'itemdesc', title: 'Item Description'},
        {key: 'qty', title: 'Qty'},
        {key: 'qtyactual', title: 'Received Qty'},
        {key: 'qtycancelled', title: 'Cancelled Qty'},
        {key: 'qtywriteoff', title: 'Write-Off Qty'},
        {key: 'reason', title: 'Reason'},
        {key: 'finalreason', title: 'Final Reason'},
        {key: 'linereplenishreference', title: 'Item Replenish Reference'},
        {key: 'lineisono', title: 'Item ISO No.'},
        {key: 'linedeliverybatchno', title: 'Item Delivery Batch No.'},
        {key: 'serial', title: 'Serial'},
        {key: 'isreceived', title: 'Serial Received'},
        {key: 'iscancelled', title: 'Serial Cancelled'},
        {key: 'iswriteoff', title: 'Serial Write Off'},
      ]
      CommonMethod.downloadXlsxWithMultiSheetWithOptions(
        [res.details, res.items, res.serials],
        [detailCols, itemCols, serialCols],
        ['Stock Order Details', 'Stock Order Items', 'Stock Order Item Serials'],
        `Stock_Order_Details_${( this.datepipe.transform(new Date(), 'yyyyMMddHHmmss'))}`,
        this.datepipe,
        [{fieldKey: 'key'},{fieldKey: 'key'},{fieldKey: 'key'}]
      )
    }, err=>{
      this.setLoading(false);
      this.showMessage('error', NOTI_TITLE, err)
    })
  }
}
