import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-group',
  template: `
  <span class="p-float-label">
    <span class="cus-required-label" *ngIf="ipt.required">*</span>
    <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" [showClear]="true" [filter]="true" [class]="{'p-dropdown-sm':true,'ng-invalid':ipt.error,'ng-dirty':ipt.error}"
      [options]="ipt.options" [(ngModel)]="ipt.value" [group]="true" [disabled]="ipt.disabled" (onChange)="onChange($event)">
      <ng-template let-group pTemplate="group">
        <div class="p-d-flex p-ai-center">
          <span>{{group.label}}</span>
        </div>
      </ng-template>
    </p-dropdown>
    <label for="dropdown">{{ipt.title}}</label>
  </span>
  `,
  styles: []
})
export class SelectGroupComponent {
  @Input() ipt
  @Output() change = new EventEmitter()

  onChange(e){
    // console.log('change', e);
    this.change.emit(e.value)
  }
}
