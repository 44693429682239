import { action, observable, toJS } from 'mobx';
import {ModalPannelStore} from "./ModalPannelStore";
// import {Input} from "@angular/core";
import { TreeNode } from 'primeng/api';
/**
 *  class for Table pannel
 */
export class TablePannelStore{

  constructor(tp) {
    ////////////////////////
    /// 默认表格 类型为normal，故type/treeKey可以不传数据
    /// 树状表格 类型为tree，故type/treeKey必须传入
    ///////////////////////
    this.modalPannel = new ModalPannelStore(tp)
    this.btns = tp.btns||[]                 //按钮组
    this.head = tp.head||[]                 //表头
    this.loadingSwitch = false       //是否开启加载图标
    this.showCheckboxFlag = tp.showCheckboxSwitch==undefined?true:false  //是否开启checkbox
    this.setData(tp.data||[])  // 处理数据源
    this.type = tp.type||'normal'  // normal for normal-table;tree for tree-table
    this.domain = tp.domain||'search' //search for search table pannel;product for product table pannel;verify for verification table pannel etc.
    this.treeKey = tp.treeKey||[] //排序键
    this.detailKey = tp.detailKey
    this.showColumn = tp.showColumn

    this._selectedColumns = this.head
    this.lineCols = tp.lineCols || []
    this.frozenColumns = tp.frozenColumns || null
    this.frozenWidth = tp.frozenWidth || null
  }

  /**
   * 选中数据记录ID
   */
  @observable mapOfCheckedId: { [key: string]: boolean } = {};

  @observable modalPannel: ModalPannelStore;
  //
  @observable showCheckboxSwitch = false
  //
  @observable allCheckBoxValue = false

  // table loading status or not
  @observable loadingSwitch

  // table head
  @observable head = []

  @observable btns = []

  @observable showColumn = {}

  // original data
  @observable data = []

  // data for display after sort
  @observable display_data = []
  @observable tree_data = []
  @observable selectedNodes: TreeNode[]

  @observable type = ''

  @observable treeKey = {}

  @observable detailKey:any[] = []

  @observable domain = ''

  @observable selectedData = []

  @observable _selectedColumns = []
  @observable selectedProducts = []

  @observable lineCols = []
  @observable scrollHeight: string | null = null
  @observable frozenColumns: any[] | null = null
  @observable frozenWidth: number | null = null
  @observable rowEditable = false //row edit
  @observable expanded = false // row expand
  @observable isHover = true
  @observable sortField = null
  @observable sortOrder = null

  showCheckboxFlag : boolean

  channels:[]
  channels2:[]
  skus: []
  qty: number
  channelValue: []
  channelValue2: []
  skuCode: null
  skuId
  balance
  seText

  childrenNum = 0
  /**
   * 记录被选中的子节点ID
   */
  mapOfChildrenCheckedId  = {}


  firstApply(){
    this.qty = 20-this.balance
  }

  secondApply(){
    this.qty = 40-this.balance
  }

  thirdApply(){
    this.qty = 60-this.balance
  }

  delete(data) {
    this.display_data.splice(this.display_data.indexOf(data),1)
  }

  confirm() {
    this.display_data.forEach((d,index) =>{
      if(!d.id){
        d.id = this.display_data[index-1].id + 1
        d.fromChannel = this.channelValue['label']
        d.toChannel = this.channelValue2['label']
        d.sku = this.skuCode
        d.stock = this.balance
        d.firstDays = 20
        d.secDays = 40
        d.thirdDays = 60
        d.qty = this.qty
        let data:any[] = JSON.parse(localStorage.getItem("RepleData"))
        data.push(d)
        localStorage.setItem("RepleData",JSON.stringify(data))
      }
    })
  }
  /**
   *
   * @param d
   */
  @action setData(d) {
    if (d != null && this.showCheckboxFlag) {
      if (d.length < 1) {
        this.showCheckboxSwitch = false
      } else {
        this.showCheckboxSwitch = true
      }
    }

    this.data = JSON.parse(JSON.stringify(d || []));
    this.display_data = d;
    this.tree_data = d;

  }
  /**
   * method to sort the data
   * @param e
   */
  @action sort(e) {
    let key = e.key
    let value = e.value
    if (key && value) {
      this.display_data = this.data.sort((a, b) =>
        value === 'ascend'
          ? a[key] > b[key]
            ? 1
            : -1
          : b[key] > a[key]
            ? 1
            : -1
      );
    } else {
      this.display_data = this.data;
    }
  }

  @action sortPN(event){
    this.display_data = event.data.sort((data1, data2) => {
      let value1 = event.field === 'reservedTotal' ? +data1['reservedStock'] + +data1['reserve'] : data1[event.field];
      let value2 = event.field === 'reservedTotal' ? +data2['reservedStock'] + +data2['reserve'] :data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
          result = event.order;
      else if (value1 != null && value2 == null)
          result = -1 * event.order;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
  });
  }
  /**
   * 设置是否开启加载图标
   * @param v => true|false
   */
  @action setLoading(v) {
    this.loadingSwitch = v
  }
  /**
   * 设置全选或者全闭
   * @param v => true|false
   */
  @action selectAllCheckbox(v) {
    this.allCheckBoxValue = v
    if(v) {
    this.display_data.forEach(d => {
      this.mapOfCheckedId[d.id] = v
      if (d.children != null && d.children.length > 0) {
        d.children.forEach(c => {
          this.mapOfCheckedId[c.id] = v
          // this.checkParentCheckBox(v,d.id,c.id)
        })
      }
    })
  }else{
    this.mapOfCheckedId={}
  }
    toJS(this.mapOfCheckedId)
  }
  /**
   *  设置某个数据记录 选中 或者 取消选中
   * @param id
   * @param v => true|false
   */
  @action selectOneCheckbox(id, v) {
    if (v) {
      this.mapOfCheckedId[id] = v
      //计算所有子节点个数
      let allChildrenLen = this.countChildren();
     if(Object.keys(this.mapOfCheckedId).length == (this.display_data.length+allChildrenLen)) this.allCheckBoxValue = true
    } else {
      delete this.mapOfCheckedId[id]
      if(this.allCheckBoxValue) this.allCheckBoxValue = false
    }

  }

    @action selectOnePNCheckBox(id){
      if(this.mapOfCheckedId[id]){
        delete this.mapOfCheckedId[id]
        if(this.allCheckBoxValue) this.allCheckBoxValue = false
      }else{
        this.mapOfCheckedId[id] = true
      }
    }

    @action selectAllPNCheckbox(){
      this.selectAllCheckbox(!this.allCheckBoxValue)
    }

    @action selectDisplayColumn(d){
        if(d.length<=0){
          if(this.head.length<this.showColumn['options'].length){
            this.head = this.showColumn['options'].map(col=>{
              return {key:col.value,title:col.label}
            })
          }
          return
        }
        let temp = []
        this.showColumn['options'].forEach(col=>{
          if(d.includes(col.value)){
            temp.push({key:col.value,title:col.label})
          }
        })
        this.head = temp
        console.log(this.head)
    }

  /**
   * 获取选中数据ID数组
   */
  @action formatSelectedIds() {
    return Object.keys(this.mapOfCheckedId)
  }
  /**
   * 获取选中数据对象数组
   */
  @action formatSelectedObjs() {
    let arr = this.formatSelectedIds()
    let obj = {}
    arr.forEach(a => {
      obj[a] = true
    })
    // console.log("选中得id为"+JSON.stringify(obj));
    // console.log("获取得数据为:"+JSON.stringify(this.data));
    this.findInChildren(obj,this.data);
    // console.log("队中得数据对象数组为:"+JSON.stringify(obj));
    return Object.values(obj)
  }
  /**
   *
   * @param idx
   * @param v
   */
  @action setCheckBoxProperty(idx, v) {
    let d = this.display_data[idx]
    if (d) {
      this.mapOfCheckedId[d.id] = v
    } else {
      console.error("Cannot find the object from the display_data!")
    }
  }

  /**
   * 展开树状表格节点
   * @param array
   * @param data
   * @param $event
   */
  @action
  collapse(idx, $event: boolean): void {
    this.display_data[idx]["expand"] = $event
  }
  /**
   * 表格第二层的展开树状表格节点
   * @param idx
   * @param jdx
   * @param $event
   */
  @action
  collapsePro(idx,jdx, $event: boolean): void {
    this.display_data[idx].children[jdx]["expand"] = $event
  }
  /**
   * 补全详情字段
   * @param detailKey
   */
  @action setDetailKey(dk){
    let arr:any[] = [{key:'createAt',title:'CreateAt'},
      {key:'createBy',title:'CreateBy'},
      {key:'updateAt',title:'UpdateAt'},
      {key:'updateBy',title:'UpdateBy'}]
    this.detailKey = dk.concat(arr)
  }

  /**
   * 时间转换
   * @param long d
   */
  @action convertDate(d){
    var date:any = new Date(parseInt(d))
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes() + ':'
    var s = date.getSeconds()
    return Y + M + D + h + m + s
  }

  /**
   * 勾选树形结构的checkbox
   * @param data
   * @param id
   * @param pId
   * @param v
   */
  @action selectOneTreeCheckBox(id,pId,v){
    this.childrenNum = this.countChildren()
    //判断有无子级
    if(pId == "0"){
      if (v) {
        this.mapOfCheckedId[id] = v
        this.checkChildCheckBox(id,v)
       if(Object.keys(this.mapOfCheckedId).length == (this.display_data.length+this.childrenNum)) this.allCheckBoxValue = true
      } else {
        delete this.mapOfCheckedId[id]
        this.checkChildCheckBox(id,v)
        if(this.allCheckBoxValue) this.allCheckBoxValue = false
      }
    }else{
      if (v) {
        this.mapOfCheckedId[id] = v
        this.checkParentCheckBox(v,pId,id)
       if(Object.keys(this.mapOfCheckedId).length == (this.display_data.length+this.childrenNum)) this.allCheckBoxValue = true
      } else {
        delete this.mapOfCheckedId[id]
        this.checkParentCheckBox(v,pId,id)
        if(this.allCheckBoxValue) this.allCheckBoxValue = false
      }

    }
    console.log(this.mapOfChildrenCheckedId)
  }
  @action
  skuChange(sku){
    this.skuCode = sku.label
    this.skuId = sku.value
    this.qty=0
    this.balance=Math.floor(Math.random()*20)
  }

  /**
   * 计算所有子节点的个数
   */
  private countChildren() {
    let childrenCounter = 0;
    this.display_data.forEach(d => {
      if (d.children) {
        childrenCounter += d.children.length;
      }
    });
    return childrenCounter
  }

  /**
   * 处理子节点
   * @param id
   * @param v
   */
  checkChildCheckBox(id,v){
    this.display_data.forEach(d=>{
      if(d.id == id && d.children && d.children.length>0){
        let childrenChecked = []
        d.children.forEach(child=>{
          let childChecked = {}
          if(v){
            this.mapOfCheckedId[child.id] = v
            childChecked = { [child.id] : v }

          }else{
            delete this.mapOfCheckedId[child.id]
          }
          childrenChecked.push(childChecked)
        })
        v ? this.mapOfChildrenCheckedId[id] = childrenChecked : delete this.mapOfChildrenCheckedId[id]
      }
    })
  }

  /**
   * 处理父节点
   * @param v
   * @param pId
   * @param id
   */
  checkParentCheckBox(v,pId,id){

    let childrenLength = 0
    this.display_data.forEach(d=>{
      if(d.id == pId){
        childrenLength = d.children.length
      }
    })

    if(v){
      let childChecked = {[id] : v}
      if(this.mapOfChildrenCheckedId[pId]){
        this.mapOfChildrenCheckedId[pId].push(childChecked)
      }else{
        let childrenChecked = []
        childrenChecked.push(childChecked)
        this.mapOfChildrenCheckedId[pId] = childrenChecked
      }
      if(this.mapOfChildrenCheckedId[pId].length == childrenLength) this.mapOfCheckedId[pId] = true

    }else{
      let i = 0
       this.mapOfChildrenCheckedId[pId].forEach((obj,index)=>{
        if(Object.keys(obj)[0] == id){
          i = index;
        }
      })
      this.mapOfChildrenCheckedId[pId].splice(i,1)
      if(this.mapOfCheckedId[pId]) delete this.mapOfCheckedId[pId]
    }

  }



  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.head.filter(col => val.includes(col));
  }

  private findInChildren(obj,d) {
    // console.log("[findInchilden]:"+JSON.stringify(obj)+";;;;;"+JSON.stringify(d));
    d.forEach(item=>{
      if(obj[item.id]){
        obj[item.id] = toJS(item)
        console.log("new :d.id="+item.id+"====obj[d.id]="+obj[item.id]);
        return;
      }else{
        if(item.children) {
          this.findInChildren(obj, item.children);
        }
      }
    })
  }
  @action selectCheckboxOfPrimeTable() {
    this.mapOfCheckedId = {}
    this.selectedNodes.forEach(item => {
      this.mapOfCheckedId[item.data.id] = item.data
    });
  }
}
