import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService, SortEvent } from "primeng/api";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { CONFIG, INPUT_TYPE } from "../../base/BaseStore";
import { map, tap, switchMap } from "rxjs/operators";
import { StockTakeService } from "src/app/service/stock/stock-take.service";
// import * as XLSX from 'xlsx';
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { CommonService } from "src/app/service/common/common-service";
import { forkJoin, of } from "rxjs";
import { ADJUSTMENT_STATUS, BTN_TITLE, FRONTLINE_STOCK_TAKE_CODE, SerialCompareValue, STATUS_TITLE, SURPRISE_STOCK_TAKE_CODE, TAKE_ADJUSTMENT_TXN_TYPE, TAKE_STATUS_ID, TITLE } from "./TakeShare";
import { CommonMethod, EXPORT_XLSX_OPTIONS, TABLE_BORDER_OOPTIONS } from "src/app/util/CommonMethod";
import { FileUpload } from "primeng/fileupload";
import { CommonStore } from "@/service/common/common-store-service";
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";
import { DatePipe } from "@angular/common";

// const TITLE = {
//   STOCK_TAKE_REF_NO: "Stock Take Ref. No.",
//   STOCK_ADJUSTMENT_REF_NO: "Stock Adjustment Ref. No.",
//   CHANNEL: "Channel",
//   STATUS: "Status",
//   TARGET_STOCK_TAKE_DATE: "Target Stock Take Date",
//   STOCK_TAKE_TYPE: "Stock Take Type",
//   COUNTBY:"Serialized Item Count By",
// }

// const BTN_TITLE = {
//   PRE_STOCK_TAKE: "Pending For Stock Take",
//   READY_FOR_STOCK_TAKE: "Ready for Stock Take",
//   READY_FOR_SECOND_COUNT: "Ready For 2nd count",
//   CHECK_VARIANCE: "Check Variance",
//   EDIT: "Edit",
//   MARK_AS_FIRST_COUNT: "Mark As 1st Count",
//   VIEW_VARIANCE_REPORT: "View Variance Report",
//   EXPORT: "Export",
//   BACK: "Back",
//   ONE_COUNT_COMPLETE:"1st Count Input Complete",
//   TWO_COUNT_COMPLETE:"2nd Count Input Complete",
//   VIEW_ADJUSTMENT: "View Stock Adjustment",
//   UPLOAD: "Attachment",
// }

// const STATUS_TITLE = {
//   DRAFT: "Draft",
//   PRE_STOCK_TAKE: "Pending For Stock Take",
//   STOCK_TAKE_IN_PROGRESS_1: "Stock Take In Progress (1st count)",
//   STOCK_TAKE_IN_PROGRESS_2: "Stock Take In Progress (2nd count)",
//   STOCK_TAKE_IN_PROGRESS_1_INPUT: "Stock Take In Progress (1st count input complete)",
//   STOCK_TAKE_IN_PROGRESS_2_INPUT: "Stock Take In Progress (2nd count input complete)",
//   STOCK_TAKE_IN_PROGRESS_1_COMPLETE: "Stock Take In Progress (1st count complete)",
//   STOCK_TAKE_IN_PROGRESS_2_COMPLETE: "Stock Take In Progress (2nd count complete)",
//   STOCK_TAKE_IN_PROGRESS: "Stock Take In Progress",
//   REVIEW_IN_PROGRESS: "Review In Progress",
//   COMPLETED: "Completed",
//   PARTIAL_COMPLETED: "Adjustment in Progress",
// }

@Injectable()
export class TakeDetailStore {

  commonStore: CommonStore;

  queryData: any = {
    ipts: [
      {
        title: TITLE.STOCK_TAKE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-6",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.CHANNEL,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: false,
        disabled: true
      },
      {
        title: TITLE.STATUS,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.STOCK_TAKE_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3 p-xlg-3",
        value: null,
        options: [],
        disabled: true
      },
      {
        title: TITLE.STOCK_ADJUSTMENT_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.COUNTBY,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.TARGETDATE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.TAKEBU,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true,
        optionLabel: "label",
        optionValue: "value"
      },
      {
        title: TITLE.REMARKS,
        value: null,
        disabled: true,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-12 p-lg-6 textarea-min-width-100",
      },
      {
        title: TITLE.CANCEL_REASON,
        value: null,
        disabled: true,
        show: false,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-12 p-lg-3 textarea-min-width-100",
      },
    ],
    btns: [
      {
        title: BTN_TITLE.BACK,
        alias: 'BACK',
        class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
        show: true,
        unchanged: true,
        handler: { click: () => this.back() }
      },
      {
        title: BTN_TITLE.CANCEL_TAKE,
        alias: 'CANCEL',
        class: 'p-order-1 p-mr-1',
        show: false,
        handler: { click: () => this.openConfirmCancelTakeModal() },
      },
      {
        title: BTN_TITLE.EXPORT,
        alias: 'EXPORT',
        class: "p-order-1 p-mr-1",
        show: true,
        unchanged: true,
        handler: { click: () => this.export() }
      },
      {
        title: BTN_TITLE.EXPORT_SNAPSHOT_DATA,
        alias: 'EXPORT_SNAPSHOT_DATA',
        class: "p-order-1 p-mr-1",
        show: false,
        unchanged: true,
        handler: { click: () => this.exportSnapshotQtyExcel() }
      },
      {
        title: BTN_TITLE.UPLOAD,
        alias: 'UPLOAD',
        class: 'p-order-1 p-mr-1',
        show: false,
        handler: { click: () => this.showUploadModal() },
      },
      {
        title: BTN_TITLE.READY_FOR_SECOND_COUNT,
        alias: 'READY_FOR_SECOND_COUNT',
        class: "p-order-2 p-mr-1",
        show: false,
        handler: { click: () => this.readyFor2ndCount() }
      },
      {
        title: BTN_TITLE.CHECK_VARIANCE,
        alias: 'CHECK_VARIANCE',
        class: "p-order-2 p-mr-1",
        show: false,
        handler: { click: () => this.checkVariance(['main', 'stock_take', 'check']) }
      },
      {
        title: BTN_TITLE.VIEW_ADJUSTMENT,
        alias: 'VIEW_ADJUSTMENT',
        class: 'p-order-2 p-mr-1',
        show: false,
        handler: {
          click: () => {
            this.viewStockAdjustment()
          },
        },
      },
      {
        title: BTN_TITLE.VIEW_VARIANCE_REPORT,
        alias: 'VIEW_VARIANCE_REPORT',
        class: "p-order-2 p-mr-1",
        show: false,
        handler: { click: () => this.viewReport(['main', 'stock_take', 'check']) }
      }, {
        title: BTN_TITLE.EDIT,
        alias: 'EDIT',
        class: "p-order-1 p-mr-1",
        show: false,
        handler: { click: () => this.edit() }
      }, {
        title: BTN_TITLE.PRE_STOCK_TAKE,
        alias: 'PRE_STOCK_TAKE',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: { click: () => this.preStockTake() }
      },
      {
        title: BTN_TITLE.ONE_COUNT_COMPLETE,
        alias: 'ONE_COUNT_COMPLETE',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: { click: () => this.updateStockTakeStatus() }
      },
      {
        title: BTN_TITLE.TWO_COUNT_COMPLETE,
        alias: 'TWO_COUNT_COMPLETE',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: { click: () => this.updateStockTakeStatus() }
      },
      {
        title: BTN_TITLE.READY_FOR_STOCK_TAKE,
        alias: 'READY_FOR_STOCK_TAKE',
        class: "p-order-3 p-mr-1",
        show: false,
        handler: { click: () => this.changeStatus() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12'
  }
  tableData = {
    btns: [],
    data: [],
    dataDisplay: null,
    showCheckboxSwitch:false,
    loadingSwitch:false,
    head: [
      {key:'itemCode',title:'Item Code', width:"8rem", exportValue: true},
      {key:'itemDesc',title:'Item Description', width:"16rem", exportValue: true,},
      {key:'actionBy',title:'Action By', width: '12rem'},
      {key:'normal', parent: { title: '1st Count', colspan: 2 }, count:1, title:'FG', width:"8rem", exportValue: false, type:'compareSerial'},
      {key:'faulty', parent: true, title:'Faulty', width:"8rem", count:1, exportValue: false, type:'compareSerial'},
      {key:'normal_2', parent: { title: '2nd Count', colspan: 2 }, count:2, title:'FG', width:"8rem", exportValue: false, type:'compareSerial'},
      {key:'faulty_2', parent: true, title:'Faulty', width:"8rem", count:2, exportValue: false, type:'compareSerial'},
      // {key:'total',title:'Total', width:"10%"}
    ],
    sortField: 'itemCode',
    sortOrder: 1,
  }
  compareSerialModalConfig = {
    ipts: [
      {
        title: 'Channel',
        alias: 'channel',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Condition',
        alias: 'stockCondition',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Onhand Serial Qty',
        alias: 'onhandSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Code',
        alias: 'itemCode',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Desc',
        alias: 'itemDesc',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Take Serial Qty',
        alias: 'stockTakeSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
    ],
    serialCloneList: [],
    serialList: [],
    columns: [
      { key: 'serial', title: 'Serial Number', width: 'auto' },
      { key: 'inStockTakeSerial', title: 'Found in stock take', width: 'auto' },
      { key: 'inQtyOnHandSerial', title: 'Found in system record', width: 'auto', },
    ],
    header: 'Compare Serial Number',
    checked: false,
    loading: false,
    visible: false,
  }
  assignByIpt = {
    title: TITLE.FILTERASSIGNBY,
    type: INPUT_TYPE.SELECT,
    options: [],
    class: '',
    value: null,
  }

  selectRequest = null
  searchAll = true
  requestDialog = false
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  isActionEnable: boolean
  dialogTitle = ""
  info = {
    title: 'Stock Take Detail',
    emptyFile: 'File content cannot be empty!',
    download: "Start downloading Excel files",
    submitSuccess: "submit successfully!",
    submitFail: "submit failed!",
    confirmSuccess: "confirm successfully!",
    confirmFail: "confirm failed!",
    addItems: 'Please add the item content!',
    labelRequired: 'label is required!',
    itemEmpty: 'Stock Take Item cannot be empty',
    cancelReasonEmpty: 'Cancel Reason cannot be empty',
  }
  detailData:any = {} // allocation data parameter from parent
  uploadRemind = false // upload DOM is displayed
  tempUploadData // temporarily reserved upload data
  stockTakeCount
  headId
  countByList
  allowViewSerial = [
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2,
    STATUS_TITLE.REVIEW_IN_PROGRESS,
    STATUS_TITLE.COMPLETED,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1_INPUT,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2_INPUT,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1_COMPLETE,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2_COMPLETE,
  ]
  _countByList={}
  itemList
  takeStatus
  adjustmentStatus
  orderType
  popupPUploadEl: FileUpload;

  haveInput: boolean;

  currStatusId;

  takeTypeDescription='';
  targetDateDescription='';

  get isShowFilterBy(){
    return this.currStatusId!=null&&[TAKE_STATUS_ID.DRAFT].indexOf(this.currStatusId) == -1
  }

  get isSurprise(){
    return SURPRISE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)
  }
  get isFrontline(){
    return FRONTLINE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)
  }

  get isUploadable(){
    let isSurprise = this.isSurprise
    let isFrontline = this.isFrontline
    let permission = false
    if(isSurprise){
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_READY_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_PENDING_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE_AJ))
      )
    }else if(isFrontline){
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING_MARK_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE_AJ))
      )
    }else{
      permission = (
        (this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP1CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING_MARK_2ND)) ||
        (this.currStatusId == TAKE_STATUS_ID.STIP2CC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE_AJ))
      )
    }
    return permission && !([TAKE_STATUS_ID.COMPLETED].indexOf(this.currStatusId)>-1)
  }

  _stockTakeTypeValue
  statusTitleList
  statusId
  getStatusId(){
    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)
    return statusObj.value || null
  }

  allowCompareSerial = {};
  rowDataUniqueKey = 'itemCode';

  takeBuOptions

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private stockTakeService: StockTakeService,
    private commonService: CommonService,
    private stockCommonService: StockCommonService,
    private takeService: StockTakeService,
    private conf: ConfirmationService,
    private permissionService: PermissionService,
    public datepipe: DatePipe,
  ) {
    makeAutoObservable(this)
    this.getPermission(route)
    this.getResolverData()
    // this.initQueryData()
    this.initConfig();
    this.initCountBy()
    this.loadDetailDataById()
    this.resetUploadModalConfig();
    this.resetCancelModalConfig();
  }
  get status() {
    let ipt = this.queryData.ipts.find(item => item.title === TITLE.STATUS)
    return ipt.value
  }
  // get label() {
  //   let ipt = this.queryData.ipts.find(item => item.title === TITLE)
  //   return this.queryData.ipts[5].value
  // }
  get showC() {
    return true // this.permission?.includes('Create Stock Take')
  }
  initConfig(){
    this.takeStatus = LocalStorageHelper.getObject('TAKE_STATUS');
    this.orderType = LocalStorageHelper.getObject('ORDER_TYPE')
    this.takeBuOptions = LocalStorageHelper.getObject('STOCK_TAKE_BU')
    this.getQueryIptByLabel(TITLE.TAKEBU).options = this.takeBuOptions.map(item=>{return {label: item.code, value: item.id}})
  }
  checkActionDisable(channelId){
    this.isActionEnable=CommonMethod.haveChannelPermission(channelId.id)
  }
  convertDate(d){
    var timeZone = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date:Date = new Date(parseInt(d, 10) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // this.setPermission(this.queryData.btns)
  }
  getResolverData() {
    // this.route.data.pipe(pluck('query')).subscribe(res => {
    //   if(!res.local && res[0].status === 200 ) {
    //     let list = []
    //     res[0].data.forEach(item => {
    //       list.push({id:item.id, code: item.code, other: item.description, name: item.description})
    //     })
    //     LocalStorageHelper.setObject('TAKE_LABEL', list)
    //   }
    // })
  }
  setPermission(...list) {
    let permissionService = this.permissionService
    let prefix = this.detailData.stockTakeTypeId === 45 ? 'Surprise - ' : ''
    let btns = this.queryData.btns
    // if(btns) {
    if(this.isSurprise){
      if(btns) btns.forEach(item => {
        item.title === BTN_TITLE.PRE_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_PENDING));
        item.title === BTN_TITLE.READY_FOR_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_READY));
        item.title === BTN_TITLE.CHECK_VARIANCE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV));
        item.title === BTN_TITLE.EDIT && (item.disabled = !(
          permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT)&&
          (
            (this.currStatusId == TAKE_STATUS_ID.DRAFT && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_PENDING)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP1C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP2C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_2ND_COMPLETE))
          ) ||
          (this.currStatusId == TAKE_STATUS_ID.PREST && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_READY)) ||
          this.editRemarkPermission
          )||!this.isActionEnable
        );
        item.title === BTN_TITLE.VIEW_VARIANCE_REPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_VIEW_REPORT));
        item.title === BTN_TITLE.EXPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EXPORT));
        item.title === BTN_TITLE.READY_FOR_SECOND_COUNT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_READY_2ND));
        item.title === BTN_TITLE.ONE_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST));
        item.title === BTN_TITLE.TWO_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_2ND_COMPLETE));
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_SEARCH));
        item.title === BTN_TITLE.CANCEL_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE));
        item.title === BTN_TITLE.EXPORT_SNAPSHOT_DATA && (item.show = (permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE)
          && ![TAKE_STATUS_ID.DRAFT, TAKE_STATUS_ID.PREST].includes(this.currStatusId)));
      })
    }else if(this.isFrontline){
      if(btns) btns.forEach(item => {
        item.title === BTN_TITLE.PRE_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING));
        item.title === BTN_TITLE.READY_FOR_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY));
        item.title === BTN_TITLE.CHECK_VARIANCE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV));
        item.title === BTN_TITLE.EDIT && (item.disabled = !(
          permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT)&&
          (
            (this.currStatusId == TAKE_STATUS_ID.DRAFT && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP1C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP2C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE))
          ) ||
          (this.currStatusId == TAKE_STATUS_ID.PREST && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY)) ||
          this.editRemarkPermission
        )||!this.isActionEnable);
        item.title === BTN_TITLE.VIEW_VARIANCE_REPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_VIEW_REPORT));
        item.title === BTN_TITLE.EXPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EXPORT));
        item.title === BTN_TITLE.READY_FOR_SECOND_COUNT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY_2ND));
        item.title === BTN_TITLE.ONE_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST));
        item.title === BTN_TITLE.TWO_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE));
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.SO_TYPE1_SEARCH));
        item.title === BTN_TITLE.CANCEL_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE));
        item.title === BTN_TITLE.EXPORT_SNAPSHOT_DATA && (item.show = (permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE)
          && ![TAKE_STATUS_ID.DRAFT, TAKE_STATUS_ID.PREST].includes(this.currStatusId)));
      })
    }else{
      if(btns) btns.forEach(item => {
        item.title === BTN_TITLE.PRE_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING));
        item.title === BTN_TITLE.READY_FOR_STOCK_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY));
        item.title === BTN_TITLE.CHECK_VARIANCE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV));
        item.title === BTN_TITLE.EDIT && (item.disabled = !(
          permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT)&&
          (
            (this.currStatusId == TAKE_STATUS_ID.DRAFT && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP1C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) ||
            (this.currStatusId == TAKE_STATUS_ID.STIP2C && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE))
          ) ||
          (this.currStatusId == TAKE_STATUS_ID.PREST && permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY)) ||
          this.editRemarkPermission
        )||!this.isActionEnable);
        item.title === BTN_TITLE.VIEW_VARIANCE_REPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_VIEW_REPORT));
        item.title === BTN_TITLE.EXPORT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EXPORT));
        item.title === BTN_TITLE.READY_FOR_SECOND_COUNT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY_2ND));
        item.title === BTN_TITLE.ONE_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST));
        item.title === BTN_TITLE.TWO_COUNT_COMPLETE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE));
        item.title === BTN_TITLE.VIEW_ADJUSTMENT && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE_AJ));
        item.title === BTN_TITLE.CANCEL_TAKE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE));
        item.title === BTN_TITLE.EXPORT_SNAPSHOT_DATA && (item.show = (permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE)
          && ![TAKE_STATUS_ID.DRAFT, TAKE_STATUS_ID.PREST].includes(this.currStatusId)));
      })
    }
  }

  get editRemarkPermission(){
    let isSuprise = this.isSurprise
    let isFrontline = this.isFrontline
    let statusId = this.currStatusId
    let remarkPermission = false
    if(isSuprise){
      remarkPermission = statusId != TAKE_STATUS_ID.COMPLETED && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_REMARKS_EDIT)
    }else if(isFrontline){
      remarkPermission = statusId != TAKE_STATUS_ID.COMPLETED && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_REMARKS_EDIT)
    }else{
      remarkPermission = statusId != TAKE_STATUS_ID.COMPLETED && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_REMARKS_EDIT)
    }
    return remarkPermission
  }

  setLabels() {
    const list = LocalStorageHelper.getObject('TAKE_LABEL')
    const takeType = list.find(item => item.id === this.detailData.stockTakeTypeId)
    this._stockTakeTypeValue = takeType.code

    // if(this.detailData.labelName){
      let ipt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_TYPE)
      ipt.value = takeType.name
      this.takeTypeDescription = takeType.name
    // }else{
      // const labels  = this.detailData.label.map(item=>item.labelName)
      // this.queryData.ipts[5].value = labels.toString()
    // }
  }
  setSubmitStatus(){
    const isNull = this.detailData.items.some(item=>{
      return item.natureList.some(sub => sub.balanceDiff === null);
    })
    // console.log(' => is null: ', isNull, this.detailData.isSubmit);

    // this.queryData.btns[3].disabled = !!this.detailData.stockAdjustmentReferenceNumber || !isNull ? false : true
  }

  loadDetailDataById() {
    this.tableData.loadingSwitch = true
    this.route.queryParams.subscribe(params=>{
      if(params['id']){
        this.headId = params['id']
        forkJoin([
          this.stockTakeService.searchDetailById(params["id"]),
          this.loadItemListBySkuModule(),
        ]).pipe(
          map(res=>{
            return res[0]
          })
        ).subscribe(res=>{
        // this.stockTakeService.searchDetailById(params["id"]).subscribe(res=>{
          this.tableData.loadingSwitch = false
          if(res?.data[0]){
            this.detailData = res.data[0]
            this.initQueryData();
            // this.setPermission(this.queryData.btns)
            this.getAdjustmentStatus().subscribe(res=>{
              this.initQueryData();
              // this.setPermission(this.queryData.btns)
            })
          }
        })
      }
    })
  }
  //
  initQueryData() {
    // console.log('go to in detail page');
    // this.detailData = LocalStorageHelper.getObject('takeDetail');
    // console.log(this.detailData);

    // this.detailData = JSON.parse(sessionStorage.getItem('takeDetail'))
    // console.log(this.detailData.stockAdjustmentReferenceNumber);
    this.stockTakeCount = this.detailData.stockTakeCount >= 2 ? 2 : this.detailData.stockTakeCount;
    let takeNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_REF_NO)
    let adjRefNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_ADJUSTMENT_REF_NO)
    let channelIpt = this.queryData.ipts.find(item => item.title === TITLE.CHANNEL)
    let statusIpt = this.queryData.ipts.find(item => item.title === TITLE.STATUS)
    let dateIpt = this.queryData.ipts.find(item => item.title === TITLE.TARGETDATE)
    let countByIpt = this.queryData.ipts.find(item => item.title === TITLE.COUNTBY)
    let takeBuIpt = this.queryData.ipts.find(item => item.title === TITLE.TAKEBU)
    let remarkIpt = this.queryData.ipts.find(item => item.title === TITLE.REMARKS)
    let cancelReasonIpt = this.queryData.ipts.find(item => item.title === TITLE.CANCEL_REASON)

    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)

    takeNoIpt.value = this.detailData.stockTakeNumber
    // adjRefNoIpt.value = this.detailData.stockAdjustmentReferenceNumber
    channelIpt.value = this.detailData.channelCode
    countByIpt.value = this.detailData.countBy
    statusIpt.value = statusObj.label
    takeBuIpt.value = this.detailData.bu
    remarkIpt.value = this.detailData.remark
    cancelReasonIpt.value = this.detailData.cancelReason
    let channelId=LocalStorageHelper.getObject('REPO').find(sub=>sub.label===this.detailData.channelCode)
    this.checkActionDisable(channelId)

    statusIpt.value = statusObj.label

    this.loadCurrStatusId();

    cancelReasonIpt.show = this.currStatusId == TAKE_STATUS_ID.CANCELLED;

    const createat = this.detailData.stockTakeDate
    dateIpt.value = CommonMethod.convertDate_YYYYMMDD(createat)
    this.targetDateDescription = dateIpt.value
    // this.queryData.ipts[6].value = this.detailData.isSurpriseStockTake === "Y" ? ["surprise stock take"] : [];
    this.setLabels()
    this.loadLines()
    // let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)
    let firstCountNormal = this.tableData.head.find(_data=>_data.key == 'normal')
    let firstCountFaulty = this.tableData.head.find(_data=>_data.key == 'faulty')
    let secondCountNormal = this.tableData.head.find(_data=>_data.key == 'normal_2')
    let secondCountFaulty = this.tableData.head.find(_data=>_data.key == 'faulty_2')
    if([
      // 86, 87, 78, 13, 14, 85
      TAKE_STATUS_ID.STIP2CIC,
      TAKE_STATUS_ID.STIP2CC,
      TAKE_STATUS_ID.PARTIAL,
      // TAKE_STATUS_ID.REVIEW,
      TAKE_STATUS_ID.COMPLETED,
      TAKE_STATUS_ID.STIP2C,
      TAKE_STATUS_ID.CANCELLED,
    ].indexOf(statusObj.value)>-1){
      // after 2nd count input
      firstCountNormal.exportValue = true;
      firstCountFaulty.exportValue = true;
      secondCountNormal.exportValue = true;
      secondCountFaulty.exportValue = true;
    }else if([
      // 82, 83, 84
      TAKE_STATUS_ID.STIP1C,
      TAKE_STATUS_ID.STIP1CIC,
      TAKE_STATUS_ID.STIP1CC,
    ].indexOf(statusObj.value)>-1){
      // after 1st count input
      firstCountNormal.exportValue = true;
      firstCountFaulty.exportValue = true;
      secondCountNormal.exportValue = false;
      secondCountFaulty.exportValue = false;
    }else{
      firstCountNormal.exportValue = false;
      firstCountFaulty.exportValue = false;
      secondCountNormal.exportValue = false;
      secondCountFaulty.exportValue = false;
    }


    // this.tableData.data = items
    this.setPermission(this.queryData.btns)
    this.tableData.loadingSwitch = false
    this.disabledOfStatus()
  }
  getQueryIptByLabel(name) {
    return this.queryData.ipts.find(ipt => ipt.title === name)
  }
  getButtonByLabel(name) {
    return this.queryData.btns.find(btn => btn.title === name)
  }

  changeBtnStatus(params) {
    for (const btn of  this.queryData.btns) {
      !btn.unchanged && (btn.show = params[btn.alias])
    }
  }
  /*
  * data的字段是从BTN_TITLE中获取的。只需将要显示的字段设为 true, 没有的默认为 false。btn含有unchanged字段时， 此设置无效
  */
  disabledOfStatus() {
    let data = {}
    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)

    if(
      // this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1
      statusObj.value == TAKE_STATUS_ID.STIP1C
    ) {
      data = {
        EDIT: true,
        ONE_COUNT_COMPLETE: this.haveInput,
        UPLOAD: true,
        CANCEL: true,
      }
    }
    if(
      // this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2
      statusObj.value == TAKE_STATUS_ID.STIP2C
    ) {
      data = {
        EDIT: true,
        // CHECK_VARIANCE: true,
        TWO_COUNT_COMPLETE: this.haveInput,
        UPLOAD: true,
        CANCEL: true,
      }
    }
    if(
      // this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1_INPUT ||
      // this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2_INPUT
      statusObj.value == TAKE_STATUS_ID.STIP1CIC ||
      statusObj.value == TAKE_STATUS_ID.STIP2CIC
    ) {
      data = {
        EDIT: true,
        CHECK_VARIANCE: true,
        UPLOAD: true,
        CANCEL: true,
      }
    }
    if(
      // this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1_COMPLETE
      statusObj.value == TAKE_STATUS_ID.STIP1CC
    ) {
      data = {
        EDIT: true,
        READY_FOR_SECOND_COUNT: true,
        UPLOAD: true,
        // EDIT: true
        CANCEL: true,
      }
    }
    if(
      // statusObj.value == TAKE_STATUS_ID.STIP2CC ||
      statusObj.value == TAKE_STATUS_ID.PARTIAL
    ) {
      data = {
        EDIT: true,
        VIEW_ADJUSTMENT: true,
        VIEW_VARIANCE_REPORT: true,
        UPLOAD: true,
        CANCEL: true,
      }
    }
    if(
      // statusObj.value == TAKE_STATUS_ID.STIP2CC ||
      statusObj.value == TAKE_STATUS_ID.COMPLETED
    ) {
      data = {
        VIEW_ADJUSTMENT: this.adjustmentStatus && this.adjustmentStatus!=ADJUSTMENT_STATUS.NO_ADJUSTMENT,
        VIEW_VARIANCE_REPORT: true,
        UPLOAD: true,
      }
    }
    if(
      statusObj.value == TAKE_STATUS_ID.STIP2CC
    ) {
      data = {
        EDIT: true,
        VIEW_VARIANCE_REPORT: true,
        UPLOAD: true,
        CANCEL: true,
      }
    }
    if(
      // this.status === STATUS_TITLE.PRE_STOCK_TAKE
      statusObj.value == TAKE_STATUS_ID.PREST
    ) {
      data = {
        READY_FOR_STOCK_TAKE: true,
        EDIT: true,
        CANCEL: true,
      }
    }
    if(
      // this.status === STATUS_TITLE.DRAFT
      statusObj.value == TAKE_STATUS_ID.DRAFT
    ) {
      data = {
        PRE_STOCK_TAKE: true,
        EDIT: true,
        CANCEL: true,
      }
    }
    this.changeBtnStatus(data)
  }
  changeStatus() {
    let data = this.detailData
    this.tableData.loadingSwitch = true
    this.stockTakeService.readyForStocktakeById(data.id).subscribe(res=>{
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title, this.info.submitSuccess.replace('submit', BTN_TITLE.READY_FOR_STOCK_TAKE))
        this.stockTakeService.searchDetailById(this.headId).subscribe(res=>{
          if(res?.data[0]){
            this.detailData = res.data[0]
            this.initQueryData();
          }
        })
      } else {
        this.showMessage('warn', this.info.title, this.info.submitFail.replace('submit', BTN_TITLE.READY_FOR_STOCK_TAKE))
      }
    },
    e => console.log('error', e),
    () => console.log('ready for stock take completed!')
    )
  }
  edit(){
    if(!this.isActionEnable){
      this.showMessage('warn','System', 'No permission to edit.')
      return
    }
    this.router.navigate(["main",'stock_take','edit'], {queryParams: {id: this.detailData.id}})
  }

  viewReport(routerRule){
    this.router.navigate(routerRule, { queryParams: {id: this.detailData.id, view:true} })
  }
  readyFor2ndCount() {
    this.tableData.loadingSwitch = true
    this.stockTakeService.readyFor2ndCount(this.detailData.id).pipe(
      switchMap(res => {
      // 在这里可以添加逻辑处理readyFor2ndCount返回的结果
        if (res.code === '000') {
          return this.stockTakeService.save2ndSerial(this.detailData.id);
        }else {
          throw res.msg
        }
    }
    )).subscribe(res => {
      this.tableData.loadingSwitch = false
      this.loadDetailDataById();
    })
  }
  checkVariance(routerRule) {
    if(!this.detailData.lines.length){
      this.showMessage('warn', this.info.title, this.info.addItems)
      return
    }
    this.router.navigate(routerRule, {queryParams: {id: this.detailData.id}})
  }
  export() {
    this.setLoading(true)
    this.exportAsExcel()
    this.setLoading(false)
  }

  exportAsExcel(){
    let data = [...this.tableData.data];
    let col = [...this.tableData.head];
    let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    data = data.map(e=>{return {
      ...e,
      normal: [TAKE_STATUS_ID.STIP1C].includes(currStatus.value)&&e.normal==0?null:e.normal,
      faulty: [TAKE_STATUS_ID.STIP1C].includes(currStatus.value)&&e.faulty==0?null:e.faulty,
      normal_2: [TAKE_STATUS_ID.STIP2C].includes(currStatus.value)&&e.normal_2==0?null:e.normal_2,
      faulty_2: [TAKE_STATUS_ID.STIP2C].includes(currStatus.value)&&e.faulty_2==0?null:e.faulty_2,
    }})
    // let actionBySheets = {};
    let actionBySheetNames = [];
    let filteredTableData = {}
    this.tableData.data.forEach(data=>{
      if(data.actionBy&&data.actionBy.length>0){
        if(actionBySheetNames.indexOf(data.actionBy)==-1){
          actionBySheetNames.push(data.actionBy)
          filteredTableData[data.actionBy] = [];
        };
        filteredTableData[data.actionBy].push(data)
      }
    })
    // worksheet with all data without group by action by
    // let allDataWs = this.getWorkSheet(data, col)
    // worksheet group by action by
    // actionBySheetNames.forEach(name=>{
    //   actionBySheets[name] = this.getWorkSheet(filteredTableData[name], col)
    // })

    let _data = [data, ...actionBySheetNames.map(e=>filteredTableData[e])]
    let _cols = [col, ...actionBySheetNames.map(e=>col)]
    let _tabNames = ['All', ...actionBySheetNames]
    let header = [
      { key: TITLE.STOCK_TAKE_TYPE, value: this.takeTypeDescription },
      { key: TITLE.TARGETDATE, value: this.targetDateDescription },
      { key: TITLE.CHANNEL, value: this.detailData.channelCode },
      { key: TITLE.STOCK_TAKE_REF_NO, value: this.detailData.stockTakeNumber },
      { key: TITLE.SNAPSHOT_DATE, value: this.datepipe.transform(this.detailData.snapshotDate, "yyyy/MM/dd HH:mm:ss") },
    ]
    // wip footer
    let footer = [
      { key: TITLE.DEVICE_MANAGEMENT, value: "" },
      { key: TITLE.NAME_IN_FULL, value: this.detailData.createByUserDisplayName || 'N/A' },
      { key: TITLE.STAFF_ID, value: this.detailData.createByUserDomainId || 'N/A' },
      { key: TITLE.EXPORT_DATE, value: this.detailData.create_at?CommonMethod.convertDate_YYYYMMDD(this.detailData.create_at):'N/A' },
    ]
    let _options: EXPORT_XLSX_OPTIONS[] = _tabNames.map(e=>{return {
      header: header,
      footer: footer,
      fieldKey: 'key',
      haveParent: true,
      tableBorder: TABLE_BORDER_OOPTIONS.leftright,
      tableHeaderBorderBottom: true,
      autoPrintTitle: true,
      pageSetup: CONFIG.PRINT_CONFIG_FIT_COLUMN_LANDSCAPE,
      headerWrapText: false,
      colWidth: [22,30,10,10,10,10,10],
    }})

    CommonMethod.downloadXlsxWithMultiSheetWithOptions(
      _data,
      _cols,
      _tabNames,
      `Stock_Take_Checklist_${this.detailData.stockTakeNumber}`,
      this.datepipe,
      _options
    )

    // let ws_data = [];
    // let merges: XLSX.Range[] = [];
    // let header = []
    // let rows = [...col].map(head=>head.parent?2:1)
    // let maxRow = Math.max(...rows)
    // for(let i=0;i<maxRow;i++){
    //   header.push([])
    // }

    // col.forEach((head,idx)=>{
    //   for(let i = 0;i<maxRow;i++){
    //     if(!head.parent){
    //       if(i==0) merges.push({s: {r: 0, c: header[0].length }, e: {r: maxRow - 1, c: header[0].length}})
    //       header[i].push(head.title)
    //     }else{
    //       if(i==0 && head.parent!==true){
    //         merges.push({s: {r: 0, c: header[0].length }, e: {r: 0, c: header[0].length - 1 + head.parent.colspan}})
    //         for(let k = 0; k<head.parent.colspan;k++){
    //           header[i].push(head.parent.title)
    //         }
    //       }
    //       if(i==1){
    //         header[i].push(head.title)
    //       }
    //     }
    //   }
    // })

    // let dataRow = []

    // data.forEach(row=>{
    //   let _row = []
    //   col.forEach(head => {
    //     // export function should export not export any number empty number
    //     if(head.exportValue == false){
    //       _row.push('')
    //     }else{
    //       // if(val==0||val=='0') val = ''
    //       let val = row[head.key]
    //       _row.push(val)
    //     }
    //   });
    //   dataRow.push(_row)
    // })

    // ws_data = [...header, ...dataRow]
    // let ws = XLSX.utils.aoa_to_sheet(ws_data);
    // let objectMaxLength = [];

    // for (let i = 0; i < ws_data.length; i++) {
    //   let value = <any>Object.values(ws_data[i]);
    //   for (let j = 0; j < value.length; j++) {
    //     if (typeof value[j] == "number") {
    //       objectMaxLength[j] = 10;
    //     } else {
    //       objectMaxLength[j] =
    //         objectMaxLength[j] >= (value[j]?value[j].length:0)
    //           ? objectMaxLength[j]
    //           : value[j].length;
    //     }
    //   }
    // }

    // if(!ws['!merges']) ws['!merges'] = [];
    // ws['!merges'].push(...merges);
    // if(!ws['!cols']) ws['!cols'] = [];
    // ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));

    // XLSX.writeFile({
    //   SheetNames:['ALL', ...actionBySheetNames],
    //   Sheets: {
    //     ALL: allDataWs,
    //     ...actionBySheets
    //   }
    // }, `Stock_Take_Checklist_${this.detailData.stockTakeNumber}.xlsx`);

  }

  // getWorkSheet(_data, _col){
  //   let data = [..._data];
  //   let col = [..._col];

  //   let ws_data = [];
  //   let merges: XLSX.Range[] = [];
  //   let header = []
  //   let rows = [...col].map(head=>head.parent?2:1)
  //   let maxRow = Math.max(...rows)
  //   for(let i=0;i<maxRow;i++){
  //     header.push([])
  //   }

  //   col.forEach((head,idx)=>{
  //     for(let i = 0;i<maxRow;i++){
  //       if(!head.parent){
  //         if(i==0) merges.push({s: {r: 0, c: header[0].length }, e: {r: maxRow - 1, c: header[0].length}})
  //         header[i].push(head.title)
  //       }else{
  //         if(i==0 && head.parent!==true){
  //           merges.push({s: {r: 0, c: header[0].length }, e: {r: 0, c: header[0].length - 1 + head.parent.colspan}})
  //           for(let k = 0; k<head.parent.colspan;k++){
  //             header[i].push(head.parent.title)
  //           }
  //         }
  //         if(i==1){
  //           header[i].push(head.title)
  //         }
  //       }
  //     }
  //   })

  //   let dataRow = []

  //   data.forEach(row=>{
  //     let _row = []
  //     col.forEach(head => {
  //       // export function should export not export any number empty number
  //       if(head.exportValue == false){
  //         _row.push('')
  //       }else{
  //         // if(val==0||val=='0') val = ''
  //         let val = row[head.key]
  //         _row.push(val)
  //       }
  //     });
  //     dataRow.push(_row)
  //   })

  //   ws_data = [...header, ...dataRow]
  //   let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //   let objectMaxLength = [];

  //   for (let i = 0; i < ws_data.length; i++) {
  //     let value = <any>Object.values(ws_data[i]);
  //     for (let j = 0; j < value.length; j++) {
  //       if (typeof value[j] == "number") {
  //         objectMaxLength[j] = 10;
  //       } else {
  //         objectMaxLength[j] =
  //           objectMaxLength[j] >= (value[j]?value[j].length:0)
  //             ? objectMaxLength[j]
  //             : value[j].length;
  //       }
  //     }
  //   }

  //   if(!ws['!merges']) ws['!merges'] = [];
  //   ws['!merges'].push(...merges);
  //   if(!ws['!cols']) ws['!cols'] = [];
  //   ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));

  //   return ws
  // }

  back() {
    // window.history.back()
    let searchParams = this.commonStore.getObject(CONFIG.SEARCHPARAMSKEY.STOCKTAKE)
    if(!searchParams){searchParams = {}}
    this.router.navigate(["main",'stock_take','search'], {queryParams: {...searchParams}})
  }

  // setButtonStatus(flag) {
    // this.queryData.btns[2].disabled = flag
    // this.queryData.btns[3].disabled = flag
  // }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  preStockTake(){
    if(this.detailData.lines.length==0){
      this.showMessage('warn', this.info.title, this.info.itemEmpty)
      return
    }
    this.tableData.loadingSwitch = true
    this.stockTakeService.preStockTake(this.detailData.id).subscribe(preStockTakeRes=>{
      this.tableData.loadingSwitch = false
      if(preStockTakeRes.code == '000' && preStockTakeRes?.data[0]?.id){
        this.showMessage('success', this.info.title, this.info.submitSuccess.replace('submit', BTN_TITLE.PRE_STOCK_TAKE))
        this.stockTakeService.searchDetailById(preStockTakeRes.data[0].id).subscribe(res=>{
          if(res?.data[0]){
            this.detailData = res.data[0]
            this.initQueryData();
          }
        })
      } else {
        this.showMessage('warn', this.info.title, this.info.submitFail.replace('submit', BTN_TITLE.PRE_STOCK_TAKE))
      }
    })
  }
  updateStockTakeStatus(){
    this.tableData.loadingSwitch = true
    this.stockTakeService.updateStockTakeStatus(this.detailData.id).subscribe(preStockTakeRes=>{
      this.tableData.loadingSwitch = false
      if(preStockTakeRes.code == '000'){
        this.stockTakeService.searchDetailById(this.detailData.id).subscribe(res=>{
          if(res?.data[0]){
            this.detailData = res.data[0]
            this.initQueryData();
          }
        })
      }
    })
  }
  markAsFirstCount(){
    this.tableData.loadingSwitch = true
    this.stockTakeService.markAsFirstCount(this.detailData.id).subscribe(preStockTakeRes=>{
      this.tableData.loadingSwitch = false
      if(preStockTakeRes.code == '000' && preStockTakeRes?.data[0]?.id){
        this.stockTakeService.searchDetailById(preStockTakeRes.data[0].id).subscribe(res=>{
          if(res?.data[0]){
            this.detailData = res.data[0]
            this.initQueryData();
          }
        })
      }
    })
  }

  // compare stock take serial
  compareStockTakeLineSerial(rowData, key, count) {
    this.tableData.loadingSwitch = true
    // let _lineData = rowData.countData[count]
    let conditions = LocalStorageHelper.getObject('CONDITIONS')
    let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
    let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY'

    let targetCondition = key.indexOf('normal')>-1?fgCode:key.indexOf('faulty')>-1?faultyCode:null
    let lineData = rowData._data.find(data=>data.stockCondition == targetCondition&&data.countType==count)

    let isFg = targetCondition == 'FG';

    const data = {stockTakeLineId: lineData.lineId}
    this.stockTakeService.compareSerial(data).subscribe((res) => {
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        list = res.data
      }
      const values = {
        channel: this.detailData.channelCode,
        stockCondition: isFg ? 'FG' : 'Faulty',
        onhandSerialQty: list.filter(data=>data.inQtyOnHandSerial==SerialCompareValue.YES).length,
        itemCode: rowData.itemCode,
        itemDesc: rowData.itemDesc,
        stockTakeSerialQty: list.filter(data=>data.inStockTakeSerial==SerialCompareValue.YES).length,
      }
      this.compareSerialModalConfig.ipts.forEach((item) => { item.value = values[item.alias] })
      this.compareSerialModalConfig.serialCloneList = JSON.parse( JSON.stringify(list) )
      this.compareSerialModalConfig.serialList = list
      this.compareSerialModalConfig.checked = true
      this.compareSerialModalConfig.visible = true
    })
  }

  initCountBy(){
    this.countByList = LocalStorageHelper.getObject('STOCK_TAKE_COUNT_BY')
    this.countByList.forEach(item=>{
      this._countByList[item.code]=item.description
    })
  }
  getIsSerialControl(data,line){
    let item = this.itemList.find(_item=>_item.itemCode==line.itemCode)
    let res = false;
    if(data.countBy==this._countByList['SN']){
      if(item._data.isserialcontrol == 'Y'){
        res = true
      }
    }else if(data.countBy==this._countByList['QTY']){
      if(item._data.isserialcontrol == 'Y'){
        // line.isSerialControl = true
        if(line.countType==1){
          res = false
        }else if(line.countType==2){
          res = true
        }
      }else{
        res = false
      }
    }
    return res
  }

  loadItemListBySkuModule(){
    let skus = LocalStorageHelper.getObject('SKU');
    return this.stockCommonService.getItemListByItemId({itemId: skus.map(sku=>sku.code)}).pipe(tap(res=>{
      if (res) {
        let _items = []
        res.forEach(element => {
          _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
        });
        this.itemList = _items;
      }
    }))

    /* return this.commonService.getSkuModuleList().pipe(
      switchMap(res=>{
        return this.stockCommonService.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      tap(res=>{
        if (res) {
          let _items = []
          res.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          this.itemList = _items;
        }
      })
    ) */
  }

  loadLines(){
    let items = [];
    let _items: any = {};
    if(this.detailData.lines) {
      this.detailData.lines.forEach((item) => {
        let el: any = _items[item.itemCode]?_items[item.itemCode]: {
          itemCode: item.itemCode,
          itemDesc: item.itemDesc,
          actionBy: item.actionBy,
          // isSerialControl: this.getIsSerialControl(this.detailData, item),
          isSerialControl: {},
          _data: []
        }
        let conditions = LocalStorageHelper.getObject('CONDITIONS')
        let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
        let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY'

        if(item.stockCondition===fgCode){
          el[`normal`+`${item.countType==1?'':('_'+item.countType)}`] = item.qtyCount
        }
        if(item.stockCondition===faultyCode){
          el[`faulty`+`${item.countType==1?'':('_'+item.countType)}`] = item.qtyCount
        }
        let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
        // if([TAKE_STATUS_ID.COMPLETED].indexOf(currStatus.value)>-1){
        if([TAKE_STATUS_ID.STIP1C, TAKE_STATUS_ID.STIP2C].indexOf(currStatus.value)>-1 && this.stockTakeCount==item.countType && item.qtyCount!=0){
          this.haveInput = true;
        }
        el._data.push(item)
        el.isSerialControl[item.countType] = (this.getIsSerialControl(this.detailData, item)) // split isSerialControl by count type
        _items[item.itemCode] = el
      });
      for(let key in _items){
        if (_items.hasOwnProperty(key)) {
          items.push(_items[key])
        }
      }
    }
    let _actionBy = [...new Set(items.map(item=>{return item.actionBy}))]
      .filter(actionBy=>actionBy&&actionBy.length>0)
      .map(actionBy=>{return {name:actionBy,value:actionBy}})
    this.assignByIpt.options = _actionBy
    this.tableData.data = items
    this.initAllowCompareSerial(this.tableData.data);
  }
  getAdjustmentStatus(){
    return this.stockCommonService.getTransferOrderBySourceTxnData(
      TAKE_ADJUSTMENT_TXN_TYPE,
      this.detailData.id,
      this.detailData.stockTakeNumber
    ).pipe(map(res=>{
      if(res[0]){
        let statusList = LocalStorageHelper.getObject('ORDER_ADJUSTMENT_STATUS');
        let resStatus = res[0].status;
        let status = statusList.find(_status=>_status.label == resStatus)
        let adjRefNoIpt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_ADJUSTMENT_REF_NO)
        adjRefNoIpt.value = res[0].orderNumber
        if(status.value == 111){
          // complete
          return ADJUSTMENT_STATUS.COMPLETE
        }else{
          // not complete
          return ADJUSTMENT_STATUS.NOT_COMPLETE
        }
      }else{
        // no adjustment
        return ADJUSTMENT_STATUS.NO_ADJUSTMENT
      }
    }),tap(res=>{
      this.adjustmentStatus = res;
    }))
  }

  viewStockAdjustment() {
    let params = {
      sourceTxnType: TAKE_ADJUSTMENT_TXN_TYPE,
      sourceTxnRefHeaderId: this.detailData.id,
      sourceTxnRefHeaderNo: this.detailData.stockTakeNumber,
    }
    this.tableData.loadingSwitch = true
    this.stockCommonService.getTransferOrderBySourceTxnData(
      params.sourceTxnType,
      params.sourceTxnRefHeaderId,
      params.sourceTxnRefHeaderNo
    ).pipe(
      switchMap((res) => {
        if (res && res.length == 0) {
          return of(null)
        } else if (res && res.length == 1) {
          return of(res)
        }
      }),
    ).subscribe((order) => {
      if (order && order.length == 1) {
        this.router.navigate(['main', 'transfer_order', 'create'], {
          queryParams: { headId: order[0].headId },
        })
      }
      this.tableData.loadingSwitch = false
    },err=>{
      this.tableData.loadingSwitch = false
    })
  }

  // attachment start
  uploadModalConfig
  resetUploadModalConfig(){
    this.uploadModalConfig={
      visible: false,
      title: "Attachment",
      readonly: false,
      table: {
        data:[],
        head:[
          {key:'fileName',title:'File Name', width: '8rem',type:"filename" },
          {key:'createDate',title:'Upload Date', width: '8rem',type:'datetime' },
          {key:'delete',title:'Action', width: '8rem',type:'delete' },
        ],
        loading: false,
      }
    }
  }
  showUploadModal(){
    this.loadFileDetails()
    // let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    this.uploadModalConfig.readonly = !this.isUploadable;
    this.setUploadModalVisible(true)
  }
  hideUploadModal(){
    this.setUploadModalVisible(false)
  }
  setUploadModalVisible(e){
    this.uploadModalConfig.visible=e
  }
  downloadFile(e){
    this.takeService.getEdmsFile(
        e.edmsFileMappingId
      ).subscribe(res=>{
        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        a.href = url;
        a.download = e.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
  }

  confirmUpload(e, el){
    this.conf.confirm({
      message: `Confirm to upload ${e.files[0].name}?`,
      header: 'Submit',
      accept: () => {
        this.uploadFile(e, el)
      },
    })
  }

  uploadFile(e, popupPUploadEl){
    if(popupPUploadEl)this.popupPUploadEl = popupPUploadEl;
    this.uploadModalConfig.table.loading = true;
    this.takeService.uploadEdmsFile({
      file:e.files[0],
      body:JSON.stringify({"moduleName":"StockTake","relatedId":this.detailData.id,"fileName":e.files[0].name})
    }).subscribe(res=>{
      this.loadFileDetails()
      this.resetUploader()
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }
  loadFileDetails(){
    this.uploadModalConfig.table.loading = true;
    return this.takeService.getEdmsFileIds({
      "moduleName": "StockTake",
      "relatedId": this.detailData.id
    }).subscribe(res=>{
      if(res.code=='000'){
        let data = res.data.sort((a,b)=>{
          return a.createDate>b.createDate?-1:a.createDate<b.createDate?1:0
        })
        this.uploadModalConfig.table.data = data;
      }
    },err=>{},()=>{
      this.uploadModalConfig.table.loading = false;
    })
  }
  resetUploader(){
    if(this.popupPUploadEl)this.popupPUploadEl.clear()
  }
  onUploadModalHide(){
    this.resetUploader()
    this.resetUploadModalConfig()
  }
  deleteFile(edms){
    this.uploadModalConfig.table.loading = true;
    this.takeService.deleteEdmsFIle([edms.edmsFileMappingId]).subscribe(res=>{
      this.uploadModalConfig.table.loading = false;
      this.loadFileDetails()
    },err=>{},()=>{
      // this.uploadModalConfig.table.loading = false;
    })
  }

  attachmentDeleteOnClick(edms){
    this.conf.confirm({
      message: `Confirm to delete ${edms.fileName}?`,
      header: 'Confirmation',
      accept: () => { this.deleteFile(edms) },
    });
  }
  // attachment end

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  setCommonStore(e){this.commonStore=e}

  filterAssignBy() {
    let list = []
    if (this.assignByIpt.value) list = this.assignByIpt.value.map((val) => val.value);
    if (list && list.length > 0) {
      this.tableData.dataDisplay = this.tableData.data.filter(
        (_data) => list.indexOf(_data.actionBy) > -1
      );
    } else {
      this.tableData.dataDisplay = null;
    }
  }
  clearFilterAssignBy(){
    this.tableData.dataDisplay = null;
    this.assignByIpt.value = null;
  }
  loadCurrStatusId(){
    let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    this.currStatusId = currStatus.value
  }

  initAllowCompareSerial(tableData){
    this.allowCompareSerial = {};

    // not allow compare serial in current status
    if(this.allowViewSerial.indexOf(this.detailData.status)==-1) return;

    let _allowCompareSerial = {};
    let cols = this.tableData.head.filter(col=>col.type=='compareSerial')
    tableData.forEach((rowData, idx)=>{
      _allowCompareSerial = {
        ..._allowCompareSerial,
        ...this.getAllowCompareSerialPerRow(rowData, idx, cols, _allowCompareSerial)
      }
    })
    this.allowCompareSerial = _allowCompareSerial
  }

  getAllowCompareSerialPerRowCol(rowData, idx, col){
    let qty = rowData[col.key]
    let isSerialControl: boolean = rowData.isSerialControl[col.count]; // loadLines > getIsSerialControl
    let isSuprise = this.isSurprise;
    let isFrontline = this.isFrontline;

    let permissionValid = false;
    if(isSuprise){
      permissionValid = (
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) ||
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_2ND_COMPLETE)) ||
        ([2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_2ND_COMPLETE)) ||
        ([1,2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CV))
      )
    }else if(isFrontline){
      permissionValid = (
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) ||
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE)) ||
        ([2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE)) ||
        ([1,2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CV))
      )
    }else{
      permissionValid = (
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) ||
        ([1].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT) &&
          this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE)) ||
        ([2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE)) ||
        ([1,2].includes(col.count) && this.currStatusId == TAKE_STATUS_ID.STIP2CIC && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CV))
      )
    }

    return qty != 0 && isSerialControl && permissionValid
  }

  getAllowCompareSerialPerRow(rowData, idx, cols, _allowCompareSerial){
    cols.forEach(col=>{
      _allowCompareSerial[`${rowData[this.rowDataUniqueKey]}_${col.key}`] = this.getAllowCompareSerialPerRowCol(rowData, idx, col)
    })
    return _allowCompareSerial;
  }

  setLoading(e){
    this.tableData.loadingSwitch = e
  }

  reloadData(){
    this.setLoading(true)
    this.stockTakeService.searchDetailById(this.detailData.id).subscribe(res=>{
      this.setLoading(false)
      if(res?.data[0]){
        this.detailData = res.data[0]
        this.initQueryData();
      }
    })
  }

  cancelModalConfig
  resetCancelModalConfig(){
    this.cancelModalConfig={
      visible: false,
      title: 'Confirm Close With Reason',
      reasonIpt: {
        title: TITLE.CANCEL_REASON,
        value: null,
        disabled: false,
        // type: INPUT_TYPE.TEXTAREA,
        rows: 5,
        class: "textarea-min-width-100 textarea-min-height-1 p-mt-2 p-d-block",
      },
    }
  }

  onCancelModalHide(){
    this.resetCancelModalConfig()
  }
  openConfirmCancelTakeModal(){
    this.cancelModalConfig.visible = true
  }
  hideConfirmCancelTakeModal(){
    this.cancelModalConfig.visible = false
  }
  cancelTake(){
    let reason = this.cancelModalConfig.reasonIpt.value
    if(!reason || reason.length == 0){
      this.showMessage('error', this.info.title, this.info.cancelReasonEmpty)
      return
    }
    let data = {
      id: this.detailData.id,
      cancelReason: reason,
    }
    this.setLoading(true)
    this.takeService.cancelStockTake(data).subscribe(res=>{
      this.setLoading(false)
      this.hideConfirmCancelTakeModal()
      this.reloadData()
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  exportSnapshotQtyExcel(){
    this.setLoading(true)
    this.takeService.getSnapshotLine(this.headId).subscribe(res=>{
      this.setLoading(false)
      if(res.code!=='000' || !res.data[0]) {
        this.showMessage('error', this.info.title, res.msg || res.message)
        return
        // throw res.msg || res.message
      }
      let lines = res.data[0].lines

      let data = [...lines];
      let col = [
        {key:'itemcode',title:'Item Code'},
        {key:'itemdesc',title:'Item Description'},
        {key:'stockcondition',title:'Condition'},
        {key:'qtycount',title:'Qty'},
      ];

      let _data = [data]
      let _cols = [col]
      let _tabNames = ['All']
      let header = [
        { key: TITLE.STOCK_TAKE_TYPE, value: this.takeTypeDescription },
        { key: TITLE.TARGETDATE, value: this.targetDateDescription },
        { key: TITLE.CHANNEL, value: this.detailData.channelCode },
        { key: TITLE.STOCK_TAKE_REF_NO, value: this.detailData.stockTakeNumber },
        { key: TITLE.SNAPSHOT_DATE, value: this.datepipe.transform(this.detailData.snapshotDate, "yyyy/MM/dd HH:mm:ss") },
      ]
      let footer = [
        { key: TITLE.DEVICE_MANAGEMENT, value: "" },
        { key: TITLE.NAME_IN_FULL, value: this.detailData.createByUserDisplayName || 'N/A' },
        { key: TITLE.STAFF_ID, value: this.detailData.createByUserDomainId || 'N/A' },
        { key: TITLE.EXPORT_DATE, value: this.detailData.create_at?CommonMethod.convertDate_YYYYMMDD(this.detailData.create_at):'N/A' },
      ]
      let _options = _tabNames.map(e=>{return {
        header: header,
        footer: footer,
        fieldKey: 'key',
        haveParent: false,
      }})

      CommonMethod.downloadXlsxWithMultiSheetWithOptions(
        _data,
        _cols,
        _tabNames,
        `Stock_Take_Snapshot_List_${this.detailData.stockTakeNumber}`,
        this.datepipe,
        _options
      )

    },
    err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }
}
