import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class DemandCycleResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.POOL,
      this.baseResolver.resolverDataList.CYCLE,
      this.baseResolver.resolverDataList.RESSTOCKRESERVATION_TYPE
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList); 
  }
}
