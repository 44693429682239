import { ElementRef, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService, SortEvent } from "primeng/api";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { INPUT_TYPE, URLDICT, CONFIG } from "../../base/BaseStore";
import { HttpHelper } from "src/app/util/HttpHelper";
import { StockTakeService } from "src/app/service/stock/stock-take.service";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { CommonService } from "src/app/service/common/common-service";
import { map, switchMap, tap } from "rxjs/operators";
import { CommonMethod } from 'src/app/util/CommonMethod';
import { forkJoin, Observable, of } from "rxjs";
import { FRONTLINE_STOCK_TAKE_CODE, TITLE, SerialCompareValue, STATUS_TITLE, SURPRISE_STOCK_TAKE_CODE, TAKE_STATUS_ID, BTN_TITLE } from "./TakeShare";
import { SerialScanAllCstComponent } from "@/pages/base/serial-scanall-cst/serial-scanall-cst.component";
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";
import { LoadingService } from "@/service/common/loading-service";
import { SerialCstComponent } from "@/pages/base/serial-cst/serial-cst.component";
import { NgxFileDropComponent } from "ngx-file-drop";
import * as _ExcelJS from '_exceljs';
declare var ExcelJS: _ExcelJS;

// const STATUS_TITLE = {
//   DRAFT: "Draft",
//   PRE_STOCK_TAKE: "Pending For Stock Take",
//   STOCK_TAKE_IN_PROGRESS_1: "Stock Take In Progress (1st count)",
//   STOCK_TAKE_IN_PROGRESS_2: "Stock Take In Progress (2nd count)",
//   STOCK_TAKE_IN_PROGRESS_1_INPUT: "Stock Take In Progress (1st count input complete)",
//   STOCK_TAKE_IN_PROGRESS_2_INPUT: "Stock Take In Progress (2nd count input complete)",
//   STOCK_TAKE_IN_PROGRESS_1_COMPLETE: "Stock Take In Progress (1st count complete)",
//   STOCK_TAKE_IN_PROGRESS_2_COMPLETE: "Stock Take In Progress (2nd count complete)",
//   STOCK_TAKE_IN_PROGRESS: "Stock Take In Progress",
//   REVIEW_IN_PROGRESS: "Review In Progress",
//   COMPLETED: "Completed",
//   PARTIAL_COMPLETED: "Adjustment in Progress",
// }

// const TITLE = {
//   STOCK_TAKE_REF_NO: "Stock Take Ref. No.",
//   STOCK_ADJUSTMENT_REF_NO: "Stock Adjustment Ref. No.",
//   CHANNEL: "Channel",
//   STATUS: "Status",
//   TARGET_STOCK_TAKE_DATE: "Target Stock Take Date",
//   STOCK_TAKE_TYPE: "Stock Take Type",
//   COUNTBY:"Serialized Item Count By",
// }

// const QUERYTITLE = {
//   TYPE:"Stock Take Type",
//   CHANNEL:"Channel",
//   STOCKTAKEREFNO:"Stock Take Ref. No.",
//   STOCKADJREFNO:"Stock Adjustment Ref. No.",
//   TARGETDATE:"Target Stock Take Date",
//   BULKADD_FILTER:"Filter",
//   BULKADD_BRAND:"Brand",
//   BULKADD_ITEM:"Item",
// }

@Injectable()
export class TakeEditStore {

  public loadingService: LoadingService = new LoadingService();

  queryData:any = {
    ipts: [
      {
        title: TITLE.STOCK_TAKE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-6",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.CHANNEL,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: false,
        disabled: true
      },
      {
        title: TITLE.STATUS,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.STOCK_TAKE_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3 p-xlg-3",
        value: null,
        options: [],
        disabled: true
      },
      {
        title: TITLE.STOCK_ADJUSTMENT_REF_NO,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.COUNTBY,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true
      },
      {
        title: TITLE.TARGETDATE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        rows: 3,
        value: null,
        disabled: true
      },
      {
        title: TITLE.TAKEBU,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true,
        optionLabel: "label",
        optionValue: "value"
      },
      {
        title: TITLE.REMARKS,
        value: null,
        disabled: true,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-12 p-lg-6 textarea-min-width-100",
      },
      /* {
        title: "surprise stock take",
        name: "surprise stock take",
        type: INPUT_TYPE.CHECKBOX,
        class: "p-col-12 p-md-4 p-lg-3",
        group: 'surprise',
        rows: 3,
        value: null,
        disabled: false
      } */
      /* {
        title: "Item",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: null,
        options: []
      }, */

    ],
    btns:[{
      title: BTN_TITLE.BACK,
      class: "p-button-outlined p-ml-auto p-mr-1",
      show: true,
      handler: { click: () => this.back() }
    }, /* {
      title: 'Add',
      class: "p-order-2 p-mr-1",
      permissionType: 'c',
      show: true,
      disabled: false,
      handler: { click: () => this.addItem() }
    } ,*/ {
      title: BTN_TITLE.IMPORT_QTY_FROM_XLSX,
      class: "p-mr-1",
      permissionType: 'c',
      show: this.allowEditQty,
      disabled: false,
      handler: { click: () => this.importQtyFromXlsx() }
    },
    {
      title: BTN_TITLE.EDIT_SAVE,
      class: "p-mr-1",
      permissionType: 'c',
      show: true,
      disabled: false,
      handler: { click: () => this.saveTake() }
    }],
    btnsclass: 'p-d-flex p-col-12'
  }
  tableData = {
    ipt: {
      title: "Item",
      type: INPUT_TYPE.MUTIPLESELECT,
      class: "p-col-12 p-md-4 p-lg-3",
      value: null,
      error: null,
      options: []
    },
    btns: [],
    data: [], // use for store data, save to api, this data filter by setTableContent, filter function and store into dataDisplay
    dataDisplay: null, // use for filter which row need to display, change by setTableContent, filter function
    showCheckboxSwitch:false,
    loadingSwitch:false,
    head: [
      {key:'itemCode',title:'Item Code', width: '8rem', },
      {key:'itemDesc',title:'Item Description', width: '16rem', edit: false},
      {key:'actionBy',title:'Action By', width: '12rem'},
      {key:'normal', type: 'amtInput', disabledKey: 'normal_disabled', count: 1, parent: { title: '1st Count', colspan: 2 }, title:'FG', width:"12rem", edit: false},
      {key:'faulty', type: 'amtInput', disabledKey: 'faulty_disabled', count: 1, parent: true, title:'Faulty', width:"12rem", edit: false},
      {key:'normal_2', type: 'amtInput', disabledKey: 'normal_2_disabled', count: 2, parent: { title: '2nd Count', colspan: 2 }, title:'FG', width:"12rem", edit: false},
      {key:'faulty_2', type: 'amtInput', disabledKey: 'faulty_2_disabled', count: 2, parent: true, title:'Faulty', width:"12rem", edit: false},
      // {key:'total',title:'Total', width:"5%"}
    ],
    sortField: 'itemCode',
    sortOrder: 1,
  }

  compareSerialModalConfig = {
    ipts: [
      {
        title: 'Channel',
        alias: 'channel',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Condition',
        alias: 'stockCondition',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Onhand Serial Qty',
        alias: 'onhandSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Code',
        alias: 'itemCode',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Item Desc',
        alias: 'itemDesc',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
      {
        title: 'Stock Take Serial Qty',
        alias: 'stockTakeSerialQty',
        type: INPUT_TYPE.INPUT,
        value: null,
        disabled: true,
      },
    ],
    serialCloneList: [],
    serialList: [],
    columns: [
      { key: 'serial', title: 'Serial Number', width: 'auto' },
      { key: 'inStockTakeSerial', title: 'Found in stock take', width: 'auto' },
      { key: 'inQtyOnHandSerial', title: 'Found in system record', width: 'auto', },
    ],
    header: 'Compare Serial Number',
    checked: false,
    loading: false,
    visible: false,
  }

  scanallModalConfig = {
    existSerialList: [],
    visible: false,
    header: 'Scan All Serial',
    actionQty: {  value: 0, disabled: true, title: 'Qty', },
  };
  selectRequest = null
  searchAll = true
  requestDialog = false
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  dialogTitle = ""
  isActionEnable:boolean
  channelId
  // status = "Draft"
  info = {
    title: 'Stock Take Edit',
    selectChannel : "Please select a Channel!",
    selectItem : "Please select a Item!",
    emptyFile: 'File content cannot be empty!',
    notItems: 'The selected channel has no related items!',
    addItems: 'Please click the Add button to add the item content!',
    required: 'Normal and Faulty is required!',
    labelRequired: 'label is required!',
    saveSuccess: 'Data save successfully!',
    saveFail: 'Failed to save data!',
    notNull: 'Normal or Faulty of item cannot be empty!',
    notNegative: 'Normal or Faulty of item cannot be less than 0!',
    repeat: 'Data duplication!',
    duplicated: 'Found Duplicated Item',
    submitSuccess: 'submit successfully!',
    notChange: 'The data did not change!',
    inputSerial: 'Please enter the serial number!',
  }
  detailData:any = {} // allocation data parameter from parent
  uploadRemind = false // upload DOM is displayed
  tempUploadData // temporarily reserved upload data
  stockTakeCount
  _savedLines = []
  _isSavedByCode = {}
  bulkAddQueryPanel
  itemList
  repoList
  isDraft
  isPending
  isCounting
  stockTakeLineId
  serialModalConfig
  serialListBuffer:SerialListBufferMapper = {};
  serialList:any = {};
  _countByList={}
  countByList
  allowViewSerial = [
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2,
    STATUS_TITLE.REVIEW_IN_PROGRESS,
    STATUS_TITLE.COMPLETED,
    STATUS_TITLE.STOCK_TAKE_IN_PROGRESS,
  ]
  // actionByIpt = null
  actionByOptions = []
  elRef: ElementRef<HTMLElement>;
  tableTagPrefix = 'stock-take-table-'

  filterPanel
  filterAddPanel
  buCodes
  lobCode
  subSubCat
  mainCat
  subCat
  mfgBrand
  takeStatus
  serialcst: SerialCstComponent;

  // scan all popup
  serialscanallcst: SerialScanAllCstComponent;
  currentLineSerialFullList=[]

  currStatusId;
  _stockTakeTypeValue: string;

  fileDropper: NgxFileDropComponent;

  get countBy(){return this.detailData.countBy}

  get isCountBySerial(){return this._countByList['SN']==this.countBy}

  get isShowFilterBy(){
    return this.showEdit && [TAKE_STATUS_ID.STIP1C,TAKE_STATUS_ID.STIP2C].indexOf(this.currStatusId) > -1;
  }
  get isShowScan(){
    return this.showEdit && [TAKE_STATUS_ID.STIP1C,TAKE_STATUS_ID.STIP2C].indexOf(this.currStatusId) > -1 && !this.isFirstCountByQty;
  }

  get isFirstCountByQty(){
    return this.stockTakeCount == 1 && !this.isCountBySerial
  }

  get isCountingQty(){
    return this.showEdit && [TAKE_STATUS_ID.STIP1C,TAKE_STATUS_ID.STIP2C].indexOf(this.currStatusId) > -1;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private conf: ConfirmationService,
    private stockTakeService: StockTakeService,
    private commonService: CommonService,
    private stockCommonService: StockCommonService,
    private permissionService: PermissionService,
  ) {
    makeAutoObservable(this)
    this.getPermission(this.route)
    this.repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE').filter(repo=>repo.children.length)
    this.initCountBy()
    this.initConfig();
    this.initfilterPanel()
    // this.initBulkAddPanel()
    this.initFilterAddPanel()
    this.setPanelOptions()

    this.setLoading(true)
    this.loadItemListBySkuModule().subscribe(res=>{
    this.setLoading(false)
    this.loadDetailDataById()
    this.setPanelOptions()
    }, err=>{
      this.setLoading(false)
    })
    // this.initQueryData()
    this.initSerialModalConfig()
    // this.setActionByIpt()
    // this.loadDetailDataById()
    // this.getLableData()
  }
  get channel() {
    let ipt = this.queryData.ipts.find(item => item.title === TITLE.CHANNEL)
    return ipt.value
    // return this.queryData.ipts[2].value
  }
  /* get items() {
    return this.queryData.ipts[3].value
  } */
  get status() {
    let ipt = this.queryData.ipts.find(item => item.title === TITLE.STATUS)
    return ipt.value
    // return this.queryData.ipts[3].value
  }
  get createDate() {
    return new Date(this.detailData.stockTakeDate)
    // let ipt = this.queryData.ipts.find(item => item.title === TITLE.TARGET_STOCK_TAKE_DATE)
    // return ipt.value
    // return this.queryData.ipts[4].value
  }
  get label() {
    return this.detailData.stockTakeTypeId
    // let ipt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_TYPE)
    // return ipt.value
    // return this.queryData.ipts[5].value
  }

  get loading(){return this.loadingService.loading}
  /* get surprise() {
    return this.queryData.ipts[5].value
  } */

  get isSuprise(){return SURPRISE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)}
  get isFrontline(){return FRONTLINE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)}

  get showEdit() {
    let isSuprise = this.isSuprise
    let isFrontline = this.isFrontline

    let statusId = this.currStatusId
    let editPermission = false;
    let statusPermission = false;
    let readyPermission = false;
    if(isSuprise){
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_2ND_COMPLETE))

      readyPermission = (statusId==TAKE_STATUS_ID.PREST && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_READY))
    }else if(isFrontline){
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE))

      readyPermission = (statusId==TAKE_STATUS_ID.PREST && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY))
    }else{
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE))

      readyPermission = (statusId==TAKE_STATUS_ID.PREST && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY))
    }

    // wip: only surp permission now, should have other permission here
    return (editPermission && statusPermission) || readyPermission
  }

  get allowEditQty(){
    let isSuprise = this.isSuprise
    let isFrontline = this.isFrontline

    let statusId = this.currStatusId
    let editPermission = false;
    let statusPermission = false;
    if(isSuprise){
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SUR_2ND_COMPLETE))
    }else if(isFrontline){
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_2ND_COMPLETE))
    }else{
      editPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_EDIT)

      statusPermission = (statusId==TAKE_STATUS_ID.DRAFT && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_PENDING)) ||
      (statusId==TAKE_STATUS_ID.STIP1C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_1ST)) ||
      (statusId==TAKE_STATUS_ID.STIP2C && this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_2ND_COMPLETE))
    }

    return (editPermission && statusPermission)
  }

  get isAllowAssignBy(){
    let isSuprise = this.isSuprise
    let isFrontline = this.isFrontline
    let statusId = this.currStatusId
    let permission = false
    if(isSuprise){
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_READY)
    }else if(isFrontline){
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_READY)
    }else{
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_READY)
    }
    return (statusId==TAKE_STATUS_ID.PREST && permission)
  }

  get editRemarkPermission(){
    let isSuprise = this.isSuprise
    let isFrontline = this.isFrontline
    let remarkPermission = false
    if(isSuprise){
      remarkPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_REMARKS_EDIT)
    }else if(isFrontline){
      remarkPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_REMARKS_EDIT)
    }else{
      remarkPermission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_REMARKS_EDIT)
    }
    return remarkPermission
  }

  get isAllowEditRemark(){
    let statusId = this.currStatusId
    return statusId != TAKE_STATUS_ID.COMPLETED && this.editRemarkPermission
  }

  get isAllowAddItem(){
    let isSuprise = this.isSuprise
    let isFrontline = this.isFrontline
    let permission = false
    if(isSuprise){
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE)
    }else if(isFrontline){
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE)
    }else{
      permission = this.permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE)
    }
    return this.filterAddPanel && (this.isDraft || (this.stockTakeCount == 1 && this.isCounting)) && permission
  }

  initConfig(){
    this.takeStatus = LocalStorageHelper.getObject('TAKE_STATUS');
  }
  convertDate(d) {
    const timeZone = 8 //目前是东八时区 需要改变时区时修改此属性值
    // tslint:disable-next-line: radix
    const date:Date = new Date(parseInt(d) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }
  setPermission(list) {
    list.forEach(item => {
      item.title === BTN_TITLE.EDIT_SAVE && (item.disabled = !(this.showEdit || this.isAllowEditRemark))
      item.title === BTN_TITLE.IMPORT_QTY_FROM_XLSX && (item.show = (this.allowEditQty))
    })
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  // getLableData(){
    // const list = LocalStorageHelper.getObject('TAKE_LABEL')
    // const takeType = list.find(item => item.id === this.detailData.stockTakeTypeId)
    // console.log(takeType);

    // const labelObj = this.getQueryByTitle(TITLE.STOCK_TAKE_TYPE)
    // labelObj.options = list
    // labelObj.value = takeType.code
    // labelObj.disabled = false
  // }
  setLabels() {
    const list = LocalStorageHelper.getObject('TAKE_LABEL')
    const takeType = list.find(item => item.id === this.detailData.stockTakeTypeId)
    this._stockTakeTypeValue = takeType.code
    // if(this.detailData.labelName){
      let ipt = this.queryData.ipts.find(item => item.title === TITLE.STOCK_TAKE_TYPE)
      ipt.value = takeType.name
    // }else{
      // const labels  = this.detailData.label.map(item=>item.labelName)
      // this.queryData.ipts[5].value = labels.toString()
    // }
  }

  disabledToStatus() {
    // const status = this.detailData.status
    // if(status !== 'Draft'){
    this.queryData.ipts.forEach(item =>{
      item.disabled = true
      if(item.title == TITLE.REMARKS){
        item.disabled = !this.isAllowEditRemark
      }
    })
    // }
  }

  loadDetailDataById(type='normal'){
    this.setLoading(true)
    this.route.queryParams.subscribe(params=>{
      if(params['id']){
        this.stockTakeService.searchDetailById(params["id"]).subscribe(res=>{
          this.setLoading(false)
          if(res?.data[0]){
            this.detailData = res.data[0]
            // this.setChannelContent(this.detailData.channelCode)
            this.initQueryData();
            this.getPermission(this.route)
            // this.getLableData();
          }
        },err=>{
          this.setLoading(false)
        })
      }
    })
  }

  //status=draft，可编辑head部分及加减item，但不能修改item内容，否则只能编辑和加减item
  initQueryData() {
    this.setSelectOption()
    // this.detailData = JSON.parse(sessionStorage.getItem('takeDetail'))
    // this.detailData = LocalStorageHelper.getObject('takeDetail');
    // this.status = this.detailData.status
    // console.log(this.detailData);
    this.stockTakeCount = this.detailData.stockTakeCount >= 2 ? 2 : this.detailData.stockTakeCount;

    const takeRefNo = this.getQueryByTitle(TITLE.STOCK_TAKE_REF_NO)
    takeRefNo.value = this.detailData.stockTakeNumber

    const asjustmentRefNo = this.getQueryByTitle(TITLE.STOCK_ADJUSTMENT_REF_NO)
    asjustmentRefNo.value = this.detailData.stockAdjustmentReferenceNumber

    const status = this.getQueryByTitle(TITLE.STATUS)

    let countByIpt = this.queryData.ipts.find(item => item.title === TITLE.COUNTBY)
    countByIpt.value = this.detailData.countBy
    let channelIpt = this.queryData.ipts.find(item => item.title === TITLE.CHANNEL)
    let dateIpt = this.queryData.ipts.find(item => item.title === TITLE.TARGETDATE)
    channelIpt.value = this.detailData.channelCode
    const createat = this.detailData.stockTakeDate
    dateIpt.value = this.convertDate(createat)

    let takeBuIpt = this.queryData.ipts.find(item => item.title === TITLE.TAKEBU)
    takeBuIpt.value = this.detailData.bu

    let remarkIpt = this.queryData.ipts.find(item => item.title === TITLE.REMARKS)
    remarkIpt.value = this.detailData.remark

    this.setLabels()

    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)
    status.value = statusObj.label

    this.loadCurrStatusId()

    this.isDraft = statusObj.value == TAKE_STATUS_ID.DRAFT;
    this.isPending = statusObj.value == TAKE_STATUS_ID.PREST;
    this.isCounting = (
      statusObj.value == TAKE_STATUS_ID.READY ||
      statusObj.value == TAKE_STATUS_ID.STIP1C ||
      statusObj.value == TAKE_STATUS_ID.STIP2C
    );


    this._savedLines = this.detailData.lines
    this.setTableContent(this.detailData.lines)
    // this.queryData.ipts[3].value = data.status
    // this.queryData.ipts[4].value = this.convertDate(this.detailData.create_at)

    // const takeDate = this.getQueryByTitle(TITLE.TARGET_STOCK_TAKE_DATE)
    // const date = new Date(this.detailData.stockTakeDate)
    // takeDate.value = date
    // takeDate.minDate = date


    // const surprise = this.getQueryByTitle('surprise stock take')
    // surprise.value = this.detailData.isSurpriseStockTake === "Y" ? ["surprise stock take"] : [];
    this.statusChanged(this.status)
    // reaction(
    //   ()=>status.value,
    //   (data)=>{
    //     this.statusChanged(data)
    //   }
    // )

    // console.log('initial data:');
    // const channelObj = this.getQueryByTitle(TITLE.CHANNEL)
    // reaction (
    //   () => channelObj.value,
    //   (data) => {
    //     this.queryItems(data)
    //   }
    // );

    // reaction (
    //   () => this.tableData.ipt.value,
    //   (data) => {
    //     this.setItemStatus(data)
    //     this.addItem()
    //   }
    // );
    this.repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE').filter(repo=>repo.children.length)
    this.channelId=LocalStorageHelper.getObject('REPO').find(sub=>sub.label===this.detailData.channelCode)
    this.checkActionDisable(this.channelId)
    if(!this.isActionEnable) {
      this.showMessage('warn','System', 'No permission to edit, redirect back to Stock take search.')
      this.router.navigate(["main", "stock_take", "search"]);
    }
  }

  checkActionDisable(channelId){
    this.isActionEnable=CommonMethod.haveChannelPermission(channelId.id)
    return this.isActionEnable
  }

  isSavedItem(itemCode){
    // sub.name.split(' ~ ')[0]
    return this._savedLines.some(line=>line.itemCode == itemCode)
  }
  // getSelectedItemCode(val){
  //   return val.name.split(' ~ ')[0]
  // }
  // setItemStatus(val){
  //   console.log('---->', val);
  //   // handle status here
  //   // item.edit == true ==> unremovable
  //   switch(this.status){
  //     case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS:
  //       val.forEach(item => {
  //         item.edit = true
  //       });
  //       break;
  //     case STATUS_TITLE.PRE_STOCK_TAKE:
  //     case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1:
  //     case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2:
  //       val.forEach(item => {
  //         let isSavedItem = this.isSavedItem(this.getSelectedItemCode(item))
  //         item.edit = isSavedItem
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  //   // if(this.status === STATUS_TITLE.STOCK_TAKE_IN_PROGRESS){
  //   //   val.forEach(item => {
  //   //     item.edit = true
  //   //   });
  //   // }
  // }

  _haveSerialControlItem = false
  set haveSerialControlItem(e){this._haveSerialControlItem=e}
  get haveSerialControlItem(){return this._haveSerialControlItem}

  // have set 2nd qty value by 1st data logic in this function
  setTableContent(list) {
    // console.log('------',list);

    let items = []
    const status = this.detailData.status
    let _items: any = {};
    list.forEach((item) => {
      this._isSavedByCode[item.itemCode] = true;
      let el: any = _items[item.itemCode]?_items[item.itemCode]: {
        code: item.lineId,
        itemMasterId: item.itemId,
        itemCode : item.itemCode,
        itemDesc : item.itemDesc,
        stockCondition : item.stockCondition,
        // normal : (item.stockCondition==='FG'?item.qtyCount:item.normal),
        // faulty : (item.stockCondition==='FAULTY'?item.qtyCount:item.faulty),
        actionBy: item.actionBy,
        disabled: status === 'Draft' ? true : false,
        _countBookmark: {},
        _data: [],
        // isSerialControl: this.getIsSerialControl(this.detailData, item),
        isSerialControl:{},
      }
      if(!el._countBookmark[item.countType]) el._countBookmark[item.countType] = {}
      if(!el._data)el._data=[];
      let isSerialControl = this.getIsSerialControl(this.detailData, item)
      if(isSerialControl) this.haveSerialControlItem = true
      el.isSerialControl[item.countType] = isSerialControl // split isSerialControl by count type
      el._data.push(item)

      let conditions = LocalStorageHelper.getObject('CONDITIONS')
      let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
      let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY'

      // set 2nd count qty for 1st count no variance item
      if(item.stockCondition===fgCode){
        // el[`normal`+`${item.countType==1?'':('_'+item.countType)}`] = item.qtyCount
        // el[(`normal`+`${item.countType==1?'':('_'+item.countType)}`)+`_variance`] = item.qtyVariance
        let prefix = 'normal'
        el[this.genFNCountKey(prefix,item.countType)] = item.qtyCount
        el[this.genFNCountKey(prefix,item.countType, '_before')] = item.qtyCount
        el[this.genFNCountKey(prefix,item.countType,'_variance')] = item.qtyVariance
        el._countBookmark[item.countType] = {...el._countBookmark[item.countType], normalLineId: item.lineId}

        if(
          item.countType>1 &&
          this.stockTakeCount==item.countType
        ){
          // last count have no variance
          // let lastCountType = item.countType-1;
          let lastVariance = el[this.genFNCountKey(prefix,item.countType-1,'_variance')]
            if(lastVariance == 0){
            // last count no variance
            el[this.genFNCountKey(prefix,item.countType)] = el[this.genFNCountKey(prefix,item.countType-1)]
            el[this.genFNCountKey(prefix,item.countType,'_disabled')] = true
          }
        }
      }
      if(item.stockCondition===faultyCode){
        // el[`faulty`+`${item.countType==1?'':('_'+item.countType)}`] = item.qtyCount
        // el[(`faulty`+`${item.countType==1?'':('_'+item.countType)}`)+`_variance`] = item.qtyVariance
        let prefix = 'faulty'
        el[this.genFNCountKey(prefix,item.countType)] = item.qtyCount
        el[this.genFNCountKey(prefix,item.countType, '_before')] = item.qtyCount
        el[this.genFNCountKey(prefix,item.countType,'_variance')] = item.qtyVariance
        el._countBookmark[item.countType] = {...el._countBookmark[item.countType], faultyLineId: item.lineId}

        if(
          item.countType>1 &&
          this.stockTakeCount==item.countType
        ){
          let lastCountType = item.countType-1;
          let lastVariance = el[this.genFNCountKey(prefix,item.countType-1,'_variance')]
          if(lastVariance == 0){
            // last count no variance
            el[this.genFNCountKey(prefix,item.countType)] = el[this.genFNCountKey(prefix,item.countType-1)]
            el[this.genFNCountKey(prefix,item.countType,'_disabled')] = true
          }
        }
      }
      el._data.push(item)

      _items[item.itemCode] = el
    });
    for(let key in _items){
      if(_items.hasOwnProperty(key)) {
        items.push(_items[key])
      }
    }
    items.forEach(item=>{
      item.isOldData = true
      item._item = item.itemMasterId
    })

    let _actionBy = [...new Set(items.map(item=>{return {name:item.actionBy,value:item.actionBy}}))].filter(actionBy=>actionBy.name&&actionBy.name.length>0)
    // this.actionByIpt.options = _actionBy
    // this.actionByOptions = _actionBy
    // let actionByIpt = this.filterPanel.ipts.find(ipt=>ipt.title==QUERYTITLE.ACTIONBY)
    // if(actionByIpt)actionByIpt.options = _actionBy
    this.tableData.data = items
    // this.tableData.dataDisplay = this.tableData.data

    this.disabledToStatus()

    if(this.isCountingQty && this.stockTakeCount == 2) {
      this.setLoading(true)
      this.setTableContentBySerialControl(items).subscribe(res=>{
        this.setLoading(false)
      },err=>{
        this.setLoading(false)
      })
    }
  }

  // have set 2nd serial value by 1st data logic in this function
  setTableContentBySerialControl(items){
    if(this.stockTakeCount!=2) return of(null)

    // gen forkjoin obs
    let obsList:Observable<any>[] = []
    let _items = items.filter(e=>e.isSerialControl[this.stockTakeCount])
    if(this.stockTakeCount == 2) return this.stockTakeService.compareAllSerial({stockTakeId: this.detailData.id, countType: 1}).pipe(
      switchMap(res=>{
        if(res.code != '000') throw res.msg || res.message
        return forkJoin(this.getObsListForInitSerialListForSecondCount(_items,res.data))
      })
    )
  }

  getObsListForInitSerialListForSecondCount(items, serialCompareResultList){
    let obsList:Observable<any>[] = []
    let _items = items.filter(e=>e.isSerialControl[this.stockTakeCount])
    let serialListMappedByTakeLineId = {}
    serialCompareResultList.forEach(serial => {
      if(!serialListMappedByTakeLineId[serial.stockTakeLineId]) serialListMappedByTakeLineId[serial.stockTakeLineId] = []
      serialListMappedByTakeLineId[serial.stockTakeLineId].push(serial)
    });
    _items.forEach(item => {
      for(let countType in item._countBookmark){
        let _countType = Number.parseInt(countType)
        if(
          this.countBy==this._countByList['SN'] && _countType > 1 && item.isSerialControl[_countType] && item.isSerialControl[_countType-1]==true
        ){
          // last count have serial control
          // only do previous count have value item
          if(item[this.genFNCountKey('normal',_countType-1)] > 0){
            obsList = [
              ...obsList,
              this.processItemDataWithCompareSerialWithSerialList(serialListMappedByTakeLineId, item, 'normal', _countType),
            ]
          }
          if(item[this.genFNCountKey('faulty',_countType-1)] > 0){
            obsList = [
              ...obsList,
              this.processItemDataWithCompareSerialWithSerialList(serialListMappedByTakeLineId, item, 'faulty', _countType),
            ]
          }
        }

        if(
          this.countBy==this._countByList['QTY'] && _countType > 1 && item.isSerialControl[_countType]
        ){
          // only do previous count have value item
          if(item[this.genFNCountKey('normal',_countType-1,'_variance')] == 0 && item[this.genFNCountKey('normal', _countType-1)] > 0){
            obsList = [
              ...obsList,
              this.processItemDataWithCompareSerialWithSerialList(serialListMappedByTakeLineId, item, 'normal', _countType, true),
            ]
          }
          if(item[this.genFNCountKey('faulty',_countType-1,'_variance')] == 0 && item[this.genFNCountKey('faulty', _countType-1)] > 0){
            obsList = [
              ...obsList,
              this.processItemDataWithCompareSerialWithSerialList(serialListMappedByTakeLineId, item, 'faulty', _countType, true),
            ]
          }
        }
      }
    });
    return [null, ...obsList]
  }

  // force = true when count by qty and 1st count no variance, force to copy serial from compare serial api to 2nd count
  processItemDataWithCompareSerial(item, condition, countType, force = false){
    // return obs per item counttype 2
    // countType should > 1
    // let lastQtyHaveVariance = item[this.genFNCountKey(condition,countType-1>1?countType-1:'','_variance')]
    if(item[this.genFNCountKey(condition,countType-1)]==0) return of(null) // last count value, dont need to check
    if(item[this.genFNCountKey(condition,countType)]>0) return of(null) // current count have value, dont need to check
    let lastCountObs = this.getSerialList(item._countBookmark[countType-1][`${condition}LineId`])
    // let thisCountObs = this.getSerialList(item._countBookmark[countType][`${condition}LineId`])
    let lineId = item._countBookmark[countType][`${condition}LineId`];
    return lastCountObs.pipe(tap((last)=>{
      let lastFullMatchSerial = []
      let lastCountHaveSerialVariance = false
      last.forEach(element => {
        if(
          force ||
            element.inQtyOnHandSerial==SerialCompareValue.YES
          && element.inStockTakeSerial==SerialCompareValue.YES
        ){
          lastFullMatchSerial.push(element.serial)
        }else{
          if(!lastCountHaveSerialVariance)lastCountHaveSerialVariance = true
        }
      });

      // let currentCountHaveSerial = false;
      // current.forEach(element => {
      //   if(
      //     !currentCountHaveSerial &&
      //     element.inStockTakeSerial==SerialCompareValue.YES
      //   ){
      //     currentCountHaveSerial = true
      //   }
      // });

      // if(currentCountHaveSerial){
      //   // case: 2st count have serial, do nothing here
      // }else{
        // 2st count dont have serial input
      if(!lastCountHaveSerialVariance){
        // case: 1st count no serial variance
        this.serialListBuffer[lineId] = {
          _data: [], // old data, this case should be empty
          appendList: lastFullMatchSerial,
          deleteList: [],
          existList: [],
          _field: `${condition}${countType>1?'_'+countType:''}`,
          serialList: lastFullMatchSerial
        }
        item[this.genFNCountKey(condition,countType)] = lastFullMatchSerial.length
        item[this.genFNCountKey(condition,countType>1?countType:'','_serial_full_copied')] = true
      }else{
        // case: 1st count have serial variance
        this.serialListBuffer[lineId] = {
          _data: [], // old data, this case should be empty
          appendList: lastFullMatchSerial,
          deleteList: [],
          existList: [],
          _field: `${condition}${countType>1?'_'+countType:''}`,
          serialList: lastFullMatchSerial
        }
        item[this.genFNCountKey(condition,countType)] = lastFullMatchSerial.length
        item[this.genFNCountKey(condition,countType>1?countType:'','_serial_partial_copied')] = true
      }
      // }
      let dataIdx = this.tableData.data.findIndex(_data=>_data.itemCode == item.itemCode)
      this.tableData.data[dataIdx] = item;
      // this.tableData.data[dataIdx] = [...this.tableData.data[dataIdx]]
    }))
  }
  // force = true when count by qty and 1st count no variance, force to copy serial from compare serial api to 2nd count
  processItemDataWithCompareSerialWithSerialList(serialList, item, condition, countType, force = false){
    // return obs per item counttype 2
    // countType should > 1
    // let lastQtyHaveVariance = item[this.genFNCountKey(condition,countType-1>1?countType-1:'','_variance')]
    if(item[this.genFNCountKey(condition,countType-1)]==0) return of(null) // last count value, dont need to check
    if(item[this.genFNCountKey(condition,countType)]>0) return of(null) // current count have value, dont need to check
    let _serialList = serialList[item._countBookmark[countType-1][`${condition}LineId`]]
    if(!_serialList) return of(null)
    // let lastCountObs = this.getSerialList(item._countBookmark[countType-1][`${condition}LineId`])
    // let thisCountObs = this.getSerialList(item._countBookmark[countType][`${condition}LineId`])
    let lineId = item._countBookmark[countType][`${condition}LineId`];
    // return lastCountObs
    return of(_serialList).pipe(tap((last)=>{
      let lastFullMatchSerial = []
      let lastCountHaveSerialVariance = false
      last.forEach(element => {
        if(
          force ||
            element.inQtyOnHandSerial==SerialCompareValue.YES
          && element.inStockTakeSerial==SerialCompareValue.YES
        ){
          lastFullMatchSerial.push(element.serial)
        }else{
          if(!lastCountHaveSerialVariance)lastCountHaveSerialVariance = true
        }
      });

      // let currentCountHaveSerial = false;
      // current.forEach(element => {
      //   if(
      //     !currentCountHaveSerial &&
      //     element.inStockTakeSerial==SerialCompareValue.YES
      //   ){
      //     currentCountHaveSerial = true
      //   }
      // });

      // if(currentCountHaveSerial){
      //   // case: 2st count have serial, do nothing here
      // }else{
        // 2st count dont have serial input
      if(!lastCountHaveSerialVariance){
        // case: 1st count no serial variance
        this.serialListBuffer[lineId] = {
          _data: [], // old data, this case should be empty
          appendList: lastFullMatchSerial,
          deleteList: [],
          existList: [],
          _field: `${condition}${countType>1?'_'+countType:''}`,
          serialList: lastFullMatchSerial
        }
        item[this.genFNCountKey(condition,countType)] = lastFullMatchSerial.length
        item[this.genFNCountKey(condition,countType>1?countType:'','_serial_full_copied')] = true
      }else{
        // case: 1st count have serial variance
        this.serialListBuffer[lineId] = {
          _data: [], // old data, this case should be empty
          appendList: lastFullMatchSerial,
          deleteList: [],
          existList: [],
          _field: `${condition}${countType>1?'_'+countType:''}`,
          serialList: lastFullMatchSerial
        }
        item[this.genFNCountKey(condition,countType)] = lastFullMatchSerial.length
        item[this.genFNCountKey(condition,countType>1?countType:'','_serial_partial_copied')] = true
      }
      // }
      let dataIdx = this.tableData.data.findIndex(_data=>_data.itemCode == item.itemCode)
      this.tableData.data[dataIdx] = item;
      // this.tableData.data[dataIdx] = [...this.tableData.data[dataIdx]]
    }))
  }

  genFNCountKey(condition, countType, suffix=''){
    return condition+`${countType==1?'':('_'+countType)}`+suffix
  }
  /* setItemsContent(){
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }
    const codeList = this.detailData.lines.map(line => line.itemCode).filter(distinct)
    // console.log(codeList)
    let list = []
    // const itemObj = this.getQueryByTitle('Item')
    // const itemObj = this.tableData.ipt
    // codeList.forEach((item) => {
    //   const result = itemObj.options.find(sub => sub.name.split(' ~ ')[0] === item)
    //   // tslint:disable-next-line: no-unused-expression
    //   result && list.push(result)
    // });
    // itemObj.value = list
  } */
  setChannelContent(val) {
    // const channelObj = this.getQueryByTitle(TITLE.CHANNEL)
    const repos = LocalStorageHelper.getObject("REPO");
    const channel = repos.find(item => item.label === this.detailData.channelCode)
    //const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE')
    //channelObj.options = repoList
    //let list = []
    //repoList.forEach(item => {
      //list = [...list, item, ...item.children]
    //});

    //const result = list.find(item => item.label === val);
    // console.log(result);
    //channelObj.showValue = val
    // channelObj.value = channel.id
    this.queryItems(channel.id, true)
  }
  queryItems(channel, flag=false) {

    this.setLoading(true)
    HttpHelper.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, {"dtlRepoId": channel}).then(res => {
      // console.log('Query items:', res);
      // const itemObj = this.getQueryByTitle('Item')
      const itemObj = this.tableData.ipt
      let skus = []
      itemObj.options = []
      itemObj.value = null
      // this.tableData.data = []
      if(res.code === '000' && res.data.length > 0){
        res.data.forEach(sku => {
          const name = sku.skuDesc ? sku.skuCode +'-' + sku.skuDesc : sku.skuCode
          // skus.push({code: sku.dtlSkuId, other: null, name})
          skus.push({
            name,
            _data: sku,
            code: sku.dtlSkuId,
            itemCode: sku.skuCode,
            desc: sku.skuDesc,
          })
        })
      } else {
        skus = []
        // tslint:disable-next-line: no-unused-expression
        channel?.id && this.showMessage('warn', this.info.title, this.info.notItems)
      }
      this.changeItemOptions(skus, flag)
    }).catch(e => console.log('error', e)).finally(() => {
      // console.log('Query items completed!')
      this.setLoading(false)
    })
  }
  changeItemOptions(list, flag) {
    // this.queryData.ipts[3].options = list
    this.tableData.ipt.options = list
    /* this.tableData.data.forEach(tableData=>{
      if(!tableData._item){
        let item = list.find(item=>item.itemCode == tableData.itemCode)
        tableData._item = item?.code || ''
      }
    }) */

    //设置由view page 跳转过来的数据
    // tslint:disable-next-line: no-unused-expression
    // flag && this.setItemsContent()
  }
  setSelectOption() {
    const channelObj = this.getQueryByTitle(TITLE.CHANNEL)
    const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMGROUP')
    // console.log(repoList);

    channelObj.options = repoList

    const takeBuList = LocalStorageHelper.getObject('STOCK_TAKE_BU').map(item=>{return {
      label: item.code,
      value: item.id,
    }})
    let takeBuipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TAKEBU)
    takeBuipt.options = takeBuList
  }

  validationData() {
    let flag = true
    this.queryData.ipts.forEach(ipt => {
      if(ipt.title === 'Channel' && !ipt.value){
        ipt.error = 'error'
        this.showMessage('warn', this.info.title, this.info.selectChannel)
        flag = false
      }
      /* if(ipt.title === 'Item' && (!ipt.value || ipt.value.length === 0)){
        ipt.error = 'error'
        this.showMessage('warn', this.info.title, this.info.selectItem)
        flag = false
      } */
    })
    let _item = []
    this.tableData.data.some(row => {
      if(!row.itemCode) {
        const info = 'Item Code and Item Description'
        this.showMessage('warn', this.info.title, this.info.labelRequired.replace('label', info))
        flag = false
        return true
      }
      if(_item.indexOf(row.itemCode) > -1) {
        this.showMessage('warn', this.info.title, this.info.duplicated + ': ' + row.itemCode)
        flag = false
        return true
      } else {
        _item.push(row.itemCode)
        return false
      }
    })
    return flag
  }
  // addItem() {
  //   this.tableData.data.push(
  //     {
  //       // code: item.code,
  //       // itemCode : item.name.split(' ~ ')[0],
  //       // itemDesc : item.name.split(' ~ ')[1],
  //       // normal : this.status.includes('Progress') ? (this.status.includes('2') ? 0 : null) : 0,
  //       // faulty : this.status.includes('Progress') ? (this.status.includes('2') ? 0 : null) : 0,
  //       normal : this.status.includes('Progress') ? null : 0,
  //       faulty : this.status.includes('Progress') ? null : 0,
  //       ...(this.status.includes('Progress') && this.status.includes('2') && {normal_2: null}),
  //       ...(this.status.includes('Progress') && this.status.includes('2') && {faulty_2: null}),
  //       // normal_2 : 0,
  //       // faulty_2 : 0,
  //       isNew: true,
  //       disabled: false
  //     }
  //   )
  //   // if(this.validationData() && this.tableData.ipt.value) {
  //   //   let list = []
  //   //   const status = this.detailData.status
  //   //   this.tableData.ipt.value.forEach(item => {
  //   //     const takeItem = this.tableData.data.find(sub => sub.itemCode === item.name.split(' ~ ')[0])
  //   //     if(takeItem){
  //   //       list.push(takeItem)
  //   //     }else{

  //   //       let data: any = {
  //   //         code: item.lineId,
  //   //         itemMasterId: item.code,
  //   //         itemCode : item.name.split(' ~ ')[0],
  //   //         itemDesc : item.name.split(' ~ ')[1],
  //   //         // normal : null,
  //   //         // faulty : null,
  //   //         disabled: status === 'Draft' ? true : false
  //   //       }
  //   //       data[`normal${this.stockTakeCount==1?'':('_'+this.stockTakeCount)}`] = 0;
  //   //       data[`faulty${this.stockTakeCount==1?'':('_'+this.stockTakeCount)}`] = 0;
  //   //       list.push(data)
  //   //     }
  //   //   });
  //   //   this.tableData.data = list
  //   // }
  // }
  delete(idx, data) {
    // 排除原有数据disabled为true的
    if(data.isOldData && !data.disabled) {
      return
    }
    let statusObj = this.takeStatus.find(status=>status.label == this.detailData.status)

    if(
      statusObj.value == TAKE_STATUS_ID.DRAFT
      || statusObj.value == TAKE_STATUS_ID.PREST
      || statusObj.value == TAKE_STATUS_ID.STIP1C
      || statusObj.value == TAKE_STATUS_ID.STIP2C
    ) {
      this.conf.confirm({
        message: `Do you want to delete these 1 record?`,
        header: 'Delete Confirmation',
        accept: () => {
          // this.tableData.ipt.value.splice(idx, 1)
          // console.log(this.tableData.ipt.value);
          this.tableData.data.splice(idx, 1)
          /* if(!this.tableData.ipt.value.length){
            this.tableData.ipt.value = null
          } */
        }
      });
    }
  }
  disableAll(){
    this.queryData.btns.forEach((item, idx) => {
      if(idx > 0){
        item.disabled = true
      }
    })
    this.queryData.ipts.forEach(item => {
      item.disabled = true
    })
    this.tableData.data.forEach(item => {
      item.disabled = true
    })
  }
  saveTake() {
    if(!this.validationData()){
      return
    }

    const labellist = LocalStorageHelper.getObject('TAKE_LABEL')
    const takeType = labellist.find(item => item.id === this.label)
    const stockTakeTypeId = takeType.id
    let remarks = this.getQueryByTitle(TITLE.REMARKS).value

    let items = []
    let itemsWithNewItemBeforeChangeQty = [];
    let isNull = false
    let isNegative = false
    let errorItem = ''
    this.tableData.data.forEach(item => {
      if((item.faulty === null || item.normal === null) && this.status.includes('1') ||
        ((item.faulty_2 === null || item.normal_2 === null) && this.status.includes('2'))) {
        errorItem = item.itemCode
        isNull = true
      }
      if((item.faulty < 0 || item.normal < 0) && this.status.includes('1') ||
        ((item.faulty_2 < 0 || item.normal_2 < 0) && this.status.includes('2'))) {
        errorItem = item.itemCode
        isNegative = true
      }
      //normal(FG-1001) & faulty(FAULTY-1002)
      for(let i = 1; i <= this.stockTakeCount; i++){
        if(i == this.stockTakeCount){
          const tempNormalItem = {
            id: this.getLineId(item, i, 'normalLineId'),
            itemId: item.itemMasterId,
            stockConditionId: "1001",
            qtyCount: item[`normal${i==1?'':('_'+i)}`],
            countType: i,
            actionBy: item.actionBy
          };
          items.push(tempNormalItem)
          const tempFaultyItem = {
            id: this.getLineId(item, i, 'faultyLineId'),
            itemId: item.itemMasterId,
            stockConditionId: "1002",
            qtyCount: item[`faulty${i==1?'':('_'+i)}`],
            countType: i,
            actionBy: item.actionBy
          };
          items.push(tempFaultyItem)

          const tempNormalItemBefore = {
            id: this.getLineId(item, i, 'normalLineId'),
            itemId: item.itemMasterId,
            stockConditionId: "1001",
            qtyCount: item[this.genFNCountKey('normal', i, '_before')] || 0,
            countType: i,
            actionBy: item.actionBy || null
          };
          itemsWithNewItemBeforeChangeQty.push(tempNormalItemBefore)
          const tempFaultyItemBefore = {
            id: this.getLineId(item, i, 'faultyLineId'),
            itemId: item.itemMasterId,
            stockConditionId: "1002",
            qtyCount: item[this.genFNCountKey('faulty', i, '_before')] || 0,
            countType: i,
            actionBy: item.actionBy || null
          };
          itemsWithNewItemBeforeChangeQty.push(tempFaultyItemBefore)
        }
      }
    })
    if(isNull){
      this.showMessage('warn', this.info.title, this.info.notNull.replace('item', 'item ' + errorItem + ' '))
      return
    }
    if(isNegative){
      this.showMessage('warn', this.info.title, this.info.notNegative.replace('item', 'item ' + errorItem + ' '))
      return
    }
    const status = {
      "ST-Review": 3,
      "ST-Conp": 4,
      "ST-ST": 2,
      "ST-Draft": 1,
    }
    let data = {
      accountName: this.commonService.getAccount(),
      id: this.detailData.id,
      channelId: this.channel.id,
      channelCode: this.detailData.channelCode,
      targetDate: this.createDate.getTime(),
      // isSurpriseStockTake: this.surprise && this.surprise.length > 0 ? true : false,
      remarks: remarks,
      stockTakeTypeId,
      statusId: status[this.detailData.statusCode],
      items
    }

    let itemsNeedToSumbitAndReloadLineId = items.filter(item=>!item.id)

    let beforeData = {
      accountName: this.commonService.getAccount(),
      id: this.detailData.id,
      channelId: this.channel.id,
      channelCode: this.detailData.channelCode,
      targetDate: this.createDate.getTime(),
      // isSurpriseStockTake: this.surprise && this.surprise.length > 0 ? true : false,
      remarks: remarks,
      stockTakeTypeId,
      statusId: status[this.detailData.statusCode],
      items: itemsWithNewItemBeforeChangeQty
    }

    this.setLoading(true)

    of(null)
    .pipe(
      switchMap(res=>{
        // save when counting qty, call update take to update remarks
        // then update line qty and serial for changed line
        if(itemsNeedToSumbitAndReloadLineId.length == 0 && this.isCountingQty) return this.saveStockTakeObs(beforeData);
        // update take for add item, also update remark
        if(itemsNeedToSumbitAndReloadLineId.length > 0 && this.isCountingQty) return this.saveStockTakeObs(beforeData).pipe(
          switchMap(res=>{
            // get updated line
            return this.stockTakeService.searchDetailById(this.detailData.id)
          }),
          tap(res=>{
            // replace new created line id
            items.forEach(item=>{
              if(!item.id){
                let savedLine = res.data[0].lines.find(line=>{
                  return line.itemId == item.itemId &&
                    line.countType == item.countType &&
                    CONFIG.CONDITION_CODE[line.stockCondition] == item.stockConditionId
                })
                if(savedLine){
                  item.id = savedLine.lineId
                  if(this.serialListBuffer[`dummy_${item.itemId}_${item.stockConditionId}_${item.countType}`]){
                    this.serialListBuffer[savedLine.lineId] = this.serialListBuffer[`dummy_${item.itemId}_${item.stockConditionId}_${item.countType}`]
                    delete this.serialListBuffer[`dummy_${item.itemId}_${item.stockConditionId}_${item.countType}`]
                  }
                }else{
                  throw 'Unexpected Error Occurred'
                }
              }
            })
            data.items = items
          }),
        )
        // skip to update whole take by isCountingQty
        return of(null)
      }),
      switchMap(res=>{
        return this.updateNeedToUpdateSerialDataByDbData()
      }),
      switchMap(res=>{
        return this.saveAllSerial()
      }),
      switchMap(res=>{
        if(this.isCountingQty) return this.updateTakeLineQty(data)
        if(!this.isCountingQty) return this.saveStockTakeObs(data)
        return of(null)
      }),
    )
    .subscribe(res=>{
      this.setLoading(false)
      this.showMessage('success', this.info.title, this.info.saveSuccess)
      this.disableAll()
      this.router.navigate(["main", "stock_take", "detail"], {queryParams: {id: this.detailData.id}});
    },e=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, this.info.saveFail)
      CommonMethod.consoleError(this.info.title, this.info.saveFail, e)
    })
  }

  saveStockTakeObs(data){
    return this.stockTakeService.saveStockTake(data).pipe(map(r => {
      if(r.code==='000'){
        return r
      }else{
        throw r.msg
      }
    }))
  }

  updateTakeLineQty(data){
    return this.updateTakeLine(data).pipe(map(r => {
      if(r.code==='000'){
        return r
      }else{
        throw r.msg
      }
    }))
  }

  updateNeedToUpdateSerialDataByDbData(){
    // compare updated serial list and db serial list, get new append, delete list
    let obsList = []
    Object.keys(this.serialListBuffer).forEach(key => {
      // this.serialListBuffer[key]
      let stockTakeLineId = key

      if(
        ( !this.serialListBuffer[key].appendList ||
          this.serialListBuffer[key].appendList?.length == 0 )&&
        ( !this.serialListBuffer[key].deleteList ||
          this.serialListBuffer[key].deleteList?.length == 0 )
      ){
        // no differ compare with origin data (get origin data when click scan / scan all and store when serial popup save)
        // skip process to not change other's updated data back to before update version
        // serialListBuffer valid when scan button clicked, for serialListBuffer not updated by current session,
        // should not use the unchanged data to update again to prevent user a updated and user b rollback the data
        return
      }
      let obs = this.stockTakeService.getSerial({ stockTakeLineId: stockTakeLineId }).pipe(
        map(res=>{
          if(res.code!=='000') throw res.msg
          return res.data
        }),
        tap(res=>{
          this.serialListBuffer[key]._data = res;
          this.serialListBuffer[key].existList = res;
          let dbSerialList:string[] = res.map(e=>e.serial);
          let _appendList = this.serialListBuffer[key].serialList.filter(e=>!dbSerialList.includes(e));
          let _deleteTakeLineSerialIdList = res.filter(e=>!this.serialListBuffer[key].serialList.includes(e.serial)).map(e=>e.stockTakeLineSerialId);
          this.serialListBuffer[key].appendList = _appendList;
          this.serialListBuffer[key].deleteList = _deleteTakeLineSerialIdList;
        })
      )
      obsList.push(obs)
    })
    return CommonMethod.forkjoinSync(obsList)
  }

  resetTakeDetail(obj) {
    // const skus = LocalStorageHelper.getObject("SKU");
    const repos = LocalStorageHelper.getObject("REPO");
    const statusCode = {
      1: "DRAFT",
      2: "READY",
      3: "REVIEW",
      4: "COMPLETED",
    };
    const status = {
      1: "Draft",
      2: "Ready for Stock Take",
      3: "Review In Progress",
      4: "Completed",
    };
    const repo = repos.find(item => item.id === obj.channelId)

    /* let num = 0
    const lines = obj.line.map(line => {
      const el = {
        lineItemId: num++,
        itemMasterId: line.item.id,
        itemCode: line.item.itemCode,//sku.name.split(" ~ ")[0],
        itemDesc: line.item.itemDesc,//sku.flag,
        // natureList: [
        //   { variance: item.natureList[0].balanceDiff, stockTakeQty: item.natureList[0].stockTakeQty, natureName: "Normal" },
        //   { variance: item.natureList[0].balanceDiff, stockTakeQty: item.natureList[1].stockTakeQty, natureName: "Faulty" },
        // ],
      };
      return el
    }); */

    const data = {
      accountName: "admin",
      stockTakeDate: this.createDate.getTime(),
      create_by: obj.createBy,
      isSurpriseStockTake: obj.isSurpriseStockTake,
      update_at: obj.updateAt,
      id: obj.id,
      stockTakeNumber: obj.stockTakeNumber,
      create_at: obj.createAt,
      update_by: obj.updateBy,
      stockAdjustmentReferenceNumber: null,
      stockTakeTypeId: obj.stockTakeTypeId,
      // lines,
      channelCode: repo.label,
      statusCode: statusCode[obj.statusId],
      status: status[obj.statusId],
    };
    HttpHelper.post(URLDICT.STOCK_TAKE_SEARCH_DETAIL, data).then(res => {
      // this.tablePannel.loadingSwitch = false
      if (res.code === '000' && res.data.length > 0) {
        LocalStorageHelper.setObject("takeDetail", res.data[0])
        this.router.navigate(["main", "stock_take", "detail"], {queryParams: {id: res.data[0].id}});
      }
    })
    // sessionStorage.setItem("takeDetail", JSON.stringify(data));
  }
  back() {
    // window.history.back()
    this.router.navigate(["main", "stock_take", "detail"], {queryParams: {id: this.detailData.id}});
  }

  updateTakeLine(_data){
    if(!this.isCountingQty) return of()
    let data = {
      items: [],
    }
    let oldData = this.detailData.lines.map(_line=>{return {id: _line.lineId, qtyCount: _line.qtyCount}})
    let newData = _data.items.map(_line=>{return {id: _line.id, qtyCount: _line.qtyCount}})
    let oldDataMapping = CommonMethod.jsonObjArrayToKeyValueObject(oldData, 'id', 'qtyCount')
    let dataNeedToUpdate = newData.filter(_line=>oldDataMapping[_line.id]!=_line.qtyCount)
    data.items = dataNeedToUpdate
    return this.stockTakeService.updateTakeLine(data)
  }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  statusChanged(status){
    switch(status){
      case STATUS_TITLE.DRAFT:
        this.tableData.head.forEach(head=>{
          // head.edit = head.key == 'itemDesc';
        })
        break;
      case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_1:
      case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS_2:
        this.tableData.head.forEach(head=>{
          head.edit = this.stockTakeCount != null ? head.count == this.stockTakeCount: false;
        })
        break;
      case STATUS_TITLE.PRE_STOCK_TAKE:
      case STATUS_TITLE.STOCK_TAKE_IN_PROGRESS:
      default:
        this.tableData.head.forEach(head=>{
          head.edit = false;
        })
        break;
    }
  }

  tableItemChange(e, item) {
    if (e.value) {
      let selectedItem = this.tableData.ipt.options.find(option => {
        return option.code === e.value
      })
      item.itemCode = selectedItem.itemCode
      item.itemDesc = selectedItem.desc
      item.itemMasterId = selectedItem.code
      item.code = e.value // set from html ngModel
    } else {
      item.itemCode = ''
      item.itemDesc = ''
      item.itemMasterId = ''
      item.code = ''
    }
    item.normal = this.status.includes('1') ? null : item.normal
    item.faulty = this.status.includes('1') ? null : item.faulty
    item.normal_2 = null
    item.faulty_2 = null
  }

  loadItemListBySkuModule(){
    return this.commonService.getSkuModuleList().pipe(
      switchMap(res=>{
        return this.stockCommonService.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      tap(res=>{
        if (res) {
          let _items = []
          res.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          this.itemList = _items;
        }
      })
    )
  }

  initBulkAddPanel(){
    this.bulkAddQueryPanel={
      ipts:[
        {
          title: TITLE.BULKADD_FILTER,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // wip: hardcode, will store at db
            // {code:1,name:'Items By Brand Name'},
            // {code:2,name:'Items With Available Stock'},
            // {code:3,name:'All Items'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.BULKADD_BRAND,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // wip: hardcode, will store at db
            // {code:1,name:'CSL'},
            // {code:2,name:'Apple'},
            // {code:3,name:'Google'},
            // {code:4,name:'XIAOMI'},
            // {code:5,name:'MIJIA'},
          ],
          disabled: true,
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.BULKADD_ITEM,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
          value: null,
          options: [],
          disabled: true,
          optionLabel: "itemDesc",
          optionValue: "id"
        },
      ],
      btns:[
        {
          title: 'Add Items',
          class: "p-ml-auto p-order-0 p-mr-1",
          show: true,
          handler: { click: () => this.bulkAdd() }
        },
      ],
      btnsclass: 'p-d-flex p-col-12',
    }
  }

  bulkAdd(){
    let itemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SELECT_ITEM);
    let serialitemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SERIALIZED_ITEM);
    let itemList = [...itemIpt.value?itemIpt.value:[], ...serialitemIpt.value?serialitemIpt.value:[]]
    this.addItemToTable(itemList)
    itemIpt.value = null;
    serialitemIpt.value = null;
  }

  // bulkAddOptionChanged(i,e,ipt){
  //   let filterIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_FILTER);
  //   let brandIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_BRAND);
  //   let itemIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_ITEM);
  //   if(ipt == filterIpt){
  //     switch(filterIpt.value){
  //       case 1:
  //         brandIpt.disabled = false;
  //         brandIpt.disabled = false;
  //         brandIpt.disabled = false;
  //         itemIpt.options = []
  //         itemIpt.value = null;
  //         break;
  //       case 2:
  //         brandIpt.disabled = true;
  //         brandIpt.value=null;
  //         itemIpt.disabled = false;
  //         itemIpt.options = []
  //         itemIpt.value = null;
  //         this.loadAvailableItemListByChannel();
  //         break;
  //       case 3:
  //         brandIpt.disabled = true;
  //         brandIpt.value=null;
  //         itemIpt.disabled = false;

  //         let _itemList = this.itemList
  //         .map(item=>{return{
  //           id: item._data.itemid,
  //           name: item._data.item + ' - ' + item._data.itemdesc,
  //           itemCode: item._data.item,
  //           desc: item._data.itemdesc
  //         }})
  //         itemIpt.options = _itemList;
  //         itemIpt.value = null;
  //         break;
  //       default:
  //         brandIpt.disabled = true;
  //         itemIpt.disabled = true;
  //         brandIpt.value = null;
  //         itemIpt.value = null;
  //         break;
  //     }
  //   }else if(ipt == brandIpt){
  //     itemIpt.disabled = false;
  //     itemIpt.options = []
  //     itemIpt.value = null;
  //     let brand = brandIpt.options.find(option=>option.code==brandIpt.value)
  //     if(brand){
  //       let _itemList = this.itemList
  //         .filter(item=>item._data.mfgbrand&&item._data.mfgbrand.toLowerCase()==brand.name.toLowerCase())
  //         .map(item=>{return{
  //           id: item._data.itemid,
  //           name: item._data.item + ' - ' + item._data.itemdesc,
  //           itemCode: item._data.item,
  //           desc: item._data.itemdesc
  //         }})
  //       if(_itemList.length>0){
  //         itemIpt.options = _itemList;
  //       }else{
  //         itemIpt.options = [];
  //       }
  //     }
  //   }
  // }

  loadAvailableItemListByChannel(){
    let channel = this.queryData.ipts.find(ipt=>ipt.title==TITLE.CHANNEL)
    let filterIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === TITLE.BULKADD_FILTER);
    if(channel.value&&filterIpt.value==2){
      let itemIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === TITLE.BULKADD_ITEM);
      this.getItemListByChannel().subscribe(res=>{
        let _res = res.filter(item=>{
          return item.totalOnHand!=null&&item.totalOnHand>0
        }).map(item=>{
          return {
            id: item.skuId,
            name: item.sku + ' - ' + item.skuDesc,
            itemCode: item.sku,
            desc: item.skuDesc
          }
        })
        itemIpt.options = _res
      })
    }
  }

  getItemListByChannel(){
    let channel = CommonMethod.findInChildren(this.repoList, 'key', this.detailData.channelCode, 'children')

    // let channel = this.queryData.ipts.find(ipt=>ipt.title==QUERYTITLE.CHANNEL)
    if(!channel) return null
    return this.stockCommonService.searchBalance({
      accountName: this.commonService.getAccount(),
      isIncludeLis: 0,
      pageIndex: 0,
      pageSize: 2147483647,
      shopId: [{id: channel.id, key: channel.key}],
      skuNum: [],
      sortEvent: {},
    })
  }

  assignModal = {
    visible: false,
    title: 'Assign Action By',
    inputTitle: 'Staff ID / Staff Name',
    staffId: '',
    table:{
      selectAll: false,
      data:[
      ],
      _data:[],
      head:[
        {key:'checkbox',title:'',type:'checkbox',width:'2.125rem ' },
        {key:'itemCode',title:'Item Code', width: '8rem', },
        {key:'itemDesc',title:'Item Description', width: '16rem'},
        {key:'actionBy',title:'Action By', width: '12rem'},
      ]
    }
  }

  showAssignModal(){
    let tableData = this.tableData.data.map(data=>{
      return {
        checkbox:false,
        itemCode:data.itemCode,
        itemDesc:data.itemDesc,
        actionBy:data.actionBy,
      }
    })
    this.assignModal.table.data = tableData;
    this.assignModal.table._data = tableData;
    this.assignModal.table.selectAll = false;

    // let _actionBy = [...new Set([...tableData.map(item=>{return item.actionBy}), ...'1'])]
    // .filter(actionBy=>actionBy&&actionBy.length>0)
    // .map(actionBy=>{return {
    //   name: actionBy,
    //   value: actionBy,
    // }})
    // // this.actionByIpt.options = _actionBy
    // // this.actionByOptions = _actionBy
    // let actionByIpt = this.filterPanel.ipts.find(ipt=>ipt.title==QUERYTITLE.ACTIONBY)
    // if(actionByIpt)actionByIpt.options = _actionBy

    this.loadActionByOptionsFromPopupData()

    this.setAssignModalVisible(true)
  }
  setAssignModalVisible(e){this.assignModal.visible=e}
  clearAssignModalInput(){
    this.assignModal.staffId=''
    this.assignModal.table.data.forEach(data=>{
      data.checkbox=false
    })
  }
  cancelAssignActionBy(){
    this.setAssignModalVisible(false)
    this.clearAssignModalInput()
  }
  assignActionBy(){
    // this.assignModal.table.data.forEach(data=>{
    //   let matchedData = this.tableData.data.find(_data=>{
    //     return _data.itemCode==data.itemCode
    //   })
    //   matchedData.actionBy = data.actionBy
    // })
    this.assignModal.table._data.forEach(data=>{
      let idx = this.tableData.data.findIndex(_data=>{
        return _data.itemCode==data.itemCode
      })
      this.tableData.data[idx].actionBy = data.actionBy
    })

    // let _actionBy = [...new Set(this.tableData.data.map(item=>{return {name:item.actionBy,value:item.actionBy}}))].filter(actionBy=>actionBy.name&&actionBy.name.length>0)
    // // this.actionByIpt.options = _actionBy
    // // this.actionByOptions = _actionBy
    // let actionByIpt = this.filterPanel.ipts.find(ipt=>ipt.title==QUERYTITLE.ACTIONBY)
    // if(actionByIpt)actionByIpt.options = _actionBy
    this.setAssignModalVisible(false)
    this.clearAssignModalInput()
  }
  applyAssignModalActionBy(){
    this.assignModal.table.data.forEach(data=>{
      if(data.checkbox==true){
        data.actionBy=this.assignModal.staffId
        this.assignModal.table._data.find(_data=>data.itemCode == _data.itemCode).actionBy = data.actionBy
      }
    })
    this.loadActionByOptionsFromPopupData()
  }

  applyAssignModalActionByToExistRow(){
    this.assignModal.table.data.forEach(data=>{
      if(data.actionBy==null || data.actionBy.length==0){
        data.actionBy=this.assignModal.staffId
        this.assignModal.table._data.find(_data=>data.itemCode == _data.itemCode).actionBy = data.actionBy
      }
    })
    this.loadActionByOptionsFromPopupData()
  }

  showScan(data,key){
    let dataStockCondition = key.includes('normal')?'FG':'FAULTY';
    const filteredData = data.filter(item => item.stockCondition == dataStockCondition && item.countType === 1);
    return filteredData[0].qtyVariance === 0;
  }

  // serial modal
  viewSerial(data, field, count = this.stockTakeCount) {
    // this.setLoading(true)
    let [condition, countType] = this.decodeFNCountKey(field)
    let conditions = LocalStorageHelper.getObject('CONDITIONS')
    let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
    let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY'

    let conditionFilterValue = condition == 'normal'?fgCode:condition == 'faulty'?faultyCode:''
    let conditionId = conditionFilterValue == fgCode?CONFIG['CONDITION_CODE']['FG']:CONFIG['CONDITION_CODE']['FAULTY'];

    let stockTakeLine = data?._data?.find(res=>res.stockCondition == conditionFilterValue&&res.countType==count)
    let stockTakeLineId
    if(stockTakeLine){
      stockTakeLineId = stockTakeLine.lineId
    }
    let dataStockCondition = field.includes('normal')?1:2;
    if(stockTakeLineId){
      this.stockTakeLineId = stockTakeLineId
      if(!this.serialListBuffer[stockTakeLineId]){
        this.setLoading(true)
        this.stockTakeService
          .getSerial({
            stockTakeLineId: this.stockTakeLineId,
            itemCode:data.itemCode,
            stockCondition:dataStockCondition
          })
          .subscribe((res) => {
            this.setLoading(false)
            let list = []
            if (res.code === '000') {
              list = res.data
            }
            this.serialListBuffer[stockTakeLineId] = {
              _data: list,
              _field: field,
              existList: list.length ? JSON.parse(JSON.stringify(list)) : [],
              serialList: list.map(serial=>serial.serial)
            }
            this.showSerials(this.serialListBuffer[this.stockTakeLineId]._data, data, field, 'view', this.serialListBuffer[this.stockTakeLineId].serialList)
          },err=>{
            this.setLoading(false)
          })
      }else{
        this.showSerials(this.serialListBuffer[this.stockTakeLineId]._data, data, field, 'view', this.serialListBuffer[this.stockTakeLineId].serialList)
      }
    }else{
      // no line data in db, new item
      this.stockTakeLineId = `dummy_${data.itemMasterId}_${conditionId}_${this.stockTakeCount}`
      this.showSerials([], data, field, 'view', this.serialListBuffer[this.stockTakeLineId]?this.serialListBuffer[this.stockTakeLineId].serialList:[])
    }
  }

  decodeFNCountKey(val:string){
    let _split = val.split('_')
    let condition,
        countType
    if(_split[0])condition=_split[0]
    if(_split[1]){countType=_split[1]}else{countType=1}
    return[condition,countType]
  }

  getIsSerialControl(data,line){
    let item = this.itemList.find(_item=>_item.itemCode==line.itemCode)
    let res = false;
    if(data.countBy==this._countByList['SN']){
      if(item._data.isserialcontrol == 'Y'){
        res = true
      }else{
        res = false
      }
    }else if(data.countBy==this._countByList['QTY']){
      if(item._data.isserialcontrol == 'Y'){
        // line.isSerialControl = true
        if(line.countType==1){
          res = false
        }else if(line.countType==2){
          res = true
        }
      }else{
        res = false
      }
    }
    return res
  }
  showSerials(list, data, field, type, serialList) {
    const items = LocalStorageHelper.getObject('SKU')
    const item = items.find((sku) => sku.code === data.itemMasterId)
    // const num = field === 'faulty' ? data.faulty : data.normal
    this.serialModalConfig = {
      ...this.serialModalConfig,
      itemCode: { ...this.serialModalConfig.itemCode, value: item.name},
      itemDesc: { ...this.serialModalConfig.itemDesc, value: item.flag, disabled: true, title: 'Item Desc.' },
      actionQty: { ...this.serialModalConfig.actionQty, value: `${serialList.length}`, disabled: true, title: 'Qty', },
      // qty: num,
      serialList: type === 'add' ? [] : JSON.parse(JSON.stringify(serialList)),
      _serialList: type === 'add' ? [] : JSON.parse(JSON.stringify(serialList)), // only set in this function, null after saveserial use in cancelSerial()
      newSerialValue: null,
      range: {},
      index: +data.item,
      existList: type === 'add' ? [] : list.length ? JSON.parse(JSON.stringify(list)) : [],
      deleteList: [],
      currentLine: field,
      type,
      lineId: data.code
    }
    this.setSerialModalVisible(true)
  }
  setSerialModalVisible(e) {
    if(this.serialcst){
      this.serialcst.setModalVisible(e)
    }
    // this.serialModalConfig.visible = e
  }
  resetSerialModalConfig() {
    this.initSerialModalConfig()
    // this.serialModalConfig = {
    //   itemCode: {},
    //   itemDesc: {},
    //   actionQty: {},
    //   qty: 0,
    //   existList: [],
    //   serialList: [],
    //   deleteList: [],
    //   header: 'Input Serial Number',
    //   newSerialValue: null,
    //   range: {},
    //   index: null,
    // }
  }
  initSerialModalConfig(){
    this.serialModalConfig = {
      itemCode: {
        title: 'Item Code',
        type: INPUT_TYPE.INPUT,
        disabled: true,
        value: null,
      },
      itemDesc: {
        title: 'Item Desc',
        type: INPUT_TYPE.INPUT,
        disabled: true,
        value: null,
      },
      actionQty: {
        title: 'Qty',
        type: INPUT_TYPE.INPUT,
        disabled: true,
        value: null,
      },

      qty: 0,
      serialList: [],
      _serialList: null, // set to null for cancelSerial() not use this to override value in serialListBuffer
      existList: [],
      deleteList: [],
      header: 'Input Serial Number',
      newSerialValue: null,
      range: {},
      index: null,
      currentLine: null,
      type: null,
    }
  }
  saveSerial() {
    if (this.serialModalConfig.type !== 'add') {
    // } else {
      // 和服务器端数据对比，检索新加数据
      const existlist = this.serialModalConfig.existList.map( (item) => item.serial )
      const appendList = this.difference(this.serialModalConfig.serialList, existlist)
      let deleteList = this.serialModalConfig.existList.filter(item=>this.serialModalConfig.serialList.indexOf(item.serial)==-1).map(item=>item.stockTakeLineSerialId)

      let serialList = this.serialModalConfig.serialList
      this.serialListBuffer[this.stockTakeLineId] = {
        ...this.serialListBuffer[this.stockTakeLineId],
        serialList: serialList,
        // existList: existlist,
        appendList: appendList,
        deleteList: deleteList,
      }
      let tableRowData = this.tableData.data.find(data=>data.code == this.serialModalConfig.lineId)
      tableRowData[this.serialModalConfig.currentLine]=serialList.length
      this.resetSerialModalConfig()
      // 存在新数据时
      // if (appendList.length) {
      //   this.confirmToAppendSerialNumber(appendList).subscribe()
      // } else {
      //   // 存在删除的数据时
      //   if (this.serialModalConfig.deleteList.length) {
      //     this.confirmToDeleteSerialNumber().subscribe()
      //   } else {
      //     this.setLoading(false)
      //     this.showMessage('warn', 'Serial Number', this.info.notChange)
      //   }
      // }
    }
  }
  difference(arr = [], oarr = []) {
    return arr.reduce((t, v) => (!oarr.includes(v) && t.push(v), t), [])
  }
  confirmToAppendSerialNumber(list, lineId):Observable<any> {
    const data = {
      stockTakeLineId: lineId,
      serials: list,
    }
    return this.stockTakeService.appendSerial(data).pipe(map((res) => {
      if (res.code === '000') {
        // append success, reset this buffer to prevent click save again will call api again
        this.serialListBuffer[lineId].appendList = []
        //存在删除的数据时
        // if (this.serialModalConfig.deleteList.length) {
        //   return this.confirmToDeleteSerialNumber()
        // } else {
        //   this.confirmToSave('serial')
        //   return of(res)
        // }
        return true
      } else {
        // this.setLoading(false)
        // this.showMessage('error', 'Add Fail', res.msg)
        // return of(res)
        throw res.msg
      }
    }))
  }
  confirmToDeleteSerialNumber(deleteList, keys) {
    const data = {
      stockTakeLineSerialId: deleteList,
    }
    return this.stockTakeService.deleteSerial(data).pipe(map((res) => {
      if (res.code === '000') {
        // this.confirmToSave('serial')
        // delete success, reset this buffer to prevent click save again will call api again
        if(keys) keys.forEach(key => {
          this.serialListBuffer[key].deleteList
        });
        return true
      } else if(res.code === 'STOCK-006') {
        // record not found error / already deleted
        // delete success, reset this buffer to prevent click save again will call api again
        if(keys) keys.forEach(key => {
          this.serialListBuffer[key].deleteList
        });
        return true
      }else{
        throw res.msg
      }
    }))
  }
  confirmToSave(type='normal') {
    this.showMessage('success', 'Submit Success', this.info.submitSuccess)
    this.resetSerialModalConfig()
    this.setSerialModalVisible(false)
    // const isView = this.route.snapshot.queryParams.view || false
    this.loadDetailDataById(type)
  }
  addSerialByInput() {
    const newSerial = this.serialModalConfig.newSerialValue
    if (newSerial) {
      let success = this.addToSerialList(newSerial, false, true)
      this.serialModalConfig.newSerialValue = null
      this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
      if(success){
        this.showMessage('info', 'Add Success', `Serial Number ${newSerial} added`)
      }else{
        this.showMessage('warn', 'Add Fail', `Serial Number ${newSerial} duplicated`)
      }
    } else {
      this.showMessage('warn', 'Serial Input', this.info.inputSerial)
    }
  }
  addSerialByScanner(serial){
    let success = this.addToSerialList(serial, false, true)
    this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
    if(success)this.showMessage('info', 'Scan Success', `Serial Number ${serial} added`)
  }
  // removeDeleteData(data) {
  //   const serial = this.serialModalConfig.existList.find( (item) => item.serial === data )
  //   if (serial) {
  //     let idx = this.serialModalConfig.deleteList.findIndex( (e) => serial.stockTakeLineSerialId === e );
  //     (<any[]>this.serialModalConfig.deleteList).splice(idx, 1)
  //   }
  // }
  addToSerialList(_e, force = false, isUnshift = false) {
    let duplicate = false
    let e = _e.trim()
    if (!force) {
      duplicate = this.serialModalConfig.serialList.includes(e)
    }
    if (!duplicate) {
      // this.removeDeleteData(e)
      // this.serialModalConfig.serialList.push(e)
      if(!isUnshift){
        this.serialModalConfig.serialList.push(e)
      }else{
        this.serialModalConfig.serialList.unshift(e)
      }
    }
    this.changeActionQty()
    return !duplicate
  }
  onSerialRowDelete(data) {
    // 判断是否删除的是从服务器传递过来的数据
    // const serial = this.serialModalConfig.existList.find( (item) => item.serial === data )

    // calculate delete list when save
    // if (serial) {
    //   // 判断是否是重复的删除数据
    //   if (!this.serialModalConfig.deleteList.includes(serial.stockTakeLineSerialId)) {
    //     this.serialModalConfig.deleteList.push(serial.stockTakeLineSerialId)
    //   }
    // }
    let idx = this.serialModalConfig.serialList.findIndex((e) => data === e);
    (<any[]>this.serialModalConfig.serialList).splice(idx, 1)
    this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
    this.changeActionQty()
  }
  serialRowDeleted(){
    this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
    this.changeActionQty()
  }
  onMultiDelete(e){
    this.serialModalConfig.serialList=[...e]
    this.changeActionQty()
  }
  changeActionQty() {
    this.serialModalConfig.actionQty.value = `${this.serialModalConfig.serialList.length}`
  }
  saveAllSerial(){
    let observables:Observable<any>[] = [];
    let allDeleteList = []
    let allDeleteListKeys = []
    Object.keys(this.serialListBuffer).forEach(key => {
      this.serialListBuffer[key]
      // let existList = this.serialListBuffer[key].existList
      let appendList = this.serialListBuffer[key].appendList
      let deleteList = this.serialListBuffer[key].deleteList
      if((appendList&&appendList.length>0)||(deleteList&&deleteList.length>0)){
        // have change
        if(appendList&&appendList.length){
          observables.push(this.confirmToAppendSerialNumber(appendList, key))
        }
        if(deleteList&&deleteList.length){
          allDeleteList = [...allDeleteList, ...deleteList]
          allDeleteListKeys = [...allDeleteListKeys, key]
          // observables.push(this.confirmToDeleteSerialNumber(deleteList))
        }
      }
    })
    if(allDeleteList.length)observables.push(this.confirmToDeleteSerialNumber(allDeleteList, allDeleteListKeys))
    // return forkJoin(observables).pipe(defaultIfEmpty(null))

    // let res = of([])
    // observables.forEach(obs=>{
    //   res = res.pipe(mergeMap(_res=>{
    //     return obs.pipe(map(obsRes=>{return [..._res, obsRes]}))
    //   }))
    // })
    // return res

    return CommonMethod.forkjoinSync(observables)
  }
  initCountBy(){
    this.countByList = LocalStorageHelper.getObject('STOCK_TAKE_COUNT_BY')
    this.countByList.forEach(item=>{
      this._countByList[item.code]=item.description
    })
  }
  serialFileDropped(e: any) {
    let reader = new FileReader()
    if(e.length>1){
      this.showMessage( 'error', 'Upload Failed', `Only allow one file at a time.` )
      return
    }
    if(!(e[0].fileEntry.isFile && this.isFileAllowed(e[0].fileEntry.name))){
      this.showMessage( 'error', 'Upload Failed', `Only '.csv', '.txt' format is accepted.` )
      return
    }
    e[0].fileEntry.file((file) => {
      reader.readAsText(file)
      reader.onload = () => {
        let res = (<string>reader.result)
          .split(/\r?\n/)
          .filter((content) => content.length > 0)
        let successCount = 0
        let duplicateCount = 0
        res.forEach((val) => {
          let success = this.addToSerialList(val)
          if (success) {
            successCount++
          } else {
            duplicateCount++
          }
        })
        this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
        if (successCount)
          this.showMessage( 'info', 'Add Success', `${successCount} Serial Number Added` )
        if (duplicateCount)
          this.showMessage( 'error', 'Add Fail', `${duplicateCount} Serial Number Duplicated` )
      }
    })
  }
  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.csv', '.txt'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  duplicateSavedSerialToSecondCount() {
    // this.setLoading(true)
    // if (this.serialModalConfig.type !== 'add') {
    //   const existlist = this.serialModalConfig.existList.map( (item) => item.serial )
    //   const appendList = this.difference(this.serialModalConfig.serialList, existlist)
    //   let deleteList = this.serialModalConfig.existList.filter(item=>this.serialModalConfig.serialList.indexOf(item.serial)==-1).map(item=>item.stockTakeLineSerialId)

    //   let serialList = this.serialModalConfig.serialList
    //   this.serialListBuffer[this.stockTakeLineId] = {
    //     ...this.serialListBuffer[this.stockTakeLineId],
    //     serialList: serialList,
    //     existList: existlist,
    //     appendList: appendList,
    //     deleteList: deleteList,
    //   }
    //   this.setLoading(false)
    //   let tableRowData = this.tableData.data.find(data=>data.code == this.serialModalConfig.lineId)
    //   tableRowData[this.serialModalConfig.currentLine]=serialList.length
    //   this.resetSerialModalConfig()
    //   // 存在新数据时
    //   // if (appendList.length) {
    //   //   this.confirmToAppendSerialNumber(appendList).subscribe()
    //   // } else {
    //   //   // 存在删除的数据时
    //   //   if (this.serialModalConfig.deleteList.length) {
    //   //     this.confirmToDeleteSerialNumber().subscribe()
    //   //   } else {
    //   //     this.setLoading(false)
    //   //     this.showMessage('warn', 'Serial Number', this.info.notChange)
    //   //   }
    //   // }
    // }
    // this.
  }

  getSerialList(lineId) {
    const data = {stockTakeLineId: lineId}
    return this.stockTakeService.compareSerial(data).pipe(map(res=>{
      if(res.code === '000') {
        return res.data
      }else{
        return [];
      }
    }))
  }

  // actionByFilterChanged(e){
  //   if(e){
  //     let list = e.map(e=>e.value)
  //     if(list.length){
  //       this.tableData.dataDisplay = this.tableData.data.filter(data=>list.indexOf(data.actionBy)>-1)
  //     }else{
  //       this.tableData.dataDisplay = this.tableData.data;
  //     }
  //   }else{
  //     this.tableData.dataDisplay = this.tableData.data;
  //   }
  // }

  // setActionByIpt(){
  //   let that = this;
  //   this.actionByIpt = {
  //     title: "Filter Action By",
  //     // class: "p-col-12 p-md-4 p-lg-3",
  //     value: null,
  //     error: null,
  //     options: [],
  //     change: function(i,e,ipt){that.actionByFilterChanged(e)}
  //   }
  // }

  onInputEnter(e, rowIndex, key, rowData,){
    if(this.elRef){
      let targetTableCell = this.elRef.nativeElement.getElementsByClassName(this.tableTagPrefix+(rowIndex+1)+'-'+key)
      if(targetTableCell&&targetTableCell[0]){
        let _target = targetTableCell[0].getElementsByClassName('p-inputtext');
        if(_target&&_target[0]){
          (<HTMLElement>_target[0]).focus();
        }
      }
    }
  }

  initfilterPanel(){
    this.filterPanel = {
      ipts:[
        {
          title: TITLE.BU,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "label",
          optionValue: "value",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
            const availableInput = this.filterAddPanel.ipts.find(
              (input) => input.title === TITLE.AVAILABLE
            );
            if (availableInput) {
              availableInput.value = ipt.value.length > 0 ? 'Y' : null;
            }
          },
        },
        {
          title: TITLE.LOB,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.ACTIONBY,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.BULKADD_BRAND,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        //ADD by zhang henry start
        {
          title: TITLE.MAINCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.SUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.SUBSUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        // {
        //   title: QUERYTITLE.AVAILABLE,
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-4",
        //   value: null,
        //   options: [
        //     {code:'Y',name:'Yes'},
        //     {code:'N',name:'No'},
        //   ],
        //   optionLabel: "name",
        //   optionValue: "code",
        //   change:(i,e,ipt)=>{
        //     // this.bulkAddOptionChanged(i,e,ipt)
        //   },
        // },
      ],
      btns:[
        {
          title: 'Clear',
          class: "p-ml-auto p-mr-1 p-button-outlined",
          show: true,
          handler: { click: () => this.clearAssignPopupFilterInput() }
        },
        {
          title: 'Search',
          class: "",
          show: true,
          handler: { click: () => this.filterPopupTableData() }
        },
      ],
      btnsclass: 'p-d-flex p-align-center p-col-12 p-md-8 p-lg-8',
    }
    // this.getBUCode().subscribe(res=>{
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BU).options = this.buCodes
    // })
    // this.getItemMasterColumn().subscribe(res=>{
    //   // this.filterPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BU).options = this.buCodes
    //   // this.filterPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.LOB).options = this.lobCode
    //   this.filterPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.SUBSUBCAT).options = this.subSubCat
    //   this.filterPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BULKADD_BRAND).options = this.mfgBrand
    // })
  }

  filterPopupTableData(){
    let _bu = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BU).value
    let _lob = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).value
    let _sscat = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).value
    let _brand = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).value
    let _actionBy = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.ACTIONBY).value
    //ADD by zhang henry start
    let _maincat = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).value
    let _subcat = this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).value
    //ADD by zhang henry end

    let bu = _bu?_bu.map(val=>val.value):[]
    let lob = _lob?_lob.map(val=>val.value):[]
    let sscat = _sscat?_sscat.map(val=>val.value):[]
    let brand = _brand?_brand.map(val=>val.value):[]
    let actionBy = _actionBy?_actionBy:[]

    let res = this.assignModal.table._data
    if(actionBy)res = this.filterWithActionBy(res,actionBy)

    //ADD by zhang henry start
    let maincat = _maincat?_maincat.map(val=>val.value):[]
    let subcat = _subcat?_subcat.map(val=>val.value):[]
    let itemList = (maincat.length==0&&subcat.length==0
    //ADD by zhang henry end
    &&bu.length==0&&lob.length==0&&sscat.length==0&&brand.length==0)?this.itemList:this.itemList.filter(item=>{
      return !(
        (bu.length>0 && !bu.includes(item._data.buCode))||
        (lob.length>0 && !lob.includes(item._data.lobCode))||
        (sscat.length>0 && !sscat.includes(item._data.subSubCat))||
        (brand.length>0 && !brand.includes(item._data.mfgbrand))||
        //ADD by zhang henry start
        (maincat.length>0 && !maincat.includes(item._data.mainCat))||
        (subcat.length>0 && !subcat.includes(item._data.subCat))
        //ADD by zhang henry end
      )
    })
    let itemCodeList = itemList.map(item=>item.itemCode)

    res = res.filter(line=>{
      return itemCodeList.indexOf(line.itemCode)>-1
    })
    this.assignModal.table.selectAll = false
    this.assignModal.table.data = res
  }

  filterWithActionBy(data, e){
    let res = data;
    if(e){
      let list = e.map(e=>e.value)
      if(list.length){
        res = data.filter(data=>list.indexOf(data.actionBy)>-1)
      }
    }
    return res
  }

  getItemMasterColumn(){
    return this.stockCommonService.getItemMasterColumn().pipe(map(res => {
      if(res.code === '000'){
        // res.data.forEach((item) => {
        //   this.buCodes.push({name: item,  value: item})
        // });
        if(res.data[0]){
          let data = res.data[0];
          this.buCodes = data['buCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.lobCode = data['lobCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subSubCat = data['subSubCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.mfgBrand = data['mfgBrand']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          //ADD by zhang henry start
          this.mainCat = data['mainCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subCat = data['subCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          //ADD by zhang henry end
        }
        return res.data
      }else{
        return null;
      }
    }))
  }

  lineValueChanged(value, idx, key, rowData){
    // sync value in data and dataDisplay
    this.tableData.data[idx][key]=value
    // this.tableData.dataDisplay[idx][key]=value
  }


  initFilterAddPanel(){
    this.filterAddPanel={
      ipts:[
        {
          title: TITLE.BU,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "label",
          optionValue: "value",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
            const availableInput = this.filterAddPanel.ipts.find(
              (input) => input.title === TITLE.AVAILABLE
            );
            if (availableInput) {
              availableInput.value = ipt.value.length > 0 ? 'Y' : null;
            }
          },
        },
        {
          title: TITLE.LOB,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        //ADD by zhang henry start
        {
          title: TITLE.MAINCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.SUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        //ADD by zhang henry edn
        {
          title: TITLE.SUBSUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.BULKADD_BRAND,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.AVAILABLE,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        /*{
          type: null,
          class: "p-d-none p-d-md-block p-md-4 p-lg-4",
        },*/
        {
          title: TITLE.SELECT_ITEM,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
          value: null,
          options: [],
          disabled: false,
          // optionLabel: "itemDesc",
          // optionValue: "id"
        },
        {
          title: TITLE.SERIALIZED_ITEM,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
          value: null,
          options: [],
          disabled: false,
          // optionLabel: "itemDesc",
          // optionValue: "id"
        },
      ],
      btns:[
        // {
        //   title: 'Add Items By Filter',
        //   class: "p-ml-auto p-mr-1",
        //   show: true,
        //   handler: { click: () => this.filterAdd() }
        // },
        // {
        //   title: 'Add Selected Items',
        //   class: "",
        //   show: true,
        //   handler: { click: () => this.bulkAdd() }
        // },
        {
          title: 'Import',
          class: "p-mr-1",
          show: true,
          handler: { click: () => {this.fileDropper.openFileSelector()} }
        },
        {
          title: 'Add',
          class: "",
          show: true,
          handler: { click: () => {this.filterAdd();this.bulkAdd()} }
        },
      ],
      btnsclass: 'p-col-12 p-text-right',
    }
    // this.getBUCode().subscribe(res=>{
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BU).options = this.buCodes
    // })
    // this.getItemMasterColumn().subscribe(res=>{
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BU).options = this.buCodes
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.LOB).options = this.lobCode
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.SUBSUBCAT).options = this.subSubCat
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BULKADD_BRAND).options = this.mfgBrand
    //   if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.SELECT_ITEM).options = this.itemList.map(item=>{return{
    //     id: item._data.itemid,
    //     name: item._data.item + ' - ' + item._data.itemdesc,
    //     itemCode: item._data.item,
    //     desc: item._data.itemdesc
    //   }})
    //   // this.bulkAddQueryPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BULKADD_BRAND).options = this.mfgBrand
    // })
  }

  setPanelOptions(){
    this.getItemMasterColumn().subscribe(res=>{
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BU).options = this.buCodes
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).options = this.lobCode
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).options = this.subSubCat
      //ADD by zhang henry start
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).options = this.mainCat
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).options = this.subCat
      //ADD by zhang henry end
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).options = this.mfgBrand
      if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SELECT_ITEM).options = this.itemList.map(item=>{return{
        id: item._data.itemid,
        name: item._data.item + ' - ' + item._data.itemdesc,
        itemCode: item._data.item,
        desc: item._data.itemdesc
      }})
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BU).options = this.buCodes
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).options = this.lobCode
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).options = this.subSubCat
      //ADD by zhang henry start
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).options = this.mainCat
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).options = this.subCat
      //ADD by zhang henry end
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).options = this.mfgBrand
      // filter isserialcontrol data for serialized item input
      if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SERIALIZED_ITEM).options =
      this.itemList.filter(item=>item._data.isserialcontrol == 'Y').map(item=>{return{
        id: item._data.itemid,
        name: item._data.item + ' - ' + item._data.itemdesc,
        itemCode: item._data.item,
        desc: item._data.itemdesc
      }})
    })
  }


  filterAdd(){

    let haveFilter = false;

    let _bu = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BU).value
    let _lob = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).value
    let _sscat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).value
    //ADD by zhang henry start
    let _maincat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).value
    let _subcat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).value
    //ADD by zhang henry end
    let _brand = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).value
    let _available = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.AVAILABLE).value

    let bu = _bu?_bu.map(val=>val.value):[]
    let lob = _lob?_lob.map(val=>val.value):[]
    let sscat = _sscat?_sscat.map(val=>val.value):[]
    let brand = _brand?_brand.map(val=>val.value):[]
    let available = _available?_available=='Y':null

    //ADD by zhang henry start
    let maincat = _maincat?_maincat.map(val=>val.value):[]
    let subcat = _subcat?_subcat.map(val=>val.value):[]
    if(maincat.length==0&&subcat.length==0
    //ADD by zhang henry end
    &&bu.length==0&&lob.length==0&&sscat.length==0&&brand.length==0&&!available) return;

    let itemAfterFilter = (maincat.length==0&&subcat.length==0&&bu.length==0&&lob.length==0&&sscat.length==0&&brand.length==0)?this.itemList:this.itemList.filter(item=>{
      return !(
        (bu.length>0 && !bu.includes(item._data.buCode))||
        (lob.length>0 && !lob.includes(item._data.lobCode))||
        (sscat.length>0 && !sscat.includes(item._data.subSubCat))||
        (brand.length>0 && !brand.includes(item._data.mfgbrand))||
        //ADD by zhang henry start
        (maincat.length>0 && !maincat.includes(item._data.mainCat))||
        (subcat.length>0 && !subcat.includes(item._data.subCat))
        //ADD by zhang henry end

      )
    })
    haveFilter = itemAfterFilter.length > 0
    if(haveFilter==false&&available==null){
      // no thing selected, skip add items
      return
    }
    if(available){
      this.getItemListByChannel().subscribe(res=>{
        let _res = res.filter(item=>{
          return available?item.totalOnHand!=null&&item.totalOnHand>0:item
        }).filter(item=>{
          return itemAfterFilter.map(_itemAfterFilter=>_itemAfterFilter._data.item).indexOf(item.sku)>-1
        }).map(item=>{
          return {
            id: item.skuId,
            name: item.sku + ' - ' + item.skuDesc,
            itemCode: item.sku,
            desc: item.skuDesc
          }
        })
        this.addItemToTable(_res)
      })
    }else if(haveFilter == true && available == null){
      let _res = itemAfterFilter.map(item=>{return{
        id: item._data.itemid,
        name: item._data.item + ' - ' + item._data.itemdesc,
        itemCode: item._data.item,
        desc: item._data.itemdesc
      }})
      this.addItemToTable(_res)
    }
  }

  // code use as line id in this page, use itemMasterId as item id
  addItemToTable(_itemList){
    let existItemId = [];
    let itemList = _itemList.filter(e=>{
      let duplicate = existItemId.includes(e.id)
      if(!duplicate) existItemId.push(e.id)
      return !duplicate
    })
    if(itemList && itemList.length>0)itemList.forEach(item => {
      let isDuplicate = this.tableData.data.some(row=>row.itemMasterId == item.id)
      let newRow = {
        // code: item.id,
        itemCode : item.itemCode,
        itemDesc : item.desc,
        itemMasterId : item.id,
        normal : 0,
        faulty : 0,
        // normal_2 : 0,
        // faulty_2 : 0,
        disabled: false,
        isSerialControl:{},
        newItem: true,
      }

      let isSerialControl = this.getIsSerialControl(this.detailData, item)
      if(isSerialControl) this.haveSerialControlItem = true
      newRow.isSerialControl[this.stockTakeCount] = isSerialControl // split isSerialControl by count type
      if(!isDuplicate) this.tableData.data.push(newRow)
    });
  }

  clearAssignPopupFilterInput(){
    [
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BU),
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.LOB),
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT),
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND),
      this.filterPanel.ipts.find(ipt=>ipt.title == TITLE.ACTIONBY),
    ].forEach(e=>e.value=null)
  }

  assignModalSelectAllChange(e){
    this.assignModal.table.data.forEach(data => {
      data.checkbox = e
    });
  }

  assignModalSelectChange(e){
    this.assignModal.table.selectAll = false
  }

  loadActionByOptionsFromPopupData(){
    let _actionBy = [...new Set([...this.assignModal.table._data.map(item=>{return item.actionBy})])]
    .filter(actionBy=>actionBy&&actionBy.length>0)
    .map(actionBy=>{return {
      name: actionBy,
      value: actionBy,
    }})
    // this.actionByIpt.options = _actionBy
    // this.actionByOptions = _actionBy
    let actionByIpt = this.filterPanel.ipts.find(ipt=>ipt.title==TITLE.ACTIONBY)
    if(actionByIpt)actionByIpt.options = _actionBy
  }

  cancelSerial(){
    // serialModalConfig._serialList is null if saveserial > resetSerialModalConfig > initSerialModalConfig
    if(this.serialModalConfig._serialList!=null)this.serialListBuffer[this.stockTakeLineId].serialList = this.serialModalConfig._serialList
    this.setSerialModalVisible(false);
  }

  addSerialByRange() {
    let prefix = this.serialModalConfig.range.prefix ?? ''
    let suffix = this.serialModalConfig.range.suffix ?? ''
    let from = this.serialModalConfig.range.from
    let to = this.serialModalConfig.range.to
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)

    if ( from && to && from.length === to.length && !isNaN(fromInt) && !isNaN(toInt) && fromInt <= toInt ) {
      let successCount = 0
      let duplicateCount = 0
      for (let i = fromInt; i <= toInt; i++) {
        let value = String(i).padStart(from.length, '0')
        let success = this.addToSerialList(`${prefix}${value}${suffix}`)
        if (success) {
          successCount++
        } else {
          duplicateCount++
        }
      }
      this.serialModalConfig.serialList = [...this.serialModalConfig.serialList]
      if (successCount)
        this.showMessage( 'info', 'Add Success', `${successCount} Serial Number Added` )
      if (duplicateCount)
        this.showMessage( 'error', 'Add Fail', `${duplicateCount} Serial Number Duplicated` )
      this.serialModalConfig.range = {}
    }
  }
  serialModalRangeChange() {
    let from = this.serialModalConfig.range.from
    let to = this.serialModalConfig.range.to
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)
    if ( from && to && from.length === to.length && !isNaN(fromInt) && !isNaN(toInt) && fromInt <= toInt ) {
      this.serialModalConfig.range.total = toInt - fromInt
    } else {
      this.serialModalConfig.range.total = 0
    }
  }
  getLineId(item,count,key){
    if(item&&item._countBookmark&&item._countBookmark&&item._countBookmark[count]){
      return item._countBookmark[count][key]
    }else{
      return null
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  showScanAllModal(){
    this.setLoading(true)
    forkJoin([this.getAllLineSerialDataForCurrentCountType(), this.initScanAllData()]).subscribe(res=>{
      // load all serial data from stock take line
      this.setLoading(false)
      this.scanallModalConfig.visible = true;
      this.scanallModalConfig.existSerialList = this.snapshotData
    },err=>{
      this.setLoading(false)
    })
  }

  hideScanAllModal(){
    this.scanallModalConfig.visible = false;
    this.serialscanallcst.reset()
  }

  addSerialToDataListByItemCondition(e){
    let data = e.formattedData
    let itemCodeList = e.itemCodeList
    itemCodeList.forEach(itemCode=>{
      let fgSerialList = data[itemCode][this.getConditionCode('FG')]?.map(serial=>serial.serial) || [];
      let faultySerialList = data[itemCode][this.getConditionCode('FAULTY')]?.map(serial=>serial.serial)||[];
      let tableItemObj = this.tableData.data.find(data=>data.itemCode == itemCode)
      let fgLineId = this.getLineId(tableItemObj, this.stockTakeCount, 'normalLineId')
      let faultyLineId = this.getLineId(tableItemObj, this.stockTakeCount, 'faultyLineId')
      this.addSerialToLine(fgLineId, fgSerialList, 'normal')
      this.addSerialToLine(faultyLineId, faultySerialList, 'faulty')
    })

    this.hideScanAllModal()
  }

  snapshotData = null;

  initScanAllData(){
    if(this.snapshotData) return of(this.snapshotData)
    return this.stockTakeService.getAllSerialFromSnapshot(this.detailData.id).pipe(tap(res=>{
      if(res.code == '000')this.snapshotData = res.data
    }))
  }

  getConditionCode(condition : 'FG' | 'FAULTY' = 'FG'){
    let conditions = LocalStorageHelper.getObject('CONDITIONS')
    return conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE'][condition]).description || condition
  }

  getAllLineSerialDataForCurrentCountType(){
    let conditions = LocalStorageHelper.getObject('CONDITIONS')
    let fgCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FG']).description || 'FG'
    let faultyCode = conditions.find(cond=>cond.code==CONFIG['CONDITION_CODE']['FAULTY']).description || 'FAULTY'

    let forkObj = []
    this.detailData.lines.forEach(line => {
      let stockTakeLineId = line.lineId
      if(!this.serialListBuffer[stockTakeLineId] && line.countType == this.stockTakeCount){
        let condition = line.stockCondition==fgCode?'normal':line.stockCondition==faultyCode?'faulty':null
        if(!condition) return
        forkObj.push(this.stockTakeService
          .getSerial({ stockTakeLineId: stockTakeLineId })
          .pipe(tap(res=>{
            let list = []
            if (res.code === '000') {
              list = res.data
              // this.currentLineSerialFullList.push(...list)
            }
            this.serialListBuffer[stockTakeLineId] = {
              _data: list,
              _field: this.genFNCountKey(condition,line.countType),
              existList: list.length ? JSON.parse(JSON.stringify(list)) : [],
              serialList: list.map(serial=>serial.serial)
            }
            this.currentLineSerialFullList.push(...this.serialListBuffer[stockTakeLineId].serialList)
          })))
      }else{
        if(line.countType == this.stockTakeCount) forkObj.push(of(null).pipe(tap(()=>{
          this.currentLineSerialFullList.push(...this.serialListBuffer[stockTakeLineId].serialList)
        })))
      }
    });

    if(forkObj.length>0){
      return of(null).pipe(
        tap(res=>{
          this.currentLineSerialFullList = []; // reset to empty array, forkjoin get data and add value to this variable when tap
        }),
        switchMap(res=>{
          return forkJoin(forkObj)
        }
      ))
    }
    return of(null)
  }
  addSerialToLine(lineId, appendList, condition) {
    let _serialList = [...(this.serialListBuffer[lineId].serialList||[]), ...appendList]
    let _existList = this.serialListBuffer[lineId].existList.map( (item) => item.serial )
    let _appendList = this.difference(_serialList, _existList)
    let deleteList = this.serialListBuffer[lineId].existList.filter(item=>_serialList.indexOf(item.serial)==-1).map(item=>item.stockTakeLineSerialId)
    let field = this.genFNCountKey(condition,this.stockTakeCount)
    this.serialListBuffer[lineId] = {
      ...this.serialListBuffer[lineId],
      serialList: _serialList,
      appendList: _appendList,
      // existList: existlist,
      deleteList: deleteList,
    }
    let tableRowData = this.tableData.data.find(data=>this.getLineId(data, this.stockTakeCount, `${condition}LineId`) == lineId)
    // update table display number
    tableRowData[field]=_serialList.length
    let countHeaderLabel = this.stockTakeCount==1?'1st Count':this.stockTakeCount==2?'2nd Count':'N/A';
    // upper case first char Normal, Faulty
    let conditionLabel = condition.charAt(0).toUpperCase() + condition.slice(1)
    if(appendList.length>0)this.showMessage('success', 'Apply Serials Success', `${appendList.length} serials added to ${tableRowData.itemCode} ${countHeaderLabel} ${conditionLabel}`)
    this.resetSerialModalConfig()
  }

  setSerialscanallcst(e:SerialScanAllCstComponent){this.serialscanallcst=e}

  loadCurrStatusId(){
    let currStatus = this.takeStatus.find(status=>status.label == this.detailData.status)
    this.currStatusId = currStatus.value
  }

  setLoading(e){
    this.loadingService.setLoadingBoolean(e)
  }

  fileDropped(e){
    let reader = new FileReader();
    e[0].fileEntry.file(file=>{
      reader.readAsText(file)
      reader.onload=()=>{
        let res = (<string>reader.result).split(/\r?\n/).filter(content=>content.length>0);
        this.addItemByItemCode(res)
        // res.forEach(val=>{
        //   let success = this.addToSerialList(val)
        //   if(success){successCount++}else{duplicateCount++}
        // });
        // this.setSerialList()
        // if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
        // if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      }
    })
  }

  addItemByItemCode(itemCodeList = []){
    let itemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SELECT_ITEM);
    let options = itemIpt.options
    let itemList = options.filter(option=>itemCodeList.includes(option.itemCode))
    let foundItemCodeList = itemList.map(item=>item.itemCode)
    let notFoundItemCodeList = itemCodeList.filter(itemCode=>!foundItemCodeList.includes(itemCode))
    this.addItemToTable(itemList)
    let limit = 20
    let limitedNotFoundItemCodeList = notFoundItemCodeList.slice(0, limit)
    if(limitedNotFoundItemCodeList.length>0) this.showMessage('error', this.info.title, `Item code ${limitedNotFoundItemCodeList.toString().replace(/,/g, ', ')} (Total: ${notFoundItemCodeList.length}, displayed: ${limitedNotFoundItemCodeList.length}) not found`)
  }

  async importFileForUpdateQty(){
    try{
      let [handle] = await CommonMethod.showOpenFilePickerPolyfill({
        types: [
          {
            description: 'XLSX',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':['.xlsx']
            }
          },
        ]
      });
      if (!handle) {
        // User cancelled, or otherwise failed to open a file.
        return;
      }
      return handle
    }catch(e){
      return
    }
  }

  importQtyFromXlsx(){
    let that = this
    if(!this.isCountingQty) return null
    let {resolver, rejector, promise} = CommonMethod.createNewPromise();
    let importAsJsonPromiseResolver = resolver;
    let importAsJsonPromise = promise;
    let markedLoading = false;

    this.importFileForUpdateQty().then((res)=>{
      if(res==null) {
        throw null
      }else{
        return res.getFile()
      }
    }).then((res)=>{
      if(!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(res.type)) throw 'Please upload .xlsx file'
      this.setLoading(true)
      markedLoading = true
      return res
    }).then((res:File)=>{
      // xlsx file to json
      let fileReader = new FileReader();
      fileReader.onload = async function(e) {
        const buffer:any = fileReader.result;
        const wb = new ExcelJS.Workbook();
        let workbook = await wb.xlsx.load(buffer)
        let sheet = workbook.worksheets[0] // "ALL" tab

        let currentParent = null
        let columnList = that.tableData.head.map(column=>{
          if(column.parent && column.parent!==true){
            currentParent = column.parent
          }
          return {
            ...column,
            _parent: column.parent?currentParent.title:null
          }
        })

        let firstHeaderRow = columnList.map(column=>{
          return column._parent?column._parent:column.title
        })
        // let secondHeaderRow = columnList.map(column=>{
        //   return column.title
        // })

        let minRow = 1;
        let maxRow = sheet._lastRowNumber

        let tableStartRow
        let tableEndRow

        for (let i = minRow; i < maxRow; i++) {
          const row = sheet.getRow(i);
          let rowValue = row.values.filter(e=>e);
          if(tableStartRow && rowValue.length == 0){
            // empty row after table start row
            tableEndRow = i - 1;
            break;
          }
          // use first header to get table start row idx
          if(!tableStartRow && rowValue.toString() == firstHeaderRow.toString()){
            tableStartRow = i;
          }
        }

        // have 2 row header
        let tableDataStartRow = tableStartRow + 2;
        let _res = [];

        sheet.eachRow((row, rowNumber) => {
          // only process table data rows
          if (rowNumber < tableDataStartRow || rowNumber > tableEndRow) return;
          let values = row.values;
          let obj = {};
          for (let i = 0; i < columnList.length; i ++) {
            let key = columnList[i].key;
            obj[key] = values[i+1]?values[i+1]:null;
          }
          _res.push(obj);
        })
        importAsJsonPromiseResolver({data: _res})
      };
      fileReader.readAsArrayBuffer(res)
      return importAsJsonPromise
    }).then((res:any)=>{
      that.setLoading(false)
      markedLoading = false
      that.setQtyFromImportedXlsx(res.data)
    }).catch(e=>{
      if(markedLoading)that.setLoading(false) // marke sure disable loading if error occurred before set loading false in normal flow
      markedLoading = false
      if(e==null) return // excepted error null for user cancel file selector
      console.error(e)
      this.showMessage('error', this.info.title, `An Unexpected Error Occurred. Please make sure to import file exported from Stock Take Detail page and only modify the quantity part in the file. ${e}`)
    })
  }

  setQtyFromImportedXlsx(excelDatas){
    let normalKey = this.genFNCountKey('normal',this.stockTakeCount);
    let faultyKey = this.genFNCountKey('faulty',this.stockTakeCount);
    let normalInputDisabledKey = this.genFNCountKey('normal',this.stockTakeCount,'_disabled')
    let faultyInputDisabledKey = this.genFNCountKey('faulty',this.stockTakeCount,'_disabled')

    excelDatas.forEach(newData => {
      let tableDataIdx = this.tableData.data.findIndex(_data=>{
        return newData.itemCode == _data.itemCode;
      })
      // skip when can't find this item code in stock take
      if(tableDataIdx==-1) return;
      // skip when this item is serial control in current count
      if(this.tableData.data[tableDataIdx].isSerialControl[this.stockTakeCount]) return;
      // not serial control in current count, do update current count qty
      // only change qty when not disabled by "1st count no variance, set 2nd count qty and disable input" function
      if(!this.tableData.data[tableDataIdx][normalInputDisabledKey]) this.tableData.data[tableDataIdx][normalKey] = newData[normalKey]?newData[normalKey]:0;
      if(!this.tableData.data[tableDataIdx][faultyInputDisabledKey]) this.tableData.data[tableDataIdx][faultyKey] = newData[faultyKey]?newData[faultyKey]:0;
    });
  }
}

interface SerialListBufferMapper {
  [lineId: string]: SerialListBufferItem
}
interface SerialListBufferItem {
  appendList?:any[], // call append serial api when save
  deleteList?:any[], // call delete serial api when save
  existList?:any[], // set when show serial popup, should be serial list in db, use for calculate appendList & deleteList
  serialList?:any[], // set when show serial popup, use for display
  _data?:any[], // set when show serial popup, origin data from get serial list api
  _field?:string, // field name in line table e.g. normal, normal_2, faulty, faulty_2, map with lineId in SerialListBufferMapper
}
