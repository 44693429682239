import { StockCommonService } from '@/service/stock/stock-common.service';
import { CommonMethod } from '@/util/CommonMethod';
import { DatePipe } from '@angular/common';
import { Input, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { action, computed, observable } from 'mobx';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { pluck, tap } from 'rxjs/operators';
import { CONFIG, INPUT_TYPE, URLDICT } from 'src/app/stores/base/BaseStore';
import { HttpHelper } from 'src/app/util/HttpHelper';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';

@Component({
  selector: 'app-access-function',
  templateUrl: './access-function.component.html',
  styles: [
    `.p-shadow-1 {
      margin: 20px 0;
      padding: 25px;
      border-radius: 5px;
      background-color: white;
    }`
  ]
})
export class AccessFunctionComponent {
  queryTitle = 'UAM - Access Function'
  @observable permission: string[]
  // @observable defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  functions: any[] = []
  @observable selectedRows: any[] = []
  @observable queryPannel: any = {
    ipts: [
      {
        title: "Function Code",
        name: 'functionCode',
        type: INPUT_TYPE.MUTIPLESELECT,
        options: this.functions,
        optionLabel: 'name',
        optionValue: 'name',
        value: null,
        class: "p-col-12 p-md-4 p-lg-4",
      },
      {
        title: "Description",
        name: 'description',
        type: INPUT_TYPE.INPUT,
        value: null,
        class: "p-col-12 p-md-4 p-lg-4",
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-md-4 p-lg-4",
    btns:[
      {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-mr-1",
        show: true,
        handler: { click: () => this.refresh() }
      },
      {
        title: 'Export',
        show: true,
        class: "p-mr-1",
        handler: { click: () => this.exportXlsx() }
      },
      {
        title: 'Search',
        show: true,
        class: "",
        handler: { click: () => this.searchFromFilter() }
      }
    ]
  }
  @observable tableData:any = {
    btns: [],
    data: [],
    columns: [],
    selectedColumns: [],
    showColumns: [],
    frozenColumns: [
      // {field: '', title: '', width: '60px', height: '88px', type: 'checkbox'},
      // {field: '', title: 'Action', width: '100px', height: '88px', type: 'action' },
      {field: 'functionCode', title: 'Function Code', width: '200px', allowSort: true},
      {field: 'description', title: 'Description', width: '200px', allowSort: true},
    ],
    frozenWidth: '400px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'importId',
    initSortOrder: -1,
    sortField: { field: "importId", order: -1 },
    selectedProducts: null,
    editShow: true,
  }

  lastSearchParams = {};

  _selectedColumns = []
  @Input() get selectedColumns() {
    return this._selectedColumns
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.tableData.columns.filter(item => val.includes(item))
    let arr = ['CSTK_CM', 'CSTK_CG', 'CSTK_HKTE' ,'CSTK_CommM']
    let resolt = this._selectedColumns.reduce((p, c) => {
      c.field.includes(arr[0]) ? p[0]+=1 : p[0]
      c.field.includes(arr[1]) ? p[1]+=1 : p[1]
      c.field.includes(arr[2]) ? p[2]+=1 : p[2]
      c.field.includes(arr[3]) ? p[3]+=1 : p[3]
      return p
    }, [0, 0, 0, 0])
    let list = this._selectedColumns.map(item => {
      item.parent = true
      return item
    })
    if(list.length) {
      arr.forEach((str, idx) => {
        let selObj = list.find(item => item.field.includes(str))
        if(selObj) {
          selObj.parent = { title: str.split('_')[1], colspan: resolt[idx] }
        }
      })
      this.tableData.frozenWidth= '400px'
    }else{
      list = []
      this.tableData.frozenWidth= '100%'
    }
    this.tableData.showColumns = list
  }

  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(
    private route: ActivatedRoute,
    private confServe: ConfirmationService,
    private msgServe: MessageService,
    public stockCommonService: StockCommonService,
    public datePipe: DatePipe,
  ) {
    this.getPermission()
    this.initColumns()
    this.selectedColumns = this.tableData.columns
    this.tableData.showColumns = this.tableData.columns
    this.getResolverData()
    this.search()
  }

  initColumns(){
    this.tableData.columns = []
    let _cols = []
    let roles:any[] = LocalStorageHelper.getObject('UAM_ROLE')
    roles = roles.sort((a,b)=>a.name>b.name?1:a.name<b.name?-1:0)
    roles.forEach(role => {
      _cols.push({
        field: role.name, 
        title: role.name, 
        width: `${role.name.length/2 + 1}rem`, 
        type: 'trigger',
        allowSort: false,
      })
    });
    this.tableData.columns = _cols
  }
  @action getPermission() {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')['UAM Permission Setting']
  }

  @computed get showC() {
    // tslint:disable-next-line: no-bitwise
    return this.permission.includes('Create')
  }

  @computed get functionCode(){
    let funcCode = this.getQueryDataByName('functionCode')
    return funcCode.value
  }
  @computed get description(){
    let desc = this.getQueryDataByName('description')
    return desc.value
  }

  getResolverData() {
    this.route.data.pipe(pluck('query')).subscribe(res => {
      // console.log('=> Get data from resolve of the Configuration Tables Maintenance: ', res);
      // 从后端获取到的数据
      // if (Array.isArray(res)) {
      //   const list = ['ACCESS_FUNCTION']
      //   res.forEach(({data}, idx) => {
      //     this.parseRemoteData(data, list[idx])
      //   });
      // }else{
      const funcCode = this.getQueryDataByName('functionCode')
      funcCode.options = LocalStorageHelper.getObject('ACCESS_FUNCTION').map(data=>{return {...data, name: data.label}})
      // }
    })
  }

  getQueryDataByName (name) {
    return this.queryPannel.ipts.find(item => item.name === name)
  }

  parseRemoteData(data: any, name: string) {
    data.forEach(item => {
      item.name = item.label
    });
    const funcCode = this.getQueryDataByName('functionCode')
    funcCode.options = data
    LocalStorageHelper.setObject(name, data)
  }

  editDetail(data) {
    data.editId = data.id
    data.rawData = JSON.parse(JSON.stringify(data));
    delete data.id
    data.edit= true
  }

  save(data) {
    // console.log(data);
    let str = ''
    this.tableData.columns.forEach(col => {
      if (Object.prototype.hasOwnProperty.call(data, col.field)) {
        // console.log(col.field, data[col.field]);
        if(data[col.field + 'cb'].length === 1) {
          // console.log(col.field);
          str += col.field + ','
        }
      }
    });
    // console.log('new data:', str);
    str = str.substring(0, str.length - 1)
    // console.log('not change:', str === data.roleCodes.trim(), 'new:',str, 'old:',data.roleCodes);

    this.dataRestore(data)
  }

  cancel(data) {
    this.dataRestore(data, true)
  }

  delete() {
    let len = this.selectedRows.length
    const obj = {
      ids: this.selectedRows.map(item => item.id + "")
    }
    this.confServe.confirm({
      message: `Do you want to delete these ${len} record?`,
      header: 'Delete Confirmation',
      accept: () => {
        this.tableData.loadingSwitch = true
        HttpHelper.post(URLDICT.UAM_FUNCTION_DELETE, obj).then(res => {
          if (res.code == '000') {
            //清空选中并重赋数据源
            this.selectedRows = []
          } else {
            this.showMessage('error', 'System', res.msg || res.message || 'Delete Fail!')
          }
        }).catch(e => {
          this.showMessage('error', 'System', e.toString())
        }).finally(() => { this.tableData.loadingSwitch = false })
      }
    });
  }
  //
  dataRestore(data, reset=false) {
    data.id = data.editId
    if(reset){
      for (const i in data.rawData) {
        if (Object.prototype.hasOwnProperty.call(data.rawData, i)) {
          data[i] = data.rawData[i];
        }
      }
    }
    delete data.editId
    delete data.rawData
    data.edit = false
  }

  exchange(data) {
    console.log(data);
  }

  searchFromFilter() {
    if (this.paginator) {
      this.tableData.sortField.field = this.tableData.initSortField
      this.tableData.sortField.order = this.tableData.initSortOrder
      this.paginator.changePage(0)
    } else {
      this.search()
    }
  }

  refresh() {
    this.queryPannel.ipts.forEach(item => {
      item.value = null
    })
  }

  getSearchParams() {
    return {
      functionCodeList: this.functionCode ? this.functionCode.map(item=>item.label): [],
      description: this.description || "",
    }
  }

  sortSearch(field) {
    this.tableData.sortField.field = field
    if (!this.tableData.sortField.order) {
      this.tableData.sortField.order = -1
    } else {
      this.tableData.sortField.order = -this.tableData.sortField.order
    }
    this.search()
  }
  search() {
    let data:any = this.getSearchParams()
    let sortParams = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField
    }
    data = {...data, ...sortParams}
    this.tableData.loadingSwitch = true
    HttpHelper.post(URLDICT.COMMON_UAM_FUNCTION_SEARCH, data).then(res => {
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.lastSearchParams = data
        this.tableData.totalRecords = res.msg
        this.showDataToTable(res.data)
      } else {

      }
    })
  }
  exportXlsx(){
    let data = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT,
    }
    this.tableData.loadingSwitch = true
    this.stockCommonService.getAccessFunction(data).pipe(
      tap(res=>{
        if(res.code!='000') throw res.msg
      }),
    ).subscribe(res=>{
      this.tableData.loadingSwitch = false
      let data = this.formatData(res.data)
      let columns = [
        ...this.tableData.frozenColumns,
        ...this.tableData.showColumns,
      ]
      CommonMethod.downloadXlsxWithOptions(
        data,
        columns,
        `UAM_Access_Function_${this.datePipe.transform(new Date, 'YYYYMMDDHHmmss')}`,
        this.datePipe,
        {fieldKey: 'field'}
      )
    },err=>{
      this.tableData.loadingSwitch = false
      this.showMessage('error', 'System', err)
    })
  }
  formatData(list){
    return list.map(item => {
      let roleList = (<string>item.roleCodes).split(',')
      this.tableData.columns.forEach(col => {
        item[col.field] = roleList.includes(col.field)
        // item[col.field] = col.field
        item[col.field + 'cb'] = item.roleCodes.includes(col.field) ? [col.field] : []
      })
      return item
    });
  }
  showDataToTable(list) {
    list.forEach(item => {
      let roleList = (<string>item.roleCodes).split(',')
      this.tableData.columns.forEach(col => {
        item[col.field] = roleList.includes(col.field)
        // item[col.field] = col.field
        item[col.field + 'cb'] = item.roleCodes.includes(col.field) ? [col.field] : []
      })
    });
    this.tableData.data =  list
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search()
  }

  showMessage(severity, summary, detail){
    this.msgServe.add({severity, summary, detail});
  }
}
