import { makeAutoObservable } from 'mobx';
import { ChangeDetectorRef, Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { DemandStore } from './DemandStore';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { DemandCycleStore } from '../demandCycle/DemandCycleStore';
import { SearchFieldRecordor } from '@/service/common/search-record';
import { CommonStore } from '@/service/common/common-store-service';
import { CommonMethod } from '@/util/CommonMethod';
import { DatePipe } from '@angular/common';

@Injectable()
export class SearchStore {
  breadcrumb = this.demandStore.info.title
  searchFieldRecordor: SearchFieldRecordor;
  cdRef: ChangeDetectorRef
  parentData ={
    ipts: [
      {
        title: this.demandStore.TITLE.FORECAST_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      }, 
     /*  {
        title: this.demandStore.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-6 p-lg-3",
        disabled: true,
        value: null,
      }, */
       {
        title: this.demandStore.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.demandStore.TITLE.FORECAST_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.demandStore.TITLE.FORECAST_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData = {
    ipts: [
      {
        title: this.demandStore.TITLE.CHANNEL_ID,
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-4",
        value: null,
        options: [],
        error: false,
      }, {
        title: this.demandStore.TITLE.ITEM_NUMBER,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-4",
        value: null,
        dataKey: 'code',
        skipAllCheckMatch: true,
        showValue: null,
        options: [],
        optionLabel: 'name',
        optionValue: 'code',
      }
    ],
    btns: [
      {
        title: 'Back',
        class: "p-button-outlined p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.back() }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      }, 
      {
        title: 'Export',
        class: "p-mr-1",
        show: true,
        handler: { click: () => this.exportXlsx() }
      },
      {
        title: 'Create New Demand',
        class: "p-mr-1",
        permissionType: 'c',
        show: true,
        disabled: true,
        handler: { click: () => this.create() }
      },
      {
        title: 'Search',
        class: "",
        show: true,
        handler: { click: () => this.searchFromFilter() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12'
  }
  tableData = {
    btns: [
    ],
    data: [],
    columns: [
      {field: 'channelName', title: this.demandStore.QUERY_TITLE.CHANNEL_ID, width: '120px'},
      {field: 'itemName', title: this.demandStore.QUERY_TITLE.ITEM_NUMBER, width: '130px'},
      {field: 'description', title: this.demandStore.QUERY_TITLE.DESCRIPTION, width: '200px'},
      {field: 'uom', title: this.demandStore.QUERY_TITLE.UOM, width: '80px',},
      {field: 'qtyAvailable', title: this.demandStore.QUERY_TITLE.AVAILABLE_QTY, width: '130px'},
      {field: 'qtyIntransit', title: this.demandStore.QUERY_TITLE.TRANSIT_QTY, width: '130px'},
      {field: 'qtyRequest', title: this.demandStore.QUERY_TITLE.REQUEST_QTY, width: '130px', type: 'number'},
      {field: 'qtyPast7dSales', title: this.demandStore.QUERY_TITLE.PAST_7_DAYS_SALES, width: '170px'},
      {field: 'qtyDoc', title: this.demandStore.QUERY_TITLE.DATE_OF_COVER, width: '130px'},
      {field: 'updateAccountName', title: this.demandStore.QUERY_TITLE.UPDATED_BY, width: '130px'},
      {field: 'updateAt', title: this.demandStore.QUERY_TITLE.UPDATED_DATETIME, width: '160px', type: 'datetime'},
      {field: 'isAcknowledged', title: this.demandStore.QUERY_TITLE.ACKNOWLEAGE, width: '130px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {field: '', title: 'Action', width: '100px', type: 'action'},
    ],
    frozenWidth: '150px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "updateDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'updateDate',
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  }
  skuCode = []
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  startDateIpt = null
  // endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  itemIpt = null
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  loading:boolean = false
  cloneLine = {}
  selected
  skus
  showBtn:boolean
  searchCache = {}
  resetSearch = false
  paginator = null
  lastSearchParams
  isActionEnable:boolean

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: StockDemandCycleService,
    private demandStore: DemandStore,
    public commonStore: CommonStore,
    public datepipe: DatePipe,
  ) {
    makeAutoObservable(this)
    this.getPermission(route)
    this.tableData.selectedColumns = [...this.tableData.columns]
    this.initOriginIpt()
    this.initQueryIpt()
    // this.initdata()
    this.loadDataByParams().subscribe(
      (res)=>{
        if(res.hasDemand){
          this.initdata(res); this.search(true);
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
    // this.search(true)
  }
  get channelId() {
    return this.channelIpt.value
  }
  get itemsId() {
    return this.itemIpt.value
  }

  get showU() {
    return this.permission?.includes('Edit Demand')
  }
  get access() {
    return false
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title === 'Search' && (item.disabled = !this.permission.includes("Edit Demand"))
      item.title === 'Create New Demand' && (item.disabled = !this.permission.includes("Edit Demand"))
    })
  }
  getOriginByTitle(title){
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  getBtnByTitle(title){
    return this.queryData.btns.find(item => item.title === title)
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_CYCLE_NAME)
    // this.poolTypeIpt = this.getOriginByTitle(this.demandStore.TITLE.STOCK_POOL_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_START_DATE)
    // this.endDateIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_END_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.demandStore.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.demandStore.TITLE.CHANNEL_ID)
    this.itemIpt = this.getQueryByTitle(this.demandStore.TITLE.ITEM_NUMBER)
  }
  initdata(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))) {
    if(!demandData) return
    this.demandData = demandData
    const list = LocalStorageHelper.getObject('POOL_TYPE')
    // const poolType = list.find(item =>  item.id === this.demandData.sdl_stock_pool_type_id);

    this.cycleNameIpt.value = this.demandData.demandCycleName
    // this.poolTypeIpt.value = poolType.name
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    // this.endDateIpt.value =  new Date(this.demandData.demandEndDate)
    this.submissionDeadlineIpt.value =  new Date(this.demandData.submissionDeadline)
    const btn = this.getBtnByTitle("Create New Demand")
    btn.disabled = this.demandData.action
    this.showBtn = this.demandData.action
    this.setInitSearchData()
  }

  checkActionDisable(channelId){
    this.isActionEnable = CommonMethod.haveChannelPermission(channelId)
  }

  setInitSearchData() {
    const channels = LocalStorageHelper.getObject('REPOTREE')
    this.channelIpt.options = [{ data: 0, label: "ALL", expanded: true, children: channels }]

    const skuList = LocalStorageHelper.getObject('SKU')
    skuList.forEach(item => {
      item.name += " ~ " + item.flag
    });
    this.itemIpt.options = skuList
    this.skuCode = skuList
    this.skus = skuList
  }
  save(data) {
    const saveData = JSON.parse(JSON.stringify(data))
    delete saveData.updateBy
    delete saveData.createBy
    delete saveData.action
    delete saveData.edit
    if(saveData.qtyRequest === null || saveData.qtyRequest < 1){
      this.demandStore.showMessage('warn', this.demandStore.info.title, this.demandStore.info.requestQtyRequired)
      return
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.demandEdit(data).subscribe(res => {
      data.edit = false
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.demandStore.showMessage('success', this.demandStore.info.title,  this.demandStore.info.success.replace('Created', 'Update'))
        this.search()
      } else {
        this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.fail.replace('create', 'Update'))
      }
    })
  }
  cancel(data, index: number) {
    const currrentQty = data.qtyRequest
    const oldQty = this.cloneLine[data.id].qtyRequest
    const params = { data, index }
    if(currrentQty !== oldQty){
      this.demandStore.restoreData('confirmCancel', this, params, this.demandStore.info.noChange)
    } else {
      this.confirmCancel(params)
    }
  }
  confirmCancel(params) {
    const {data, index} = params
    data.edit = false
    this.tableData.data[index].qtyRequest = this.cloneLine[data.id].qtyRequest;
    delete this.cloneLine[data.id];
  }

  editRow(data) {
    data.edit= true
    this.cloneLine[data.id] = {...data};
  }
  back() {
    // window.history.back()
    // this.router.navigate(['main/demand_cycle/search'])
    let searchParams = this.commonStore.getObject(CONFIG.SEARCHPARAMSKEY.DEMANDCYCLE)
    if(!searchParams){searchParams = {}}
    this.router.navigate(["main/demand_cycle/search"], {queryParams: {...searchParams}})
  }

  create() {
    this.router.navigate(["main",'demand_cycle','demand','create'], {queryParams: {id: this.demandData.demandCycleId}})
  }

  clear() {
    this.queryData.ipts.forEach(item => {
      item.value = null
      item.showValue = null
    })
  }
  delete(event, data) {
    event.stopPropagation()
    this.demandStore.restoreData('confirmDelete', this, data, this.demandStore.info.delete)
  }
  confirmDelete(data){
    this.loading = true
    this.tableData.loadingSwitch = true
    const ids = [data.id]
    this.service.demandDelete({ids}).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.demandStore.showMessage('success', this.demandStore.info.title, this.demandStore.info.success.replace('Created', 'Deleted'))
        this.clear()
        this.search()
      } else {
        this.demandStore.showMessage('error', this.demandStore.info.title, "Delete failed!")
      }
    })
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  search(comeFrom = false) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      demandCycleId: this.demandData.demandCycleId,
      accountName: this.demandStore.account,
      ...(this.channelId && {toChanelId: this.channelId.id}),
      ...(this.itemsId && {items: this.itemsId.map(item => item.code)})
    }
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }
    this.lastSearchParams = data;
    this.service.demandSearch(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        list = res.data
        this.tableData.totalRecords = res.msg
        this.checkActionDisable(res.data[0].toChannelId)
      } else {
        this.demandStore.showMessage('error', this.demandStore.info.title, res.msg || res.message)
      }
      this.tableData.data = list
    })
      }
  sortSearch(field) {
    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
    this.search()
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search(this.resetSearch ? true : false );
    this.resetSearch = false
  }
  stopEvent(event){
    event.stopPropagation()
  }
  tableSelectedColumnsChange(e){
    this.tableData.selectedColumns = this.tableData.columns.filter(col => e.includes(col));
  }
  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
  
  customSort(e){

  }

  exportXlsx(){
    let data = {
      ...this.lastSearchParams,
      pageIndex: 0,
      pageSize: CONFIG.XLSX_ROW_LIMIT,
    }
    
    this.service.demandSearch(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        list = res.data
        // this.tableData.totalRecords = res.msg
      } else {
        this.demandStore.showMessage('error', this.demandStore.info.title, res.msg || res.message)
      }

      let cols = this.tableData.columns
      let header = [
        { key: this.demandStore.TITLE.FORECAST_CYCLE_NAME, value: this.demandData.demandCycleName},
        { key: this.demandStore.TITLE.SUBMISSION_DEADLINE, value: this.datepipe.transform(this.demandData.submissionDeadline, 'yyyy/MM/dd')},
        { key: this.demandStore.TITLE.FORECAST_START_DATE, value: this.datepipe.transform(this.demandData.demandStartDate, 'yyyy/MM/dd')},
      ]
      CommonMethod.downloadXlsxWithOptions(
        list,
        cols,
        `Demand_Cycle__${this.datepipe.transform(new Date(), 'YYYYMMDDhhmmss')}`,
        this.datepipe,
        {fieldKey: 'field', header: header}
      )
    })
  }
}
