import { HttpHelper } from '../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from '../../config';
import { from, of } from 'rxjs';
import { map, switchMap, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QtyOnhandSerialService {
  search(data) {
    return from(HttpHelper.post(URLDICT.QTY_ONHAND_SERIAL_SEARCH, data))
  }
}
