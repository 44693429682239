
import { SearchStore } from '@/stores/stock/qtyOnlandSerial/SearchStore';
import { Component, OnInit } from '@angular/core';

@Component({
  providers: [SearchStore],
  selector: 'app-qty-onhand-serial',
  templateUrl: './qty-onhand-serial.component.html',
  styleUrls: ['./qty-onhand-serial.component.css']
})
export class QtyOnhandSerialComponent implements OnInit {

  constructor(public store: SearchStore) { }

  ngOnInit(): void {
  }

}
