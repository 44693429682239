import { makeAutoObservable, reaction, toJS } from 'mobx';
import { Injectable } from "@angular/core";
import { INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { DemandStore } from './DemandStore';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';

@Injectable()
export class CreateStore {
  breadcrumb = "Demand"
  parentData ={
    ipts: [
      {
        title: this.demandStore.TITLE.FORECAST_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      },
     /*  {
        title: this.demandStore.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-6 p-lg-3",
        disabled: true,
        value: null,
      },  */
      {
        title: this.demandStore.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.demandStore.TITLE.FORECAST_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.demandStore.TITLE.FORECAST_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData = {
    ipts: [
      {
        title: this.demandStore.TITLE.CHANNEL_ID,
        type: INPUT_TYPE.SELECTGROUP,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        error: false,
      }, {
        title: this.demandStore.TITLE.ITEM_NUMBER,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        dataKey: 'code',
        optionLabel: 'name',
        optionValue: 'code',
      }
    ],
    btns: [
      {
        title: 'Back',
        class: "p-button-outlined p-order-2 p-mr-1",
        show: true,
        handler: { click: () => this.back() }
      }, {
        title: 'Save',
        class: "p-order-4  p-mr-1",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.save() }
      }, {
        title: 'Add Item',
        class: "p-order-0 p-mr-auto",
        show: true,
        handler: { click: () => this.addItem() }
      }, {
        title: 'upload Item(s)',
        class: "p-order-1 p-mr-1",
        show: false,
        disabled: true,
        // handler: { click: () => this.back() }
      },
    ],
    change: (i,$event,ipt)=>{
      if(ipt.title == this.demandStore.TITLE.CHANNEL_ID){
        this.channelChanged($event)
      }
    },
    btnsclass: 'p-d-flex p-col-6'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'channelName', title: this.demandStore.QUERY_TITLE.CHANNEL_ID, width: '120px'},
      {field: 'itemName', title: this.demandStore.QUERY_TITLE.ITEM_NUMBER, width: '130px'},
      {field: 'description', title: this.demandStore.QUERY_TITLE.DESCRIPTION, width: '200px'},
      {field: 'uom', title: this.demandStore.QUERY_TITLE.UOM, width: '80px',},
      {field: 'qtyAvailable', title: this.demandStore.QUERY_TITLE.AVAILABLE_QTY, width: '130px'},
      {field: 'qtyIntransit', title: this.demandStore.QUERY_TITLE.TRANSIT_QTY, width: '130px'},
      {field: 'qtyPast7dSales', title: this.demandStore.QUERY_TITLE.PAST_7_DAYS_SALES, width: '170px'},
      {field: 'qtyDoc', title: this.demandStore.QUERY_TITLE.DATE_OF_COVER, width: '130px'},
      {field: 'qtyRequest', title: this.demandStore.QUERY_TITLE.REQUEST_QTY, width: '130px', type: 'number'},
      {field: 'updateAccountName', title: this.demandStore.QUERY_TITLE.UPDATED_BY, width: '130px'},
      {field: 'updateAt', title: this.demandStore.QUERY_TITLE.UPDATED_DATETIME, width: '160px', type: 'datetime'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {field: '', title: 'Action', width: '100px', type: 'action'},
    ],
    frozenWidth: '100px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "completeTime", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'updateDate',
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  }
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  scrollable:boolean = true
  loading:boolean = false
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  startDateIpt = null
  // endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  itemIpt = null
  channels = []
  demandLineList = []
  idx = 0
  skus = []
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: StockDemandCycleService,
    public demandStore: DemandStore) {
    makeAutoObservable(this)
    this.tableData.selectedColumns = [...this.tableData.columns]
    this.getPermission(route)
    this.initOriginIpt()
    this.initQueryIpt()
    // this.initQueryData()
    this.loadDataByParams().subscribe(
      (res)=>{
        if(res.hasDemand){
          this.initQueryData(res);
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
  }

  get channelId() {
    return this.channelIpt.value
  }
  get itemId() {
    return this.itemIpt.value
  }
  get showU() {
    return this.permission?.includes('Edit Demand')
  }

  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title !== 'Back' && (item.disabled = !this.permission.includes("Edit Demand"))
    })
  }
  getOriginByTitle(title){
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_CYCLE_NAME)
    // this.poolTypeIpt = this.getOriginByTitle(this.demandStore.TITLE.STOCK_POOL_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_START_DATE)
    // this.endDateIpt = this.getOriginByTitle(this.demandStore.TITLE.FORECAST_END_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.demandStore.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.demandStore.TITLE.CHANNEL_ID)
    this.itemIpt = this.getQueryByTitle(this.demandStore.TITLE.ITEM_NUMBER)
    // reaction (
    //   () => this.channelId,
    //   (channelValue) => {
    //     const data =  {
    //       dtlRepoId: channelValue
    //     }
    //     if(channelValue) {
    //       this.channelIpt.error = false
    //       this.loading = true
    //       this.service.searchRepo(data).subscribe(res => {
    //         this.itemIpt.value = null
    //         let list = []
    //         if(res.code === '000') {
    //           list = res.data.map(item => {
    //             item.skuCode += " ~ " + item.skuDesc
    //             const {skuCode: name, dtlSkuId: value} = item
    //             return {name, value}
    //           });
    //         }
    //         this.loading = false
    //         if(!list.length) {
    //           this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.notdata)
    //         }
    //         this.itemIpt.options = list
    //         this.setItemDefaultValue(channelValue)
    //       })
    //     }
    //   }
    // );
  }
  channelChanged(channelValue, skipError = false){
    
    // if(channelValue.target) return
    // if(Number.isNaN(Number.parseInt(channelValue))) return

    // const data =  {
    //   dtlRepoId: channelValue
    // }
    // if(channelValue) {
    //   this.channelIpt.error = false
    //   this.loading = true
    //   this.service.searchRepo(data).subscribe(res => {
    //     this.itemIpt.value = null
    //     let list = []
    //     if(res.code === '000') {
    //       list = res.data.map(item => {
    //         item.skuCode += " ~ " + item.skuDesc
    //         const {skuCode: name, dtlSkuId: value} = item
    //         return {name, value}
    //       });
    //     }
    //     this.loading = false
    //     if(!list.length && !skipError) {
    //       this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.notdata)
    //     }
    //     this.itemIpt.options = list
    //     this.setItemDefaultValue(channelValue)
    //   }
    //   )
    // }
  }
  setItemDefaultValue(id) {
    if(this.demandLineList.length) {
      const tlist = this.channelIpt.options.map(item => item.items).filter(item => item.length)[0]
      const repo = tlist.find(channel => channel.value === id)
      const nameList = this.itemIpt.options.map( item => item.name)
      const exists = this.demandLineList.filter(line => nameList.includes(line.itemName))
      const result = exists.filter(item => item.channelName === repo.name).map(item => item.itemId)
      // tslint:disable-next-line: no-unused-expression
      result.length && (this.itemIpt.value = this.itemIpt.options.filter(item => result.includes(item.value)))
    }
  }
  initQueryData(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))){
    if(!demandData) return
    this.demandData = demandData
    const list = LocalStorageHelper.getObject('POOL_TYPE')
    // const poolType = list.find(item =>  item.id === this.demandData.sdl_stock_pool_type_id);

    this.cycleNameIpt.value = this.demandData.demandCycleName
    // this.poolTypeIpt.value = poolType.name
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    // this.endDateIpt.value =  new Date(this.demandData.demandEndDate)
    this.submissionDeadlineIpt.value =  new Date(this.demandData.submissionDeadline)
    this.setInitSearchData()
  }
  setInitSearchData() {
    this.channels = LocalStorageHelper.getObject('REPOMODULEBYUAMGROUP').filter(repo=>repo.items.length)
    this.channelIpt.options = this.channels
    const list = this.channels.map(item => item.items).filter(item=>item.length)[0]
    this.channelIpt.value = list[0].value
    //this.channelChanged(this.channelIpt.value, true)
    this.skus = LocalStorageHelper.getObject('SKU')
    this.skus.forEach(item => {
      item.name += " ~ " + item.flag
    });
    this.itemIpt.options = this.skus
  }
  addItem() {
    if(!this.channelId || !this.itemId) {
      this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.required)
      // tslint:disable-next-line: no-unused-expression
      !this.channelId && (this.channelIpt.error = true)
      // tslint:disable-next-line: no-unused-expression
      !this.itemId && (this.itemIpt.error = true)
      return
    }
    if(this.tableData.data.length){
      const isExist = this.tableData.data.some(item => item.toChannelId === this.channelId && item.itemId === this.itemId);
      if(isExist) {
        this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.exist)
        return
      }
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    const data = {
      accountName: this.demandStore.account,
      repoId: this.channelId,
      skuId: this.itemId.map(item => item.code)
    }
    this.service.demandAddItem(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        // this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.success)
        this.showDataToTable(res.data)
      } else {
        this.demandStore.showMessage('warn', this.demandStore.info.title,  this.demandStore.info.fail)
      }
    })
  }

  showDataToTable(lineData) {
    //处理重复的数据
    const list = lineData.filter(newline => {
      const result = this.demandLineList.some(line => line.channelName === newline.channelName && line.itemName === newline.itemName)
      if(!result) {
        newline.id = this.idx++
        return newline
      }
    });
    //处理移除的数据
    console.log('channel', this.channelId, toJS(this.demandLineList), lineData);
    // const lnlist = lineData.map(line => line.itemName);
    this.demandLineList = this.demandLineList.filter(item => {
      return (item.toChannelId === this.channelId)  || item.toChannelId !== this.channelId
    })

    this.demandLineList = [...list, ...this.demandLineList]
    this.tableData.data = this.demandLineList
  }
  delete(data) {
    this.demandStore.restoreData('confirmDelete', this, data)
  }

  confirmDelete(data) {
    console.log(this.demandLineList, data);
    this.demandLineList = this.demandLineList.filter(item => item.id !== data.id)
    this.tableData.data = this.demandLineList
  }
  clear() {
    this.queryData.ipts.forEach(item => {
      item.value = null
    })
  }

  validationData() {
    let flag = true
    if(!this.tableData.data.length) {
      this.demandStore.showMessage('warn', this.demandStore.info.title, this.demandStore.info.lineRequired)
      flag = false
    }else{
      flag = this.tableData.data.every(item => item.qtyRequest !== null && item.qtyRequest > 0)
      // tslint:disable-next-line: no-unused-expression
      !flag && this.demandStore.showMessage('warn', this.demandStore.info.title, this.demandStore.info.requestQtyRequired)
    }
    return flag
  }
  save() {
    if(!this.validationData()){
      return
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.tableData.data.forEach(item => {
      delete item.id
    })
    const data = {
      accountName: this.demandStore.account,
      demandCycleId: this.demandData.demandCycleId,
      lines: this.tableData.data
    }
    this.service.demandCreate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.demandStore.showMessage('success', this.demandStore.info.title,  this.demandStore.info.success)
        this.redirect()
      } else {
        this.demandStore.showMessage('warn', this.demandStore.info.title,  res.msg)
        this.idx = 0
        this.tableData.data.forEach(item => {
          item.id = this.idx++
        })
      }
    })
  }
  redirect(){
    setTimeout(() => {
      this.router.navigate(["main/demand_cycle/demand/search"])
    }, 200);
  }

  stopEvent(event){
    event.stopPropagation()
  }

  sortSearch(data) {
    console.log(data);
  }

  back() {
    window.history.back()
  }
  tableSelectedColumnsChange(e){
    this.tableData.selectedColumns = this.tableData.columns.filter(col => e.includes(col));
  }

  // loadDataByParams(){
  //   if(this.route.snapshot.queryParams && this.route.snapshot.queryParams['id']){
  //     let id = this.route.snapshot.queryParams['id'];
  //     if(this.demandData&&this.demandData.demandCycleId.toString()!=id){
  //       return throwError(null);
  //     }else if(this.demandData&&this.demandData.demandCycleId.toString()==id){
  //       return of(this.demandData).pipe(tap(()=>{}))
  //     }else{
  //       return this.demandStore.searchDemandCycleByIdAndPreProcess(id).pipe(tap(res=>{
  //         if(res.hasDemand){
  //           this.initQueryData(res)
  //         }else{
  //           throw null;
  //         }
  //       }))
  //     }
  //   }else{
  //     return throwError(null);
  //   }
  // }

  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
}
