import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { LisInterfaceStore } from 'src/app/stores/stock/lisInterface/lisInterfaceStore';
import {computed,observable} from "mobx";
import {HttpHelper} from "../../../util/HttpHelper";
import {URLDICT} from "../../../stores/base/BaseStore";
// import { StockReservationService } from '@/service/stock/stock-reservation.service';
// import { RequestDetailStore } from '@/stores/stock/reservation/requestDetailStore';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonService } from '@/service/common/common-service';
@Component({
  providers: [LisInterfaceStore],
  selector: 'app-lis-interface',
  templateUrl: './lis-interface.component.html',
  styleUrls: ['./lis-interface.component.css']
})
export class LisInterfaceComponent implements AfterViewInit {
  @ViewChild('paginator', {static: false}) paginator: Paginator;
  @ViewChild('ptable', {static: false}) ptable: Table;
  constructor(public store: LisInterfaceStore,
    public n: MessageService,
    public r: Router,
    // private detail: RequestDetailStore,
    public p: ActivatedRoute,
    public m: ConfirmationService,
    private i18n: TranslateService,
    // public stockReservationService: StockReservationService,
    public datepipe: DatePipe,
    public cdRef: ChangeDetectorRef,
    public commonService: CommonService,
    ) { }
  @observable permission: string[]
  @observable loadingSwitch = false
  ngAfterViewInit(): void {
    this.store.table = this.ptable
    this.store.paginator = this.paginator
  }
/*   @computed get showEdit() {
    return this.permission?.includes('Edit Reservation')
  } */
}
