import { INPUT_TYPE } from "@/stores/base/BaseStore"
import { Location as _location } from "@angular/common"
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from "@angular/router"
import { from, Observable, Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { CommonStore } from "./common-store-service"

export class SearchFieldRecordor {

  restoredStateId
  sub: Subscription
  routePath

  constructor(
    public commonStore: CommonStore,
    public router: Router, 
    public route: ActivatedRoute,
    public key: string,
  ) {}

  storeSearchParams(_data, ipts, iptNameKey = 'nickName'){
    let _lastSearchRouteParam:any = {}
    // Object.keys(data).forEach(key=>{
    //   let isIpt = ipts.some(ipt=>ipt[iptNameKey]==key)
    //   if(isIpt) _lastSearchRouteParam[key] = data[key]
    // })
    let data = _data
    if(data['accountName']) delete data['accountName'];

    ipts.forEach(ipt => {
      if(ipt.type == INPUT_TYPE.MUTIPLETREESELECT){
        if(ipt._selectedIds)data[`_${ipt.name}`] = ipt._selectedIds
      }
    });

    Object.keys(data).forEach(key=>{
      if(data[key]!=null){
        if(typeof data[key] == 'object') {
          _lastSearchRouteParam[key] = JSON.stringify(data[key])
        }else{
          _lastSearchRouteParam[key] = data[key]
        }
      }else{
        _lastSearchRouteParam[key]
      }
    })
    if(this.router.url.split('?')[0] !== this.routePath) return
    this.commonStore.setObject(this.key, _lastSearchRouteParam)
    if(Object.keys(_lastSearchRouteParam).length){
      // this.router.navigate([], { relativeTo: this.route, queryParams: _lastSearchRouteParam });
      let url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: _lastSearchRouteParam }).toString()
      this.commonStore.location.replaceState(url);
    }else{
      // this.router.navigate([], { relativeTo: this.route });
      let url = this.router.createUrlTree([], { relativeTo: this.route }).toString()
      this.commonStore.location.replaceState(url);
    }
  }
  
  setRouterEventForSearchParams(router):Observable<any>{
    let resolver
    let pro = new Promise((_resolver)=>{resolver = _resolver})
    let routePath = router.url.split('?')[0]
    this.routePath = routePath;
    this.sub = (<Router>router).events
    .pipe(filter(
      ( event: RouterEvent ) => { return( event instanceof NavigationStart || event instanceof NavigationEnd ); }
    )).subscribe(
      (event: RouterEvent)=>{
        if ( event instanceof NavigationStart && event.restoredState ) {
          this.restoredStateId = event.id;
        }
        if ( event instanceof NavigationEnd ) {
          if(this.restoredStateId == event.id || event.url.split('?')[0] == routePath){
            // unsub
            this.sub.unsubscribe()
            this.sub=null

            // get data
            let data = this.route.snapshot.queryParams;
            let _data = {}
            
            Object.keys(data).forEach(key=>{
              if(this.isJsonString(data[key])) {
                _data[key] = JSON.parse(data[key])
                if(Array.isArray(_data[key])&&_data[key].length==0){_data[key] = null}
              }else{
                _data[key] = data[key]
              }
            })

            resolver(_data)
          }
        }
      }
    )
    return from(pro)
  }

  onDestory(){
    if(this.sub){
      this.sub.unsubscribe()
    }
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  parse(){
    
  }

  getRouteParams():any{
    
    let data = this.route.snapshot.queryParams;
    let _data = {
      accountName: this.commonStore.getAccount(),
    }
    
    Object.keys(data).forEach(key=>{
      if(this.isJsonString(data[key])) {
        _data[key] = JSON.parse(data[key])
        if(Array.isArray(_data[key])&&_data[key].length==0){_data[key] = null}
      }else{
        _data[key] = data[key]
      }
    })
    return _data
  }
}
