import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {action, computed, observable} from "mobx";

import {HttpHelper} from "../../../util/HttpHelper";
import {INPUT_TYPE, NOTI_MSG, NOTI_TITLE, URLDICT} from "../../../stores/base/BaseStore";
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { FUNCTION_CODE } from '@/service/common/permission.type';
import { PermissionService } from '@/service/common/permission-service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @observable permission: string[]
  // @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false

  @observable lisOfData: any[] = []
  @observable selectedProducts: any[] = []
  @observable queryPannel: any
  @observable createPannel: any

  queryTitle = 'UAM - Role Function Mapping'
  roles: any[]
  channels: any[]
  functions: any[]
  roleId
  repoId
  functionId
  seRoleId
  seFunctionId
  seRepoId
  errorInfo = {
    role: "the Role Name can not be empty, please choose it!",
    function: "the Function can not be empty, please choose it!"
  }
  // ids: number[] = []
  // mapOfCheckedId: { [key: string]: boolean } = {};
  // showCheckboxSwitch = false
  // allCheckBoxValue = false


  _selectedColumns: any[] = [];

  pageIndex: number = 0
  pageSize: number = 20
  totalRecords = 0

  field = 'roleId'
  order = -1
  isSortSearch
  editing = false

  cols = [
    {header: 'roleId', title: 'Role Name', width: '410px'},
    {header: 'functionId', title: 'Function', width: '410px'},
  ]
  searchCache = {}
  resetSearch = false
  rolemappingEditEnable = false;
  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public n: MessageService, public m: ConfirmationService, private p:ActivatedRoute,private permissionService: PermissionService) {
    this._selectedColumns = this.cols
    this.functions = JSON.parse(localStorage.getItem("UAM_FUNCTION"))
    this.roles = JSON.parse(localStorage.getItem("UAM_ROLE"))
    this.initQueryData()
    this.getPermission(p)
  }
  initQueryData() {
    this.queryPannel = {
      ipts:[
        {
          title: "Role Name",
          type: INPUT_TYPE.SELECT,
          options: this.roles,
          optionLabel: 'name',
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }, {
          title: "Function",
          type: INPUT_TYPE.SELECT,
          options: this.functions,
          optionLabel: 'name',
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-4",
      btns:[{
          title: 'Search',
          show: true,
          class: "p-order-1",
          handler:{
            "click":()=>{
              this.searchFromFilter()
            }
          }
        }, {
          title: 'Clear',
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          show: true,
          handler:{
            "click":()=>{
              this.refresh()
            }
          }
      }]
    }
    this.createPannel = {
      ipts:[
        {
          title: "Role Name",
          type: INPUT_TYPE.SELECT,
          options: this.roles,
          optionLabel: 'name',
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-4"
        }, {
          title: "Function",
          type: INPUT_TYPE.SELECT,
          options: this.functions,
          optionLabel: 'name',
          optionValue: 'value',
          value: null,
          class: "p-col-12 p-md-4 p-lg-4",
        }],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-4",
      btns:[{
        title: 'Create',
        class: "p-order-1",
        handler:{
          "click":()=>{
            this.save()
          }
        }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        handler:{
          "click":()=>{
            this.clear()
          }
        }
    }]
    }
  }
  @action getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')['UAM Permission Setting']
    // console.log('>-- get permission --<', p.snapshot.data.name, this.permission);
    this.setPermission();``
  }
  @computed get showU(){
    return true // this.permission?.includes('Update')
  }
  @computed get showC(){
    return true // this.permission?.includes('Create')
  }
  @computed get showD(){
    return true // this.permission?.includes('Delete')
  }


  ngOnInit(): void {
    this.sortPageSearch(true)
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.sortPageSearch(true)
  }
  search(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.pageIndex
      data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_FUNCTION_SEARCH, data).then(res => {
      if (res.code == '000') {
        // console.log("data:" + JSON.stringify(res.data))
        res.data.map(item => {
          let roleArr = this.roles.filter(sub => sub.label === item.roleName)
          item.role = roleArr.length ? roleArr[0].value : -1
          let funcArr = this.functions.filter(sub => sub.value == item.functionId)
          item.func = funcArr.length ? funcArr[0].value : -1
        })

        this.lisOfData = res.data
        // this.lisOfData.forEach(d => {
        //   d.repoId = d.repoId + ''
        // })
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System", "Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch = false
    }).catch(e => console.log(e)).finally(
      () => this.loadingSwitch = false
    )
  }

  refresh() {
    this.seRoleId = null
    this.seFunctionId = null
    this.queryPannel.ipts[0].value = null
    this.queryPannel.ipts[1].value = null
    // this.search()
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));

  }

  clear() {
    this.roleId = null
    this.functionId = null
    this.createPannel.ipts[0].value = null
    this.createPannel.ipts[1].value = null
  }

  save() {
    let valid = true
    this.roleId = this.createPannel.ipts[0].value
    this.functionId = this.createPannel.ipts[1].value
    if (!this.roleId) {
      // this.n.warning("System", this.errorInfo.role);
      this.showMessage('warn', 'System', this.errorInfo.role)
      valid = false
      return valid
    }
    if (!this.functionId) {
      // this.n.warning("System", this.errorInfo.function);
      this.showMessage('warn', 'System', this.errorInfo.function)
      valid = false
      return valid
    }

    if (valid) {
      this.loadingSwitch = true
      HttpHelper.post(URLDICT.UAM_ROLE_FUNCTION_CREATE, {
        roleId: this.roleId,
        functionId: this.functionId
        // pageIndex: this.pageIndex,
        // pageSize: this.pageSize
      }).then(res => {

        if (res.code == '000') {
          // this.n.success("System", "Create Successful!")
          this.showMessage('success', 'System', 'Create Successful!')
          this.clear()
          this.sortPageSearch()
        } else {
          // this.n.error("System", res.msg)
          this.showMessage('error', 'System', res.msg)
        }
        this.loadingSwitch = false
      }).catch(e => console.log(e.toString())).finally(
        () => this.loadingSwitch = false
      )
    }
  }

  cancel(d) {
    if (d.newid) {
      d.mappingId = d.newid
      delete d.newid
    }
    this.editing = false;
    this.sortPageSearch()
  }

  edit(d) {
    if (d.mappingId) {
      d.newid = d.mappingId
      delete d.mappingId
    }
    this.editing = true;
    // console.log(d)
  }

  page(e) {
    // console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    // if (this.isSortSearch) this.sortPageSearch(this.resetSearch ? true : false)
    // else this.search(this.resetSearch ? true : false)
    this.sortPageSearch(this.resetSearch ? true : false)
    this.resetSearch = false
  }

  sortSearch(key) {
    // console.log(key);
    this.field = key
    this.isSortSearch = true
    if (!this.order) {
      this.order = -1
    } else if (this.order == -1) {
      this.order = 1
    } else if (this.order == 1) {
      this.order = -1
    }
    this.sortPageSearch()
  }
  getSearchParams() {
    return {
      ...(this.queryPannel.ipts[0].value && {roleId: this.queryPannel.ipts[0].value}),
      ...(this.queryPannel.ipts[1].value && {functionId: this.queryPannel.ipts[1].value}),
      // pageIndex: this.pageIndex,
      // pageSize: this.pageSize
    }
  }
  sortPageSearch(comeFrom = false) {
    let data:any = this.getSearchParams()
    if (comeFrom) {
      this.searchCache = data
    } else {
        data = this.searchCache
        data.pageIndex = this.pageIndex
        data.pageSize = this.pageSize
      // data.sortEvent = this.tableData.sortField
    }
    let sortParams = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order}
    }
    data = {...data, ...sortParams}
    // data['sortEvent'] = {field: this.field, order: this.order}
    // console.log("sortData:" + JSON.stringify(data))
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_FUNCTION_SORTSEARCH, data).then(res => {
      if (res.code == '000') {
        this.lisOfData = res.data
        // this.lisOfData.forEach(d => {
        //   d.repoId = d.repoId + ''
        // })
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // console.log(res);
      } else {
        // this.n.error("System", "Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch = false
    }).catch(e => {
      // console.log(e)
      this.loadingSwitch = false
    })
  }

  editSave(data) {
    if (data.newid) {
      data.mappingId = data.newid
      delete data.newid
    }
    // console.log("editData:", data);
    if(!data.role){
      // this.n.warning("System", this.errorInfo.role);
      this.showMessage('warn', 'System', this.errorInfo.role)
      return
    }
    if(!data.func){
      // this.n.warning("System", this.errorInfo.function);
      this.showMessage('warn', 'System', this.errorInfo.function)
      return
    }
    this.loadingSwitch = true
    HttpHelper.post(URLDICT.UAM_ROLE_FUNCTION_EDIT, {id: data.mappingId, roleId: data.role, functionId: data.func}).then(res => {
      if (res.code == '000') {
        this.sortPageSearch()
        // console.log(res);
      } else {
        // this.n.error("System", "Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
      this.loadingSwitch = false
      this.editing = false;
    }).catch(e => {
      // console.log(e)
      this.loadingSwitch = false
      this.editing = false;
    })
  }

  /* selectOnePNCheckBox(mappingId) {
    if (this.mapOfCheckedId[mappingId]) {
      delete this.mapOfCheckedId[mappingId]
      if (this.allCheckBoxValue) this.allCheckBoxValue = false
    } else {
      this.mapOfCheckedId[mappingId] = true
    }
  } */

  /* selectAllPNCheckbox(v) {
    this.selectAllCheckbox(!this.allCheckBoxValue)
  } */

  /**
   * 设置全选或者全闭
   * @param v => true|false
   */
  /* selectAllCheckbox(v) {
    this.allCheckBoxValue = v
    if (v) {
      this.lisOfData.forEach(d => {
        this.mapOfCheckedId[d.mappingId] = v
        if (d.children != null && d.children.length > 0) {
          d.children.forEach(c => {
            this.mapOfCheckedId[c.mappingId] = v
            // this.checkParentCheckBox(v,d.id,c.id)
          })
        }
      })
    } else {
      this.mapOfCheckedId = {}
    }
    toJS(this.mapOfCheckedId)
  } */

  delete() {
    // let obj = {}
    // obj["ids"] = this.formatSelectedIds()
    // console.log("ids:", this.selectedProducts)

    let len = this.selectedProducts.length
    if (len < 1) {
      // this.n.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    } else {
      const obj = {
        ids: this.selectedProducts.map(item => item.mappingId + "")
      }
      this.m.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.loadingSwitch = true
          HttpHelper.post(URLDICT.UAM_ROLE_FUNCTION_DELETE, obj).then(res => {
            if (res.code == '000') {
              //清空选中并重赋数据源
              this.selectedProducts = []
              // this.allCheckBoxValue = false
              // this.mapOfCheckedId = {}
            } else {
              // this.n.error("System", "Submit Fail!")
              this.showMessage('error', 'System', 'Submit Fail!')
            }
            this.sortPageSearch()
            this.loadingSwitch = false
          }).catch(e => {
            // this.n.error("System", e.toString())
            this.showMessage('error', 'System', e.toString())
            this.loadingSwitch = false
          })
        }
      });
    }
  }

  showMessage(severity, summary, detail){
    this.n.add({severity, summary, detail});
  }
  setPermission(...e){
    let permissionService = this.permissionService
    this.createPannel && this.createPannel.btns.length>0 && this.createPannel.btns.forEach(item=>{
      item.title=='Create' && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.ROLE_FUNCTION_MAPPING_EDIT))
    })
    if(permissionService.havePermission(FUNCTION_CODE.ROLE_FUNCTION_MAPPING_EDIT)){
      this.rolemappingEditEnable=true;
    }
  }
}
