
import { BrowserModule } from '@angular/platform-browser';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { environment } from "../environments/environment";
import { TranslateModule, TranslateLoader, TranslateService, } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule, } from './app-routing.module';

import { AppComponent } from './app.component';
import zh from '@angular/common/locales/zh';
import en from '@angular/common/locales/en';

export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function LocaleIdFactory() {
  return "en-US";
}

//此处需关联对应的i18n
registerLocaleData(LocaleIdFactory() === "zh-CN" ? zh : en);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [
    { provide: LOCALE_ID, useFactory: LocaleIdFactory },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private i18n: TranslateService, @Inject(LOCALE_ID) locale: string){
    if (environment.supportedLocale.indexOf(locale) === -1) {
      locale = "en-US";
    }
    this.i18n.use(locale);
  }
}
