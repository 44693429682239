import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
  * @description: 日期范围选择
  * @author:  ChenShuCheng
  */
@Component({
  selector: 'app-daterange-cst',
  templateUrl: './daterange-cst.html',
  styleUrls:['./daterange-cst.component.css']
})
export class DateRangeCustomComponent {
  @Input() ipt
  @Output() change = new EventEmitter()
  @Output() close = new EventEmitter()
    onClose() {
      this.close.emit();
    }
}
