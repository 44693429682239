import {LocalStorageHelper} from "./LocalStorageHelper";
import { URLDICT} from '../config'


export class HttpHelper {
  static apiError = 'Api call error, please contact system support.'
  static post(url, data) {
    return fetch(url, {
      method: 'POST',
      // credentials: 'include',
      mode: 'cors',    // mode:'no-cors' for no cors
      headers: this.getHeaders(),
      body:JSON.stringify(data)
    })
    .then(res => url.includes(URLDICT.SYSTEM_LOGIN) ? res : res.json())
    .then(res => {
      if(res.status && res.status === 500) {
        return this.getApiError()
      }
      if(res.code && res.code !== '000' && res.msg.includes('500 Server')) {
        res.msg = this.apiError
      }
      return res
    })
    .catch(err => this.getApiError())
  }
  static put(url, data) {
    return fetch(url, {
      method: 'PUT',
      // credentials: 'include',
      mode: 'cors',    // mode:'no-cors' for no cors
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    })
    .then(res => url.includes(URLDICT.SYSTEM_LOGIN) ? res : res.json())
    .then(res => {
      if(res.status && res.status === 500) {
        return this.getApiError()
      }
      if(res.code && res.code !== '000' && res.msg.includes('500 Server')) {
        res.msg = this.apiError
      }
      return res
    })
    .catch(err => this.getApiError())
  }

  static postForExcel(url, data) {
    return fetch(url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    }).then(res => res)
    .catch(err => err)
  }

  /* static getFile(url) {
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.getHeaders(),
    })
    .then(res => res.blob())
    .catch(err => err)
  } */

  static get(url){
    return fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: this.getHeaders(),
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status === 500) {
        return this.getApiError()
      }
      if(res.code && res.code !== '000' && res.msg.includes('500 Server')) {
        res.msg = this.apiError
      }
      if(res.state === "failed") {
        return {...res, data:[]}
      }
      return res
    })
    .catch(err => this.getApiError())
  }
  static getHeaders(){
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': location.hostname,
      'Access-Control-Expose-Headers': '*',
      'Access-Control-Allow-Credentials': 'false',
      'access_token':'Bearer ' + sessionStorage.getItem("access_token"),
      'TOKEN': LocalStorageHelper.getObject("TOKEN"),
    }
  }
  static getApiError() {
    return {
      code: '888',
      data: [],
      state: 'failed',
      msg: this.apiError
    }
  }
  static formPost(url, data){
    return fetch(url, {
      method: 'POST',
      // credentials: 'include',
      // mode: 'cors',    // mode:'no-cors' for no cors
      headers: {
        'Access-Control-Allow-Origin': location.hostname,
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Credentials': 'false',
        'access_token':'Bearer ' + sessionStorage.getItem("access_token"),
        'TOKEN': LocalStorageHelper.getObject("TOKEN"),
      },
      body:data
    })
    .then(res => url.includes(URLDICT.SYSTEM_LOGIN) ? res : res.json())
    .then(res => {
      if(res.status && res.status === 500) {
        return this.getApiError()
      }
      if(res.code && res.code !== '000' && res.msg.includes('500 Server')) {
        res.msg = this.apiError
      }
      return res
    })
    .catch(err => this.getApiError())
  }
  static postWithErrorHandle(url, data) {
    return fetch(url, {
      method: 'POST',
      // credentials: 'include',
      mode: 'cors',    // mode:'no-cors' for no cors
      headers: this.getHeaders(),
      body:JSON.stringify(data)
    })
    .then(res => res)
    .then(res => {
      // if(res.status && res.status === 500) {
      //   return this.getApiError()
      // }
      // if(res.code && res.code !== '000' && res.msg.includes('500 Server')) {
      //   res.msg = this.apiError
      // }
      return res
    })
    .catch(err => this.getApiError())
  }
}
