import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, computed, observable} from "mobx";
import {HttpHelper} from "../../../util/HttpHelper";
import {BUTTON_TYPE, INPUT_TYPE, URLDICT} from "../../../stores/base/BaseStore";
import {CommonMethod} from "../../../util/CommonMethod";
import {Table} from 'primeng/table';
import {LocalStorageHelper} from 'src/app/util/LocalStorageHelper';
import {MessageService, ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {RequestDetailStore} from "../../../stores/stock/reservation/requestDetailStore";
import { StockReservationService } from 'src/app/service/stock/stock-reservation.service';
import { tap } from 'rxjs/operators';
import { FilterCommonMethod } from 'src/app/util/FilterCommonMethod';


@Component({
  selector: 'table-tab-demo',
  templateUrl: './table-tab.component.html',
  styleUrls: ['./table-tab.component.scss']
})
export class DemoTableTabComponent implements OnInit {
  // copy from reservation/integration

  @observable permission: string[]
  // @observable defaultPermission: any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false
  // @observable loadingSwitch2 = false
  @observable selectedProducts: any[] = []

  @observable queryPannel: any = {}
  @observable lisOfData: any[] = []

  @action changeitem = (idx, data) => {
    this.lisOfData[idx] = data
  }
  backupData: any[] = [];
  // @observable isVisible:boolean = false

  columns = [
    // {header: 'action', title: 'Action', type: 'action'},
    {header: 'itemCode', title: 'Item', width: '90px'},
    {header: 'itemDesc', title: 'Description', width: '250px'},
    {header: 'id', title: 'Reservation ID', width: '150px'},
    {header: 'reservationType', title: 'Reservation Type', width: '150px'},
    {header: 'reservationDate', title: 'Reservation Date', width: '150px', type: 'date'},
    {header: 'reason', title: 'Reason', width: '100px'},
    {header: 'qtyRequested', title: 'Request Reserve Qty', width: '170px'},
    {header: 'qtyPending', title: 'Pending Qty', width: '120px'},
    {header: 'qtyCancelled', title: 'Cancel Qty', width: '120px', type: 'number', min: 0},
    {header: 'qtyAllocated', title: 'Allocate Qty', width: '120px', type: 'number', min: 0},
    {header: 'qtyReleased', title: 'Release Qty', width: '120px'},
    {header: 'sensitiveRemarks', title: 'Remark', width: '150px', type: 'txt'},
    {header: 'releaseChannel', title: 'Release Channel', width: '150px'},
    {header: 'expiryDate', title: 'Expiry Date', width: '130px', type: 'date'},
    {header: 'extensionCount', title: 'No. of Extension', width: '150px'},
    {header: 'sourceSystem', title: 'Source System', width: '140px'},
    {header: 'sourceTxnType', title: 'Source System Txn Type', width: '190px'},
    {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '150px'},
    {header: 'schCode', title: 'School Code', width: '150px'},
    {header: 'schLocCode', title: 'School Location Code', width: '190px'},
    {header: 'reserveDescription', title: 'Reserve For', width: '190px'},
  ]

  frozenColumns: any = [
    {header: 'expandControl', title: '', type: 'expandControl', width: '56px', hideInExpandRow: true, sortable: false},
    {header: 'action', title: 'Action', type: 'action', sortable: false},
  ]
  selectedTab
  tabForSelectColumns = [
    {
      label: 'Show All',
      name: 'showall',
      columnList: [
        {header: 'itemCode', title: 'Item', width: '90px'},
        {header: 'itemDesc', title: 'Description', width: '250px'},
        {header: 'id', title: 'Reservation ID', width: '150px'},
        {header: 'reservationType', title: 'Reservation Type', width: '150px'},
        {header: 'reservationDate', title: 'Reservation Date', width: '150px', type: 'date'},
        {header: 'reason', title: 'Reason', width: '100px'},
        {header: 'qtyRequested', title: 'Request Reserve Qty', width: '170px'},
        {header: 'qtyPending', title: 'Pending Qty', width: '120px'},
        {header: 'qtyCancelled', title: 'Cancel Qty', width: '120px', type: 'number', min: 0},
        {header: 'qtyAllocated', title: 'Allocate Qty', width: '120px', type: 'number', min: 0},
        {header: 'qtyReleased', title: 'Release Qty', width: '120px'},
        {header: 'sensitiveRemarks', title: 'Remark', width: '150px', type: 'txt'},
        {header: 'releaseChannel', title: 'Release Channel', width: '150px'},
        {header: 'expiryDate', title: 'Expiry Date', width: '130px', type: 'date'},
        {header: 'extensionCount', title: 'No. of Extension', width: '150px'},
        {header: 'sourceSystem', title: 'Source System', width: '140px'},
        {header: 'sourceTxnType', title: 'Source System Txn Type', width: '190px'},
        {header: 'sourceTxnRefHeaderNo', title: 'Source Ref. No', width: '150px'},
        {header: 'schCode', title: 'School Code', width: '150px'},
        {header: 'schLocCode', title: 'School Location Code', width: '190px'},
        {header: 'reserveDescription', title: 'Reserve For', width: '190px'},
      ]
    },
    {
      label: 'tab 1',
      name: 'tab1',
      columnList: [
        {header: 'itemCode', title: 'Item', width: '90px'},
        {header: 'itemDesc', title: 'Description', width: '250px'},
        {header: 'id', title: 'Reservation ID', width: '150px'},
        {header: 'reservationType', title: 'Reservation Type', width: '150px'},
      ]
    }
  ]

  // editDialog = false
  dialogTitle = ""
  channels: any[] = []
  channels2: any[] = []
  skus: any[] = []
  seSkus: any[] = []
  catalogs
  orderCreationDate
  reservationID
  assignmentLogic
  reservationType
  reservedBy
  orderId
  value: null
  balance
  seSku = [""]
  reservationStatus
  sechannelValue = ""
  _selectedColumns: any[];
  sourceSystem
  // orderStatus
  // assignmentlogics
  resstockreservationtypes
  selectedValue
  remark
  field = 'reservationDate'
  order = -1;
  isSortSearch
  initSortField = 'reservationDate'
  initSortOrder = -1
  channelOptions = [];

  totalRecords
  pageIndex = 0
  pageSize = 20
  searchParams = {}
  editShow: boolean = true;
  clonedLines = {}

  channelsDisableEdit = ['ELG']

  @ViewChild('ptable') private ptable: Table

  // tslint:disable-next-line:max-line-length
  constructor(
    public n: MessageService,
    public r: Router,
    private detail: RequestDetailStore,
    public p: ActivatedRoute,
    public m: ConfirmationService,
    private i18n: TranslateService,
    public stockReservationService: StockReservationService,
  ) {
    this._selectedColumns = this.columns;
    this.getPermission(p)

  }

  initQueryData() {
    this.queryPannel = {
      hasQuantity: "All",
      ipts: [
        {
          title: "Channel",
          name: 'channel',
          placeholder: 'Choose Channel',
          value: null,
          showValue: null,
          type:INPUT_TYPE.MUTIPLETREESELECT,
          options: this.channelOptions,
          class: "p-col-12 p-md-4 p-lg-3",
          beforeSubmit: function(value) { return value?value.map(val=>val.id):null },
          change: function(value, ipt) {
            ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        },
        {
          title: "Item",
          name: 'skuId',
          placeholder: 'Choose Item',
          value: null,
          type: INPUT_TYPE.MUTIPLESELECT,
          options: this.seSkus,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: "Source System",
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "Source Reference No",
          name: 'orderId',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },

        {
          title: "Reservation Type",
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          options: this.resstockreservationtypes,
          // iptionLabel: 'name',
          // iptionValue: 'code',
        },
        {
          title: "Reservation ID",
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "Reservation Creation Date",
          name: 'reservationCreateDate',
          type: INPUT_TYPE.DATETIME,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "Reserved For",
          name: 'reserveDescription',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "School Code",
          name: 'schoolCode',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
        },
        {
          title: "School Location Code",
          name: 'schoolLocCode',
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3 p-lg-offset-right-6",
          value: null,
        },
        // {
        //   title: "Reservation Status",
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: this.orderStatus,
        //   iptionLabel: 'name',
        //   iptionValue: 'code',
        // },
        // {
        //   title: "Assignment Logic",
        //   type: INPUT_TYPE.SELECT,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   value: null,
        //   options: this.assignmentlogics,
        //   // iptionLabel: 'name',
        //   // iptionValue: 'code',
        // },
        {
          title: "All",
          id: "one",
          name: "hasQua",
          type: INPUT_TYPE.RADIO,
          class: "p-col-12 p-md-4 p-lg-2",
          value: "All",
          // disabled: true
        },
        {
          title: "Pending",
          id: "Two",
          name: "hasQua",
          type: INPUT_TYPE.RADIO,
          class: "p-col-12 p-md-4 p-lg-1",
          value: "Pending",
          // disabled: true
        },
        {
          title: "Allocated",
          id: "Three",
          name: "hasQua",
          type: INPUT_TYPE.RADIO,
          class: "p-col-12 p-md-4 p-lg-1",
          value: "Allocated",
          // disabled: true
        },
        {
          title: "Cancelled",
          id: "Four",
          name: "hasQua",
          type: INPUT_TYPE.RADIO,
          class: "p-col-12 p-md-4 p-lg-1",
          value: "Cancelled",
          // disabled: true
        },

        {
          title: "Released",
          id: "Five",
          name: "hasQua",
          type: INPUT_TYPE.RADIO,
          class: "p-col-12 p-md-4 p-lg-1",
          value: "Released",
          // disabled: true
        }
      ],
      btnsclass: 'p-d-flex p-col-12 p-md-4 p-md-offset-8 p-lg-6 p-lg-offset-0',
      btns: [{
        type: BUTTON_TYPE.BUTTON,
        title: 'Search',
        icon: '',
        show: true,
        class: "p-order-2",
        handler: {
          "click": () => {
            this.search()
          }
        }
      },
        {
          type: BUTTON_TYPE.BUTTON,
          title: 'Create',
          icon: '',
          show: true,
          class: "p-order-1 p-mr-1",
          handler: {
            "click": () => {
              this.create()
            }
          }
        },
        {
          type: BUTTON_TYPE.BUTTON,
          title: 'Clear',
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          show: true,
          handler: {
            'click': () => {
              this.refresh()
            }
          }
      }],
      change: (idx, value, ipt)=>{
        if(ipt.name == 'channel'){
          if(ipt.change){
            ipt.change(value, ipt)
          }
        }
      }
    };
  }

  @action getPermission(p) {
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // console.log('>-- get permission --<', p.snapshot.data.name, this.permission);
  }

  @computed get showU() {
    return this.permission.includes('Create')
  }

  onChange() {
    const len = this.seSku.length;
    this.seSku = len > 0 ? (this.seSku[len - 1] === '' ? [""] : this.seSku.filter((item) => item)) : [""]
  }

  warehouseIdList = {};

  ngOnInit(): void {
    this.selectedValue = "All"
    this.channels2 = JSON.parse(localStorage.getItem("REPOTREE"))
    this.channels2 = [{data: 0, label: "ALL", expanded: true, children: this.channels2}]
    // this.channels2[0].title = "ALL"
    this.channelOptions = JSON.parse(localStorage.getItem("REPOTREE"))
    this.seSkus = JSON.parse(localStorage.getItem("SKU")).map(item=>{
      return {
        code: item.code,
        name: `${item.name} - ${item.flag}`,
        _data: item
      }
    })
    this.warehouseIdList = {}
    JSON.parse(localStorage.getItem("WAREHOUSE")).forEach(wh => {
      this.warehouseIdList[wh.id]=true
    });
    /* this.orderStatus = LocalStorageHelper.getObject("RESERVATION_STATUS").map(
      (item) => {
        let {value: code, label: name, ...other} = item
        return {code, name, ...other}
      }
    ) */

    this.i18n.get('reservation').subscribe(res => {
      /* this.assignmentlogics = LocalStorageHelper.getObject("ASSIGNMENTLOGIC").map(item => {
        item.label = res[item.label]
        let {value: code, label: name, ...other} = item
        return {code, name, ...other}
      }) */
      this.resstockreservationtypes = LocalStorageHelper.getObject("RESSTOCKRESERVATIONTYPE").map(item => {
        item.label = res[item.label]
        let {stockReservationTypeCode: code, description: name, ...other} = item
        return {code, name, ...other}
      })
      this.order = 1
      this.initQueryData()
      this.sortSearch(this.initSortField)
    })

    this.selectedColumns = this.columns
  }

  search(isSort: boolean = true) {
    this.editShow = true
    // this.sechannelValue = this.queryPannel.ipts.find(item => item.title === "Channel").value?.data;
    this.seSku = this.queryPannel.ipts.find(item => item.title === "Item").value;
    this.seSku = this.seSku ? this.seSku.map(item => item['code']) : null;
    this.sourceSystem = this.queryPannel.ipts.find(item => item.title === "Source System").value;


    this.orderCreationDate = this.queryPannel.ipts.find(item => item.name === "reservationCreateDate").value;


    this.orderCreationDate = this.transferDate(this.orderCreationDate)

    this.reservationID = this.queryPannel.ipts.find(item => item.title === "Reservation ID").value;
    this.orderId = this.queryPannel.ipts.find(item => item.title === "Source Reference No").value;

    // this.reservationStatus = this.queryPannel.ipts.find(item => item.title === "Reservation Status").value;
    // this.reservationStatus = this.reservationStatus ? this.reservationStatus.name : null;
    // this.assignmentLogic = this.queryPannel.ipts.find(item => item.title === "Assignment Logic").value;
    // this.assignmentLogic = this.assignmentLogic ? this.assignmentLogic.name : null;
    this.reservationType = this.queryPannel.ipts.find(item => item.title === "Reservation Type").value;
    // this.reservationType = this.reservationType ? this.reservationType.code : null;

    this.reservedBy = this.queryPannel.ipts.find(item => item.title === "Reserved For").value;
    let _channelIpt = this.queryPannel.ipts.find(item => item.name === "channel");
    let _channel = _channelIpt.beforeSubmit?_channelIpt.beforeSubmit(_channelIpt.value): _channelIpt.value;
    let _schoolCode = this.queryPannel.ipts.find(item => item.name === "schoolCode").value;
    let _schoolLocCode = this.queryPannel.ipts.find(item => item.name === "schoolLocCode").value;
    let _reserveDescription = this.queryPannel.ipts.find(item=>item.name==='reserveDescription').value;

    let data = {
      itemId: this.seSku,
      // repoId:this.sechannelValue,
      sourceSystem: this.sourceSystem,
      reservationId: !isNaN(Number.parseInt(this.reservationID))?Number.parseInt(this.reservationID):this.reservationID,
      orderDate: this.orderCreationDate,
      sourceReferenceNo: this.orderId,
      // reservationStatus: this.reservationStatus,
      // assignmentLogicId: this.assignmentLogic,
      reserveDescription: _reserveDescription,
      haveQuantity: this.queryPannel.hasQuantity,
      reservationType: this.reservationType,
      // reservedFor: this.reservedBy,
      channel: _channel?_channel:null,
      schCode: _schoolCode,
      schLocCode: _schoolLocCode,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: isSort ? {field: this.field, order: this.order} : null,
    }

    this.searchParams = {...data}
    const url = URLDICT.STOCK_RESERVATION_SEARCH;
    this.getDataFromServer(url, data)
    this.isSortSearch = isSort;
    if (!isSort) {
      // tslint:disable-next-line: no-unused-expression
      this.ptable && this.ptable.reset()
      this.order = undefined
    }
  }

  transferDate(date) {
    let dateNew
    if (date){
      var year = date.getFullYear()
      console.log(typeof (year))

      var m = (date.getMonth() + 1).toString();
      var d = (date.getDate()).toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      if (d.length == 1) {
        d = '0' + d;
      }
      dateNew = year + '-' + m + '-' + d

    }else dateNew = null
    return dateNew
  }

  getDataFromServer(url, data) {
    this.loadingSwitch = true
    this.isSortSearch = false
    HttpHelper.post(url, data).then(res => {
      if (res.code == '000') {
        this.backupData = JSON.parse(JSON.stringify(res.data));
        console.log('getDataFromServer: ', this.backupData)
        res.data.forEach(item => {
          if (item.createdDate){
            item.createdDate = new Date(item.createdDate)

          }
          if (item.fulfilledDate) {
            item.fulfilledDate = new Date(item.fulfilledDate)
          }
          if (item.endDateActive) {
            item.endDateActive = new Date(item.endDateActive)
          }
        })
        this.lisOfData = res.data
        console.log(res.data);
        if (res.msg !== '') {
          this.totalRecords = res.msg
        }
        // this.buildData()
      } else {
        // this.n.error("System","Submit Fail!")
        this.showMessage('error', 'System', 'Submit Fail!')
      }
    }).catch(e => {
      // this.n.error("System",e.toString())
      this.showMessage('error', 'System', e.toString())
      console.log(e.toString());
    }).finally(() => this.loadingSwitch = false)
  }

  refresh() {
    this.seSku = ['']
    this.sechannelValue = null
    // this.sepaymentStatus=null
    // this.secustomerType=null
    this.ptable.reset()
    this.ptable.sortOrder = this.initSortOrder
    this.ptable.sortField = this.initSortField
    this.order = 1
    this.field = this.initSortField
    this.queryPannel.ipts.forEach((item) => {
      item.value = null
      item.showValue = null
    });
    this.sortSearch(this.initSortField)
  }

  save(d, idx) {
    // const {newid, edit, ...swapDate} = d;
    // const data = {...swapDate, id: d.id};
    // /* data.createdDate = data.createdDate.getTime()
    //     data.fulfilledDate = data.fulfilledDate.getTime()
    //     data.endDateActive = data.endDateActive.getTime() */

    // this.editShow = true;
    // this.loadingSwitch = true
    // this.updateRowData(d.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
    // // HttpHelper.post(URLDICT.STOCK_RESERVATION_UPDATE, data).then(res => {
    // //   if (res.code === '000') {
    // //     // d.edit = false
    // //     // d.id = data.id
    // //     // delete d.newid
    // //     // this.changeitem(idx, data)
    // //     this.updateRowData(d.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
    // //     // this.search()
    // //     // this.clear()
    // //   } else {
    // //     // this.n.error("System","Submit Fail!")
    // //     this.showMessage('error', 'System', res.msg)
    // //     this.resetQty(d, idx)
    // //     this.loadingSwitch = false
    // //   }
    // // }).catch(e => {
    // //   // this.n.error("System",e.toString())
    // //   this.showMessage('error', 'System', e.toString())
    // //   this.resetQty(d, idx)
    // //   this.loadingSwitch = false
    // // }).finally(
    // //   () => {}
    // // )
  }

  editDetail(data) {
    // // this.detail.currentState = 'edit'
    // this.clonedLines[data.id] = {...data};
    // data.edit = true
    // // this.detail.selectRequest = data
    // // this.dialogTitle = 'Stock Reservation - Edit'
    // // this.editDialog = true
    // console.log('view request data', this.clonedLines);
  }

  edit(data) {

    if (data.createdDate == null) {
      data.createdDate = new Date(data.createdDate)
    }
    if (data.fulfilledDate == null) {
      data.fulfilledDate = new Date(data.fulfilledDate)
    }
    if (data.fulfilledDate == null) {
      data.endDateActive = new Date(data.endDateActive)
    }
    this.channels = JSON.parse(localStorage.getItem("REPOTREE"))[0].children
    if (data.id) {
      data.newid = data.id
      delete data.id
    }
    this.editShow = false;
    console.log('The data to be edited is: ', data);
  }

  cancel(data, idx) {
    // data.edit = false
    // data.qtyPending = this.clonedLines[data.id].qtyPending
    // data.qtyCancelled = this.clonedLines[data.id].qtyCancelled
    // data.qtyAllocated = this.clonedLines[data.id].qtyAllocated
    // data.sensitiveRemarks = this.clonedLines[data.id].sensitiveRemarks
    // delete this.clonedLines[data.id];
    // // this.clear()
    // // if (d.newid) {
    // //   const oldData = this.backupData.filter(item => item.id === d.newid)
    // //   d.id = d.newid
    // //   delete d.newid
    // //   d.remark = oldData[0].remark
    // //   this.changeitem(idx, oldData[0])
    // //   this.editShow = true;
    // // }
  }

  resetQty(data, idx){
    if(this.clonedLines[data.id]){
      data.qtyPending = this.clonedLines[data.id].qtyPending
      data.qtyCancelled = this.clonedLines[data.id].qtyCancelled
      data.qtyAllocated = this.clonedLines[data.id].qtyAllocated
    }
  }

  sortSearch(key) {
    if (this.field && this.field !== key) {
      this.order = undefined
    }
    this.field = key;
    this.isSortSearch = true
    // if(key === 'createdDate' || key === 'fulfilledDate'){
    this.order = !this.order ? 1 : -this.order
    // this.sortPageSearch()
    this.search(true)
  }

  page(e) {
    console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    let data: any = {...this.searchParams}
    data.pageIndex = this.pageIndex
    data.pageSize = this.pageSize
    // if(this.isSortSearch) this.sortPageSearch()
    // else this.getDataFromServer(data)
    this.search(this.isSortSearch);
  }

  delete() {
    const len = this.selectedProducts.length
    if (len < 1) {
      // this.n.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      // this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      return
    } else {
      const obj = {
        ids: this.selectedProducts.map(item => item.id + '')
      }
      this.m.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.loadingSwitch = true
          HttpHelper.post(URLDICT.STOCK_RESERVATION_DELETE, obj).then(res => {
            if (res.code == '000') {
              this.search()
              this.selectedProducts = null;
              //清空选中并重赋数据源
              // this.allCheckBoxValue = false
              // this.mapOfCheckedId = {}
            } else {
              // this.n.error("System", "Submit Fail!")
              this.showMessage('error', 'System', 'Submit Fail!');
            }
            this.loadingSwitch = false
          }).catch(e => {
            // this.n.error("System", e.toString())
            this.showMessage('error', 'System', e.toString());
            this.loadingSwitch = false
          })
        }
      });
    }
  }

  toChannelLabel(v) {
    return CommonMethod.toSelectedLabel("REPO", v)
  }

  toSkuLabel(v) {
    return CommonMethod.toSelectedLabel("SKU", v)
  }
  hideDialog(ref){
    this.search()
    this.initQueryData()
  }

  // @Input() get selectedColumns(): any[] {
  //   return this._selectedColumns;
  // }

  // set selectedColumns(val: any[]) {
  //   //restore original order
  //   console.log(this.selectedColumns)
  //   this._selectedColumns = this.columns.filter(col => val.includes(col));
  // }

  selectedColumns = []

  openUrl(id) {
    const url = 'https://here2serve--fta.lightning.force.com/lightning/n/Cart?orderID=' + id
    window.open(url, '_blank')
  }

  showMessage(severity, summary, detail) {
    this.n.add({severity, summary, detail});
  }

  create() {
    this.r.navigate(["main", 'stock_reservation', 'create']);
  }
  onTableValueInput(event, data, col){
    let value = event.value!=null?event.value:0;
    this.onTableValueChange(value, data, col)
  }
  onTableValueChange(event, data, col){
    let value = event!=null&&event>=0?event:0;
    switch(col.header){
      case 'qtyCancelled':
        data.qtyPending = data.qtyRequested - (data.qtyAllocated + value)
        break;
      case 'qtyAllocated':
        data.qtyPending = data.qtyRequested - (value + data.qtyCancelled)
        break;
    }
  }

  reserveLis(e){
    // this.loadingSwitch = true;
    // this.stockReservationService.lisAllocationUpdate(e.id).subscribe(
    //   res=>{
    //     if(res.code=='000'){
    //       this.updateRowData(e.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
    //     }else{
    //       let msg = ''
    //       if(res.msg){
    //         let _msg:string = res.msg;
    //         _msg = _msg.replace(/(^LIS error: )|(;[^;]*$)/g, '');
    //         msg += _msg;
    //       }
    //       this.showMessage('error', 'System', `Reserve LIS Fail! ${msg}`);
    //       this.loadingSwitch = false;
    //     }
    //   },
    //   (err)=>{
    //     this.showMessage('error', 'System', `Reserve LIS Fail! ${err}`);
    //     this.loadingSwitch = false;
    //   },
    //   ()=>{
    //   }
    // )
  }
  extendReleaseDate(e){
    // this.loadingSwitch = true;
    // this.stockReservationService.extendAutoReleaseDate(e.id).subscribe(
    //   res=>{
    //     if(res.code=='000'){
    //       this.updateRowData(e.id).subscribe(()=>{},(err)=>{},()=>{this.loadingSwitch = false;});
    //     }else{
    //       this.showMessage('error', 'System', `Extend Fail! ${res.msg}`);
    //       this.loadingSwitch = false;
    //     }
    //   },
    //   (err)=>{
    //     this.showMessage('error', 'System', `Extend Fail! ${err}`);
    //     this.loadingSwitch = false;
    //   },
    //   ()=>{
    //   }
    // )
  }

  updateRowData(id){
    return this.stockReservationService.searchReservationById(id).pipe(tap(
      res=>{
        if(res.code=='000'){
          let _rowIdx = this.lisOfData.findIndex(row=>row.id == id);
          if(_rowIdx>-1) {
            this.lisOfData[_rowIdx] = res.data[0];
            this.lisOfData = [...this.lisOfData];
          }
        }
      }
    ))
  }

  setSelectedColumns(columns){
    this.selectedColumns = columns
  }

  tableTabOnClick(e){
    // this.tableData['selectedTab'] = item.name
    // this.setSelectedColumns(item.columnList)
  }

  resetTableTab(){
    this.selectedTab = null;
  }

  modalVisible = false;
  selectedRow = null
  setModalVisible(e){
    this.modalVisible = e
  }
  openModal(e){
    this.selectedRow = e
    this.setModalVisible(true)
  }
}
