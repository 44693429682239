import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseResolverService, RESOLVERDATA } from '../resolvers/base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class AccessFunctionResolverService implements Resolve<[any]> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve():Observable<[any]>{
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.ACCESS_FUNCTION,
      this.baseResolver.resolverDataList.UAM_ROLE,
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
