import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationInterfaceResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.SYSDEFLOOKUPDESCSOURCE,
      this.baseResolver.resolverDataList.COM_INTERFACE_STATUS,
      this.baseResolver.resolverDataList.IMS_INTERFACE_STATUS,
      this.baseResolver.resolverDataList.BOM_INTERFACE_STATUS,
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
