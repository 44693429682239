import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pre-allocate-left-item-panel',
  templateUrl: './pre-allocate-left-item-panel.component.html',
  styleUrls: ['./pre-allocate-left-item-panel.component.scss']
})
export class PreAllocateLeftItemPanelComponent implements OnInit, OnChanges {

  @Input() itemList: any[];
  @Input() changedItemListCode=[];
  @Input() savedItemListCode=[];
  @Input() selectedItem;
  @Input() filterItemCode=[];
  @Input() isAutoScroll = false
  @Output() onItemClick = new EventEmitter();

  @ViewChild('leftScrollEl') leftScrollEl: ElementRef<HTMLElement>;

  constructor(
    public elRef: ElementRef<HTMLElement>,
  ) { }

  ngOnInit(): void {
  }

  onClick(e){
    this.onItemClick.emit(e)
  }

  ngOnChanges(changes){
    if(changes['filterItemCode'] && this.isAutoScroll){
      this.autoScroll()
    }
  }

  autoScroll(){
    if(this.filterItemCode?.length > 0 && this.itemList?.length > 0 && this.leftScrollEl){
      let first = this.itemList.find(item=>this.filterItemCode.includes(item.name))
      if(first){
        let target = <HTMLElement>this.elRef.nativeElement.querySelector(`#pre-allocate-left-${first.name}`)
        if(target){
          this.leftScrollEl.nativeElement.scrollTo({top: target.offsetTop})
        }
      }
    }
  }
}
