import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { makeAutoObservable, reaction } from "mobx";
import { ConfirmationService, MessageService } from "primeng/api";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { INPUT_TYPE, URLDICT } from "../../base/BaseStore";
import { HttpHelper } from "src/app/util/HttpHelper";

@Injectable()
export class CreateStore {
  title = "Stock Backend Transaction";
  queryData = {
    ipts: [
      {
        title: "Transaction Type Id",
        name: "trxTypeId",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: null,
        options: [],
        optionlabel: 'name',
        optionValue: 'code',
      },
      {
        title: "Channel Code",
        name: "channelCode",
        type: INPUT_TYPE.SELECTGROUP,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: null,
        options: [],
      },
      {
        title: "Source System Id",
        name: "sourceSystemId",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3 p-xlg-3",
        value: null,
      },
      {
        title: "Source System",
        name: "sourceSystem",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Request Number",
        name: "requestNumber",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Channel Owner",
        name: "channelOwner",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Channel CCC",
        name: "channelCCC",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
      },
      {
        title: "Remarks",
        name: "remarks",
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-4 p-lg-9",
        value: null,
      },
    ],
    btns: [
      {
        title: "Save",
        class: "p-order-0 p-ml-auto",
        permissionType: "c",
        show: true,
        handler: { click: () => this.save() },
      },
    ],
    btnsclass: "p-d-flex p-col-3",
  };
  tableData = {
    btns: [],
    data: [],
    totalRecords: 0,
    showCheckboxSwitch: false,
    loadingSwitch: false,
    head: [
      { key: "lineId", title: "Line Id", width: "10%", edit: true },
      { key: "reservationId", title: "Reservation Id", width: "15%", edit: true, },
      { key: "itemCode", title: "Item Code", width: "15%", edit: true },
      { key: "quantity", title: "Quantity", width: "10%", edit: true },
      { key: "revenueAccountGroup", title: "Revenue Account Group", width: "15%", edit: true, },
      { key: "orderItemNature", title: "Order Item Nature", width: "15%", edit: true, },
      { key: "stockStatusCode", title: "Stock Status Code", width: "15%", edit: true, },
    ],
  };
  lineCols = [{ header: "serialNumber", title: "Serial" }];
  selectRequest = null;
  searchAll = true;
  requestDialog = false;
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };
  permission: string[];
  dialogTitle = "";
  info = {
    notItems: 'There is no item in the current channel!',
    reason: "Filter area option is required!",
    reasonItem: "Backend Transtaction Item is required!",
    deleteTitle: "Delete Confirmation",
    deleteTransaction: "Do you want to delete the transaction record?!",
    deleteSerial: "Do you want to delete the Serial record?!",
    emptyItem: "There is a null value for Item!",
    emptySerial: "There is a null value for serialnumber!",
    requiredSerial: "Serial is required!",
  };
  detailData: any = {}; // allocation data parameter from parent
  uploadRemind = false; // upload DOM is displayed
  tempUploadData; // temporarily reserved upload data
  editing = false;
  skuList = []
  repoList = []
  constructor(
    private route: ActivatedRoute,
    private msg: MessageService,
    private conf: ConfirmationService
  ) {
    makeAutoObservable(this);
    this.getPermission(this.route);
    this.initQueryData();
  }

  get showC() {
    return true // this.permission.includes('Create')
  }

  getPermission(p) {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[p.snapshot.data.code];
    // this.setPermission(this.queryData.btns)
  }
  /* setPermission(list) {
    list.forEach(item => {
      if(item.permissionType){
        let permission = this.defaultPermission[item.permissionType]
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permission & permission) === permission
      }
    })
  } */
  initQueryData() {
    // this.skuList = LocalStorageHelper.getObject("SKU");
    this.repoList = LocalStorageHelper.getObject("REPOMODULEBYUAMGROUP").filter(repo=>repo.items.length);
    this.queryData.ipts[1].options = this.repoList;

    const options = [
      { name: "9 - Sales", code: 9 },
      { name: "10 - Sales Return", code: 10 },
      { name: "11 - Trade-in", code: 11 },
    ];
    this.queryData.ipts[0].options = options;
    reaction (
      () => this.queryData.ipts[1].value,
      (data) => {
        this.queryItems(data)
      }
    );
  }

  queryItems(channel) {
    HttpHelper.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, {"dtlRepoId": channel}).then(res => {
      this.skuList = []
      if(res.code === '000' && res.data.length > 0){
        res.data.forEach(sku => {
          const name = sku.skuDesc ? sku.skuCode +' ~ ' + sku.skuDesc : sku.skuCode
          this.skuList.push({code: sku.dtlSkuId, other: null, name})
        })
      } else {
        this.skuList = []
        this.showMessage('warn', this.title, this.info.notItems)
      }
    }).catch(e => console.log('error', e)).finally(() => console.log('Query items completed!'))
  }
  customSort(e) {}
  create() {
    const data = {
      reservationId: "",
      lineId: "",
      itemCode: "",
      quantity: "",
      revenueAccountGroup: "",
      orderItemNature: "",
      stockStatusCode: "",
      serial:[]
    };
    this.tableData.data.push(data);
  }
  itemChange(e) {
    console.log(e);
  }
  createLine(index) {
    this.tableData.data[index].serial.push({ serialNumber: "" });
  }
  onRowDelete(index, fatherIndex) {
    this.conf.confirm({
      message: this.info.deleteSerial,
      header: this.info.deleteTitle,
      accept: () => this.tableData.data[fatherIndex].serial.splice(index, 1)
    });
  }

  delete(index) {
    this.conf.confirm({
      message: this.info.deleteTransaction,
      header: this.info.deleteTitle,
      accept: () => this.tableData.data.splice(index, 1)
    });
  }
  verification() {
    const isEmptyQuery = this.queryData.ipts.some(item => {
      // const value =  item.name === "trxTypeId" ? item.value : item.value
      return !item.value
    })
    if(isEmptyQuery) {
      this.showMessage("warn", this.title, this.info.reason);
      return false
    }

    if(this.tableData.data.length === 0) {
      this.showMessage("warn", this.title, this.info.reasonItem);
      return false
    }
    // check item
    /* const isEmpty = this.tableData.data.some(item => {
      for (const key in item) {
        if (!item[key]) {
          return true
        }
      }
    })
    if(isEmpty) {
      this.showMessage("warn", this.title, this.info.emptyItem);
      return false
    } */
    // check seial
    /* let isEmptySerial = this.tableData.data.some(item => item.serial.length === 0)
    if(isEmptySerial) {
      this.showMessage("warn", this.title, this.info.requiredSerial);
      return false
    }
    isEmptySerial = this.tableData.data.some(item => {
      return item.serial.some(sub => !sub.serialNumber)
    })
    if(isEmptySerial) {
      this.showMessage("warn", this.title, this.info.emptySerial);
      return false
    } */
    return true
  }
  save() {
    if(this.verification()){
      this.tableData.loadingSwitch = true
      let obj:any = {};
      this.queryData.ipts.forEach((item) => {
        obj[item.name] = item.value;
      });
      let itemList = []
      this.tableData.data.forEach(item =>{
        // console.log(this.skuList, item.itemCode);
        let code = this.skuList.find(el=> el.code === item.itemCode)
        itemList.push({
          reservationId: item.reservationId,
          lineId: item.lineId,
          itemCode: code?.name ? code?.name.split(' ~ ')[0] : '',
          quantity: item.quantity,
          revenueAccountGroup: item.revenueAccountGroup,
          orderItemNature: item.orderItemNature,
          stockStatusCode: item.stockStatusCode,
          serial: item.serial
        })
      })

      // channelCode 必须转为名称
      let data = {...obj, orderLineItems: itemList}
      let list = []
      this.repoList.forEach(item => {
        list = [...list, item, ...item.items]
      });

      data.orderLineItems.forEach(item => {
        item.itemCode = item?.itemCode ? item?.itemCode.split(' ~ ')[0] : ''
      });
      data.channelCode = list.find(item => item.id ===  obj.channelCode).label
      HttpHelper.post(URLDICT.STOCK_BACKEND_TRANSACTION_SAVE, data).then(res => {
        if (res.code === '000') {
          this.showMessage('success', 'System', 'Save successfully!')
        } else {
          let errorInfo = ''
          if(res.data){
            res.data.forEach(item => {
              errorInfo += item.error.message + ','
            });
          }else{
            errorInfo = res.error.message
          }

          console.log(errorInfo);

          this.showMessage('error', 'System', 'Save Fail! ' + errorInfo)
        }
      }).catch(e => console.log(e)).finally(() => {
        this.tableData.loadingSwitch = false
      })
    }
  }
  showMessage(severity, summary, detail) {
    this.msg.add({ severity, summary, detail, life: 6000 });
  }
}
