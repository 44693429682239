import { CONFIG } from '@/stores/base/BaseStore';
import { TakeEditStore } from '@/stores/stock/take/takeEditStore';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-serial-cst',
  templateUrl: './serial-cst.component.html',
  styleUrls: ['./serial-cst.component.scss']
})
export class SerialCstComponent implements OnInit {
  @Input() store:TakeEditStore
  @Input() serialList
  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  @Input('visible') _visible = false
  @Output() visibleChange = new EventEmitter()
  set visible(e){this._visible=e;this.visibleChange.emit(e)}
  get visible(){return this._visible}

  @Input() loadingSwitch = false;

  @Output() onResult = new EventEmitter();
  @Output() onMultiDelete = new EventEmitter();

  init = false
  deviceSelected: string;
  deviceCurrent: MediaDeviceInfo;
  availableDevices: MediaDeviceInfo[];
  hasPermission;
  hasDevices;
  isScannerEnable = false;
  tryharder = false
  scannerObj = null;
  closeScannerTimer
  formats = CONFIG.SCANNERFORMAT
  SCANNERTRYHARDER = CONFIG.SCANNERTRYHARDER
  serialFilter = ''
  _serialList = [];
  _bufferSerialList = [];
  selectedSerialRow;

  constructor(
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(change){
    if(change['serialList']){
      if(this.serialList){
        this._serialList = this.serialList;
      }else{
        this._serialList = [];
      }
      this._bufferSerialList = JSON.parse(JSON.stringify(this._serialList))
      this.reset()
    }
  }

  toggleScanner(e){
    this.isScannerEnable = e;
    if(e==false && this.scanner){
      this.scanner.enable = false;
    }
  }
  
  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null;
    if (selectedDevice&&this.deviceSelected === selectedDevice.deviceId) {
      return;
    }
    if(selectedDevice)this.deviceSelected = selectedDevice.deviceId;
    this.deviceCurrent = device || undefined;
  }

  onCodeResult(res: string) {
    this.onInput(res);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = [...devices];
    this.hasDevices = Boolean(devices && (devices.length));
    this.deviceCurrent = this.availableDevices[0];
    this.tryharder = false;
  }

  onDeviceSelectChange(selected) {
    const selectedStr = selected.value || "";
    let device = this.availableDevices.find(
      (x) => x.deviceId === selectedStr
    );
    device = (device.deviceId == ''? undefined:device)
    this.deviceCurrent = device || undefined;
  }

  setModalVisible(e) {
    // let that = this;
    if (this.scanner) {
      this.scannerObj = this.scanner;
      this.scanner.reset();
    }
    if(e) this.init = true;
    if(!e) this.deviceCurrent = undefined
    this.visible = e;
    // this.toggleScanner(e)
    if(!e){
      this.isScannerEnable = false
      this.autoCloseScannerIfModalClosed()
    }else{
      if(this.closeScannerTimer){
        clearTimeout(this.closeScannerTimer)
      }
    }
  }
  
  onInput(v) {
    this.onResult.emit(v);
  }
  autoCloseScannerIfModalClosed(){
    if(this.closeScannerTimer){
      clearTimeout(this.closeScannerTimer)
    }
    if(!this.isScannerEnable&&this.scannerObj&&this.scannerObj.isAutostarting){
      if(this.scannerObj&&this.scannerObj._enabled == true){
        this.closeScannerTimer = setTimeout(()=>{
          if(!this.isScannerEnable){
            if(this.scannerObj.isAutostarting){
              this.autoCloseScannerIfModalClosed()
              return
            }
            if(this.scannerObj&&this.scannerObj._enabled == true){
              this.scannerObj.reset();
              this.scannerObj.enable = false;
              this.isScannerEnable = false;
            }
          }
        }, 500)
      }
    }
  }
  scanFailure(e){
  }
  scanError(e){
  }
  scanComplete(e){
  }
  serialFilterChanged(e){
    let filter = e.toLowerCase()
    this._serialList = this._bufferSerialList.filter(serial=>{
      return serial.toLowerCase().indexOf(filter) >= 0
    })
  }
  reset(){
    this.serialFilter = ''
  }

  clearFilter(){
    this.serialFilter = '';
    this.serialFilterChanged(this.serialFilter);
  }

  serialModalRangeChange(){
    if(this.store.serialModalRangeChange){
      this.store.serialModalRangeChange()
    }
  }

  multiDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    // this.onMultiDelete.emit(this.selectedSerialRow)
    let res = this.serialList.filter(e=>!this.selectedSerialRow.includes(e))
    this.selectedSerialRow = [];
    this.onMultiDelete.emit(res)
  }

  confirmBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    let totalRemove = this._serialList.filter(e=>this.selectedSerialRow.includes(e))
    this.confirmationService.confirm({
      message: 'Are you sure to delete selected serial numbers?',
      header: `Delete Serial Number`,
      accept: () => {
        this.multiDelete()
      }
      // rejectLabel: 'Close'
    });
  }

  onHide(){
    this.store.cancelSerial()
  }
}
