import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class OrderResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { 
  }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.ORDER_TYPE,
      this.baseResolver.resolverDataList.All_STATUS,
      this.baseResolver.resolverDataList.REASON,
      // this.baseResolver.resolverDataList.ADJREASON,
      this.baseResolver.resolverDataList.REPO,
      this.baseResolver.resolverDataList.DEFAULT_CHANNEL,
      this.baseResolver.resolverDataList.GETSKU,
      this.baseResolver.resolverDataList.CONDITIONS,
    ]

    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
