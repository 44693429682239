import { Component } from '@angular/core';
import {EditStore} from "../../../../stores/config/item_transfer_quota/EditStore";
import { Router, ActivatedRoute } from '@angular/router';
import { InjectHelper } from 'src/app/util/InjectHelper';
import { MessageService, ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-edit',
  template: `
    <illustration name="Item Transfer Quota" path="Stock Take"></illustration>
    <p-panel>
      <div class="p-fluid p-d-flex p-jc-center">
        <div class="p-grid" style="width: 40%;">
          <ng-container *ngFor="let ipt of store.inputPannel.ipts; index as i">
            <app-input-cst [class]="ipt.class" *ngIf="ipt.type==0" [ipt]="ipt" ></app-input-cst>
            <app-select-cst [class]="ipt.class" *ngIf="ipt.type==1" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-select-cst>
            <app-treeSelect-cst [class]="ipt.class" *ngIf="ipt.type==3" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-treeSelect-cst>
            <app-daterange-cst [class]="ipt.class" *ngIf="ipt.type==6" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-daterange-cst>
            <app-datetime-cst [class]="ipt.class" *ngIf="ipt.type==2" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-datetime-cst>
            <app-select-multiple-cst [class]="ipt.class" *ngIf="ipt.type==10" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-select-multiple-cst>
            <app-inputnumber-cst [class]="ipt.class" *ngIf="ipt.type==11" [ipt]="ipt"></app-inputnumber-cst>
            <app-switch-cst [class]="ipt.class" *ngIf="ipt.type==18" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-switch-cst>
            <app-select-group [class]="ipt.class" *ngIf="ipt.type==20" [ipt]="ipt" (change)="store.change(i,$event,ipt)"></app-select-group>
          </ng-container>
          <div [class]="store.inputPannel.btnsclass">
            <ng-container *ngFor="let btn of store.inputPannel.btns">
              <button style="width:auto;margin-right:5px;"  pButton type="button" [class]="btn.class" [disabled]="btn.disabled" [label]="btn.title" (click)="btn.handler.click()"></button>
            </ng-container>
          </div>
        </div>
      </div>
    </p-panel>`,
    styles: []
})
export class EditComponent {

  public store
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService) {
    this.store = new EditStore(InjectHelper.start(this))
  }


}
