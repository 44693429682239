import { CanActivate, RouterStateSnapshot, Router, ActivatedRoute, ActivatedRouteSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageHelper } from './LocalStorageHelper';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MainComponent } from '@/pages/main/main.component';
import { MainStore } from '@/stores/main';
//模块与权限标识映射
@Injectable()
export class CheckChangePasswordGuard implements CanActivate, CanActivateChild {
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService) {
    // this.ts = new BaseStore(InjectHelper.start(this))
  }
  canActivate(next:ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean|UrlTree {
    let isKeycloak =  LocalStorageHelper.getObject('ISKEYCLOAK')
    console.log("ISKEYCLOAK:"+ isKeycloak)
    if(isKeycloak=="0"){
      return true
    } 
    this.n.add({severity:"warn", summary:"WARNING", detail:"You are not authorized to access this page."})
    return this.r.parseUrl("/app-page-not-found")
  }
  canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
    // console.log('---------- can activete child ----------')
    return this.canActivate(route, state);
  }
}
