import { HttpHelper } from "./../../util/HttpHelper";
import { Injectable } from "@angular/core";
import { URLDICT } from "./../../config";
import { from, Observable, of } from "rxjs";
import { map, switchMap, mergeMap } from "rxjs/operators";
import { CommonService } from "../common/common-service";
import { CommonMethod } from "@/util/CommonMethod";

@Injectable({
  providedIn: "root",
})
export class StockCommonService {
  constructor(
    public commonService: CommonService,
  ){}
  getItemsByChannelIdAndCondition(channelId, conditionCode) {
    return from(
      HttpHelper.post(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, {
        channelId: channelId,
        conditionCode: conditionCode,
      })
    );
    // console.log(this.fromNatures);
  }
  
  getOrder(data) {
    return from(
      HttpHelper.post(URLDICT.STOCK_TRANSFER_SORTORDERINFO, data)
    ).pipe(
      map((res) => {
        if (res["code"] == "000") {
          return res.data;
        } else {
          throw res.msg;
        }
      }),
    );
  }

  getOrderByOrderNumber(orderNumber) {
    return from(
      HttpHelper.post(URLDICT.STOCK_TRANSFER_SORTORDERINFO, {
        orderNumber: orderNumber,
        createAt: 0,
        sortEvent: { field: "creationDate", order: -1 },
      })
    ).pipe(
      map((res) => {
        if (res["code"] == "000") {
          return res.data;
        } else {
          return null;
        }
      }),
      switchMap((res) => {
        if (res&&res.length==1) {
          return from(
            HttpHelper.post(URLDICT.STOCK_TRANSFER_LOADTOSTOCKIN, {
              ...res[0],
              createAtNoTime: "",
            })
          );
        } else {
          return of(null);
        }
      }),
      map((res) => {
        if (res && res["code"] == "000" && res.data.length>0) {
          return res.data[0];
        } else {
          return null;
        }
      }),
    );
  }

  getOrderByHeadId(headId) {
    return from(
      HttpHelper.post(URLDICT.STOCK_TRANSFER_LOADTOSTOCKIN, {
        ...{
          headId: headId
        },
      })
    ).pipe(
      map((res) => {
        if (res && res["code"] == "000" && res.data.length>0) {
          return res.data[0];
        } else {
          throw res.msg
        }
      }),
    );
  }

  getFromNature(orderTypeId, fromChannelId){
    return from(HttpHelper.post(URLDICT.STOCK_TRANSFER_GETFROMNATURE, {orderTypeId: orderTypeId, fromChannelId: fromChannelId})).pipe(
      map(res=>{
        if (res.code == '000' && res.data.length > 0) {
          return res.data
          // const fromStatus = this.queryPannel.ipts.find((item) => item.name === "fromStatusNature");
          // fromStatus.options = this.fromStatus;
          // console.log(this.fromStatus, fromStatus.options);
        }else{
          return null
        }
      })
    )
  }

  transferOffer(data){
    return from(HttpHelper.post(URLDICT.STOCK_CHANNEL_TRANSFERCREATE, data)).pipe(map(res=>{
      if(res&&res.code == "000"){
        return res.data
      }else{
        throw res.msg
      }
    }))
  }

  getItemListByItemId(e){
    return from(HttpHelper.post(URLDICT.COMMON_ITEMMASTERBYID, e)).pipe(map(res=>{
      if(res&&res.code == "000"){
        return res.data
      }else{
        return null
      }
    }))
  }

  getSpecAllocationForReservationList(e){

    return from(HttpHelper.post(`${URLDICT.STOCK_RESERVATION_SEARCHSTOCKALLOCATION}/${e}`, {})).pipe(map(res=>{
      if(res&&res.code == "000"){
        return res.data
      }else{
        return null
      }
    }))

  }

  updateStockAllocation(data){
    return from(HttpHelper.post(`${URLDICT.STOCK_RESERVATION_STOCKALLOCATION_UPDATE}`, data)).pipe(map(res=>{
      if(res&&res.code == "000"){
        return res.data
      }else{
        throw [res.msg, res]
      }
    }))
  }

  getTransferOrderBySourceTxnData(sourceTxnType, sourceTxnRefHeaderId: number, sourceTxnRefHeaderNo){
    return from(
      HttpHelper.post(URLDICT.STOCK_TRANSFER_SORTORDERINFO, {
        sourceTxnType: sourceTxnType,
        sourceTxnRefHeaderId: sourceTxnRefHeaderId.toString(),
        sourceTxnRefHeaderNo: sourceTxnRefHeaderNo,
        // createAt: 0,
        sortEvent: { field: "creationDate", order: -1 },
      })
    ).pipe(
      map((res) => {
        if (res["code"] == "000") {
          return res.data;
        } else {
          return null;
        }
      })
    )
  }

  getItemListBySkuModule(){
    return this.commonService.getSkuModuleList().pipe(
      switchMap(res=>{
        return this.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      map(res=>{
        if (res) {
          return res.map(element=>{
            return {code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element}
          })
        }else{
          return null
        }
      })
    )
  }

  updateStockOrder(data){
    return from(HttpHelper.put(URLDICT.STOCK_ORDER_EDIT, data))
  }

  searchBalance(data){
    return from(HttpHelper.post(URLDICT.STOCK_CURD_SEARCH, data)).pipe(map(res=>{

      if(res.code == '000'){
        return res.data
      }else{
        return null
      }
    }))
  }
  
  searchSortBalance(data){
    return from(HttpHelper.post(URLDICT.STOCK_CURD_SORTSEARCH, data)).pipe(map(res=>{

      if(res.code == '000'){
        return res.data
      }else{
        return null
      }
    }))
  }

  getCasAppointmentTimeslot(data){
    return from(HttpHelper.post(URLDICT.CAS_GET_APPOINTMENT_QUOTA, data)).pipe(map(res=>{
      if(res.code == '000' && res.data && res.data[0]){
        return res.data[0]
      }else{
        throw res.msg
      }
    }))
  }

  createCasAppointment(data){
    return from(HttpHelper.post(URLDICT.CAS_CREATE_APPOINTMENT, data)).pipe(map(res=>{
      if(res.code == '000'){
        return res.data
      }else{
        throw res.msg
      }
    }))
  }

  updateOrderStatus(data){
    return from(HttpHelper.post(URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE, data)).pipe(map(res=>{
      if(res.code == '000'){
        return res.data
      }else{
        throw res.msg
      }
    }))
  }

  getStockConditions(){
    return from(HttpHelper.get(URLDICT.COMMON_STOCKCONDITION)).pipe(map(res=>{
      if(res.code == '000'){
        return res.data
      }else{
        throw res.msg
      }
    }))
  }

  getStockConditionsResponse(){
    return from(HttpHelper.get(URLDICT.COMMON_STOCKCONDITION)).pipe(map(res=>{
      if(res.code == '000'){
        return res
      }else{
        throw res.msg
      }
    }))
  }

  getAllOrderType(){
    return from(HttpHelper.post(URLDICT.STOCK_TRANSFER_ALLORDERTYPE, {})).pipe(map(res=>{
      if(res.code == '000'){
        return res.data
      }else{
        throw res.msg
      }
    }))
  }

  getTransferOrderByItem(params){
    return from(
      HttpHelper.post(URLDICT.STOCK_TRANSFER_SORTORDERINFOBYITEMSORT, params)
    ).pipe(
      // map((res) => {
      //   if (res["code"] == "000") {
      //     return res.data;
      //   } else {
      //     return null;
      //   }
      // })
    )
  }

  getDmDeliveryDoc(params){
    return from(
      HttpHelper.post(URLDICT.DM_GETDELIVERYDOCUMENT, params)
    ).pipe(
      // map((res) => {
      //   if (res["code"] == "000") {
      //     return res.data;
      //   } else {
      //     return null;
      //   }
      // })
    )
  }
  
  getDeliveryDocFromCSTK(headId, orderNumber){
    return HttpHelper.postForExcel(URLDICT.STOCK_TRANSFER_PRINT + "?headId=" + headId, {headId:headId}).then(res => {
      res.blob().then((pdf) => {
        const name = `Delivery_Note_${orderNumber}`
        CommonMethod.downloadFile(pdf, name, 'pdf')
      })
    })
  }

  getBuCode() {
    return of(null).pipe(mergeMap(()=>{
      return from(HttpHelper.get(URLDICT.STOCK_DEMAND_REPLENISHMENT_GET_BU_CODE))
    }))
  }
  getItemMasterColumn() {
    return of(null).pipe(mergeMap(()=>{
      return from(HttpHelper.get(URLDICT.ITEM_MASTER_GET_COLUMN))
    }))
  }
  
  uploadEdmsFile(data:{[key:string]:string|Blob}):Observable<any>{
    let _data = new FormData();
    for (const [key, value] of Object.entries(data)) {
      _data.append(key,value)
    }
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.formPost(URLDICT.EDMS_UPLOAD, _data))
    }))
  }

  getEdmsFileIds(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.EDMS_GET_IDS, data))
    }))
  }

  getEdmsFile(edmsFileMappingId):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.postWithErrorHandle(URLDICT.EDMS_DOWNLOAD, {"edmsFileMappingId": edmsFileMappingId}))
    }), mergeMap((res:Response)=>{
      return from(res.blob())
    }))
  }

  deleteEdmsFIle(ids):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.EDMS_DELETE, {"edmsFileMappingIdsList": ids}))
    }))
  }

  checkSerialExist(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_CHECK_SERIAL_EXIST, data))
    }))
  }

  // adjustment order only
  checkReservable(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_RESERVABLE, data))
    }))
  }
  getOrderDetailToExcel(orderIds:number[]){
    return of({}).pipe(
      mergeMap(res=>{
        return from(HttpHelper.post(URLDICT.STOCK_TRANSFER_GET_ORDER_DETAIL_BY_ID, {idList:orderIds}))
      }),
      map(res=>{
        if(res.code=='000'){
          return res.data
        }else{
          throw res.msg || res.message
        }
      })
    )
  }
  getOrderItemsToExcel(orderIds:number[]){
    return of({}).pipe(
      mergeMap(res=>{
        return from(HttpHelper.post(URLDICT.STOCK_TRANSFER_GET_ORDER_ITEMS_BY_ID, {idList:orderIds}))
      }),
      map(res=>{
        if(res.code=='000'){
          return res.data
        }else{
          throw res.msg || res.message
        }
      })
    )
  }
  getOrderSerialsToExcel(orderIds:number[]){
    return of({}).pipe(
      mergeMap(res=>{
        return from(HttpHelper.post(URLDICT.STOCK_TRANSFER_GET_ORDER_SERIALS_BY_ID, {idList:orderIds}))
      }),
      map(res=>{
        if(res.code=='000'){
          return res.data
        }else{
          throw res.msg || res.message
        }
      })
    )
  }
  getItemMaster(data){
    return of({}).pipe(
      mergeMap(res=>{
        return from(HttpHelper.post(URLDICT.STOCK_ITEM_MASTER_SEARCH, data))
      }),
      map(res=>{
        if(res.code=='000'){
          return res.data
        }else{
          throw res.msg || res.message
        }
      })
    )
  }
  getTransHistory(data){
    return of({}).pipe(
      mergeMap(res=>{
        return from(this.commonService.httpPost(URLDICT.STOCK_MOVEMENT_SORTSEARCH, data))
      }),
      // map(res=>{
      //   if(res.code=='000'){
      //     return res.data
      //   }else{
      //     throw res.msg || res.message
      //   }
      // })
    )
  }
  getAccessFunction(data){
    return of({}).pipe(
      mergeMap(res=>{
        return from(this.commonService.httpPost(URLDICT.COMMON_UAM_FUNCTION_SEARCH, data))
      }),
    )
  }
}
