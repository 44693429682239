import { action } from 'mobx';
import { BaseSearchStore } from '../../base/BaseSearchStore'
import {INPUT_TYPE, BUTTON_TYPE, URLDICT} from '../../base/BaseStore'
import { Inject, Injectable } from '@angular/core';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { FilterCommonMethod } from 'src/app/util/FilterCommonMethod';
import { ChangeDetectorRef } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { SearchFieldRecordor } from '@/service/common/search-record';
import { CommonMethod } from '@/util/CommonMethod';
import { TITLE } from './TakeShare';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';
import { CommonService } from 'src/app/service/common/common-service';
import {HttpHelper} from "@/util/HttpHelper";


const FILTER_TITLE = {
  STOCKTAKEREFNO: "Stock Take Ref. No.",
  STATUS: "Stock Take Status",
  CHANNEL: "Channel",
  TARGETDATE: "Target Stock Take Date",
  ITEMCODE:"Item Code",
  TAKETYPE:"Stock Take Type",
  BU:"BU"
}
@Injectable()
export class SearchStore extends BaseSearchStore {
  datepipe
  searchFieldRecordor: SearchFieldRecordor;
  cdRef: ChangeDetectorRef
  public commonService
  public channels
  public selectedChannel
  public selectedChannelDisplay

  constructor(
    @Inject({}) params:any,
  ) {

    var queryPannel = {
      ipts:[
        {
          title:FILTER_TITLE.TAKETYPE,
          name:'sdlStockTakeTypeId',
          // value:"",
          class: "p-col-12 p-md-4 p-lg-3",
          type:INPUT_TYPE.SELECT,
          options:[],
          optionLabel: "name",
          optionValue: "id"
        },
        {
          title:FILTER_TITLE.STATUS,
          name:'statusId',
          // value:"",
          class: "p-col-12 p-md-4 p-lg-3",
          type:INPUT_TYPE.SELECT,
          options:[]
        },
        {
          title:FILTER_TITLE.STOCKTAKEREFNO,
          name:'stockTakeNumber',
          value:"",
          class: "p-col-12 p-md-4 p-lg-3",
          type:INPUT_TYPE.INPUT
        },
        {
          title: FILTER_TITLE.TARGETDATE,
          name:'targetStockTakeDate',
          type: INPUT_TYPE.DATERANGE,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null
        },
        /*  {
          title:FILTER_TITLE.CHANNEL,
          name:'channelId',
          value:"",
          class: "p-col-12 p-md-4 p-lg-3",
          // expandKeys: ['0'],
          type:INPUT_TYPE.MUTIPLETREESELECT,
          options:"REPOMODULEBYUAMTREE",
          // addtionOption:{value: "", other: "", label: "ALL"},
          beforeSubmit: function(value) { return value?value.map(val=>val.id).filter(id=>id>0):null },
          change: function(value, ipt) {
            ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          },
        } */

         {
          title: FILTER_TITLE.CHANNEL,
          name: 'channelId',
          value: null,
          defaultValue: '',
          selValue: [],
          showValue: null,
          placeholder: 'Choose',
          class: "p-col-12 p-md-4 p-lg-3",
          expandKeys: ['0'],
          type: INPUT_TYPE.MUTIPLETREESELECT,
          options: 'REPOTREE_ACTIVE',
          colspan: 7,
          _selectedIds: null,
          beforeSubmit: function(value, ipt) {
            let res = null;
            let _selectedIds = null;
            if(value){
              _selectedIds = value.map(val=>val.data)
              res = _selectedIds.filter(id=>id>0)
            }
            ipt._selectedIds = _selectedIds
            return res
            // let selectedIds = value?value.map(val=>val.id)
            // return value?value.map(val=>val.id).filter(id=>id>0):null
          },
          change: function(value, ipt) {
            if(Array.isArray(value)) ipt.showValue = FilterCommonMethod.getRepoMultiSelectShowValue(value);
          }
        } ,
        {
          title: FILTER_TITLE.BU, // for display, can be chinese or english
          name: "bu",  // for database colume
          placeholder: 'BU',
          value: null,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.MUTIPLESELECT,
          appendTo: 'body',
          optionLabel: "name",
          optionValue: "id",
          colspan: 7,
          _selectedIds: null,
          beforeSubmit: function(value, ipt) {
            let res = null;
            let _selectedIds = null;
            if(value){
              _selectedIds = value.map(val=>val.id)
              res = _selectedIds.filter(id=>id>0)
            }
            ipt._selectedIds = _selectedIds
            return res
            // let selectedIds = value?value.map(val=>val.id)
            // return value?value.map(val=>val.id).filter(id=>id>0):null
          },
        },
        {
          title: FILTER_TITLE.ITEMCODE, // for display, can be chinese or english
          name: "skuNum",  // for database colume
          placeholder: 'Item',
          value: [],
          defaultValue: '',
          class: "p-col-12 p-md-4 p-lg-6",
          dataKey: 'code',
          skipAllCheckMatch: true,
          type: INPUT_TYPE.MUTIPLESELECT,
          options: "SKUMODULEBYUAM",
          appendTo: 'body',
          colspan: 7
        },
        // {
        //   title:FILTER_TITLE.TAKETYPE,
        //   name:'sdlStockTakeTypeId',
        //   // value:"",
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   type:INPUT_TYPE.SELECT,
        //   options:[],
        //   optionLabel: "name",
        //   optionValue: "id"
        // },
      ],
      btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-12",
      btns:[{
        type: BUTTON_TYPE.BUTTON,
        title:'Search',
        class: "p-order-2",
        show: true,
        handler: {
          click: () => {
            // this.search(this.$URLDICT.STOCK_TAKE_SORTSEARCH)
            // this.sortPageSearch(this.sortSearchUrl, this.field)
            this.searchFromFilter()
          }
        }
      }, {
        type: BUTTON_TYPE.BUTTON,
        title: 'Create',
        class: "p-order-1 p-mr-1",
        show: true,
        permissionType: 'c',
        handler: {
          click: () => { this.create(["main",'stock_take','create']) }
        }
      }, {
        type: BUTTON_TYPE.BUTTON,
        title:'Clear',
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        show: true,
        handler: {
          click: () => { this.clearSearchData() }
        }
      }]
    }

    var tablePannel = {
      // showCheckboxSwitch:false,
      loadingSwitch: false,
      btns:[],
      data: [],
      head:[
        //{key: 'id',title: 'ID'},
        {key: 'channelCode', title: 'Channel', width: '250px'},
        {key: 'targetStockTakeDate', title: 'Target Stock Take Date', width: '250px', type: 'date'},
        {key: 'stockTakeType', title: 'Stock Take Type', width: '250px'},
        {key: 'status', title: 'Stock Take Status', width: '250px'},
        {key: 'stockTakeNumber', title: 'Stock Take Ref. No.', width: '250px'},
        //{key: 'itemCode', title: 'item Code', width: '250px'},
        {key: 'code', title: 'BU', width: '120px'},
        {key: 'update_at', title: 'Last Update', width: '250px', type: 'datetime'},
      ],
      pageIndex: 0,
      pageSize: 20,
      totalRecords: 0,
      sortField: { field: "update_at", order: -1 },
      detailUrl: ["main","stock_take","edit"],
      sortSearchUrl: URLDICT.STOCK_TAKE_SORTSEARCH
    }
    super(queryPannel,tablePannel,params);

    this.field = 'update_at';
    this.order = -1;
    // this.sortSearch('update_at')
    let takeBuOptions = LocalStorageHelper.getObject('STOCK_TAKE_BU')
    takeBuOptions = takeBuOptions.map(item=>{return {name: item.code, id: item.id}})
    let takeBuIpt = this.queryPannel.ipts.find(ipt => ipt.title === FILTER_TITLE.BU)
    takeBuIpt.options = takeBuOptions

    let list = LocalStorageHelper.getObject('TAKE_STATUS')
    list = list.map(item => {
      const {value:code, label:name, alias:label, ...other} = item
      return {code, name,label, ...other}
    })
    list.unshift({name:'ALL', code: -1});
    let statusIpt = this.queryPannel.ipts.find(ipt=>ipt.title == FILTER_TITLE.STATUS)
    statusIpt.options = list

    this.searchFieldRecordor = new SearchFieldRecordor(this.$COMMONSTORE, this.$ROUTER, this.$PARAMS, this.$CONFIG.SEARCHPARAMSKEY.STOCKTAKE)
    //this.setLabels();
    this.getLableData()
    this.initRouterEvent()

  }
  @action openDet(d){
    this.tablePannel.loadingSwitch = true
    this.$ROUTER.navigate(["main", "stock_take", "detail"], {queryParams: {id: d.id}});
    // this.$HTTP.post(URLDICT.STOCK_TAKE_SEARCH_DETAIL, d).then(res => {
    //   this.tablePannel.loadingSwitch = false
    //   if (res.code === '000' && res.data.length > 0) {
    //     LocalStorageHelper.setObject("takeDetail", res.data[0])
    //     this.$ROUTER.navigate(["main", "stock_take", "detail"], {queryParams: {id: res.data[0].id}});
    //   }
    // })
  }

  setDatePipe(datepipe){
    this.datepipe = datepipe
  }
  setCdRef(e){
    this.cdRef = e
  }

  initDefaultChannel(){
    try{
    let channel = CommonMethod.getDefaultChannel(this.channels)
    if(channel){
       this.selectedChannel = [channel];
    }else{
      this.selectedChannel = null;
    }
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
    }catch(e){console.error(e)}
  }

  convertData(data){
    if(data.skuNum){
      data.skuNum = data.skuNum.map(item =>{return item.code});

      const skuNum = data.skuNum.map(item => {
        const skus = LocalStorageHelper.getObject('SKU')
        const sku = skus.find(sub => sub.code === item)
        sku.name = sku.name.split(" ~ ")[0]
        const {code:id, name:code} = sku
        return {id, code}
      });
      data.skuNum = skuNum
    }
    let _data = data
    if(_data.targetStockTakeDate) {
      data.targetStockTakeDate[1] = data.targetStockTakeDate[1] || data.targetStockTakeDate[0]
      _data.targetStockTakeDate = [
        new Date(data.targetStockTakeDate[0].setHours(0, 0, 0)),
        new Date(data.targetStockTakeDate[1].setHours(23, 59, 59))
      ];
      _data.targetStockTakeDate = _data.targetStockTakeDate.map((date)=>{
        return this.dateFormatToApi(date)
      })
    }
    return _data
  }

  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss" )
  }
  searchFromFilter() {
    this.resetSearch = true
    if(this.paginator ){
      this.paginator.changePage(0)
    }else{
      this.callSortPageSearch()
    }
  }
  page(e){
    this.pageIndex=e.first/e.rows
    this.pageSize=e.rows
    this.isSortSearch = true;
    if(this.isSortSearch) this.callSortPageSearch()
    // else this.getData()
    else this.search(this.$URLDICT.STOCK_TAKE_SEARCH).then()
  }
  getSearchParams() {
    const params = {
      accountName: '',
      channelId: '',
      statusId: '',
      stockCountReferenceNumber: '',
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: this.tablePannel.sortField
    }
    return params
  }
  clearSearchData() {
    this.queryPannel.clear()
  }

  initRouterEvent(){
    this.searchFieldRecordor.setRouterEventForSearchParams(this.$ROUTER)
    .subscribe(res=>{
      this.getSnapshotData(res)
    })
  }

  getQueryByTitle(title){
    return this.queryPannel.ipts.find(item => item.title === title)
  }

  getLableData(){
    HttpHelper.get(URLDICT.STOCK_TAKE_LABEL).then(res => {
      // console.log('query labels:', res);
      const label = this.getQueryByTitle(TITLE.TYPE)
      if (res.status === 200) {
        let labelList = []
        res.data.forEach(item => {
          // const name = item.labelName
          labelList.push({id:item.id, code: item.code, other: item.description, name: item.description})
        })
        //label.options = labelList

        let ipt = this.queryPannel.ipts.find(ipt=>ipt.title==TITLE.TYPE)
        ipt.options = labelList
      } else {
        label.options = []
      }
    }).catch(e => console.log('error', e)).finally(() => console.log('Query labels completed!'))
  }

  onDestroy(){
    if(this.searchFieldRecordor)this.searchFieldRecordor.onDestory()
  }

  getSnapshotData(params){
    // this.initChannel()
    let data = params
    this.queryPannel.ipts.forEach(ipt=>{
      if(ipt.title == FILTER_TITLE.ITEMCODE){
        if(data[ipt.name]){
          let ids = data[ipt.name].map(val=>val.id)
          ipt.value = ipt.options.filter(opt=>ids.indexOf(opt.code)>-1)
          // data[ipt.name]
        }
      }else if(ipt.title == FILTER_TITLE.CHANNEL){
        if(data[ipt.name]){
          let val = CommonMethod.multipleTreeSelectGetValueByIds(ipt, data[ipt.name])
          ipt.value = val
          ipt.change(val, ipt)
          // data[ipt.name]
        }
        if(Object.keys(params).length==0&&sessionStorage.getItem('NOTICE_MESSAGE')==null){
          this.channels = FilterCommonMethod.getRepoTreeActiveForSearchFilter();
          this.initDefaultChannel()//init default chanel
          if(this.channels&&this.selectedChannel)ipt.value=[this.selectedChannel[0]]//set default channel
          ipt.showValue=this.selectedChannelDisplay//set default channel
        }
      }else if(ipt.type == INPUT_TYPE.DATERANGE){
        if(data[ipt.name]) ipt.value = data[ipt.name].map(val=>new Date(val));
      }else if(ipt.type !== INPUT_TYPE.MUTIPLETREESELECT){
        if(data[ipt.name]) ipt.value = data[ipt.name];
      }else{
        if(data[ipt.name]){
          let val = CommonMethod.multipleTreeSelectGetValueByIds(ipt, data[ipt.name])
          ipt.value = val
          ipt.change(val, ipt)
        }
      }
    })
    if(data.pageSize){
      this.pageSize = Number.parseInt(data.pageSize)
    }
    if(data.sortEvent){
      let sortEvent = data.sortEvent
      this.field = sortEvent.field
      this.order = sortEvent.order
    }
  }

  afterViewInit(){
    let data = this.searchFieldRecordor.getRouteParams()
    this.pageIndex = Number.parseInt(data.pageIndex)||0
/*    this.callSortPageSearch().then((res)=>{
      if(this.cdRef) this.cdRef.detectChanges()
      if(this.paginator) this.paginator.changePage(Number.parseInt(data.pageIndex))
    })*/
  }

  @action sortSearch(key, order){
    this.isSortSearch=true
    this.field = key
    if(order!=null){
      this.order = order
    }else if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    return this.callSortPageSearch()
  }

  callSortPageSearch(){
    return this.sortPageSearch(this.sortSearchUrl, this.field).then((res)=>{
      this.searchFieldRecordor.storeSearchParams(
        this.lastSuccessSearchPayload,
        this.queryPannel.ipts,
        'name'
      )
    })
  }

  handleInputValue(e){}

  @action getPermission(...e){
    // this.permissions = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // this.setPermission(this.queryData.btns)
    this.setPermission()
  }

  setPermission(...e){
    let permissionService = this.$COMMONSTORE.permissionService
    this.queryPannel && this.queryPannel.btns.length>0 && this.queryPannel.btns.forEach(item=>{
      item.title==TITLE.SEARCH && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SEARCH))
      item.title==TITLE.CREATE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE||FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE||FUNCTION_CODE.STOCK_TAKE_SURP_CREATE))
    })
    this.tablePannel && this.tablePannel.btns.length>0 && this.tablePannel.btns.forEach(item=>{
      // item.title==TITLE.SEARCH && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SEARCH))
      // item.title==TITLE.CREATE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE))
    })
  }
}
