import { Component, Input, EventEmitter, Output } from '@angular/core';

/**
 * @description: 查询条件页面组件
 * @author: ken
 */
@Component({
  selector: 'app-reseQuery-pannel',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './query-pannel.component.html',
  styleUrls: ['./query-pannel.component.css']
})
export class ResearQueryPannelComponent {
  @Input() headerTilte = 'FILTER';
  @Input() store
  @Output() heightEvent = new EventEmitter();
}
