import { Paginator } from 'primeng/paginator';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ItemMasterStore } from 'src/app/stores/stock/item/itemMasterStore';

@Component({
  selector: 'app-item-master',
  providers: [ItemMasterStore],
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements AfterViewInit {

  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public store: ItemMasterStore) { }
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
  }

}
