import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.SOURCE,
      this.baseResolver.resolverDataList.All_STATUS,
      this.baseResolver.resolverDataList.GETSKUBYUAM,
      this.baseResolver.resolverDataList.CONDITIONS,
      this.baseResolver.resolverDataList.DEFAULT_CHANNEL,
      // this.baseResolver.resolverDataList.ALL_ORDER_TYPE,
      this.baseResolver.resolverDataList.HISTORY_TRANSACTION_TYPE
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
