import { Paginator } from 'primeng/paginator';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SearchStore } from 'src/app/stores/stock/demand/SearchStore';

@Component({
  providers: [SearchStore],
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements AfterViewInit {
  constructor(public store: SearchStore) {}
  @ViewChild("paginator", {static: false}) paginator: Paginator
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
  }
}
