import { Component, Input } from '@angular/core';

/**
 * @description: 查询条件页面组件
 * @author: ken
 */
@Component({
  selector: 'app-add-pannel',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-pannel.component.html',
  styleUrls: ['./add-pannel.component.css']
})
export class AddPannelComponent {
  @Input() headerTilte = 'Create';
  @Input() store
  @Input() disableBtn
}
