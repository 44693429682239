import { HttpHelper } from "./../../util/HttpHelper";
import { Injectable, Injector } from "@angular/core";
import { URLDICT } from "./../../config";
import { from, of } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { LoadingService } from "./loading-service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { BaseResolverService } from "../resolvers/base-resolver.service";
import { PermissionService } from "./permission-service";
import { CommonMethod } from "@/util/CommonMethod";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  loadingService: LoadingService;
  constructor(
    // public loadingService: LoadingService,
    public oauthService: OAuthService,
    public datePipe: DatePipe,
    public router: Router,
    // public baseResolverService: BaseResolverService,
    private injector: Injector,
    public permissionService: PermissionService
  ) {
    this.loadingService = new LoadingService();
  }

  getAllReservationType() {
    return from(HttpHelper.get(URLDICT.COMMON_RESSTOCKRESERVATIONTYPE)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          return null;
        }
      })
    );
  }
  getAllStockAllocationType() {
    return from(HttpHelper.get(URLDICT.COMMON_STOCKALLOCATIONTYPE)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          return null;
        }
      })
    );
  }

  getSkuModuleList() {
    return from(HttpHelper.get(`${URLDICT.COMMON_SKU_MODULE}`)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          throw res.msg;
        }
      })
    );
  }

  getAllSourceSystem() {
    return from(HttpHelper.get(`${URLDICT.COMMON_SOURCESYSTEM}`)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          throw res.msg;
        }
      })
    );
  }
  getAllApiType() {
    return from(HttpHelper.get(`${URLDICT.COMMON_API_TYPE}`)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          throw res.msg;
        }
      })
    );
  }
  getDefaultChannel(options?) {
    return CommonMethod.getDefaultChannel(options);
    // wip: copy from "Stock Balance Enquiry", should be user login channel / default channel
    // const channels = LocalStorageHelper.getObject("REPOMODULEBYUAMGROUP");
    // if (channels) {
    //   let pranetName = channels[0].label;
    //   let selItem = channels[0].items[0];
    //   if (!selItem) {
    //     channels.find((item1) => {
    //       if (item1.items.length) {
    //         pranetName = item1.label;
    //         selItem = item1.items[0];
    //       }
    //     });
    //   }
    //   const list = options;
    //   console.log({ options }, { pranetName });

    //   const parentItem = list.find((item1) => item1.label === pranetName);
    //   const item = parentItem.children.find(
    //     (item1) => item1.label === selItem.label
    //   );
    //   return item;
    // } else {
    //   return null;
    // }
  }
  getDefaultChannelFromBackend(){
    return of(null).pipe(switchMap(e=>{
      return from(HttpHelper.get(URLDICT.COMMON_GET_DEFAULT_CHANNEL))
    })).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          return null;
        }
      })
    );
  }
  runTask(data) {
    return from(HttpHelper.post(URLDICT.TASK_RUN, data));
  }
  skuHandle(res, key) {
    res.data = res.data.sort((a, b) => a.label.localeCompare(b.label));
    const skuList = res.data.map((item) => {
      const { label: name, value: code, ...other } = item;
      return key.includes("UAM") ? { name, code } : { name, code, ...other };
    });

    LocalStorageHelper.setObject(key, skuList);
  }

  toGeneralCache(list) {
    return list.map((item) => {
      const { id: data, name: label, id, ...other } = item;
      return { label, data, id, ...other };
    });
  }

  arrayToTree(list, pid = 0) {
    return list
      .filter((item) => item.pid === pid)
      .map((item) => ({
        key: item.label,
        ...item,
        children: this.arrayToTree(list, item.id),
      }));
  }
  arrayToGroup(list, pid = 0) {
    return list
      .filter((item) => item.pid === pid)
      .map((item) => ({
        ...item,
        items: this.arrayToGroup(list, item.id),
      }));
  }
  // filter isUser, then filter isActive, id < 0 and pass to uam handle function
  newRepoHandle(res) {
    const data = res.data.filter(
      (item) =>
        item.isUser === "Y" ||
        (
          !item.isUser 
          // && item.pid !== -5 && item.id !== -5
        )
    ).filter(e=>e.id<=0||e.isActive=='Y');
    this.repoHandle(res);
    this.repoByUamHandle({ data });
  }
  // res include all channel
  repoHandle(res) {
    let data = res.data.filter((item) => item.name);
    data = data.sort((a, b) => a.name.localeCompare(b.name));
    const repoList = this.toGeneralCache(data);
    const Warehouse = repoList.filter(
      (item) => item.other === "Warehouse" && item.isClosed !== "Y"
    );

    const repoListWithoutInactive = repoList.filter(e=>e.id<=0||e.isActive=='Y');

    LocalStorageHelper.setObject("REPO", repoList);
    LocalStorageHelper.setObject("REPO_ACTIVE", repoListWithoutInactive);
    LocalStorageHelper.setObject("WAREHOUSE", Warehouse);
    LocalStorageHelper.setObject("WAREHOUSE_ACTIVE", Warehouse);
    LocalStorageHelper.setObject("REPOTREE", this.arrayToTree(repoList));
    LocalStorageHelper.setObject("REPOTREE_ACTIVE", this.arrayToTree(repoListWithoutInactive));

    const repoGroupList = data.map((item) => {
      const { id: value, name: label, id, name, ...other } = item;
      return { value, label, id, name, ...other };
    });
    LocalStorageHelper.setObject("REPOGROUP", this.arrayToGroup(repoGroupList));
  }
  // res include isActive = Y || id < 0
  repoByUamHandle(res) {
    LocalStorageHelper.setObject(
      "REPOMODULEBYUAM",
      this.toGeneralCache(res.data)
    );
    const rmList = this.toGeneralCache(res.data);
    LocalStorageHelper.setObject(
      "REPOMODULEBYUAMTREE",
      this.arrayToTree(rmList)
    );
    LocalStorageHelper.setObject(
      "REPOIDBYUAM",
      this.toGeneralCache(res.data).map(e=>e.id)
    );
    const groupList = res.data.map((item) => {
      const { id: value, name: label, id, name, ...other } = item;
      return { value, label, id, name, ...other };
    });
    LocalStorageHelper.setObject(
      "REPOMODULEBYUAMGROUP",
      this.arrayToGroup(groupList)
    );
  }
  allHandle(res: any) {
    res.data = res.data.map((item) => {
      const { label: alias, other: label, ...other } = item;
      return { label, alias, ...other };
    });
    const transferList = res.data.filter(
      (item) => item.flag === "STOCK_TRANSFER_STATUS"
    );
    LocalStorageHelper.setObject("TRANSFER_STATUS", transferList);
    const interFaceList = res.data.filter(
      (item) => item.flag === "LIS_INTERFACE_STATUS"
    );
    LocalStorageHelper.setObject("INTERFACE_STATUS", interFaceList);
    const replenishmentList = res.data.filter(
      (item) => item.flag === "REPLENISH_STATUS"
    );
    LocalStorageHelper.setObject("REPLENISHMENT_STATUS", replenishmentList);
    const takeList = res.data.filter(
      (item) => item.flag === "STOCK_TAKE_STATUS"
    );
    LocalStorageHelper.setObject("TAKE_STATUS", takeList);
    const orderList = res.data.filter(
      (item) => item.flag === "STOCK_TRANSFER_STATUS"
    );
    LocalStorageHelper.setObject("ORDER_STATUS", orderList);
    const orderAdjustmentList = res.data.filter(
      (item) => item.flag === "STOCK_ADJUSTMENT_STATUS"
    );
    LocalStorageHelper.setObject(
      "ORDER_ADJUSTMENT_STATUS",
      orderAdjustmentList
    );
  }
  defaultHandle(res, key) {
    if (res.data != undefined) LocalStorageHelper.setObject(key, res.data);
  }
  roleHandle(res, key): void {
    const list = res.data.map((item) => {
      const { value: data, label: name, value, label, ...other } = item;
      return { name, data, value, label, ...other };
    });
    LocalStorageHelper.setObject(key, list);
  }
  functionHandle(res, key): void {
    const list = res.data.map((item) => {
      const { value: data, other: name, value, other, ...rest } = item;
      return { name, data, value, other, ...rest };
    });
    LocalStorageHelper.setObject(key, list);
  }
  orderTypeHandle(res, key) {
    const typeList = res.data.map((item) => {
      const { id: value, description: label, id, description, ...other } = item;
      return { value, label, id, description, ...other };
    });
    LocalStorageHelper.setObject(key, typeList);
  }
  reasonHandle(res, key) {
    const list = res.data[0];
    LocalStorageHelper.setObject(key, list);
  }
  adjreasonHandle(res, key) {
    const list = res.data;
    for (const apiKey in key) {
      let _list = list.filter((e) => e.reasonType == apiKey);
      LocalStorageHelper.setObject(key[apiKey], _list);
    }
  }
  poolHandle(res, key) {
    let list = [];
    res.data.forEach((item) => {
      list.push({ id: item.id, code: item.code, name: item.description });
    });
    LocalStorageHelper.setObject(key, list);
  }
  addressHandle(res, key) {
    const list = res.data.map((item) => ({
      code: item.code,
      label: item.description,
    }));
    LocalStorageHelper.setObject(key, list);
  }
  takeLabelHandle(res, key) {
    const list = res.data.map((item) => ({
      id: item.id,
      code: item.code,
      other: item.description,
      name: item.description,
    }));
    LocalStorageHelper.setObject(key, list);
  }

  getSysDefLookupByType(type:string){
    return of(null).pipe(switchMap(e=>{
      return from(this.httpPost((URLDICT.SYS_DEF_LOOKUP_DESC), {
        lookupType: type,
      }))
    })).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res.data;
        } else {
          return null;
        }
      })
    );
  }

  public convertDateWithFormat(date, format) {
    var timeZone = "+8";
    return this.datePipe.transform(date, format, timeZone);
  }

  public logout() {
    let baseResolverService = this.injector.get(BaseResolverService);
    this.clearSession().then(() => {
      LocalStorageHelper.removeAll();
      baseResolverService.reset();
      sessionStorage.clear();
      this.oauthService.logOut();
      this.permissionService.clear();
      this.router.navigate([""]);
    });
  }
  public changepassword() {
    this.router.navigate(["/main/system/changepassword"]);
  }

  clearSession() {
    return HttpHelper.post(URLDICT.SYSTEM_LOGOUT, {}).then((res) => {
      console.log(res);
    });
  }

  getNotifications(e) {
    return from(HttpHelper.post(URLDICT.NOTICE_SORTSEARCH, e)).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res;
        } else {
          throw res.message || res.msg;
        }
      })
    );
  }

  getKafkaVersions() {
    return this.httpGetObs(URLDICT.KAFKA_VERSION_GETALL).pipe(
      map((res) => {
        if (res && res.code == "000") {
          return res;
        } else {
          throw res.message || res.msg;
        }
      })
    );
  }

  getAccount() {
    let account = LocalStorageHelper.getObject("account");
    return typeof account == "string" ? account : null;
  }

  setAccount(e) {
    LocalStorageHelper.setObject("account", e);
  }

  getFormData(e: { [key: string]: string | Blob }) {
    let _data = new FormData();
    for (const [key, value] of Object.entries(e)) {
      _data.append(key, value);
    }
    return _data;
  }

  httpPost(...e) {
    return HttpHelper.post(e[0], e[1]).then();
  }

  httpGet(e) {
    return HttpHelper.get(e).then();
  }
  httpPut(...e) {
    return HttpHelper.put(e[0], e[1]).then();
  }
  formPost(...e) {
    return HttpHelper.formPost(e[0], e[1]);
  }
  postForExcel(...e) {
    return HttpHelper.postForExcel(e[0], e[1]);
  }

  httpPostObs(...e) {
    return of({}).pipe(
      mergeMap(() => {
        return this.httpPost(...e);
      })
    );
  }
  httpGetObs(e) {
    return of({}).pipe(
      mergeMap(() => {
        return this.httpGet(e);
      })
    );
  }
  formPostObs(...e) {
    return of({}).pipe(
      mergeMap((res) => {
        return this.formPost(...e);
      })
    );
  }
  postForExcelObs(...e) {
    return of({}).pipe(
      mergeMap((res) => {
        return this.postForExcel(...e);
      })
    );
  }

  commonHandler() {}
}
