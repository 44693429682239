import { makeObservable, observable, computed, action } from 'mobx';
import { Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { ReplenishmentStore } from './ReplenishmentStore';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DemandStore } from '../demand/DemandStore';
import { CommonService } from '@/service/common/common-service';

@Injectable()
export class CreateStore extends ReplenishmentStore {
  breadcrumb = this.info.title
  subBreadcrumb = 'Create'
  toggleable = true
  parentData ={
    ipts: [
      {
        title: this.TITLE.DEMAND_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      },
      /*  {
        title: this.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-2",
        disabled: true,
        value: null,
      }, */
        {
        title: this.TITLE.DEMAND_CYCLE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.TITLE.DEMAND_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.TITLE.DEMAND_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData = {
    ipts: [
      {
        title: this.QUERY_TITLE.CHANNEL,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'id',
        error: false,
      }, {
        title: this.QUERY_TITLE.REFERENCE_NUMBER,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        maxLength: 17
      }, {
        title: this.QUERY_TITLE.STATUS,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'value',
      }, {
        title: this.QUERY_TITLE.NEED_BY_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        minDate: null
      }, {
        title: this.QUERY_TITLE.REMARKS,
        type: INPUT_TYPE.TEXTAREA,
        class: "p-col-12 p-md-4 p-lg-9",
        rows: 3,
        maxLength: 70,
        value: null,
      },
    ],
    extended: {
      show: false,
      title: this.QUERY_TITLE.FIELDSET,
      type: INPUT_TYPE.FIELDSET,
      class: "p-col-12 p-md-4 p-lg-9",
      ipts:[
        {
          title: this.QUERY_TITLE.SCHOOL_NUMBER,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          value: null,
        },
        // {
        //   title: this.QUERY_TITLE.SCHOOL_LOCATION_CODE,
        //   type: INPUT_TYPE.INPUT,
        //   class: "p-col-12 p-md-4 p-lg-4",
        //   value: null,
        // },
        {
          title: this.QUERY_TITLE.CONTACT_PERSON,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          maxLength: 50,
          value: null,
        }, {
          title: this.QUERY_TITLE.ADDRESS_1,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          maxLength: 50,
          value: null,
        }, {
          title: this.QUERY_TITLE.ADDRESS_2,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          maxLength: 50,
          value: null,
        }, {
          title: this.QUERY_TITLE.ADDRESS_3,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          maxLength: 50,
          value: null,
        }
      ]
    },
    btns: [
      {
        title: this.BUTTON_TITLE.CANCEL,
        class: "p-ml-auto p-button-outlined p-order-2 p-mr-1 p-as-end",
        show: true,
        handler: { click: () => this.clear() }
      }, {
        title: this.BUTTON_TITLE.SAVE,
        class: "p-order-3 p-mr-1 p-as-end",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.save() }
      }
    ],
    btnsclass: 'p-d-flex p-col-3 vertical-container'
  }
  checkBox ={
    title: this.QUERY_TITLE.ONLY_INCLUDE,
    name: "only",
    type: INPUT_TYPE.CHECKBOX,
    class: "p-col-12 p-md-4 p-lg-3",
    group: 'only',
    value: null,
  }
  tableData = {
    btns: [],
    data: [],
    basicData: [],
    columns: [
      {field: 'itemBuCode', title: this.TABLE_FIELD.BU, width: '70px'},
      {field: 'itemLobCode', title: this.TABLE_FIELD.LOB, width: '70px'},
      {field: 'channelNature', title: this.TABLE_FIELD.CHANNEL_NATURE, width: '150px'},
      {field: 'channel', title: this.TABLE_FIELD.CHANNEL, width: '130px', type: 'repo'},
      {field: 'icCode', title: this.TABLE_FIELD.IC_CODE, width: '170px'},
      {field: 'itemNumber', title: this.TABLE_FIELD.ITEM_NUMBER, width: '160px', type: 'sku'},
      {field: 'desc', title: this.TABLE_FIELD.DESCRIPTION, width: '130px'},
      {field: 'model', title: this.TABLE_FIELD.MODEL, width: '90px'},
      {field: 'uom', title: this.TABLE_FIELD.UOM, width: '80px'},
      {field: 'p30Sales', title: this.TABLE_FIELD.P30, width: '70px'},
      {field: 'p21Sales', title: this.TABLE_FIELD.P21, width: '70px'},
      {field: 'p14Sales', title: this.TABLE_FIELD.P14, width: '70px'},
      {field: 'p07Sales', title: this.TABLE_FIELD.P07, width: '70px'},
      {field: 'qtyDoc', title: this.TABLE_FIELD.DOC, width: '70px'},
      {field: 'qtyAvailable', title: this.TABLE_FIELD.AVAILABLE, width: '120px'},
      {field: 'qtyReserved', title: this.TABLE_FIELD.RESERVED, width: '120px'},
      {field: 'qtyAo', title: this.TABLE_FIELD.AO, width: '70px'},
      {field: 'qtyTransit', title: this.TABLE_FIELD.TRANSIT_IN, width: '120px'},
      {field: 'qtyDo', title: this.TABLE_FIELD.DO, width: '70px'},
      {field: 'qtyTotalAvailable', title: this.TABLE_FIELD.TOTAL_AVAILABLE, width: '150px'},
      {field: 'qtyOnhand', title: this.TABLE_FIELD.TOTAL_ON_HAND, width: '150px'},
      {field: 'qtyRequest', title: this.TABLE_FIELD.CHANNEL_REQUEST, width: '160px'},
      {field: 'qtyPreAllocate', title: this.TABLE_FIELD.PRE_ALLOCATE_QTY, width: '150px'},
      {field: 'qtyAllocate', title: this.TABLE_FIELD.ALLOCATE_QTY, width: '140px', type: 'number'},
      {field: 'qtyW003', title: this.TABLE_FIELD.W003, width: '80px'},
      {field: 'qtyW003Demand', title: this.TABLE_FIELD.W003_DEMANDED, width: '160px'},
      {field: 'qtyW005', title: this.TABLE_FIELD.W005, width: '80px'},
      {field: 'qtyW006', title: this.TABLE_FIELD.W006, width: '80px'},
      {field: 'qtyW007', title: this.TABLE_FIELD.W007, width: '80px'},
      {field: 'qtyW008', title: this.TABLE_FIELD.W008, width: '80px'},
      {field: 'qtyW009', title: this.TABLE_FIELD.W009, width: '80px'},
      {field: 'qtyW010', title: this.TABLE_FIELD.W010, width: '80px'},
      {field: 'qtyW011', title: this.TABLE_FIELD.W011, width: '80px'},
      {field: 'qtyW012', title: this.TABLE_FIELD.W012, width: '80px'},
      {field: 'qtyW013', title: this.TABLE_FIELD.W013, width: '80px'},
      // {field: 'interfaceStatus', title: this.TABLE_FIELD.INTERFACED_STATUS, width: '160px'},
      // {field: 'interfaceDate', title: this.TABLE_FIELD.INTERFACED_DATE, width: '150px', type: 'date'},
      {field: 'updateBy', title: this.TABLE_FIELD.UPDATED_BY, width: '130px', type: 'name' },
      {field: 'updateDate', title: this.TABLE_FIELD.UPDATED_DATETIME, width: '160px', type: 'datetime'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {field: '', title: 'Action', width: '100px', type: 'action'},
    ],
    frozenWidth: '100px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "updateDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'updateDate',
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  }
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  scrollable:boolean = true
  loading:boolean = false
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  cycleTypeIpt = null
  startDateIpt = null
  // endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  referenceNumberIpt = null
  statusIpt = null
  buCodeIpt = null
  needByDateIpt = null
  remarksIpt = null
  onlyIncludeIpt = null
  schoolCodeIpt = null
  schoolLocationCodeIpt = null
  schoolNumberIpt = null
  contactPersonIpt = null
  address1Ipt = null
  address2Ipt = null
  address3Ipt = null
  channels = []
  demandLineList = []
  idx = 0
  buCodes = []
  selectBUCodes = []
  repos = []
  skus = []
  currentLine:any = {}
  currentDay:Date
  cloneRepoData
  cloneSkuData
  hasCreate = false
  modalConfig: any = {
    title: 'Reservation',
    visible: false,
    warehouseTitle: 'W003',
    rangeDate: '01/01/2022 - 01/05/2022',
    totalCount: '10(200)',
    selTotalCount: 0,
    items: [],
    selectedItem: '',
    table: {
      columns: [
        {field: 'reservationDate', title: 'Reservation Date', width: '140px'},
        {field: 'reservationType', title: 'Type'},
        {field: 'channelCode', title: 'Channel'},
        {field: 'itemCode', title: 'Item Code', width: '100px'},
        {field: 'itemDesc', title: 'Item Desc', width: '350px'},
        {field: 'qtyRequested', title: 'Qty Request', width: '110px'},
        {field: 'qtyPending', title: 'Qty Pending', width: '110px'},
        {field: 'qtyPlanToAllocate', title: 'Qty Planned', width: '110px'},
        {field: 'sensitiveRemarks', title: 'Remarks', width: '110px'},
        {field: 'sourceSystem', title: 'Source System', width: '130px'},
        {field: 'sourceTxnRefHeaderId', title: 'source Txn Ref Header Id', width: '180px'},
        {field: 'sourceTxnRefHeaderNo', title: 'source Txn Ref Header No', width: '180px'},
        {field: 'createDate', title: 'Create Date', width: '110px'},
        {field: 'updateDate', title: 'Update Date', width: '110px'},
      ],
      frozenColumns:[
        {field: 'action', title: '', type: 'action', width: '50px'},
      ],
      frozenWidth: '50px',
      rawData: [],
      data: [],
      selected: [],
    }
  }
  demandLine:any;
  getDemandLine(){
    // return this.demandLine
    return JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
  }
  setDemandLine(e){
    sessionStorage.setItem('DEMAND_LINE', JSON.stringify(e))
    this.demandLine=e
  }
  constructor(
    router: Router,
    route: ActivatedRoute,
    service: StockDemandCycleService,
    msg: MessageService,
    private demandStore: DemandStore,
    conf: ConfirmationService,
    public commonService: CommonService,
  ) {
    super(router, route, service, msg, conf, commonService)
    makeObservable(this, {
      breadcrumb: observable,
      parentData: observable,
      queryData: observable,
      tableData: observable,
      // skuCode: observable,
      cycleNameIpt: observable,
      poolTypeIpt: observable,
      cycleTypeIpt: observable,
      startDateIpt: observable,
      // endDateIpt: observable,
      submissionDeadlineIpt: observable,
      channelIpt: observable,
      referenceNumberIpt: observable,
      statusIpt: observable,
      buCodeIpt: observable,
      remarksIpt: observable,
      onlyIncludeIpt: observable,
      needByDateIpt: observable,
      schoolCodeIpt: observable,
      schoolLocationCodeIpt: observable,
      schoolNumberIpt: observable,
      contactPersonIpt: observable,
      address1Ipt: observable,
      address2Ipt: observable,
      address3Ipt: observable,
      channelId: computed,
      referenceNumber: computed,
      status: computed,
      // buCode: computed,
      needByDate: computed,
      remarks: computed,
      // onlyInclude: computed,
      showU: computed,
      initOriginIpt: action,
      initQueryIpt: action,
      initQueryData: action,
      addDataToTable: action
    })
    this.tableData.selectedColumns = [...this.tableData.columns]
    this.tableData.basicData = []

    this.getPermission(route)
    this.getBUCode()
    this.getChannels()
    this.getItems()
    this.initOriginIpt()
    this.initQueryIpt()

    this.loadDataByParams().subscribe(
      (res)=>{
        if(res.action){
          this.initQueryData(res);
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
    // this.initQueryData()
  }

  get channelId() {
    return this.channelIpt.value
  }
  get referenceNumber() {
    return this.referenceNumberIpt.value
  }
  get status() {
    return this.statusIpt.value
  }
  get buCode() {
    return this.buCodeIpt.value
  }
  get needByDate() {
    return this.needByDateIpt.value
  }
  get remarks() {
    return this.remarksIpt.value
  }
  get onlyInclude() {
    return this.onlyIncludeIpt.value
  }
  get showU() {
    return this.permission?.includes('Create Replenishment')
  }

  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title !== 'Cancel' && (item.disabled = !this.permission.includes("Create Replenishment"))
    })
  }
  getOriginByTitle(title) {
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title) {
    return this.queryData.ipts.find(item => item.title === title)
  }
  getSchoolByTitle(title) {
    return this.queryData.extended.ipts.find(item => item.title === title)
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_NAME)
    // this.poolTypeIpt = this.getOriginByTitle(this.TITLE.STOCK_POOL_TYPE)
    this.cycleTypeIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_START_DATE)
    // this.endDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_END_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.QUERY_TITLE.CHANNEL)
    this.referenceNumberIpt = this.getQueryByTitle(this.QUERY_TITLE.REFERENCE_NUMBER)
    this.statusIpt = this.getQueryByTitle(this.QUERY_TITLE.STATUS)
    this.needByDateIpt = this.getQueryByTitle(this.QUERY_TITLE.NEED_BY_DATE)
    this.currentDay = new Date()
    const nextDay = this.getNextDay(this.currentDay)
    this.needByDateIpt.minDate = nextDay
    this.needByDateIpt.value = nextDay

    this.remarksIpt = this.getQueryByTitle(this.QUERY_TITLE.REMARKS)
    this.initSchoolIpt()
  }
  initSchoolIpt() {
    this.schoolCodeIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_CODE)
    this.schoolLocationCodeIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_LOCATION_CODE)
    this.schoolNumberIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_NUMBER)
    this.contactPersonIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PERSON)
    this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_1)
    this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_2)
    this.address3Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_3)
  }
  initQueryData(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))){
    this.demandData = demandData
    this.queryData.extended.show = this.demandData.hasSchoolData
    const list = LocalStorageHelper.getObject('POOL_TYPE')
    // const poolType = list.find(item =>  item.id === this.demandData.sdl_stock_pool_type_id);

    this.cycleNameIpt.value = this.demandData.demandCycleName
    // this.poolTypeIpt.value = poolType.name
    this.cycleTypeIpt.value = this.demandData.demandCycleType
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    // this.endDateIpt.value =  new Date(this.demandData.demandEndDate)
    this.submissionDeadlineIpt.value = this.demandData.submissionDeadline ? new Date(this.demandData.submissionDeadline) : null
    this.setInitSearchData()
  }
  setInitSearchData() {
    this.channels = LocalStorageHelper.getObject('WAREHOUSE')
    this.channelIpt.options = this.channels
    this.channelIpt.value = 1

    const status = LocalStorageHelper.getObject('REPLENISHMENT_STATUS')
    this.statusIpt.options = status
    this.statusIpt.value = status.find(item => item.label === 'Draft').value;

    this.skus = LocalStorageHelper.getObject('SKU')
    // 这里针对类型做不同的限制，目前不需要
    /* if(this.demandData.demandCycleType !== 'BYOD'){
      this.remarksIpt.maxLength = undefined
    } */
  }
  update(fromPreAllocate = false) {
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      fromChannelId: this.channelId,
      referenceNumber: this.referenceNumber,
      sdlReplenishStatusId: this.status,
      id: replenishmentLine.id,
      schNum: this.schoolNumberIpt.value,
      contactPersonNo: this.contactPersonIpt.value,
      address1: this.address1Ipt.value,
      address2: this.address2Ipt.value,
      address3: this.address3Ipt.value,
      needByDate: this.needByDate.getTime(),
      remarks: this.remarks,
      ...(this.tableData.data.length && {lines: this.tableData.data})
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentEdit(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title,  this.info.success)
        this.saveDataToStorage(res.data[0], fromPreAllocate)
      } else {
        if (res.error) {
          this.showMessage('error', this.info.title,  res.error.message)
        } else {
          this.showMessage('warn', this.info.title,  res.msg)
        }
        this.idx = 0
      }
    })
  }
  changeItem() {
    this.setPreAllocateTableData()
  }
  change(val) {
    let selectItem = this.modalConfig.table.rawData.find(item => item.itemCode === this.modalConfig.selectedItem)
    if(selectItem.totalCount === 0) {
      return
    }
    let resolt = this.modalConfig.table.selected.find((item) => item.id === val.id);
    val.qtyPlanToAllocate = resolt ? val.qtyPending : 0
    this.changeTotal(selectItem)
  }
  setModalVisible(state) {
    this.modalConfig.visible = state
    if(!state) {
      this.changeTick(true)
    }
  }
  formatDate(date: Date) {
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }
  fetchPreAllocate(params) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let data = {
      replenishId: params.id,
      demandCycleType: this.demandData.demandCycleType,
    }

    this.service.fetchPreAllocate(data).subscribe(res => {
      if(res.code === '000') {
        if (res.data.length === 0) {
          this.showMessage('warn', this.info.title, this.info.noPreAllocate)
        } else {
          this.modalConfig.table.rawData = res.data
          this.setWareHouseTitle()
          this.setRangeDate(res.data)
          this.setPreAllocateItems()
        }
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Pre Allocate'))
      }
      this.loading = false
      this.tableData.loadingSwitch = false
    })
  }
  getLines() {
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    const data = {
      accountName: this.account,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: replenishmentLine.id,
      demandCycleId: replenishmentLine.demandCycleId
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineSearch(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        this.tableData.basicData = JSON.parse(JSON.stringify(res.data))
        res.data.forEach((item) => {
          let tableItem = this.tableData.data.find(tItem => tItem.channel === item.channel && tItem.itemNumber === item.itemNumber)
          tableItem.qtyAllocate = item.qtyAllocate
          tableItem.qtyW003 = item.qtyW003
        });
        this.setModalVisible(false)
      }
    })
  }
  changePreAllocate() {
    const replenishmentLine = this.getDemandLine() // JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
    let data = {
      replenishId: replenishmentLine.id,
      list: this.getPreAllocateData(this.modalConfig.table.rawData)
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.savePreAllocate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title, res.msg || this.info.success.replace('Saved', 'Change Pre Allocate'))
        this.getLines()
      } else {
        this.showMessage('warn', this.info.title, res.msg || res.message || res.error.message || this.info.fail.replace('Save', 'Change Pre Allocate'))
      }
    })
  }
  getPreAllocateData(list) {
    return list.map((item) => item.reservationsList).flat()
  }
  setWareHouseTitle() {
    let warehouse = this.channels.find(channel => channel.id === this.channelId )
    this.modalConfig.warehouseTitle = warehouse.label
  }
  setRangeDate(list) {
    let dateList =  list.map((item) => item.reservationsList).flat().map(date => date.reservationDate)
    dateList = [...new Set(dateList)].sort()
    this.modalConfig.rangeDate = dateList[0] + ' to ' + dateList[dateList.length - 1]
  }
  setPreAllocateItems() {
    this.modalConfig.items = this.modalConfig.table.rawData.map(item => {
      if(!item.itemCode) {
        item.itemCode = item.reservationsList[0].itemCode
      }
      return {name: item.itemCode, code: item.itemCode, id: item.reservationsList[0].itemId}
    })
    this.modalConfig.selectedItem = this.modalConfig.items[0].name

    this.setPreAllocateTableData()
  }
  changeTotal(selectItem){
    /* let count1 = selectItem.reservationsList.reduce((prev, cur) => {
      prev += cur.qtyPending
      return prev
    }, 0); */
    let count = selectItem.reservationsList.reduce((prev, cur) => {
      prev += cur.qtyPlanToAllocate
      return prev
    }, 0);
    this.modalConfig.selTotalCount = selectItem.totalCount
    this.modalConfig.totalCount = (selectItem.totalCount - count) + '(' + selectItem.totalCount + ')'
  }
  changeTick(clear=null) {
    this.modalConfig.table.selected = []
    if (!clear) {
      this.modalConfig.table.data.forEach(item => {
        if(item.qtyPlanToAllocate > 0) {
          let selItem = this.modalConfig.table.selected.find(sel => sel.id === item.id)
          if(!selItem) {
            this.modalConfig.table.selected.push(item)
          }
        }
      });
    }
  }
  setPreAllocateTableData() {
    let selectItem = this.modalConfig.table.rawData.find(item => item.itemCode === this.modalConfig.selectedItem)
    this.modalConfig.table.data = selectItem.reservationsList
    this.changeTick()
    this.changeTotal(selectItem)
    this.setModalVisible(true)
  }
  getBUCode() {
    this.service.replenishmentGetBuCode().subscribe(res => {
      this.buCodes = []
      if(res.code === '000'){
        res.data.forEach((item) => {
          this.buCodes.push({label: item,  value: item})
        });
      }
    })
  }
  getChannels() {
    const list = LocalStorageHelper.getObject('REPO')
    this.repos = list.filter(item => item.id > 0)
    this.cloneRepoData = JSON.parse(JSON.stringify(this.repos))
  }
  getItems() {
    this.skus = LocalStorageHelper.getObject('SKU')
    this.cloneSkuData = JSON.parse(JSON.stringify(this.skus))
  }

  getItemsByBU() {
    this.demandLineList = this.tableData.data
    if(!this.selectBUCodes || !this.selectBUCodes.length) {
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'BU Code'))
      return
    }
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      buList: this.selectBUCodes,
      isHaveDemandOnly: this.checkBox.value && this.checkBox.value.length ? true : false
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemByBU(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data)
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }
  addDataToTable(list, type=null) {
    if(this.tableData.data.length) {
      if (type === 'school') {
        list = list.filter(item => {
          const hasExist = this.tableData.data.some(line => {
            const exist = line.itemBuCode === item.itemBuCode && line.channel === item.channel && line.itemNumber === item.itemNumber
            if(exist) {
              line.qtyAllocate = item.qtyAllocate
            }
            return exist
          })
          if(!hasExist) {
            return item
          }
        });
      } else {
        if(!(list.length === 1 && !list[0].channel && !list[0].itemNumber && !list[0].itemBuCode)) {
          list = list.filter(item => {
            const hasExist = this.tableData.data.some(line =>
              line.itemBuCode === item.itemBuCode && line.channel === item.channel && line.itemNumber === item.itemNumber)
            if(!hasExist) {
              return item
            }
          });
        }
      }
    }
    list.forEach(item => {
      item.rowId = this.idx++
      item.qtyAllocate = type ? item.qtyAllocate : 0
    });
    this.demandLineList = [...list, ...this.tableData.data]
    this.tableData.data = this.demandLineList
  }
  /* addItemBySchool() {
    // const schCode = this.schoolCodeIpt.value
    // const schLocCode = this.schoolLocationCodeIpt.value
    // if(!schCode && !schLocCode) {
    //   this.showMessage('warn', this.info.title, this.info.hasValue)
    //   return
    // }
    const schNum = this.schoolNumberIpt.value
    const data = {
      // schCode,
      // schLocCode,
      schNum,
      demandCycleId: this.demandData.demandCycleId,
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItemBySchoolCode(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        if (res.data.length) {
          this.addDataToTable(res.data, 'school')
        } else {
          this.showMessage('info', this.info.title,  this.info.noRecord)
        }
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
      }
    })
  } */
  addItem() {
    let data:any = this.tableData.columns.reduce((t, v) => {
      t[v.field] = ''
      return t
    }, {});
    this.addDataToTable([data])
  }

  delete(data) {
    this.demandLineList = this.tableData.data
    this.restoreData('confirmDelete', this, data, this.info.delete)
  }

  confirmDelete(data) {
    // console.log(this.demandLineList, data);
    this.demandLineList = this.demandLineList.filter(item => item.rowId !== data.rowId)
    this.tableData.data = this.demandLineList
  }

  clear() {
    this.restoreData('confirmCancel', this, {}, this.info.cancel)
  }
  confirmCancel() {
    this.back()
    // this.router.navigate(['main/demand_cycle/replenishment/search'])
  }
  searchRepo(str) {
    this.repos = this.cloneRepoData.filter(item => item.label.toLowerCase().includes(str.toLowerCase()))
  }
  searchSku(str) {
    this.skus = this.cloneSkuData.filter(item => item.name.toLowerCase().includes(str.toLowerCase()))
  }
  selRepo(event, data, el) {
    this.currentLine = data
    this.repos = this.cloneRepoData
    el.toggle(event)
  }

  changeRepo(event, el) {
    const repo = this.repos.find(item => item.id === event.value)
    this.currentLine.channel = repo.label
    this.currentLine.toChannelId = repo.id
    el.hide()
    if(this.currentLine.toChannelId && this.currentLine.itemId) {
      this.getLineData()
    }
  }

  selSku(event, data, el) {
    this.currentLine = data
    this.skus = this.cloneSkuData
    el.toggle(event)
  }

  changeSku(event, el) {
    const sku = this.skus.find(item => item.code === event.value)
    this.currentLine.itemNumber = sku.name
    this.currentLine.itemId = sku.code
    el.hide()
    if(this.currentLine.toChannelId && this.currentLine.itemId) {
      this.getLineData()
    }
  }
  getLineData() {
    const data = {
      accountName: this.account,
      // channelReplenishId: replenishmentLine.replenishId,
      demandCycleId: this.demandData.demandCycleId,
      itemId: this.currentLine.itemId,
      itemCode: this.currentLine.itemNumber,
      channelId:  this.currentLine.toChannelId,
      channelCode: this.currentLine.channel
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentLineAddItem(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        // tslint:disable-next-line: no-unused-expression
        res.data.length && this.changeTableRowData(res.data[0])
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
        // this.showMessage('warn', this.info.title, this.info.fail.replace('Save', 'get data'))
      }
    })
  }
  changeTableRowData(data) {
    // tslint:disable-next-line: forin
    for (const key in data) {
      this.currentLine[key] = data[key]
    }
    const hasExist = this.tableData.data.some(line => line.rowId !==  this.currentLine.rowId
      && line.itemBuCode === this.currentLine.itemBuCode && line.channel === this.currentLine.channel
      && line.itemNumber === this.currentLine.itemNumber)
    if(hasExist) {
      this.showMessage('warn', this.info.title, this.info.duplicate)
    }
    this.currentLine.icCode = "S" + this.currentLine.channel
    this.currentLine.qtyAllocate = 0
    const idx = this.demandLineList.findIndex(item => item.rowId ===  this.currentLine.rowId)
    this.demandLineList[idx] = this.currentLine
  }
  validationData() {
    let flag = true
    if(!this.channelId) {
      this.channelIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Channel'))
      flag = false
    }
    if(!this.referenceNumber) {
      this.referenceNumberIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Reference NumberIpt'))
      flag = false
    } else if(this.referenceNumber.length > CONFIG.REPLENISH_REFNO_MAX_LENGTH) {
      this.referenceNumberIpt.error = true
      this.showMessage('warn', this.info.title, this.info.maxLength.replace('${1}', CONFIG.REPLENISH_REFNO_MAX_LENGTH))
      flag =  false
    }

    if(!this.needByDate) {
      this.needByDateIpt.error = true
      this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Need By Date'))
      flag = false
    } else {
      // 判断选择的日期是否大于系统当天的日期
      const day = 24 * 60 * 60 * 1000
      const diff = Math.floor((this.needByDate.getTime() - this.currentDay.getTime()) / day)
      if(diff <= 0) {
        this.needByDateIpt.error = true
        this.showMessage('warn', this.info.title, this.info.dateError)
        flag = false
      }
    }
    if(this.tableData.data.length) {
      const channel = this.tableData.data.some(item => !item.channel)
      if(channel) {
        this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Channel of Item'))
        flag = false
      }
      const itemNumber = this.tableData.data.some(item => !item.itemNumber)
      if(itemNumber) {
        this.showMessage('warn', this.info.title, this.info.reqiuired.replace('this', 'Item Number of Item'))
        flag = false
      }
      const result = this.tableData.data.some(item => item.qtyAllocate === null || item.qtyAllocate < 0 )
      if(result) {
        flag = false
        this.showMessage('warn', this.info.title, this.info.gt)
      }
    }
    return flag
  }
  verifyQty(list) {
    return list.find(item => {
      let selItem = this.tableData.basicData.find(bItem => bItem.channel === item.channel && bItem.itemNumber === item.itemNumber)
      return selItem.qtyAllocate > item.qtyAllocate || item.qtyAllocate > item.qtyW003
    })
  }
  save(fromPreAllocate = false) {
    if(this.demandData.demandCycleType !== 'Channel') {
      this.showMessage('warn', this.info.title, this.info.notPreAllocate.replace('type', this.demandData.demandCycleType + ' type'))
      return
    }
    if(!this.validationData()){
      return
    }
    if(this.hasDuplicate(this.tableData.data)) {
      this.showMessage('warn', this.info.title, this.info.duplicate)
      return
    }
    if(this.tableData.basicData.length > 0) {
      let resolt = this.verifyQty(this.tableData.data)
      if(resolt) {
        let name = resolt.channel + ' + ' + resolt.itemNumber
        this.showMessage('warn', this.info.title, this.info.valueError.replace('item', name))
        return
      }
    }
    if(this.hasCreate) {
      this.update(fromPreAllocate)
      return
    }
    const data = {
      accountName: this.account,
      demandCycleId: this.demandData.demandCycleId,
      fromChannelId: this.channelId,
      referenceNumber: this.referenceNumber,
      sdlReplenishStatusId: this.status,
      // buCode: this.buCode,
      // isHaveDemandOnly: this.isHaveDemandOnly,
      // schCode: this.schoolCodeIpt.value,
      // schLocCode: this.schoolLocationCodeIpt.value,
      schNum: this.schoolNumberIpt.value,
      contactPersonNo: this.contactPersonIpt.value,
      address1: this.address1Ipt.value,
      address2: this.address2Ipt.value,
      address3: this.address3Ipt.value,
      needByDate: this.needByDate.getTime(),
      remarks: this.remarks,
      ...(this.tableData.data.length && {lines: this.tableData.data})
    }
    this.loading = true
    this.tableData.loadingSwitch = true
    this.service.replenishmentCreate(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title,  this.info.success)
        this.saveDataToStorage(res.data[0], fromPreAllocate)
      } else {
        this.showMessage('warn', this.info.title,  res.msg || res.message || res.error.message)
        this.idx = 0
      }
    })
  }
  saveDataToStorage(params, fromPreAllocate=false) {
    // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(params))
    this.setDemandLine(params)
    if (fromPreAllocate) {
      this.hasCreate = true
      this.fetchPreAllocate(params)
    } else {
      this.redirect()
    }
  }
  redirect(){
    setTimeout(() => {
      this.router.navigate(['main', 'demand_cycle', 'replenishment', 'detail'])
    }, 200);
  }

  stopEvent(event){
    event.stopPropagation()
  }

  sortSearch(field) {
    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
  }
  back() {
    window.history.back()
  }
  // The current date + 1 day
  getNextDay(date) {
    const nextDay = new Date(date)
    nextDay.setDate(nextDay.getDate() + 1)
    return nextDay
  }
  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
}
