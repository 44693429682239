import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-column-tabs',
  templateUrl: './table-column-tabs.component.html',
  styleUrls: ['./table-column-tabs.component.scss']
})
export class TableColumnTabsComponent implements OnInit {

  @Input() tabStyle: 'button' | 'underline' = 'underline';
  @Input() tabsList;
  @Input() selectedColumns: any;
  @Output() selectedColumnsChange = new EventEmitter()
  @Input() selectedTab: any;
  @Output() selectedTabChange = new EventEmitter();
  @Output() tabChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  setSelectedColumns(columns){
    // this.store.tableData.selectedColumns = columns
    this.selectedColumns = columns;
    this.selectedColumnsChange.emit(this.selectedColumns)
  }
  tableTabOnClick(e, item){
    this.tabChange.emit({event: e, item: item})
    this.selectedTab = item.name;
    this.selectedTabChange.emit(this.selectedTab)
    this.setSelectedColumns(item.columnList)
  }
  resetTableTab(){
    this.selectedTab = null;
    this.selectedTabChange.emit(this.selectedTab)
  }
}
