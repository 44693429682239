import { CommonService } from "@/service/common/common-service";
import { DatePipe } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { pluck } from "rxjs/operators";
import { StockMovementHistoryService } from "src/app/service/stock/stock-movement-history.service";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";


export class HistoryStore {
  QUERY_TITLE = {
    BATCH_ID: "Batch ID",
    IMPORT_STATUS: "Import Status",
    SHOP_CODE: "Shop Code",
  }
  BUTTON_TITLE = {
    SEARCH: "Search",
    EXPORT: "Export",
    CLEAR: "Clear",
  }
  TABLE_FIELD = {
    CSTK: "CSTK",
    PPOS: "PPOS",
    BATCH_ID: "Batch ID",
    TRANSACTION_ID: "Transaction ID",
    IMPORT_STATUS: "Import Status",
    PROCESS_DATE_TIME: "Process Date/Time",
    ERROR_MESSAGE: "Error Message",
    WAYBILL: "Waybill",
    SHOP_CODE: "Shop Code",
    SM_NUMBER: "SM Number",
    ORDER_TYPE: "Order Type",
    ITEM_CODE: "Item Code",
    PRODUCT_LINE: "Product Line",
    QTY: "Qty",
    SERIAL_NUMBER: "Serial Number",
    TRANSACTION_DATE_TIME: "Transaction Date/Time",
    STOCK_CATEGORY: "Stock Category",
    CCC: "CCC",
    WORK_ORDER_NUMBER: "Work Order Number",
    RE_SUBMIT: "Re-submit",

  }
  info = {
    title: "PPOS Daily Stock Movement History",
    success: "Saved successfully!",
    fail: "Save failed!",
    delete: "confirm to delete?",
    cancel: "confirm to cancel?",
    noChange: "no Change!",
    apiError: "Api call error, please contact system support."
  }
  account = this.commonService.getAccount()
  router: Router = null
  route: ActivatedRoute = null
  service: StockMovementHistoryService = null
  msg: MessageService = null
  conf: ConfirmationService = null
  datepipe:DatePipe = null
  constructor(
    datePipe: DatePipe,
    router: Router,
    route: ActivatedRoute,
    service: StockMovementHistoryService,
    msg: MessageService,
    conf: ConfirmationService,
    public commonService: CommonService) {
      this.datepipe = datePipe
      this.router = router
      this.route = route
      this.service = service
      this.msg = msg
      this.conf = conf
  }
  getResolverData(route) {
    route.data.pipe(pluck('query')).subscribe(res => {
      let list = []
      if(!res.local && res[0].code === '000' ) {
        res[0].data.forEach(item => {
          list.push({id:item.id, code: item.code, name: item.description})
        })
        LocalStorageHelper.setObject('POOL_TYPE', list)
      }
    })
  }
  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss" )
  }
  comparisonDate(timeStamp1, timeStamp2) {
    const dayValue = 24 * 60 * 60 * 1000
    return Math.ceil((timeStamp1 - timeStamp2) / dayValue)
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
  restoreData(fun, self, data, msg) {
    this.conf.confirm({
      message: msg,
      header: this.info.title,
      accept: () => {
        self[fun](data)
      }
    });
  }
  //Determine whether there is duplicate data
  hasDuplicate(arr) {
    let flag = false
    arr.forEach(item => {
      const index = arr.findIndex(item2 => item.rowId !== item2.rowId
        && item.itemNumber === item2.itemNumber && item.itemBuCode === item2.itemBuCode && item.channel === item2.channel)
      if(index !== -1){
        flag = true
      }
    })
    return flag
  }
}
