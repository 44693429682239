import { CanActivate, RouterStateSnapshot, Router, ActivatedRoute, ActivatedRouteSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageHelper } from './LocalStorageHelper';
import { MessageService, ConfirmationService } from 'primeng/api';
//模块与权限标识映射
@Injectable()
export class AuthService implements CanActivate, CanActivateChild {
  // private ts
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService) {
    // this.ts = new BaseStore(InjectHelper.start(this))
  }
  canActivate(next:ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean|UrlTree {
    //TODO 需要判断子路由的权限设置
    // console.log('---------- can activete ----------', state.url)
    try{
      var url: string = state.url
      const authList = LocalStorageHelper.getObject("MENU").reduce((pre, cur) => {
        return pre.concat(cur.items);
      }, [])
      const path = url.substring(6).split("?")[0];
      const prevPath = path.split('/')[0]
      const lastPath = path.split('/')[1]
      const result = authList.find((item) => {
        return item.routerLink[0].includes(path)
      })

      const flag = result && result.visible ? true : false
      if (flag) {
        return flag
      }
      const subResult = authList.find((item) => item.routerLink[0].includes(prevPath))
      if(subResult){
        const childrenResult = subResult.children.find((item) => item.routerLink[0].includes(lastPath))
        if (childrenResult && childrenResult.visible) {
          return true
        }
      }
    }catch(e){console.error(e)}
    // this.ts.$NOTICE.warning( "WARNING", "You are not authorized to access this page." )
    this.n.add({severity:"warn", summary:"WARNING", detail:"You are not authorized to access this page."})
    return this.r.parseUrl("/app-page-not-found")
  }
  canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
    // console.log('---------- can activete child ----------')
    return this.canActivate(route, state);
  }
}
