import { Component, Input } from '@angular/core';
/**
  * @description: radio选择框
  * @author:  xk
  */
@Component({
  selector: 'app-radio-cst',
  templateUrl: './radio-cst.html',
  styleUrls:['./radio-cst.css']
})
export class RadioCustom {
  @Input() ipt
  @Input() store
  change(){
    console.log('ipt',this.ipt, this.ipt.value);
  }
}
