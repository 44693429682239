import { CommonStore } from '@/service/common/common-store-service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TakeDetailStore } from 'src/app/stores/stock/take/takeDetailStore';

@Component({
  selector: 'app-take-detail',
  providers: [TakeDetailStore],
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class TakeDetailComponent implements OnInit {
  @ViewChild('popupPUploadEl') popupPUploadEl;
  constructor(public store: TakeDetailStore,
    public commonStore: CommonStore) { 
      store.setCommonStore(commonStore)
    }
  ngOnInit(): void {
  }
  ngAfterViewInit(){
    this.store.popupPUploadEl = this.popupPUploadEl
  }
}
