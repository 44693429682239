import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { from } from 'rxjs';
import { URLDICT, MENU } from '../../config';
import { HttpHelper } from '../../util/HttpHelper';
import { CommonService } from '../common/common-service';

@Injectable({
  providedIn: 'root'
})
export class StockAvailabilityLevelService {
  TITLE = {
    CHANNEL: "Channel",
    ITEM: "Item",
    LABEL: "Label",
    FROM: "From",
    TO: "To"
  }
  BUTTON_TITLE = {
    SEARCH: "Search",
    BACK: "Back",
    CLEAR: "Clear",
    ADD: "Add",
    CANCEL: "Cancel",
  }
  TABLE_FIELD = {
    CHANNEL: "Channel",
    ITEM: "Item",
    LABEL: "Label",
    FROM: "From",
    TO: "To"
  }
  info = {
    title: MENU.CIC_STOCK_AVAILABILITY_LEVEL,
    confirmSave: 'Are you sure you want to save the changes?',
    confirmCancel: 'Are you sure you want to cancel the modification?',
    confirmDelete: 'Are you sure you want to delete this data?',
    noItems: 'The selected channel has no associated items!',
    reqiuired: 'This is a required!',
    noChange: 'The data has not been modified and does not need to be saved!',
    coexist: 'After selecting channel, you must select item!',
    success: 'Created successfully!',
    failed: 'The operation failed',
    gt: 'To must be greater than from'
  }
  defaultConfigDesc = 'Stock Availability'
  account = this.commonService.getAccount() 
  constructor(private msg: MessageService, private conf: ConfirmationService, 
    public commonService: CommonService) { }
  getLabels(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_LABEL, data))
  }
  getType(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_GET_ALL_TYPE, data))
  }
  search(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_SEARCH, data))
  }
  searchAvailablity(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_SEARCH_AVAILABLITY, data))
  }
  create(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_CREATE, data))
  }
  edit(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_EDIT, data))
  }
  editAvailablity(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_EDIT_AVAILABLITY, data))
  }
  delete(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.CHANNEL_ITEM_CONFIG_DELETE, data))
  }
  getItems(data) {
    data.account = this.account
    return from(HttpHelper.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, data))
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
  showDialog(msg, data, self,  callback) {
    this.conf.confirm({
      message: msg,
      header: this.info.title,
      accept: () => self[callback](data)
    });
  }
}
