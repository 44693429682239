import { ConfirmationService, MessageService } from 'primeng/api';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {LoginStore} from '../../../stores/system/login/LoginStore';
import {InjectHelper} from '../../../util/InjectHelper';
import {AppComponent} from "../../../app.component";
import { CommonStore } from '@/service/common/common-store-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public store
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService,private app:AppComponent,
    private commonStore: CommonStore) {
    this.store = new LoginStore(InjectHelper.start(this));
  }

  async oauthLogin(){
    this.app.login()
  }

  // @action login() {
  //   this.store.getAccountByPass(this.account,this.password).toPromise().then(
  //     (res =>{
  //       console.log(res)
  //       if(res['code']=='000'){
  //         this.r.navigate([""])//navigate to loading
  //         console.log(res['data'][0])
  //       } else {
  //         this.n.error(NOTI_TITLE,NOTI_MSG.ERROR_ACCOUNT)
  //         this.password = ""
  //       }
  //
  //       //this.sto.setItem("token",res[0].token)
  //       //console.log(this.sto.getItem("token"))
  //     })
  //   ) .catch((e) => {
  //     this.r.navigate(["/login"])
  //     console.log(e)
  //     this.n.error(NOTI_TITLE,NOTI_MSG.ERROR_ACCOUNT)
  //     this.password = ""
  //   })
  //
  // }

  // switchView() {
  //   this.visible = !this.visible
  // }

}

