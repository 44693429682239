import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InjectHelper } from 'src/app/util/InjectHelper';
import { LoadingStore } from 'src/app/stores/loading/LoadingStore';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { OAuthService } from 'angular-oauth2-oidc';
import { CommonStore } from '@/service/common/common-store-service';

@Component({
  selector: 'app-loading',
  template:`
    <body>
     <div class="loading">
        <!-- <nz-progress
         [nzStrokeColor]="'#1890ff'"
         [nzPercent]="store.percent"
          nzStatus="active"
         nzType="circle"></nz-progress> -->
         <!-- <p-knob [(ngModel)]="store.percent" [strokeWidth]="5" readonly=true></p-knob> -->
         <p-progressSpinner></p-progressSpinner>
       <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
     </div>
    </body>
  `,
  styles: [
    `
      body {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: #f0f2f5;
      }
    `
  ]
})

export class LoadingComponent {
  //  private percent =10;  //缓存加载进度
  //  public showLoading: boolean = true;//是否显示loading进度
  public store
  msgs: Message[] = [];
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService,
    private oauthService: OAuthService,private commonStore: CommonStore) {
    this.store = new LoadingStore(InjectHelper.start(this));
  }
}
