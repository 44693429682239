import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { SearchStore } from "../../../../stores/stock/balance/SearchStore";
import { InjectHelper } from "src/app/util/InjectHelper";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { StockReservationService } from "src/app/service/stock/stock-reservation.service";
import { Paginator } from "primeng/paginator";
import { Subscription } from "rxjs";
import { CommonStore } from "@/service/common/common-store-service";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-search",
  templateUrl: "./balance-query-pannel.component.html",
  styleUrls: ["./balance-query-pannel.component.scss"],
})
export class SearchComponent {
  public store: SearchStore = null;
  constructor(
    private n: MessageService,
    private r: Router,
    private p: ActivatedRoute,
    private m: ConfirmationService,
    public stockCommonService: StockCommonService,
    public stockReservationService: StockReservationService,
    public cdRef: ChangeDetectorRef,
    public commonStore: CommonStore,
    public datepipe: DatePipe,
  ) {
    this.store = new SearchStore(InjectHelper.start(this),stockCommonService);
    this.store.setStockCommonService(this.stockCommonService);
    this.store.setStockReservationService(this.stockReservationService);
    this.store.datepipe = datepipe;
    // console.log('----- balance -----', this.store);
  }


  @ViewChild('popupPaginator', {static: false}) popupPaginator: Paginator;
  resetPopupPaginator(){
    if(!this.popupPaginator) return;
    var pc = this.popupPaginator.getPageCount();
    this.popupPaginator.first = 0;
    this.popupPaginator.updatePageLinks();
    this.popupPaginator.onPageChange.emit(null); // paginator display not update without this line
    this.popupPaginator.updatePaginatorState();
    this.popupPaginator.pageLinks = [...this.popupPaginator.pageLinks]
    this.cdRef.markForCheck();
  }
  popupPaginatorSub: Subscription
  ngAfterViewInit(){
    if(this.store.popupPaginatorSubject){
      if(this.popupPaginatorSub) {
        this.popupPaginatorSub.unsubscribe();
        this.popupPaginatorSub = null;
      }
      this.popupPaginatorSub = this.store.popupPaginatorSubject.subscribe(e=>{
        if(e=='reset'){
          this.resetPopupPaginator()
        }
      })
    }
  }
  ngOnDestroy() { if(this.popupPaginatorSub)this.popupPaginatorSub.unsubscribe() }

}
