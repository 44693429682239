/**
 * create cas appointment with suggest date from demand
 */

import { StockOrderService } from "@/service/stock/stock-order.service";
import { DatePipe } from "@angular/common";
import { THIS_EXPR, ThrowStmt } from "@angular/compiler/src/output/output_ast";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { computed, observable } from "mobx";
import { MessageService } from "primeng/api";
import { throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { CONFIG, INPUT_TYPE } from "src/app/stores/base/BaseStore";
import { ORDERTYPEID, STATUSID } from "../transfer_order/StockOrderShare";

const TITLE = {
  DATERANGE: "Date Range",
  TIMESLOT: "Time Slot",
  SUBMIT: "Submit",
  CHANNEL: "Channel",
  INFO: "INFO",
};
@Component({
  selector: "app-cas-appointment",
  templateUrl: "./cas-appointment.component.html",
  styleUrls: ["./cas-appointment.component.scss"],
})
export class CasAppointmentComponent implements OnInit {

  @observable _loading = 0;
  @computed get loading(){return this._loading};
  set loading(e) { this._loading = e > 0? e: 0; };

  TITLE = TITLE;
  headId;
  channel: string = "";
  queryPannel: any;
  timeslot;
  timeslotOption = [];
  daterange;
  selectionMode: "single" | "multiple" | "range" = "single";
  isValid;
  appointmentQuota;
  order
  selectedDate
  info = {
    title: 'CAS Appointment'
  }

  constructor(
    public stockCommonService: StockCommonService,
    public datePipe: DatePipe,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public router: Router,
    public stockOrderService: StockOrderService,
  ) {}

  ngOnInit(): void {
    // this.channelList = JSON.parse(localStorage.getItem("REPO"))
    this.initQueryData();
    this.route.queryParams.subscribe((params) => {
      if (params["headId"]) {
        this.loading++;
        this.headId = params["headId"];
        this.stockCommonService
          .getOrderByHeadId(params["headId"])
          .subscribe((res) => {
            if (res) {
              if(res.orderStatus == STATUSID.DRAFT){
                this.order = res
                this.channel = res.to;
                this.init();
                this.loadDefaultDate()
              }else{
                this.router.navigate(["main", "transfer_order", "create"], {queryParams: {headId: this.headId}});
              }
            }
          },
          (err)=>{
            this.loading--;
            this.router.navigate(["main", "transfer_order", "create"], {queryParams: {headId: this.headId}});
          },
          ()=>{this.loading--;});
      }
    });
  }

  init() {
    let channelIpt = this.queryPannel.ipts.find(
      (ipt) => ipt.title == TITLE.CHANNEL
    );
    channelIpt.value = this.channel;
  }

  initQueryData() {
    let now = new Date();
    this.queryPannel = {
      ipts: [
        {
          title: TITLE.CHANNEL,
          type: INPUT_TYPE.INPUT,
          value: this.channel,
          disabled: true,
          // class: "p-col-12 p-md-4 p-lg-3",
          class: "p-col-12 p-lg-6 p-lg-offset-3 p-col-order-1",
        },
        {
          title: TITLE.DATERANGE,
          minDate: new Date(now.setDate(now.getDate() + 1)),
          selectionMode: this.selectionMode,
          showTime: false,
          showSeconds: false,
          inline: true,
          value: this.daterange,
          inputId: "calendar",
          class: "p-col-12 p-lg-6 p-lg-offset-3 p-col-order-2",
          calendarClass: "p-d-block width-fit-content p-m-auto",
        },
        {
          title: TITLE.TIMESLOT,
          value: this.timeslot,
          options: this.timeslotOption,
          inputId: "value",
          class: "p-col-12 p-lg-8 p-lg-offset-2 p-col-order-3",
        },
        {
          title: TITLE.INFO,
          value: null,
          inputId: "value",
          show: false,
          class: "p-col-12 p-lg-3 p-col-order-4 p-lg-order-2",
        },
      ],
      btnsclass: "p-col-12 button-group p-d-flex p-col-order-5",
      btns: [
        {
          title: "Cancel",
          show: true,
          class: "p-button-sm p-button-outlined p-mr-1",
          handler: {
            click: () => {
              this.router.navigate(["main", "transfer_order", "create"], {queryParams: {headId: this.headId}});
            },
          },
        },
        {
          title: TITLE.SUBMIT,
          class: "p-button-sm",
          show: true,
          disabled: true,
          handler: {
            click: () => {
              this.submit();
            },
          },
        },
      ],
    };
  }

  change(idx, event, ipt) {
    if (ipt.title == TITLE.DATERANGE) {
      this.selectedDateChange(event);
    }
    if (ipt.title == TITLE.TIMESLOT) {
      this.timeslotChange(event);
    }
    this.isValid = this.validation();
    this.handleValidation();
  }

  validation() {
    return this.appointmentQuota && this.timeslot;
  }

  handleValidation() {
    let submitBtn = this.queryPannel.btns.find((btn) => btn.title == "Submit");
    if (this.isValid) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }

  updateTimeslotOptions(timeslotOption = this.timeslotOption) {
    let timeslotIpt = this.queryPannel.ipts.find(
      (ipt) => ipt.title == TITLE.TIMESLOT
    );
    timeslotIpt.options = timeslotOption;
  }

  selectedDateChange(e): void {
    let isValid: boolean = this.isDateValid(e);
    this.daterange = e;
    if (isValid) {
      let srvType = "REPLENISHMENT";
      let srcLoc = this.order.from;
      let loc = this.order.to;
      let prodType = 'CG';
      if(this.order.orderTypeId == ORDERTYPEID.RETURN){
        srvType = "RETURN";
        loc = this.order.from;
        srcLoc = this.order.to;
        if(CONFIG.CAS_APPOINTMENT_QUOTA_PRODTYPE_NOP_WAREHOUSE.includes(srcLoc)) {
          prodType = 'NOP';
        }else{
          prodType = 'MOBILE'
        }
      }
      let selectedDateString = this.datePipe.transform(e, 'yyyy-MM-ddT00:00:00');
      let params = {
        // location: this.channel, //--channel code (use YWS / MKK / YHO / COM for testing due to setting in CAS)
        prodType: prodType,
        srvType: srvType, // --pass "REPLENISHMENT" first
        urgent: "N", // --pass "N" first
        deptId: "", // --pass empty
        sourceLocation: srcLoc,
        location: loc,
        fromDate: selectedDateString,
        toDate: selectedDateString,
      };
      this.loading++;
      this.stockCommonService.getCasAppointmentTimeslot(params).subscribe(
        (res:any) => {
          if (res) {
            let data = res;
            this.appointmentQuota = res;
            if (data.quotaDateList) {
              let _timeslotOption = [];
              // limit timeslot only show first 2 only
              if(data.quotaDateList.length > 0){
                let quotaDateList = data.quotaDateList.filter((data,idx)=>{return idx<2})
                quotaDateList.forEach((quotaDate) => {
                  if (
                    quotaDate.quotaTimeList &&
                    quotaDate.quotaTimeList.length > 0
                  ) {
                    let timelist = quotaDate.quotaTimeList;
                    timelist.forEach((timeslot) => {
                      if (timeslot.quota > 0) {
                        let value = "";
                        // if(data.quotaDateList.length > 1){
                        value += `${this.datePipe.transform(
                          new Date(quotaDate.date),
                          "yyyy/MM/dd"
                        )} `;
                        // }
                        value += `${this.hourNumToHHmm(
                          timeslot.startTime
                        )} - ${this.hourNumToHHmm(timeslot.endTime)}`;
                        _timeslotOption.push({
                          value: value,
                          _timeslot: timeslot,
                          _date: quotaDate,
                        });
                      }
                    });
                  }
                });
              }
              this.timeslot = null;
              this.timeslotOption = _timeslotOption;
              this.updateTimeslotOptions(this.timeslotOption);
            }
          }
        },
        (err) => {
          // console.error(`get appointment quota error:`, err)
          this.showMessage("error", this.info.title, err);
          this.loading--;
        },
        ()=>{
          this.loading--;
        }
      );
    }
  }

  showMessage(severity, summary, detail) {
    // console.error(`trace showMessage: ${severity} | ${summary} | ${detail}`)
    this.messageService.add({ severity, summary, detail });
  }

  submit() {
    let apptDate = this.datePipe.transform(this.timeslot._date.date, 'yyyy-MM-ddT00:00:00');

    let srvType = "REPLENISHMENT";
    let srcLoc = this.order.from;
    let loc = this.order.to;
    let prodType = 'CG';
    if(this.order.orderTypeId == ORDERTYPEID.RETURN){
      srvType = "RETURN";
      loc = this.order.from;
      srcLoc = this.order.to;
      if(CONFIG.CAS_APPOINTMENT_QUOTA_PRODTYPE_NOP_WAREHOUSE.includes(srcLoc)) {
        prodType = 'NOP';
      }else{
        prodType = 'MOBILE'
      }
    }

    let data = {
      stockOrderId: Number.parseInt(this.headId),
      resourceArea: this.appointmentQuota.resourceArea, // --base on enquire appointment quota result
      mktSeg: this.appointmentQuota.mktSeg, // --base on enquire appointment quota result
      apptDate: apptDate, // --selected date
      timeslot: this.timeslot._timeslot.timeslot, // --timeslot from enquire appointment quota result
      sourceLocation: srcLoc, // --empty first
      location: loc,
      deptId: "", // --empty first
    };
    this.loading++;
    this.stockOrderService.checkBalanceReserve(this.headId).pipe(switchMap(res=>{
      if(res.code == '000'){
        if(res.data[0].canreserve=='true'){
          return this.stockCommonService.createCasAppointment(data)
        }else{
          throw `Balance not enough to reserve`
        }
      }else{
        throw res.msg
      }
    }),switchMap(res=>{
      if(res){
        this.showMessage("info", this.info.title, "Create Success");
        return this.stockCommonService.updateOrderStatus({
          "orderNumber": this.order.orderNumber,
          "operation": "ALLOCATED",
          "updateType": this.order.orderTypeId
        })
      }else{
        throw res.msg
      }
    })).subscribe(updateRes=>{
      if(updateRes){
        this.router.navigate(["main", "transfer_order", "create"], {queryParams: {headId: this.headId}});
        // this.loading--;
      }else{
        // this.loading--;
      }
    }, e=>{
      this.showMessage("error", this.info.title, e);
      this.loading--;
    },()=>{
      this.loading--;
    })
    // this.stockCommonService.createCasAppointment(data).subscribe(res=>{
    //   if(res){
    //     this.showMessage("info", "", "Create Success");
    //     // transfer order
    //     this.stockCommonService.updateOrderStatus({
    //       "orderNumber": this.order.orderNumber,
    //       "operation": "ALLOCATED",
    //       "updateType": this.order.orderTypeId
    //     }).subscribe(updateRes=>{
    //       if(updateRes){
    //         this.router.navigate(["main", "transfer_order", "create"], {queryParams: {headId: this.headId}});
    //         this.loading--;
    //       }else{
    //         this.loading--;
    //       }
    //     }, e=>{
    //       this.showMessage("error", "", e);
    //       this.loading--;
    //     })
    //   }else{
    //     this.loading--;
    //   }
    // }, e=>{
    //   this.showMessage("error", "", e);
    //   this.loading--;
    // })
  }

  isDateValid(e) {
    let valid = true;
    switch (this.selectionMode) {
      case "range":
        let [startDate, endDate] = e;
        if (!startDate || !endDate) valid = false;
        break;
      case "single":
      case "multiple":
      default:
        break;
    }
    return valid;
  }

  hourNumToHHmm(hour) {
    return `${("0" + hour).slice(-2)}:00`;
  }

  timeslotChange(e) {
    this.timeslot = e;
  }

  loadDefaultDate(){
    this.loading++;
    this.stockOrderService.getNextReplenishDate().subscribe((res:any)=>{
      let infoipt = this.queryPannel.ipts.find(ipt=>ipt.title==TITLE.INFO)
      if(res.code=='000'&&res.data.length>0&&res.data[0]){
        if(res.data[0]){
          let ipt = this.queryPannel.ipts.find(ipt=>ipt.title==TITLE.DATERANGE)
          if(ipt){
            ipt.value = res.data[0]?new Date(res.data[0]):null
            this.selectedDateChange(ipt.value)
          }
          if(infoipt){
            infoipt.value = `Next Replenishment Date: <br>${this.datePipe.transform(res.data[0], 'yyyy/MM/dd')}${res.data[1]?('<br>'+this.datePipe.transform(res.data[1], 'yyyy/MM/dd')):''}`
            infoipt.show = true;
          }
        }else{
          infoipt.value = '';
          infoipt.show = false;
        }
      }else{
        infoipt.value = '';
        infoipt.show = false;
      }
    },e=>{
      this.showMessage("error", this.info.title, e);
      this.loading--;
    },()=>{
      this.loading--;
    })
  }
}
