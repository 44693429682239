import { makeObservable, observable, action } from 'mobx';
import { Injectable } from "@angular/core";
import { CONFIG, INPUT_TYPE } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplenishmentStore } from './ReplenishmentStore';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DemandStore } from '../demand/DemandStore';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CommonStore } from '@/service/common/common-store-service';
import { CommonService } from '@/service/common/common-service';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';

@Injectable()
export class SearchStore extends ReplenishmentStore {
  breadcrumb = this.info.title
  subBreadcrumb = 'Search'
  toggleable = false
  parentData ={
    ipts: [
      {
        title: this.TITLE.DEMAND_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      },
       /* {
        title: this.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-2",
        disabled: true,
        value: null,
      }, */
       {
        title: this.TITLE.DEMAND_CYCLE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.TITLE.DEMAND_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.TITLE.DEMAND_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData = {
    ipts: [
      {
        title: this.QUERY_TITLE.CHANNEL,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-4",
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'id',
        error: false,
      }, {
        title: this.QUERY_TITLE.REFERENCE_NUMBER,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-4",
        value: null,
      }, {
        title: this.QUERY_TITLE.STATUS,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-4",
        value: null,
        options: [],
        optionLabel: 'label',
        optionValue: 'value',
      }
    ],
    btns: [
      {
        title: 'Back',
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.back() }
      },
      {
        title: this.BUTTON_TITLE.SEARCH,
        class: "p-order-3",
        show: true,
        handler: { click: () => this.searchFromFilter() }
      }, {
        title: this.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      },
      // {
      //   title: this.BUTTON_TITLE.CREATE_REPLENISHMENT,
      //   class: "p-order-2 p-mr-1",
      //   permissionType: 'c',
      //   show: false,
      //   disabled: false,
      //   handler: { click: () => this.create(["main",'demand_cycle','replenishment','create'], {queryParams: {id: this.demandData.demandCycleId}}) }
      // },
      {
        title: "Create Worksheet",
        class: "p-order-2 p-mr-1",
        permissionType: 'c',
        show: true,
        disabled: false,
        handler: { click: () => this.create(["main",'demand_cycle','replenishment','createspreadsheet'], {queryParams: {id: this.demandData.demandCycleId}}) }
      }
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12'
  }
  tableData = {
    btns: [
    ],
    data: [],
    columns: [
      {field: 'channelName', title: this.TABLE_FIELD.CHANNEL, width: '100px'},
      {field: 'referenceNumber', title: this.TABLE_FIELD.REFERENCE_NUMBER, width: '160px', type: 'select'},
      {field: 'buCode', title: this.TABLE_FIELD.BU_CODE, width: '100px'},
      {field: 'needByDate', title: this.TABLE_FIELD.NEED_BY_DATE, width: '130px', type: 'date'},
      {field: 'status', title: this.TABLE_FIELD.STATUS, width: '90px'},
      {field: 'remarks', title: this.TABLE_FIELD.REMARKS, width: '130px'},
      {field: 'updateAccountName', title: this.TABLE_FIELD.UPDATED_BY, width: '120px'},
      {field: 'updateAt', title: this.TABLE_FIELD.UPDATED_DATETIME, width: '160px', type: 'datetime'},
      {field: 'submitAccountName', title: this.TABLE_FIELD.SUBMITTED_BY, width: '130px', type: 'number'},
      {field: 'submitAt', title: this.TABLE_FIELD.SUBMITTED_DATETIME, width: '170px', type: 'datetime'},
      // {field: 'interface_date', title: this.TABLE_FIELD.SUBMITTED_DATETIME, width: '160px', type: 'datetime'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      {field: '', title: 'Action', width: '100px', type: 'action'},
    ],
    frozenWidth: '80px',
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "updateDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
    initSortField: 'updateDate',
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  }
  skuCode = []
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  cycleTypeIpt = null
  startDateIpt = null
  endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  itemIpt = null
  statusIpt = null
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  loading:boolean = false
  cloneLine = {}
  selected
  skus
  searchCache = {}
  resetSearch = false
  paginator = null
  
  get havePermissionCreateReplenishment(){
    return this.permissionService.havePermission(FUNCTION_CODE.REPLENISHMENT_CREATE)
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    service: StockDemandCycleService,
    msg: MessageService,
    private demandStore: DemandStore,
    conf: ConfirmationService,
    public commonStore: CommonStore,
    public commonService: CommonService,
    public permissionService: PermissionService,
  ) {
    super(router, route, service, msg, conf, commonService)
    makeObservable(this, {
      breadcrumb: observable,
      parentData: observable,
      queryData: observable,
      tableData: observable,
      skuCode: observable,
      cycleNameIpt: observable,
      poolTypeIpt: observable,
      cycleTypeIpt: observable,
      startDateIpt: observable,
      endDateIpt: observable,
      submissionDeadlineIpt: observable,
      channelIpt: observable,
      itemIpt: observable,
      search: action,
      save: action,
    })
    this.getPermission(route)
    this.tableData.selectedColumns = [...this.tableData.columns]
    this.initOriginIpt()
    this.initQueryIpt()
    this.loadDataByParams().subscribe(
      (res)=>{
        if(res.action){
          this.initdata(res); this.search(true);
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
    // this.initdata()
    // this.search(true)
  }
  get channelId() {
    return this.channelIpt.value
  }
  get itemsId() {
    return this.itemIpt.value
  }
  get status() {
    return this.statusIpt.value
  }
  get showU() {
    return this.permission?.includes('Create Replenishment')
  }
  get access() {
    return false
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title === 'Search' && (item.disabled = !this.permission.includes("Create Replenishment"))
      item.title === 'Create' && (item.disabled = !this.permission.includes("Create Replenishment"))
      item.title === 'Create Worksheet' && (item.disabled = !this.permission.includes("Create Replenishment"))
    })
  }
  getOriginByTitle(title){
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  getBtnByTitle(title){
    return this.queryData.btns.find(item => item.title === title)
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_NAME)
    // this.poolTypeIpt = this.getOriginByTitle(this.TITLE.STOCK_POOL_TYPE)
    this.cycleTypeIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_START_DATE)
    // this.endDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_END_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.QUERY_TITLE.CHANNEL)
    this.itemIpt = this.getQueryByTitle(this.QUERY_TITLE.REFERENCE_NUMBER)
    this.statusIpt = this.getQueryByTitle(this.QUERY_TITLE.STATUS)
    const status = LocalStorageHelper.getObject('REPLENISHMENT_STATUS')
    this.statusIpt.options = status
  }
  initdata(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))) {
    this.demandData = demandData
    const list = LocalStorageHelper.getObject('POOL_TYPE')
    // const poolType = list.find(item =>  item.id === this.demandData.sdl_stock_pool_type_id);

    this.cycleNameIpt.value = this.demandData.demandCycleName
    // this.poolTypeIpt.value = poolType.name
    this.cycleTypeIpt.value = this.demandData.demandCycleType
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    // this.endDateIpt.value =  new Date(this.demandData.demandEndDate)
    this.submissionDeadlineIpt.value = this.demandData.submissionDeadline ? new Date(this.demandData.submissionDeadline) : null
    // const btn = this.getBtnByTitle(this.BUTTON_TITLE.CREATE_REPLENISHMENT)
    // btn.disabled = this.demandData.gtEnd
    this.setInitSearchData()
  }
  setInitSearchData() {
    const channels = LocalStorageHelper.getObject('WAREHOUSE')
    // this.channelIpt.options = [{ data: 0, label: "ALL", expanded: true, children: channels }]
    this.channelIpt.options = channels

    const skuList = LocalStorageHelper.getObject('SKU')
    this.itemIpt.options = skuList
    this.skuCode = skuList
    this.skus = skuList
  }
  save(data) {
    this.loading = true
    this.tableData.loadingSwitch = true
    const saveData = JSON.parse(JSON.stringify(data))
    delete saveData.updateBy
    delete saveData.createBy
    delete saveData.action
    delete saveData.edit
    this.service.demandEdit(data).subscribe(res => {
      data.edit = false
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title,  this.info.success.replace('Created', 'Update'))
        this.search()
      } else {
        this.showMessage('warn', this.info.title,  this.info.fail.replace('create', 'Update'))
      }
    })
  }
  cancel(data, index: number) {
    const currrentQty = data.qtyRequest
    const oldQty = this.cloneLine[data.id].qtyRequest
    const params = { data, index }
    if(currrentQty !== oldQty){
      this.restoreData('confirmCancel', this, params, this.info.noChange)
    } else {
      this.confirmCancel(params)
    }
  }
  confirmCancel(params) {
    const {data, index} = params
    data.edit = false
    this.tableData.data[index].qtyRequest = this.cloneLine[data.id].qtyRequest;
    delete this.cloneLine[data.id];
  }
  openDet(data) {
    // console.log('open replenishment detail data:', data);
    sessionStorage.setItem('DEMAND_LINE', JSON.stringify(data))
    this.router.navigate(["main", 'demand_cycle', 'replenishment', 'detail'], {queryParams:{id:data.demandCycleId,replenishId:data.id}})
  }
  deleteRow(event, data) {
    event.stopPropagation()
    // console.log('are you delete replenishment data:', data);
    this.restoreData('confirmDelete', this, data, this.info.delete)
  }
  confirmDelete(data){
    // console.log('confirm delete replenishment data:', data);
    this.loading = true
    this.tableData.loadingSwitch = true
    const ids = [data.id]
    this.service.replenishmentDelete({ids}).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Deleted'))
        this.clear(true)
      } else {
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Delete'))
      }
    })
  }
  back() {
    // window.history.back()
    // this.router.navigate(['main/demand_cycle/search'])
    let searchParams = this.commonStore.getObject(CONFIG.SEARCHPARAMSKEY.DEMANDCYCLE)
    if(!searchParams){searchParams = {}}
    this.router.navigate(["main/demand_cycle/search"], {queryParams: {...searchParams}})
  }

  create(url,options={}) {
    this.router.navigate(url,options)
  }

  clear(isDelete=false) {
    this.queryData.ipts.forEach(item => {
      item.value = null
    })
    if (isDelete) {
      this.search()
    }
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  search(comeFrom = false) {
    this.loading = true
    this.tableData.loadingSwitch = true
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      demandCycleId: this.demandData.demandCycleId,
      accountName: this.account,
      ...(this.channelId && {channelId: this.channelId}),
      ...(this.itemsId && {referenceNumber: this.itemsId}),
      ...(this.status && {sdlReplenishStatusId: this.status})
    }
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }
    this.service.replenishmentSearch(data).subscribe(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
      let list = []
      if (res.code === '000') {
        const currentTime = new Date().getTime()
        res.data.forEach(item => {
          /* const num = this.comparisonDate(this.demandData.submissionDeadline, currentTime)
          item.action = num <= 0 ? true : false */
          const start = this.comparisonDate(currentTime, this.demandData.demandStartDate)
          const deadline = this.comparisonDate(currentTime, this.demandData.submissionDeadline)
          // const end = this.comparisonDate(currentTime, this.demandData.demandEndDate)
          item.ltStart = start < 0 ? true : false
          // item.gtEnd = end > 0 ? true : false
          item.action = deadline <= 0 ? true : false
        });
        list = res.data
        this.tableData.totalRecords = res.msg
      } else {
        if (res.code) {
          this.showMessage('error', this.info.title, res.msg)
        } else {
          this.showMessage('error', this.info.title, res.message)
        }
      }
      this.tableData.data = list
    })
  }
  sortSearch(field) {
    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
    this.search()
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search(this.resetSearch ? true : false );
    this.resetSearch = false
  }
  stopEvent(event){
    event.stopPropagation()
  }
  tableSelectedColumnsChange(e){
    this.tableData.selectedColumns = this.tableData.columns.filter(col => e.includes(col));
  }
  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
}
