import { Injectable } from "@angular/core";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService } from "primeng/api";
import { of, throwError } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { StockDemandCycleService } from "src/app/service/stock/stock-demand-cycle.service";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { DemandCycleStore } from "../demandCycle/DemandCycleStore";
import { CommonService } from "@/service/common/common-service";

@Injectable({providedIn: "root"})
export class DemandStore {
  TITLE = {
    FORECAST_CYCLE_NAME: "Demand Cycle Name",
    STOCK_POOL_TYPE: "Stock Pool Type",
    FORECAST_START_DATE: "Tentative Delivery Date",
    FORECAST_END_DATE: "Demand End Date",
    SUBMISSION_DEADLINE: "Demand Deadline",
    CHANNEL_ID: "Channel Id",
    ITEM_NUMBER: "Item Code"
  }
  QUERY_TITLE = {
    CHANNEL_ID : 'Channel ID',
    ITEM_NUMBER: 'Item Code',
    DESCRIPTION: 'Description',
    UOM: 'UOM',
    AVAILABLE_QTY: 'Available Qty',
    TRANSIT_QTY: 'Transit Qty',
    PAST_7_DAYS_SALES: 'Past 7 days Sales',
    DATE_OF_COVER: 'Days of Cover',
    REQUEST_QTY: 'Request Qty',
    UPDATED_BY: 'Updated By',
    UPDATED_DATETIME: 'Updated Datetime',
    ACKNOWLEAGE: 'Acknowleage?',
  }
  info = {
    title: "Demand",
    delete: "confirm to delete?",
    required: "channel or item is required!",
    lineRequired: "DemandLine is required!",
    requestQtyRequired: "Request qty cannot be empty and must be > 0!",
    notdata: "The selected channel does not have an item!",
    exist: "The same data already exists!",
    success: "Created successfully!",
    fail: "Failed to create data!",
    cancel: "Are you sure to delete this demandline?",
    gt: "date1 must be greater than date2!",
    lt: "date1 must be earlier than date2!",
    noChange: "Do you want to discard this modification?"
  }
  account = this.commonService.getAccount()
  constructor(
    private msg: MessageService,
    private conf: ConfirmationService,
    private service: StockDemandCycleService,
    private common: DemandCycleStore,
    public commonService: CommonService,
  ) {
    makeAutoObservable(this)
  }
  getResolverData(route) {
    route.data.pipe(pluck('query')).subscribe(res => {
      let list = []
      if(!res.local && res[0].code === '000' ) {
        res[0].data.forEach(item => {
          list.push({id:item.id, code: item.code, name: item.description})
        })
        LocalStorageHelper.setObject('POOL_TYPE', list)
      }
    })
  }
  comparisonDate(timeStamp1, timeStamp2) {
    const dayValue = 24 * 60 * 60 * 1000
    return Math.floor((timeStamp1 - timeStamp2) / dayValue)
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
  restoreData(fun, self, data, msg=this.info.cancel) {
    this.conf.confirm({
      message: msg,
      header: this.info.title,
      accept: () => {
        self[fun](data)
      }
    });
  }
  
  searchDemandCycleByIdAndPreProcess(id) {
    // share function between page under demand cycle
    return this.service.search({demandCycleId: [id]}).pipe(map(res=>{
      if(res.code=='000' && res.data){
        let item = res.data[0];
        const currentTime = new Date().getTime();
        const overdueDay = this.common.comparisonDate( currentTime,item.demandStartDate,  );
        item.overdue = overdueDay > 0 ? true : false
        const deadline = this.common.comparisonDate( item.submissionDeadline, currentTime );
        item.action = deadline < 0 ? true : false;
        item.demandCycleType = item.demandCycleType || "Channel"
        const selItem = LocalStorageHelper.getObject('DEMAND_CYCLE_TYPE').find(ct => ct.typeName === item.demandCycleType)
        item.hasDemand = selItem.hvDemand === 'Y' ? true : false
        item.hasByBUItem = selItem.hvByBuItem === 'Y' ? true : false
        item.hasSchoolData = selItem.hvSchoolData === 'Y' ? true : false

        sessionStorage.setItem("DEMAND", JSON.stringify(item));

        return item;
      }else{
        throw null
      }
    }))
  }

  loadDataByParams(route){
    if(route.snapshot.queryParams && route.snapshot.queryParams['id']){
      let id = route.snapshot.queryParams['id'];
      let sessionDemandData = JSON.parse(sessionStorage.getItem("DEMAND"))
      if(sessionDemandData&&sessionDemandData.demandCycleId.toString()==id){
        // session data id is same, skip api call 
        return of(sessionDemandData)
      }else{
        // return throwError(null);
        return this.searchDemandCycleByIdAndPreProcess(id)
      }
    }else{
      return throwError(null);
    }
  }

  searchDemandLineByIdAndPreProcess(cycleId, replenishId, demandData) {
    // share function between page under demand cycle
    return this.service.replenishmentSearch({
      accountName: this.commonService.getAccount(),
      pageIndex: 0,
      pageSize: 20,
      demandCycleId: cycleId,
      replenishId: replenishId,
      sortEvent: {field: "updateDate", order: -1}
    }).pipe(map(res => {
      let list = []
      if (res.code === '000' && res.data) {
        const currentTime = new Date().getTime()
        let item = res.data[0]
        const start = this.comparisonDate(currentTime, demandData.demandStartDate)
        const deadline = this.comparisonDate(currentTime, demandData.submissionDeadline)
        item.ltStart = start < 0 ? true : false
        item.action = deadline <= 0 ? true : false
        sessionStorage.setItem("DEMAND_LINE", JSON.stringify(item));
        return item
      }else{
        throw null
      }
    }))
  }
  
  loadDemandLineByParams(route, demandData, force = false){
    if(route.snapshot.queryParams && route.snapshot.queryParams['id'] && route.snapshot.queryParams['replenishId']){
      let id = Number.parseInt(route.snapshot.queryParams['id']);
      let replenishId = route.snapshot.queryParams['replenishId'];
      let sessionDemandLineData = JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
      if(sessionDemandLineData&&sessionDemandLineData.id==replenishId && !force && !sessionDemandLineData.isLisSubmitted && !sessionDemandLineData.isLisSubmitted){
        // session data id is same, skip api call 
        return of(sessionDemandLineData)
      }else{
        // return throwError(null);
        return this.searchDemandLineByIdAndPreProcess(id, replenishId, demandData)
      }
    }else{
      return throwError(null);
    }
  }
}
