import { Component, ViewChild } from '@angular/core';
import { NgxFileDropComponent } from 'ngx-file-drop';
import { CreateStore } from 'src/app/stores/stock/take/takeCreateStore';
@Component({
  selector: 'app-create',
  providers: [CreateStore],
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {
  @ViewChild('fileDropper') fileDropper: NgxFileDropComponent;
  constructor(public store: CreateStore) {
  }

  ngAfterViewInit(){
    this.store.fileDropper = this.fileDropper;
  }
}
