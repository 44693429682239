import { HttpHelper } from '../../util/HttpHelper'
import { URLDICT, NOTI_MSG, NOTI_TITLE, CONFIG} from '../../config'
import { LocalStorageHelper } from "../../util/LocalStorageHelper";
import { action } from "mobx";
import { TablePannelStore } from './TablePannelStore';
import { CommonStore } from '@/service/common/common-store-service';
import { Inject, Injector } from '@angular/core';
/**
 * define the type of input for store to use
 */
enum INPUT_TYPE {
  INPUT = 0,
  SELECT = 1,
  DATETIME = 2,
  TREESELECT = 3,
  TEXTAREA = 4,
  TREE = 5,
  DATERANGE = 6,
  DESCRIPTIONS = 7,
  HIDDENTIME = 8,
  AUTOCOMPLETE = 9,
  MUTIPLESELECT = 10,
  INPUTNUMBER = 11,
  SELECTWITHDESC = 12,
  SELECTWITHTABLEMUTI=13,
  DYNAMICFORM = 14,
  CHECKBOX = 15,
  HEADLINE = 16,
  SELECTDISPLAYINPUT = 17,
  SWITCH = 18,
  TABLE = 19,
  SELECTGROUP = 20,
  MUTIPLETREESELECT = 21,
  RADIO = 22,
  INPUTSCAN = 23,
  DIVIDER = 24,
  FIELDSET = 25,
  INPUTLIMIT = 26,
  EMPTY = 27,
}

enum VALIDATE_TYPE{
    /**
     * 邮箱
     */
    EMAIL,
    /**
     * 全数字
     */
    ALLNUMBER,
    /**
     * 自定义
     */
    CUSTOM,
    /**
     * 默认
     */
    DEFAULT,
    /**
     * 正数金额，识别两位小数
     */
    POSITIVEMONENY,
    /**
     * 英文和数字
     */
    ENGANDNUM,

    /**
     * 正负整数，正负小数
     */
    POSIORNEGANUMBER,
  /**
   * 正整数
   */
    POSITIVEINTEGER
}


/**
 * define the type of button for store to use
 */
enum BUTTON_TYPE {
  BUTTON,
  TOPBUTTON
}

enum STEP_TYPE{
  REMARK,
  PRODUCT,
  VERIFY
}

const BUTTON_WRAPPER = 'wrapper'
/**
 *
 */
export class DEFAULTVALUE {
  static INPUT_DEFAULT = null
  static SELECT_DEFAULT = null
  static TEXTAREA_DEFAULT = ''
  static TREESELECT_DEFAULT = null
  static DATERANGE_DEFAULT = null
  static AUTOCOMPLETE_DEFAULT = null
  static MUTIPLESELECT_DEFAULT = null
  static INPUTNUMBER_DEFAULT = null
  static SELECTWITHDESC_DEFAULT = null
  static SELECTWITHTABLEMUTI_DEFAULT = []
}
/**
 * define the status of input
 */
enum INPUT_STATUS {
  DISABLE = 0,
  READONLY = 1,
  // NONEDISPLAY = 2,
}
/**
 *
 */
let DEFAULT_INPUT_OBJECT = {
  "0": {
    title: "XXX",
    name: 'XXX',
    placeholder: '',
    value: "",
    defaultValue: '!!',
    type: INPUT_TYPE.INPUT,
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "1": {
    title: "XXX",
    name: 'XXX',
    placeholder: 'Choose',
    value: null,
    type: INPUT_TYPE.SELECT,
    showSearch:true,
    disabled:false,
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "6": {
    title: "XXX",
    name: 'XXX',
    placeholder: 'Choose',
    value: null,
    type: INPUT_TYPE.DATERANGE,
    format: "yyyy-MM-dd",
    showTime: null,
    showSearch:true,
    disabled:false,
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "8": {
    name: "Date",
    value: new Date().getTime(),
    type: INPUT_TYPE.HIDDENTIME,
    colon:true
  },
  "3": {
    title: "XXX",
    name: 'XXX',
    placeholder: 'Choose',
    type: INPUT_TYPE.TREESELECT,
    value: "",
    multiple: false,
    checkable: false,
    showSearch: false,
    allowClear: false,
    defaultExpandAll: false,
    expandedKeys: ["0"],
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "4": {
    title: "XXX",
    name: 'XXX',
    placeholder: '~~~~',
    value: "",
    required: false,
    type: INPUT_TYPE.TEXTAREA
  },
  "9": {
    title: "XXX",
    name: 'XXX',
    placeholder: '~~~',
    value: "",
    type: INPUT_TYPE.AUTOCOMPLETE,
    // autocomplete: "auto", auto是NzAutocompleteComponent类型
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "10": {
    title: "XXX",
    name: 'XXX',
    placeholder: 'Choose',
    value: null,
    type: INPUT_TYPE.MUTIPLESELECT,
    selectMode:'multiple',
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "11": {
    title: "XXX",
    name: 'XXX',
    placeholder: 'Input Number',
    value: null,
    step:1,
    min:-99999999,
    max:99999999,
    type: INPUT_TYPE.INPUTNUMBER,
    required: false,
    validate: [{
      type: VALIDATE_TYPE.DEFAULT
    }]
  },
  "12":
    {
    name: 'XXX',
    value: "",
    type: INPUT_TYPE.SELECTWITHDESC,
    required: false,
  },
  "13":{
    value: "",
    tableMultiData: [],
    type: INPUT_TYPE.SELECTWITHTABLEMUTI,
    required: false,
    validate: [{
    type: VALIDATE_TYPE.DEFAULT
  }]
},
"15": {
      title: "XXX",
      name: 'XXX',
      value: "",
      required: false,
      type: INPUT_TYPE.CHECKBOX
    },
"17": {
  title: "XXX",
  name: 'XXX',
  placeholder: 'Choose',
  value: null,
  type: INPUT_TYPE.SELECTDISPLAYINPUT,
  showSearch:true,
  disabled:false,
  required: false,
  inputDisplay:null,
  validate: [{
    type: VALIDATE_TYPE.DEFAULT
  }]
},"18": {
  title: "XXX",
  name: 'XXX',
  value: false,
  required: false,
  type: INPUT_TYPE.SWITCH
},"22": {
  title: "XXX",
    name: 'XXX',
    value: "",
    required: false,
    type: INPUT_TYPE.RADIO
},

}
let DEFAULT_BUTTON_OBJECT = {
  "0": {
    type: BUTTON_TYPE.BUTTON,
    title: 'XXX',
    loading: false,
    category: 'default',
    handler: {}
  },
  "1": {
    type: BUTTON_TYPE.TOPBUTTON,
    title: 'XXX',
    loading: false,
    category: 'default',
    handler: {}
  }
}
/**
 * 输入验证的正则表达式和提示信息
 */
let REGEXUTIL = {
  "0": {
    REGEX: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    MSG: "Please enter the correct email in "
  },
  "1": {
    REGEX: /^[\d]*$/,
    MSG: "Please enter number in "
  },
  "4": {
    REGEX: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/,
    MSG: "Please enter the correct moneny in "
  },
  "5": {
    REGEX: /^[A-Za-z0-9]+$/,
    MSG: "Enter only English and numbers in "
  },
  "6": {
    REGEX: /^(-)?\d+(\.\d+)?$/,
    MSG: "Enter only positive and negative numbers in"
  },
  "7":{
    REGEX:/^[1-9]\d*$/,
    MSG:"Enter only positive integer"
  }
}

let DEFAULT_STEPS_OBJECT = {
  "0":{
    stepDirection:"horizontal",
    step: [{
    title: "Remark",
    desc: "",
    active: 'process',
}, {
    title: "What Products",
    desc: "",
    active: 'wait',
}, {
    title: "Verify",
    desc: "",
    active: 'wait',
}]
},
    "1":{
      stepDirection:"horizontal",
      step: [{
      title: "Remark",
      desc: "",
      active: 'finish',
  }, {
      title: "What Products",
      desc: "",
      active: 'process',
  }, {
      title: "Verify",
      desc: "",
      active: 'wait',
  }]
  },
    "2":{
      stepDirection:"horizontal",
      step: [{
      title: "Remark",
      desc: "",
      active: 'finish',
  }, {
      title: "What Products",
      desc: "",
      active: 'finish',
  }, {
      title: "Verify",
      desc: "",
      active: 'process',
  }]
  }

}

export class BaseStore {
  $NOTICE = null     // 提示模块
  $ROUTER = null     // 路由模块
  $PARAMS = null     // 路由传参模块
  $MODAL = null      // 模态框模块
  $URLDICT = URLDICT // URL字典
  $CONFIG = CONFIG
  $HTTP = HttpHelper // HTTP模块
  $CACHE = LocalStorageHelper //缓存模块
  $COMMONSTORE:CommonStore = null;
  constructor(
    params,
    // @Inject(Injector) private Injector,
  ) {
    if (params == null) return
    this.$NOTICE = params["n"]
    this.$ROUTER = params["r"]
    this.$PARAMS = params["p"]
    this.$MODAL = params['m']
    this.$COMMONSTORE = params['commonStore']
  }

  /**
   *
   * @param ipts data
   */
  protected init_ipts(ipts) {
    return ipts.map(ipt => {
      // find id of input,it should be disabled
      if (ipt.name == 'id') {
        ipt.disabled = true
      }
      //处理二维动态表单初始化
      if (ipt.type==14) {
        if(ipt.editFlag||this.$PARAMS.queryParams.value['existVersion']){
          // console.log(this.$PARAMS.queryParams);
          ipt.ipts =  this.init_dynamicForm_ipts_edit(ipt.ipts)
        }else{
          ipt.ipts =  this.init_dynamicForm_ipts(ipt.ipts)
        }
      }
      //table类型数据初始化
      if(ipt.type==19){
        ipt = this.initTable(ipt);
        console.log("init table data")
      }
      //处理验证
      this.handleInputValidate(ipt)
      //处理默认属性
      ipt = this.handleDefaultProperty(DEFAULT_INPUT_OBJECT[ipt.type], ipt)
      if(ipt.isLinkageOther === true) {
        this.initOtherValue(ipt,ipts)
      }

      //
      if(ipt.adjustFields&&ipt.adjustFields instanceof Array){
        this.adjustIptFiled(ipt)
      }
      //处理Option值绑定
      this.handleInputOption(ipt)

      //处理传参值绑定
      this.handleInputValue(ipt)
      return ipt
    })
  }

  private initTable(ipt: any) {
    ipt = new TablePannelStore(ipt);
    // ipt.colon = true
    return ipt;
  }

  init_tp(tp){
    let options = tp.head.map(col => {
      return {value:col.key,label:col.title}
    });
    tp.showColumn = {
      title: "Show Column",
      name: 'showColumn',
      placeholder: 'Choose Display Columns',
      value: null,
      selectMode:'multiple',
      options
    }
    return tp
  }

  protected init_eidtTable(ip){
    let temp = {
      cols:[],
      lineCols:[],
      btns:[]
    }
    temp.cols = ip.cols.map(col=>{return {name:col.name,title:col.title}})
    if(ip.lineCols && ip.lineCols.length>0){
      temp.lineCols = ip.lineCols.map(col=>{return {name:col.name,title:col.title}})
    }
    temp.btns = this.init_btns(ip.btns)
    return temp
  }

  protected init_editNewData(data){
    let newData = this.init_ipts(data)
    return newData
  }
   /**
   * 页面时间显示处理
   * @param store data
   */
  @action convertDateToTable(res) {
    if(res.data && res.data != null){
      res.data.forEach(d=>{
        d.createTime = d.createAt
        if(d.createAt && d.createAt != null && d.createAt != 0){
          d.createAt = this.convertDate(d.createAt)
          d.createAtNoTime = d.createAt.substr(9)
        }else{
          d.createAtNoTime = ""
        }
        if(d.updateAt && d.updateAt != null && d.updateAt != 0){
          d.updateAt = this.convertDate(d.updateAt)
        }
      })
    }
  }

  initOtherValue(ipt,ipts) {
    if(ipt.options instanceof Array){
      if (this.$PARAMS) var param = this.$PARAMS.queryParams.value
      ipts.forEach(input =>{
        if(input.valueUse){
          if(param[ipt.name] == input.valueUse){
            input.disabled=false
            input.required=true
          } else {
            input.value=''
            input.disabled=true
            input.required=false
          }
        }
      } )
    }
  }

  adjustIptFiled(ipt) {
    if (this.$PARAMS) {
      let param = this.$PARAMS.queryParams.value
      ipt.adjustFields.forEach(field =>{
          let key = field.key
          ipt[key] = param[field.valuePointer] === 0 ? false : true
      })
    }
    return ipt
  }

    /**
   * 二维动态表单初始化
   * @param ipts data
   */
  protected  init_dynamicForm_ipts(ipts){
    return ipts.map(arr=>{
      return arr.map(ipt=>{
        //处理验证
      this.handleInputValidate(ipt)
      //处理默认属性
      ipt = this.handleDefaultProperty(DEFAULT_INPUT_OBJECT[ipt.type], ipt)

      //处理Option值绑定
      if(!ipt.editFlag){
        this.handleInputOption(ipt)
      }
      //处理传参值绑定
      this.handleInputValue(ipt)
      return ipt
      })
    })

  }


  /**
   * 二维动态表单EDIT初始化
   * @param ipts data
   * @param url url
   */
  protected init_dynamicForm_ipts_edit(ipts) {
    ipts = this.$CACHE.getObject("/DYN/IPTS")
    return this.init_dynamicForm_ipts(ipts)
  }
  /**
   *
   * @param btns data
   */
  protected init_btns(btns) {
    return btns.map(btn => {
      return this.handleDefaultProperty(DEFAULT_BUTTON_OBJECT[btn.type], btn)
    })
  }

  protected init_steps(steps){
    return DEFAULT_STEPS_OBJECT[steps]
  }

  /**
   * 处理传参值绑定
   * @param ipt data
   */
  protected handleInputValue(ipt) {
    // check wether add or edit, for edit must setup the valu
    if (this.$PARAMS) {
      let param = this.$PARAMS.queryParams.value
      if (param[ipt.name]) {
        if(ipt.type === INPUT_TYPE.DATETIME) {
          ipt.value = parseInt(param[ipt.name], 10)
        } else if (ipt.type === INPUT_TYPE.SELECT) {
          ipt.value = ipt.title === 'UOM' ? ipt.options.find(item => item.code === param[ipt.name])
            : ipt.options.find(item => item.code === +param[ipt.name])?.code
        } else {
          if(ipt.type === INPUT_TYPE.TREESELECT) {
            ipt.showValue = param.repoCode
          }
          ipt.value = param[ipt.name]
        }
      }
    }
    return ipt
  }
  setContenOfOptions(oName, ipt) {
    let result
    if(['REPOTREE', 'REPOTREE_ACTIVE', 'REPOMODULEBYUAMTREE'].includes(oName)){
      const items = this.$CACHE.getObject(ipt['options']).filter(repo=>repo.children.length)
      result = ipt.expandKeys ? [{ data: 0, label: "ALL", expanded: true, children: items }] : items
    } else if(oName.includes("GROUP")) {
      result = this.$CACHE.getObject(ipt['options'])
    } else {
      result = this.$CACHE.getObject(ipt['options']).map((item) => {
        let { label: name, value: code, ...other } = item
        return {name, code, ...other}
      });
    }
    return result
  }
  /**
   * 处理option值绑定
   * @param ipt data
   */
  private handleInputOption(ipt) {
    if (ipt.type === INPUT_TYPE.SELECT || ipt.type === INPUT_TYPE.TREESELECT || ipt.type === INPUT_TYPE.MUTIPLESELECT
      || ipt.type === INPUT_TYPE.MUTIPLETREESELECT || ipt.type === INPUT_TYPE.AUTOCOMPLETE
      || ipt.type === INPUT_TYPE.SELECTGROUP || ipt === INPUT_TYPE.SELECTDISPLAYINPUT) {
      if (ipt['options'] && !(ipt['options'] instanceof Array)) {
        ipt['options'] = this.setContenOfOptions(ipt['options'], ipt)
        if (ipt['addtionOption']) {
          ipt.options.splice(0, 0, ipt['addtionOption'])
        }
      }
    }
    ipt.options = ipt.options instanceof Array ? ipt.options : []

    return ipt
  }
  private getDifferentDate(arr) {
    const list = []
    arr.forEach(({id:value, description:label}) => list.push({value, label}))
    return list
  }
  handleInputHiddenTime(ipt) {
    if (ipt.type === INPUT_TYPE.HIDDENTIME) {
      ipt["Date"] = new Date().getTime()
    }
  }

  /**
  * 处理默认属性
  * @param obj1  DEFAULT
  * @param obj2 data
  */
  protected handleDefaultProperty(obj1, obj2) {
    let obj3 = {}
    Object.assign(obj3, obj1, obj2);
    return obj3
  }

  /**
   * 设置validate的验证规则和提示信息
   * @param obj data
   */
  private handleInputValidate(obj) {
    if (obj.validate instanceof Array) {
      obj.validate.forEach(val => {
        //val.type: 2 是自定义类型 3是默认类型
        if (val.type != 2 && val.type != 3) {
          val.regex = REGEXUTIL[val.type].REGEX
          val.msg = REGEXUTIL[val.type].MSG + obj.title
        }
      })
    }
  }
  @action arrayToTree(list, pid = 0) {
    return list.filter(item => item.pid === pid).map(item => ({
      key: item.label, ...item,
      children: this.arrayToTree(list, item.id),
    }));
  }
  @action arrayToGroup(list, pid = 0) {
    return list.filter(item => item.pid === pid).map(item => ({
      ...item,
      items: this.arrayToGroup(list, item.id),
    }));
  }
  protected toTreeDataOfPrimeUI(list, tableData) {
    if(tableData.type === 'tree') {
      let { id, pid } = tableData.treeKey
      let map = {}; //构建map
      let treeData = [];
      if(list) {
        const tempList = JSON.parse(JSON.stringify(list))
        tempList.forEach(child => {
          delete child.children;
          map[child[id]] = {data: child}; //id为键 当前数据为值

          const mapItem = map[child[pid]]; //判断当前数据的parentId是否存在map中
          if (mapItem) {
            mapItem.children = mapItem.children || [];
            mapItem.children.push(map[child[id]])
          }
        })
      }

      for (const i in map) {
        if(map[i].data[pid] === 0) {
          treeData.push(map[i]);
        }
      }
      return treeData;
    }else {
      return list
    }
  }
  /**
   *
   * @param dataList data
   * @param t data
   */
  protected toTreeData(dataList, t) {
      if(t.type == 'tree'){
        let id = t.treeKey.id
        let pid = t.treeKey.pid
        let map = {}; //构建map
        let treeData = [];
        if(dataList){
          dataList.forEach(i => {
            delete i.children;
            map[i[id]] = i; //id为键 当前数据为值
          });

          dataList.forEach(child => {
            const mapItem = map[child[pid]]; //判断当前数据的parentId是否存在map中
            if (mapItem) {

              if(mapItem.children){
                mapItem.children.push(child)
              }else{
                mapItem.children = [];
                mapItem.children.push(child)
              }
            } else {
              //不存在则是顶层数据
              treeData.push(child);
            }
          });
        }
        return treeData;
      }else {
        return dataList
      }
  }
  /**
   *
   * @param routerRule data
   */
  redict(routerRule) {
    this.$ROUTER.navigate(routerRule)
  }

  /**
   *
   * @param dataList data
   * @param t data
   */
  protected toTreeSelect(dataList, topPid = 0) {

    let map = {};

    //构建map
    dataList.forEach(item => {
      item.children = []
      item.isLeaf = true
      map[item.id] = item;
    });

    //标记非叶子节点
    dataList.forEach(child => {
        if (map[child.pid]) {
          map[child.pid].isLeaf = false
        }
      });
    let treeData = [];

    //数据转换方式
    const translate = (obj) => {
      return { key: obj.id + "", value: obj.id + "", title: obj.name, children: obj.children, isLeaf: obj.isLeaf }
    }

    //收集子节点
    dataList.forEach(child => {
      if (map[child.pid]) {
        map[child.pid].children.push(translate(child))
        // map[child.pid].isLeaf = false
      }
    });
    //根据topPid获取最顶级节点
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        const element = map[key];
        if (element.id == topPid)
          treeData.push(translate(element))
      }
    }
    console.log(treeData)
    return treeData;
  }

  toCacheArr(data){
    let arrCache = []
    const translate = (obj) => {
      return { value: obj.id + "",label: obj.name,other:obj.pid }
    }
    data.forEach(item=>{
      arrCache.push(translate(item))
    })
    return arrCache
  }

  toGeneralCache(list){
    // {"id":92,"pid":10,"name":"YWS","other":"S","isClosed":"N"}
    return list.map((item) => {
      const {id: data, name: label, id, ...other } = item
      return {label, data, id, ...other}
    });
  }

  /**
   * 加载数据到缓存
   * @param url API路由
   * @param key 缓存key
   */
  loadToCache(url, key) {
    if (key === "ORDER_TYPE" || key === "REASONS" || key === "TRANSFER_CONFIRM") {
      return this.$HTTP.post(url, {}).then(res => {
        if(res.code === "000"){
          if(key === "ORDER_TYPE"){
            const typeList = res.data.map((item) => {
              const {id: value, description: label, id, description, ...other } = item
              return {value, label, id, description, ...other}
            });
            this.$CACHE.setObject(key, typeList)
          }
          if(key === "REASONS" || key === "TRANSFER_CONFIRM"){
            const reasons = res.data[0]
            this.$CACHE.setObject(key, reasons)
          }
        }
      })
    } else {
      return this.$HTTP.get(url).then(res => {
        switch (key) {
          case "STOCK_TYPE":
            let arr = res.data.filter(item => item.value <= 3);
            this.$CACHE.setObject("/STOCK/CATEGORY/STOCK_TYPE", arr)
            this.$CACHE.setObject(key, res.data)
            break;
          case "ALL_STATUS":
            res.data =  res.data.map((item) => {
              const {label:alias, other:label, ...other } = item
              return {label, alias, ...other}
            });
            const transferList = res.data.filter(item => item.flag === 'STOCK_TRANSFER_STATUS')
            this.$CACHE.setObject('TRANSFER_STATUS', transferList)
            const interFaceList = res.data.filter(item => item.flag === 'LIS_INTERFACE_STATUS')
            this.$CACHE.setObject('INTERFACE_STATUS', interFaceList)
            // const allocationList = res.data.filter(item => item.flag === 'ASSORTMENT_ALLOCATION_STATUS');
            // this.$CACHE.setObject('ALLOCATION_STATUS', allocationList)
            // const requestList = res.data.filter(item => item.flag === 'ASSORTMENT_REQUEST_STATUS');
            // this.$CACHE.setObject('REQUEST_STATUS', requestList)
            const replenishmentList = res.data.filter(item => item.flag === 'REPLENISH_STATUS')
            this.$CACHE.setObject('REPLENISHMENT_STATUS', replenishmentList)
            const takeList = res.data.filter(item => item.flag === 'STOCK_TAKE_STATUS');
            this.$CACHE.setObject('TAKE_STATUS', takeList)
            const orderList = res.data.filter(item => item.flag === 'STOCK_TRANSFER_STATUS');
            this.$CACHE.setObject('ORDER_STATUS', orderList)
            break;
          case "REPO":
            res.data = res.data.filter(item => item.name)
            res.data = res.data.sort((a, b) => a.name.localeCompare(b.name));
            const repoList = this.toGeneralCache(res.data)
            const Warehouse = repoList.filter(item => item.other === 'Warehouse' && item.isClosed !== "Y");
            // //const Shop = repoList.filter(item => item.other === 'S' && item.isClosed !== "Y");

            this.$CACHE.setObject('REPO', repoList)
            this.$CACHE.setObject('WAREHOUSE', Warehouse)
            // this.$CACHE.setObject('SHOP', Shop)
            this.$CACHE.setObject('REPOTREE', this.arrayToTree(repoList))

            const repoGroupList =  res.data.map((item) => {
              const {id: value, name:label, id, name, ...other } = item
              return {value, label, id, name, ...other}
            });
            this.$CACHE.setObject('REPOGROUP', this.arrayToGroup(repoGroupList))
            break;
          case "REPOMODULEBYUAM":
            this.$CACHE.setObject('REPOMODULEBYUAM', this.toGeneralCache(res.data))
            const rmList = this.toGeneralCache(res.data)
            this.$CACHE.setObject('REPOMODULEBYUAMTREE', this.arrayToTree(rmList))
            const groupList =  res.data.map((item) => {
              const {id: value, name:label, id, name, ...other } = item
              return {value, label, id, name, ...other}
            });
            this.$CACHE.setObject('REPOMODULEBYUAMGROUP', this.arrayToGroup(groupList))
            break;
          case "SKU":
          case "SKUMODULEBYUAM":
            res.data = res.data.sort((a, b) => a.label.localeCompare(b.label));
            const skuList = res.data.map(item => {
              const {label:name, value:code, ...other} = item
              return key === 'SKUMODULEBYUAM' ? {name, code} : {name, code, ...other}
            });

            this.$CACHE.setObject(key, skuList)
            break
          case "UAM_ROLE":
            const urList = res.data.map((item) => {
              const {value: data, label: name, value, label, ...other } = item
              return {name, data, value,  label, ...other}
            });
            this.$CACHE.setObject(key, urList)
            break
          case "UAM_FUNCTION":
            const ufList = res.data.map((item) => {
              const {value: data, other: name, value, other, ...rest } = item
              return {name, data, value, other, ...rest}
            });
            this.$CACHE.setObject(key, ufList)
            break
          case "REASONS":
            const reasons = res.data[0]
            this.$CACHE.setObject(key, reasons)
            break
          default:
            this.$CACHE.setObject(key, res.data)
            break;
        }
        // console.log("Loading " + key + " Done! >>>")
      })
    }
  }

  /**
     * 生成Transation Number
     * @param date data
     * @param dnNo data
     * @param shop data
     */
  genTranNum(date: Date,dnNo,shop) {
      return date.getFullYear().toString().substr(2, 2) +
          this.complement(date.getMonth() + 1) +
          this.complement(date.getDate()) +
          dnNo + shop +
          this.complement(date.getHours()) +
          this.complement(date.getMinutes()) +
          this.complement(date.getSeconds());
  }

  @action complement(n) { return n < 10 ? '0' + n : n }

    /**
   * 时间转换
   * @param long d
   */
  @action convertDate(d){
    var timeZone:any = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date:any = new Date(parseInt(d, 10) + timeZone * 3600 * 1000)
    return date.toJSON().substr(0, 19).replace('T', '/').replace(/-/g, '/').split("/").reverse().join("/").replace('/',' ');
  }
}

export {
  URLDICT,
  NOTI_MSG,
  NOTI_TITLE,
  INPUT_STATUS,
  INPUT_TYPE,
  BUTTON_TYPE,
  VALIDATE_TYPE,
  STEP_TYPE,
  CONFIG,
  BUTTON_WRAPPER,
}
