import { HttpHelper } from "./../../util/HttpHelper";
import { Injectable } from "@angular/core";
import { URLDICT } from "./../../config";
import { from } from "rxjs";
import { CommonService } from "../common/common-service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StockReservationService {
  constructor(
    public commonService: CommonService,
  ){}

  extendAutoReleaseDate(reservation_id){
    return from(HttpHelper.put(URLDICT.STOCK_RESERVATION_EXTENDAUTORELEASEDATE, {"id": reservation_id}))
  }

  searchReservationById(id){
    return from(HttpHelper.post(URLDICT.STOCK_RESERVATION_SEARCH, {"reservationId": id, "sortEvent":{"field":"reservationId","order":1}})
    ).pipe(map(res=>{
      res.data = res.data.map(e=>{return {
        ...e,
        qtyAvaliableForSales: e.qtyAvaliableForSales || e.qtyavaliableforsales || 0
      }})
      return res
    }))
  }

  saveReservationQtysUpdate(params){
    return from(HttpHelper.post(URLDICT.STOCK_RESERVATION_UPDATE, params))
  }

  searchReservation(params){
    return from(HttpHelper.post(URLDICT.STOCK_RESERVATION_SEARCH, params)).pipe(map(res=>{
      if(res.code == '000') { return res } else { throw res.msg }
    })).pipe(map(res=>{
      res.data = res.data.map(e=>{return {
        ...e,
        qtyAvaliableForSales: e.qtyAvaliableForSales || e.qtyavaliableforsales || 0
      }})
      return res
    }))
  }
  searchChannelItemBalance(channelIds, itemIds){
    return from(
      HttpHelper.post(URLDICT.STOCK_CURD_SEARCH, {
        skuNum: itemIds?[...itemIds.map(id=>{return {id:id}})]:[],
        shopId: channelIds&&channelIds.length?[...channelIds.map(id=>{return {id:id}})]:[],
        isIncludeLis: 0,
        pageIndex: 0,
        pageSize: 2147483647,
        accountName: this.commonService.getAccount(),
      })
    ).pipe(map(res=>{
      if(res.code == '000') { return res } else { throw res.msg }
    }))
  }

  createReservation(data){
    return from(HttpHelper.post(URLDICT.STOCK_RESERVATION_CREATE, data))
    .pipe(
      map((res:any)=>{
        if(res.status == 500){
          throw res.message?res.message:res
        }
        if(res.code == '000') { return res } else { throw res.msg }
      }),
    )
  }
  cancelReservation(data) {
    return from(HttpHelper.post(URLDICT.STOCK_RESERVATION_CANCEL, data))
  }
}
