import { Component } from '@angular/core';
import { CreateStore } from 'src/app/stores/stock/demandCycle/CreateStore';

@Component({
  providers: [CreateStore],
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent {
  constructor(public store: CreateStore) { }

}
