import { SearchFieldRecordor } from '@/service/common/search-record';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonStore } from 'src/app/service/common/common-store-service';
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { SearchStore } from 'src/app/stores/stock/transfer_order/SearchStore';
import { InjectHelper } from 'src/app/util/InjectHelper';

@Component({
  selector: 'app-test-search',
  template: `
    <div>
      <illustration [name]="'menu.stock.transaction' | translate" path="Stock Balance Enquiry"></illustration>
      <app-query-pannel [store]="store.queryPannel"></app-query-pannel>
      <app-ptable-pannel [store]="store.tablePannel" [searchStore]="store"></app-ptable-pannel>
      <!-- <app-common-table-panel [store]="store.tablePannel" [searchStore]="store"></app-common-table-panel> -->
    </div>
  `,
  styles: []
})
export class SearchComponent implements OnDestroy {
  public store: SearchStore = null
  // resize$
  constructor(private n: MessageService, private r: Router,
    private p: ActivatedRoute, private m: ConfirmationService,
    private commonStore: CommonStore, private stockCommonService: StockCommonService,
    public cdRef: ChangeDetectorRef, public datepipe: DatePipe) {
    this.store = new SearchStore(InjectHelper.start(this))
    this.store.cdRef = cdRef;
    this.store.stockCommonService = stockCommonService;
    this.store.datepipe = datepipe;
  }

  ngOnDestroy(): void {
    this.store.onDestroy()
  }

  ngAfterViewInit(){
    if(this.store.afterViewInit)this.store.afterViewInit()
  }
}
