import { DemandCycleStore } from "./DemandCycleStore";
import { makeAutoObservable } from "mobx";
import { ChangeDetectorRef, Injectable } from "@angular/core";
import { MENU } from "../../../config";
import { CONFIG, INPUT_TYPE } from "../../base/BaseStore";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { Router, ActivatedRoute } from "@angular/router";
import { StockDemandCycleService } from "src/app/service/stock/stock-demand-cycle.service";
import { SearchFieldRecordor } from "@/service/common/search-record";
import { CommonStore } from "@/service/common/common-store-service";
import { CommonMethod } from "@/util/CommonMethod";
import { catchError, tap } from "rxjs/operators";
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";

@Injectable()
export class SearchStore {
  breadcrumb = MENU.DEMAND_CYCLE;
  searchFieldRecordor: SearchFieldRecordor;
  queryData = {
    ipts: [
      {
        title: this.common.TITLE.FORECAST_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        error: false,
        name: 'demandCycleName',
      },
      /* {
        title: this.common.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: "name",
        optionValue: "id",
      }, */
      {
        title: this.common.TITLE.FORECAST_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        name: 'demandStartDate',
      },
      // {
      //   title: this.common.TITLE.FORECAST_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-3",
      //   value: null,
      // },
      {
        title: this.common.TITLE.DEMAND_CYCLE_TYPE,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-3 p-lg-3",
        value: null,
        options: [],
        optionLabel: 'typeName',
        optionValue: 'id',
        name: 'demandCycleTypeId',
      },
      {
        title: this.common.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        // showTime: true,
        value: null,
        name: 'submissionDeadline'
      },
    ],
    btns: [
      {
        title: "Search",
        class: "p-order-3",
        show: true,
        disabled: true,
        handler: { click: () => this.searchFromFilter() },
      },
      {
        title: "Clear",
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clear() },
      },
      {
        title: "Create Demand Cycle",
        class: "p-order-2  p-mr-1",
        show: true,
        disabled: true,
        handler: {
          click: () => this.create(["main", "demand_cycle", "create"]),
        },
      },
    ],
    btnsclass: "p-d-flex p-col-12 p-md-12 p-lg-12",
  };
  tableData = {
    btns: [],
    data: [],
    columns: [
      { field: "demandCycleName", title: "Demand Cycle Name", width: "190px" },
      // { field: "stockPoolTypeId", title: "Stock Pool Type", width: "150px" },
      { field: "demandCycleType", title: "Demand Cycle Type", width: "165px" },
      { field: "demandStartDate", title: "Tentative Delivery Date", width: "200px", type: "date", edit: true, inputValueKey: '_demandStartDate', defaultDate: this.getToday()},
      { field: "updateBy", title: "Updated By", width: "120px" },
      { field: "channelRequestQty", title: "Channel Request", width: "150px" },
      { field: "updateDate", title: "Updated Datetime", width: "160px", type: "datetime", },
      { field: "demandCycleId", title: "Demand Cycle ID", width: "160px" },
      // { field: "demandEndDate", title: "End Date", width: "120px", type: "date", edit: true, },
      { field: "submissionDeadline", title: "Demand Deadline", width: "180px", type: "datetime", edit: true, inputValueKey: '_submissionDeadline', defaultDate: this.getToday() },
      { field: "createBy", title: "Created By", width: "120px" },
      { field: "createDate", title: "Created Datetime", width: "160px", type: "datetime", },
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [
      { field: "", title: "Action", width: "160px", type: "action" },
    ],
    frozenWidth: "150px",
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "demandStartDate", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: "single",
    initSortField: "demandStartDate",
    initSortOrder: -1,
    selectedProducts: null,
    editShow: true,
  };
  waitDeleteData;
  currentRow;
  currentDate; //table calendar 打开时的时间
  originalDate; //table calendar 打开时记录的时间
  clickNum = 0; //table calendar 点击次数，当日期相同时，点击2次关闭日历
  cloneData;
  showTime;
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };
  permission: string[];
  loading: boolean = false;
  searchCache = {}
  resetSearch = false
  paginator = null
  field='demandStartDate'
  order=-1
  public cdRef: ChangeDetectorRef
  forceSearch=false

  get havePermissionCreateReplenishment(){
    return this.permissionService.havePermission(FUNCTION_CODE.REPLENISHMENT_CREATE)
  }

  constructor(
    private common: DemandCycleStore,
    private router: Router,
    private route: ActivatedRoute,
    private service: StockDemandCycleService,
    public commonStore: CommonStore,
    public permissionService: PermissionService,
  ) {
    makeAutoObservable(this);
    this.getPermission(route);
    this.tableData.selectedColumns = [...this.tableData.columns];
    this.getResolverData();
    this.setCycleType();
    this.searchFieldRecordor = new SearchFieldRecordor(commonStore, router, route, CONFIG.SEARCHPARAMSKEY.DEMANDCYCLE)
    this.initRouterEvent()
    // this.search(0);
  }
  get demandCycleName() {
    const ipt = this.getQueryByTitle(this.common.TITLE.FORECAST_CYCLE_NAME);
    return ipt.value;
  }
  /* get stockPoolTypeId() {
    const ipt = this.getQueryByTitle(this.common.TITLE.STOCK_POOL_TYPE);
    return ipt.value;
  } */
  get submissionDeadline() {
    const ipt = this.getQueryByTitle(this.common.TITLE.SUBMISSION_DEADLINE);
    return ipt.value;
  }
  get demandStartDate() {
    const ipt = this.getQueryByTitle(this.common.TITLE.FORECAST_START_DATE);
    return ipt.value;
  }
  get demandCycleType() {
    const ipt = this.getQueryByTitle(this.common.TITLE.DEMAND_CYCLE_TYPE);
    return ipt.value
  }
  // get demandEndDate() {
  //   const ipt = this.getQueryByTitle(this.common.TITLE.FORECAST_END_DATE);
  //   return ipt.value;
  // }
  get showEDC() {
    return this.permission?.includes("Edit Demand Cycle")
  }
  get showED() {
    return this.permission?.includes("Input Demand")
  }
  get showVD() {
    return this.permission?.includes('View Demand')
  }
  get showR() {
    // return this.permission?.includes('Create Replenishment')
    return this.havePermissionCreateReplenishment
  }
  get access() {
    return true;
  }
  getToday(){
    return new Date(new Date().setHours(0,0,0,0))
  }
  getQueryByTitle(title) {
    return this.queryData.ipts.find((item) => item.title === title);
  }
  getPermission(p) {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[p.snapshot.data.code];
    this.setPermission(this.queryData.btns);
  }

  setPermission(list) {
    list.forEach((item) => {
      item.title === 'Search' && (item.disabled = !this.permission.includes("Search Demand Cycle"))
      item.title === 'Create Demand Cycle' && (item.disabled = !this.permission.includes("Create Demand Cycle"))
        // item.show = (this.permission & permission) === permission;
    });
  }
  getResolverData() {
    this.common.getResolverData(this.route);
    /* const ipt = this.getQueryByTitle(this.common.TITLE.STOCK_POOL_TYPE);
    ipt.options = LocalStorageHelper.getObject("POOL_TYPE"); */
    // tslint:disable-next-line: no-unused-expression
    this.access && (this.tableData.frozenWidth = "240px");
  }
  /* initdata() {
    this.search();
  } */
  setCycleType() {
    const ipt = this.getQueryByTitle(this.common.TITLE.DEMAND_CYCLE_TYPE);
    ipt.options = LocalStorageHelper.getObject('DEMAND_CYCLE_TYPE')
  }
  showSaveBtn(data) {
    console.log("show save button", data);
    const datetime = new Date().getTime();
    const diff = data.submissionDeadline - datetime;
    return diff > 0 ? true : false;
  }
  changeDate(time) {
    let d1 = new Date(time).toDateString();
    return new Date(d1).getTime() + this.common.changeDefaultTime();
  }
  validation(data) {
    let flag = true;
    // demandStartDate > submissionDeadline
    const gtNum = this.common.comparisonDate(data.submissionDeadline, data.demandStartDate);
    if (gtNum >= 0) {
      flag = false;
      this.common.showMessage( "error", this.common.info.title,
      this.common.info.lt.replace("date1", this.common.TITLE.SUBMISSION_DEADLINE).replace("date2", this.common.TITLE.FORECAST_START_DATE) );
    }
    // demandEndDate >= demandStartDate. 这里是否需要验证，有待确定
    // const ltNum = this.common.comparisonDate( data.demandEndDate, data.demandStartDate );
    // if (ltNum < 0) {
    //   flag = false;
    //   this.common.showMessage( "error", this.common.info.title,
    //   this.common.info.gt .replace("date1", this.common.TITLE.FORECAST_END_DATE).replace("date2", this.common.TITLE.FORECAST_START_DATE) );
    // }
    return flag
  }
  getParameterValues(data) {
    return {
      accountName: this.common.account,
      demandCycleName: data.demandCycleName,
      demandCycleTypeId: data.type_id,
      // stockPoolTypeId: data.sdl_stock_pool_type_id,
      demandCycleId: data.demandCycleId,
      // submissionDeadline: this.changeDate(data.submissionDeadline),
      submissionDeadline: data.submissionDeadline,
      demandStartDate: data.demandStartDate,
      demandEndDate: this.changeDate(data.demandEndDate),
    };
  }
  saveSuccess(data) {
    data.isEdit = false;
    const currentTime = new Date().getTime();
    const deadline = this.common.comparisonDate(data.submissionDeadline, currentTime);
    data.action = deadline < 0 ? true : false;
    this.callSearch();
    this.common.showMessage( "success", this.common.info.title, this.common.info.success );
  }
  save(data, idx) {
    const flag = this.validation(data)
    if (flag) {
      const cycleData = this.getParameterValues(data)
      this.loading = true;
      this.tableData.loadingSwitch = true;
      this.service.edit(cycleData).subscribe((res) => {
        this.loading = false;
        this.tableData.loadingSwitch = false;
        if (res.code === "000") {
          this.saveSuccess(data)
        } else {
          this.common.showMessage('error', this.common.info.title, res.msg || res.message || res.error.message || this.common.info.unknown)
        }
      });
    }
  }
  openCalender() {
    console.log('open calender ');

  }
  onSelectCalender(e) {
    // const oldDate = new Date(this.originalDate).toLocaleDateString()
    this.currentRow.data[this.currentRow.field] = e.getTime();
    // const newDate = e.toLocaleDateString()
    // if(oldDate !== newDate) {
    //   el.hide();
    // }
  }
  editRow(data) {
    data.isEdit = true;
    this.cloneData = JSON.parse(JSON.stringify(data));
  }

  cancel(data, idx) {
    this.waitDeleteData = data;
    if (
      data.demandStartDate === this.cloneData.demandStartDate &&
      // data.demandEndDate === this.cloneData.demandEndDate &&
      data.submissionDeadline === this.cloneData.submissionDeadline
    ) {
      this.reset(this);
    } else {
      this.common.restoreData(this.reset, this);
    }
  }
  reset(self) {
    self.waitDeleteData.isEdit = false;
    self.waitDeleteData.demandStartDate = self.cloneData.demandStartDate;
    // self.waitDeleteData.demandEndDate = self.cloneData.demandEndDate;
    self.waitDeleteData.submissionDeadline = self.cloneData.submissionDeadline;
  }
  demand(data, idx) {
    sessionStorage.setItem("DEMAND", JSON.stringify(data));
    this.router.navigate(["main", "demand_cycle", "demand", "search"], {queryParams:{id:data.demandCycleId}});
  }

  replenishment(data, idx) {
    sessionStorage.setItem("DEMAND", JSON.stringify(data));
    this.router.navigate(["main", "demand_cycle", "replenishment", "search"], {queryParams:{id:data.demandCycleId}});
  }

  create(url) {
    this.router.navigate(url);
  }

  clear() {
    // this.tableData.frozenWidth = "320px";
    this.queryData.ipts.forEach((item) => {
      item.value = null;
    });
    this.field = this.tableData.initSortField;
    this.order = this.tableData.initSortOrder;
    this.pageIndex = 0;
    this.pageSize = 20;
    // this.search();
  }
  searchFromFilter() {
    this.resetSearch = true
    this.forceSearch = true
    this.paginator ? this.paginator.changePage(0) : this.callSearch(0)
  }
  callSearch(page = 0) {
    this.search(page).subscribe()
  }
  search(page){
    this.loading = true;
    this.tableData.loadingSwitch = true;
    let data: any = {
      pageIndex: page!=null?page:this.pageIndex,
      pageSize: this.pageSize,
      sortEvent: {field: this.field, order: this.order},
      ...(this.demandCycleName && { demandCycleName: this.demandCycleName }),
      ...(this.demandCycleType && { demandCycleTypeId: this.demandCycleType }),
      // ...(this.stockPoolTypeId && { stockPoolTypeId: this.stockPoolTypeId }),
      ...(this.submissionDeadline && { submissionDate: this.submissionDeadline.toLocaleDateString(), }),
      ...(this.submissionDeadline && { submissionDeadline: this.submissionDeadline.getTime(), }),
      ...(this.demandStartDate && { demandStartDate: this.demandStartDate.getTime(), }),
      // ...(this.demandEndDate && { demandEndDate: this.demandEndDate.setHours(23, 59, 59), }),
    };
    // -----------
    // 获取从notice传递的数据
    let noticeMsg = JSON.parse(sessionStorage.getItem('NOTICE_MESSAGE'))
    if(noticeMsg) {
      data.demandCycleName = noticeMsg.demandCycleName;
      const ipt = this.getQueryByTitle(this.common.TITLE.FORECAST_CYCLE_NAME);
      ipt.value = data.demandCycleName
      sessionStorage.removeItem('NOTICE_MESSAGE')
    }
    return this.service.search(data).pipe(tap(res=>{
      this.loading = false;
      let list = [];
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        this.searchFieldRecordor.storeSearchParams(
          data,
          this.queryData.ipts,
          'name'
        )
        const currentTime = new Date().getTime();
        res.data.forEach((item) => {
          const overdueDay = this.common.comparisonDate( currentTime,item.demandStartDate,  );
          item.overdue = overdueDay > 0?true:false
          const deadline = this.common.comparisonDate( item.submissionDeadline, currentTime );
          item.action = deadline < 0 ? true : false;
          // 这里这样处理是因为存在旧数据的原因，如果没有旧数据，则应该用item的相关属性去判断（hv_demand, hv_byitem, hv_school_data）
          item.demandCycleType = item.demandCycleType || "Channel"
          const selItem = this.common.cycleTypes.find(ct => ct.typeName === item.demandCycleType)
          item.hasDemand = selItem.hvDemand === 'Y' ? true : false
          item.hasByBUItem = selItem.hvByBuItem === 'Y' ? true : false
          item.hasSchoolData = selItem.hvSchoolData === 'Y' ? true : false
          item.hasCourierData = selItem.hvCourierData === 'Y' ? true : false
          item._demandStartDate = new Date(item.demandStartDate)
          item._submissionDeadline = new Date(item.submissionDeadline)
          if(item.channelRequestQty=='0'&&item.hasDemand==false){
            item.channelRequestQty='-';
          }
        });
        list = res.data;
        this.tableData.totalRecords = res.msg;
      } else {
        this.common.showMessage('error', this.common.info.title, res.msg || res.message || res.error.message || this.common.info.unknown)
      }
      this.tableData.data = list;
    }, catchError(err=>{
      this.loading = false;
      this.tableData.loadingSwitch = false;
      throw err
    })))
    // .subscribe((res) => {
    // });
  }
  sortSearch(key) {
    if(this.order == -1 || this.field != key || this.order == null){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    this.field = key
    this.callSearch();
  }
  stopEvent(event) {
    event.stopPropagation();
  }
  page(e) {
    let pageIndex = e.first / e.rows;
    if(!this.forceSearch &&
      this.pageSize != 0 &&
      this.pageSize == e.rows && 
      this.pageIndex == pageIndex
    ) return
    this.forceSearch = false;
    this.pageIndex = pageIndex;
    this.pageSize = e.rows;
    this.callSearch(this.pageIndex);
  }
  tableSelectedColumnsChange(e){
    this.tableData.selectedColumns = this.tableData.columns.filter(col => e.includes(col));
  }
  
  customSort(e){
  }

  
  initRouterEvent(){
    this.searchFieldRecordor.setRouterEventForSearchParams(this.router)
    .subscribe(res=>{
      this.getSnapshotData(res)
    })
  }

  onDestroy(){
    if(this.searchFieldRecordor)this.searchFieldRecordor.onDestory()
  }

  getSnapshotData(params){
    let data = params
    this.queryData.ipts.forEach(ipt=>{
      if(ipt.type==INPUT_TYPE.DATETIME){
        if(data[ipt.name]){
          let val = data[ipt.name]
          ipt.value = new Date(val)
        }
      }else {
        if(data[ipt.name]){
          let val = data[ipt.name]
          ipt.value = val
        }
      }
    })
    if(data.pageSize){
      this.pageSize = Number.parseInt(data.pageSize)
    }
    if(data.sortEvent){
      let sortEvent = data.sortEvent
      this.field = sortEvent.field
      this.order = sortEvent.order
    }
  }

  pageSize
  pageIndex
  
  afterViewInit(){
    let data = this.searchFieldRecordor.getRouteParams()
    this.pageIndex = Number.parseInt(data.pageIndex)||0
    // this.callSortPageSearch().then((res)=>{
    //   if(this.cdRef) this.cdRef.detectChanges()
    //   if(this.paginator) this.paginator.changePage(Number.parseInt(data.pageIndex))
    // })
    // this.search()
    this.search(this.pageIndex).subscribe(res=>{
      this.paginator.changePage(this.pageIndex)
      if(this.cdRef) this.cdRef.detectChanges()
      if(this.paginator) this.paginator.changePage(this.pageIndex)
    })
  }
}
