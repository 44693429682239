import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
/**
  * @description: 输入框
  * @author:  ken
  */
@Component({
  selector: 'app-input-limit-cst',
  templateUrl: './input-limit-cst.html',
  styles:[]
})
export class InputLimitCustom {
    @Input() ipt
    @Input() style
    @Output() change = new EventEmitter()
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    onInput(e, v){
      this.change.emit(v)
    }

    beforeInput(e){
      // if((/[^0-9]/).test(e.data) && e.data != null){
      if((this.ipt.blockRegExp).test(e.data) && e.data != null){
        e.preventDefault();
      }
    }

    ngAfterViewInit(){
      let that = this
      if(this.input){
        this.input.nativeElement.addEventListener('beforeinput', (e)=>{that.beforeInput(e)})
      }
    }
}
