import { makeAutoObservable, reaction } from "mobx";
import { Injectable } from "@angular/core";
import { MENU } from "../../../config";
import { INPUT_TYPE } from "../../base/BaseStore";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { ActivatedRoute } from "@angular/router";
import { BlockScrollStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { StockAvailabilityLevelService } from "src/app/service/channelItemControl/stock-availability-level.service";
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from "@/service/common/permission.type";


@Injectable()
export class SearchStore {
  breadcrumb = MENU.CIC_STOCK_AVAILABILITY_LEVEL;
  queryData = {
    ipts: [
      {
        title: this.service.TITLE.CHANNEL,
        type: INPUT_TYPE.TREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        showValue: null,
        options: [],
        // optionLabel: "label",
        // optionValue: "id",
      },
      {
        title: this.service.TITLE.ITEM,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        dataKey: 'code',
        skipAllCheckMatch: true,
        // optionLabel: "name",
        // optionValue: "code",
      },
    ],
    btns: [
      {
        title: this.service.BUTTON_TITLE.SEARCH,
        class: "p-order-1",
        show: true,
        handler: { click: () => this.searchFromFilter() },
      },
      {
        title: this.service.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clearOfQuery() },
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-md-4 p-lg-6",
  };
  addData = {
    ipts: [
      {
        title: this.service.TITLE.CHANNEL,
        type: INPUT_TYPE.SELECTGROUP,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: "name",
        optionValue: "id",
      },
      {
        title: this.service.TITLE.ITEM,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        dataKey: 'code',
        skipAllCheckMatch: true,
      },
      {
        title: this.service.TITLE.LABEL,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
        optionLabel: "name",
        optionValue: "code",
        required: true,
      },
      /* {
        title: this.service.TITLE.FROM,
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        min: 0,
        required: true,
      },
      {
        title: this.service.TITLE.TO,
        type: INPUT_TYPE.INPUTNUMBER,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        min: 0,
        required: true,
      }, */
    ],
    btns: [
      {
        title: this.service.BUTTON_TITLE.ADD,
        class: "p-order-1",
        show: true,
        disabled:true,
        handler: { click: () => this.add() },
      },
      {
        title: this.service.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clearOfAdd() },
      }
    ],
    btnsclass: "p-d-flex p-col-12 p-lg-3",
    show: true,
    loading: 'hidden'
  }
  tableData = {
    btns: [],
    data: [],
    // display_data: [],
    head: [],
    columns: [
      { field: "channelCode", title: this.service.TABLE_FIELD.CHANNEL, width: "120px" },
      { field: "itemCode", title: this.service.TABLE_FIELD.ITEM, width: "190px" },
      { field: "description", title: this.service.TABLE_FIELD.LABEL, width: "150px" , type: "select"},
      { field: "availLevelFrom", title: this.service.TABLE_FIELD.FROM, width: "160px" , type: "number"},
      { field: "availLevelTo", title: this.service.TABLE_FIELD.TO, width: "190px" , type: "number"}
    ],
    selectedColumns: [],
    visibleColumns: [],
    frozenColumns: [{ field: "", title: "Action", width: "160px", type: "action" },],
    frozenWidth: "150px",
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "channel", order: -1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: "single",
    initSortField: "channel",
    initSortOrder: -1,
    selectedProducts: [],
    editShow: true,
  };
  totalRecords: 0
  channelIpt: any = {}
  itemIpt: any = {}
  channelByAddIpt: any = {}
  itemByAddIpt: any = {}
  labelIpt: any = {}
  // fromIpt: any = {}
  // toIpt: any = {}

  currentRow: any = {}
  cloneData: any = {}
  rawData: any = {}
  skus: any = []
  labels: any = []
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };
  permission: string[];
  loading: boolean = false;
  showSearchLoading = 'hidden'
  configType = 3
  selectAvailabilityLevel: any = {}
  private overlayRef: OverlayRef | undefined
  private scrollStrategy: BlockScrollStrategy;

  searchCache = {}
  resetSearch = false
  paginator = null
  stockAvailabilityLevelEditEnable=false
  constructor(
    private route: ActivatedRoute,
    private overlay: Overlay,
    private service: StockAvailabilityLevelService,
    private permissionService: PermissionService
  ) {
    makeAutoObservable(this);
    this.getPermission();
    this.tableData.head = [...this.tableData.columns];
    this.tableData.selectedColumns = [...this.tableData.columns];
    // this.getResolverData();
    this.initdata();
  }
  get channelByQuery() {
    const ipt = this.getQueryByTitle(this.service.TITLE.CHANNEL);
    return ipt.value;
  }
  get itemByQuery() {
    const ipt = this.getQueryByTitle(this.service.TITLE.ITEM);
    return ipt.value;
  }
  get channelByAdd() {
    const ipt = this.getTitleByAdd(this.service.TITLE.CHANNEL);
    return ipt.value;
  }
  get itemByAdd() {
    const ipt = this.getTitleByAdd(this.service.TITLE.ITEM);
    return ipt.value;
  }
  get label() {
    const ipt = this.getTitleByAdd(this.service.TITLE.LABEL);
    return ipt.value ? ipt.value.map(item=>item.code) : []
  }


  get showSearch() {
    return true //this.permission.includes('Search')
  }

  getQueryByTitle(title) {
    return this.queryData.ipts.find((item) => item.title === title);
  }
  getTitleByAdd(title) {
    return this.addData.ipts.find((item) => item.title === title);
  }

  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
    // this.addData.show = (this.permission & this.defaultPermission.c) === this.defaultPermission.c
    this.setPermission(this.queryData.btns);
  }

  /* setPermission(list) {
    list.forEach((item) => {
      if (item.permissionType) {
        let permission = this.defaultPermission[item.permissionType];
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permission & permission) === permission;
      }
    });
  } */
  initQueryData() {
    this.channelIpt = this.getQueryByTitle(this.service.TITLE.CHANNEL);
    this.itemIpt = this.getQueryByTitle(this.service.TITLE.ITEM);
    const channels = JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo=>repo.children.length)
    this.channelIpt.options = channels
    const skus = JSON.parse(localStorage.getItem("SKU"))
    this.itemIpt.options = skus
  }
  queryItems(channelId) {
    if(channelId) {
      // const positionStrategy = this.overlay.position().global()
      this.scrollStrategy = this.overlay.scrollStrategies.block();
      this.overlayRef = this.overlay.create()

      const div = document.createElement('div')
      div.classList.add('mask')
      const doc = document.createDocumentFragment()
      doc.appendChild(div)
      this.overlayRef.overlayElement.appendChild(doc)
      this.scrollStrategy.enable()
      // this.showSearchLoading= 'visible'
      this.addData.loading = 'visible'
      this.service.getItems({dtlRepoId: channelId}).subscribe(res => {
        this.overlayRef.dispose()
        // this.scrollStrategy.enable()
        this.addData.loading = 'hidden'
        this.itemByAddIpt.value = null
        let list = res.code === '000' ? res.data : []
        if(list.length) {
          list = list.map(item => {
            item.name = item.skuCode + ' ~ ' + item.skuDesc
            let {dtlSkuId:code, ...other} = item
            return {code, ...other}
          });
        } else {
          this.service.showMessage('warn', this.breadcrumb, this.service.info.noItems)
        }
        this.itemByAddIpt.options = list
      })
    } else {
      this.itemByAddIpt.value = null
      this.itemByAddIpt.options = this.skus
    }
  }
  initCreateData() {
    this.channelByAddIpt = this.getTitleByAdd(this.service.TITLE.CHANNEL);
    this.itemByAddIpt = this.getTitleByAdd(this.service.TITLE.ITEM);
    this.labelIpt = this.getTitleByAdd(this.service.TITLE.LABEL);
    const channles = JSON.parse(localStorage.getItem("REPOMODULEBYUAMGROUP")).filter(repo=>repo.items.length)
    this.channelByAddIpt.options = channles
    this.skus = JSON.parse(localStorage.getItem("SKU")).map(sku => {
      sku.name += ' ~ ' +  sku.flag
      return sku
    });
    this.itemByAddIpt.options = this.skus

    reaction (
      () => this.channelByAddIpt.value,
      (data) => {
        this.queryItems(data)
      }
    );
  }
  initdata() {
    this.initQueryData()
    this.initCreateData()
    this.getAllTypes();
    this.getLabels()
  }

  reset() {
    // empty
  }
  verify() {
    let flag = true
    if(!this.label || !this.label.length) {
      this.labelIpt.error = true
      this.service.showMessage('error', this.breadcrumb, this.service.info.reqiuired.replace('This', this.service.TITLE.LABEL))
      flag = false
    } else {
      this.labelIpt.error = false
    }

    // 可以只有item， 或item和channel都没有。但不能只有channel
    /* if(this.channelByAdd && !this.itemByAdd) {
      this.itemByAddIpt.error = true
      this.service.showMessage('error', this.breadcrumb, this.service.info.coexist)
      flag = false
    } else {
      this.itemByAddIpt.error = false
    } */
    return flag
  }
  splitLabels(item = null) {
    let dataArr = []
    const labels = this.label
    labels.forEach(label => {
      const params = {
        ...(this.channelByAdd && { channelId: this.channelByAdd }),
        ...(item && { itemId: item.code, }),
        channelItemConfigTypeId: this.configType,
        udlAvailLevelId: label,
      }
      dataArr.push(params)
    });
    return dataArr
  }
  add() {
    if(this.verify()) {
      let data = []
      if(this.itemByAdd && this.itemByAdd.length) {
        this.itemByAdd.forEach(item => {
          data = [...data, ...this.splitLabels(item)]
        });
      } else {
        data = this.splitLabels()
      }
      this.loading = true;
      this.tableData.loadingSwitch = true;
      this.service.create(data).subscribe(res => {
        this.loading = false;
        this.tableData.loadingSwitch = false;
        if (res.code === '000') {
          this.service.showMessage('info', this.breadcrumb, this.service.info.success)
          this.search()
        } else {
          this.service.showMessage('error',
            this.breadcrumb, res.msg || res.message || this.service.info.failed.replace('The', "Create Data"))
        }
      })
    }
  }

  clearOfQuery() {
    this.queryData.ipts.forEach((item) => {
      item.value = null;
      if(item.showValue) {
        item.showValue = null;
      }
    });
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder;
    this.tableData.pageIndex = 0;
    this.tableData.pageSize = 20;
    this.search();
  }

  clearOfAdd() {
    this.addData.ipts.forEach((item) => {
      item.value = null;
    })
    this.itemByAddIpt.options = this.skus

    if(this.labelIpt.error) {
      this.labelIpt.error = false
    }
  }
  /**
   * 需要判断lines的数量， 最高级的label是可以不需要填写to值的，其它的值大小必须按高低顺序赋值
   */
  rowSave(data) {
    // 与保存的源数据做对比，检测是否有数据变化（availLevelTo）
    const hasChange = this.getChange(data)
    if (hasChange) {
      const len = data.lines.length;
      // 检测除最高级label外，availLevelTo是否有空的字段。存在为空，则验证不通过
      const idx = data.lines.findIndex(item => !item.availLevelTo && item.availLevelTo !== 0)
      if(idx !== -1 && idx < len - 1) {
        this.service.showMessage('error', this.breadcrumb,
        this.service.info.reqiuired.replace('This', 'To value of ' + data.lines[idx].description))
        return
      }
      let initValue = 0
      let stop = 0
      let ltList = data.lines.find((item, idx) =>{
        if(idx === 0) {
          initValue = item.availLevelTo
        } else {
          if(item.availLevelTo - initValue < 0) {
            stop = idx
            return item
          }
          initValue = item.availLevelTo
        }
      })
      if(stop === len - 1 && !data.lines[stop].availLevelTo && data.lines[stop].availLevelTo !== 0) {
        ltList = undefined
      }
      if(ltList) {
        const from = 'To value of ' + data.lines[stop].description
        const to = 'To value of ' + (idx === 0 ? data.lines[0].description : data.lines[stop - 1].description)
        this.service.showMessage('error', this.breadcrumb, this.service.info.gt.replace('To', from).replace('from', to))
        return
      }
    } else {
      this.service.showMessage('warn', this.breadcrumb, this.service.info.noChange)
      return
    }
    const reqData = []
    data.lines.forEach(item => {
      reqData.push({
        ...item,
        channelItemConfigTypeId:  this.configType
      })
    });
    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.editAvailablity(reqData).subscribe(res => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        this.service.showMessage('success', this.breadcrumb, this.service.info.success.replace('Created', 'Edit'))
        this.search();
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    })
  }
  getChange(data) {
    const list = this.rawData[data.rowId].lines
    return data.lines.some(line => list.some(item => item.id === line.id && item.availLevelTo !== line.availLevelTo));
  }
  rowCancel(data) {
    const hasChange = this.getChange(data)
    if(hasChange) {
      this.service.showDialog(this.service.info.confirmCancel, data, this, 'confirmCancel')
    } else {
      this.confirmCancel(data)
    }
  }
  confirmCancel(data) {
    const oldData = this.rawData[data.rowId].lines
    data.lines.forEach((item, idx) => {
      item.availLevelFrom = oldData[idx].availLevelFrom
      item.availLevelTo = oldData[idx].availLevelTo
      item.description = oldData[idx].description
    });
    delete this.rawData[data.id]
    data.edit = false
    data.isEdit = false
  }
  selectLabel(e, op) {
    this.selectAvailabilityLevel.description = e.value.name
    op.hide();
  }
  openSelect(e, data, op) {
    this.selectAvailabilityLevel = data
    op.toggle(e)
  }
  rowEdit(data) {
    const len = data.lines.length - 1
    data.lines.forEach( (line, idx) => {
      if(idx === len) {
        line.endLine = true
      }
    });
    this.rawData[data.rowId] = JSON.parse(JSON.stringify(data));
    data.edit = true
    data.isEdit = true
  }
  rowDelete(data) {
    this.service.showDialog(this.service.info.confirmDelete, data, this, 'confirmDelete')
  }
  confirmDelete (data) {
    const props = {
      ids: data.lines.map(item => item.id),
      channelItemConfigTypeId: this.configType,
    }
    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.delete(props).subscribe(res => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        this.service.showMessage('success', this.breadcrumb, this.service.info.success.replace('Created', 'Delete'))
        this.search();
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    })
  }
  getLabels() {
    this.service.getLabels({}).subscribe((res) => {
      if (res.code === "000") {
        // handle res.data = [[{},{}]]
        let dataList = res.data[0]
        // handle res.data = [{},{}]
        if(!Array.isArray(dataList))dataList = res.data
        this.labels = dataList.map(item => {
          const {description:name, id:code} = item
          return {name, code}
        })
        this.sortByProps(this.labels, 'code')
        this.labelIpt.options = this.labels
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    });
  }
  getAllTypes(){
    this.service.getType({}).subscribe((res) => {
      if (res.code === "000") {
        this.configType = res.data.find(item => item.description === this.service.defaultConfigDesc).id
        this.search(true);
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    });
  }
  sortByProps(list, props){
    list.sort((a, b) => a[props] - b[props])
  }
  searchFromFilter() {
    this.resetSearch = true
    this.paginator ? this.paginator.changePage(0) : this.search(true)
  }
  search(comeFrom = false) {
    let data:any = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      channelItemConfigTypeId: this.configType,
      ...(this.channelByQuery && { channel: this.channelByQuery?.id }),
      ...(this.itemByQuery && { items: this.itemByQuery.map(item => item?.code) }),
    };
    if (comeFrom) {
      this.searchCache = data
    } else {
      data = this.searchCache
      data.pageIndex = this.tableData.pageIndex
      data.pageSize = this.tableData.pageSize
      data.sortEvent = this.tableData.sortField
    }
    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.searchAvailablity(data).subscribe((res) => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      if (res.code === "000") {
        const list = res.data
        list.forEach((item, idx) => {
          // const {configInfoBeans: lines} = item
          item.lines = item.configInfoBeans
          item.rowId = idx
          item.deleted = item.channelCode || item.itemCode ? true : false,
          this.sortByProps(item.lines, 'udlAvailLevelId')
        });
        this.tableData.data = list
        this.totalRecords = res.msg
        this.tableData.totalRecords = res.msg
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    });
  }
  sortSearch(field) {
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = -this.tableData.sortField.order;
    this.search();
  }

  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search(this.resetSearch ? true : false );
    this.resetSearch = false;
  }
  onRowExpand(e, d) {
    if(e){
      d.edit = false
      d.isEdit = false
    }
  }
  setPermission(...e){
    let permissionService = this.permissionService
    if(permissionService.havePermission(FUNCTION_CODE.STOCK_AVAILABILITY_LEVEL_EDIT)){
      this.stockAvailabilityLevelEditEnable=true;
    }
    this.addData && this.addData.btns.length>0 && this.addData.btns.forEach(item=>{
      item.title==this.service.BUTTON_TITLE.ADD && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_AVAILABILITY_LEVEL_EDIT))
    })
  }
}
