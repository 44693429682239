import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { ApiCallLogStore } from 'src/app/stores/stock/ApiCallLog/ApiCallLogStore';

@Component({
  providers: [ApiCallLogStore],
  selector: 'api_call_log',
  templateUrl: './api_call_log.component.html',
  styleUrls: ['./api_call_log.component.css']
})
export class ApiCallLogComponent implements AfterViewInit {
  @ViewChild('paginator', {static: false}) paginator: Paginator;
  @ViewChild('ptable', {static: false}) ptable: Table;
  constructor(public store: ApiCallLogStore) { }
  sourceSystemList = [];
  ngAfterViewInit(): void {
    this.store.table = this.ptable
    this.store.paginator = this.paginator
  }

}
