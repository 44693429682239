import { ChangeDetectorRef, Component } from '@angular/core';
import { SearchStore } from 'src/app/stores/stock/take/SearchStore';
import { Router, ActivatedRoute } from '@angular/router';
import { InjectHelper } from 'src/app/util/InjectHelper';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { CommonStore } from '@/service/common/common-store-service';


@Component({
  selector: 'app-search',
  template: `<div>
    <illustration [name]="'Stock Take'"></illustration>
    <p-progressBar mode="indeterminate" *ngIf="store.tablePannel.loadingSwitch"></p-progressBar>
    <app-query-pannel [store]="store.queryPannel"></app-query-pannel>
    <app-ptable-pannel [store]="store.tablePannel" [searchStore]="store"></app-ptable-pannel>
    <!-- <app-common-table-panel [store]="store.tablePannel" [searchStore]="store"></app-common-table-panel> -->
  </div>`,
  styles: []
})
// <app-table-pannel [store]="store.tablePannel" [searchStore]="store"></app-table-pannel>
export class SearchComponent {
  public store:SearchStore
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService, datePipe: DatePipe, public commonStore: CommonStore, public cdRef: ChangeDetectorRef) {
    this.store = new SearchStore(InjectHelper.start(this))
    this.store.setDatePipe(datePipe)
    this.store.setCdRef(cdRef)
    // console.log('----- init take data -----', this.store);
  }
  ngAfterViewInit(){
    if(this.store.afterViewInit)this.store.afterViewInit()
  }
  ngOnDestroy(): void {
    this.store.onDestroy()
  }
}
