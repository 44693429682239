import { CommonService } from "@/service/common/common-service";
import { Injectable } from "@angular/core";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService } from "primeng/api";
import { pluck } from "rxjs/operators";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";

@Injectable({providedIn: "root"})
export class DemandCycleStore {
  TITLE = {
    FORECAST_CYCLE_NAME: "Demand Cycle Name",
    STOCK_POOL_TYPE: "Stock Pool Type",
    DEMAND_CYCLE_TYPE: "Demand Cycle Type",
    FORECAST_CYCLE_ID: "Demand Cycle ID",
    FORECAST_START_DATE: "Tentative Delivery Date",
    FORECAST_END_DATE: "Demand End Date",
    SUBMISSION_DEADLINE: "Demand Deadline",
    CREATED_BY: "Created By",
    CREATED_DATETIME: "Created Datetime",
    UPDATED_BY: "Updated By",
    UPDATED_DATETIME: "Updated Datetime"
  }
  info = {
    title: "Demand Cycle",
    required: "name is required!",
    unique: "name must be unique!",
    success: "Created successfully!",
    cancel: "Confirm to cancel?!",
    failed: "Failed to get data",
    gt: "date1 must be greater than date2!",
    lt: "date1 must be earlier than date2!",
    unknown: "Unknown error in API access"
  }
  DEMANDCYCLETYPE = {
    MobileChannel: 1,
    BYOD: 2,
    NOP: 3,
    MOBRoadShow: 4,
    HKTHomeChannel: 5,
  }
  poolType = []
  cycleTypes = []
  account = this.commonService.getAccount()
  constructor(
    private msg: MessageService,
    private conf: ConfirmationService,
    public commonService: CommonService
  ) {
    makeAutoObservable(this)
  }
  getResolverData(route) {
    route.data.pipe(pluck('query')).subscribe(res => {
      if (res.local) {
        this.poolType = LocalStorageHelper.getObject('POOL_TYPE')
        this.cycleTypes = LocalStorageHelper.getObject('DEMAND_CYCLE_TYPE')
      } else {
        let list = []
        if(res[0].code === '000' ) {
          res[0].data.forEach(item => {
            list.push({id:item.id, code: item.code, name: item.description})
          })
          this.poolType = list
          LocalStorageHelper.setObject('POOL_TYPE', list)
        }
        if(res[1].code === '000' ) {
          this.cycleTypes = res[1].data[0]
          LocalStorageHelper.setObject('DEMAND_CYCLE_TYPE', res[1].data[0])
        }
      }
    })
  }
  comparisonDate(timeStamp1, timeStamp2) {
    const dayValue = 24 * 60 * 60 * 1000
    const diff = Math.floor((timeStamp1 - timeStamp2) / dayValue)
    return diff
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
  restoreData(fun, self) {
    this.conf.confirm({
      message: this.info.cancel,
      header: this.info.title,
      accept: () => {
        fun(self)
      }
    });
  }
  //将默认的时间从 00:00:00 改为 23:59:59
  changeDefaultTime() {
    const time = 24 * 60 * 60 * 1000 - 1000
    return time
  }
}
