import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {action, computed, observable} from "mobx";
import {HttpHelper} from "../../../../util/HttpHelper";
import {CONFIG, INPUT_TYPE, URLDICT} from "../../../../stores/base/BaseStore";
import {LocalStorageHelper} from "../../../../util/LocalStorageHelper";
import {CommonMethod} from "../../../../util/CommonMethod";
import { MessageService, ConfirmationService } from 'primeng/api';
import { StockCommonService } from 'src/app/service/stock/stock-common.service';
import { forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { CommonService } from 'src/app/service/common/common-service';
import { CREATE_PERMISSION_CODE, ORDERTYPEID, PANELBTNTAG, SELECTEDTYPE, STATUSID, TITLE } from '../StockOrderShare';
import { ADJUSTMENT_STATUS, TAKE_ADJUSTMENT_TXN_TYPE } from 'src/app/stores/stock/take/TakeShare';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';

// const TITLE = {
//   TRANSACTION_TYPE: 'Transaction Type',
//   ORDER_NUMBER: 'Stock Order No.',
//   ORDER_STATUS: 'Stock Order Status',
//   CREATION_DATE: 'Creation Date',
//   FROM: 'From',
//   FROM_STATUS_CONDITION: 'From Status Condition',
//   TO: 'To',
//   TO_STATUS_CONDITION: 'To Status Condition',
//   REFERENCE_NUMBER: 'Reference Number',
//   REASON: 'Reason',
//   REMARK: 'Remark',
// }

// const SELECTEDTYPE = {
//   CHANGECONDITION: 'CHANGE-CONDITION', // change stock condition
//   ADJUSTMENT: 'ADJUSTMENT', // Stock adjustment
//   CHANNELTRANSFER: 'CHANNEL-TRANSFER', // Channel to Channel By Staff
//   ASSORTMENT: 'REPLENISHMENT', // Warehouse to Channel
//   RETURN: 'RETURN', // Channel to Warehouse
//   CHANNELTRANSFERCOURIER: 'CHANNEL-TRANSFER-COURIER', //Channel to Channel By Courier
//   WAREHOUSEHOMED: 'WAREHOUSE-HOMED',
//   CHANNELHOMED: 'CHANNEL-HOMED',
//   CHANNELHOMEDRETURN: 'CHANNEL-HOMED-RETURN', // Return to Channel from Customer
//   WAREHOUSEHOMEDRETURN: 'WAREHOUSE-HOMED-RETURN', // Return to Warehouse from Customer
// }

// const PANELBTNTAG = {
//   SAVEASDRAFT: 'SaveAsDraft',
//   UPDATETONEXTSTATUS: 'UpdateToNextStatus',
//   CLEARALL: 'ClearAll',
//   BACKTOHISTORYPAGE: 'BackTOHistoryPage',
//   CANCEL: 'Cancel',
// }

// const STATUSID={
//   DRAFT: 7,
//   ALLOCATED: 8,
//   TRANSFERRED: 9,
//   RECEIVED: 10,
//   COMPLETED: 11,
// }
@Component({
  selector: 'transfer-order-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class TransferOrderEditComponent implements OnInit, OnDestroy {

  @observable permission:string[]
  @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  @observable loadingSwitch = false

  @observable queryPannel: any = {}
  @observable lisOfData: any[] = []

  @observable isVisible: boolean = false

  items: any[] = []
  adjReasons: any[] = []
  colorAdjReasons: any[] = [];
  _reasonOptions: any[] = [];
  // seItems: any[] = []
  channels: any[] = []
  channels2: any[] = []
  sechannels: any[] = []
  sechannels2: any[] = []
  orderTypes = []
  status: any[] = []
  statusItem1
  statusItem2
  orderTypeItem
  orderTypeItemLabel
  orderStatus: any[] = []
  orderStatusId:any
  orderStatusObject: any = null;
  channelValue
  channelValue2
  itemValue
  balance
  seItem
  itemDesc
  itemId
  qty
  StockInQty
  creationDate
  updateDate
  remark = ''
  sourceTxnRefHeaderNo=''
  noCartonBox = ''
  quantity
  fromChannel
  toWareHouse
  deliveryNote = ''
  orderNumber = ''
  fromStatus: any[] = []
  fromStatusTemplate: any[] = []
  fromNatures: any = []
  toStatus: any[] = []
  shopAndWarehouse: any[] = []
  warehouseListForTreeSelectOptions = []
  uamChannelOptions = [];
  btnLabel = 'Confirm'
  data:any = {line: []}
  updateStatusData = {orderNumber: "", operation: "",updateType: ""}
  lineIndex: number = 0
  lineNumber: number = 1 //start from 1 now
  lineData: any[] = []
  headId = null; // set data when ngOnInit router params or submit() submit result

  // adjustment field
  internalRemarks=''
  internalReference=''
  fromStockTakeAdjustment=false
  rejectedAdjustment=false
  updateBy
  createBy

  cols = [
    {header: 'statusCondition', title: 'Status Condition', width: '200px'},
    {header: 'itemCode', title: 'Item Code', width: '140px', type: 'text'},
    {header: 'item', title: 'Item Description', width: '400px'},
    // {header: 'item', title: 'Item', width: '400px'},
    // {header: 'itemDesc', title: 'Item Desc', width: '200px'},
    // {header: 'lotNo', title: 'Lot no', width: '100px'},
    {header: 'qty', title: 'Qty', width: '100px'},
    {header: 'reason', title: 'Reason', width: '200px'},
    {header: 'finalReason', title: 'Final Reason', width: '200px'},
    {header: 'remark', title: 'Remark', width: '200px'},
  ]

  lineCols = [
    {header: 'serialNumber', title: 'Serial'}
  ]
  disabledList = [TITLE.ORDER_NUMBER, "", TITLE.CREATION_DATE, TITLE.REFERENCE_NUMBER, TITLE.ORDER_STATUS,TITLE.UPDATEBY,TITLE.UPDATEDATE,TITLE.CRREATEBY];
  pageIndex: number = 0
  pageSize: number = 10
  totalRecords = 0

  isNew = true
  // isReason = false
  saveFlag = false // default false for hide before loaddata
  proceedFlag = false
  editing = false;
  isDraft = false;
  toOptions = [];
  fromOptions = [];
  title: any = '';
  // isFinalReason = false;

  // normal editable (draft allow change, etc, include rejected adjustment from stock take)
  // wip: check rejected stock take adjustment
  get isEditable(){return this.isNew || (this.isDraft && !this.fromStockTakeAdjustment) || (this.rejectedAdjustment&&this.fromStockTakeAdjustment) }

  _selectedColumns: any[];
  originColList = [];

  isQtyPositive = false;
  selectedType = "";
  _orderTypeItemData: any = null;
  _adjustmentItemList:any={}
  fromChannelShowValue
  toChannelShowValue
  warehouseList
  reasonEditable = false;
  finalReasonEditable = false;
  lineRemarkEditable = false;
  remarkEditable = false;
  notAllowAddDeleteLine = false;
  qtyAllowNegative = false;

  get isAllowEdit(){return this.showC&&[
    STATUSID.DRAFT,
    STATUSID.ADJ_DRAFT,
    STATUSID.ADJ_REVIEWING,
    STATUSID.ADJ_REJECTED,
    STATUSID.ADJ_ADJUSTIP1,
    STATUSID.ADJ_SUBMITTED,
  ].indexOf(this.orderStatusId)>-1}

  get ignoreIsEditAllowAddDeleteLine(){
    return (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME
    ) &&
    this.fromStockTakeAdjustment &&
    this.isDraft
  }

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  constructor(public n: MessageService, public r: Router, public p: ActivatedRoute, public m: ConfirmationService,
    public stockCommonService: StockCommonService,
    public commonService: CommonService,
    public translate: TranslateService,
    public permissionService: PermissionService,
  ) {
    this._selectedColumns = this.cols;
    this.originColList = this.cols;
    this.getPermission(p)
  }
  initQueryData() {
    this.queryPannel = {
      ipts: [
        {
          title: TITLE.TRANSACTION_TYPE, // for display, can be chinese or english
          name: "orderType", // for database colume
          value: this.orderTypeItem,
          type: INPUT_TYPE.SELECT,
          options: this.orderTypes,
          linkage: "All",
          colspan: 7,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: TITLE.ORDER_STATUS,
          name: "orderStatusItem",
          value: this.orderStatusId,
          type: INPUT_TYPE.SELECT,
          options: this.orderStatus,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title: TITLE.ORDER_NUMBER,
          name: "orderNumber",
          value: this.orderNumber,
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUT,
        },
        {
          title: TITLE.sourceTxnRefHeaderNo,
          name: 'sourceTxnRefHeaderNo',
          value: this.sourceTxnRefHeaderNo,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.FROM,
          name: "from",
          value: this.fromChannel,
          showValue: this.fromChannelShowValue,
          type: INPUT_TYPE.TREESELECT,
          options: this.fromOptions,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "FromStatusNature",
        },
        {
          title: TITLE.FROM_STATUS_CONDITION,
          name: "fromStatusNature",
          value: this.statusItem1,
          type: INPUT_TYPE.SELECT,
          options: this.fromStatus,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        {
          title:TITLE.TO,
          name: "to",
          value: this.toWareHouse,
          showValue: this.toChannelShowValue,
          type: INPUT_TYPE.TREESELECT,
          options: this.shopAndWarehouse,
          disabled: this.isEditable || this.selectedType == SELECTEDTYPE.CHANGECONDITION,
          class: "p-col-12 p-md-4 p-lg-3",
          linkage: "ToStatusNature",
        },
        {
          title: TITLE.TO_STATUS_CONDITION,
          name: "toStatusNature",
          value: this.statusItem2,
          type: INPUT_TYPE.SELECT,
          options: this.toStatus,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-3",
        },
        // {
        //   title: TITLE.REFERENCE_NUMBER,
        //   name: "deliveryNote",
        //   value: this.deliveryNote,
        //   disabled: this.isNew,
        //   class: "p-col-12 p-md-4 p-lg-3",
        //   type: INPUT_TYPE.INPUT,
        // },
        /* {
          title: TITLE.REASON,
          name: "reason",
          value: null,
          disabled: this.isNew,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.SELECT,
          options: [],
        }, */
        {
          title: TITLE.UPDATEDATE,
          name: "updateDate",
          value: this.updateDate,
          defaultValue: "!!",
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.DATETIME,
        },
        {
          title: TITLE.UPDATEBY,
          name: 'updateBy',
          value: this.updateBy,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: true,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.CREATION_DATE,
          name: "creationDate",
          value: this.creationDate,
          defaultValue: "!!",
          disabled: true,
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.DATETIME,
        },
        {
          title: TITLE.CRREATEBY,
          name: 'createBy',
          value: this.createBy,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: true,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.REMARK,
          name: "remark",
          value: this.remark,
          type: INPUT_TYPE.TEXTAREA,
          disabled: this.isEditable,
          class: "p-col-12 p-md-4 p-lg-9 textarea-min-width-100",
        },
        {
          title: TITLE.NO_OF_CARTON_BOX,
          name: "noCartonBox",
          class: "p-col-12 p-md-4 p-lg-3",
          type: INPUT_TYPE.INPUTNUMBER,
          value: this.noCartonBox,
          disabled: this.isEditable,
        },
        {
          title: TITLE.internalRemarks,
          name: 'internalRemarks',
          value: this.internalRemarks,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
        {
          title: TITLE.internalReference,
          name: 'internalReference',
          value: this.internalReference,
          class: "p-col-12 p-md-4 p-lg-3",
          disabled: this.isEditable,
          type: INPUT_TYPE.INPUT
        },
      ],
      btnsclass: "p-d-flex p-col-12 filterBtn p-text-right",
      btns: [
        {
          category: "primary",
          icon: "",
          title: "Save",
          class: "p-order-2 p-mr-1",
          show: true,
          tag: PANELBTNTAG.SAVEASDRAFT,
          handler: {
            click: () => {
              this.submit(this.data);
            },
          },
        },
        // {
        //   icon: "",
        //   title: this.btnLabel,
        //   show: true,
        //   class: "p-order-3",
        //   tag: PANELBTNTAG.UPDATETONEXTSTATUS,
        //   handler: {
        //     click: () => {
        //       this.update(this.data);
        //     },
        //   },
        // },
        {
          title: "Clear",
          icon: "",
          show: true,
          class: "p-button-outlined p-order-1 p-mr-1",
          tag: PANELBTNTAG.CLEARALL,
          handler: {
            click: () => {
              this.refresh();
            },
          },
        },
        {
          title: "Cancel",
          class: "p-button-outlined p-order-0 p-mr-1",
          icon: "",
          show: true,
          tag: PANELBTNTAG.CANCEL,
          handler: {
            click: () => {
              this.routeToCreate();
            },
          },
        },
      ],
      change: (idx, value, data) => {
        // query panel change / form value change
        // console.log('---- change ----', idx, value, data);
        this[data.name] = data.value;
        if ((data.name === "orderType")) {
          this.orderTypeItem = value;
          // this.isReason = this.orderTypeItem === 1 ? true : false
          // this.isFinalReason = this.orderTypeItem === 1 ? true : false
          const typeObj = data.options.find(item=>item.code === value)
          if(typeObj){this.selectedType = typeObj.orderTypeCode}else{this.selectedType = null}
          // clear table
          this.clearTable();
          if(
            this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
            this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME ||
            this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
          ){
            // adjustment item use all item get from skumodule
            this.loadItemListBySkuModule().subscribe(res=>{})
          }
        }

        if ((data.name === "from")) {
          this.fromChannel = value?.data;
          if(this.selectedType == SELECTEDTYPE.CHANGECONDITION){
            let toIpt = this.queryPannel.ipts.find(e=>e.name=="to");
            if(toIpt){
              toIpt.value =  value;
              toIpt.showValue =  value?value.label:null;
              this.toWareHouse = this.fromChannel;
            }
          }
          // clear table
          this.clearTable();
        }
        if ((data.name === "to")) {
          this.toWareHouse = value?.data;
        }
        if ((data.name === "fromStatusNature")) {
          this.statusItem1 = value;
        }
        if ((data.name === "toStatusNature")) {
          //this.statusItem2 = value?.name;
          this.statusItem2 = value;
        }
        // dealType contain setDisabled() for set all disabled
        if([TITLE.FROM, TITLE.FROM_STATUS_CONDITION, TITLE.TO, TITLE.TO_STATUS_CONDITION, TITLE.TRANSACTION_TYPE].indexOf(data.title)>-1){
          this.dealType(value);
        }
        if ((data.name === "orderType")) {
          // const typeObj = data.options.find(item=>item.code === value)
          // setIptDisabled set fromcondition, to, tocondition disabled
          // this.setIptDisabled(typeObj)
          this.setTableColBySelectedType(this.selectedType)
        }
        if(this.selectedType){
          // for override dealType setDisabled, setIptDisabled form ipt disabled result with selected trans type
          // this.handleIptWithSelectedType(this.selectedType)
        }
        if(data.name === 'internalReference'){
          this.internalReference = data.value
        }
        if(data.name === 'noCartonBox') this.noCartonBox = data.value
      },
    };
  }

  ngOnDestroy(): void {
    LocalStorageHelper.remove("line")
  }
  @action getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // console.log('>----------', p.snapshot.data.name, this.permission);
  }

  @computed get showC(){
    const typeIpt = this.getQueryIptByLabel(TITLE.TRANSACTION_TYPE)
    return this.permissionService.havePermission(CREATE_PERMISSION_CODE[typeIpt.value])
  }

  loadOrderStatus(key = 'ORDER_STATUS'){
    this.orderStatus = LocalStorageHelper.getObject(key).map(
      (item) => {
        let { value: code, label: name, ...other } = item
        return { code, name, ...other }
      }
    )
  }

  getFullOrderStatusList(){
    return [...LocalStorageHelper.getObject('ORDER_STATUS'), ...LocalStorageHelper.getObject('ORDER_ADJUSTMENT_STATUS')].map(
      (item) => {
        let { value: code, label: name, ...other } = item
        return { code, name, ...other }
      }
    )
  }

  loadOrderStatusList(){
    this.orderStatus = this.getFullOrderStatusList()
    // switch(this.selectedType){
    //   case SELECTEDTYPE.ADJUSTMENT:
    //   case SELECTEDTYPE.ADJUSTMENTHKTHOME:
    //   case SELECTEDTYPE.COLORADJUSTMENT:
    //     this.loadOrderStatus('ORDER_ADJUSTMENT_STATUS')
    //     break;
    //   default:
    //     this.loadOrderStatus()
    //     break;
    // }
  }

  ngOnInit(): void {
    this.orderTypes = LocalStorageHelper.getObject("ORDER_TYPE").map((item) => {
      let { value: code, label: name, ...other } = item
      return { code, name, ...other }
    })
    this.translate.get('menu.stock.transaction').subscribe(res=>{
      this.title=res
    })
    this.channels = LocalStorageHelper.getObject("SHOP")
    this.channels2 = LocalStorageHelper.getObject("WAREHOUSE")
    this.warehouseList = LocalStorageHelper.getObject("WAREHOUSE")
    this.sechannels = LocalStorageHelper.getObject("SHOP")
    this.sechannels2 = LocalStorageHelper.getObject("SHOP")
    this.adjReasons = LocalStorageHelper.getObject("REASONS")
    this.colorAdjReasons = LocalStorageHelper.getObject("ADJ_REASONS")
    // if(this.reasons!=null){
    //   this.reasons = this.reasons.map(reason=>{
    //     return {
    //       ...reason,
    //       displayDesc: `${reason.reasonCode} - ${reason.reasonDescription}`
    //     }
    //   })
    // }

    // this.items = LocalStorageHelper.getObject("ITEM")
    // this.seItems = JSON.parse(localStorage.getItem("ITEM"))
    // this.orderStatus = LocalStorageHelper.getObject("ORDER_STATUS").map(
    //   (item) => {
    //     let { value: code, label: name, ...other } = item
    //     return { code, name, ...other }
    //   }
    // )
    this.loadOrderStatusList()
    //this.orderStatusItem = this.orderStatus.find(item=>item.other === this.orderStatusItem)
    this.orderStatusObject = this.orderStatus.find(item=>item.alias === 'Draft')
    this.orderStatusId = this.orderStatusObject.code
    this.status = JSON.parse(localStorage.getItem("STOCK_TYPE"))
    this.shopAndWarehouse = JSON.parse(localStorage.getItem("REPOTREE"))
    this.uamChannelOptions = JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(e=>e.children.length>0)
    this.warehouseListForTreeSelectOptions = JSON.parse(localStorage.getItem("REPOTREE")).filter(repo=>repo.key=='Warehouse').filter(e=>e.children.length>0)
    //this.toOptions = this.shopAndWarehouse

    // this.creationDate = this.timestampToTime(new Date().getTime())
    this.creationDate = new Date()
    this.updateDate = new Date()
    // this.orderNumber = CommonMethod.genTranNum(new Date(), "TF", '')
    // this.deliveryNote = this.orderNumber

    // if (Object.keys(this.p.queryParams['value']).length > 0) {
    //   this.loadData(this.p.queryParams['value'])
    // }

    // this.initQueryData()
    // // this.setOrderStatus()
    // this.setDisabled()

    this.p.queryParams.subscribe(params => {
      // if (Object.keys(this.p.queryParams['value']).length > 0) {this.loadData(params)}
      if(
        // !params['orderNumber']&&
        !params['headId']
      ){
        // gentrannum if no current order number
        // this.orderNumber = CommonMethod.genTranNum(new Date(), "TF", '')
        this.saveFlag = true // set to true when no ordernumber in params to show button
        this.proceedFlag = true
      }else{
        this.isNew = false
      }
      this.initQueryData()
      this.updateFromOptionsForUamChannel()
      // this.setOrderStatus()
      this.setDisabled()
      if(params['headId']){
        // this.loadOrderTypeOptions()
        this.headId = params['headId']
        this.updateFromOptionsForUamChannel();
        this.loadDataByHeadId(params['headId'])
        // this.stockCommonService.getOrderByHeadId(params['headId']).subscribe(res=>{
        //   if(res){
        //     this.headId = params['headId']
        //     this.loadData(res)
        //     this.initQueryData()
        //     // this.setOrderStatus()
        //     this.setDisabled()
        //     // if(this.isDraft) {
        //     //   this.handleIptWithSelectedType()
        //     // }
        //   }
        // })
      }
      // else if(params['orderNumber']){
      //   this.stockCommonService.getOrderByOrderNumber(params['orderNumber']).subscribe(res=>{
      //     if(res){
      //       this.loadData(res)
      //       this.initQueryData()
      //       // this.setOrderStatus()
      //       this.setDisabled()
      //     }
      //   })
      // }
    });
  }

  loadDataByHeadId(headId){
    this.stockCommonService.getOrderByHeadId(headId).subscribe(res=>{
      if(res){
        this.loadData(res)
        this.initQueryData()
        let uamChannelList = LocalStorageHelper.getObject('REPOMODULEBYUAM');
        let userHaveFromChannelAccess = uamChannelList.some(e=>e.id==this.fromChannel);
        if(!userHaveFromChannelAccess) {
          this.showMessage('warn','System', 'No permission to edit, redirect back to Stock Order Create.')
          this.routeToCreate()
        }
        this.updateToOptionsByType()
        // this.setOrderStatus()
        this.setDisabled()
      }
    })
  }

  getType(v) {
    // console.log(typeof v);
    return typeof v
  }

  updateFromOptionsForUamChannel(){
    let fromIptIdx = this.queryPannel.ipts.findIndex(ipt=>ipt.title==TITLE.FROM)
    this.fromOptions = this.uamChannelOptions
    this.queryPannel.ipts[fromIptIdx] = {...this.queryPannel.ipts[fromIptIdx], options: this.fromOptions}
  }

  setDisabled() {
    this.queryPannel.ipts.forEach((item) => {
      let disable = this.disabledList.includes(item.title)

      if (disable || item.title === TITLE.NO_OF_CARTON_BOX || item.title == TITLE.netAmount) {
        item.disabled = true
      } else if (item.title === TITLE.TRANSACTION_TYPE) {
        item.disabled = !this.isNew;
		  } else {
        item.disabled = !this.isEditable || !this.orderTypeItem;
      }

      switch(item.title){
        case TITLE.sourceTxnRefHeaderNo:
          item.show = true;
          break;
        case TITLE.internalRemarks:
          item.show = this.selectedType == SELECTEDTYPE.ADJUSTMENT || this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          break;
        case TITLE.REMARK:
          item.show = true;
          break;
        case TITLE.internalReference:
          if(
            this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
            this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          ) {
            item.show = true
          }else{
            item.show = false
          }
          break;
        case TITLE.netAmount:
          if (
            this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
            this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME
          ) {
            item.show = this.orderStatusId == STATUSID.ADJ_SUBMITTED
          }else{
            item.show = false
          }
          break;
        case TITLE.NO_OF_CARTON_BOX:
          if(
            this.selectedType == SELECTEDTYPE.RETURN||
            this.selectedType == SELECTEDTYPE.RETURNWOCOURIER
          ){
            item.show = true
          }else{
            item.show = false
          }
          break
      }

      // if(
      //   [TITLE.sourceTxnRefHeaderNo, TITLE.internalReference, TITLE.internalRemarks,TITLE.REMARK].indexOf(item.title)>-1
      // ){
      //   if(
      //     this.selectedType == SELECTEDTYPE.ADJUSTMENT
      //     || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
      //   ) {
      //     item.show = true
      //   }else{
      //     item.show = false
      //   }
      // }

      // if([TITLE.netAmount].indexOf(item.title)>-1){
      //   if (
      //     this.selectedType == SELECTEDTYPE.ADJUSTMENT
      //   ) {
      //     item.show = this.orderStatusId == STATUSID.ADJ_SUBMITTED
      //   } else if (
      //     this.selectedType==SELECTEDTYPE.CHANNELTRANSFER
      //   ) {
      //     item.show = false
      //   } else if (
      //     (this.selectedType == SELECTEDTYPE.RETURN||this.selectedType==SELECTEDTYPE.CHANNELTRANSFERCOURIER)
      //   ) {
      //     item.show = false
      //   }else{
      //     item.show = false
      //   }
      // }

      // if (
      //   [TITLE.NO_OF_CARTON_BOX].indexOf(item.title)>-1
      // ) {
      //   if(
      //     this.selectedType == SELECTEDTYPE.RETURN
      //     // || this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
      //   ){
      //     item.show = true
      //   }else{
      //     item.show = false
      //   }
      // }

    });
    this.handleIptWithSelectedType()
    this.initReason()
    this.queryPannel.btns.forEach((item) => {
      switch (item.tag) {
        case PANELBTNTAG.CLEARALL:
          item.show = this.isEditable&&!this.fromStockTakeAdjustment
          break;
        case PANELBTNTAG.SAVEASDRAFT:
          // adjustment allow edit when isAllowEdit true
          item.show = (this.isEditable||(
            (
              this.orderTypeItem == ORDERTYPEID.ADJUSTMENT ||
              this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME ||
              this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT
            )
            && this.isAllowEdit)) && this.showC
          break;
        case PANELBTNTAG.CANCEL:
          item.show = true
          break;
        // case PANELBTNTAG.BACKTOHISTORYPAGE:
        //   item.show = true
        //   break;
        // case PANELBTNTAG.UPDATETONEXTSTATUS:
        //   item.show = !this.isNew && this.proceedFlag
        //   break;
        default:
          item.show = this.proceedFlag && this.showC
          break;
      }
    });
    this.reasonEditable =(
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT && [STATUSID.ADJ_DRAFT].indexOf(this.orderStatusId)>-1
    )
    this.finalReasonEditable = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING,STATUSID.ADJ_SUBMITTED, STATUSID.ADJ_ADJUSTIP1].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING,STATUSID.ADJ_SUBMITTED, STATUSID.ADJ_ADJUSTIP1].indexOf(this.orderStatusId)>-1
    )
    this.lineRemarkEditable = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
    )
    this.notAllowAddDeleteLine = (
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT && (this.sourceTxnRefHeaderNo!=null&&this.sourceTxnRefHeaderNo.length<0)
      || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME && (this.sourceTxnRefHeaderNo!=null&&this.sourceTxnRefHeaderNo.length<0)
    )

    this.qtyAllowNegative = (
      (this.orderTypeItem == ORDERTYPEID.ADJUSTMENT || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME || this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT) && [STATUSID.ADJ_DRAFT].indexOf(this.orderStatusId)>-1
    )
  }
  getQueryIptByLabel(name) {
    return this.queryPannel.ipts.find(item => item.title === name)
  }
  setIptDisabled(obj){
    // console.log(obj);
    const fromIpt = this.getQueryIptByLabel(TITLE.FROM)
    const fromStatusIpt = this.getQueryIptByLabel(TITLE.FROM_STATUS_CONDITION)
    const toIpt = this.getQueryIptByLabel(TITLE.TO)
    const toStatusIpt = this.getQueryIptByLabel(TITLE.TO_STATUS_CONDITION)
    // const refNoIpt = this.getQueryIptByLabel(TITLE.REFERENCE_NUMBER)
    // const reasonIpt = this.getQueryIptByLabel(TITLE.REASON)

    fromStatusIpt.value = null
    fromIpt.showValue = null
    fromIpt.value = null
    toIpt.showValue = null
    toIpt.value = null
    toStatusIpt.value = null

    if(obj){
      fromStatusIpt.disabled = obj.isOrderChannelConditionRequired === "Y" ? false : true
      toIpt.disabled = obj.isCrossChannelsOnly === "Y" ? false : true
      toStatusIpt.disabled = obj.isConditionChangeAllowed === "Y" ? false : true
      // reasonIpt.disabled = obj.isReasonRequired === "Y" ? false : true
    }else{
      fromStatusIpt.disabled = true
      toIpt.disabled = true
      toStatusIpt.disabled = true

      // refNoIpt.value = null
    }
    // refNoIpt.disabled = obj ? false : true
  }
  loadData(da) {
    // console.log('da:', da)
    let that = this;
    this.isNew = false
    this.saveFlag = false
    this.proceedFlag = false
    this.orderNumber = da.orderNumber
    this.orderTypeItem = this.orderTypes.find(item=>item.id === +da.orderTypeId)?.code //Number(da.orderTypeId)
    this.selectedType = this.orderTypes.find(item=>item.id === +da.orderTypeId)?.orderTypeCode;
    this.loadOrderStatusList()
    this.setTableColBySelectedType(this.selectedType)
    this.orderTypeItemLabel = da.orderType
    // this.isReason = this.orderTypeItem === ORDERTYPEID.ADJUSTMENT ? true : false
    // this.isFinalReason = this.orderTypeItem === ORDERTYPEID.ADJUSTMENT ? true : false
    this.fromChannel = isNaN(Number.parseInt(da.fromChannelId))? null: Number.parseInt(da.fromChannelId); // shopAndWarehouse>item>data is int
    let fromChannelObj = this.getConditionFromShopAndWarehouseById(this.fromChannel);
    this.fromChannelShowValue = fromChannelObj?fromChannelObj.label:null;
    this.toWareHouse = isNaN(Number.parseInt(da.toChannelId))? null: Number.parseInt(da.toChannelId); // shopAndWarehouse>item>data is int
    let toChannelObj = this.getConditionFromShopAndWarehouseById(this.toWareHouse)
    this.toChannelShowValue = toChannelObj?toChannelObj.label:null
    //this.orderStatusItem = this.orderStatus.find(item=>item.alias === da.orderStatus)//
    this.orderStatusObject = this.orderStatus.find(item=>item.code == da.orderStatus)
    this.orderStatusId = this.orderStatusObject.code
    this.fromStockTakeAdjustment = da.sourceTxnType == TAKE_ADJUSTMENT_TXN_TYPE;
    this.rejectedAdjustment = false;
    this.internalRemarks = da.internalRemarks;
    this.internalReference = da.internalReference;
    this.dealItem()
    this.dealToItem()

    // this.loadFromNature({orderTypeId: this.orderTypeItem.code, fromChannelId: this.fromChannel}, URLDICT.STOCK_TRANSFER_GETFROMNATURE)
    // this.loadToNature({orderTypeId: this.orderTypeItem.code, toChannelId: this.toWareHouse}, URLDICT.STOCK_TRANSFER_GETTONATURE)
    // this.statusItem1 = {'name':da.fromStatusNature, 'code':da.fromConditionCode}
    this.statusItem1 = da.fromConditionCode;
    // this.fromStatus = da.fromConditionCode?[{name:da.fromStatusNature, code:+da.fromConditionCode}]:[]
    // this.statusItem2 = {'name':da.toStatusNature, 'code':da.toConditionCode}
    this.statusItem2 = da.toConditionCode;
    // this.toStatus = da.toConditionCode?[{name:da.toStatusNature, code:+da.toConditionCode}]:[]
    switch(this.orderStatusId){
      case STATUSID.DRAFT:
      case STATUSID.ADJ_DRAFT:{
        this.isDraft = true
        this.proceedFlag = true
        // orderTypeId should be number
        // if(da.orderTypeId == 5)
        //   this.btnLabel = 'Allocate'
        // else if (da.orderTypeId == 3) {
        //   // change stock condition directly draft to completed
        //   this.btnLabel = 'Stock In'
        // }else if (da.orderTypeId == 1) {
        //   // change stock condition directly draft to completed
        //   this.btnLabel = 'Adjust'
        // }
        break;
      }
      // case STATUSID.ALLOCATED:{
      //   this.proceedFlag = true
      //   this.btnLabel = 'Transfer'
      //   break;
      // }
      // case STATUSID.TRANSFERRED:{
      //   this.proceedFlag = true
      //   this.btnLabel = 'Receive for Inspection'
      //   break;
      // }
      // case STATUSID.RECEIVED:{
      //   this.proceedFlag = true
      //   this.btnLabel = 'Stock In'
      //   break;
      // }
      // case STATUSID.COMPLETED:{
      //   this.saveFlag = false
      //   this.proceedFlag = false
      // }
    }
    /*if (da.orderStatus == 'Tran-Draft' || da.orderStatus == 'Tran-InStkln') {
      // this.isNew = true
      this.proceedFlag = true
      this.btnLabel = 'Confirm'
    } else if (da.orderStatus == 'Tran-Conf') {
      // this.isNew = true
      this.proceedFlag = true
      this.btnLabel = 'Proceed'
    }*/
    this.creationDate = new Date(+da.creationDate) //this.timestampToTime(+da.creationDate)
    // console.log(this.creationDate, da.creationDate);
    this.updateDate = new Date(+da.updateDate)
    this.deliveryNote = da.dmDnNuber
    this.remark = da.remark
    this.sourceTxnRefHeaderNo=da.sourceTxnRefHeaderNo
    this.noCartonBox = da.noCartonBox
    this.updateBy=da.updateBy
    this.createBy=da.createBy
    // this.lineData = LocalStorageHelper.getObject("line")
    // this.lineData = da.lines;
    this.lineData = da.lines.map(line=>{
      return {
        ...line,
        reason: line.reasonId,
        finalReason: line.finalReasonId
      }
    });
    // this.items = LocalStorageHelper.getObject("ITEM") //JSON.parse(localStorage.getItem("SKU"))
    // this.reasons = LocalStorageHelper.getObject("REASONS")
    // let adjustment = this.orderTypes.find(type=>type.orderTypeCode==SELECTEDTYPE.ADJUSTMENT).description
    // let replenishment = this.orderTypes.find(type=>{return type.orderTypeCode==SELECTEDTYPE.ASSORTMENT || type.orderTypeCode == SELECTEDTYPE.NOPREPLENISHMENT}).description
    if(
      // da.orderType!==adjustment && da.orderType!==replenishment
      this.orderTypeItem != ORDERTYPEID.ADJUSTMENT &&
      this.orderTypeItem != ORDERTYPEID.ADJUSTMENTHKTHOME &&
      this.orderTypeItem != ORDERTYPEID.COLORADJUSTMENT &&
      this.orderTypeItem != ORDERTYPEID.ASSORTMENT &&
      this.orderTypeItem != ORDERTYPEID.NOPREPLENISHMENT
    ){
      this.loadFromNaturePost(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, {channelId: da.fromChannelId, conditionCode: da.fromConditionCode}, ()=>{
        that.lineData.forEach(line => {
          that.items.forEach(ele => {
            if (ele.code === line.itemId) {
              line.isserialcontrol = ele._data.isserialcontrol
            }
          })
        })
      })
    }else if(
      // da.orderType==replenishment
      this.orderTypeItem == ORDERTYPEID.ASSORTMENT ||
      this.orderTypeItem == ORDERTYPEID.NOPREPLENISHMENT
    ){
      this.loadItemListByItemIdList(da.lines.map(line => line.itemId), ()=>{
        that.lineData.forEach(line => {
          that.items.forEach(ele => {
            if (ele.code === line.itemId) {
              line.isserialcontrol = ele._data.isserialcontrol
            }
          })
        })
      })
    }else if(
      // da.orderType==adjustment
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENT ||
      this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME ||
      this.orderTypeItem == ORDERTYPEID.COLORADJUSTMENT
    ){
      // stockadjustment load item list per item
      this.loadingSwitch = true;
      let itemStatusConditionObs = this.stockCommonService.getFromNature(da.orderTypeId, da.fromChannelId).pipe(
        tap(res=>{
          this.fromStatus = []
          if (res) {
            this.fromNatures = res
            res.forEach(element => {
              this.fromStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
            });
            // const fromStatus = this.queryPannel.ipts.find((item) => item.name === "fromStatusNature");
            // fromStatus.options = this.fromStatus;
            // console.log(this.fromStatus, fromStatus.options);
          }
        })
      )
      let lineDataItemListObservables = []
      // this.lineData.forEach(line=>{
      //   line.lineNumber = line.lineId; // lineNumber using for display selected item in table
      //   let obs = this.loadAdjustmentItemsByStatusCondition(da.fromChannelId, line.fromStockConditionId).pipe(
      //     tap((res)=>{
      //       if (res.code == '000' && res.data.length > 0) {
      //         let items = []
      //         res.data.forEach(element => {
      //           items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, _data: element})
      //         });
      //         this._adjustmentItemList[line.lineNumber] = items
      //       } else {
      //         this.showMessage('warn','System', 'There is no sku in the current channel!')
      //         this._adjustmentItemList[line.lineNumber] = []
      //       }
      //     })
      //   )
      //   lineDataItemListObservables.push(obs)
      // })
      // this.loadItemListByItemIdList(da.lines.map(line => line.itemId))
      this.loadItemListBySkuModule().subscribe(res=>{
        this.lineData.forEach(line => {
          this.items.forEach(ele => {
            if (ele.code === line.itemId) {
              line.isserialcontrol = ele._data.isserialcontrol
            }
          })
        })
      })
      forkJoin([itemStatusConditionObs, ...lineDataItemListObservables]).subscribe(
        res=>{this.loadingSwitch = false},
        err=>{this.loadingSwitch = false}
      )
    }
    this.lineData.forEach(line => {
      // line.itemId = line.itemId
      // line.itemDesc = line.itemDesc
      line.quantity = line.qty
      line.itemCode = line.item
      line.stockOrderLineId = line.lineId
      // this.items.some(ele => {
      //   if (ele.code == da.itemId) {
      //     console.log(ele)
      //     da.isserialcontrol = ele._data.isserialcontrol
      //     return true
      //   }else{
      //     return false
      //   }
      // })
    })

    Object.assign(this.data, da)
    let _lineData = this.lineData.map((line, idx)=>{
      return {...line, lineNumber: idx+1}
    })
    this.data['line'] = _lineData
  }

  toChannelLabel(v) {
    return CommonMethod.toSelectedLabel("REPO", v)
  }

  initOrderType() {
    HttpHelper.post(URLDICT.STOCK_TRANSFER_ORDERTYPE, {}).then(res => {

      if (res.code == '000' && res.data.length > 0) {
        this.orderTypes = []
        res.data.forEach(element => {
          const data = {value: element.id, label: element.description, description: element.itemdesc, flag: null, itemCode: element.item, other: null, _data: element}
          this.orderTypes.push(data)
          // this.orderTypes.push({value: element.id, label: element.description, flag: null, itemCode: element.item, other: null, _data: element})
        });
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.orderTypes = []
      }
      // console.log(this.orderTypes);
    })
  }

  /* search() {
    let data = {
      "orderTypeItem": this.orderTypeItem,
      "orderNumber": this.orderNumber,
      "orderStatusItem": this.orderStatusItem,
      "fromChannel": this.fromChannel,
      "toWareHouse": this.toWareHouse,
      "creationDate": this.creationDate,
      "statusItem1": this.statusItem1,
      "statusItem2": this.statusItem2,
      "deliveryNote": this.deliveryNote,
      "pageIndex": this.pageIndex,
      "pageSize": this.pageSize,
    }
    //console.log(data);
    this.loadingSwitch = true

  } */

  refresh() {
    const list = ['orderType', 'from', 'to','fromStatusNature', 'toStatusNature', 'remark', 'noCartonBox']
    this.queryPannel.ipts.forEach((item) => {
      if (list.includes(item.name)) {
        if((item.name == 'orderType' && !this.isDraft) || item.name != 'orderType'){
          item.showValue = '';
          item.value = null;
        }
      }
    });
    this.items = null
    this.adjReasons = null
    this.seItem = null
    this.fromChannel = null
    this.toWareHouse = null
    this.orderTypeItem = null
    // this.orderStatusItem = null
    this.statusItem1 = null
    this.statusItem2 = null
    this.noCartonBox = null
    // this.orderNumber = null
    // this.deliveryNote = null
    // this.btnLabel = this.btnLabel
    this.setDisabled();
  }

  dealType(e) {
    // console.log("type:", e)
    // this.orderTypeItem = e;
    this.setDisabled();
    this.dealItem()
    this.dealToItem()
  }

  //根据channel联动sku数据
  dealItem() {
    if(this.orderTypeItem && this.fromChannel){
      this.loadFromNaturePre({orderTypeId: this.orderTypeItem, fromChannelId: this.fromChannel}, URLDICT.STOCK_TRANSFER_GETFROMNATURE, this.fromChannel, this.fromNatures)
    }
  }

  dealToItem() {
    if(this.orderTypeItem && this.toWareHouse){
      this.loadToNature({orderTypeId: this.orderTypeItem, toChannelId: this.toWareHouse}, URLDICT.STOCK_TRANSFER_GETTONATURE)
    }
  }

  loadFromNature(pa, url) {
    HttpHelper.post(url, pa).then(res => {
      this.fromStatus = []
      if (res.code == '000' && res.data.length > 0) {
        res.data.forEach(element => {
          this.fromStatus.push({value: element, label: element})
        });
      }
    })
    // console.log(this.fromNatures);
  }

  loadFromNaturePre(pa, url, channelId, conditionId) {
  // console.log('loadSKU(fromChange)', channelId, this.statusItem1)
    HttpHelper.post(url, pa).then(res => {
      this.fromStatus = []
      if (res.code == '000' && res.data.length > 0) {
        this.fromNatures = res.data
        let conditionBlackList = (<string[]>CONFIG.ORDER_CONDITION_BLACKLIST).map(e=>e.toLowerCase())
        //this.loadFromNaturePost(URLDICT.COMMON_SEARCH_SKUBYCHANNELID, {channelId: v, statusNatures: this.fromNatures})
        //sku only showed if channelid and conditionid are selected
        if (channelId && this.statusItem1)
          this.loadFromNaturePost(URLDICT.COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID, {channelId: channelId, conditionCode: this.statusItem1})
        res.data.forEach(element => {
          if(!conditionBlackList.includes(element.description.toLowerCase()))
            this.fromStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
        });
        const fromStatus = this.queryPannel.ipts.find((item) => item.name === "fromStatusNature");
        fromStatus.options = this.fromStatus;
        this.fromStatusTemplate = this.fromStatus;
        // console.log(this.fromStatus, fromStatus.options);
      }
    })
  }

  loadFromNaturePost(url, pa, cb:Function = ()=>{}) {
    HttpHelper.post(url, pa).then(res => {
      if (res.code == '000' && res.data.length > 0) {
        this.items = []
        res.data.forEach(element => {
          this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
        });
        if(cb){
          cb()
        }
        // console.log("skus:" + this.skus)
      } else {
        // this.n.warning("System", "There is no sku in the current channel!")
        this.showMessage('warn','System', 'There is no sku in the current channel!')
        this.items = []
      }
    })
    // console.log(this.fromNatures);
  }

  loadItemListByItemIdList(idList: string[], cb: Function = ()=>{}) {
    this.stockCommonService.getItemListByItemId({itemId: idList}).pipe().subscribe(res => {
      if (res) {
        this.items = []
        res.forEach(element => {
          this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
        });
        if(cb){
          cb()
        }
      }
    })
  }

  loadItemListBySkuModule(){
    return this.commonService.getSkuModuleList().pipe(
      // tap(res=>{
      //   this.items = [];
      //   res.forEach(element => {
      //     this.items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, _data: element})
      //   });
      // })
      switchMap(res=>{
        return this.stockCommonService.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      tap(res=>{
        if (res) {
          let _items = []
          res.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          this.items = _items;
        }
      })
    )
  }

  loadToNature(pa, url) {
    this.toStatus = []
    HttpHelper.post(url, pa).then(res => {
      if (res.code == '000' && res.data.length > 0) {
        //sku only showed if channelid and conditionid are selected
        let conditionBlackList = (<string[]>CONFIG.ORDER_CONDITION_BLACKLIST).map(e=>e.toLowerCase())
        this.toStatus = []
        res.data.forEach(element => {
          if(!conditionBlackList.includes(element.description.toLowerCase()))
            this.toStatus.push({code: element.code, description: element.description, value: element.code, name: element.description})
        });
      } else {
        this.toStatus = []
      }
      const toStatus = this.queryPannel.ipts.find( (item) => item.name === "toStatusNature" );
      toStatus.options = this.toStatus;
    })
    this.fromStatusTemplate = this.fromStatus;
    // console.log(this.toStatus);
  }

//根据item联动desc
  itemChange(e, data) {
    let haveItem = this.items.some(ele => {
      if (ele.code == e.value) {
        // console.log(ele)
        data.itemDesc = ele.flag//this.itemDesc
        data.itemId = ele.code//this.itemId
        // this.itemDesc = ele.flag
        // this.itemId = ele.code
        // this.selectedItems.push(ele)
        if(data.isserialcontrol!=ele._data.isserialcontrol){
          data.quantity = 0;
          data.serials = [];
        }
        data.isserialcontrol = ele._data.isserialcontrol;
        data.itemCode = ele.itemCode;
        return true
      }else{
        return false
      }
    })
    if(!haveItem){
      data.isserialcontrol = null
      data.itemDesc = null
      data.itemId = null
      data.itemCode = null;
      data.quantity = 0;
      data.serials = [];
    }
    // data.itemDesc = this.itemDesc
    // data.itemId = this.itemId
    // console.log("skuDesc:" + this.skuDesc)
  }

  adjustmentItemChange(e, data, items) {
    items.some(ele => {
      if (ele.code == e.value) {
        // console.log(ele)
        data.itemDesc = ele.flag//this.itemDesc
        data.itemId = ele.code//this.itemId
        // this.itemDesc = ele.flag
        // this.itemId = ele.code
        // this.selectedItems.push(ele)
        return true
      }else{
        return false
      }
    })
    // data.itemDesc = this.itemDesc
    // data.itemId = this.itemId
    // console.log("skuDesc:" + this.skuDesc)
  }
  /* page(e) {
    console.log(e);
    this.pageIndex = e.first / e.rows
    this.pageSize = e.rows
    this.search()
  } */

  edit(d) {
    // console.log(this)
    this.items = JSON.parse(localStorage.getItem("SKU"))
    this.channels = JSON.parse(localStorage.getItem("SHOP"))
    this.channels2 = JSON.parse(localStorage.getItem("WAREHOUSE"))
    this.itemValue = d['itemId']
    this.channelValue = d['fromChannel'] + ''
    this.channelValue2 = d['toWareHouse'] + ''
    this.qty = d['qty']
    this.status = d['status']
    if (d.id) {
      d.newid = d.id
      delete d.id
    }
    // console.log(d);
  }

  cancel(d) {
    //this.search()
    // this.clear()
    if (d.newid) {
      d.id = d.newid
      delete d.newid
    }
  }

  clear() {
    // fix statusItem1, statusItem2 removed when item delete before save & trigger validate fail
    // using refresh() to clear all transaction details
    // this.itemValue = ""
    // this.channelValue = ""
    // this.channelValue2 = ""
    // this.qty = ""
    // this.StockInQty = ""
    // this.orderTypeItem = null
    // this.orderStatusItem = ""
    // this.statusItem1 = ""
    // this.statusItem2 = ''
    // this.itemDesc = ""
  }

  delete(data) {
    if (data.id) {
      this.m.confirm({
        message: `Do you want to delete the transaction record?`,
        header: 'Delete Confirmation',
        accept: () => {
          HttpHelper.post(URLDICT.STOCK_RETURN_DELETE, data).then(res => {
            if (res.code == '000') {
              // this.search()
              this.lineData.splice(this.lineData.indexOf(data), 1)
              // this._adjustmentItemList[data.lineNumber] = undefined;
            } else {
              // this.n.error("System", "Submit Fail!")
              this.showMessage('error','System', 'Submit Fail!')
            }
          }).catch(e => {
            // this.n.error("System", e.toString())
            this.showMessage('error','System', e.toString())
          })
        }
      });
    }else{
      this.m.confirm({
        message: `Do you want to delete this transaction record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.lineData.splice(this.lineData.indexOf(data), 1)
          // this._adjustmentItemList[data.lineNumber] = undefined;
        }
      });
    }
    // this.lineData.splice(this.lineData.indexOf(data), 1)
    // this._adjustmentItemList[data.lineNumber] = undefined;
    this.editing = false;
    // this.clear()
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    // console.log(this.selectedColumns)
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    this.selectedColumnsLength = this._selectedColumns.length;
    this.rowexpansionColspan = this.selectedColumnsLength + 2;
  }
  selectedColumnsLength = 3;
  rowexpansionColspan = 5

  timestampToTime(timestamp) {
    return CommonMethod.timestampToTime(timestamp)
  }

  line = {}
  cloneLine = {}

  onRowEditInit(line) {
    this.cloneLine[line.id] = {...line};
  }

  onRowEditSave(line) {
    // if (line.price > 0) {
    delete this.cloneLine[line.id];
    // this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
    // }
    // else {
    // this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
    // }
  }

  onRowEditCancel(line, index: number) {
    this.line[index] = this.cloneLine[line.id];
    delete this.line[line.id];
  }

  onRowDelete(index1, index2) {
    // console.log(index1, index2)
    this.lineData[index1].serials.splice(index2, 1)
  }


  create() {
    // this.lineData.push({itemId:this.skuValue,quantity:this.quantity})
    if(this.loadingSwitch) return;
    this.lineData.push({
      // lineNumber: this.lineNumber,
      //trxTypeId: '6',
      itemId: this.itemValue,
      quantity: this.qty?this.qty:0,
      serials: [],
      _isNewLine: true,
    })
    this.editing = true
    return this.lineData[this.lineData.length-1]
  }

  createLine(index) {

    this.lineData[index].serials.push({serialNumber: ''})
  }

  save(d=null) {
    this.loadingSwitch = true
    // console.log("lineIndex:" + this.lineIndex)
    // let line: any[] = []
    // let serials: any[] = []
    // Object.assign(this.data, d)
    this.data['dmDnNumber'] = this.deliveryNote
    this.data['orderNumber'] = this.orderNumber
    this.data['orderTypeId'] = this.orderTypeItem
    this.data['fromChannelId'] = this.fromChannel
    this.data['toChannelId'] = this.toWareHouse
    this.data['fromStatusNature'] = this.statusItem1
    this.data['toStatusNature'] = this.statusItem2
    this.data['orderStatusCode'] = this.orderStatusId //?.code // orderStatusItem already save as code
    this.data['remarks'] = this.remark
    this.data['noCartonBox'] = this.noCartonBox
    this.data['creationDate'] = this.creationDate
    this.data['internalReference'] = this.internalReference
    this.data['internalRemarks'] = this.internalRemarks

    let _lineData = this.lineData.map((line, idx)=>{
      return {
        ...line,
        lineNumber: idx+1,
        reasonId: line.reason,
        finalReasonId: line.finalReason
      }
    })
    this.data['line'] = _lineData
    // console.log(this.data);

    // this.n.info("Transfer", "Save Successful")
    d && this.showMessage('info','Transfer', 'Save Successful')
    this.loadingSwitch = false
    this.editing = false
  }
  async validationData(){
    this.save()
    // console.log('validationData: ', this.data)
    if (
      this.selectedType !== SELECTEDTYPE.ADJUSTMENT &&
      this.selectedType !== SELECTEDTYPE.ADJUSTMENTHKTHOME &&
      this.selectedType !== SELECTEDTYPE.COLORADJUSTMENT &&
      this.selectedType !== SELECTEDTYPE.ASSORTMENT &&
      this.selectedType !== SELECTEDTYPE.NOPREPLENISHMENT &&
      this.selectedType !== SELECTEDTYPE.RETURN &&
      this.selectedType !== SELECTEDTYPE.RETURNWOCOURIER &&
      (
        !this.data['fromChannelId'] ||
        !this.data['fromStatusNature'] ||
        !this.data['toChannelId'] ||
        !this.data['toStatusNature'] ||
        !this.data['orderTypeId']
      )
    ) {
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if((
      this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
      this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME ||
      this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
    )&& !this.data['fromChannelId']){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.RETURN &&
      (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['toChannelId'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.RETURNWOCOURIER &&
      (!this.data['fromChannelId'] || !this.data['fromStatusNature'] || !this.data['toChannelId'] || !this.data['orderTypeId'])){
      this.showMessage('warn','System', 'Please fill in the content of creation area first!')
      return false
    }
    if(this.selectedType == SELECTEDTYPE.RETURN){
      let warehouseIdList = this.warehouseList.map(data=>data.id)
      if(warehouseIdList.indexOf(this.data['toChannelId'])==-1){
        // this.showMessage('warn','System', 'Channel to Warehouse is Selected, Please Select Warehouse in "To" Field!')
        // return false
      }
    }
    if((this.selectedType == SELECTEDTYPE.CHANNELTRANSFER || this.selectedType == SELECTEDTYPE.CHANNELTRANSFERCOURIER) &&
      (this.data['fromChannelId'] && this.data['fromStatusNature'] && this.data['toChannelId'] && this.data['toStatusNature'] && this.data['orderTypeId']) &&
      (this.data['fromChannelId'] == this.data['toChannelId'])
    ){
      let _type = this.orderTypes.find(e=>e.id==this.orderTypeItem)
      this.showMessage('warn','System', `${_type.description} not allow same channel as from, to channel`)
      return false
    }
    if ( this.data['line'].length === 0) {
      this.showMessage('warn','System', 'Please create a new transfer order first!')
      return false
    }else{
      for (const item of this.data['line']) {
        if(!item.itemId || !item.quantity){
          this.showMessage('warn','System', 'Please fill in the content of transfer order first!')
          return false
        }
        if(
          this.selectedType == SELECTEDTYPE.ADJUSTMENT ||
          this.selectedType == SELECTEDTYPE.ADJUSTMENTHKTHOME ||
          this.selectedType == SELECTEDTYPE.COLORADJUSTMENT
        ){
          if(!item.fromStockConditionId){
            this.showMessage('warn','System', 'Please fill in the content of transfer order first!')
            return false
          }

          if(item.reason){
            let reasonId = item.reason;
            // use final reason to check when final reason editable (submitted / ADJUSTIP1)
            if(this.finalReasonEditable){reasonId = item.finalReason}
            let lineReason = this._reasonOptions.find(reason=>reason.id==item.reason)
            if(item.quantity > 0 && lineReason.isAllowPositive != 'Y'){
              this.showMessage('warn','System', `Reason: "${lineReason.reasonDescription}" can't input positive quantity!`)
              return false
            }
            if(item.quantity < 0 && lineReason.isAllowNegative != 'Y'){
              this.showMessage('warn','System', `Reason: "${lineReason.reasonDescription}" can't input negative quantity!`)
              return false
            }
          }
        }
      }
    }
    if(this.orderTypeItem==ORDERTYPEID.COLORADJUSTMENT){
      let itemCodeList = []
      let haveDuplicateItem = this.lineData.some(line=>{
        if(itemCodeList.includes(line.itemId)) return true
        itemCodeList.push(line.itemId)
        return false
      })
      if(haveDuplicateItem){
        this.showMessage('error','System', 'Item Should be unique!')
        return false
      }
    }
    let _orderType = this.orderTypes.find(e=>e.code == this.data.orderTypeId);
    if(_orderType.orderTypeCode == SELECTEDTYPE.CHANGECONDITION ){
      if(this.data.fromStatusNature == this.data.toStatusNature){
        this.showMessage('warn','System', 'From Status Condition and To Status Condition should be different!')
        return false
      }
    }
    if(_orderType.orderTypeCode == SELECTEDTYPE.CHANGECONDITION || _orderType.orderTypeCode == SELECTEDTYPE.CHANNELTRANSFER ){
      let haveNonUnique = this.data.line.some((element, idx) => {
        let duplic = this.data.line.find((e, i)=>{
          return e.itemId == element.itemId && idx != i
        })
        if(duplic){return true}else{return false}
      });
      if(haveNonUnique){
        this.showMessage('warn','System', 'Transfer Offer Item Should be unique!')
        return false
      }
    }

    if(this.fromStockTakeAdjustment && this.isDraft){
      let haveNewLine = this.lineData.some(line=>line._isNewLine)
      if(haveNewLine){
        let confirmedAddItem = await this.confirmAddItem()
        if(!confirmedAddItem) return false
      }
    }
    return true
  }
  confirmAddItem(){
    let {resolver, rejector, promise} = CommonMethod.createNewPromise();
    this.m.confirm({
      message: `Do you sure to add item to this stock take adjustment?`,
      header: 'Confirmation',
      accept: () => {
        resolver(true);
      },
      reject: () =>{
        resolver(false);
      },
    });
    return promise
  }
  //提交数据
  async submit(data) {
    // rename orderStatusItem to orderStatusItemObj since this row use for store the object and edit object.value later
    // const orderStatusItemObj = this.queryPannel.ipts.find(item=>item.name==='orderStatusItem')
    const flag = await this.validationData()
    if(!flag){
      return
    }
    if(this.isAllowEdit){
      this.loadingSwitch = true
      // const orderNum = this.orderTypeItem?.code || this.orderTypeItem
      // const url = URLDICT.STOCK_ORDER_EDIT;
      // if (url) {
      const btn = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.SAVEASDRAFT)
      btn.disabled = true;
      // const btnConfirm = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.UPDATETONEXTSTATUS)
      // const btnClear = this.queryPannel.btns.find(item=>item.tag===PANELBTNTAG.CLEARALL)
      // console.log('before update', data)
      this.stockCommonService.updateStockOrder(data).subscribe(res=>{
        btn.disabled = false;
        if (res.code === '000') {
          this.headId = res.data[0].headId;
          this.showMessage('success', null, 'Save Success')
          this.routeToCreate()
        } else {
          if(!res.error){
            res.error = res.msg || res.data[0].errorMsg
          }
          this.showMessage('error','System', res.error)
        }
      },
      e => {
        btn.disabled = false;
        this.showMessage('error','System', e.toString())
      },
      () => {
        this.loadingSwitch = false
        btn.disabled = false;
      })
      // } else {
      //   this.loadingSwitch = false
      // }
    }
  }
  customSort(e){}
  /**
   * 返回上一页
   */
  @action back() {
    window.history.back()
  }
  showMessage(severity, summary, detail){
    // console.error(`trace showMessage: ${severity} | ${summary} | ${detail}`)
    this.n.add({severity, summary, detail});
  }


  setTableColBySelectedType(selectedType = this.selectedType){
    // switch(selectedType){
    //   case SELECTEDTYPE.CHANGECONDITION:
    //   case SELECTEDTYPE.CHANNELTRANSFER:
    //     this.cols = this.originColList.filter(e => ['reason', 'statusCondition'].indexOf(e.header) == -1)
    //     this.selectedColumns = this.cols;
    //     this.isQtyPositive = true;
    //     break;
    //   case SELECTEDTYPE.ADJUSTMENT:
    //     this.cols = this.originColList //.filter(e => [].indexOf(e.header) == -1)
    //     this.selectedColumns = this.cols;
    //     this.isQtyPositive = false;
    //     break;
    //   default:
    //     this.cols = this.originColList.filter(e => ['reason', 'statusCondition'].indexOf(e.header) == -1)
    //     this.selectedColumns = this.cols;
    //     this.isQtyPositive = false;
    // }

    switch(selectedType){
      case SELECTEDTYPE.CHANGECONDITION:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = true;
        break;
      case SELECTEDTYPE.CHANNELTRANSFER:
      case SELECTEDTYPE.CHANNELTRANSFERCOURIER:
      case SELECTEDTYPE.ASSORTMENT:
      case SELECTEDTYPE.NOPREPLENISHMENT:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'qtyActual', 'qtyCancelled', 'qtyWriteOff', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = true;
        break;
      case SELECTEDTYPE.ADJUSTMENT:
      case SELECTEDTYPE.ADJUSTMENTHKTHOME:
      case SELECTEDTYPE.COLORADJUSTMENT:
        this.cols = this.originColList.filter(e => ['statusCondition', 'itemCode', 'item', 'qty', 'reason', 'finalReason', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = false;
        break;
      default:
        this.cols = this.originColList.filter(e => ['itemCode', 'item', 'qty', 'remark'].indexOf(e.header) !== -1)
        this.selectedColumns = this.cols;
        this.isQtyPositive = false;
    }
  }
  handleIptWithSelectedType(selectedType = this.selectedType, setDisabled = true){
    // selectedType set when orderType change
    // wip: this should handle in setDisabled()
    if(selectedType && this.isEditable){
      // normal draft / normal draft adjustment / rejected stock take adjustment
      switch(selectedType){
        case SELECTEDTYPE.ADJUSTMENT:
        case SELECTEDTYPE.ADJUSTMENTHKTHOME:
        case SELECTEDTYPE.COLORADJUSTMENT:
          // set all disabled except from when adjustment
          this.remarkEditable = (
            this.orderTypeItem == ORDERTYPEID.ADJUSTMENT
            || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME
            || this.orderTypeItem==ORDERTYPEID.COLORADJUSTMENT
          ) && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
          if(!this.fromStockTakeAdjustment){
            this.queryPannel.ipts.forEach(element => {
              switch(element.title){
                case TITLE.TRANSACTION_TYPE:
                  if(this.isDraft){
                    element.disabled = true;
                  }else if(setDisabled){element.disabled = false;}
                  break;
                case TITLE.FROM:
                case TITLE.REMARK:
                  if(setDisabled)element.disabled = !this.remarkEditable;
                  break;
                case TITLE.FROM_STATUS_CONDITION:
                case TITLE.TO:
                case TITLE.TO_STATUS_CONDITION:
                  // element value reset by setIptDisabled()
                  // element.value = null;
                  // element.showValue = null;
                  if(setDisabled)element.disabled = true;
                  break;
                case TITLE.internalReference:
                  if(setDisabled){
                    element.disabled = this.orderStatusId!=STATUSID.ADJ_ADJUSTIP1
                  }
                  break;
                default:
                  if(setDisabled)element.disabled = true;
              }
            });
            // reset to, tostatuscondition, fromstatuscondition value
            this.toWareHouse = null;
            this.statusItem1 = null;
            this.statusItem2 = null;
          }else{
            // from stock take but isEditable passed (rejected), not allow change any ipt except internalReference when adjustip1
            this.queryPannel.ipts.forEach(element => {
              switch(element.title){
                case TITLE.internalReference:
                  if(setDisabled){
                    element.disabled = this.orderStatusId!=STATUSID.ADJ_ADJUSTIP1
                  }
                  break;
                case TITLE.REMARK:
                  if(setDisabled){
                    element.disabled = !this.remarkEditable
                  }
                  break;
                default:
                  if(setDisabled)element.disabled = true;
              }
            });
            // reset to, tostatuscondition, fromstatuscondition value
            this.toWareHouse = null;
            this.statusItem1 = null;
            this.statusItem2 = null;
          }
          break;
        case SELECTEDTYPE.CHANGECONDITION:
          // disable to and set to as from value
          this.toWareHouse = this.fromChannel;
          let toIpt = this.queryPannel.ipts.find(e=>e.name=="to");
          let fromIpt = this.queryPannel.ipts.find(e=>e.name=="from");
          if(toIpt){
            if(setDisabled)toIpt.disabled = true;
            toIpt.value = fromIpt.value;
            toIpt.showValue = fromIpt.showValue;
            this.toWareHouse = this.fromChannel;
          }
          break;
        case SELECTEDTYPE.RETURN:
        case SELECTEDTYPE.RETURNWOCOURIER:
          this.queryPannel.ipts.forEach(element => {
            switch(element.title){
              case TITLE.TRANSACTION_TYPE:
                if(this.isDraft){
                  element.disabled = true;
                }else if(setDisabled){element.disabled = false;}
                break;
              case TITLE.FROM:
              case TITLE.FROM_STATUS_CONDITION:
              case TITLE.TO:
              case TITLE.REMARK:
              case TITLE.NO_OF_CARTON_BOX:
                if(setDisabled)element.disabled = false;
                break;
              case TITLE.TO_STATUS_CONDITION:
                if(setDisabled)element.disabled = true;
                break;
              default:
                if(setDisabled)element.disabled = true;
            }
          });
          break;
      }
    }else if(selectedType&&this.headId){
      // isEditable failed
      // not draft / non rejected stock take adjustment
      this.remarkEditable = (
        this.orderTypeItem == ORDERTYPEID.ADJUSTMENT
        || this.orderTypeItem == ORDERTYPEID.ADJUSTMENTHKTHOME
      ) && [STATUSID.ADJ_DRAFT,STATUSID.ADJ_REVIEWING].indexOf(this.orderStatusId)>-1
      switch(selectedType){
        case SELECTEDTYPE.ADJUSTMENT:
        case SELECTEDTYPE.ADJUSTMENTHKTHOME:
        case SELECTEDTYPE.COLORADJUSTMENT:
          // editable failed (not rejected adjustment or normal adjustment, i.e. should be adjustment from stock take)
          // if(this.fromStockTakeAdjustment){
          // non draft adjustment
          // editable internalReference, REMARK in differ status, all other ipt disable in non draft status
          this.queryPannel.ipts.forEach(element => {
            switch(element.title){
              case TITLE.internalReference:
                if(setDisabled){
                  element.disabled = this.orderStatusId!=STATUSID.ADJ_ADJUSTIP1
                }
                break;
              case TITLE.REMARK:
                if(setDisabled){
                  element.disabled = !this.remarkEditable
                }
                break;
              default:
                if(setDisabled)element.disabled = true;
            }
          });
          // reset to, tostatuscondition, fromstatuscondition value
          this.toWareHouse = null;
          this.statusItem1 = null;
          this.statusItem2 = null;
          // }
          break;
      }
    }
  }

  onAdjustmentItemStatusConditionChange(e, data){
    // call api /common/searchItemByChannelIdAndCondition, update row item options by result
    if(data.fromStockConditionId){
      // this.loadingSwitch = true;
      // this.loadAdjustmentItemsByStatusCondition(this.fromChannel, data.fromStockConditionId).subscribe(
      //   (res)=>{
      //     if (res.code == '000' && res.data.length > 0) {
      //       let items = []
      //       res.data.forEach(element => {
      //         items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, _data: element})
      //       });
      //       // this._adjustmentItemList[data.lineNumber] = items
      //     } else {
      //       this.showMessage('warn','System', 'There is no sku in the current channel!')
      //       // this._adjustmentItemList[data.lineNumber] = []
      //     }
      //   },
      //   (err)=>{
      //     // this.showMessage('warn','System', err)
      //     // this.loadingSwitch = false;
      //   },
      //   // ()=>{this.loadingSwitch = false;}
      // )
    }else{
      // reset selected row item on status condition unselected
      // this._adjustmentItemList[data.lineNumber] = null;
      data.itemId = null;
    }
  }

  clearTable(){
    this.lineData = [];
    // this._adjustmentItemList = {};
  }

  loadAdjustmentItemsByStatusCondition(fromChannel, stockConditionId){
    return this.stockCommonService.getItemsByChannelIdAndCondition(fromChannel, stockConditionId)
  }

  getConditionFromShopAndWarehouseById(id, list:any[] = this.shopAndWarehouse){
    if(id){
      let target = null
      list.forEach(child=>{
        if(child.data == id) {
          target = child;
        }else if(child.children && child.children.length>0){
          let res = this.getConditionFromShopAndWarehouseById(id, child.children)
          if(res) target = res
        }
      })
      return target
    }else{
      return null
    }
  }

  serialModalConfig: any = {
    itemCode: {},
    itemDesc: {},
    actionQty: {},
    qty: 0,
    serialList: [],
    header: 'Input Serial Number',
    header_readonly: 'Serial Number',
    newSerialValue: null,
    range: {},
    index: null,
    editable: false,
  }

  editSerial(index, editable = true) {
    let that = this
    if(this.loadingSwitch) return;
    let data = this.lineData[index];
    // let itemList: any[] = this.selectedType == SELECTEDTYPE.ADJUSTMENT? this._adjustmentItemList[this.lineData[index].lineNumber]: this.items;
    let itemList: any[] = this.items;
    let item = itemList.find(e=>e.code==data.itemId)
    let isNegative = data.quantity < 0;
    let _editable = this.isEditable || (this.ignoreIsEditAllowAddDeleteLine && data._isNewLine)
    this.serialModalConfig = {
      ...this.serialModalConfig,
      editable: _editable,
      itemCode: {value: item._data.item, disabled: true, title: 'Item Code'},
      itemDesc: {value: item._data.itemdesc, disabled: true, title: 'Item Desc.'},
      // actionQty: {value: `${data.serials?data.serials.length:0} / ${data.quantity?data.quantity:0}`, disabled: true, title: 'Action Qty'},
      actionQty: {value: `${data.serials?data.serials.length*(isNegative?-1:1):0}`, disabled: true, title: 'Action Qty'},
      isNegative: {value: isNegative?['serialNegative']:[], disabled: !(_editable && this.showC), title: 'Negative Qty', name: 'serialNegative', group: 'serialNegative', onChange: (e)=>{
        that.serialPopupIsNegativeChanged(e)
      }},
      qty: data.quantity?data.quantity:0,
      serialList: data.serials?JSON.parse(JSON.stringify(data.serials)):null,
      // header: `Create / Edit Serial`,
      newSerialValue: null,
      range: {},
      index: index,
      _isNegative: isNegative,
    }
    this.setSerialModalVisible(true)
    // this.lineData[index].serials.push({serialNumber: ''})
  }

  serialPopupIsNegativeChanged(e){
    this.serialModalConfig._isNegative = this.serialModalConfig.isNegative.value.length>0;
    this.serialModalConfig.actionQty.value = Math.abs(this.serialModalConfig.actionQty.value) * (this.serialModalConfig._isNegative?-1:1)
  }

  showSerial(e){
    if(this.loadingSwitch) return;
    this.editSerial(e, false)
  }

  resetSerialModalConfig(){
    this.serialModalConfig = {
      itemCode: {},
      itemDesc: {},
      actionQty: {},
      qty: 0,
      serialList: [],
      header: 'Input Serial Number',
      header_readonly: 'Serial Number',
      newSerialValue: null,
      range: {},
      index: null,
    }
  }

  setSerialModalVisible(e){
    if(e){this.setSerialList()}
    this.serialModalConfig.visible = e;
  }

  addSerialByInput(e?){
    // this.serialModalConfig.serialList.push({serial: this.serialModalConfig.newSerialValue})
    let success = this.addToSerialList(this.serialModalConfig.newSerialValue, true)
    this.setSerialList()
    // this.showMessage('info', 'Add Success', `Serial Number ${this.serialModalConfig.newSerialValue} Added`)
    if(success){
      this.showMessage('info', 'Add Success', `Serial Number ${this.serialModalConfig.newSerialValue} added`)
    }else{
      this.showMessage('warn', 'Add Fail', `Serial Number ${this.serialModalConfig.newSerialValue} duplicated`)
    }
    this.serialModalConfig.newSerialValue = null;
  }

  enableScan(e?){

  }

  addSerialByRange(){
    // this.
    let prefix = this.serialModalConfig.range.prefix?this.serialModalConfig.range.prefix:'';
    let suffix = this.serialModalConfig.range.suffix?this.serialModalConfig.range.suffix:'';
    let from = this.serialModalConfig.range.from;
    let to = this.serialModalConfig.range.to;
    let fromInt = Number.parseInt(from, 10);
    let toInt = Number.parseInt(to, 10);
    if (
      from && to &&
      from.length == to.length &&
      !isNaN(fromInt) &&
      !isNaN(toInt) &&
      fromInt <= toInt
    ) {
      let successCount = 0;
      let duplicateCount = 0;
      for( let i = fromInt; i <= toInt; i++ ){
        let value = String(i).padStart(from.length, '0')
        // this.serialModalConfig.serialList.push({serial: `${prefix}${value}${suffix}`})
        let success = this.addToSerialList(`${prefix}${value}${suffix}`)
        if(success){successCount++}else{duplicateCount++}
      }
      this.setSerialList()
      if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
      if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      this.serialModalConfig.range = {}
    }
  }

  serialModalRangeChange(){
    let from = this.serialModalConfig.range.from;
    let to = this.serialModalConfig.range.to;
    let fromInt = Number.parseInt(from, 10)
    let toInt = Number.parseInt(to, 10)
    if (
      from && to &&
      from.length == to.length &&
      !isNaN(fromInt) &&
      !isNaN(toInt) &&
      fromInt <= toInt
    ) {
      this.serialModalConfig.range.total = toInt - fromInt + 1
    }else{
      this.serialModalConfig.range.total = 0
    }
  }

  saveSerial(){
    let idx = this.serialModalConfig.index
    if(idx!=null) this.lineData[idx].serials = JSON.parse(JSON.stringify(this.serialModalConfig.serialList))
    this.lineData[idx].quantity = this.lineData[idx].serials?this.lineData[idx].serials.length*(this.serialModalConfig._isNegative?-1:1):0;
    this.resetSerialModalConfig()
    this.setSerialModalVisible(false)
  }

  onSerialRowDelete(data){
    if(this.loadingSwitch) return;
    this.m.confirm({
      message: `Do you want to delete this serial number?`,
      header: 'Delete Confirmation',
      accept: () => {
        let idx = this.serialModalConfig.serialList.findIndex(e=>data.serial == e.serial);
        (<any[]>this.serialModalConfig.serialList).splice(idx, 1)
        this.setSerialList(false)
        this.serialModalConfig.actionQty = {value: `${this.serialModalConfig.serialList.length}`, disabled: true, title: 'Action Qty'}
      }
    });
    // let idx = this.serialModalConfig.serialList.findIndex(e=>data === e);
    // (<any[]>this.serialModalConfig.serialList).splice(idx, 1)
    // this.serialModalConfig.actionQty = {value: `${this.serialModalConfig.serialList.length}`, disabled: true, title: 'Action Qty'}
  }

  formats = CONFIG.SCANNERFORMAT
  SCANNERTRYHARDER = CONFIG.SCANNERTRYHARDER
  isScannerEnable = false;

  toggleScanner(e){
    this.isScannerEnable = e;
    if(e==false && this.scanner){
      this.scanner.enable = false;
    }
  }
  deviceSelected
  deviceCurrent
  hasPermission
  availableDevices
  hasDevices

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null;
    if (this.deviceSelected === selectedDevice) { return; }
    this.deviceSelected = selectedDevice;
    this.deviceCurrent = device || undefined;
  }

  onCodeResult(res: string) {
    let duplicateScan = this.serialModalConfig.serialList.some(e=>e.serial==res);
    if(!duplicateScan){
      // this.serialModalConfig.serialList.push({serial: res})
      this.addToSerialList(res, true)
      this.setSerialList()
      this.showMessage('info', 'Scan Success', `Serial Number ${res} added`)
    }
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onDeviceSelectChange(selected) {
    const selectedStr = selected.value.deviceId || '';
    // if (this.deviceSelected === selected.value) { return; }
    // this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selectedStr);
    this.deviceCurrent = device || null;
  }

  serialFileDropped(e: any){
    let reader = new FileReader();
    e[0].fileEntry.file(file=>{
      reader.readAsText(file)
      reader.onload=()=>{
        let res = (<string>reader.result).split(/\r?\n/).filter(content=>content.length>0);
        let successCount = 0;
        let duplicateCount = 0;
        res.forEach(val=>{
          let success = this.addToSerialList(val)
          if(success){successCount++}else{duplicateCount++}
        });
        this.setSerialList()
        if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
        if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      }
    })
  }

  addToSerialList(e, isUnshift = false){
    let val = e.trim()
    let duplicate = false
    duplicate = this.serialModalConfig.serialList.some(serialItem=>serialItem.serial==val);
    if(!duplicate){
      if(!isUnshift){
        this.serialModalConfig.serialList.push({serial: val})
      }else{
        this.serialModalConfig.serialList.unshift({serial: val})
      }
    }
    // this.serialModalConfig.actionQty = {value: `${this.serialModalConfig.serialList.length} / ${this.serialModalConfig.qty?this.serialModalConfig.qty:0}`, disabled: true, title: 'Action Qty'}
    this.serialModalConfig.actionQty = {value: `${this.serialModalConfig._isNegative?'-':''}${this.serialModalConfig.serialList.length}`, disabled: true, title: 'Action Qty'}
    return !duplicate;
  }

  routeToCreate(){
    this.r.navigate(['main', 'transfer_order', 'create'], {queryParams: {headId: this.headId}})
  }

  lineReasonChanged(data){
    data.finalReason = data.reason
  }

  getReasonOptions(){
    let reasons = [];
    switch(this.selectedType){
      case SELECTEDTYPE.ADJUSTMENT:
      case SELECTEDTYPE.ADJUSTMENTHKTHOME:
        reasons = this.adjReasons
        break;
      case SELECTEDTYPE.COLORADJUSTMENT:
        reasons = this.colorAdjReasons
        break;
    }
    return reasons.map(reason=>{
      return {
        ...reason,
        displayDesc: `${reason.reasonCode} - ${reason.reasonDescription}`
      }
    })
  }

  initReason(){
    this._reasonOptions = this.getReasonOptions()
  }
  _serialList=[]
  _bufferSerialList=[]
  serialFilter=''

  setSerialList(reset = true){
    let serialList = this.serialModalConfig.serialList? this.serialModalConfig.serialList: []
    if(reset){
      this._serialList = serialList
      this._bufferSerialList = JSON.parse(JSON.stringify(serialList))
      this.resetSerialFilter();
    }else{
      this._bufferSerialList = JSON.parse(JSON.stringify(serialList))
      this.serialFilterChanged(this.serialFilter);
    }
  }

  serialFilterChanged(e){
    let filter = e.toLowerCase()
    this._serialList = this._bufferSerialList.filter(serial=>{
      return serial.serial.toLowerCase().indexOf(filter) >= 0
    })
  }
  resetSerialFilter(){
    this.serialFilter = ''
  }

  clearFilter(){
    this.serialFilter = '';
    this.serialFilterChanged(this.serialFilter);
  }

  selectedSerialRow

  serialBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    // this.onMultiDelete.emit(this.selectedSerialRow)
    let res = this.serialModalConfig.serialList.filter(e=>!this.selectedSerialRow.includes(e))
    this.selectedSerialRow = [];
    this.serialModalConfig.serialList = res;
    this.setSerialList()
    this.serialModalConfig.actionQty = {
      value: `${this.serialModalConfig._isNegative?'-':''}${this.serialModalConfig.serialList.length}`,
      disabled: true, title: 'Action Qty'
    }
  }

  confirmSerialBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    let totalRemove = this.serialModalConfig.serialList.filter(e=>this.selectedSerialRow.includes(e))
    this.m.confirm({
      message: 'Are you sure to delete selected serial numbers?',
      header: `Delete Serial Number`,
      accept: () => {
        this.serialBulkDelete()
      }
      // rejectLabel: 'Close'
    });
  }

  updateToOptionsByType(){
    let toIptIdx = this.queryPannel.ipts.findIndex(ipt=>ipt.title==TITLE.TO)
    if(this.selectedType === SELECTEDTYPE.RETURN){
      this.toOptions = this.warehouseListForTreeSelectOptions
    }else{
      this.toOptions = this.filterInactiveNodes(this.shopAndWarehouse)
      //this.toOptions = this.shopAndWarehouse
    }
    this.queryPannel.ipts[toIptIdx] = {...this.queryPannel.ipts[toIptIdx], options: this.toOptions}
  }

  filterInactiveNodes(data) {
    // 递归函数，用于过滤掉 isActive 为 "N" 的节点
    function filter(node) {
      if (node.children && node.children.length > 0) {
        node.children = node.children.filter(child => filter(child));
      }
      return node.isActive !== "N";
    }

    // 遍历顶层节点
    return data.filter(node => filter(node));
  }
}
