import { CommonService } from "@/service/common/common-service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { pluck } from "rxjs/operators";
import { StockDemandCycleService } from "src/app/service/stock/stock-demand-cycle.service";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";


export class ReplenishmentStore {
  TITLE = {
    DEMAND_CYCLE_NAME: "Demand Cycle Name",
    STOCK_POOL_TYPE: "Stock Pool Type",
    DEMAND_CYCLE_TYPE: "Demand Cycle Type",
    SUBMISSION_DEADLINE: "Demand Deadline",
    DEMAND_START_DATE: "Tentative Delivery Date",
    DEMAND_END_DATE: "Demand End Date",
  }
  QUERY_TITLE = {
    CHANNEL: "Channel (warehouse)",
    ROADSHOW_CHANNEL: "Channel",
    REFERENCE_NUMBER: "Reference Number",
    STATUS: "Status",
    BU_CODE: "Item BU",
    NEED_BY_DATE: "Need by Date",
    REMARKS: "Remarks",
    FIELDSET: "For BYOD Use",
    UPDATED_BY: "Updated By",
    UPDATED_DATETIME: "Updated Datetime",
    SUBMITTED_BY: "Submitted By",
    SUBMITTED_DATETIME: "Submitted Datetime",
    // INTERFACED_DATETIME: "Interfaced Datetime",
    ONLY_INCLUDE : 'Only include item(s) have Channel Demand',
    IS_AODO: 'Only include item(s) have AO / DO',
    IS_SERIALIZED: 'Only include serialized item(s)',
    // ONLY_INCLUDE : 'Only include item(s) have demand request',
    SCHOOL_CODE: "School Code",
    SCHOOL_LOCATION_CODE: "School Location Code",
    SCHOOL_NUMBER: "School Code / Location ID",
    CONTACT_PERSON: "Contact Person Name",
    CONTACT_PHONE: "Contact Phone Number",
    ADDRESS_1: "Address 1",
    ADDRESS_2: "Address 2",
    ADDRESS_3: "Address 3",
    ROADSHOW_FIELDSET: "For RoadShow Use",
    ROADSHOW_COURIER: "Override Courier",
    ROADSHOW_ADDRESS_1: "Address 1",
    ROADSHOW_ADDRESS_2: "Address 2",
    ROADSHOW_ADDRESS_3: "Address 3",
    BUCODE: 'BU Code',
    LOBCODE: 'LOB Code',
    MAINCAT: 'Main cat',
    SUBCAT: 'Sub Cat',
    BRAND: 'Brand',
    SUBSUBCAT: 'Sub-Sub Cat',
    ITEMCODE: 'Item Code',
    LISRESERVATIONSTATUS: 'LIS Reservation Status'
  }
  public BUTTON_TITLE = {
    SEARCH: "Search",
    CREATE_REPLENISHMENT: "Create",
    BACK: "Back",
    DELETE: "Delete",
    CLEAR: "Clear",
    // GENERATE_ITEM_DETAIL : "Generate Item Detail",
    SAVE: "Save",
    CANCEL: "Cancel",
    CANCEL_LIS_RESERVATION: "Cancel LIS Rsv",
    EDIT: "Edit",
    VERIFY: "Verify",
    RESERVE_LIS: "Reserve LIS",
    RESERVE_LIS_NOP: "Reserve LIS For Delivery",
    SUBMIT_TO_LIS: "Submit to LIS",
    SUBMIT_TO_DM: "Submit to DM",
    EXPORTPREALLOCATE: "Export Pre-Allocated Reservation",
    EXPORT: 'Export',
    RE_RESERVE_LIS: "Re-Reserve LIS",
    RE_SUBMIT_TO_LIS: "Re-Submit to LIS",
    REFRESH_WAREHOUSE_QTY: "Refresh Warehouse Qty",
    UPDATE_WAREHOUSE_QTY: "Update Warehouse Qty",
    REFRESH_STATUS: "Refresh Status",
    ADD_ITEM: "Add Item By Item Code",
    ADD_ITEMS_BY_BU_GROUP_TITLE: "Add Items By BU And By Channel",
    ADD_ITEMS_BY_BU: "Add Items By Channel",
    ADD_ITEMS_BY_BU_EXPORT: 'Export By Channel',
    ADD_ITEMS_BY_BU_ALL_CHANNEL_GROUP_TITLE: "Select BU",
    ADD_ITEMS_BY_BU_EXPORT_ALL_CHANNEL: 'Export All Channel',
  }
  TABLE_FIELD = {
    CHANNEL: "Channel",
    REFERENCE_NUMBER: "Reference Number",
    BU_CODE: "Item BU",
    STATUS: "Status",
    NEED_BY_DATE: "Need by Date",
    TOTAL_NO_OF_ITEMS: "Total no. of items",
    REMARKS: "Remarks",
    UPDATED_BY: "Updated By",
    UPDATED_DATETIME: "Updated Datetime",
    LIS_RESERVATION_STATUS: "Lis Reservation Status",
    SUBMITTED_BY: "Submitted By",
    SUBMITTED_DATETIME: "Submitted Datetime",
    BU: "BU",
    LOB: "LOB",
    CHANNEL_NATURE: "Channel Nature",
    IC_CODE: "IC Code (LIS subinv)",
    ITEM_NUMBER: "Item Number",
    DESCRIPTION: "Description",
    MODEL: "Model",
    UOM: "UOM",
    P30: "P30",
    P21: "P21",
    P14: "P14",
    P07: "P07",
    AVAILABLE: "Available",
    RESERVED: "Reserved",
    AO: "AO",
    TRANSIT_IN: "Transit-in",
    DO: "DO",
    TOTAL_AVAILABLE: "Total FG",
    TOTAL_ON_HAND: "Total On-hand",
    CHANNEL_REQUEST: "Channel Request",
    PRE_ALLOCATE_QTY: "Pre-Allocate Qty",
    ALLOCATE_QTY: "Allocate Qty",
    USERINPUT_QTY: "Additional Qty",
    W003: "W003",
    W003_DEMANDED: "W003 Demanded",
    W005: "W005",
    W006: "W006",
    W007: "W007",
    W008: "W008",
    W009: "W009",
    W010: "W010",
    W011: "W011",
    W012: "W012",
    W013: "W013",
    PSW: "PSW",
    INTERFACED_STATUS: "Interfaced Status",
    LIS_RESERVATION_ID: "Lis Reservation Id",
    INTERFACED_DATE: "Interfaced Datetime",
    ISO_NUMBER: "ISO Number (LIS)",
    SYSTEM_MESSAGE: "System Message",
    DOC: "DOC",
    nopDeliveryQty: "NOP Delivery Qty",
    NOP_ORDER_NO: 'NOP Order No',
    NOP_ORDER_DELIVERYQTY: 'Delivery Qty',
    NOP_ORDER_STATUS: 'Status',
    NOP_ORDER_ERR_MSG: 'Error Message',
    RESERVATION_ID: 'Reservation Id',
    IR_BATCH: "IR Batch",
    NOP_ORDER_RESERVE_STATUS: "Reserve Status",
    NOP_ORDER_RESERVE_MSG: "Reserve Error Message",
  }
  info = {
    title: "Channel Replenishment",
    success: "Saved successfully!",
    fail: "Save failed!",
    delete: "confirm to delete?",
    cancel: "confirm to cancel?",
    cancelLis: "Are you sure you want to cancel the LIS reservation?",
    noChange: "no Change!",
    gt: "Allocate Qty must be >= 0",
    lt: "Allocate Qty must be <= qtyW003 value",
    duplicate: "Duplicate data exists!",
    reqiuired: "this is required!",
    hasValue: "School code or school location code must have a value!",
    maxLength: "Max length is ${1}",
    dateError: "The selected date is incorrect!",
    noRecord: "No record!",
    noPreAllocate: "No data for pre allocate!",
    notPreAllocate: "type cannot be pre allocate now!",
    valueError: "The changed value of allocate qty must be greater than the original value!",
    notCalculate: "Totalcount cannot be 0",
    calculate: "Pre allocate amount cannot be greater than totalcount",
    importFail: "Data Import Failed",
    importSuccess: "Data Import Success",
    notEdit: "channel cannot be edited when the demand cycle type is RoadShow or BYOD.",
    selectChannel: "Please select a channel first",
    consistent: "The channel in the record must be consistent with the selected channel",
    dateFail: "Date is require or format is incorrect",
    phoneNumericFail: "Contact Phone Number Only Allow Numeric",
    diffBuCodeFound: "Item with different BU is not allowed",
    popupTotalCountPatten: '${warehouse} Qty Before Pre-Allocate',
    _required: '${1} is required!',
    _invalidFormat: '${1} is Invalid input format!',
    nopCalculateAllResetWarning: `System will remove all of the checked box and proceed Calculate All. Press 'Yes' if confirm`,
    lisRefreshSuccess: 'Warehouse Qty Refresh Success',
    lisUpdateReplenishLineSuccess: 'Warehouse Qty Update Success',
    confirmLisRefresh: 'Comfirm to refresh warehouse qty?',
    onlyAcceptOneBuCode: 'Please Only Select 1 BU Code.',
    oneBuCodeRequired: 'BU Code is required! Please Select 1 BU Code.',
  }
  STATUS = {
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT',
    VERIFIED: 'VERIFIED',
    SUBMITTED: 'SUBMITTED',
    INTERFACED: 'INTERFACED',
    RESERVED: 'RESERVED',
    RESERVING: 'RESERVING',
    SUBMITTING: 'SUBMITTING',
  }
  ISSUBMITTED = {
    Y: 'Y',
    N: 'N',
    A: 'A',
  }
  DEMANDTYPEID = {
    CHANNEL: 1,
    BYOD: 2,
    NOP: 3,
    MOB_RoadShow: 4,
  }
  account = this.commonService.getAccount()
  router: Router = null
  route: ActivatedRoute = null
  service: StockDemandCycleService = null
  msg: MessageService = null
  conf: ConfirmationService = null
  address: any = {}

  CHANNELFLAG_RESERVATION = {
    CHANNEL: 'channel',
    WAREHOUSE: 'warehouse',
  }
  constructor(
    router: Router,
    route: ActivatedRoute,
    service: StockDemandCycleService,
    msg: MessageService,
    conf: ConfirmationService,
    public commonService: CommonService) {
      this.router = router
      this.route = route
      this.service = service
      this.msg = msg
      this.conf = conf
    // makeAutoObservable(this)
  }
  getResolverData() {
    this.address = LocalStorageHelper.getObject('REPLENISH_ADDRESS')
  }
  comparisonDate(timeStamp1, timeStamp2) {
    const dayValue = 24 * 60 * 60 * 1000
    return Math.ceil((timeStamp1 - timeStamp2) / dayValue)
  }
  showMessage(severity, summary, detail, isInnerHTML = false){
    this.msg.add({severity, summary, detail, data: {isInnerHTML:isInnerHTML}});
  }
  restoreData(fun, self, data, msg) {
    this.conf.confirm({
      message: msg,
      header: this.info.title,
      accept: () => {
        self[fun](data)
      }
    });
  }
  //Determine whether there is duplicate data
  hasDuplicate(arr) {
    let flag = false
    arr.forEach(item => {
      const index = arr.findIndex(item2 => item.rowId !== item2.rowId
        && item.itemNumber === item2.itemNumber && item.itemBuCode === item2.itemBuCode && item.channel === item2.channel)
      if(index !== -1){
        flag = true
      }
    })
    return flag
  }
  //无法对特别复杂的对象做对比，（例如包含函数式等等）
  isEqual(a, b) {
    // 判断两个对象是否指向同一内存，指向同一内存返回 true
    if (a === b) return true // 获取两个对象键值数组
    let aProps = Object.getOwnPropertyNames(a)
    let bProps = Object.getOwnPropertyNames(b)
    // 判断两个对象键值数组长度是否一致，不一致返回 false
    if (aProps.length !== bProps.length) return false // 遍历对象的键值
    for (let prop in a) {
      // 判断 a 的键值，在 b 中是否存在，不存在，返回 false
      if (b.hasOwnProperty(prop)) {
      // 判断 a 的键值是否为对象，是则递归，不是对象直接判断键值是否相等，不相等返回 false
        if (typeof a[prop] === 'object') {
          // 对日期做特别处理先转为字符串再比较
          if (a[prop] instanceof Date) {
            if (!this.isEqual(a[prop].toString(), b[prop].toString())) return false
          } else {
            if (!this.isEqual(a[prop], b[prop])) return false
          }
        } else if (a[prop] !== b[prop]) {
          return false
        }
      } else {
        return false
      }
    }
    return true
  }


  getByteLength(normal_val) {
    // Force string type
    normal_val = String(normal_val);

    var byteLen = 0;
    var complete = false;
    for (var i = 0; i < normal_val.length; i++) {
        var c = normal_val.charCodeAt(i);
          if (c < (1 <<  7))
            continue;
          else complete = true;
     }
     return complete;
  }
}
