import {LocalStorageHelper} from "./LocalStorageHelper";
import { Observable, forkJoin, from, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import * as _ExcelJS from '_exceljs';
declare var ExcelJS: _ExcelJS;

export class CommonMethod{
  /**
   * 时间戳或日期转字符串
   * @param timestamp
   */
  public static timestampToTime(timestamp) {
    let tmp = ''
    if(timestamp){
      if(typeof timestamp == 'number'){
        timestamp = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      }
      var Y = timestamp.getFullYear();
      var M = (timestamp.getMonth()+1 < 10 ? '0'+(timestamp.getMonth()+1) : timestamp.getMonth()+1);
      var D = timestamp.getDate();
      tmp = `${Y}/${M}/${D}`
    }
    return tmp
  }

  /**
   * 格式化日期
   * @param timestamp
   */
  public static dateToTime(date) {
    if(date && date.toString().length>10){
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1).toString().padStart(2, '0'); //(date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
      var D = date.getDate().toString().padStart(2, '0');
      date = `${Y}-${M}-${D}`

    }
    return date
  }

  /**
   * 生成transaction number
   * @param date
   * @param dnNo
   * @param shop
   */
  public static genTranNum(date: Date,dnNo,shop) {
    /* let number = Math.floor(Math.random()*100000000).toString();
    if(number.length<8){
      for(let i=0;i< 8-number.length;i++){
        number = '0'+number
      }
    } */
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const number = array[0].toString().padStart(8, '0').substring(0, 8);
    return dnNo + number
    // return date.getFullYear().toString().substr(2, 2) +
    //   this.complement(date.getMonth() + 1) +
    //   this.complement(date.getDate()) +
    //   dnNo + shop +
    //   this.complement(date.getHours()) +
    //   this.complement(date.getMinutes()) +
    //   this.complement(date.getSeconds());
  }

  static complement(n) { return n < 10 ? '0' + n : n }

  /**
   * 返回所在Storage label值
   * @param module
   * @param v
   */
  static toSelectedLabel(module,v){
    let la = ''
    LocalStorageHelper.getObject(module).forEach( repo =>{
      if(repo.value == v || repo.data == v || repo.code == v){
        la = repo.label
      }
    })
    return la
  }

  /**
   * 返回所在Storage property值
   * @param module
   * @param v
   */
  static toSelectedProperty(module,v,protyKey){
    let a = ''
    LocalStorageHelper.getObject(module).forEach( repo =>{
      if(repo.value == v || repo.data == v || repo.code == v){
        a = repo[protyKey]
      }
    })
    return a
  }

  /**
   * 时间戳转日期
   * @param d
   */
  public static convertDate(d){
    var timeZone:any = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date:any = new Date(parseInt(d)+timeZone * 3600 * 1000)
    return date.toJSON().substr(0, 19).replace('T', '/').replace(/-/g, '/').split("/").reverse().join("/").replace('/',' ');
  }
  /**
   * a标签下载文件，文档流 xls，xlsx，doc，docx，pdf
   * @param obj 文档流
   * @param name 文件名
   * @param suffix 文件格式
   */
   static downloadFile(obj, name, suffix) {
    const DOWNLOAD_TYPE_MAP = {
      xls: 'application/vnd.ms-excel;charset=UTF-8',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      doc: 'application/msword;charset=UTF-8',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8',
      pdf: 'application/pdf;charset=UTF-8'
    }
    if (!DOWNLOAD_TYPE_MAP[suffix]) {
      throw new Error('请传入文件下载的格式后缀，eg：xls，xlsx，doc，docx，pdf')
    }
    const blob = new Blob([obj], { type: DOWNLOAD_TYPE_MAP[suffix] })
    const fileName = `${name}.${suffix}`
    let link = document.createElement('a')
    document.body.appendChild(link)
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href) // 销毁url对象
  }

  public static findInChildren(array:any[], valueKey:string, value:any, childrenKey:string='children'){
    let res = null;
    array.some(obj=>{
      if(obj[valueKey]==value){
        res = obj
        return true
      }
      if(obj[childrenKey] && obj[childrenKey].length>0){
        let _res = this.findInChildren(obj[childrenKey], valueKey, value, childrenKey)
        if(_res){
          res = _res
          return true
        }
      }
      return false
    })
    return res
  }

  public static saveBlobData(blob, fileName) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = 'none';
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a)
  };

  public static base64toBlob(base64){
    var byteCharacters = atob(base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    // var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);
    return file
  }

  public static proxyToJson(val){
    return JSON.parse(JSON.stringify(val))
  }

  public static getElementChildFromElementByClassRegex(parent: Element, regex: RegExp) {
    let children = parent.children
    let targetElement: Element = null;
    for (let i = 0; i < children.length; i++) {
        if (regex.test(children[i].className) == true) {
            targetElement = children[i]
        } else {
            targetElement = this.getElementChildFromElementByClassRegex(children[i], regex)
        }
        if (targetElement != null) {
            break;
        }
    }
    return targetElement;
  }

  public static getElementChildFromElementByClass(parent: Element, targetClass: string) {
    let children = parent.children
    let targetElement: Element = null;
    for (let i = 0; i < children.length; i++) {
        if (children[i].className.indexOf(targetClass)>-1) {
            targetElement = children[i]
        } else {
            targetElement = this.getElementChildFromElementByClass(children[i], targetClass)
        }
        if (targetElement != null) {
            break;
        }
    }
    return targetElement;
  }

  public static convertDate_YYYYMMDD(d){
    var timeZone = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date:Date = new Date(parseInt(d, 10) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  }

  public static getValueByKeyPath(data:any, keyPath: string[]){
    if(keyPath.length>1){
      if(data[keyPath[0]]) return this.getValueByKeyPath(data[keyPath[0]], keyPath.slice(1))
      return null
    }else{
      return data[keyPath[0]]
    }
  }

  // debug: dummy data json
  // "assets/_SELECT_net_code_eventType_net_uam_function_list_id_uamFunctionL_202210261221.json"
  public static fetchData(jsonfile: string) {
    const req = new XMLHttpRequest();
    let resolver
    let promise = new Promise<any>((resolve, reject)=>{resolver=resolve})
    req.open('GET', jsonfile);
    req.onload = () => {
      resolver(JSON.parse(req.response));
    };
    req.send();
    return promise;
  }
  
  public static replacePatten(patten: string, value: string[]){
    let res = ''+patten
    value.forEach((val, idx)=>{
      let index = idx + 1;
      let replaceKey = "${"+index+"}"
      if(res.indexOf(replaceKey)>0){
        res = res.replace(replaceKey, val)
      }
    })
    return res
  }

  // get values to set value when only have ids use for call api
  public static multipleTreeSelectGetValueByIds(ipt, values){
    let options = ipt.options
    let valueObjs = []
    values.forEach(id => {
      valueObjs.push(this.findInChildren(options, 'data', id, 'children'))
    });
    let pKeys = valueObjs.map(obj=>obj.pid)
    let uniPKeys = pKeys.filter((val, idx, arr)=>arr.indexOf(val)==idx)
    let pKeyChildSelectedLength:any = {}
    // get selected child length group by pid
    pKeys.forEach(pkey=>{
      if(pKeyChildSelectedLength[pkey]) {pKeyChildSelectedLength[pkey]++
      }else{pKeyChildSelectedLength[pkey]=1}
    })
    let allChildSelectedPKeys = []
    // get selected child parent and complete with selected child length
    uniPKeys.forEach(pkey=>{
      if(pkey!=null){
      let parent = this.findInChildren(options, 'data', pkey, 'children')
      if(pKeyChildSelectedLength[parent.data] == parent.children.length){
        // all 
        allChildSelectedPKeys.push(pkey)
      }}
    })
    let parentValueObjs = []
    if(allChildSelectedPKeys.length>0)parentValueObjs = this.multipleTreeSelectGetValueByIds(ipt, allChildSelectedPKeys)
    return [...valueObjs, ...parentValueObjs]
  }

  public static async downloadXlsx(data, col, fileName, datePipe?){
    try{
      const aoa = this.dataColToAoa(data,col,datePipe,'field')
      const workbook = this.aoaToWorkbook(aoa)
      const blob = await this.workbookToBlob(workbook);
      this.saveBlob(blob, fileName);
    }catch(e){
      throw e;
    }
  }
  
  public static async downloadXlsxWithOptions(data, col, fileName, datePipe?, option?:EXPORT_XLSX_OPTIONS){
    try{
      const header = option?.header;
      const footer = option?.footer;
      const fieldKey = option?.fieldKey || 'field';
      const colStyles = option?.columnStyles || [];
      
      const workbook = this.getWorkbook();

      const worksheet = this.addWorkSheet(workbook);
      const mainAoa = this.dataColToAoa(data,col,datePipe,fieldKey,option)
      let headerAoa = header?[...this.headerFooterToAoa(header), []]:[]
      let footerAoa = footer?[[], ...this.headerFooterToAoa(footer)]:[]
      let mergeOption = this.getMergeOptionAoa(col,headerAoa.length,option)

      this.writeDataToWorksheet(worksheet, [...headerAoa,...mainAoa,...footerAoa])
      this.worksheetSetMerge(worksheet, mergeOption)
      this.worksheetFormatWidth(worksheet)

      this.worksheetSetStyle(worksheet, colStyles)

      const blob = await this.workbookToBlob(workbook);
      this.saveBlob(blob, fileName);
    }catch(e){
      throw e;
    }
  }

/**
  deprecated
  public static downloadXlsxWithMultiSheet(datas: any[][], cols: any[][], sheetNames: string[], fileName: string, datePipe?: DatePipe){
  }
 */

  public static async downloadXlsxWithMultiSheetWithOptions(datas: any[][], cols: any[][], sheetNames: string[], fileName: string, datePipe?: DatePipe, options?:EXPORT_XLSX_OPTIONS[]){
    try{
      const workbook = this.getWorkbook();
      sheetNames.forEach((sheetName,idx)=>{
        const data = datas[idx]
        const col = cols[idx]
        const option = options&&options[idx]?options[idx]:null
        const header = option?.header;
        const footer = option?.footer;
        const fieldKey = option?.fieldKey || 'field'

        const worksheet = this.addWorkSheet(workbook, sheetName);
        const mainAoa = this.dataColToAoa(data,col,datePipe,fieldKey,option)
        let headerAoa = header?[...this.headerFooterToAoa(header), []]:[]
        let footerAoa = footer?[[], ...this.headerFooterToAoa(footer)]:[]
        let mergeOption = this.getMergeOptionAoa(col,headerAoa.length,option)
        if(option&&option.pageSetup){
          Object.keys(option.pageSetup).forEach(key=>{
            worksheet.pageSetup[key] = option.pageSetup[key]
          })
        }
        if(option&&option.autoPrintTitle){
          let printTitleRow = headerAoa.length+1;
          let printTitleRowEnd = printTitleRow+(option.haveParent?1:0);
          worksheet.pageSetup.printTitlesRow = `${printTitleRow}:${printTitleRowEnd}`;
        }
        this.writeDataToWorksheet(worksheet, [...headerAoa,...mainAoa,...footerAoa])
        this.worksheetSetMerge(worksheet, mergeOption)
        if(option&&option.colWidth) {
          this.worksheetSetWidth(worksheet, option)
        }else{
          this.worksheetFormatWidth(worksheet)
        }
        // if(option&&option.headerWrapText) this.worksheetSetTableHeaderWrapText(worksheet, col, option, headerAoa.length)
        if(option&&option.tableBorder) this.worksheetSetBorder(worksheet, col, option, headerAoa.length, mainAoa.length)
        if(option&&option.tableHeaderBorderBottom) this.worksheetSetHeaderBorder(worksheet, option, headerAoa.length, mainAoa.length)
      })
      const blob = await this.workbookToBlob(workbook);
      this.saveBlob(blob, fileName);
    }catch(e){
      throw e;
    }
  }
  public static worksheetSetBorder(worksheet, col, option:EXPORT_XLSX_OPTIONS, headerLength, tableLength){
    let startRow = headerLength + 1;
    let endRow = startRow + tableLength
    if(option&&option.tableBorder=='leftright'){
      for(let i = startRow; i < endRow; i++){
        let row = worksheet.getRow(i)
        col.forEach((e,idx)=>{
          let cell = row.getCell(idx+1)
          cell.border = {
            left: {style:'thin'},
            right: {style:'thin'},
          };
          cell.alignment = { wrapText: true };
          // cell.alignment = { ...cell.alignment, horizontal: 'fill' };
        })
      }
    }
  }
  public static worksheetSetHeaderBorder(worksheet, option:EXPORT_XLSX_OPTIONS, headerLength, tableLength){
    let startRow = headerLength + 1;
    let endRow = startRow + tableLength
    let thHeight = 1
    if(option&&option.haveParent) thHeight = 2
    let thEnd = startRow + thHeight - 1
    worksheet.getRow(thEnd).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      cell.border = {
        ...cell.border,
        bottom: {style:'thin'},
      };
    });
  }
  public static worksheetSetWidth(worksheet, option:EXPORT_XLSX_OPTIONS){
    worksheet.columns.forEach((column, i) => {
      // const lengths = column.values.map(v => v.toString().length + 2);
      // min 8, max 60
      // const maxLength = Math.min(Math.max(8, ...lengths.filter(v => typeof v === 'number')),30)
      if(option.colWidth[i]) column.width = option.colWidth[i];
    });
  }
  public static worksheetSetTableHeaderWrapText(worksheet, col:any[], option:EXPORT_XLSX_OPTIONS, headerLength){
    let startRow = headerLength + 1
    let endRow = startRow;
    let targetRows = [startRow]
    if(option.haveParent) {
      endRow++
      targetRows.push(endRow)
    }
    let maxHeight = 1
    targetRows.forEach((e, idx)=>{
      worksheet.getRow(e).eachCell({ includeEmpty: true }, (cell, colNum) => {
        // cell.alignment = { wrapText: true };
        let _height = col[colNum-1].title?.split(" ").length || 0;
        if(_height > maxHeight) maxHeight = _height;
      });
    })
    // worksheet.getRow(endRow).height = maxHeight * 15
  }
  // one by one
  public static forkjoinSync(obsList){
    let res = of([])
    obsList.forEach(obs=>{
      res = <Observable<any[]>>res.pipe(mergeMap(_res=>{
        return obs.pipe(map(obsRes=>{return [..._res, obsRes]}))
      }))
    })
    return res
  }
  public static forkjoinSyncByGroupWithoutResponseData(obsList, lengthPerCall = 5){
    let res = of([])
    let length = obsList.length / lengthPerCall
    for(let i = 0; i < length; i++){
      let _obs = obsList.slice(i*lengthPerCall, ((i+1)*lengthPerCall))
      let groupObs = forkJoin(_obs)
      res = <Observable<any[]>>res.pipe(mergeMap(_res=>{
        return groupObs.pipe(map(obsRes=>null))
      }))
    }
    return res
  }

  public static jsonObjArrayToKeyValueObject(arr, key, value){
    let res = {}
    arr.forEach(e => {
      res[e[key]] = e[value]
    });
    return res
  }

  public static jsonObjArrayToKeyObject(arr, key){
    let res = {}
    arr.forEach(e => {
      res[e[key]] = e
    });
    return res
  }

  public static consoleError(...arg){
    // console.error(...arg)
  }

  public static getDefaultChannel(options?) {
    // return null; // default channel showing as W003 in prod, remove this function for now
    // wip: copy from "Stock Balance Enquiry", should be user login channel / default channel
    const defaultChannels = LocalStorageHelper.getObject("DEFAULT_CHANNEL");
    const repo = LocalStorageHelper.getObject("REPO");
    let defaultChannel = null
    if(defaultChannels && defaultChannels[0]){defaultChannel = defaultChannels[0]}
    if(!defaultChannel) return null;
    // const channels = LocalStorageHelper.getObject("REPOMODULEBYUAM");
    const channels = LocalStorageHelper.getObject("REPO");
    let target = null
    let parentName = null
    // channels.some(parentChannel=>{
    //   let found = parentChannel.items.some(child=>{
    //     if(child.id == defaultChannel.id){
    //       target = child
    //       return true
    //     }
    //     return false
    //   })
    //   if(found) parentName = parentChannel.label;
    //   return found
    // })

    channels.some(channel=>{
      if(channel.id == defaultChannel.id){
        parentName = channel.other
        target = channel
      }
    })

    // const channels = LocalStorageHelper.getObject("REPOMODULEBYUAMGROUP");
    // if (channels) {
    //   let pranetName = channels[0].label;
    //   let selItem = channels[0].items[0];
    //   if (!selItem) {
    //     channels.find((item1) => {
    //       if (item1.items.length) {
    //         pranetName = item1.label;
    //         selItem = item1.items[0];
    //       }
    //     });
    //   }
    //   if(!selItem) return null
    const list = options;
    const parentItem = list.find((item1) => item1.label === parentName);
    const item = parentItem?parentItem.children.find(
      (channel) => channel.label === target.label
    ):null;
    return item;
    // } else {
    //   return null;
    // }
  }

  public static downloadFileFromResponse(res, suggestName='file'){
    if(!res.ok || res.status != 200) throw 'An Unexpected Error Occurred';
    let header = res.headers.get('content-disposition')
    let name = null
    if(header) name = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].trim()
    return from(res.blob()
      .then((blob) => URL.createObjectURL(blob))
      .then((href) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = href;
        a.download = name?name:suggestName;
        a.click();
        window.URL.revokeObjectURL(href);
        document.body.removeChild(a);
      }))
  }

  public static getToday(){
    return new Date(new Date().setHours(0,0,0,0))
  }

  public static sameDayAdd(_date:Date[]){
    if(!_date) return null
    let _addDateTime = [..._date]
    if(_addDateTime[1]==null){
      _addDateTime[1]=_addDateTime[0]
    }
    return _addDateTime
  }

  public static getDateFromToday(dayDiff:number){
    return new Date(this.getToday().getTime() + (dayDiff * 24*60*60*1000))
  }

  public static getItemListFromSku(skus){
    return skus.map(sku=>{return {
      label: sku.name + '~' + sku.flag,
      id: sku.code
    }})
  }

  public static distinctArray(arr){
    return arr.filter((value, index, array) => array.indexOf(value) === index);
  }

  public static createNewPromise(){
    let resolver
    let rejector
    let promise = new Promise((resolve, reject)=>{
      resolver = resolve
      rejector = reject
    })
    return {
      promise: promise,
      resolver: resolver,
      rejector: rejector
    }
  }

  public static showOpenFilePickerPolyfill(options):Promise<any[]> {
    if((<any>window).showOpenFilePicker){
      return (<any>window).showOpenFilePicker(options)
    }
    return new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = options.types
        .map((type) => type.accept)
        .flatMap((inst) => Object.keys(inst).map((key) => inst[key]))
        .join(",");

      input.addEventListener("change", (e) => {
        let res = [...input.files].map((file) => {
          return {
            getFile: async () =>
              new Promise((resolve) => {
                resolve(file);
              }),
          };
        })
        resolve(res)
      });

      input.click();
    });
  }

  static getWorkbook(){
    return new ExcelJS.Workbook();
  }

  static addWorkSheet(workbook, sheetName?){
    return workbook.addWorksheet(sheetName);
  }

  static writeDataToWorksheet(worksheet, aoa: any[][]){
    aoa.forEach(_row=>{
      worksheet.addRow(_row)
    })
  }

  static dataColToAoa(data, col, datePipe, fieldKey, option?):any[][]{
    let _res = []
    // let th = col.map((_col, colIdx)=>{ 
    //   return _col.title
    // })
    let thAoa = this.dataColToThAoa(col,option)
    _res.push(...thAoa)

    data.forEach((_data, dataIdx)=>{
      let row = [];
      col.forEach((_col, colIdx)=>{
        let value = _data[_col[fieldKey]]
        if(datePipe){
          if(_col.type == 'datetime'){
            value = datePipe.transform(value, "yyyy/MM/dd HH:mm:ss")
          }else if(_col.type == 'date'){
            value = datePipe.transform(value, "yyyy/MM/dd")
          }
        }
        row.push(value)
      })
      _res.push(row)
    })
    return _res
  }
  
  static dataColToTrAoa(data, col, datePipe, fieldKey):any[][]{
    let _res = []
    data.forEach((_data, dataIdx)=>{
      let row = [];
      col.forEach((_col, colIdx)=>{
        let value = _data[_col[fieldKey]]
        if(datePipe){
          if(_col.type == 'datetime'){
            value = datePipe.transform(value, "yyyy/MM/dd HH:mm:ss")
          }else if(_col.type == 'date'){
            value = datePipe.transform(value, "yyyy/MM/dd")
          }
        }
        row.push(value)
      })
      _res.push(row)
    })
    return _res
  }
  
  static dataColToThAoa(col, option?):any[][]{
    let labelKey = 'title'
    let _res = []
    
    if(!option||!option.haveParent){
      // header no parent, default 1 row header
      let th = col.map((_col, colIdx)=>{ 
        return _col[labelKey]
      })
      _res.push(th)
    }else{
      // header have parent, 
      // only need to support 2 row header now
      // please update this part if need more than 2 row th
      _res.push([],[])
      col.forEach((_col, colIdx)=>{
        let thIdx = colIdx + 1
        let value = _col.title
        if(!_col.parent){
          _res[0][colIdx] = value
          _res[1][colIdx] = value
        }else if(_col.parent){
          // parent === true mean belong to share the same parent with prev column, parent already set by the have parent object column, dont need to do any thing
          if(_col.parent!==true){
            // have parent object and parent not === true
            _res[0][colIdx] = new Array(_col.parent.colspan).fill(_col.parent.title)
          }
          // 2nd row th
          _res[1][colIdx] = value
        }
      })
      _res[0] = _res[0].flat()
    }
    return _res
  }

  public static aoaToBlob(aoa: any[][]):Promise<any> {
    let that = this
    const workbook = this.getWorkbook();
    const worksheet = this.addWorkSheet(workbook);
    this.writeDataToWorksheet(worksheet, aoa)

    return workbook.xlsx.writeBuffer().then((buffer: any) => {
      return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    });
    // Generate Excel file
    // workbook.xlsx.writeBuffer().then((buffer: any) => {
    //   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   // saveAs(blob, `${fileName}.xlsx`);
    //   that.saveBlob(blob,fileName)
    // });
  }

  static aoaToWorkbook(aoa: any[][]){
    const workbook = this.getWorkbook();
    const worksheet = this.addWorkSheet(workbook);
    this.writeDataToWorksheet(worksheet, aoa)
    this.worksheetFormatWidth(worksheet)
    return workbook
  }

  static workbookToBlob(workbook){
    return workbook.xlsx.writeBuffer().then((buffer: any) => {
      return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    });
  }

  static worksheetFormatWidth(worksheet){
    worksheet.columns.forEach((column, i) => {
      const lengths = column.values.map(v => v.toString().length + 2);
      // min 8, max 60
      const maxLength = Math.min(Math.max(8, ...lengths.filter(v => typeof v === 'number')),30)
      column.width = maxLength;
    });
  }

  public static saveBlob(blob,fileName){
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = `${fileName&&fileName.length>0?fileName:'spreadsheet'}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static headerFooterToAoa(obj){
    return obj.map(keyValue=>[keyValue.key, keyValue.value])
  }

  static getMergeOptionAoa(col,headerLength,option?):number[][]{
    let res=[]
    let thStartRowIdx = headerLength + 1
    let thEndRowIdx = (option&&option.haveParent? thStartRowIdx + 1: thStartRowIdx)
    if(!option?.haveParent) return []
    
    col.forEach((_col, colIdx)=>{
      let thIdx = colIdx + 1
      let value = _col.title
      if(!_col.parent){
        res.push([thStartRowIdx,thIdx,thEndRowIdx,thIdx])
      }else if(_col.parent){
        if(_col.parent!==true){
          res.push([thStartRowIdx,thIdx,thStartRowIdx,thIdx+_col.parent.colspan-1])
        }
      }
    })

    return res
  }

  static worksheetSetMerge(worksheet, mergeOption:number[][]){
    mergeOption.forEach(e => {
      worksheet.mergeCells(...e)
    });
  }

  static worksheetSetStyle(worksheet, colStyles:{columnIdx:number,style:any}[]){
    colStyles.forEach(colStyle=>{
      // worksheet.getColumn(style.columnIdx).
      if(colStyle.style.fill){
        worksheet.getColumn(colStyle.columnIdx).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: colStyle.style.fill
            },
          };
        });
      }
    })
  }

  public static arrayUnique(arr:any[]){
    return [...new Set(arr)].sort()
  }

  public static keyPathParseValue(data: any, keyPath: any[]){
    if(keyPath.length>1 && data[keyPath[0]]){
      return this.keyPathParseValue(data[keyPath[0]], keyPath.slice(1))
    }else{
      return data[keyPath[0]]
    }
  }

  public static haveChannelPermission(channelId){
    return LocalStorageHelper.getObject('REPOIDBYUAM').includes(channelId)
  }
}

export interface EXPORT_XLSX_OPTIONS {
  header?:{key:string,value:any}[], // add before table
  footer?:{key:string,value:any}[], // add after table
  haveParent?:boolean, // use column parent, {title, colspan} / true
  fieldKey?:string, // column key to get data key
  columnStyles?:{columnIdx:number,style:any}[], // fill
  pageSetup?:{ [key: string]: any }, // index CONFIG.PRINT_CONFIG_FIT_COLUMN_LANDSCAPE
  autoPrintTitle?: boolean, // set th to excel print title
  tableBorder?: 'leftright', // table border style
  tableHeaderBorderBottom?: boolean, // th border bot
  colWidth?: number[], // custom column width
  headerWrapText?: boolean, // deprecated, set wraptext in tableBorder
}

export enum TABLE_BORDER_OOPTIONS {
  leftright = 'leftright'
}