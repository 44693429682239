import { HttpHelper } from '../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from '../../config';
import { from, of } from 'rxjs';
import { CommonService } from '../common/common-service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReservationInterfaceService {
  constructor(
    public commonService: CommonService,
  ){}
  search(data) {
    return from(HttpHelper.post(URLDICT.RESERVATION_INTERFACE_SEARCH, data))
  }
  retry(reservationInterfaceId) {
    return from(HttpHelper.post(URLDICT.RESERVATION_INTERFACE_RETRY, {reservationInterfaceId}))
  }

}
