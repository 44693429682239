import { observable, action } from 'mobx';
import { QueryPannelStore } from './QueryPannelStore';
import { TablePannelStore } from './TablePannelStore';
import { BaseStore, NOTI_TITLE, NOTI_MSG } from './BaseStore';
import {ModalPannelStore} from "./ModalPannelStore";
import { LocalStorageHelper } from './../../util/LocalStorageHelper';
import { Subject } from 'rxjs';
import { Paginator } from 'primeng/paginator';


/**
 * base store for every searchstore
 */

export class BaseSearchStore extends BaseStore {
    @observable permissions:any
    @observable defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
    @observable queryPannel: QueryPannelStore;
    @observable tablePannel: TablePannelStore;
    @observable modalPannel: ModalPannelStore;
    @observable detailUrl = []

    pageIndex:number = 0
    pageSize:number = 20
    totalRecords = 0

    /**自定义排序搜索**/
    field
    order
    isSortSearch
    searchParams = {};
    lastSuccessSearchPayload = {};
    @observable sortSearchUrl

    paginatorSubject = new Subject()

    // searchCache = {}
    resetSearch = false
    paginator: Paginator = null

    @observable eventType = {
      1: 'main/stock_balance/search',
      2: 'main/stock_movement/search',
      3: 'main/stock_take/search',
      4: 'main/stock_reservation/search',
      5: 'main/stock_allocation/search',
      6: 'main/transfer_order/search',
      7: 'main/demand_cycle/search',
      8: 'main/transaction_summary/search',
      9: 'main/item_master/search',
      10: 'main/transaction_backend/search',
      11: 'main/day_of_cover/search',
      12: 'main/cross_shop_pickup_quota/search',
      13: 'main/stock_availability_level/search',
      14: 'main/configuration_tables_maintenance',
      15: 'main/item_transfer_quota/search',
      101: 'main/stock_ppos/search',
      102: 'main/ext_api_request_log/search',
      103: 'main/api_call_log/search'
    }
    constructor(qp, tp, params) {
        super(params)
        this.detailUrl = tp.detailUrl
        //this.modalPannel = new ModalPannelStore(qp)
        if(tp.modal){
          tp.modal.ipts = this.init_ipts(tp.modal.ipts)
          tp.modal.btns = this.init_btns(tp.modal.btns)
          this.modalPannel = new ModalPannelStore(tp.modal)
        }
        this.queryPannel = new QueryPannelStore({ ipts: this.init_ipts(qp.ipts), btns: this.init_btns(qp.btns) , btnsclass: qp.btnsclass})
        this.tablePannel = new TablePannelStore(this.init_tp(tp))
        this.sortSearchUrl = tp.sortSearchUrl
        this.getPermission()
    }
    @action getPermission(){
      let code = this.$PARAMS.snapshot.data.code
      this.permissions = this.$CACHE.getObject('PERMISSIONS')[code]
      // console.log("----- permission module -----", this.$PARAMS.snapshot.data.name, this.permissions)
      this.setPermission(this.queryPannel.btns, code)
      this.setPermission(this.tablePannel.btns, code)
    }
    @action changeTableSize() {
      setTimeout(() => {
        const event = new Event("resize");
        window.dispatchEvent(event);
      }, 800);
    }
    @action openUrl(id) {
      const url = 'https://here2serve--fta.lightning.force.com/lightning/n/Cart?orderID='+id
      window.open(url, '_blank')
    }
    @action setPermission(list, code) {
      list.forEach(item => {
        // balance module
        if(code === 1) {
          item.title === 'Search' && (item.disabled = !this.permissions.includes('Search Stock Balance'))
          item.title === 'Export' && (item.disabled = !this.permissions.includes('Export allocated on Balance Enquiry'))
        }
        // take module
        if(code === 3) {
          item.title === 'Search' && (item.disabled = !this.permissions.includes('Search Stock Take'))
          item.title === 'Create' && (item.disabled = !this.permissions.includes('Create Stock Take'))
        }
        // Transaction module
        if(code === 6) {
          let hasCreate = this.permissions.some(item => item.desc.includes('Create'))
          let hasSearch = this.permissions.some(item => item.desc.includes('Search'))
          item.title === 'Search' && (item.disabled = hasSearch ? false : true)
          item.title === 'Create' && (item.disabled = hasCreate ? false : true)
          this.changeTransactionTypeOption()
        }
      })
    }
    changeTransactionTypeOption() {
      let transitionTypeIpt = this.queryPannel.ipts.find(ipt => ipt.name === "orderType")
      transitionTypeIpt.options = transitionTypeIpt.options.filter(item => {
        return this.permissions.find(el => el.type == item.code && el.desc.includes('Search'))
      })
    }
    // searchWithTreeData
    /**
     * 搜索
     * @param url
     */
    @action search(url,index=0) {
      if (!this.isSubmitAvailable()) return
      //判断之前是否有请求未响应
      if (this.executeWait()) return
      var data = this.queryPannel.format()
      data = this.convertData(data)
      // console.log('------------- search -------------- ', data);
      if(data.hasOwnProperty('isIncludeLis')) {
        // only [stock balance search] access to this code
        if((!data.skuNum || !data.skuNum.length) && !data.shopId){
          this.showMessage('warn', 'Balance Search', 'Please enter search criteria.')
          return
        }

        const wareHouses = LocalStorageHelper.getObject('WAREHOUSE')
        const isExist = data.shopId ? wareHouses.find(item => data.shopId.includes(item.id)) : false
        //data.skuNum.length (item) > 20 && (data.isIncludeLis === 1 (select) || Warehouse exists)
        const len = data.skuNum ? data.skuNum.length : 0
        // if((!data.skuNum || (len > 20 || len === 0)) && data.shopId.length === 0){
        //   this.showMessage('warn', 'Balance Search', 'no. of item code should not be more than 20!')
        //   return
        // }
        if((data.isIncludeLis || isExist) && (len > 20 || len === 0)) {
          this.showMessage('warn', 'Balance Search', 'no. of item code should not be more than 20!')
          return
        }
        if(data.shopId){
          const shopId = data.shopId.map(item => {
            const shops = LocalStorageHelper.getObject('REPO')
            const shop = shops.find(sub=>sub.id === item)
            const {id, label:code} = shop
            return {id, code}
          });
          data.shopId = shopId
        }
        if(data.skuNum){
          const skuNum = data.skuNum.map(item => {
            const skus = LocalStorageHelper.getObject('SKU')
            const sku = skus.find(sub => sub.code === item)
            sku.name = sku.name.split(" ~ ")[0]
            const {code:id, name:code} = sku
            return {id, code}
          });
          data.skuNum = skuNum
        }
      }
      data.pageIndex = this.pageIndex
      data.pageSize = this.pageSize
      data['sortEvent'] = {field: this.field, order: this.order}
      data.accountName = this.$COMMONSTORE?.getAccount()
      this.lastSuccessSearchPayload = data
      this.queryPannel.setBtnProperty(index, "loading", true)
      this.tablePannel.setLoading(true)
      this.searchParams = {...data}
      return this.getDataFromServer(url,index, data)
    }
    convertData(data){
      if(data.shopId){
        if (location.pathname.includes('stock_balance')) {
          // console.log(this.queryPannel.ipts[2].selValue, this.queryPannel.ipts[2].showValue);
          // 处理选择的是All的情况
          // console.log(this.queryPannel.ipts[2].showValue, this.queryPannel.ipts[2].selValue);
          if(this.queryPannel.ipts[1].showValue === 'ALL'){
            this.queryPannel.ipts[1].selValue = []
          }
          data.shopId =  this.queryPannel.ipts[1].showValue ? this.queryPannel.ipts[1].selValue : []
        } else {
          data.shopId = data.shopId.data || data.shopId
        }
      }
      if(data.repoId){
        data.repoId = data.repoId.data || data.repoId
      }
      if(data.channelId){
        data.channelId = data.channelId.data || data.channelId
      }
      if(data.skuId){
        data.skuId = location.pathname.includes('stock_replenishment') ? data.skuId.data : data.skuId.map(item => item.code);
      }
      if(data.eventType){
        data.eventType = data.eventType.code || data.eventType
      }
      if(data.fillStatus){
        data.fillStatus = data.fillStatus.code || data.fillStatus
      }
      if(data.skuNum){
        data.skuNum = data.skuNum.map(item => item.code);
      }
      if(data.orderStatusCode){
        data.orderStatusCode = this.queryPannel.ipts[2].options.find(item => item.code === data.orderStatusCode).alias;
      }
      /* if(data.createAt){
        data.createAt[0] = CommonMethod.dateToTime(data.createAt[0]);
        data.createAt[1] = CommonMethod.dateToTime(data.createAt[1]);
      } */

      return data
    }
    getDataFromServer(url,index, data){
      // 注意 notice 传递的是字符串
      this.isSortSearch=false
      this.tablePannel.setLoading(true);
      return this.$HTTP.post(url, data)
            .then(res => {
              if (res.code === '000') {
                this.tablePannel.setData(res.data)
                // console.log("SEARCHDATA");
                // console.log(res);
                this.tablePannel.mapOfCheckedId = {}
              //   this.tablePannel.mapOfChildrenCheckedId = {}
                this.tablePannel.allCheckBoxValue = false
                this.convertDateToTable(res)
                this.setTreeDate(res)
                if(res.msg !== '') {
                  this.totalRecords = res.msg
                }
              } else {
                this.showMessage('error', NOTI_TITLE, res.message || res.msg)
              }
            })
            .catch(err => {
              // console.log(err);
              // this.$NOTICE.error(NOTI_TITLE, err)
              this.showMessage('error', NOTI_TITLE, err)
            })
            .finally(() => {
                this.queryPannel.setBtnProperty(index, "loading", false)
                this.tablePannel.setLoading(false)
                this.lastSuccessSearchPayload = data
            })
    }
    setTreeDate(res){
      this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
      this.tablePannel.tree_data = this.toTreeDataOfPrimeUI(res.data, this.tablePannel)
      // this.tablePannel.data = this.tablePannel.display_data
    }
    getEventTypes(url){
      let data ={}
      this.$HTTP.post(url, data).then(res => {
        //{state: "success", code: "000", msg: "", data: Array(2)}
        if(res.code === '000'){
          res.data.sort((a, b) => a.eventType.localeCompare(b.eventType)).map(item=>{
            let etdata = {
              code: +item.eventTypeId,
              name: item.eventType + ' ~ ' + item.description
            }
            this.queryPannel.ipts[0].options.push(etdata)
          })
        }
      })
      .catch(err => {
        // console.log(err);
        // this.$NOTICE.error(NOTI_TITLE, err)
        this.showMessage('error', NOTI_TITLE, err)
      })
      .finally(() => {
      })
    }
    @action sortSearch(key, order?){
      this.field = key
      this.isSortSearch=true
      if(order!=null){
        this.order = order
      }else if(!this.order) {
        this.order = -1
      } else if(this.order == -1){
        this.order = 1
      } else if(this.order == 1) {
        this.order = -1
      }
      return this.sortPageSearch(this.sortSearchUrl,key)
    }
    /**
     * 搜索
     * @param url
     */
    @action sortPageSearch(url,sortKey,index=0) {
      // let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.title === 'Item');

      if (!this.isSubmitAvailable()) return
      //判断之前是否有请求未响应
      if (this.executeWait()) return
      var data = this.queryPannel.format()
      data = this.convertData(data)
      let _data = {...data}

      // -----------
      // 获取从notice传递的数据
      if(url.includes('stock_curd')) {
        let noticeMsg = JSON.parse(sessionStorage.getItem('NOTICE_MESSAGE'))
        if(noticeMsg) {
          data.shopId = noticeMsg.shopId;
          data.skuNum = noticeMsg.skuNum;
          let itemIpt = this.queryPannel.ipts.find(ipt=>ipt.title === 'Item');
          let [{ code:name, id:code }] =  data.skuNum
          itemIpt.value =[{code, name:"P100100~iPad without WEEE"}]
          // console.log(itemIpt.value);
          // {name: 'P100100~iPad without WEEE', code: 142, flag: null, other: null}

          const shopIpt = this.queryPannel.ipts.find(ipt=>ipt.title === 'Channel');
          // console.log(shopIpt);
          let [{ id, key:label }] =  data.shopId
          shopIpt.value = [{id, label}]
          shopIpt.showValue = label
          shopIpt.selValue = [id]
          sessionStorage.removeItem('NOTICE_MESSAGE')
        }
      }
      if(url.includes('stock-take')) {
        let noticeMsg = JSON.parse(sessionStorage.getItem('NOTICE_MESSAGE'))
        if(noticeMsg) {
          data.stockTakeNumber = noticeMsg.stockTakeNumber;
          const ipt = this.queryPannel.ipts.find(ipt=>ipt.title === 'Stock Take Ref. No.');
          ipt.value = data.stockTakeNumber
          sessionStorage.removeItem('NOTICE_MESSAGE')
        }
      }
      // -----------
      // data.createAt = new Date(data.createAt)
      // data.pageIndex = url.includes('notice') ? this.pageIndex + 1: this.pageIndex
      data.pageIndex = this.pageIndex
      data.pageSize = this.pageSize
      data['sortEvent'] = {field:this.field,order:this.order}
      data.accountName = this.$COMMONSTORE?.getAccount()
      this.tablePannel.setLoading(true);
      // 注意 notice 传递的是字符串
      if(data.eventType && !url.includes('notice')){
        data.eventType = data.eventType[0]
      }
      // console.log('sort search params:', data);
      return this.$HTTP.post(url, data)
        .then(res => {
          if (res.code === '000') {
            this.tablePannel.setData(res.data)
            // console.log("SORTSEARCHDATA");
            // console.log(res);
            this.tablePannel.mapOfCheckedId = {}
            this.tablePannel.sortField = this.field
            this.tablePannel.sortOrder = this.order
            //   this.tablePannel.mapOfChildrenCheckedId = {}
            this.tablePannel.allCheckBoxValue = false
            this.convertDateToTable(res)
            this.setTreeDate(res)
            /* this.tablePannel.display_data = this.toTreeData(res.data, this.tablePannel)
            this.tablePannel.data = this.tablePannel.display_data */
            if(res.msg !== '') {
              this.totalRecords = res.msg
            }
          } else {
            this.showMessage('error', NOTI_TITLE, res.message || res.msg)
          }
        })
        .catch(err => {
          // console.log(err);
          // this.$NOTICE.error(NOTI_TITLE, err)
          this.showMessage('error', NOTI_TITLE, err)
        })
        .finally(() => {
          this.queryPannel.setBtnProperty(index, "loading", false)
          this.tablePannel.setLoading(false)
          // let _data = this.queryPannel.format()
          this.searchParams = {..._data}
          this.lastSuccessSearchPayload = data
        })
    }

  /**
   * 判断当前是否有按钮处于loading状态
   */
  protected executeWait() {
    let flag = false;
    this.queryPannel.btns.forEach(btn => {
      if (btn["loading"]) {
        flag = true;
      }
    });
    if(flag){
      // this.$NOTICE.warning(NOTI_TITLE,"Please wait until the execution is completed")
      this.showMessage('warn', NOTI_TITLE, "Please wait until the execution is completed")
    }
    return flag;
  }

  // @action timestampToTime(v) {
  //   return CommonMethod.timestampToTime(v)
  // }
  @action resetTreeTableStyle(){
    //这里必须清除2次selectedNodes，才起效。可能和mobx的数据绑定有关。
    if(this.tablePannel.selectedNodes && this.tablePannel.selectedNodes.length){
      this.tablePannel.selectedNodes = []
      setTimeout(() => {
        this.tablePannel.selectedNodes = []
      }, 100);
    }
  }
    /**
     * 清除输入值
     * @param url
     */
    @action clear(url) {
        //判断之前是否有请求未响应
        if (this.executeWait()) return
        // 清除输入值
        this.queryPannel.clear()
        // 重新请求
        this.tablePannel.setLoading(true);
        this.queryPannel.setBtnProperty(1, "loading", true)
        const accountName = this.$COMMONSTORE.getAccount()
        this.$HTTP.post(url, {pageIndex:this.pageIndex,pageSize:this.pageSize, accountName})
            .then(res => {
                this.tablePannel.setData(res.data)
                this.convertDateToTable(res)
                this.setTreeDate(res)
                // this.tablePannel.display_data = this.toTreeData(res.data,this.tablePannel)
                if(res.msg !== '') {
                  this.totalRecords = res.msg
                }
                this.resetTreeTableStyle()
            })
            .catch(err => {
                // this.$NOTICE.error(NOTI_TITLE, err)
                this.showMessage('error', NOTI_TITLE, err)
            })
            .finally(() => {
                this.tablePannel.setLoading(false)
                this.queryPannel.setBtnProperty(1, "loading", false)
                let data = this.queryPannel.format()
                this.searchParams = {...data}
            })
    }


    /**
     * 删除
     * @param url
     */
    @action delete(url,u,m) {
        var obj = {}
        obj["ids"] = this.tablePannel.formatSelectedIds()
        //非法判断
        var len = obj['ids'].length
        if (len < 1) {
            // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
            this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
            return
        }
        if(this.judgeIsActive(obj)==false){
          //模态框确定
          this.$MODAL.confirm({
            nzContent: `Do you want to delete these ${len} items?`,
            nzOnOk: () => {
              postDeleteRequest();
            }
          });
        }
        //删除请求
        const postDeleteRequest = () => {
            this.tablePannel.setLoading(true)
            //请求API
            this.$HTTP.post(url, obj)
                .then(res => {
                    var data = []
                    this.tablePannel.data.forEach((d, index) => {
                        if (!this.tablePannel.mapOfCheckedId[d.id]) {
                            data.push(d)
                        }
                    })
                    //清空选中并重赋数据源
                    this.tablePannel.setData(data)
                    this.tablePannel.display_data = this.toTreeData(data,this.tablePannel)
                    this.tablePannel.tree_data = this.toTreeDataOfPrimeUI(data, this.tablePannel)
                    this.tablePannel.allCheckBoxValue = false
                    this.tablePannel.mapOfCheckedId = {}
                    this.resetTreeTableStyle()
                })
                .catch(err => {
                    // this.$NOTICE.error(NOTI_TITLE, err)
                    this.showMessage('error', NOTI_TITLE, err)
                })
                .finally(() => {
                  this.tablePannel.setLoading(false)
                  if(u != null){
                    this.loadToCache(u,m)
                  }
                })
        }
    }

  /**
   * 判断数据是否失效
   * @param obj
   */
  judgeIsActive (obj) {
      let isAs = false
      obj['ids'].forEach(id =>{
        this.tablePannel.data.forEach(da =>{
          if(id==da.id&&da.active=='Y') {
            // this.$NOTICE.warning(NOTI_TITLE, "The selected is active,please disable first!")
            this.showMessage('warn', NOTI_TITLE, "The selected is active,please disable first!")
            isAs = true
          }
        })
      })
      return isAs
    }
  /**
   * 禁用
   * @param url
   */
  @action disable(url,u,m) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    } else {
      //模态框确定
      this.$MODAL.confirm({
        nzContent: `Do you want to disable these ${len} items?`,
        nzOnOk: () => {
          this.tablePannel.setLoading(true)
          //请求API
          this.$HTTP.post(url, obj)
            .then(res => {
              if(res.code=='005'){
                this.$NOTICE.warning(NOTI_TITLE, "The selected is used,can't disable!")
                this.showMessage('warn', NOTI_TITLE, "The selected is used,can't disable!")
                return
              } else if(res.code=='000'){
                var data = []
                this.tablePannel.data.forEach((d, index) => {
                  if (this.tablePannel.mapOfCheckedId[d.id]) {
                    d['active']='N'
                  }
                })
                //清空选中并重赋数据源
                this.tablePannel.setData(this.tablePannel.data)
                this.tablePannel.display_data = this.toTreeData(this.tablePannel.data,this.tablePannel)
                this.tablePannel.tree_data = this.toTreeDataOfPrimeUI(this.tablePannel.data, this.tablePannel)
                this.tablePannel.allCheckBoxValue = false
                this.tablePannel.mapOfCheckedId = {}
                this.resetTreeTableStyle()
              }
            })
            .catch(err => {
              // this.$NOTICE.error(NOTI_TITLE, err)
              this.showMessage('error', NOTI_TITLE, err)
            }).finally(() => {
              this.tablePannel.setLoading(false)
              if(u != null){
                this.loadToCache(u,m)
              }
            }
          )
        }
      });

    }
  }

  /**
   * 启用
   * @param url
   */
  @action enable(url,u,m) {
    var obj = {}
    obj["ids"] = this.tablePannel.formatSelectedIds()
    //非法判断
    var len = obj['ids'].length
    if (len < 1) {
      // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED);
      this.showMessage('warn', NOTI_TITLE, NOTI_MSG.MORE_SELECTED_NEED)
      return
    } else {
      //模态框确定
      this.$MODAL.confirm({
        nzContent: `Do you want to enable these ${len} items?`,
        nzOnOk: () => {
          this.tablePannel.setLoading(true)
          //请求API
          this.$HTTP.post(url, obj)
            .then(res => {
              if(res.code=='000'){
                var data = []
                this.tablePannel.data.forEach((d, index) => {
                  if (this.tablePannel.mapOfCheckedId[d.id]) {
                    d['active']='Y'
                  }
                })
                //清空选中并重赋数据源
                this.tablePannel.setData(this.tablePannel.data)
                this.tablePannel.display_data = this.toTreeData(this.tablePannel.data,this.tablePannel)
                this.tablePannel.tree_data = this.toTreeDataOfPrimeUI(this.tablePannel.data, this.tablePannel)
                this.tablePannel.allCheckBoxValue = false
                this.tablePannel.mapOfCheckedId = {}
                this.resetTreeTableStyle()
              }
            })
            .catch(err => {
              // this.$NOTICE.error(NOTI_TITLE, err)
              this.showMessage('error', NOTI_TITLE, err)
            }).finally(() => {
              this.tablePannel.setLoading(false)
              if(u != null){
                this.loadToCache(u,m)
              }
            }
          )
        }
      });

    }
  }

    /**
     * 跳转至修改页
     * @param routerRule
     */
    @action edit(routerRule) {
        //获取选中对象
        let arr = this.tablePannel.formatSelectedObjs();
        this.tablePannel.display_data.forEach(d=>{
        if(arr[0] && d.id == arr[0]["id"]){
          if(d.tableMutiData){
            d.tableMultiData = JSON.stringify(d.tableMutiData)
            if(d.inventoryAssetFlag) d.inventoryAssetFlag = d.inventoryAssetFlag == 'I' ? true : null;
            if(d.returnableFlag) d.returnableFlag = d.returnableFlag == 'Y' ? true : null;
            if(d.tangibleItem) d.tangibleItem = d.tangibleItem == 'Y' ? true : null;
          }

          if(d.line){
            d.line = JSON.stringify(d.line)
          }
          arr[0] = d
          }
        })
        //较验
        if (arr.length < 1 || arr.length > 1) {
            // this.$NOTICE.warning(NOTI_TITLE, NOTI_MSG.ONE_SELECTED_ONLY)
            this.showMessage('warn', NOTI_TITLE, NOTI_MSG.ONE_SELECTED_ONLY)
            return
        }
        //跳转并传参（通过querystring,basestroe封装了自动获参和注值。）
        /* if(routerRule[1] === 'masterfile_attr_value') {
          Object.assign(arr[0], {valueType: !arr[0]['attrValue'] ? -1 : 0})
        } */
        // sessionStorage.setItem('takeDetail', JSON.stringify(arr[0]))
        this.$ROUTER.navigate(routerRule, { queryParams: arr[0] })
    }
    /**
     * 跳转创建页
     * @param routerRule
     */
    @action create(routerRule) {
        this.$ROUTER.navigate(routerRule)
    }
    /**
     * 正则校验
     * @param store
     */
    @action isSubmitAvailable() {
        return this.queryPannel.isSubmitAvailable()
    }

  /**
   * 页面时间显示处理
   * @param store
   */
    @action convertDateToTable(res){
      if(res.data && res.data != null){
        res.data.forEach(d=>{
          d.createTime = d.createAt
          if(d.createAt && d.createAt != null && d.createAt != 0){
            d.createAt = this.convertDate(d.createAt).split(' ').reverse().join(' ')
            d.createAtNoTime = d.createAt.substr(9)
          }else{
            d.createAtNoTime = ""
          }
          if(d.updateAt && d.updateAt != null && d.updateAt != 0){
            d.updateAt = this.convertDate(d.updateAt).split(' ').reverse().join(' ')
          }
          if(d.approval && d.approval != null && d.approval != 0){
            d.approval = this.convertDate(d.approval).split(' ').reverse().join(' ')
          }
        })
      }
    }

  /**
   * 打开详情页面并处理需要展示的数据
   * @param d
   */
  @action openDet(d){
    // console.log('table row click:', d);

    if(d.attrData && d.attrData.length < 1  ){
      delete d.attrData
    }
    if(d.rightData && d.rightData.length < 1){
        delete d.rightData
    }
    if(d.stepData && d.stepData.length < 1){
        delete d.stepData
    }
    if(d.steps && d.steps.length < 1){
        delete d.steps
    }
    if(d.logDtls && d.logDtls.line.length < 1){
        delete d.logDtls
    }
    if(d.line && d.line.length < 1){
        delete d.line
    }
    d.rightData = JSON.stringify(d.rightData)
    d.attrData = JSON.stringify(d.attrData)
    d.stepData = JSON.stringify(d.stepData)

    d.steps = JSON.stringify(d.steps)
    d.logDtls = JSON.stringify(d.logDtls)
    d.line = JSON.stringify(d.line)
    // console.log(d.logDtls)
    this.detailUrl && this.$ROUTER.navigate(this.detailUrl, { queryParams: d })
  }

    /**
     * open modal
     */
    @action showModal(){
      this.modalPannel.showModal();
    }

    /**
     *
     * save
     */
    @action save(){
      this.modalPannel.required(this.modalPannel.ipts);
      if(!this.isSubmitAvailable()) return

      // var data:any = this.modalPannel.format()
      //API
    }

    /**
     * clear
     * @param url
     */
    @action clearModalPannel(){
      this.modalPannel.clear()
    }
    showMessage(severity, summary, detail){
      this.$NOTICE.add({severity, summary, detail});
    }

    // sortfunction trigger by p-table ngchanges after sortsearch, 
    // block same field and order trigger search function again to prevent loop
    doSortSearchBySortFunction(key, order){
      if(this.field==key && order!=null && this.order == order) return
      this.sortSearch(key, order)
    }
}
