import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
  * @description: 输入框
  * @author:  ken
  */
@Component({
  selector: 'app-input-cst',
  templateUrl: './input-cst.html',
  styles:[]
})
export class InputCustom {
    @Input() ipt
    @Input() style
    @Output() change = new EventEmitter()
    onInput(v){
      // console.log(v)
      this.change.emit(v)
    }
}
