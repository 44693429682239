import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @description: 该组件是select下拉选组件（单选）
 * @author: 陈述承
 */
@Component({
  selector: 'app-select-cst',
  templateUrl: './select-cst.component.html',
  styleUrls: ['./select-cst.component.css']
})
export class SelectCustom {
    @Input() store
    @Input() ipt
    @Input() active=false
    @Output() change = new EventEmitter()
    onChange(v){
      this.ipt.error = null
      this.change.emit(v.value)
    }
}
