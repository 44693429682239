import { HttpHelper } from "./../../util/HttpHelper";
import { Injectable } from "@angular/core";
import { URLDICT } from "./../../config";
import { from, Observable, of } from "rxjs";
import { map, switchMap, mergeMap } from "rxjs/operators";
import { CommonService } from "../common/common-service";

@Injectable({
  providedIn: "root",
})
export class StockOrderService {
  constructor(
    public commonService: CommonService,
  ){}

  updateStockOrderStatus(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_CHANNEL_TRANSFER_UPDATE, data))
    }))
  }

  getAdjustmentNetAmount(orderNumber){
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_ADJUSTMENT_NET_AMOUNT, {orderNumber:orderNumber}))
    }))
  }

  checkBalanceReserve(orderId){
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_ORDER_CHECK_RESERVE, {orderId:orderId}))
    }))
  }

  getNextReplenishDate(){
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.CHANNEL_REPLENISH_GET_NEXT_REPLENISH_DATE, {}))
    }))
  }

  createStockOrder(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_CHANNEL_TRANSFERCREATE, data))
    }))
  }
  createHomeDeliveryRequest(orderId, shipmentDate):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(
        URLDICT.DM_CREATEDELIVERYDOC, 
        {
          orderId: orderId, 
          shipmentDate: this.commonService.convertDateWithFormat(shipmentDate, 'dd-MM-YYYY')
        }
      ))
    }), map(res=>{
      if(res.code=='000'){
        return res
      }else{
        throw res.msg || res.message
      }
    }))
  }
}
