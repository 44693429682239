import { BaseSearchStore } from "../base/BaseSearchStore";
import { INPUT_TYPE, BUTTON_TYPE, URLDICT, NOTI_TITLE } from "../base/BaseStore";
import { CommonService } from "@/service/common/common-service";

export class SearchStore extends BaseSearchStore {
  isSortSearch
  commonService: CommonService;

  // createPannel
  constructor(params) {
    let queryPannel = {
      ipts: [
        {
          title: "Event Type",
          name: "eventTypeId",
          placeholder: "Event Type",
          value: "",
          defaultValue: "",
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          options:[{code:"", name:"ALL"}]
        },{}
      ],
      btnsclass: "p-d-flex p-col-12 p-md-8 p-lg-8",
      btns: [
        {
          type: BUTTON_TYPE.BUTTON,
          category: "primary",
          icon: "",
          title: "Search",
          show: true,
          class: "p-order-1",
          handler: {
            click: () => {
              this.pageIndex = 0
              this.paginator ? this.paginator.changePage(0) : this.sortSearch('createdDate')
            },
          },
        },
        {
          type: BUTTON_TYPE.BUTTON,
          title: "Clear",
          icon: "",
          show: true,
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          handler: {
            click: () => {
              this.queryPannel.clear();
              this.queryPannel.ipts[0].value = ""
              // this.search(this.$URLDICT.NOTICE_SORTSEARCH);
            },
          },
        },
      ],
    };
    let tablePannel = {
      btns: [],
      data: [],
      head: [
        { key: "createdDate", title: "Time", width: "150px", type: "datetime" },
        { key: "eventTypeId", title: "Event Type", width: "120px", type: "eventType"},
        { key: "priority", title: "Priority", width: "100px"},
        // { key: "msg", title: "Message", width: "540px", type: 'hyperlink'},
        { key: "msg", title: "Message", width: "540px", type: 'link', onClick:(e, data)=>{this.msgOnClick(e, data)}},
      ],
      lineCols: [
        { header: "createdDate", title: "Time", type: "dateTime" },
        { header: "eventType", title: "Event Type" },
        { header: "priority", title: "Priority" },
        { header: "msg", title: "Message" },
      ],
      detailUrl: [],
      showCheckboxSwitch: false,
      sortSearchUrl: URLDICT.NOTICE_SORTSEARCH,
    };
    super(queryPannel, tablePannel, params);
    // this.initCreatePannel()
    // this.search(this.$URLDICT.NOTICE_SEARCH);
    this.field = 'createdDate'
    this.order = -1
    this.getEventTypes(this.$URLDICT.NOTICE_EVENTTYPE)
  }

  onInit(){
    this.$PARAMS.queryParams.subscribe(params => {
      if( !params['id'] ) {
        this.sortSearch(this.field, this.order)
      }
      if(params['id']){
        this.loadNotificationById(params['id'])
      }
    });
  }

  navigate(data) {
    let params = {}
    switch(data.uamFunctionListId) {
      case 1:
        // balance (目前不用做)
        // params = {skuNum: [{id: 142, code: "P100100"}], shopId: [{id: 95, key: "CCS"}]}
        break;
      case 2:
        // Transaction History (目前不用做)
        // params = {reservationId: data.relatedId}
        break;
      case 3:
        // take
        params = {stockTakeNumber: data.number}
        break;
      case 4:
        // reservation
        params = {reservationId: data.number}
        break;
      case 5:
        // allocation (目前不用做)
        // params = {allocationId: data.relatedId}
        params = {reservationId: data.number}
        break;
      case 6:
        // Stock Order
        params = {orderNumber: data.number}
        break;
      case 7:
        // demand_cycle (目前不用做)
        // params = {demandCycleName: data.demandName}
        break;
      case 8:
        // report (目前不用做)
        break;
      case 9:
        // item master (目前不用做)
        break;
      case 10:
        // 10之后的目前都不做
        break;
      // default:
        // adjustment 无此模块
    }
    sessionStorage.setItem("NOTICE_MESSAGE", JSON.stringify(params));
    setTimeout(() => {
      // this.$ROUTER.navigate([this.eventType[data.uamFunctionListId]])
      const url = this.$ROUTER.serializeUrl(this.$ROUTER.createUrlTree([this.eventType[data.uamFunctionListId]], { queryParams: { ...params } }));
      window.open(url, '_blank');
    }, 0);
  }
  page(e) {
    this.pageIndex = e.first / e.rows;
    this.pageSize = e.rows;
    let data: any = { ...this.searchParams };
    data.pageIndex = this.pageIndex;
    data.pageSize = this.pageSize;
    this.sortPageSearch(this.sortSearchUrl, this.field)
    // if (this.isSortSearch) this.sortPageSearch(this.sortSearchUrl, this.field);
    // else this.getDataFromServer(this.$URLDICT.NOTICE_SORTSEARCH, 0, data).then();
  }

  msgOnClick(e, data){
    if(data.number&&data.number.length>0){
      let numberList = data.number.split(',').map(_data=>{return _data.trim()})
      this.initMsgPopup(data, data.msg, numberList)
      this.showMsgPopup()
    }else{
      this.showMessage('error', NOTI_TITLE, 'Unexpected error occurred')
    }
  }

  msgPopup

  initMsgPopup(_data, msg:string, numberList:string[]){
    this.msgPopup = {
      title: msg,
      table:{
        head: [
          { key: "number", type:"link", title: "Record Id", width: "150px", onClick:(e,data)=>{this.msgRecordIdOnClick(e,data)}},
        ],
        data: numberList.map(_data=>{return {number:_data}})
      },
      _data: _data
    }
  }

  onMsgPopupHide(){
    // this.msgPopup.visible=false
  }
  hideMsgPopup(){
    this.msgPopup.visible=false
  }
  showMsgPopup(){
    this.msgPopup.visible=true
  }
  msgRecordIdOnClick(e, popupData, notification = this.msgPopup._data){
    let _data = JSON.parse(JSON.stringify(notification))
    _data.number = popupData.number
    this.navigate(_data)
  }

  loadNotificationById(id){
    if(id.length>0 && !Number.isNaN(Number.parseInt(id))) this.getNotificationById(Number.parseInt(id))
  }

  getNotificationById(id){
    let data = {
      pageIndex: 0,
      pageSize: 20,
      sortEvent: {
        field: 'createdDate',
        order: -1
      },
      accountName: this.$COMMONSTORE.getAccount(),
      notificationId: id,
    }
    
    this.tablePannel.setLoading(true);
    this.commonService.getNotifications(data).subscribe(res=>{
      this.tablePannel.setLoading(false);
      this.tablePannel.setData(res.data)
      this.tablePannel.mapOfCheckedId = {}
      this.tablePannel.sortField = this.field
      this.tablePannel.sortOrder = this.order
      this.tablePannel.allCheckBoxValue = false
      this.convertDateToTable(res)
      this.setTreeDate(res)
      if(res.msg !== '') {
        this.totalRecords = res.msg
      }
    }, err=>{
      this.tablePannel.setLoading(false);
      this.showMessage('error', NOTI_TITLE, err)
    })
  }

  openDet(){}

  sortSearch(key, order?){
    this.field = key
    this.isSortSearch=true
    if(order!=null){
      this.order = order
    }else if(!this.order) {
      this.order = -1
    } else if(this.order == -1){
      this.order = 1
    } else if(this.order == 1) {
      this.order = -1
    }
    return this.sortPageSearch(this.sortSearchUrl,key)
  }
}
