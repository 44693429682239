import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-table-panel',
  templateUrl: './common-table-panel.component.html',
  styleUrls: ['./common-table-panel.component.css']
})
export class CommonTablePanelComponent implements OnInit {
  @Input() store
  @Input() searchStore
  @Input() productStore
  constructor() { }

  ngOnInit(): void {
  }

}
