import { Paginator } from 'primeng/paginator';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SearchStore } from 'src/app/stores/config/day_of_cover/SearchStore';

@Component({
  selector: 'app-search',
  providers: [SearchStore],
  templateUrl: './search.component.html',
  styles: [
    ":host ::ng-deep .p-datatable table td{height: 53px}"
  ]
})
export class SearchComponent implements AfterViewInit {
  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public store: SearchStore) { }
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
  }

}
