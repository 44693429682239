// copy from db
export enum FUNCTION_CODE {
    STOCK_BALANCE = "STOCK_BALANCE",
    TRANSACTION_HISTORY = "TRANSACTION_HISTORY",
    STOCK_TAKE = "STOCK_TAKE",
    RESERVATION = "RESERVATION",
    ALLOCATION = "ALLOCATION",
    STOCK_TRANSACTION = "STOCK_TRANSACTION",
    DEMAND_CYCLE = "DEMAND_CYCLE",
    REPORT = "REPORT",
    ITEM_MASTER = "ITEM_MASTER",
    STOCK_BACKEND_TRANSACTION = "STOCK_BACKEND_TRANSACTION",
    DOC_CAL_FACTOR = "DOC_CAL_FACTOR",
    CROSS_SHOP_QUOTA = "CROSS_SHOP_QUOTA",
    STOCK_AVAILABLE_LEVEL = "STOCK_AVAILABLE_LEVEL",
    TABLES_MAINT_CONFIG = "TABLES_MAINT_CONFIG",
    ITEM_TRANSFER_QUOTA = "ITEM_TRANSFER_QUOTA",
    ROLE_FUNCT_MAP = "ROLE_FUNCT_MAP",
    USER_ROLE_MAP = "USER_ROLE_MAP",
    FUNCTION_CONFIG = "FUNCTION_CONFIG",
    ROLE_CONFIG = "ROLE_CONFIG",
    STOCK_BALANCE_SEARCH = "STOCK_BALANCE_SEARCH",
    SO_TYPE1_SEARCH = "SO_TYPE1_SEARCH",
    SO_TYPE1_CREATE = "SO_TYPE1_CREATE",
    SO_TYPE1_COMPLETE = "SO_TYPE1_COMPLETE",
    SO_TYPE2_SEARCH = "SO_TYPE2_SEARCH",
    SO_TYPE2_CREATE = "SO_TYPE2_CREATE",
    SO_TYPE2_ALLOCATE = "SO_TYPE2_ALLOCATE",
    SO_TYPE2_TRANSFER = "SO_TYPE2_TRANSFER",
    SO_TYPE2_COMPLETE = "SO_TYPE2_COMPLETE",
    SO_TYPE3_SEARCH = "SO_TYPE3_SEARCH",
    SO_TYPE3_CREATE = "SO_TYPE3_CREATE",
    SO_TYPE3_COMPLETE = "SO_TYPE3_COMPLETE",
    SO_TYPE4_SEARCH = "SO_TYPE4_SEARCH",
    SO_TYPE4_CREATE = "SO_TYPE4_CREATE",
    SO_TYPE4_ALLOCTE = "SO_TYPE4_ALLOCTE",
    SO_TYPE4_TRANSFER = "SO_TYPE4_TRANSFER",
    SO_TYPE4_RECEIVE = "SO_TYPE4_RECEIVE",
    SO_TYPE4_COMPLETE = "SO_TYPE4_COMPLETE",
    SO_TYPE5_SEARCH = "SO_TYPE5_SEARCH",
    SO_TYPE5_CREATE = "SO_TYPE5_CREATE",
    SO_TYPE5_ALLOCATE = "SO_TYPE5_ALLOCATE",
    SO_TYPE5_TRANSFER = "SO_TYPE5_TRANSFER",
    SO_TYPE5_RECEIVE = "SO_TYPE5_RECEIVE",
    SO_TYPE5_COMPLETE = "SO_TYPE5_COMPLETE",
    SO_TYPE14_SEARCH = "SO_TYPE14_SEARCH",
    SO_TYPE14_CREATE = "SO_TYPE14_CREATE",
    SO_TYPE14_ALLOCATE = "SO_TYPE14_ALLOCATE",
    SO_TYPE14_TRANSFER = "SO_TYPE14_TRANSFER",
    SO_TYPE14_RECEIVE = "SO_TYPE14_RECEIVE",
    SO_TYPE14_COMPLETE = "SO_TYPE14_COMPLETE",
    SO_TYPE15_SEARCH = "SO_TYPE15_SEARCH",
    SO_TYPE15_CREATE = "SO_TYPE15_CREATE",
    SO_TYPE15_ALLOCATE = "SO_TYPE15_ALLOCATE",
    SO_TYPE15_TRANSFER = "SO_TYPE15_TRANSFER",
    SO_TYPE15_RECEIVE = "SO_TYPE15_RECEIVE",
    SO_TYPE15_COMPLETE = "SO_TYPE15_COMPLETE",
    SO_TYPE16_SEARCH = "SO_TYPE16_SEARCH",
    SO_TYPE16_CREATE = "SO_TYPE16_CREATE",
    SO_TYPE16_ALLOCATE = "SO_TYPE16_ALLOCATE",
    SO_TYPE16_TRANSFER = "SO_TYPE16_TRANSFER",
    SO_TYPE16_RECEIVE = "SO_TYPE16_RECEIVE",
    SO_TYPE16_COMPLETE = "SO_TYPE16_COMPLETE",
    RESERVATION_CREATE = "RESERVATION_CREATE",
    RESERVATION_SEARCH = "RESERVATION_SEARCH",
    RESERVATION_EDIT = "RESERVATION_EDIT",
    RESERVATION_EXTEND = "RESERVATION_EXTEND",
    STOCK_TAKE_SEARCH = "STOCK_TAKE_SEARCH",
    STOCK_TAKE_SURP_CREATE = "STOCK_TAKE_SURP_CREATE",
    STOCK_TAKE_SURP_PENDING = "STOCK_TAKE_SURP_PENDING",
    STOCK_TAKE_SURP_EDIT = "STOCK_TAKE_SURP_EDIT",
    STOCK_TAKE_SURP_EXPORT = "STOCK_TAKE_SURP_EXPORT",
    STOCK_TAKE_SURP_READY = "STOCK_TAKE_SURP_READY",
    STOCK_TAKE_SURP_CV = "STOCK_TAKE_SURP_CV",
    STOCK_TAKE_SURP_1ST = "STOCK_TAKE_SURP_1ST",
    STOCK_TAKE_FRONT_CREATE = "STOCK_TAKE_FRONT_CREATE",
    STOCK_TAKE_SURP_CREATE_AJ = "STOCK_TAKE_SURP_CREATE_AJ",
    STOCK_TAKE_FRONT_PENDING = "STOCK_TAKE_FRONT_PENDING",
    STOCK_TAKE_SURP_VIEW_REPORT = "STOCK_TAKE_SURP_VIEW_REPORT",
    STOCK_TAKE_CREATE = "STOCK_TAKE_CREATE",
    STOCK_TAKE_PENDING = "STOCK_TAKE_PENDING",
    STOCK_TAKE_EDIT = "STOCK_TAKE_EDIT",
    STOCK_TAKE_EXPORT = "STOCK_TAKE_EXPORT",
    STOCK_TAKE_READY = "STOCK_TAKE_READY",
    STOCK_TAKE_CV = "STOCK_TAKE_CV",
    STOCK_TAKE_1ST = "STOCK_TAKE_1ST",
    STOCK_TAKE_FRONT_EDIT = "STOCK_TAKE_FRONT_EDIT",
    STOCK_TAKE_CREATE_AJ = "STOCK_TAKE_CREATE_AJ",
    STOCK_TAKE_FRONT_EXPORT = "STOCK_TAKE_FRONT_EXPORT",
    STOCK_TAKE_VIEW_REPORT = "STOCK_TAKE_VIEW_REPORT",
    DEMAND_CYC_SEARCH = "DEMAND_CYC_SEARCH",
    DEMAND_CYC_CREATE = "DEMAND_CYC_CREATE",
    DEMAND_CYC_EDIT = "DEMAND_CYC_EDIT",
    DEMAND_INPUT = "DEMAND_INPUT",
    DEMAND_EDIT = "DEMAND_EDIT",
    REPLENISHMENT_CREATE = "REPLENISHMENT_CREATE",
    DEMAND_VIEW = "DEMAND_VIEW",
    RPT_DL = "RPT_DL",
    ALLOCATION_EDIT = "ALLOCATION_EDIT",
    ALLOCATION_SEARCH = "ALLOCATION_SEARCH",
    POP_ALLOCATED = "POP_ALLOCATED",
    EXPORT_ALLOCATED = "EXPORT_ALLOCATED",
    PPOS_STOCK_MOVT_HISTORY = "PPOS_STOCK_MOVT_HISTORY",
    PPOS_STOCK_MOVT_HISTORY_EXPORT = "PPOS_STOCK_MOVT_HISTORY_EXPORT",
    PPOS_STOCK_MOVT_HISTORY_SEARCH = "PPOS_STOCK_MOVT_HISTORY_SEARCH",
    TRANSACTION_LIS_INTERFACE = "TRANSACTION_LIS_INTERFACE",
    STOCK_TAKE_READY_2ND = "STOCK_TAKE_READY_2ND",
    STOCK_TAKE_2ND = "STOCK_TAKE_2ND",
    STOCK_TAKE_SUR_READY_2ND = "STOCK_TAKE_SUR_READY_2ND",
    STOCK_TAKE_SUR_2ND_COMPLETE = "STOCK_TAKE_SUR_2ND_COMPLETE",
    STOCK_TAKE_SUR_PENDING_2ND = "STOCK_TAKE_SUR_PENDING_2ND",
    STOCK_TAKE_PENDING_MARK_2ND = "STOCK_TAKE_PENDING_MARK_2ND",
    STOCK_TAKE_1ST_COMPLETE = "STOCK_TAKE_1ST_COMPLETE",
    STOCK_TAKE_2ND_COMPLETE = "STOCK_TAKE_2ND_COMPLETE",
    STOCK_TAKE_COMPLETE = "STOCK_TAKE_COMPLETE",
    STOCK_TAKE_SURP_1ST_COMPLETE = "STOCK_TAKE_SURP_1ST_COMPLETE",
    STOCK_TAKE_SURP_2ND_COMPLETE = "STOCK_TAKE_SURP_2ND_COMPLETE",
    STOCK_TAKE_SURP_COMPLETE = "STOCK_TAKE_SURP_COMPLETE",
    SO_TYPE1_REVIEW = "SO_TYPE1_REVIEW",
    SO_TYPE1_VERIFY = "SO_TYPE1_VERIFY",
    STOCK_TAKE_FRONT_READY = "STOCK_TAKE_FRONT_READY",
    STOCK_TAKE_FRONT_CV = "STOCK_TAKE_FRONT_CV",
    STOCK_TAKE_FRONT_1ST = "STOCK_TAKE_FRONT_1ST",
    STOCK_TAKE_FRONT_1ST_COMPLETE = "STOCK_TAKE_FRONT_1ST_COMPLETE",
    STOCK_TAKE_FRONT_READY_2ND = "STOCK_TAKE_FRONT_READY_2ND",
    STOCK_TAKE_FRONT_PENDING_MARK_2ND = "STOCK_TAKE_FRONT_PENDING_MARK_2ND",
    STOCK_TAKE_FRONT_2ND = "STOCK_TAKE_FRONT_2ND",
    STOCK_TAKE_FRONT_2ND_COMPLETE = "STOCK_TAKE_FRONT_2ND_COMPLETE",
    STOCK_TAKE_FRONT_CREATE_AJ = "STOCK_TAKE_FRONT_CREATE_AJ",
    STOCK_TAKE_FRONT_VIEW_REPORT = "STOCK_TAKE_FRONT_VIEW_REPORT",
    STOCK_TAKE_FRONT_COMPLETE = "STOCK_TAKE_FRONT_COMPLETE",
    QTY_ONHAND_SERIAL = "QTY_ONHAND_SERIAL",
    SO_TYPE1_FINALREVIEW = "SO_TYPE1_FINALREVIEW",
    SO_TYPE23_COL_SEARCH = "SO_TYPE23_SEARCH",
    SO_TYPE23_COL_CREATE = "SO_TYPE23_CREATE",
    SO_TYPE23_COL_COMPLETE = "SO_TYPE23_COMPLETE",
    SO_TYPE24_SEARCH = "SO_TYPE24_SEARCH",
    SO_TYPE24_RECEIVE = "SO_TYPE24_RECEIVE",
    SO_TYPE24_COMPLETE = "SO_TYPE24_COMPLETE",
    EXT_API_REQUEST_LOG = "EXT_API_REQUEST_LOG",
    SO_TYPE25_SEARCH = "SO_TYPE25_SEARCH",
    SO_TYPE25_CREATE = "SO_TYPE25_CREATE",
    SO_TYPE25_ALLOCATE = "SO_TYPE25_ALLOCATE",
    SO_TYPE25_TRANSFER = "SO_TYPE25_TRANSFER",
    SO_TYPE25_COMPLETE = "SO_TYPE25_COMPLETE",
    SO_TYPE20_SEARCH = "SO_TYPE20_SEARCH",
    SO_TYPE21_SEARCH = "SO_TYPE21_SEARCH",
    SO_TYPE27_COMPLETE = "SO_TYPE27_COMPLETE",
    SO_TYPE27_CREATE = "SO_TYPE27_CREATE",
    SO_TYPE27_FINALREVIEW = "SO_TYPE27_FINALREVIEW",
    SO_TYPE27_REVIEW = "SO_TYPE27_REVIEW",
    SO_TYPE27_SEARCH = "SO_TYPE27_SEARCH",
    SO_TYPE27_VERIFY = "SO_TYPE27_VERIFY",
    PPOS_DATA_MIGRATION = "PPOS_DATA_MIGRATION",
    KAFKA_TEST = "KAFKA_TEST",
    API_CALL_LOG = "API_CALL_LOG",
    STOCK_BALANCE_SEARCH_INCL_LIS = "STOCK_BALANCE_SEARCH_INCL_LIS",
    STOCK_TAKE_REMARKS_EDIT = "STOCK_TAKE_REMARKS_EDIT",
    STOCK_TAKE_SURP_REMARKS_EDIT = "STOCK_TAKE_SURP_REMARKS_EDIT",
    STOCK_TAKE_FRONT_REMARKS_EDIT = "STOCK_TAKE_FRONT_REMARKS_EDIT",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    RESERVATION_INTERFACE = "RESERVATION_INTERFACE",
    LIS_INTERFACE_EDIT="LIS_INTERFACE_EDIT",
    PPOS_DATA_MIGRATION_EDIT="PPOS_DATA_MIGRATION_EDIT",
    DOC_CALCULATION_FACTOR_EDIT="DOC_CALCULATION_FACTOR_EDIT",
    CROOS_SHOP_PICKUP_QUOTA_EDIT="CROOS_SHOP_PICKUP_QUOTA_EDIT",
    STOCK_AVAILABILITY_LEVEL_EDIT ="STOCK_AVAILABILITY_LEVEL_EDIT",
    ITEM_TRANSFER_QUOTA_EDIT = "ITEM_TRANSFER_QUOTA_EDIT",
    ROLE_FUNCTION_MAPPING_EDIT = "ROLE_FUNCTION_MAPPING_EDIT",
    USER_ROLE_MAPPING_EDIT = "USER_ROLE_MAPPING_EDIT",
    ROLE_EDIT = "ROLE_EDIT",
}