import { Injectable } from "@angular/core";
import { INPUT_TYPE , NOTI_MSG, NOTI_TITLE, URLDICT} from "src/app/stores/base/BaseStore";
import { MENU } from "src/app/config";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService, ConfirmationService } from "primeng/api";
import { ApiCallLogService } from "src/app/service/stock/api-call-log.service";
import { DatePipe } from "@angular/common";
import { find, pluck, switchMap } from "rxjs/operators";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import {observable} from "mobx";
import { ItemMasterStore } from "../item/itemMasterStore";
import { of } from "rxjs";
import { message } from "src/assets/ext_js/dhtmlx-spreadsheet/codebase/spreadsheet";
import { JsonDisplayPopUpComponent } from "@/components/json-display-pop-up/json-display-pop-up.component";

@Injectable()
export class ApiCallLogStore {
  @observable selectedRow: any[] = []
  @observable loadingSwitch = false
  requestJson
  reponseJson
  popUpVisable = false
  jsonResult
  breadcrumb = MENU.API_CALL_LOG
  queryData = {
    ipts: [
      {
        title: "Request date",
        type: INPUT_TYPE.DATERANGE,
        class: "p-col-12 p-md-4 p-lg-3",
      },
      {
        title: "Network Status Code",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      },
      {
           title: "Request Json",
           name: 'RequestJson',
           type: INPUT_TYPE.INPUT,
           class: "p-col-12 p-md-4 p-lg-3",
           value: null,
      },
      {
        title: "Response Json",
        name: 'ReponseJson',
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
   },
       {
        title: "API Type",
        name: "ApiType",
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
       },

      {
        title: "Request url",
        name: 'requesturl',
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null
      }
    ],
    btns: [
      {
        title: 'Search',
        class: "p-order-4",
        permissionType: 'c',
        show: true,
        handler: { click: () => this.searchFromFilter() }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
        show: true,
        handler: { click: () => this.clear() }
      },
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-6'
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      {field: 'apiType_name', title: 'Api Type Name', width: '50px'},
      {field: 'api_url', title: 'Request Url', width: '200px',type:'link'},
      {field: 'request_json', title: 'Request Body', width: '200px',type:'link'},
      {field: 'create_date', title: 'Request Date', width: '100px'},
      {field: 'network_status', title: 'Network Status Code', width: '50px'},
      {field: 'response_json', title: 'Response Json', width: '80px',type:'link'},
      {field: 'response_time', title: 'Response Time(s)', width: '80px'},
      //{field: 'api_type_id', title: 'Api Type Id', width: '160px'},
      //{field: 'source_ref_header_no', title: 'Source System Header No', width: '150px'},
    ],
    selectedColumns: [],
    visibleColumns: [],
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    initSortField:  'request_date',
    initSortOrder:  1,
    sortField: { field: 'request_date', order: 1 },
    showCheckboxSwitch: false,
    loadingSwitch: false,
    selMode: 'single',
  }
  showEdit: false
  table = null
  paginator = null
  NetworkOptions = [
    {name: '100', code: '100'},
    {name: '101', code: '101'},
    {name: '200', code: '200'},
    {name: '201', code: '201'},
    {name: '202', code: '202'},
    {name: '203', code: '203'},
    {name: '204', code: '204'},
    {name: '205', code: '205'},
    {name: '206', code: '206'},
    {name: '300', code: '300'},
    {name: '301', code: '301'},
    {name: '302', code: '302'},
    {name: '303', code: '303'},
    {name: '304', code: '304'},
    {name: '305', code: '305'},
	  {name: '306', code: '306'},
    {name: '307', code: '307'},
    {name: '400', code: '400'},
    {name: '401', code: '401'},
    {name: '402', code: '402'},
    {name: '403', code: '403'},
    {name: '404', code: '404'},
    {name: '405', code: '405'},
    {name: '406', code: '406'},
    {name: '407', code: '407'},
    {name: '408', code: '408'},
    {name: '409', code: '409'},
    {name: '410', code: '410'},
    {name: '411', code: '411'},
    {name: '412', code: '412'},
    {name: '413', code: '413'},
    {name: '414', code: '414'},
    {name: '415', code: '415'},
    {name: '416', code: '416'},
    {name: '417', code: '417'},
    {name: '500', code: '500'},
    {name: '501', code: '501'},
    {name: '502', code: '502'},
    {name: '503', code: '503'},
    {name: '504', code: '504'},
    {name: '505', code: '505'},
  ]
  UrlOptions = []
  defaultApiType = []
  defaultUrl=[]
  apiTypeOptions = []
  apiType = []
  requesturl=[]
  requestUrlOptions=[]

  //source_system_name
  dateIpt = null
  permission = null
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private datepipe: DatePipe,
    private conf: ConfirmationService,
    private serive: ApiCallLogService) {
    // makeAutoObservable(this)
    this.getPermission()
    this.getResolverData();
    this.setSelectedColumns()
    this.initQueryData()
  }
  get requestDate() {
    return this.dateIpt.value;
  }

  get network() {
    let networkIpt = this.getIptByQueryData('Network Status Code')
    return networkIpt.value;
  }

  get ApiType() {
    let ApiTypeIpt = this.getIptByQueryData('API Type')
    return ApiTypeIpt.value;
  }
  //  get apiRequesturl() {
  //   let requestUrlIpt = this.getIptByQueryData('Request url')
  //   return requestUrlIpt.value;
  // }
  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
  }

  getResolverData() {
      let apiType = LocalStorageHelper.getObject('API_TYPE')
      this.apiType = apiType
      this.apiTypeOptions = apiType.map(item => {
        let id =  item.api_type_id
        let code=item.api_code
        return {name:code, value:id}
      })

      // let requestUrl = LocalStorageHelper.getObject('REQUESTURL')
      // this.requesturl=requestUrl
      // this.requestUrlOptions = requestUrl.map(item => {
      //   let url=item.request_url
      //   return {name:url}
      // })
    }

  getIptByQueryData(name) {
    return this.queryData.ipts.find( ipt =>  ipt.title === name)
  }
  setSelectedColumns() {
    this.tableData.selectedColumns = this.tableData.columns
  }
  initSort() {
    this.tableData.sortField.field = this.tableData.initSortField;
    this.tableData.sortField.order = this.tableData.initSortOrder
  }
  initQueryData() {
    this.dateIpt = this.getIptByQueryData('Request date')
    let NetworkIpt = this.getIptByQueryData('Network Status Code')
    NetworkIpt.options = this.NetworkOptions

    //let URLIpt = this.getIptByQueryData('Request url')
    // URLIpt.options = this.requestUrlOptions
    // URLIpt.value = this.requestUrlOptions.filter(item => this.defaultUrl.includes(item.name))

    let apiTypeIpt = this.getIptByQueryData('API Type')
    apiTypeIpt.options = this.apiTypeOptions

    apiTypeIpt.value = this.apiTypeOptions.filter(item => this.defaultApiType.includes(item.code))
    //this.search()
  }

  searchFromFilter() {
    this.table.reset()
    this.initSort()
    this.paginator ? this.paginator.changePage(0) : this.search()
  }
  setDatePipe(datepipe){
    this.datepipe = datepipe
  }
  dateFormatToApi(date){
    return this.datepipe.transform(date, "yyyy-MM-dd HH:mm:ss.SS" )
  }
  clear() {
    for (const ipt of this.queryData.ipts) {
if (ipt.title === 'API Type') {
        ipt.value = this.apiTypeOptions.filter(item => this.defaultApiType.includes(item.code))
      } else {
        ipt.value = null
      }
    }
  }

  sortSearch(field) {
    this.tableData.sortField.field = field;
    this.tableData.sortField.order = -this.tableData.sortField.order;
    this.search()
  }

  search() {
    this.requestJson = this.queryData.ipts.find(item => item.title === "Request Json").value;
    this.reponseJson = this.queryData.ipts.find(item => item.title === "Response Json").value;
    this.requesturl = this.queryData.ipts.find(item => item.title === "Request url").value;
    // 如果用户只选择了一个日期（from date），则将to date也定为同一天
    if(this.requestDate==null){
      this.showMessage('error', 'Date Required', 'Request Date is required.')
      return
    }
    if(this.requestDate && !this.requestDate[1]) {
      this.requestDate[1] = this.requestDate[0]
    }

    let data = {
      ...(this.requestDate && {request_datetime: this.requestDate.map(item=>this.formatDate(item))}),
      ...(this.network && this.network.length>0 && {network: this.network.map(item=>item.name)}),
      ...(this.apiType && {api_type_id: this.ApiType.map(item=>item.value)}),
      ...(this.requestJson && {requestJson: this.requestJson}),
      ...(this.reponseJson && {reponseJson: this.reponseJson}),
      ...(this.requesturl && {requesturl: this.requesturl}),
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
    }
    this.tableData.loadingSwitch = true
    this.serive.search(data).subscribe(res => {
      this.tableData.loadingSwitch = false
      if (res.code === '000') {
        let data=res.data
        data.forEach((item, idx) => {
          let ApiTypeResult=this.apiType.find((x) => x.api_type_id == item.api_type_id)
          if(ApiTypeResult)item.apiType_name=ApiTypeResult.api_code
          item.create_date=this.dateFormatToApi(item.create_date)
        });
        this.tableData.totalRecords = res.msg
        this.tableData.data = data
        //console.log(res.data)
      } else {
        this.tableData.data = []
        this.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }
    })
  }

  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search()
  }

  showMessage(severity, summary, detail) {
    this.msg.add({severity, summary, detail});
  }

  formatDate(date: Date) {
    return date.getTime();
  }
  jsonShowDetail(json){
    this.popUpVisable=true
    this.jsonResult=json
  }

  popupHide(e){this.popUpVisable = e}
}
