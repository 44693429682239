import { CommonService } from "@/service/common/common-service";
import { Injectable } from "@angular/core";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService } from "primeng/api";
import { pluck } from "rxjs/operators";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";

@Injectable({providedIn: "root"})
export class QtyOnhandSerialStore {
  TITLE = {
    CHANNEL: "Channel",
    ITEM_CODE: "Item Code",
    SERIAL_NO:"Serial No."
  }
  info = {
    title: "Qty Onhand Serial",
    success: "Created successfully!",
    cancel: "Confirm to cancel?!",
    failed: "Failed to get data",
    unknown: "Unknown error in API access"
  }
  channels = []
  items = []
  account = this.commonService.getAccount()
  constructor(
    private msg: MessageService,
    private conf: ConfirmationService,
    public commonService: CommonService) {
    makeAutoObservable(this)
  }
  getResolverData(route) {
    route.data.pipe(pluck('query')).subscribe(res => {
      this.channels = LocalStorageHelper.getObject('REPOTREE').filter(e=>e.key!='Warehouse')
      this.items = LocalStorageHelper.getObject('SKUMODULEBYUAM')
    })
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
}
