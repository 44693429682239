import { Component, OnInit } from '@angular/core';
import { CreateStore } from 'src/app/stores/stock/transaction_backend/CreateStore';

@Component({
  selector: "app-transaction-backend",
  providers: [CreateStore],
  templateUrl: "./transaction-backend.component.html",
  styleUrls: ["./transaction-backend.component.css"],
})
export class TransactionBackendComponent implements OnInit {
  constructor(public store: CreateStore) {}
  ngOnInit(): void {}
}
