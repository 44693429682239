import {NgModule} from '@angular/core';

// import {AutoCompleteModule} from 'primeng/autocomplete';
// import {AccordionModule} from 'primeng/accordion';
import {BadgeModule} from 'primeng/badge';
import {BlockUIModule} from 'primeng/blockui';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
// import {CascadeSelectModule} from 'primeng/cascadeselect';
// import {ChipsModule} from 'primeng/chips';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {InputTextModule} from 'primeng/inputtext';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {KnobModule} from 'primeng/knob';
import {ListboxModule} from "primeng/listbox";
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MultiSelectModule} from 'primeng/multiselect';
import {PaginatorModule} from "primeng/paginator";
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
// import {SlideMenuModule} from 'primeng/slidemenu';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { OverlayModule } from '@angular/cdk/overlay'
import {TooltipModule} from 'primeng/tooltip';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { CusMultiSelectModule } from './modules/cus-multi-select-module';

@NgModule({
  exports: [
    OverlayModule,
    // AutoCompleteModule,
    // AccordionModule,
    BadgeModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    // CascadeSelectModule,
    // ChipsModule,
    CheckboxModule,
    RadioButtonModule,
    DialogModule,
    DividerModule,
    FieldsetModule,
    ConfirmDialogModule,
    DropdownModule,
    InputTextModule,
    InputMaskModule,
    InputTextareaModule,
    InputNumberModule,
    InputSwitchModule,
    KnobModule,
    ListboxModule,
    MenuModule,
    MenubarModule,
    MultiSelectModule,
    CusMultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    // SlideMenuModule,
    SplitButtonModule,
    TableModule,
    TabViewModule,
    TreeModule,
    TreeTableModule,
    TieredMenuModule,
    ToastModule,
    TooltipModule,
    VirtualScrollerModule,
  ]
})
export class ShareModule {
}
