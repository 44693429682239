import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  template: `
    <div class="p-d-flex p-jc-between">
      <div class="header">{{name}}</div>
      <div style="text-align: right;display: flex;align-items: center;" *ngIf="showBtn">
        <button type="button" pButton [label]="'Create ' + name" style="height: 40px;" (click)="onClick()"></button>
      </div>
    </div>
  `,
  styles: [],
  selector: 'illustration',
  styleUrls: ['./illustration-component.scss'],
})

export class IllustrationComponent implements OnInit{
  @Input() name
  @Input() showBtn = ""
  @Input() path
  @Output() add = new EventEmitter()
  src
  ngOnInit(): void {
    if(this.path){
      this.src = {
        [this.name]: '../../../../assets/Illustration/Illustration_'+this.path+'.svg'
      }
    } else {
      this.src = {
        [this.name]: '../../../../assets/Illustration/Illustration_'+this.name+'.svg'
      }
    }
  }
  onClick(){
    this.add.emit()
  }
}
