import { CONFIG } from '@/stores/base/BaseStore';
import { TakeEditStore } from '@/stores/stock/take/takeEditStore';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-serial-scanall-cst',
  templateUrl: './serial-scanall-cst.component.html',
  styleUrls: ['./serial-scanall-cst.component.scss']
})
export class SerialScanAllCstComponent implements OnInit {

  @Input() config
  @Input() existSerialList
  @Input() currentTakeLineSerialList: string[]
  @Input() selectedItemList

  // onConfirm emit sample
  // {
  //   _data: serialList,
  //   itemCodeList: [],
  //   formattedData: {},
  // }
  @Output() onConfirm = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent;

  @Input() visible = false
  // @Output() visibleChange = new EventEmitter()
  // set visible(e){this._visible=e;this.visibleChange.emit(e)}
  // get visible(){return this._visible}

  @Input() loadingSwitch = false;

  init = false
  deviceSelected: string;
  deviceCurrent: MediaDeviceInfo;
  availableDevices: MediaDeviceInfo[];
  hasPermission;
  hasDevices;
  isScannerEnable = false;
  tryharder = false
  scannerObj = null;
  closeScannerTimer
  formats = CONFIG.SCANNERFORMAT
  SCANNERTRYHARDER = CONFIG.SCANNERTRYHARDER
  serialFilter = ''
  _serialList = [];

  qtyTotalCst
  qtyAppendableCst

  selectedSerialRow

  constructor(
    private msg: MessageService,
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.initQtyCst()
  }

  ngOnChanges(change){
  }

  initQtyCst(){
    this.qtyTotalCst = {  value: 0, disabled: true, title: 'Scanned Serial Qty' }
    this.qtyAppendableCst = {  value: 0, disabled: true, title: 'Stock Take Serial Qty' }
  }

  toggleScanner(e){
    this.isScannerEnable = e;
    if(e==false && this.scanner){
      this.scanner.enable = false;
    }
  }
  
  onDeviceChange(device: MediaDeviceInfo) {
    const selectedDevice = device || null;
    if (selectedDevice&&this.deviceSelected === selectedDevice.deviceId) {
      return;
    }
    if(selectedDevice)this.deviceSelected = selectedDevice.deviceId;
    this.deviceCurrent = device || undefined;
  }

  onCodeResult(res: string) {
    this.onInput(res);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = [...devices];
    this.hasDevices = Boolean(devices && (devices.length));
    this.deviceCurrent = this.availableDevices[0];
    this.tryharder = false;
  }

  onDeviceSelectChange(selected) {
    const selectedStr = selected.value || "";
    let device = this.availableDevices.find(
      (x) => x.deviceId === selectedStr
    );
    device = (device.deviceId == ''? undefined:device)
    this.deviceCurrent = device || undefined;
  }

  setModalVisible(e) {
    // let that = this;
    if (this.scanner) {
      this.scannerObj = this.scanner;
      this.scanner.reset();
    }
    if(e) this.init = true;
    if(!e) this.deviceCurrent = undefined
    this.visible = e;
    // this.toggleScanner(e)
    if(!e){
      this.isScannerEnable = false
      this.autoCloseScannerIfModalClosed()
    }else{
      if(this.closeScannerTimer){
        clearTimeout(this.closeScannerTimer)
      }
    }
  }
  
  onInput(v) {
    // this.onResult.emit(v);
    let success = this.addToSerialList(v, true)
    if(success)this.showMessage('info', 'Scan Success', `Serial Number ${v} added`)
  }
  autoCloseScannerIfModalClosed(){
    if(this.closeScannerTimer){
      clearTimeout(this.closeScannerTimer)
    }
    if(!this.isScannerEnable&&this.scannerObj&&this.scannerObj.isAutostarting){
      if(this.scannerObj&&this.scannerObj._enabled == true){
        this.closeScannerTimer = setTimeout(()=>{
          if(!this.isScannerEnable){
            if(this.scannerObj.isAutostarting){
              this.autoCloseScannerIfModalClosed()
              return
            }
            if(this.scannerObj&&this.scannerObj._enabled == true){
              this.scannerObj.reset();
              this.scannerObj.enable = false;
              this.isScannerEnable = false;
            }
          }
        }, 500)
      }
    }
  }
  scanFailure(e){
  }
  scanError(e){
  }
  scanComplete(e){
  }
  serialFilterChanged(e){
    // let filter = e.toLowerCase()
    // this._serialList = this._bufferSerialList.filter(serial=>{
    //   return serial.toLowerCase().indexOf(filter) >= 0
    // })
  }
  reset(){
    this._serialList = [];
    this.initQtyCst();
  }

  clearFilter(){
    this.serialFilter = '';
    this.serialFilterChanged(this.serialFilter);
  }

  // serialModalRangeChange(){
  //   if(this.store.serialModalRangeChange){
  //     this.store.serialModalRangeChange()
  //   }
  // }

  serialFileDropped(e){
    // this.store.serialFileDropped(e)
    let reader = new FileReader()
    if(e.length>1){
      this.showMessage( 'error', 'Upload Failed', `Only allow one file at a time.` )
      return
    }
    if(!(e[0].fileEntry.isFile && this.isFileAllowed(e[0].fileEntry.name))){
      this.showMessage( 'error', 'Upload Failed', `Only '.csv', '.txt' format is accepted.` )
      return
    }
    e[0].fileEntry.file((file) => {
      reader.readAsText(file)
      reader.onload = () => {
        let res = (<string>reader.result)
          .split(/\r?\n/)
          .filter((content) => content.length > 0)
        let successCount = 0
        let duplicateCount = 0
        res.forEach((val) => {
          let success = this.addToSerialList(val)
          if (success) {
            successCount++
          } else {
            duplicateCount++
          }
        })
        if (successCount)
          this.showMessage( 'info', 'Add Success', `${successCount} Serial Number Added` )
        if (duplicateCount)
          this.showMessage( 'error', 'Add Fail', `${duplicateCount} Serial Number Duplicated` )
      }
    })
  }
  
  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.csv', '.txt'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  addToSerialList(e:string, isUnshift=false){
    if(!e||e.length==0) return
    let _e = e.trim()
    let duplicate = this._serialList.some(serial=>{return serial.serial==_e})
    if(duplicate) return false
    let snapshotData = this.existSerialList.find(serial=>serial.serial==_e)
    let itemCode = snapshotData?snapshotData.item_code:null
    let exist = snapshotData?true:false
    let stockTakeItem = itemCode?this.selectedItemList.some(serial=>serial.itemCode==itemCode):null
    let condition = snapshotData?snapshotData.condition:null
    let duplicateInStockTake = false
    duplicateInStockTake = this.currentTakeLineSerialList.some(serial=>{return serial==_e})
    let existInTake = duplicateInStockTake

    let _exist;
    let _stockTakeItem;
    let _existInTake;
    let _condition;
    let _itemCode;

    if (exist != null) {
      if (exist) {
        _exist = "Yes";
      } else {
        _exist = "No";
      }
    }
    if (stockTakeItem) {
      _stockTakeItem = "Yes";
    } else {
      _stockTakeItem = "No";
    }
    if (existInTake != null) {
      if (existInTake) {
        _existInTake = "Yes";
      } else {
        _existInTake = "No";
      }
    }
    if (condition) {
      _condition = condition;
    }
    if(itemCode&&itemCode!=null&&itemCode.length>0){
      _itemCode=itemCode
    }
    let serialObj = {
      serial: _e,
      itemCode: itemCode,
      _itemCode: itemCode?itemCode:'-',
      _exist: _exist?_exist:'-',
      _stockTakeItem: stockTakeItem?_stockTakeItem:'-',
      _existInTake: _existInTake?_existInTake:'-',
      _condition: condition?condition:'-',
      exist: exist,
      stockTakeItem: stockTakeItem,
      condition: condition,
      existInTake: existInTake,
    }
    if (isUnshift) {
      this._serialList.unshift(serialObj);
    } else {
      this._serialList.push(serialObj);
    }
    this.qtyTotalCst.value++
    if(this.serialAppendable(serialObj)) this.qtyAppendableCst.value++
    return true
  }

  serialAppendable(serial){
    return serial.exist && serial.stockTakeItem && !serial.existInTake
  }

  _onHide(){
    this.selectedSerialRow = [];
    this.onHide.emit()
  }

  addSerialByInput(e){
    this.addToSerialList(e, true)
    this.config.newSerialValue = null
  }

  confirm(){
    this.onConfirm.emit(this.getItemConditionSerialList())
  }

  getItemConditionSerialList(serialList = this._serialList){
    let res = {
      _data: serialList,
      itemCodeList: [],
      formattedData: {},
    }
    let formattedData = {};
    let itemCodeList = [];
    serialList.filter((serial=>{
      return this.serialAppendable(serial) // serial.exist && serial.stockTakeItem && !serial.existInTake
    })).forEach(serial=>{
      if(!formattedData[serial.itemCode]) {
        formattedData[serial.itemCode] = {}
        itemCodeList.push(serial.itemCode)
      }
      if(!formattedData[serial.itemCode][serial.condition]) formattedData[serial.itemCode][serial.condition] = []
      formattedData[serial.itemCode][serial.condition].push(serial)
    })
    res.itemCodeList = itemCodeList;
    res.formattedData = formattedData;
    return res
  }

  onSerialRowDelete(data, idx){
    let serialObj = this._serialList[idx]
    this._serialList.splice(idx, 1)
    this.qtyTotalCst.value--
    if(this.serialAppendable(serialObj)) this.qtyAppendableCst.value--
  }

  cancel(){
    this.onCancel.emit()
  }

  multiDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    // this.onMultiDelete.emit(this.selectedSerialRow)
    let res = this._serialList.filter(e=>!this.selectedSerialRow.includes(e))
    this.selectedSerialRow = [];
    this._serialList = res;
  }

  confirmBulkDelete(){
    if(!this.selectedSerialRow || this.selectedSerialRow.length == 0) return
    let totalRemove = this._serialList.filter(e=>this.selectedSerialRow.includes(e))
    this.confirmationService.confirm({
      message: 'Are you sure to delete selected serial numbers?',
      header: `Delete Serial Number`,
      accept: () => {
        this.multiDelete()
      }
      // rejectLabel: 'Close'
    });
  }
}

interface ScanAllData{
  serial:string,
  itemCode:string,
  haveqtyonhandserial:boolean,
  ischannelmatch:boolean,
  isstocktakeitem:boolean,
  condition:string,
  shouldaddtoline:boolean,
}
