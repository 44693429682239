import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { ExtApiRequestLogStore } from 'src/app/stores/stock/ExtApiRequestLog/ExtApiRequestLogStore';

@Component({
  providers: [ExtApiRequestLogStore],
  selector: 'ext_api_request_log',
  templateUrl: './ext_api_request_log.component.html',
  styleUrls: ['./ext_api_request_log.component.css']
})
export class ExtApiRequestLogComponent implements AfterViewInit {
  @ViewChild('paginator', {static: false}) paginator: Paginator;
  @ViewChild('ptable', {static: false}) ptable: Table;
  constructor(public store: ExtApiRequestLogStore) { }
  sourceSystemList = [];
  ngAfterViewInit(): void {
    this.store.table = this.ptable
    this.store.paginator = this.paginator
  }

}
