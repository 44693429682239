import { computed, makeObservable, observable } from 'mobx';
import { Injectable } from "@angular/core";
import { INPUT_TYPE, BUTTON_WRAPPER, CONFIG } from '../../base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { StockDemandCycleService } from 'src/app/service/stock/stock-demand-cycle.service';
import { ReplenishmentStore } from './ReplenishmentStore';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DemandStore } from '../demand/DemandStore';
import { LoadingService } from '@/service/common/loading-service';
import { CommonMethod } from '@/util/CommonMethod';
import { forkJoin, from, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CommonService } from '@/service/common/common-service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PermissionService } from '@/service/common/permission-service';
import { FUNCTION_CODE } from '@/service/common/permission.type';

@Injectable()
export class DetailStore extends ReplenishmentStore {

  @observable _loading = 0;
  @computed get cycleCount(){return this._loading};
  set cycleCount(e) {
    this._loading = e > 0? e: 0;
  };
  reserveLisCreateTimer

  breadcrumb = this.info.title
  subBreadcrumb = 'Detail'
  parentData ={
    ipts: [
      {
        title: this.TITLE.DEMAND_CYCLE_NAME,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      },
       /* {
        title: this.TITLE.STOCK_POOL_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-2",
        disabled: true,
        value: null,
      },  */
       {
        title: this.TITLE.DEMAND_CYCLE_TYPE,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.TITLE.SUBMISSION_DEADLINE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        // showTime: true,
        notIcon: true,
        value: null,
      }, {
        title: this.TITLE.DEMAND_START_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-3 p-lg-3",
        disabled: true,
        notIcon: true,
        value: null,
      },
      // {
      //   title: this.TITLE.DEMAND_END_DATE,
      //   type: INPUT_TYPE.DATETIME,
      //   class: "p-col-12 p-md-4 p-lg-2",
      //   disabled: true,
      //   notIcon: true,
      //   value: null,
      // }
    ],
    btns: [
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-2'
  }
  queryData = {
    ipts: [
      {
        title: this.QUERY_TITLE.CHANNEL,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled: true,
        options: [],
        optionLabel: 'label',
        optionValue: 'id',
        error: false,
      }, {
        title: this.QUERY_TITLE.REFERENCE_NUMBER,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.STATUS,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
      }, /* {
        title: this.QUERY_TITLE.BU_CODE,
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
        options: [],
        optionLabel: 'name',
        optionValue: 'value',
      }, */ {
        title: this.QUERY_TITLE.NEED_BY_DATE,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        notIcon: true,
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.UPDATED_BY,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.UPDATED_DATETIME,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        notIcon: true,
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.SUBMITTED_BY,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.SUBMITTED_DATETIME,
        type: INPUT_TYPE.DATETIME,
        class: "p-col-12 p-md-4 p-lg-3",
        notIcon: true,
        disabled: true,
        value: null,
      }, {
        title: this.QUERY_TITLE.REMARKS,
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-12",
        disabled: true,
        value: null,
      }
    ],
    btns: [
      {
        title: this.BUTTON_TITLE.BACK,
        class: "p-button-outlined p-mr-1",
        show: true,
        handler: { click: () => this.back() }
      },
      {
        title: this.BUTTON_TITLE.EXPORT,
        class: "p-mr-1",
        show: true,
        handler: { click: () => this.exportReplenishmentAndPreAllocateReservation() }
      },
      {
        title: this.BUTTON_TITLE.REFRESH_WAREHOUSE_QTY,
        class: "p-mr-1",
        show: true,
        handler: { click: () => this.confirmUpdateOnhandQty() }
      },
      {
        title: this.BUTTON_TITLE.UPDATE_WAREHOUSE_QTY,
        class: "p-mr-1",
        show: true,
        handler: { click: () => this.updateReplenishLineQty() }
      },
      {
        title: this.BUTTON_TITLE.EDIT,
        class: "p-mr-1",
        show: true,
        handler: { click: () => this.edit() }
      }, {
        title: this.BUTTON_TITLE.VERIFY,
        class: "p-mr-1",
        show: true,
        disabled: true,
        handler: { click: () => this.verify() }
      }, {
        type: BUTTON_WRAPPER,
        show: true,
        class: 'cus-button-wrapper p-mb-2',
      }, {
        title: this.BUTTON_TITLE.RESERVE_LIS,
        class: "p-mr-1",
        permissionType: 'c',
        show: true,
        disabled: true,
        handler: { click: () => {
          this.reserveAllLineByChannel()
          // this.reserveLis()
        } }
      }, {
        title: this.BUTTON_TITLE.SUBMIT_TO_LIS,
        class: "p-mr-1",
        permissionType: 'c',
        show: true,
        disabled: true,
        handler: { click: () => {
          // this.submitLineOneByOneAndUpdateStatus()
          this.submitAllLineByChannel()
        } }
      }, {
        title: this.BUTTON_TITLE.RE_RESERVE_LIS,
        class: "p-mr-1",
        permissionType: 'c',
        show: false,
        disabled: true,
        handler: { click: () => this.rereserveLis() }
      }, {
        title: this.BUTTON_TITLE.RE_SUBMIT_TO_LIS,
        class: "p-mr-1",
        permissionType: 'c',
        show: false,
        disabled: true,
        handler: { click: () => this.submitAllLineByChannel(true) }
      }, {
        title: this.BUTTON_TITLE.REFRESH_STATUS,
        class: "p-mr-1",
        permissionType: 'c',
        show: false,
        disabled: true,
        handler: { click: () => this.refreshReplenishmentStatus() }
      }, {
        type: BUTTON_WRAPPER,
        show: true,
        class: 'cus-button-wrapper p-mb-2',
      }, {
        title: this.BUTTON_TITLE.RESERVE_LIS_NOP,
        class: "p-mr-1",
        permissionType: 'c',
        show: false,
        disabled: false,
        handler: { click: () => this.reserveLisForNop() }
      }, {
        title: this.BUTTON_TITLE.SUBMIT_TO_DM,
        class: "p-mr-1",
        permissionType: 'c',
        show: false,
        disabled: true,
        handler: { click: () => this.submitToDM() }
      },
       /* {
        title: this.BUTTON_TITLE.CANCEL_LIS_RESERVATION,
        class: "p-order-1 p-mr-1",
        show: true,
        disabled: true,
        handler: { click: () => this.edit() }
      } */
    ],
    btnsclass: 'p-d-flex p-col-12 p-flex-wrap p-justify-end',
    extended: {
      show: false,
      title: this.QUERY_TITLE.FIELDSET,
      type: INPUT_TYPE.FIELDSET,
      class: "p-col-12 p-md-4 p-lg-9",
      ipts:[
        {
          title: this.QUERY_TITLE.ROADSHOW_CHANNEL,
          type: INPUT_TYPE.TREESELECT,
          class: "p-col-12 p-md-6 p-lg-6",
          disabled: true,
          value: null,
          showValue: null,
          options: [],
          optionLabel: 'label',
          optionValue: 'id'
        }, {
          title: this.QUERY_TITLE.SCHOOL_NUMBER,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          value: null,
          disabled: true,
        },
        // {
        //   title: this.QUERY_TITLE.SCHOOL_LOCATION_CODE,
        //   type: INPUT_TYPE.INPUT,
        //   class: "p-col-12 p-md-4 p-lg-4",
        //   value: null,
        // },
        {
          title: this.QUERY_TITLE.CONTACT_PERSON,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          value: null,
          disabled: true,
        }, {
          title: this.QUERY_TITLE.CONTACT_PHONE,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-6 p-lg-6",
          value: null,
          disabled: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_1,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          value: null,
          disabled: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_2,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          value: null,
          disabled: true,
        }, {
          title: this.QUERY_TITLE.ADDRESS_3,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12",
          value: null,
          disabled: true,
        }
      ]
    },
  }
  tableFilterPanel:any = {
    ipts: [
    ],
    btns: [
    ],
  }
  tableData:any = null
  nopTableData:any = null
  nopOrderExpandCol:any = null
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  scrollable:boolean = true
  loading:boolean = false
  showCancel:boolean = true // cancel lis reservation contral
  demandLineData = null
  demandData = null
  cycleNameIpt = null
  poolTypeIpt = null
  cycleTypeIpt = null
  startDateIpt = null
  endDateIpt = null
  submissionDeadlineIpt = null
  channelIpt = null
  referenceIpt = null
  statusIpt = null
  // buCodeIpt = null
  needByDateIpt = null
  remarksIpt = null
  onlyIncludeIpt = null
  updatedByIpt = null
  updateDateIpt = null
  submittedByIpt = null
  submittedDateIpt = null
  // schoolCodeIpt = null
  // schoolLocationCodeIpt = null
  extendChannelIpt = null
  courierIpt = null
  schoolNumberIpt = null
  contactPersonIpt = null
  contactPhoneIpt = null
  address1Ipt = null
  address2Ipt = null
  address3Ipt = null
  channels = [] // warehouse
  demandLineList = []
  nopOrderList = []
  idx = 0
  skus = []
  demandLine:any;
  replenishChannel; // for ic code display

  haveWarehouseReplenishmentLine = false;
  haveChannelReplenishmentLine = false;
  lastSearchParams = {};
  nopOrderParams
  totalReserved
  totalNonReserve
  totalSubmitted

  reserveTargetIds = [];
  reserveTargetIdChannelMapping:{[key:number]:number} = {};

  lisApiLimit = 1
  totalLineLength = 0; // line length for btn validator, only set when search without filter

  haveReservedPreAllocateByNop = false

  private loadingService: LoadingService;

  getDemandLine(){
    // return this.demandLine
    return JSON.parse(sessionStorage.getItem("DEMAND_LINE"))
  }
  setDemandLine(e){
    sessionStorage.setItem('DEMAND_LINE', JSON.stringify(e))
    this.demandLine=e
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    service: StockDemandCycleService,
    msg: MessageService,
    public conf: ConfirmationService,
    private demandStore: DemandStore,
    public datePipe: DatePipe,
    public commonService: CommonService,
    public permissionService: PermissionService,
  ) {
    super(router, route, service, msg, conf, commonService)
    this.loadingService = new LoadingService()
    makeObservable(this, {
      breadcrumb: observable,
      parentData: observable,
      queryData: observable,
      tableData: observable,
      // skuCode: observable,
      cycleNameIpt: observable,
      poolTypeIpt: observable,
      cycleTypeIpt: observable,
      startDateIpt: observable,
      endDateIpt: observable,
      submissionDeadlineIpt: observable,
      channelIpt: observable,
      referenceIpt: observable,
      statusIpt: observable,
      // buCodeIpt: observable,
      remarksIpt: observable,
      onlyIncludeIpt: observable,
      needByDateIpt: observable,
      extendChannelIpt: observable,
      courierIpt: observable,
      schoolNumberIpt: observable,
      contactPersonIpt: observable,
      contactPhoneIpt: observable,
      address1Ipt: observable,
      address2Ipt: observable,
      address3Ipt: observable,
      channelId: computed,
      referenceNumber: computed,
      status: computed,
      // buCode: computed,
      needByDate: computed,
      remarks: computed,
      onlyInclude: computed,
    })

    this.getPermission(route)
    this.initOriginIpt()
    this.initQueryIpt()
    this.initTableFilterPanel()
    // this.initQueryData()
    this.setExtendDataAccordingToType()
    this.loadData()
    // this.loadDataByParams().subscribe(
    //   (demandCycle)=>{
    //     if(demandCycle.action){
    //       this.setDemandData(demandCycle)
    //       this.setExtendDataAccordingToType(demandCycle)
    //       this.loadLineDataByParams().subscribe(
    //         (demandLine)=>{
    //           this.demandLineData = demandLine
    //           this.initQueryData()
    //           this.takeSchoolData()
    //           // this.setInitSearchData()
    //         },
    //         ()=>{this.back();}
    //       )
    //     }else{
    //       this.back();
    //     }
    //   },
    //   ()=>{this.back();}
    // )
  }

  loadData(){
    this.setLoading(true)
    this.loadDataByParams().subscribe(
      (demandCycle)=>{
        if(demandCycle.action){
          this.setDemandData(demandCycle)
          this.setExtendDataAccordingToType(demandCycle)
          this.loadLineDataByParams().subscribe(
            (demandLine)=>{
              this.setLoading(false)
              this.demandLineData = demandLine
              this.initQueryData()
              this.takeSchoolData()
              this.getReservableCountAndUpdateBtnStatus()
              // this.initWithNop()
              // this.getReplenishLineItemList()
              // this.setInitSearchData()
            },
            ()=>{this.back();}
          )
        }else{
          this.back();
        }
      },
      ()=>{this.back();}
    )
  }

  get channelId() {
    return this.channelIpt.value
  }
  get referenceNumber() {
    return this.referenceIpt.value
  }
  get status() {
    return this.statusIpt.value
  }
  /* get buCode() {
    return this.buCodeIpt.value
  } */
  get needByDate() {
    return this.needByDateIpt.value
  }
  get remarks() {
    return this.remarksIpt.value
  }
  get onlyInclude() {
    return this.onlyIncludeIpt.value
  }

  get showU() {
    return this.permission?.includes('Create Replenishment')
  }

  get isNop() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.NOP
  }
  get isBYOD() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.BYOD
  }
  get isRoadshow() {
    return this.demandData && this.demandData.demandCycleTypeId === this.DEMANDTYPEID.MOB_RoadShow
  }

  get havePermissionCreateReplenishment(){
    return this.permissionService.havePermission(FUNCTION_CODE.REPLENISHMENT_CREATE)
  }

  // submitted: 'Y'
  // need submitted but not submit yet: 'N' / null
  // no need to submit: 'A'
  get isLisSubmitted(){return this.demandLineData.isLisSubmitted}
  get isDmSubmitted(){return this.demandLineData.isDmSubmitted}
  get isNopReserved(){return this.haveReservedPreAllocateByNop}

  get replenishStatus(){return this.demandLineData.status}

  get isEditEnable(){
    const isReserved = this.totalReserved > 0
    return true
    // allow edit in any status
    // && (
    //   this.replenishStatus == this.STATUS.DRAFT
    //   || this.replenishStatus == this.STATUS.VERIFIED
    // )
    && (
      (
        this.isNop
        && (
          this.replenishStatus == this.STATUS.DRAFT
          || this.replenishStatus == this.STATUS.VERIFIED
        )
      )
      // && !this.isNopReserved && this.isDmSubmitted != 'Y'
      || !this.isNop
    )
    // && this.isLisSubmitted != 'Y'
    // && this.isDmSubmitted != 'Y'
    // && !isReserved // no return from lis reservation id value
    && this.havePermissionCreateReplenishment
  }
  get isVerifyEnable(){return this.replenishStatus == this.STATUS.DRAFT&&this.haveLine && this.havePermissionCreateReplenishment}
  get isReserveLisEnable(){
    // const isReserved = this.totalReserved > 0
    return this.replenishStatus == this.STATUS.VERIFIED
    && (this.isLisSubmitted == this.ISSUBMITTED.N || this.isLisSubmitted == null)
    // && !isReserved // no reserved status line, need to reserve
    && this.haveLine
    && this.haveChannelReplenishmentLine
    && this.havePermissionCreateReplenishment
  }
  get isSubmitLisEnable(){
    const isReserved = this.totalReserved > 0
    // const isExist = this.demandLineList.some(item=>item.lisReservationStatus==='SUCCESSFUL')
    this.demandLineList.some(item=>item.lisReservationStatus==='SUCCESSFUL')
    return this.replenishStatus == this.STATUS.RESERVED
    &&(this.isLisSubmitted == this.ISSUBMITTED.N || this.isLisSubmitted == null)
    && isReserved // have reserved line, can be submit to lis
    && this.haveLine
    && this.haveChannelReplenishmentLine
    && this.havePermissionCreateReplenishment
  }
  get isSubmitDmEnable(){
    return this.isNop
    && (
      this.replenishStatus == this.STATUS.VERIFIED ||
      this.replenishStatus == this.STATUS.RESERVED ||
      this.replenishStatus == this.STATUS.SUBMITTED
    )
    && this.replenishStatus != this.STATUS.DRAFT
    && this.replenishStatus != this.STATUS.COMPLETED
    // && this.replenishStatus != this.STATUS.VERIFIED
    && (this.isDmSubmitted == this.ISSUBMITTED.N || this.isDmSubmitted == null)
    && this.nopTableData.totalRecords.length > 0
    && this.haveWarehouseReplenishmentLine
    && this.havePermissionCreateReplenishment
  }
  get isReserveForDeliveryEnable(){
    return this.isNop
    && (
      this.replenishStatus == this.STATUS.VERIFIED ||
      this.replenishStatus == this.STATUS.RESERVED ||
      this.replenishStatus == this.STATUS.SUBMITTED
    )
    && this.replenishStatus != this.STATUS.DRAFT
    && this.replenishStatus != this.STATUS.COMPLETED
    // // && this.replenishStatus != this.STATUS.VERIFIED
    && (this.isDmSubmitted == this.ISSUBMITTED.N || this.isDmSubmitted == null)
    && this.nopTableData.totalRecords.length > 0
    && this.haveWarehouseReplenishmentLine
    && this.havePermissionCreateReplenishment
  }

  get haveLine(){
    return this.totalLineLength != null && this.totalLineLength > 0
  }

  get haveNopOrder(){
    return this.nopTableData.totalRecords != null && this.nopTableData.totalRecords > 0
  }

  get statusIsReserved(){
    return this.replenishStatus == this.STATUS.RESERVED
  }

  initTableData(){
    this.tableData = {
      btns: [],
      data: [],
      columns: [
        {field: 'desc', title: this.TABLE_FIELD.DESCRIPTION, width: '130px'},
        {field: 'itemBuCode', title: this.TABLE_FIELD.BU, width: '70px'},
        {field: 'itemLobCode', title: this.TABLE_FIELD.LOB, width: '70px'},
        {field: 'channelNature', title: this.TABLE_FIELD.CHANNEL_NATURE, width: '150px'},
        {field: 'icCode', title: this.TABLE_FIELD.IC_CODE, width: '170px'},
        {field: 'model', title: this.TABLE_FIELD.MODEL, width: '90px'},
        {field: 'uom', title: this.TABLE_FIELD.UOM, width: '80px'},
        {field: 'p30Sales', title: this.TABLE_FIELD.P30, width: '70px'},
        {field: 'p21Sales', title: this.TABLE_FIELD.P21, width: '70px'},
        {field: 'p14Sales', title: this.TABLE_FIELD.P14, width: '70px'},
        {field: 'p07Sales', title: this.TABLE_FIELD.P07, width: '70px'},
        {field: 'qtyDoc', title: this.TABLE_FIELD.DOC, width: '70px'},
        {field: 'qtyAvailable', title: this.TABLE_FIELD.AVAILABLE, width: '120px'},
        {field: 'qtyReserved', title: this.TABLE_FIELD.RESERVED, width: '120px'},
        {field: 'qtyAo', title: this.TABLE_FIELD.AO, width: '70px'},
        {field: 'qtyTransit', title: this.TABLE_FIELD.TRANSIT_IN, width: '120px'},
        {field: 'qtyDo', title: this.TABLE_FIELD.DO, width: '70px'},
        {field: 'qtyTotalAvailable', title: this.TABLE_FIELD.TOTAL_AVAILABLE, width: '150px'},
        {field: 'qtyOnhand', title: this.TABLE_FIELD.TOTAL_ON_HAND, width: '150px'},
        {field: 'qtyRequest', title: this.TABLE_FIELD.CHANNEL_REQUEST, width: '150px'},
        {field: 'qtyAllocate', title: this.TABLE_FIELD.ALLOCATE_QTY, width: '140px'},
        ...(this.demandData&&this.isNop?[{
          field: "nopDeliveryQty",
          title: this.TABLE_FIELD.nopDeliveryQty,
          width: '140px'
        }]:[]),
        {field: 'qtyW003', title: this.TABLE_FIELD.W003, width: '80px'},
        {field: 'qtyW003Demand', title: this.TABLE_FIELD.W003_DEMANDED, width: '160px'},
        {field: 'qtyW005', title: this.TABLE_FIELD.W005, width: '80px'},
        {field: 'qtyW006', title: this.TABLE_FIELD.W006, width: '80px'},
        {field: 'qtyW007', title: this.TABLE_FIELD.W007, width: '80px'},
        {field: 'qtyW008', title: this.TABLE_FIELD.W008, width: '80px'},
        {field: 'qtyW009', title: this.TABLE_FIELD.W009, width: '80px'},
        {field: 'qtyW010', title: this.TABLE_FIELD.W010, width: '80px'},
        {field: 'qtyW011', title: this.TABLE_FIELD.W011, width: '80px'},
        {field: 'qtyW012', title: this.TABLE_FIELD.W012, width: '80px'},
        {field: 'qtyW013', title: this.TABLE_FIELD.W013, width: '80px'},
        {field: 'qtyPsw', title: this.TABLE_FIELD.PSW, width: '80px'},
        {field: 'lisReservationStatus', title: this.TABLE_FIELD.LIS_RESERVATION_STATUS, width: '210px'},
        {field: 'sourceSysReservationId', title: this.TABLE_FIELD.LIS_RESERVATION_ID, width: '160px'},
        {field: 'interfaceStatus', title: this.TABLE_FIELD.INTERFACED_STATUS, width: '160px'},
        {field: 'interfaceDate', title: this.TABLE_FIELD.INTERFACED_DATE, width: '170px', type: 'datetime'},
        {field: 'sourceSysOrderNumber', title: this.TABLE_FIELD.ISO_NUMBER, width: '150px'},
        {field: 'irBatch', title: this.TABLE_FIELD.IR_BATCH, width: '150px'},
        {field: 'updateBy', title: this.TABLE_FIELD.UPDATED_BY, width: '130px', type: 'name'},
        {field: 'updateDate', title: this.TABLE_FIELD.UPDATED_DATETIME, width: '160px', type: 'datetime'},
        {field: 'systeMessage', title: this.TABLE_FIELD.SYSTEM_MESSAGE, width: '150px'},
      ],
      selectedColumns: [],
      visibleColumns: [],
      frozenColumns: [
        {field: '', title: 'Action', width: '130px', type: 'action', disableSort: true},
        {field: 'isReserveTarget', title: '', width: '50px', type: 'checkbox', disabledKey: '_reserveDisabled', disableSort: true},
        {field: 'channel', title: this.TABLE_FIELD.CHANNEL, width: '100px'},
        {field: 'itemNumber', title: this.TABLE_FIELD.ITEM_NUMBER, width: '140px'},
      ],
      frozenWidth: '420px',
      lineCols: [],
      pageIndex: 0,
      pageSize: 20,
      totalRecords: 0,
      sortField: { field: "updateDate", order: -1 },
      showCheckboxSwitch: false,
      loadingSwitch: false,
      selMode: 'single',
      initSortField: 'updateDate',
      initSortOrder: -1,
      selectedProducts: null,
      editShow: true,
    }
    this.tableData.selectedColumns = [...this.tableData.columns]
  }
  initNopData(){
    this.nopTableData = {
      btns: [],
      data: [],
      columns: [
        {field: 'expand', type: 'expand', title: '', width: '3.5rem', sortable: false},
        {field: 'nopOrderNo', title: this.TABLE_FIELD.NOP_ORDER_NO, width: '9rem'},
        // {field: 'itemCode', title: this.TABLE_FIELD.ITEM_NUMBER, width: '9rem'},
        // {field: 'itemDesc', title: this.TABLE_FIELD.DESCRIPTION, width: '150px'},
        // {field: 'deliveryQty', title: this.TABLE_FIELD.NOP_ORDER_DELIVERYQTY, width: '130px'},
        {field: 'status', title: this.TABLE_FIELD.NOP_ORDER_STATUS, width: '6rem'},
        {field: 'errorMessage', title: this.TABLE_FIELD.NOP_ORDER_ERR_MSG, width: '16rem'},
      ],
      selectedColumns: [],
      visibleColumns: [],
      lineCols: [],
      pageIndex: 0,
      pageSize: 20,
      totalRecords: 0,
      sortField: { field: "updateDate", order: -1 },
      showCheckboxSwitch: false,
      loadingSwitch: false,
      selMode: 'single',
      initSortField: 'updateDate',
      initSortOrder: -1,
      selectedProducts: null,
      editShow: true,
    }
    this.nopOrderExpandCol = {
      // btns: [],
      // data: [],
      columns: [
        // {field: 'stockReservationId', title: this.TABLE_FIELD.RESERVATION_ID, width: '0'},
        {field: 'itemCode', title: this.TABLE_FIELD.ITEM_NUMBER, width: '0'},
        {field: 'deliveryQty', title: this.TABLE_FIELD.NOP_ORDER_DELIVERYQTY, width: '0'},
        {field: 'reserveStatus', title: this.TABLE_FIELD.NOP_ORDER_RESERVE_STATUS, width: '0'},
        {field: 'reserveErrorMessage', title: this.TABLE_FIELD.NOP_ORDER_RESERVE_MSG, width: '0'},
      ],
      listKey: 'nopOrderInfoLineList',
      // selectedColumns: [],
      // visibleColumns: [],
      // lineCols: [],
      // pageIndex: 0,
      // pageSize: 20,
      // totalRecords: 0,
      // sortField: { field: "updateDate", order: -1 },
      // showCheckboxSwitch: false,
      // loadingSwitch: false,
      // selMode: 'single',
      // initSortField: 'updateDate',
      // initSortOrder: -1,
      // selectedProducts: null,
      // editShow: true,
    }
    this.nopTableData.selectedColumns = [...this.nopTableData.columns]
  }

  getSchoolByTitle(title) {
    return this.queryData.extended.ipts.find(item => item.title === title)
  }

  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    this.setPermission(this.queryData.btns)
  }

  setPermission(list) {
    list.forEach(item => {
      item.title !== 'Back' && (item.disabled = !this.permission.includes("Create Replenishment"))
    })
  }
  getOriginByTitle(title){
    return this.parentData.ipts.find(item => item.title === title)
  }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  getButtonByTitle(name) {
    return this.queryData.btns.find(btn => btn.title === name)
  }
  getCourierOptions() {
    this.courierIpt  = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_COURIER)
    const couriers = LocalStorageHelper.getObject('REPLENISH_COURIER')
    this.courierIpt.options =  couriers
  }
  getChannelOptions(hasDefaultValue=false) {
    this.extendChannelIpt  = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_CHANNEL)
    const shop =  JSON.parse(localStorage.getItem("REPOMODULEBYUAMTREE")).filter(repo => repo.children.length)
    this.extendChannelIpt .options = shop

    // If hv_school_data="Y", then default display "ELG" and set as "READ-ONLY" (not allow to edit)
    if(hasDefaultValue) {
      let channelList:string[] = CONFIG.REPLENISH_BYOD_CHANNEL_LIST
      // let targetChannelCode = channelList[0] || 'ELG';
      let elxChannelList = shop.map(channel=>channel.children).flat().filter(channel=>channelList.includes(channel.label))
      let elgChannel
      let elgChannelConfigOrder
      elxChannelList.forEach(element => {
        let configIdx = channelList.indexOf(element.label)
        if(elgChannelConfigOrder == null || elgChannelConfigOrder > configIdx) {
          elgChannel = element
          elgChannelConfigOrder = configIdx
        }
      });
      this.extendChannelIpt .showValue = elgChannel.label
      this.extendChannelIpt .value = elgChannel
      this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_1)
      this.address1Ipt .value = elgChannel.adress
    }
  }
  initOriginIpt() {
    this.cycleNameIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_NAME)
    // this.poolTypeIpt = this.getOriginByTitle(this.TITLE.STOCK_POOL_TYPE)
    this.cycleTypeIpt = this.getOriginByTitle(this.TITLE.DEMAND_CYCLE_TYPE)
    this.startDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_START_DATE)
    // this.endDateIpt = this.getOriginByTitle(this.TITLE.DEMAND_END_DATE)
    this.submissionDeadlineIpt = this.getOriginByTitle(this.TITLE.SUBMISSION_DEADLINE)
  }
  initQueryIpt() {
    this.channelIpt = this.getQueryByTitle(this.QUERY_TITLE.CHANNEL)
    this.referenceIpt = this.getQueryByTitle(this.QUERY_TITLE.REFERENCE_NUMBER)
    this.statusIpt = this.getQueryByTitle(this.QUERY_TITLE.STATUS)
    // this.buCodeIpt = this.getQueryByTitle(this.QUERY_TITLE.BU_CODE)
    this.needByDateIpt = this.getQueryByTitle(this.QUERY_TITLE.NEED_BY_DATE)
    this.remarksIpt = this.getQueryByTitle(this.QUERY_TITLE.REMARKS)
    this.updatedByIpt = this.getQueryByTitle(this.QUERY_TITLE.UPDATED_BY)
    this.updateDateIpt = this.getQueryByTitle(this.QUERY_TITLE.UPDATED_DATETIME)
    this.submittedByIpt = this.getQueryByTitle(this.QUERY_TITLE.SUBMITTED_BY)
    this.submittedDateIpt = this.getQueryByTitle(this.QUERY_TITLE.SUBMITTED_DATETIME)


    this.initSchoolIpt()
  }
  setDemandData(e){
    this.demandData = e
    this.initTableData()
    this.initNopData()
  }
  setExtendDataAccordingToType(demandData = JSON.parse(sessionStorage.getItem("DEMAND"))) {
    this.setDemandData(demandData)
    if(this.isRoadshow) {
      this.queryData.extended = {
        show: false,
        title: this.QUERY_TITLE.ROADSHOW_FIELDSET,
        type: INPUT_TYPE.FIELDSET,
        class: "p-col-12 p-md-4 p-lg-9",
        ipts: [
          {
            title: this.QUERY_TITLE.ROADSHOW_CHANNEL,
            type: INPUT_TYPE.TREESELECT,
            class: "p-col-12 p-md-6 p-lg-6",
            disabled: true,
            value: null,
            showValue: null,
            options: [],
            optionLabel: 'label',
            optionValue: 'id'
          }, {
            title: this.QUERY_TITLE.ROADSHOW_COURIER,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12 p-md-6 p-lg-6",
            value: null,
            disabled: true
          }, {
            title: this.QUERY_TITLE.CONTACT_PERSON,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12 p-md-6 p-lg-6",
            value: null,
            disabled: true
          }, {
            title: this.QUERY_TITLE.CONTACT_PHONE,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12 p-md-6 p-lg-6",
            value: null,
            disabled: true
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_1,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12",
            value: null,
            disabled: true,
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_2,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12",
            value: null,
            disabled: true
          }, {
            title: this.QUERY_TITLE.ROADSHOW_ADDRESS_3,
            type: INPUT_TYPE.INPUT,
            class: "p-col-12",
            value: null,
            disabled: true
          }
        ]
      }
      this.courierIpt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_COURIER)
      this.contactPersonIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PERSON)
      this.contactPhoneIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PHONE)
      this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_1)
      this.address1Ipt.disabled = true
      this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_2)
      this.address3Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_ADDRESS_3)
      this.address3Ipt.options = this.address
      this.getChannelOptions()
      this.getCourierOptions()
    }
  }
  initQueryData(){
    this.queryData.extended.show = this.demandData.hasSchoolData || this.demandData.hasCourierData

    const list = LocalStorageHelper.getObject('POOL_TYPE')
    // const poolType = list.find(item =>  item.id === this.demandData.sdl_stock_pool_type_id);

    this.cycleNameIpt.value = this.demandData.demandCycleName
    // this.poolTypeIpt.value = poolType.name
    this.cycleTypeIpt.value = this.demandData.demandCycleType
    this.startDateIpt.value = new Date(this.demandData.demandStartDate)
    // this.endDateIpt.value =  new Date(this.demandData.demandEndDate)
    this.submissionDeadlineIpt.value = this.demandData.submissionDeadline ? new Date(this.demandData.submissionDeadline) : null
    this.setInitSearchData()
  }
  getLines() {
    const data = {
      accountName: this.account,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId,
      itemIds: this.tableFilterItemList,
      replenishItemStatus: this.tableFilterReservationStatus,
    }
    this.setLoading(true)
    this.service.replenishmentLineSearch(data).subscribe(res => {
      this.setLoading(false)
      if(res.code === '000') {
        this.tableData.totalRecords = res.msg
        this.lastSearchParams = data;
        if((data.itemIds==null||data.itemIds.length==0)&&!data.replenishItemStatus){this.totalLineLength = res.msg}
        // tslint:disable-next-line: no-unused-expression
        this.showDataToTable(res.data)
      }
      this.setButtonStatus()
    })
  }

  getReservableCountAndUpdateBtnStatus() {
    const reservedData = {
      accountName: this.account,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId,
      replenishItemStatus: 'Reserved',
      isOnlyCount: 'Y',
    }
    const nonreserveData = {
      accountName: this.account,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId,
      replenishItemStatus: 'Non-Reserve',
      isOnlyCount: 'Y',
    }
    this.setLoading(true)
    forkJoin([
      this.service.replenishmentLineSearch(reservedData),
      this.service.replenishmentLineSearch(nonreserveData),
      this.getSubmittedQty()
    ])
    .subscribe(([reservedRes, nonReserveRes, submittedRes]) => {
      this.setLoading(false);
      if(reservedRes.code === '000') this.totalReserved = reservedRes.msg;
      if(nonReserveRes.code === '000') this.totalNonReserve = nonReserveRes.msg;
      if(submittedRes.code === '000') this.totalSubmitted = submittedRes.msg;
      this.setButtonStatus()
    }, err=>{
      this.setLoading(false);
    })
  }

  getSubmittedQty(){
    const submittedData = {
      accountName: this.account,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId,
      replenishItemStatus: 'Submitted',
      isOnlyCount: 'Y',
    }
    return this.service.replenishmentLineSearch(submittedData)
  }

  getReplenishLineItemList(){
    // get item list from replenish line for item filter
    this.service.getReplenishmentLineItemList(this.demandLineData.id).subscribe(res=>{
    })
  }

  getLinesForExportXlsx() {
    const data = {
      // ...this.lastSearchParams,
      sortEvent: this.tableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId,
      accountName: this.account,
      pageIndex: 0,
      pageSize: 2147483647,
    }
    this.setLoading(true)
    return this.service.replenishmentLineSearch(data)
  }
  getNopOrders() {
    const data = {
      accountName: this.account,
      pageIndex: this.nopTableData.pageIndex,
      pageSize: this.nopTableData.pageSize,
      sortEvent: this.nopTableData.sortField,
      replenishId: this.demandLineData.id,
      demandCycleId: this.demandLineData.demandCycleId
    }
    this.setLoading(true)
    this.service.replenishmentNopOrderSearch(data)
    .subscribe(res => {
      this.setLoading(false)
      if(res.code === '000') {
        this.nopOrderParams = data;
        this.nopTableData.totalRecords = res.msg
        // tslint:disable-next-line: no-unused-expression
        // res.data.length && this.showDataToTable(res.data)

        this.nopOrderList = res.data
        // this.demandLineList = [...this.demandLineList, ...lineData]
        this.nopTableData.data = this.nopOrderList
        this.setButtonStatus()
      }
    })
  }
  loadLineChannelData(){
    this.setLoading(true)
    this.service.getAllChannelIdsByReplenishId(this.demandLineData.id).subscribe(res=>{
      this.setLoading(false)
      let _haveWarehouse = false;
      let _haveChannel = false;
      let repo = LocalStorageHelper.getObject("REPO")
      let data: number[] = res.data
      repo.some(channel=>{
        if(data.includes(channel.id)){
          if(channel.other!='Warehouse') _haveChannel = true
          if(channel.other=='Warehouse') _haveWarehouse = true
        }
        return _haveWarehouse && _haveChannel
      })
      this.haveWarehouseReplenishmentLine = _haveWarehouse
      this.haveChannelReplenishmentLine = _haveChannel
      this.setButtonStatus()
    })
  }
  setInitSearchData() {
    this.getLines()
    this.loadLineChannelData()
    this.channels = LocalStorageHelper.getObject('WAREHOUSE')
    this.channelIpt.options = this.channels
    if ( this.demandLineData.channelName) {
      const selectChannel = this.channels.find(item => item.label === this.demandLineData.channelName)
      this.channelIpt.value = selectChannel.id
    } else {
      this.channelIpt.value = this.demandLineData.fromChannelId
    }
    this.replenishChannel = this.channels.find(warehouse=>warehouse.id==this.channelIpt.value)?.label

    if(this.isNop) this.getNopOrders()

    this.referenceIpt.value = this.demandLineData.referenceNumber
    this.statusIpt.value = this.demandLineData.status;
    // this.buCodeIpt.value = this.demandLineData.buCode
    this.needByDateIpt.value = new Date(this.demandLineData.needByDate)
    this.updatedByIpt.value = this.demandLineData.updateAccountName
    this.updateDateIpt.value = new Date(this.demandLineData.updateAt)
    this.submittedByIpt.value = this.demandLineData.submitAccountName
    this.submittedDateIpt.value = this.demandLineData.submitAt ? new Date(this.demandLineData.submitAt) : ""
    this.remarksIpt.value = this.demandLineData.remarks
    this.setButtonStatus()
  }
  setButtonStatus() {
    const verifyBtn = this.getButtonByTitle(this.BUTTON_TITLE.VERIFY)
    const reserveBtn = this.getButtonByTitle(this.BUTTON_TITLE.RESERVE_LIS)
    const submitBtn = this.getButtonByTitle(this.BUTTON_TITLE.SUBMIT_TO_LIS)
    const editBtn = this.getButtonByTitle(this.BUTTON_TITLE.EDIT)
    const submitDMBtn = this.getButtonByTitle(this.BUTTON_TITLE.SUBMIT_TO_DM)
    const reserveLisNopBtn = this.getButtonByTitle(this.BUTTON_TITLE.RESERVE_LIS_NOP)
    const rereserveBtn = this.getButtonByTitle(this.BUTTON_TITLE.RE_RESERVE_LIS)
    const resubmitBtn = this.getButtonByTitle(this.BUTTON_TITLE.RE_SUBMIT_TO_LIS)
    const refreshStatusBtn = this.getButtonByTitle(this.BUTTON_TITLE.REFRESH_STATUS)
    const exportBtn = this.getButtonByTitle(this.BUTTON_TITLE.EXPORT)
    const refreshWarehouseQtyBtn = this.getButtonByTitle(this.BUTTON_TITLE.REFRESH_WAREHOUSE_QTY)
    const updateWarehouseQtyBtn = this.getButtonByTitle(this.BUTTON_TITLE.UPDATE_WAREHOUSE_QTY)

    verifyBtn.disabled = !this.isVerifyEnable // (this.status.toLowerCase() === 'draft' && this.haveLine) ? false : true
    const state = this.status.toLowerCase()
    // const reserve = this.demandLineData.reservedFromWarehouse
    const hasRepenishId = this.demandLineList.some(line => line.sourceSysReservationId)
    const isExist = this.demandLineList.some(item=>item.lisReservationStatus==='SUCCESSFUL')

    reserveBtn.disabled = !this.isReserveLisEnable
    // state === 'verified' || state === 'reserved' && !isExist ? false : true
    submitBtn.disabled = !this.isSubmitLisEnable // state === 'reserved' && isExist ? false : true
    editBtn.disabled = !this.isEditEnable
    // (state === 'submitted' || state === 'interfaced' || hasRepenishId) ? true : false
    submitDMBtn.disabled = !this.isSubmitDmEnable
    reserveLisNopBtn.disabled = !this.isReserveForDeliveryEnable

    submitDMBtn.show = this.isNop
    reserveLisNopBtn.show = this.isNop

    rereserveBtn.show = true // !this.isNop
    resubmitBtn.show = true // !this.isNop

    // totalNonReserve only using reservation id is null to check in backend, may have problem when is nop
    rereserveBtn.disabled = ![this.STATUS.RESERVED, this.STATUS.SUBMITTED, this.STATUS.COMPLETED].includes(this.replenishStatus) || this.totalNonReserve == 0
    resubmitBtn.disabled = ![this.STATUS.SUBMITTED, this.STATUS.COMPLETED].includes(this.replenishStatus) || this.totalReserved == 0
    refreshStatusBtn.show = [this.STATUS.RESERVED].includes(this.replenishStatus) && this.totalSubmitted > 0
    refreshStatusBtn.disabled = !([this.STATUS.RESERVED].includes(this.replenishStatus) && this.totalSubmitted > 0)

    //When Replenishment status = RESERVING, disable all buttons except back
    if([this.STATUS.RESERVING].includes(this.replenishStatus)){
      verifyBtn.disabled = true
      reserveBtn.disabled = true
      submitBtn.disabled = true
      rereserveBtn.disabled = true
      reserveLisNopBtn.disabled = true
      editBtn.disabled = true
    }

//When Replenishment status = SUBMITTING, disable all buttons except back
    if([this.STATUS.SUBMITTING].includes(this.replenishStatus)){
      verifyBtn.disabled = true
      reserveBtn.disabled = true
      submitBtn.disabled = true
      rereserveBtn.disabled = true
      reserveLisNopBtn.disabled = true
      editBtn.disabled = true
    }

  }
  edit() {
    // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(data))
    this.router.navigate(["main", 'demand_cycle', 'replenishment', 'edit'], {queryParams:{id:this.demandData.demandCycleId,replenishId:this.demandLineData.id}})
  }
  // edit() {
  //   // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(data))
  //   this.router.navigate(["main", 'demand_cycle', 'replenishment', 'editspreadsheet'], {queryParams:{id:this.demandData.demandCycleId,replenishId:this.demandLineData.id}})
  // }

  showDataToTable(lineData) {
    this.demandLineList = lineData
    let warehouseList = this.channels.map(channel=>channel.label)
    // this.haveWarehouseReplenishmentLine = this.demandLineList.some(line=>{
    //   // not null and found in warehouse desc list
    //   return line.channel && warehouseList.indexOf(line.channel) > -1
    // })
    // this.haveChannelReplenishmentLine = this.demandLineList.some(line=>{
    //   // not null and not warehouse
    //   return line.channel && warehouseList.indexOf(line.channel) == -1
    // })
    this.tableData.data = this.preProcessReplenishmentLineData(this.demandLineList)
    this.setButtonStatus()
  }

  preProcessReplenishmentLineData(data){
    let warehouseCodeList = this.channels.map(warehouse=>warehouse.label)
    let _data = data.map(element => {
      return {
        ...element,
        _showCancelLisReserveBtn: !(warehouseCodeList.indexOf(element.channel)>-1),
        p30Sales: element.p30Sales==null ? '-' : element.p30Sales,
        p21Sales: element.p21Sales==null ? '-' : element.p21Sales,
        p14Sales: element.p14Sales==null ? '-' : element.p14Sales,
        p07Sales: element.p07Sales==null ? '-' : element.p07Sales,
        isReserveTarget: this.reserveTargetIds.includes(element.id),
        _reserveDisabled: element.sourceSysReservationId || warehouseCodeList.includes(element.channel),
        //DOC:如果P07 = 0 ,DOC 显示 "#N/A"
        qtyDoc: (element.p07Sales == 0 || element.p07Sales == null) && element.qtyDoc  == 0 ? '#N/A' : element.qtyDoc,
      }
    });
    return _data
  }

  confirmCancelLisReservation(data) {
    this.setLoading(true)
    this.service.replenishmentLineCancelReserveLIS(data).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        // this.demandLineData.status = 'DRAFT'
        // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
        // this.setDemandLine(this.demandLineData)
        // this.initQueryData()
        this.forceReloadReplenishment()
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }
  cancelLisReservation(data) {
    const params = {
      replenishId: this.demandLineData.id,
      replenishLineId: data.id
    }
    this.restoreData('confirmCancelLisReservation', this, params, this.info.cancelLis)
  }

  /* confirmDelete(data) {
    this.demandLineList = this.demandLineList.filter(item => item.id !== data.id)
    this.tableData.data = this.demandLineList
  } */
  /* clear() {
    this.queryData.ipts.forEach(item => {
      item.value = null
    })
  } */

  verify() {
    if(!(this.address3Ipt.value!=null||!this.isBYOD)){
      this.showMessage('error', this.info.title, 'Verify fail Address line 3 is required.')
      return
    }
    if(this.loading) return
    this.setLoading(true)
    this.service.replenishmentVerify({id: this.demandLineData.id}).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        this.statusIpt.value = 'Verified'
        this.demandLineData.status = 'VERIFIED'
        // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
        // wip reload replenish data
        this.setDemandLine(this.demandLineData)
        this.setButtonStatus()

        this.forceReloadReplenishment()

        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Verified'))
      } else {
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Verified'))
      }
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }
  forceReloadReplenishment(){
    // to load isXXXSubmitted value, status, handle button disabled
    this.setLoading(true)
    this.forceReloadDemandLineData().subscribe(
      (demandLine)=>{
        this.setLoading(false)
        this.demandLineData = demandLine
        this.initQueryData()
        this.takeSchoolData()
        // this.setInitSearchData()
        this.getReservableCountAndUpdateBtnStatus()
      },err=>{
        this.setLoading(false)
      }
    )
  }
  reserveLis() {
    this.setLoading(true)
    this.service.replenishmentReserve({id: this.demandLineData.id}).subscribe(res => {
      this.setLoading(false)
      this.statusIpt.value = 'Reserved'
      this.demandLineData.status = 'RESERVED'
      if (res.code === '000') {
        this.demandLineData.reservedFromWarehouse = 'Success'
        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Reserve LIS'))
      } else {
        this.demandLineData.reservedFromWarehouse = 'Failure'
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Reserve LIS'))
      }

      this.forceReloadReplenishment()
      this.getReservableCountAndUpdateBtnStatus()
      // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
      this.setDemandLine(this.demandLineData)
      // this.setButtonStatus()
      this.setInitSearchData()
    })
  }
  reserveLisForNop() {
    if(this.loading) return
    this.setLoading(true)
    this.service.replenishmentReserveForNop({id: this.demandLineData.id}).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Reserve LIS for NOP'))
      } else {
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Reserve LIS for NOP'))
      }

      this.forceReloadReplenishment()

      // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
      this.setDemandLine(this.demandLineData)
      // this.setButtonStatus()
      this.setInitSearchData()
    },err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }
  submitToLis() {
    this.setLoading(true)
    this.service.replenishmentSubmit({id: this.demandLineData.id}).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        // this.statusIpt.value = 'Submitted'
        // this.demandLineData.status = 'Submitted'
        // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
        // this.setDemandLine(this.demandLineData)
        // this.setButtonStatus()

        this.forceReloadReplenishment()

        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Submit To LIS'))
      } else {
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Submit To LIS'))
      }
      this.setInitSearchData()
    })
  }
  submitToDM() {
    if(this.loading) return
    this.setLoading(true)
    this.service.replenishmentSubmitToDm({id: this.demandLineData.id}).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        // this.statusIpt.value = 'Submitted'
        // this.demandLineData.status = 'Submitted'
        // sessionStorage.setItem('DEMAND_LINE', JSON.stringify(this.demandLineData))
        // this.setDemandLine(this.demandLineData)
        // this.setButtonStatus()

        this.forceReloadReplenishment()
        this.getNopOrders()

        this.showMessage('success', this.info.title, this.info.success.replace('Saved', 'Submit To LIS'))
      } else {
        this.showMessage('error', this.info.title, res.msg || this.info.fail.replace('Save', 'Submit To LIS'))
      }
      this.setInitSearchData()
    },err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  stopEvent(event) {
    event.stopPropagation()
  }

  sortFunction(e){
    // primeng sort emit
    if(
      this.tableData.sortField.field == e.field &&
      this.tableData.sortField.order == e.order
    ) {
      return
    }else{
      this.tableData.sortField.field = e.field
      this.tableData.sortField.order = e.order
      this.getLines()
    }
  }

  sortSearch(field) {
    this.tableData.sortField.field = field
    this.tableData.sortField.order = -this.tableData.sortField.order
    this.getLines()
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.getLines()
  }

  nopSortSearch(field) {
    this.nopTableData.sortField.field = field
    this.nopTableData.sortField.order = -this.tableData.sortField.order
    this.getNopOrders()
  }
  nopPage(e) {
    this.nopTableData.pageIndex = e.first / e.rows
    this.nopTableData.pageSize = e.rows
    this.getNopOrders()
  }
  back() {
    if(this.demandData){
      this.router.navigate(['main', 'demand_cycle', 'replenishment', 'search'],{queryParams:{id:this.demandData.demandCycleId}})
    }else{
      this.router.navigate(['main', 'demand_cycle', 'search'])
    }
  }
  initSchoolIpt() {
    // this.schoolCodeIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_CODE)
    // this.schoolLocationCodeIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_LOCATION_CODE)
    this.extendChannelIpt = this.getSchoolByTitle(this.QUERY_TITLE.ROADSHOW_CHANNEL)
    this.schoolNumberIpt = this.getSchoolByTitle(this.QUERY_TITLE.SCHOOL_NUMBER)
    this.contactPersonIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PERSON)
    this.contactPhoneIpt = this.getSchoolByTitle(this.QUERY_TITLE.CONTACT_PHONE)
    this.address1Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_1)
    this.address2Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_2)
    this.address3Ipt = this.getSchoolByTitle(this.QUERY_TITLE.ADDRESS_3)
    // this.takeSchoolData()
  }

  takeSchoolData() {
    let demandLineData = this.demandLineData
    const data = {
      accountName: this.account,
      demandCycleId: demandLineData.demandCycleId,
      replenishId: demandLineData.id,
    }
    this.setLoading(true)
    this.service.replenishmentLineSchoolSearch(data).subscribe(res => {
      this.setLoading(false)
      if (res.code === '000') {
        // res.data
        if(res.data.length) {
          // this.schoolCodeIpt.value = res.data[0].schCode
          // this.schoolLocationCodeIpt.value = res.data[0].schLocCode
          if(res.data[0].channelId) {
            try{
              const shops =  LocalStorageHelper.getObject('REPOTREE').filter(repo => repo.children.length)
              let channel = shops.map(repo=>repo.children).flat().find(channel=>channel.id===res.data[0].channelId)
              this.extendChannelIpt.value = channel
              this.extendChannelIpt.showValue = channel.label
            }catch(e){console.error(e)}
          }
          if(this.courierIpt) {
            this.courierIpt.value = res.data[0].overrideCourier
          }
          if(this.schoolNumberIpt)this.schoolNumberIpt.value = res.data[0].schNum;
          this.contactPersonIpt.value = res.data[0].contactPersonName
          this.contactPhoneIpt.value = res.data[0].contactPhoneNo ||  res.data[0].contactPersonNo
          this.address1Ipt.value = res.data[0].address1
          this.address2Ipt.value = res.data[0].address2
          this.address3Ipt.value = res.data[0].address3
        }
      } else {
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    })
  }

  loadDataByParams(){
    return this.demandStore.loadDataByParams(this.route)
  }
  loadLineDataByParams(){
    return this.demandStore.loadDemandLineByParams(this.route, this.demandData)
  }
  tableSelectedColumnsChange(e){
    this.tableData.selectedColumns = this.tableData.columns.filter(col => e.includes(col));
  }

  setLoading(e){
    this.loading = e
    this.tableData.loadingSwitch = e
    this.nopTableData.loadingSwitch = e
    // this.loadingService.setLoadingBoolean(e)
  }
  forceReloadDemandLineData(){
    return this.demandStore.loadDemandLineByParams(this.route, this.demandData, true)
  }

  exportPreAllocatedReservation(){
    this.service.getPreAllocatedStockReservation(this.demandLineData.id).subscribe(res=>{
      if(res.code=='000'){
        if(!res.data||res.data.length==0){
          this.showMessage('warn', this.info.title, `No record found.`)
        }

        let col = [
          {field: 'reservationDate', title: 'Reservation Date'},
          {field: 'sourceTxnRefHeaderNo', title: 'Source Ref. No'},
          {field: 'itemCode', title: 'Item Code'},
          {field: 'itemDesc', title: 'Description'},
          {field: 'reservationType', title: 'Reservation Type'},
          {field: 'channelCode', title: 'Release Channel'},
          {field: 'qtyRequested', title: 'Request Reserve Qty'},
          {field: 'qtyPending', title: 'Pending Qty'},
          {field: 'qtyPlanToAllocate', title: 'Plan Allocate Qty'},
          {field: 'qtyCancelled', title: 'Cancel Qty'},
          {field: 'sensitiveRemarks', title: 'Remarks'},
          {field: 'id', title: 'Reservation ID'},
          {field: 'sourceSystem', title: 'Source System'},
          {field: 'sourceTxnRefHeaderId', title: 'Source Ref. Id'},
          {field: 'createDate', title: 'Create Date'},
          {field: 'updateDate', title: 'Update Date'},
        ]

        CommonMethod.downloadXlsx(res.data, col, `RPL_PREALLOCATED_${this.demandData.demandCycleName}_${this.demandLineData.referenceNumber}`, this.datePipe)
      }else{
        this.showMessage('error', this.info.title, res.msg || res.message)
      }
    },err=>{
      this.showMessage('error', this.info.title, err)
    })
  }

  getPreAllocateReservationForExportXlsx(){
    return this.service.getPreAllocatedStockReservation(this.demandLineData.id).pipe(map(res=>{
      if(res.code=='000' && res.data == null) res.data = [];
      return res
    }))
  }
  getPreAllocateReservationForExportXlsxForNop(){
    return this.service.getPreAllocatedStockReservationBySourceTxnRefHeaderNo(this.demandLineData.id).pipe(map(res=>{
      if(res.code=='000' && res.data == null) res.data = [];
      return res
    }))
  }

  getPreAllocateReservation(){
    return this.service.getPreAllocatedStockReservationBySourceTxnRefHeaderNo(this.demandLineData.id).pipe(map(res=>{
      if(res.code=='000' && res.data == null) res.data = [];
      return res
    }))
  }

  initWithNop(){
    if(!this.isNop) return
    this.getPreAllocateReservation()
    .subscribe((reservationRes)=>{
      if(reservationRes.code=='000'&&reservationRes.data.length>0){
        let haveReservedPreAllocate = reservationRes.data.some(data=>{
          return data.lisReservationId != null
        })
        this.haveReservedPreAllocateByNop = haveReservedPreAllocate
      }
    })
  }

  getNopOrderForExportXlsx(){
    const data = {
      ...this.nopOrderParams,
      pageIndex: 0,
      pageSize: 2147483647,
    }
    return this.service.replenishmentNopOrderSearch(data)
    .pipe(
      map(res=>{
        if(res.code!='000') throw res.message || res.msg;
        return res
      }),
      map(res=>{
        if(res.code=='000' && res.data == null) res.data = []
        return res
      }),
      map(res=>{
        return res.data
      }),
      map(data=>{
        let _res = []
        data.forEach(order => {
          order.nopOrderInfoLineList.forEach(orderInfoLine => {
            let line = {
              ...order,
              ...orderInfoLine,
            }
            delete line['nopOrderInfoLineList']
            _res.push(line)
          });
        });
        return _res
      })
    )
  }

  exportReplenishmentAndPreAllocateReservation(){
    this.setLoading(true)
    forkJoin([
      this.getLinesForExportXlsx(),
      (this.isNop?this.getPreAllocateReservationForExportXlsxForNop():this.getPreAllocateReservationForExportXlsx()),
      (this.isNop?this.getNopOrderForExportXlsx():of(null)),
    ])
    .subscribe(([replenishmentRes, reservationRes, nopData])=>{
      this.setLoading(false)
      if(reservationRes.code=='000' && replenishmentRes.code == '000'){
        // if(!reservationRes.data||reservationRes.data.length==0){
        //   this.showMessage('warn', this.info.title, `No record found.`)
        // }

        let reservationCol = [
          {field: 'reservationDate', title: 'Reservation Date'},
          {field: 'sourceTxnRefHeaderNo', title: 'Source Ref. No'},
          {field: 'itemCode', title: 'Item Code'},
          {field: 'itemDesc', title: 'Description'},
          {field: 'reservationType', title: 'Reservation Type'},
          {field: 'channelCode', title: 'Release Channel'},
          {field: 'qtyRequested', title: 'Request Reserve Qty'},
          {field: 'qtyPending', title: 'Pending Qty'},
          {field: 'qtyPlanToAllocate', title: 'Plan Allocate Qty'},
          {field: 'qtyCancelled', title: 'Cancel Qty'},
          {field: 'sensitiveRemarks', title: 'Remarks'},
          {field: 'id', title: 'Reservation ID'},
          {field: 'sourceSystem', title: 'Source System'},
          {field: 'sourceTxnRefHeaderId', title: 'Source Ref. Id'},
          {field: 'createDate', title: 'Create Date'},
          {field: 'updateDate', title: 'Update Date'},
        ]

        let replenishCol = [
          ...this.tableData.frozenColumns,
          ...this.tableData.columns,
        ].filter((col) => !["action", 'checkbox'].includes(col.type));

        let datas = [replenishmentRes.data, reservationRes.data];
        let cols = [replenishCol, reservationCol];
        let tabs = ['Replenishemnt', 'Reservation'];

        if(this.isNop){
          let nopCol = [
            ...this.nopTableData.columns,
            ...this.nopOrderExpandCol.columns,
          ].filter(col=>col.type!='expand')
          datas = [
            ...datas,
            nopData
          ];
          cols = [
            ...cols,
            nopCol
          ]
          tabs = [
            ...tabs,
            'Nop Order'
          ]
        }

        CommonMethod.downloadXlsxWithMultiSheetWithOptions(
          datas,
          cols,
          tabs,
          `Replenishment_${this.demandLineData.referenceNumber}`,
          this.datePipe
        )
      }else{
        this.showMessage('error', this.info.title, reservationRes.msg || reservationRes.message)
      }
    },err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  rereserveLis(){
    if(this.reserveTargetIds.length>0){
      this.reserveLisById()
    }else{
      this.reserveAllLineByChannel(true)
    }
  }

  reserveAllLineByChannel(skipUpdateStatus = false){
    if(this.loading) return
    this.setLoading(true)
    of(null).pipe(
      // update status to reserving
      switchMap(res=>{
          return this.service.replenishmentReserveLiS({id: this.demandLineData.id})
      }),
      //Waiting for status update to Reserve
      map(res=>{
        if(res.code!=='000') throw res.msg;
        this.showMessage('info', "", "Waiting for Reserve LIS");
        this.waitingReserveLis()
        return
      }),

//      // update status to reserving
//      switchMap(res=>{
//        if(skipUpdateStatus) return of(null)
//        return this.service.replenishmentUpdateToReserving({id: this.demandLineData.id})
//      }),
//
//      // get channel id from replenish line
//      switchMap(res=>{
//        return this.service.getAllChannelIdsByReplenishId(this.demandLineData.id)
//      }),
//      // handle get channel id api response
//      map(res=>{
//        if(res.code!=='000') throw 'An Unexpected Error Occurred';
//        if(res.data.length==0) throw 'Non-Reserve line not found';
//        return res.data
//      }),
//      map(res=>{
//        // filter out warehouse channel id
//        let repo = LocalStorageHelper.getObject("REPO")
//        let keyObjRepo = CommonMethod.jsonObjArrayToKeyObject(repo, 'id')
//        return res.filter(e=>keyObjRepo[e].other!=CONFIG.CHANNEL_WAREHOUSE_TYPE_CODE)
//      }),
//      // get non-reserve line by replenish id and channel
//      switchMap(res=>{
//        let obsList = []
//        res.forEach(data => {
//          let obs = this.service.getUnReserveReplenishLineByReplenishIdAndChannel(this.demandLineData.id, data)
//            .pipe(map(res=>{
//              if(res.code=='000'){
//                return {idList: res.data, channelId: data}
//              }
//              return null
//            }))
//          obsList.push(obs)
//        });
//        return forkJoin(obsList)
//      }),
//      // call reserve api synchronously (one by one) per channel
//      switchMap((res:any[])=>{
//        let _res = res.filter(data=>data&&data.idList.length>0)
//        let replenishId = this.demandLineData.id
//        // non-reserve line not found and have reserved line, skip to update to reserved
//        if(_res.length==0 && this.totalReserved>0) return of(null)
//        // non-reserve line not found and no reserved line, throw error
//        if(_res.length==0) throw 'Non-Reserve line not found'
//        let obsList = _res.map(data=>{
//          return this.service.replenishmentReReserveByLineId({
//            replenishId: replenishId,
//            replenishLineIds: data.idList,
//            // channelId: data.channelId,
//          })
//        })
//        return CommonMethod.forkjoinSync(obsList)
//      }),
//      // allow update replenishment status to reserved when any reserve api success
//      tap(res=>{
//        // non-reserve line not found and have reserved line, skip to update to reserved
//        if(res==null) return
//        let failedRes = res.find(_res=>{
//          return _res.code!='000'
//        })
//        let haveSuccess = res.some(_res=>{
//          return _res.code=='000'
//        })
//        if(!haveSuccess && failedRes) throw failedRes.msg||'An Unexpected Error Occurred';
//      }),
//      // update status to reserved
//      switchMap(res=>{
//        if(skipUpdateStatus) return of(null)
//        return this.service.replenishmentUpdateToReserved({id: this.demandLineData.id})
//      }),
    )
    .subscribe(res=>{
      //this.setLoading(false)
      // if(res.code=='000'){
      //this.resetReserveTarget()
      //this.forceReloadReplenishment()
      this.setLoading(true)
      // }else{
      //   throw 'An Unexpected Error Occurred'
      // }
    }, err=>{
      this.setLoading(false)
      this.resetReserveTarget()
      this.forceReloadReplenishment()
      this.showMessage('error', this.info.title, err)
      this.setButtonStatus()
    })
  }


  submitAllLineByChannel(skipUpdateStatus = false){
    if(this.loading) return
    this.setLoading(true)
    of(null).pipe(
      // update status to Submitting
      switchMap(res=>{
        return this.service.replenishmentSubmitToLis({id: this.demandLineData.id})
      }),
      //Waiting for status update to Completed
      map(res=>{
        if(res.code!=='000') throw res.msg;
        this.showMessage('info', "", "Waiting for Submit to LIS");
        this.waitingSubmitToLis()
        return
      }),


//      // get channel id from replenish line
//      switchMap(res=>{
//        return this.service.getAllChannelIdsByReplenishId(this.demandLineData.id)
//      }),
//      // handle get channel id api response
//      map(res=>{
//        if(res.code!=='000') throw 'An Unexpected Error Occurred';
//        if(res.data.length==0) throw 'Non-Reserve line not found';
//        return res.data
//      }),
//      map(res=>{
//        // filter out warehouse channel id
//        let repo = LocalStorageHelper.getObject("REPO")
//        let keyObjRepo = CommonMethod.jsonObjArrayToKeyObject(repo, 'id')
//        return res.filter(e=>keyObjRepo[e].other!=CONFIG.CHANNEL_WAREHOUSE_TYPE_CODE)
//      }),
//      // get non-reserve line by replenish id and channel
//      switchMap(res=>{
//        let obsList = []
//        res.forEach(data => {
//          let obs = this.service.getReservedNonSubmitReplenishLineByReplenishIdAndChannel(this.demandLineData.id, data)
//            .pipe(map(res=>{
//              if(res.code=='000'){
//                return {idList: res.data, channelId: data}
//              }
//              return null
//            }))
//          obsList.push(obs)
//        });
//        return forkJoin(obsList)
//      }),
//      // call reserve api synchronously (one by one) per channel
//      switchMap((res:any[])=>{
//        let _res = res.filter(data=>data&&data.idList.length>0)
//        let replenishId = this.demandLineData.id
//        // non-reserve line not found and have reserved line, skip to update to reserved
//        if(_res.length==0 && this.totalReserved>0) return of(null)
//        // non-reserve line not found and no reserved line, throw error
//        if(_res.length==0) throw 'Not yet submitted line not found'
//        let obsList = _res.map(data=>{
//          return this.service.replenishmentSubmitByLineId({
//            replenishId: replenishId,
//            replenishLineIds: data.idList,
//            // channelId: data.channelId,
//          })
//          .pipe(catchError(err=>of(null))) // catch error
//        })
//        return CommonMethod.forkjoinSync(obsList)
//      }),
//      // allow update replenishment status to reserved when any reserve api success
//      // tap(res=>{
//      //   // non-reserve line not found and have reserved line, skip to update to reserved
//      //   if(res==null) return
//      //   let failedRes = res.find(_res=>{
//      //     return _res == null || _res.code!='000'
//      //   })
//      //   let haveSuccess = res.some(_res=>{
//      //     return _res.code=='000'
//      //   })
//      //   if(!haveSuccess && failedRes) throw failedRes.msg||'An Unexpected Error Occurred';
//      // }),
//      switchMap(res=>{return this.getSubmittedQty()}),
//      map(res=>{
//        if(res.code!=='000') return null
//        return res.msg
//      }),
//      // update status to reserved
//      switchMap(submittedLineQty=>{
//        if(submittedLineQty!==null && submittedLineQty > 0 && this.statusIsReserved) {
//          return this.service.replenishmentUpdateToSubmitted({id: this.demandLineData.id})
//        }        // if(skipUpdateStatus) return of(null)
//        // return this.service.replenishmentUpdateToSubmitted({id: this.demandLineData.id})
//        return of(null)
//      }),
    )
    .subscribe(res=>{
      // if(res.code=='000'){
      //this.resetReserveTarget()
      //this.forceReloadReplenishment()
      this.setLoading(true)
      // }else{
      //   throw 'An Unexpected Error Occurred'
      // }
    }, err=>{
      this.setLoading(false)
      this.resetReserveTarget()
      this.forceReloadReplenishment()
      this.showMessage('error', this.info.title, err)
    })
  }

  submitLineOneByOneAndUpdateStatus(){
    this.setLoading(true)
    this.service.getReservedNonSubmitReplenishLineByReplenishId(this.demandLineData.id).pipe(
      map(res=>{
        if(res.code!=='000') throw 'An Unexpected Error Occurred';
        if(res.data.length==0) throw 'Non-Submit line not found';
        return res.data
      }),
      switchMap(res=>{
        let idList = res
        let replenishId = this.demandLineData.id
        let idsList = []
        let limit = this.lisApiLimit
        idList.forEach((id, idx) => {
          let idsListPos = Math.floor(idx/limit)
          if(!idsList[idsListPos])idsList[idsListPos]=[]
          idsList[idsListPos].push(id)
        });
        let obsList = idsList.map(ids=>{
          return this.service.replenishmentSubmitByLineId({
            replenishId: replenishId,
            replenishLineIds: ids,
          }).pipe(
            catchError(err=>null)
          )
        })
        return CommonMethod.forkjoinSync(obsList)
      }),
      switchMap(res=>{
        return this.service.replenishmentUpdateToSubmitted({id: this.demandLineData.id})
      })
    )
    .subscribe(res=>{
      this.setLoading(false)
      // if(res.code=='000'){
      this.forceReloadReplenishment()
      // }else{
      //   throw 'An Unexpected Error Occurred'
      // }
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  reserveLisAllById(){
    this.setLoading(true)
    this.service.getUnReserveReplenishLineByReplenishId(this.demandLineData.id).pipe(
      switchMap(res=>{
        if(res.code!=='000') throw 'An Unexpected Error Occurred';
        if(res.data.length==0) throw 'Non-Reserve line not found';
        let data = {
          replenishId: this.demandLineData.id,
          replenishLineIds: res.data
        }
        return this.service.replenishmentReReserveByLineId(data)
      })
    )
    .subscribe(res=>{
      this.setLoading(false)
      if(res.code=='000'){
        this.forceReloadReplenishment()
      }else{
        throw 'An Unexpected Error Occurred'
      }
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  resetReserveTarget(){
    this.reserveTargetIds = [];
    this.reserveTargetIdChannelMapping = {};
    this.isReserveSelectedAll = false;
  }

  reserveLisById(){
    if(this.loading) return
    this.setLoading(true)

    let replenishId = this.demandLineData.id
    // [...channel ids]
    let channelIds = Object.values(this.reserveTargetIdChannelMapping);
    // [...distancted channel ids]
    let _channelIds = CommonMethod.distinctArray(channelIds);
    // {channelid:[], ...}
    let channelLineIdMapping = _channelIds.reduce((id, value)=>{return {...id, [value]: []}},{});
    // {channelid:[...lineid per channel]}
    for (const [key, value] of Object.entries(this.reserveTargetIdChannelMapping)) {
      channelLineIdMapping[value].push(key)
    }
    // {idList: res.data, channelId: data}[]
    let channelIdLineIdsArr = _channelIds.map(id=>{return {channelId: id, idList: channelLineIdMapping[id]}});
    let obsList = channelIdLineIdsArr.map(data=>{
      return this.service.replenishmentReReserveByLineId({
        replenishId: replenishId,
        replenishLineIds: data.idList,
        // channelId: data.channelId,
      })
    })
    of(null).pipe(
      switchMap(res=>{
        return CommonMethod.forkjoinSync(obsList)
      }),
      tap(res=>{
        let failedRes = res.find(_res=>{
          return _res.code!='000'
        })
        let haveSuccess = res.some(_res=>{
          return _res.code=='000'
        })
        // no line reserve success, throw error, dont reset checkbox, dont reload table
        if(!haveSuccess && failedRes) throw failedRes.msg||'An Unexpected Error Occurred';
        // have success but have line reserve fail, only alert msg, then next step
        if(haveSuccess && failedRes) this.showMessage('error', this.info.title, failedRes.msg||'An Unexpected Error Occurred')
      }),
    )
      .subscribe(res=>{
        this.setLoading(false)
        this.resetReserveTarget()
        this.forceReloadReplenishment()
      }, err=>{
        this.setLoading(false)
        this.resetReserveTarget()
        this.forceReloadReplenishment()
        this.showMessage('error', this.info.title, err)
      })
  }

  resubmitLis(){
    let data = {
      id: this.demandLineData.id,
    }
    this.setLoading(true)
    this.service.replenishmentReSubmit(data).subscribe(res=>{
      this.setLoading(false)
      if(res.code=='000'){
        this.forceReloadReplenishment()
      }else{
        throw 'An Unexpected Error Occurred'
      }
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  tableCheckboxChange(e, col, data){
    if(col.field=='isReserveTarget'){
      if(e.checked){
        this.reserveTargetIds.push(data.id)
        this.reserveTargetIdChannelMapping[data.id]=data.toChannelId;
      }else{
        let idx = this.reserveTargetIds.indexOf(data.id)
        if(idx!=null&&idx>=0){
          this.reserveTargetIds.splice(idx,1)
          delete this.reserveTargetIdChannelMapping[data.id]
        };
        this.isReserveSelectedAll = false
      }
    }
  }

  isReserveSelectedAll = false
  tableCheckboxChangeSelectAll(e, col){
    if(col.field=='isReserveTarget'){
      if(e.checked){
        this.setLoading(true)
        of(null).pipe(
          switchMap(res=>{
            return this.service.getAllChannelIdsByReplenishId(this.demandLineData.id)
          }),
          // handle get channel id api response
          map(res=>{
            if(res.code!=='000') throw 'An Unexpected Error Occurred';
            if(res.data.length==0) throw 'Non-Reserve line not found';
            return res.data
          }),
          map(res=>{
            // filter out warehouse channel id
            let repo = LocalStorageHelper.getObject("REPO")
            let keyObjRepo = CommonMethod.jsonObjArrayToKeyObject(repo, 'id')
            return res.filter(e=>keyObjRepo[e].other!=CONFIG.CHANNEL_WAREHOUSE_TYPE_CODE)
          }),
          // get non-reserve line by replenish id and channel
          switchMap(res=>{
            let obsList = []
            res.forEach(data => {
              let obs = this.service.getUnReserveReplenishLineByReplenishIdAndChannel(this.demandLineData.id, data)
                .pipe(map(res=>{
                  if(res.code=='000'){
                    return {idList: res.data, channelId: data}
                  }
                  return null
                }))
              obsList.push(obs)
            });
            return forkJoin(obsList)
          }),
          tap((res:any[])=>{
            let _res = res.filter(data=>data&&data.idList.length>0)
            let _ids = []
            let _idChannelMapping = {}
            _res.forEach(idsWithChannel=>{
              _ids = [..._ids, ...idsWithChannel.idList]
              let newMapping = idsWithChannel.idList.reduce((id, value)=>{return {...id, [value]: idsWithChannel.channelId}},{})
              _idChannelMapping = {..._idChannelMapping, ...newMapping}
            })
            this.reserveTargetIds = _ids;
            this.reserveTargetIdChannelMapping = _idChannelMapping;
          })
        )
          .subscribe(res=>{
            this.setLoading(false)
            // if(res.code!=='000') throw 'An Unexpected Error Occurred';
            // if(res.data.length==0) throw 'Non-Reserve line not found';
            // let _ids = [...this.reserveTargetIds, ...res.data]
            // let ids = _ids.filter((value, index, array) => array.indexOf(value) === index);
            // this.reserveTargetIds = _ids
            this.refreshTableWithCurrentData()
          }, err=>{
            this.setLoading(false)
          })
      }else{
        this.resetReserveTarget()
        this.refreshTableWithCurrentData()
      }
    }
  }

  refreshTableWithCurrentData(){
    this.tableData.data = this.preProcessReplenishmentLineData(this.demandLineList)
  }

  tableFilterItemList = []
  tableFilterReservationStatus = null

  initTableFilterPanel(){
    let that = this;
    this.skus = CommonMethod.getItemListFromSku(LocalStorageHelper.getObject('SKU'))
    let reservationStatus = [
      {id: 'Non-Reserve', label: 'Non-Reserve' },
      {id: 'Reserved', label: 'Reserved' },
      {id: 'Submitted', label: 'Submitted' },
    ]
    this.tableFilterItemList = null;
    this.tableFilterReservationStatus = null;
    this.tableFilterPanel = {
      ipts: [
        {
          title: this.QUERY_TITLE.ITEMCODE,
          type: INPUT_TYPE.MUTIPLESELECT,
          name: 'tableFilterItem',
          class: "p-col-3",
          value: null,
          disabled: false,
          options: this.skus,
          _optionLabel: 'label',
          optionValue: 'id',
          change: (idx, value, ipt)=>{
            that.tableFilterItemList = value.map(val=>val.id)
          },
        },
        {
          title: this.QUERY_TITLE.LISRESERVATIONSTATUS,
          type: INPUT_TYPE.SELECT,
          name: 'tableFilterLisReservationStatus',
          class: "p-col-3",
          value: null,
          disabled: false,
          options: reservationStatus,
          optionLabel: 'label',
          optionValue: 'id',
          change: (idx, value, ipt)=>{
            that.tableFilterReservationStatus = value
          },
        },
      ],
      btnsclass: 'p-col-3 p-d-flex p-align-center',
      btns: [
        {
          title: this.BUTTON_TITLE.SEARCH,
          class: "p-button p-mr-1",
          show: true,
          handler: { click: () => this.filterTable() }
        },
        {
          title: this.BUTTON_TITLE.CLEAR,
          class: "p-button-outlined p-mr-1",
          show: true,
          handler: { click: () => this.clearTableFilter() }
        },
      ],
    }
  }

  filterTable(){
    this.getLines()
  }
  clearTableFilter(){
    this.tableFilterItemList = null;
    this.tableFilterReservationStatus = null;
    this.tableFilterPanel.ipts.forEach(ipt => {
      ipt.value = null
    });
  }

  confirmUpdateOnhandQty(){
    let that = this;
    this.conf.confirm({
      message: this.info.confirmLisRefresh,
      header: this.info.title,
      accept: ()=>that.updateOnhandQty()
    })
  }

  updateOnhandQty(){
    this.setLoading(true)
    of(null).pipe(
      switchMap(res=>{
        // return this.service.getReplenishmentLineItemList(this.demandLineData.id)
        return this.service.getReplenishmentLineItemListWithReplenishItemStatus(this.demandLineData.id, 'Non-Reserve', this.demandLineData.channelName)
      }),
      map(res=>{
        if(res.code!='000') throw res.msg || res.message
        if(!res.data || res.data.length == 0) throw 'Please proceed "Update Warehouse Qty".';
        //if(res.data.length > 40) throw 'Maximum 40 Items';
        return res.data.map(data=>data.itemId)
      }),
      tap(itemIdList=>{
        const batchSize = 35;
        const batches = [];
        const intervalTime = 60000;
        // Split itemIdList into batches of size batchSize
        for (let i = 0; i < itemIdList.length; i += batchSize) {
          const batch = itemIdList.slice(i, i + batchSize);
          batches.push(batch);
        }
        //时间提示语:
        if(batches.length > 1){
          this.showMessage('info', this.info.title, "Refresh Warehouse Qty estimated waiting time: " + batches.length + " min.")
        }

        //Call updateOnhandQty for each batch and print the result
        batches.forEach((batch, index) => {
          setTimeout(() => {
            this.service.updateOnhandQty(this.demandLineData.fromChanelId, batch)
            .subscribe(result => {
              if (result.code != '000') this.showMessage('error', this.info.title, result.msg || result.message);
            },err=>{
              this.setLoading(false)
              this.showMessage('error', this.info.title, err)
              }
            );
            //最后一次循环更新页面数据
            if(index === batches.length - 1){
              this.setLoading(false)
              this.showMessage('success', this.info.title, this.info.lisRefreshSuccess)
              this.getLines();
            }
          }, index * intervalTime); // 设置每次执行的延迟时间60s，这里是以秒为单位，可以根据需要调整,updateOnhandQty 和CALC_STOCK_ADJ api 共享每分钟50次请求.超出次数会出现"429 Too Many Requests"
        });

        //return this.service.updateOnhandQty(this.demandLineData.fromChanelId,itemIdList)
      }),
      //tap(res=>{
      //  if(res.code != '000') throw res.msg || res.message;
      //})
    ).subscribe(res=>{
      //this.setLoading(false)
      //this.showMessage('success', this.info.title, this.info.lisRefreshSuccess)
      //this.getLines();
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  updateReplenishLineQty(){
    this.setLoading(true)
    this.service.updateReplenishLineQty(
      this.demandLineData.id
    ).pipe(
      tap(res=>{
        if(res.code!='000') throw res.msg || res.message
      })
    )
    .subscribe(res=>{
      this.setLoading(false)
      this.showMessage('success', this.info.title, this.info.lisUpdateReplenishLineSuccess)
      this.getLines()
    }, err=>{
      this.setLoading(false)
      this.showMessage('error', this.info.title, err)
    })
  }

  refreshReplenishmentStatus(){
    this.setLoading(true)
    this.getSubmittedQty().pipe(
      map(res=>{
        if(res.code!=='000') return null
        return res.msg
      }),
      switchMap(submittedLineQty=>{
        if(submittedLineQty!==null && submittedLineQty > 0 && this.statusIsReserved) {
          return this.service.replenishmentUpdateToSubmitted({id: this.demandLineData.id})
        }
        return of(null)
      })
    ).subscribe(res=>{
      this.setLoading(false)
      this.forceReloadReplenishment()
    },err=>{
      this.setLoading(false)
    })
  }

  waitingReserveLis(){
    this.setLoading(true)
    //this.showMessage('info', "", "Waiting for Reserve LIS");
    this.cycleCount++
    this.reserveLisCreateTimer = setTimeout(()=>{
        this.getStatusIsReserve().subscribe(res=>{
    this.cycleCount--
    if(res){
        this.setLoading(false)
        this.showMessage('info', "", "Reserve LIS Complete");
        this.resetReserveTarget()
        this.forceReloadReplenishment()
    }else{
        this.waitingReserveLis()
      }
    }, err=>{
      this.setLoading(false)
      this.cycleCount--
      })
    },30000)
  }

  getStatusIsReserve(){
    return this.service.getReplenishmentStatusById({id: this.demandLineData.id}).pipe(map(res=>{
      //如果状态仍然是VERIFIED,则更新为RESERVING
      if(res?.data == this.STATUS.VERIFIED){
        this.service.replenishmentReserveLiS({id: this.demandLineData.id})
      }
      return res?.data == this.STATUS.RESERVED
    }))
  }

  waitingSubmitToLis(){
    this.setLoading(true)
    //this.showMessage('info', "", "Waiting for Submit to LIS");
    this.cycleCount++
    this.reserveLisCreateTimer = setTimeout(()=>{
      this.getStatusIsSubmit().subscribe(res=>{
        this.cycleCount--
          if(res){
            this.setLoading(false)
            this.showMessage('info', "", "Submit to LIS Complete");
            this.resetReserveTarget()
            this.forceReloadReplenishment()
          }else{
            this.waitingSubmitToLis()
          }
        }, err=>{
        this.setLoading(false)
        this.cycleCount--
      })
    },30000)
  }

  getStatusIsSubmit(){
      return this.service.getReplenishmentStatusById({id: this.demandLineData.id}).pipe(map(res=>{
      //如果状态仍然是RESERVED,则更新为SUBMITTING
      if(res?.data == this.STATUS.RESERVED){
        this.service.replenishmentSubmitToLis({id: this.demandLineData.id})
      }
      return res?.data == this.STATUS.COMPLETED || res?.data == this.STATUS.SUBMITTED
    }))
  }
}
