import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { computed } from 'mobx';
import { Paginator } from 'primeng/paginator';
import { Subscription } from 'rxjs';

/**
 * @description: 列表展示组件
 * @author: ken
 */
@Component({
  selector: 'app-ptable-pannel',
  templateUrl: './table-pannel.component.html',
  styleUrls: ['./table-pannel.component.css']
})
export class TablePannelComponent implements AfterViewInit{
  @Input() productStore
  @Input() store
  @Input() searchStore
  @Input() tableHeaderCheckbox = true
  @Input() rowEditable = false
  @Input() scrollable = true
  @Input() isHover = 'true'
  @ViewChild('paginator', {static: false}) paginator: Paginator;
  clonedData = {}
  @computed get hasAttr(){
    const urlArr = ['stock_movement', 'stock_balance', 'notice', 'stock_take','stock_adjustment', 'transfer_order','cross_shop_pickup_quota']
    return urlArr.some((item) => location.pathname.includes(item));
  }

  ngAfterViewInit(): void {
    this.searchStore.paginator = this.paginator
  }

  onRowEditInit(data) {
    this.clonedData[data.id] = {...data};
  }

  onRowEditSave(data) {
    if (this.store.display_data.id > 0)
      delete this.clonedData[this.store.display_data.vin];
    // else
    //   this.messageService.add({severity:'error', summary: 'Error', detail:'Year is required'});
  }

  onRowEditCancel(data, index: number) {
    this.store.display_data[index] = this.clonedData[this.store.display_data.vin];
    delete this.clonedData[this.store.display_data.vin];
  }
  // timestampToTime(timestamp){
  //  return CommonMethod.timestampToTime(timestamp)
  // }
  customSort(e){

  }

  // @ViewChild('paginator', {static: false}) paginator: Paginator;
  // reset(){
  //   // if(this.paginator) this.paginator.changePage(0);
  //   this.paginator._first = 0;

  // }
  // paginatorSub: Subscription
  // ngOnChanges(changes){
  //   if(changes['searchStore']){
  //     if(this.searchStore.paginatorSubject){
  //       if(this.paginatorSub) {
  //         this.paginatorSub.unsubscribe();
  //         this.paginatorSub = null;
  //       }
  //       this.paginatorSub = this.searchStore.paginatorSubject.subscribe(e=>{
  //         if(e=='reset'){
  //           this.reset()
  //         }
  //       })
  //     }
  //   }
  // }
  // ngOnDestroy() { if(this.paginatorSub)this.paginatorSub.unsubscribe() }

}
