import { BasePannel } from './BasePannel';




/**
 *  class for QueryPannelStore
 *  查询面板
 */
export class QueryPannelStore extends BasePannel{
    constructor(qp){
        super(qp.ipts,qp.btns,qp.btnsclass)
    }
}
