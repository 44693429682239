import { HttpHelper } from '../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from '../../config';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtApiRequestLogService {
  search(data) {
    return from(HttpHelper.post(URLDICT.EXT_API_REQUEST_LOG_SEARCH, data))
  }
}
