// import { Injectable } from "@angular/core";

// @Injectable({
//   providedIn: "root",
// })
export class LoadingService {
  
  private _loading = 0;
  public get loading(){return this._loading};
  public set loading(e) { this._loading = e > 0? e: 0; };
  
  constructor(
  ) {}

  setLoadingBoolean(e){
    if(e){
      this.loading++
    }else{
      this.loading--
    }
  }
}
