import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {observable} from "mobx";


import {INPUT_TYPE, URLDICT} from "../../../stores/base/BaseStore";
import { MessageService, ConfirmationService } from 'primeng/api';
import {LocalStorageHelper} from "../../../util/LocalStorageHelper";



@Component({
  selector: 'nz-test',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {


  @observable loadingSwitch = false

  @observable isVisible: boolean = false
  @observable queryPannel: any = {}

  shopAndWarehouse
  transactionDate
  channel: number

  $URLDICT = URLDICT
  permission: string[]
  constructor(public n: MessageService, public r: Router, public p: ActivatedRoute, public m: ConfirmationService) {
    const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE').filter(repo=>repo.children.length)
    this.shopAndWarehouse = repoList
    this.initQueryData()
    this.getPermission(p)
  }
  initQueryData() {
    this.queryPannel = {
      ipts:[
        {
          title:"Channel", // for display, can be chinese or english
          name:"skuId",  // for database colume
          value: null,
          type: INPUT_TYPE.TREESELECT,
          options:  this.shopAndWarehouse,
          class: "p-col-12 p-md-10 p-md-offset-1 p-lg-6 p-lg-offset-3"
        },
        {
          title:"Transaction Date",
          name:'createAt',
          value: new Date(),
          class: "p-col-12 p-md-10 p-md-offset-1 p-lg-6 p-lg-offset-3",
          type:INPUT_TYPE.DATETIME
        }
      ],
      btnsclass: "p-d-flex p-col-12 p-md-10 p-md-offset-1 p-lg-6 p-lg-offset-3",
      btns:[
        {
          category: 'primary',
          title:'Download',
          show: true,
          class: "p-order-1",
          handler:{
            "click":()=>{
              this.download()
            }

          }
        },{
          title:'Reset',
          show: true,
          class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
          handler:{
            'click':()=>{
              this.refresh()
            }
          }
        }
      ]
    }
  }
  getPermission(p){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code] || []
    this.setPermission(this.queryPannel.btns)
  }
  setPermission(list) {
    list.forEach(item => {
      item.title === 'Download' && (item.disabled = !this.permission.includes('Download Report'))
    })
  }
  ngOnInit(): void {
    // this.shopAndWarehouse = JSON.parse(localStorage.getItem("REPOMODULEBYUAMGROUP"))
    this.transactionDate = new Date().getTime() - 24 * 60 * 60 * 1000
  }


  /**change 时查询sku**/
  channelChange(e) {

    let show = true
    if (e === '10' || e === '9' || e === '8' || !e) {
      this.channel = null
      show = false
    }
    if (!show) {
      setTimeout(() => {
        const el: any = document.querySelector('.repo .ant-select-selection-selected-value')
        el && (el.style.opacity = 0)
      }, 0);
    }
  }

  dateChange(e) {
    this.transactionDate = this.transactionDate.getTime()
  }


  download() {
    this.excelExport(this.$URLDICT.STOCK_REPORT_PRINT)
  }

  excelExport(u) {
    this.channel = this.queryPannel.ipts[0].value?.id
    this.transactionDate = this.queryPannel.ipts[1].value || this.transactionDate // ? new Date(this.queryPannel.ipts[1].value.split('+')[0]).getTime() : null
    if(this.channel == null){
      // this.n.warning("System", "Please select one channel !")
      this.showMessage('warn', 'System', 'Please select one channel !')
      return
    }

    // location.href trigger main.component.ts onbeforeunload, call logout api
    let href = u + "?channel=" +  this.channel + "&transactionDate=" + this.transactionDate.getTime()
    console.log("location.href" + location.href)
    console.log(this.channel)

    var a = document.createElement('a');
    a.href = href;
    a.download = null;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();

    // this.refresh()
  }


  refresh() {
    this.transactionDate = this.transactionDate = new Date().getTime() - 24 * 60 * 60 * 1000
    this.queryPannel.ipts[1].value = new Date()
    this.channel = null
    this.queryPannel.ipts[0].value = null
    this.queryPannel.ipts[0].showValue = null
  }
  showMessage(severity, summary, detail){
    this.n.add({severity, summary, detail});
  }
}

