import { HttpHelper } from '../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from '../../config';
import { from, of } from 'rxjs';
import { CommonService } from '../common/common-service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockLisInterfaceService {
  constructor(
    public commonService: CommonService,
  ){}
  search(data) {
    return from(HttpHelper.post(URLDICT.LIS_STOCK_INTERFACE_SEARCH, data))
  }
  getStatus() {
    return from(HttpHelper.get(URLDICT.LIS_STOCK_INTERFACE_GET_STATUS))
  }
  submitCall10u(id) {
    return from(HttpHelper.post(URLDICT.LIS_STOCK_INTERFACE_CALL10U, {id}))
  }
  submitCall10e(id) {
    return from(HttpHelper.post(URLDICT.LIS_STOCK_INTERFACE_CALL10E, {id}))
  }
  updateLisInterfaceLine(data){
    return of(null).pipe(switchMap(e=>{
      return from(this.commonService.httpPost(URLDICT.LIS_STOCK_INTERFACE_UPDATEOCC, data))
    })) 
  }
  deleteByIds(idList: any[], deleteRemark: string){
    return of(null).pipe(switchMap(e=>{
      return from(this.commonService.httpPost(URLDICT.LIS_STOCK_INTERFACE_DELETEBYIDS, {idList: idList, deleteRemark: deleteRemark}))
    })) 
  }
}
