import { action,observable } from 'mobx';
import { BasePannel } from './BasePannel';
import {BaseStore} from "./BaseStore";




/**
 *  class for ModalPannelStore
 *  弹窗
 */
export class ModalPannelStore extends BasePannel{
    constructor(mp){
        super(mp.ipts,mp.btns)
    }
  @observable isVisible = false
  @observable title = ""
  @observable detailData={}
  @observable detailKey = []
  @observable collapse= []

  /**
   * 打开弹窗
   */
  @action showModal() {
    this.isVisible = true;
  }

  /**
   * 关闭弹窗
   */
  @action closeModal(){
     this.clear();
     this.ipts.forEach(ipt=>{
       if(ipt.title == "From StockCategory"){ipt.options = []}
       if(ipt.title == "To StockCategory"){
         ipt.options = new BaseStore(null).$CACHE.getObject("/STOCK/CATEGORY/STOCK_TYPE")}
     })
    this.isVisible = false;
  }

}
