import { Injectable } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { makeAutoObservable, reaction } from "mobx"
import { MessageService, ConfirmationService } from "primeng/api"
import { pluck } from "rxjs/operators"
import { HttpHelper } from "src/app/util/HttpHelper"
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper"
import { INPUT_TYPE } from "../../base/BaseStore"
import { URLDICT } from "src/app//config";
import { CommonService } from "@/service/common/common-service"
import { DatePipe } from "@angular/common"
import cronstrue from 'cronstrue';

@Injectable()
export class ConfigurationTablesMaintenanceStore {
  queryData = {
    ipts: [
      {
        title: "System Master",
        name: "systemMaster",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: false,
        options: [],
        optionLabel: 'label',
        optionValue: 'label',
      },
      {
        title: "Column Search",
        name: "columnSearch",
        type: INPUT_TYPE.SELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        error: false,
        options: [],
        optionLabel: 'labelName',
        optionValue: 'labelValue',
        disabled:true,
      },
      {
        title: "Column Search Value",
        name: "columnSearchValue",
        type: INPUT_TYPE.INPUT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        disabled:true,
      },
    ],
    btns: [
      {
        title: 'Search',
        class: "p-order-3",
        show: true,
        handler: { click: () => this.search() }
      }, {
        title: 'Clear',
        class: "p-button-outlined p-order-0 p-mr-1 p-ml-auto",
        show: true,
        handler: { click: () => this.clear() }
      }
    ],
    change: (...e)=>{
      if(e[2]['name'] == 'systemMaster'){
        this.clear(true)
      }
    },
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-12',

  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      { key: "type", title: 'Type',allowSort: false},
      { key: "code", title: 'Code',allowSort: false},
      { key: "msg", title: 'Message',allowSort: false },
      { key: "desc", title: 'Description',allowSort: false},
    ],
    selectedColumns: [],
    SearchColumns : [],
    selectedRow: [],
    visibleColumns: [],
    frozenColumns: [],
    frozenWidth: '',
    head: [],
    lineCols: [],
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "", order: -1 },
    searchEvent: { fieName: "", value: "" },
    searchSortName :"",
    // showCheckboxSwitch: false,row
    showCheckboxFlag: false,
    rowEditable: false,
    loadingSwitch: false

  }
  excludedFields = ['event_time']
  excludedDate = ['event_time']
  clonedRows = {}
  dialogTitle = ""
  dialogData = []
  addDialog = false
  loading = false
  selectRows
  currentDate
  info = {
    title: 'Configuration Tables Maintenance',
    require: 'Please select a System Master first!',
    success: 'Modified successfully!'
  }
  // defaultPermission: any = { c: 8, u: 4, r: 2, d: 1 };
  permission: string[];
  prevModule = null
  currentModule = null
  // tslint:disable-next-line: max-line-length
  constructor(private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private conf: ConfirmationService,
    public commonService: CommonService,
    public datePipe: DatePipe,
    ) {
    makeAutoObservable(this)
    this.getPermission()
    this.tableData.selectedColumns = this.tableData.columns
    this.getResolverData()
    reaction (
      () => this.tableIpt.value,
      (data) => {
        this.currentModule = data
      }
    );
  }
  get master(){
    return this.tableIpt.value
  }
  get tableIpt(){
    return this.getQueryDataByName('systemMaster')
  }
  get columnIpt(){
    return this.getQueryDataByName('columnSearch')
  }
  get columnValueIpt(){
    return this.getQueryDataByName('columnSearchValue')
  }
  get showSearch() {
    return this.permission?.includes('Search')
  }
  getPermission() {
    this.permission = LocalStorageHelper.getObject("PERMISSIONS")[this.route.snapshot.data.code];
  }
  updateMaster(d){
    this.loading = true
    this.tableData.sortField.field =""
    this.tableData.sortField.order = -1
    HttpHelper.get(URLDICT.COMMON_SYSTEMMASTER).then(res => {
      if (res.code === '000') {
        this.parseRemoteData(res.data, 'SYSTEM_MASTER')
      } else {
        this.showMessage('error', 'System', 'Failed to get system master!');
      }
      this.loading = false
      // this.tableData.loadingSwitch = false
    }).catch(e => {
      this.showMessage('error', 'System', e.toString());
      this.loading = false
      // this.tableData.loadingSwitch = false
    })
  }
  getResolverData() {
    this.route.data.pipe(pluck('query')).subscribe(res => {
      // console.log('=> Get data from resolve of the Configuration Tables Maintenance: ', res);
      // 从后端获取到的数据
      if (Array.isArray(res)) {
        const list = ['SYSTEM_MASTER']
        res.forEach(({data}, idx) => {
          this.parseRemoteData(data, list[idx])
        });
      }else{
        const sysMaster = this.getQueryDataByName('systemMaster')
        sysMaster.options = LocalStorageHelper.getObject('SYSTEM_MASTER')
      }
    })
  }
  getQueryDataByName (name) {
    return this.queryData.ipts.find(item => item.name == name)
  }

  getSearchtsDataByName (name) {
    //return this.queryData.searchts.find(item => item.name = name)
  }

  parseRemoteData(data: any, name: string) {
    const sysMaster = this.getQueryDataByName('systemMaster')
    sysMaster.options = data
    LocalStorageHelper.setObject(name, data)
  }
  sortSearch(field) {
    const sortField = this.tableData.sortField
    if(sortField.field === field){
      sortField.order = -sortField.order
    }else{
      sortField.field = field
      sortField.order = 1
    }
    // console.log(sortField,  this.tableData.sortField);

    this.search(true)
  }

  searchfieName(fiename){
    const searchEvent = this.tableData.searchEvent
    searchEvent.fieName = fiename
  }
  newSearch(sort) {
    // console.log('search: ', this.master, sort);
    let data = this.getSearchParams()
    this.tableData.loadingSwitch = true
    HttpHelper.post( URLDICT.SYSTEM_MASTER_SEARCH, data).then(res => {
      if(res.code === '000') {
        this.prevModule = this.currentModule
        this.showTableData(res)
      } else {
        this.showMessage('error', 'System', res.msg)
        this.loading = false
        this.tableData.loadingSwitch = false
      }
    }).catch(res => {
      this.loading = false
      this.tableData.loadingSwitch = false
    })
    this.columnIpt.disabled = false
    this.columnValueIpt.disabled = false
  }
  search(sort=false) {
    if(!this.master) {
      this.showMessage('warn', this.info.title, this.info.require)
      this.tableIpt.error = true
      return
    }
    this.tableData.data = null
    this.newSearch(sort)
  }
  showTableData(res) {
    // console.log(res);
    const pId = res.data[0].primid
    //处理可编辑的状态
    // if(res.data[0].is_edittable) {
      res.data.forEach(item => {
        item.id = item[pId]
        if(item.update_date!=null){
          item.update_date = this.datePipe.transform(item.update_date, "yyyy/MM/dd HH:mm:ss.SSS")
        }
        if(item.create_date!=null){
          item.create_date = this.datePipe.transform(item.create_date, "yyyy/MM/dd HH:mm:ss.SSS") 
        }
        if(item.cron_expression!=null){
          item.cron_expression=`${item.cron_expression} (${cronstrue.toString(item.cron_expression)})`
        }
        /* const hasField = item.some((field) => this.excludedFields.includes(field));
        if(hasField){
          item.excludedFields = true
        } */
      });
    // }
    this.tableData.rowEditable = res.data[0].is_edittable === 'Y' ? true : false

    //const excluded = ['primid', pId, 'id', 'create_date', 'update_date', 'create_by_id', 'update_by_id', 'is_edittable'] //20230610 show all
    const excluded = ['primid', pId, 'id','is_edittable']
    let nameList = Object.keys(res.data[0])

    nameList = nameList.filter(item => !excluded.includes(item))
    nameList.sort()
    // pId && nameList.unshift(pId)

    const head = nameList.map(name => {
      return {
        key: name,
        title: name.replace(/_/g, ' '),
        edit: this.excludedFields.includes(name), // 这个字段是否允许编辑
        isDate: this.excludedDate.includes(name), // 标记 date 类型, 需要显示为日期格式
        width:  this.currentModule === 'trx_request_order_type' ? '200px' : 'auto',
        minwidth: this.currentModule === 'trx_request_order_type' ? '200px' :'150px',
      }
    });

    const searchColumns = nameList.map(name => {
      return {
        labelName: this.replaceUnderscoreWithNextLetterUpperCase(name),
        labelValue: this.convertToSnakeCase(name),
      }
    });
    this.tableData.searchSortName = this.tableIpt.value
    this.columnIpt.options = searchColumns;
    this.tableData.head = head
    this.tableData.totalRecords = res.msg
    this.tableData.selectedRow = []
    this.tableData.selectedColumns = head
    this.tableData.data = res.data
    this.tableData.btns[0].disabled = false
    this.loading = false
    this.tableData.loadingSwitch = false

  }

  replaceUnderscoreWithNextLetterUpperCase(str: string): string {
    return str.replace(/_./g, (match) => {
      return match.charAt(1).toUpperCase();
    });
  }

  convertToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase());
  }

  reSetEditing(){
    const els = document.querySelectorAll("button[label='Cancel']")
    els.forEach((item:HTMLElement) => {
      item.click()
    })
  }
  clear(exceptTableIpt = false) {
    if(!exceptTableIpt){
      const sysMaster = this.tableIpt
      sysMaster.value = null
      sysMaster.error = false
    }
    const columnSearchIpt = this.columnIpt
    columnSearchIpt.options = [];
    columnSearchIpt.value = null
    columnSearchIpt.error = false
    const columnSearchValueIpt = this.columnValueIpt
    columnSearchValueIpt.value = null
    columnSearchValueIpt.error = false
    this.tableData.pageIndex = 0
    this.tableData.pageSize = 20
    this.tableData.totalRecords = 0
    this.tableData.sortField.field = ''
    this.tableData.sortField.order = 1
    this.tableData.selectedRow = []
    this.tableData.selectedColumns = this.tableData.columns
    this.tableData.data = []
    this.tableData.head = []
    this.tableData.btns.forEach(btn => {
      btn.disabled = true
    })
  }
  // create a new data of select master
  add() {
    this.dialogTitle = "add " + this.currentModule.replace(/_/g, ' ')
    this.addDialog = true
    const tempData = JSON.stringify(this[this.currentModule+'_head']);
    this.dialogData = JSON.parse(tempData);
  }
  delete() {
    const len = this.tableData.selectedRow.length
    if(len){
      this.conf.confirm({
        message: `Do you want to delete these ${len} record?`,
        header: 'Delete Confirmation',
        accept: () => {
          const uri = URLDICT[this.currentModule.toUpperCase() + "_DELETE"]
          const data = {
            ids: this.tableData.selectedRow.map(item => item.id)
          }
          this.tableData.loadingSwitch = true
          HttpHelper.post(uri, data).then(res => {
            if (res.code == '000') {
              this.search()
            } else {
              this.showMessage('error', 'System', 'Delete Fail!');
            }
            this.tableData.loadingSwitch = false
          }).catch(e => {
            this.showMessage('error', 'System', e.toString());
            this.tableData.loadingSwitch = false
          })
        }
      });
    }
  }
  onSelectCalender(e, op) {
    this.selectRows.data[this.selectRows.field] = e.getTime();
    op.hide();
  }
  /* selectAllPNCheckbox(e) {
    this.disabledDeleteButton()
  }
  selectOnePNCheckBox(id) {
    this.disabledDeleteButton()
  } */
  onRowEditInit(data) {
    this.clonedRows[data.id] = {...data};
  }
  onRowEditSave(data) {
    const mothed = this.master.replace(/\_(\w)/g, (all, letter) => letter.toUpperCase()) + 'Save'
    this[mothed](data)
  }
  alertNoticeSave(data) {
    const {id, event_type:eventType, message, priority} = data
    const parmas = {
      sysMasterTableName: this.master,
      id,
      eventType,
      message,
      priority
    }
    this.save(parmas)
  }
  orderTypeSave(data) {
    const {id, order_type_code:orderTypeCode, description, is_order_channel_condition_required:isOrderChannelConditionRequired,
      is_condition_change_allowed:isConditionChangeAllowed, is_manual_entry_allowed:isManualEntryAllowed,is_cross_channels_only:isCrossChannelsOnly,
      is_reservation_required:isReservationRequired, is_reason_required:isReasonRequired, is_sales:isSales} = data
    const parmas = {
      sysMasterTableName: this.master,
      id,
      orderTypeCode,
      description,
      isOrderChannelConditionRequired,
      isConditionChangeAllowed,
      isManualEntryAllowed,
      isReservationRequired,
      isReasonRequired,
      isCrossChannelsOnly,
      isSales,
    }
    this.save(parmas)
  }
  stockReservationTypeSave(data) {
    const {id, stock_reservation_type_code:stockReservationTypeCode, description, is_remark_allowed:isRemarkAllowed,
      is_manual_entry_allowed:isManualEntryAllowed, is_extension_allowed:isExtensionAllowed, allocation_seq:allocationSeq,
      max_reserve_days:maxReserveDays, days_of_interval:daysOfInterval} = data
    const parmas = {
      sysMasterTableName: this.master,
      id,
      stockReservationTypeCode,
      description,
      isRemarkAllowed,
      isManualEntryAllowed,
      isExtensionAllowed,
      allocationSeq,
      maxReserveDays,
      daysOfInterval,
    }
    this.save(parmas)
  }
  userDefLookupSave(data) {
    const {id, code, description, lookup_type:lookupType} = data
    const parmas = {
      sysMasterTableName: this.master,
      id,
      code,
      description,
      lookupType
    }
    this.save(parmas)
  }
  sysDefLookupSave(data) {
    const {id, code, description, lookup_type:lookupType} = data
    const parmas = {
      sysMasterTableName: this.master,
      id,
      code,
      description,
      lookupType
    }
    this.save(parmas)
  }
  save(data) {
    this.tableData.loadingSwitch = true
    const url = {
      alert_notice : URLDICT.SYSTEM_MASTER_EDIT_ALERT_NOTICE,
      order_type : URLDICT.SYSTEM_MASTER_EDIT_ORDER_TYPE,
      stock_reservation_type : URLDICT.SYSTEM_MASTER_EDIT_STOCK_RESERVATION_TYPE,
      user_def_lookup: URLDICT.SYSTEM_MASTER_EDIT,
      sys_def_lookup: URLDICT.SYSTEM_MASTER_EDIT_SYS_DEF_LOOKUP,
    }
    HttpHelper.post(url[this.master], data).then(res => {
      this.tableData.loadingSwitch = false
      if(res.code === '000') {
        this.showMessage('success', this.info.title, this.info.success);
        this.search()
      } else {
        this.showMessage('error', 'System', res.msg || res.message);
      }
    })
  }
  onRowEditCancel(data, idx) {
    this.tableData.data[idx] = this.clonedRows[data.id];
    delete this.clonedRows[data.id];
  }
  // disabledDeleteButton() {
    // const len = this.tableData.selectedRow.length
    // const delBtn = this.tableData.btns[1].disabled = len ? false : true
  // }
  hideDialog() {
    this.addDialog = false
  }
  addNewData() {
    const data:any = {}
    this.dialogData.forEach(({key, value}) => {
      data[key] = value
    });
    if(this.currentModule === 'request_status'){
      data.type = 'Stock reservation'
    }
    const uri = URLDICT[this.currentModule.toUpperCase() + "_CREATE"]
    HttpHelper.post(uri, data).then(res => {
      if (res.code == '000') {
        this.search()
      } else {
        this.showMessage('error', 'System', 'Update data Fail!');
      }
      this.tableData.loadingSwitch = false
      this.addDialog = false
    }).catch(e => {
      this.showMessage('error', 'System', e.toString());
      this.tableData.loadingSwitch = false
      this.addDialog = false
    })
  }
  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }
  getSearchParams(){

    if (this.columnIpt.value != null){
      this.tableData.searchEvent.fieName = this.columnIpt.value
    }else {
      this.tableData.searchEvent.fieName = ''
    }
    if (this.columnValueIpt.value != null){
      this.tableData.searchEvent.value = this.columnValueIpt.value
    }else {
      this.tableData.searchEvent.value = ''
    }
    return {
      accountName: this.commonService.getAccount(),
      sysMasterTableName: this.master,
      sortEvent:  this.tableData.sortField,
      searchEvent: this.tableData.searchEvent,
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize
    }
  }
  page(e) {
    this.tableData.pageIndex = e.first / e.rows
    this.tableData.pageSize = e.rows
    this.search()
  }
}
