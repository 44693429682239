import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
  * @description: 日期选择
  * @author:  ChenShuCheng
  */
@Component({
  selector: 'app-datetime-cst',
  templateUrl: './datetime-cst.html',
  styleUrls:['./datetime-cst.component.css']
})
export class DateTimeCustomComponent {
  @Input() ipt
  onShow() {
    if(this.ipt.lastSecond) {
      this.ipt.value =  new Date(new Date().setHours(23, 59, 59))
    }
  }
  @Output() change = new EventEmitter()
  onSelect(v){
  }
  onInput(v){
  }
  ngModelChange(v){
    this.change.emit(v)
  }
}
