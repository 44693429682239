import { MenuStore } from './../../menu/MenuStore';
import {NOTI_MSG, NOTI_TITLE } from '../../../config/index';
import { action, configure } from 'mobx';
import { BaseStore } from '../../base/BaseStore';

export class LoginStore extends BaseStore{

  private account: string
  private password: string
  private visible: boolean = false
  private loading = false
  private menuStore:MenuStore = null

  constructor(params) {
    super(params)
    configure({enforceActions:'never'})
    this.menuStore = new MenuStore()
    this.checkFrom()
  }
  /**登录跳转**/
  @action login() {
    //let sto = window.localStorage
    const isKeycloak = 0
    sessionStorage.removeItem('loginError')
    this.loading = true
    this.$HTTP
    .post(this.$URLDICT.SYSTEM_LOGIN, { "username": this.account, "password": this.password })
    .then(
      ((res:Response) => {
        if (res.status == 203) {
          // let errorInfo = res.statusText || 'Invalid account or password!'
          let errorInfo = 'Invalid account or password!'
          // this.$NOTICE.error(NOTI_TITLE, errorInfo)
          this.$NOTICE.add({severity:'error', summary:'Error Message', detail: errorInfo});
        }
        if (res.status == 200) {
          //记录用户数据到sessionStrage中
          // sessionStorage.setItem('account', this.account.toString())s
          this.$COMMONSTORE.setAccount(this.account.toString())
          // 设置token
          let resolt = res.json()
          resolt.then(item => {
            let token = item.token
            let permissions = item.menu
            // let token = res.headers.get("token");
            // let permissions = res.headers.get("menu");
            //因为原始的数据的属性排列的比较混乱。这里是为了更直观的观看对象的属性，改变了对象属性的排列顺序
            // permissions = JSON.parse(permissions)
            console.log('----- auth info -----')
            console.table(permissions)
            console.log('----- login info -----', 'token:', token);
            // console.table(JSON.parse(permissions));
            if(permissions) {
              this.$CACHE.setObject("TOKEN",token);
              this.$CACHE.setObject("AUTH", permissions);
              this.$CACHE.setObject("ISKEYCLOAK",isKeycloak);
              this.menuStore.setUserCode(permissions)
              this.$COMMONSTORE.permissionService.setPermission()
              this.$ROUTER.navigate(["/loading"])//navigate to loading
            } else {
              // this.$NOTICE.error(NOTI_TITLE,NOTI_MSG.ERROR_ACCOUNTORPASSWORD)
              this.$NOTICE.add({severity:'error', summary: NOTI_TITLE, detail: NOTI_MSG.ERROR_ACCOUNTORPASSWORD});
            }
          })
        }
        // if(res.code == '003'){
        //   this.$NOTICE.error(NOTI_TITLE,NOTI_MSG.ERROR_ACCOUNTORPASSWORD)
        // }
        this.password = ""
      })
    ).catch(e => {
      this.$ROUTER.navigate(["/login"])
      // this.$NOTICE.error(NOTI_TITLE, NOTI_MSG.NETWORK_ERROR)
      this.$NOTICE.add({severity:'error', summary: NOTI_TITLE, detail: NOTI_MSG.NETWORK_ERROR});
    }).finally(()=>{
      this.loading = false
    })
  }

  /**enter login **/
  @action enterLogin() {
    if(this.account&& this.password){
      this.login()
    }
  }

  /**切换密码框视图**/
  @action switchView() {
    this.visible = !this.visible
  }
  @action checkFrom(){
    const fromInfo = sessionStorage.getItem('loginError')
    const params = this.$PARAMS.snapshot.queryParams
    if(fromInfo && params.from){
      setTimeout(() => {
        this.$NOTICE.add({severity:'error', summary: 'Error Message', detail: fromInfo});
      }, 0);
    }
  }
}
