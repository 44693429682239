import { Component, HostBinding,ChangeDetectorRef,AfterViewInit,ViewChild,HostListener,ElementRef } from '@angular/core';
import { SpreadsheetComponent } from 'src/app/components/spreadsheet-table/spreadsheet-table.component';
import { EditStore } from 'src/app/stores/stock/replenishment/EditStore';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PreAllocateCommonComponent } from '../components/pre-allocate-common/pre-allocate-common.component';
import { VirtualScroller } from 'primeng/virtualscroller';
// import { EditSpreadsheetStore } from 'src/app/stores/stock/replenishment/EditSpreadsheetStore';

@Component({
  providers: [EditStore],
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements AfterViewInit{
  @ViewChild("spreadSheet") spreadSheet: SpreadsheetComponent;
  @ViewChild("overlay") overlay;
  @ViewChild(PreAllocateCommonComponent) preAllocateCommonComponent: PreAllocateCommonComponent;
  @ViewChild("overlayVirtualScroller") overlayVirtualScroller: VirtualScroller;
  
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.store.beforeunload(event)
  }
  constructor(public store: EditStore, public cdr: ChangeDetectorRef,public elRef: ElementRef) { 
    let that = this;
    this.store.setFnGetOverlayVirtualScroller(()=>that.overlayVirtualScroller)
  }

  ngAfterViewInit(){
    this.store.setSpreadSheet(this.spreadSheet)
    this.store.setOverlayPanel('overlay', this.overlay)
    this.store.setPreAllocateCommonComponent(this.preAllocateCommonComponent)
    // this.store.setOverlayPanel('repo', this.repo)
    // this.store.setOverlayPanel('sku', this.sku)
    this.cdr.detectChanges()
    this.elRef.nativeElement
    this.store.setelRef(this.elRef)
  }
}
