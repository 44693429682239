import { Paginator } from 'primeng/paginator';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SearchStore } from 'src/app/stores/config/cross_shop_pickup_quota/SearchStore';

@Component({
  selector: 'app-cross-shop-pickup-quota',
  providers: [SearchStore],
  templateUrl: './search.component.html',
  styles: [
    ":host ::ng-deep .p-datatable table td{height: 53px}"
  ]
})
export class CrossShopPickupQuotaComponent implements AfterViewInit {
  @ViewChild("paginator", {static: false}) paginator: Paginator
  constructor(public store: SearchStore) { }
  ngAfterViewInit(): void {
    this.store.paginator = this.paginator
  }

}
