import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "custom-button-radio-group",
  templateUrl: "./button-radio-group.component.html",
  styleUrls: ["./button-radio-group.component.scss"],
})
export class ButtonRadioGroupComponent implements OnInit {

  @Input() options = [];
  @Input() inputId = null;
  @Input() 
  set value(e){this._value = e;}
  get value(){return this._value}
  @Output() valueChange = new EventEmitter();

  groupname = "";
  _value = null;
  itemIds = [];
  required = false;
  readonly = false;
  disabled = false;

  ngOnInit(): void {
    this.groupname = this.genId();
  }

  ngOnChanges(change){
    if(change['options']){
      this.itemIds = [];
      this.options.forEach(()=>{this.itemIds.push(this.genId())})
    }
  }

  onChange(e, value) {
    if (e.target.checked) {
      this.valueChange.emit(value)
    }
  }

  genId() {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }
    return (
      chr4() +
      chr4() +
      "-" +
      chr4() +
      "-" +
      chr4() +
      "-" +
      chr4() +
      "-" +
      chr4() +
      chr4() +
      chr4()
    );
  }
}
