import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseResolverService, RESOLVERDATA } from './base-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class TakeResolverService implements Resolve<any> {
  constructor(private baseResolver: BaseResolverService) { }
  resolve() {
    let _keyApiCallList: RESOLVERDATA[] = [
      this.baseResolver.resolverDataList.All_STATUS,
      this.baseResolver.resolverDataList.STOCKTAKELABEL,
      this.baseResolver.resolverDataList.STOCKTAKECOUNTBY,
      this.baseResolver.resolverDataList.GETSKU,
      this.baseResolver.resolverDataList.ORDER_TYPE,
      this.baseResolver.resolverDataList.CONDITIONS,
      this.baseResolver.resolverDataList.STOCK_TAKE_BU,  
      this.baseResolver.resolverDataList.DEFAULT_CHANNEL,
      this.baseResolver.resolverDataList.REASON
    ]
    return this.baseResolver.getResolverResult(_keyApiCallList);
  }
}
