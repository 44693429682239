import { CommonService } from '@/service/common/common-service';
import { URLDICT } from '@/stores/base/BaseStore';
import { HttpHelper } from '@/util/HttpHelper';
import { LocalStorageHelper } from '@/util/LocalStorageHelper';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-invalid-account',
  templateUrl: './invalid-account.component.html',
  styleUrls: ['./invalid-account.component.css']
})
export class InvalidAccountComponent implements OnInit {

  constructor(private route: Router, private oauthService: OAuthService, 
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
  }
  goLogin() {
    this.commonService.logout()
    // HttpHelper.post(URLDICT.SYSTEM_LOGOUT, {}).then(
    //   res => {
    //     LocalStorageHelper.removeAll()
    //     sessionStorage.clear()
    //     this.oauthService.logOut()
    //     this.route.navigate([""])
    //   }
    // )
  }
}
