import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[AppGetValueByPath]',
  template: `{{_value}}`,
})
export class GetValueByPathComponent implements OnInit {

  @Input() data: Object;
  @Input() keyPath: string[];
  _value

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes){
    if(changes['data'] || changes['keyPath']){
      this._value = this.parseValue()
    }
  }

  parseValue(data = this.data, keyPath = this.keyPath){
    if(keyPath.length>1){
      return this.parseValue(data[keyPath[0]], keyPath.slice(1))
    }else{
      return data[keyPath[0]]
    }
  }
}
