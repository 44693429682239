import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { makeAutoObservable } from 'mobx';
import { StockBalanceService } from 'src/app/service/stock/stock-balance.service';
import { INPUT_TYPE, BUTTON_TYPE } from 'src/app/stores/base/BaseStore';
import { LocalStorageHelper } from 'src/app/util/LocalStorageHelper';

@Injectable()
export class DemoStore {
  queryPannel = {
    ipts: [
      {
        title: this.service.TITLE.ITEM,
        type: INPUT_TYPE.MUTIPLESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value: null,
        options: [],
      }, {
        title: this.service.TITLE.CHANNEL,
        type: INPUT_TYPE.MUTIPLETREESELECT,
        class: "p-col-12 p-md-4 p-lg-3",
        value:null,
        showValue: null,
        options: [],
        expandKeys: ['0'],
      }, {
        title: this.service.TITLE.LIS_RT,
        type: INPUT_TYPE.CHECKBOX,
        class: "p-col-12 p-md-4 p-lg-3",
        group: 'includeLis',
        value: null,
      }
    ],
    btns: [
      {
        title: this.service.BUTTON_TITLE.SEARCH,
        class: "p-order-1",
        permissionType: 'c',
        show: true,
        disabled: false,
        handler: {
          click: () => {this.search()}
        }
      }, {
        title: this.service.BUTTON_TITLE.CLEAR,
        class: "p-button-outlined p-order-0 p-ml-auto p-mr-1",
        permissionType: 'c',
        show: true,
        disabled: false,
        handler: {
          click: () => this.clearSearchData()
        }
      }
    ],
    btnsclass: 'p-d-flex p-col-12 p-md-12 p-lg-3',
  }
  tableData = {
    btns: [],
    data: [],
    columns: [
      // {field: 'sourceSystem', title: 'Source System'},
      // {field: 'shop', title: 'Channel'},
      // {field: 'sku', title: 'Item'},
      {field: 'skuDesc', title: 'Description'},
      {field: 'uom', title: 'UOM'},
      {field: 'total', title: 'Total Onhand'},
      {field: 'fg', title: 'Available for Sales'},
      {field: 'noOfReserveStock', title: 'Reserved By'},
      {field: 'reservedTotal', title: 'Faulty'},
      {field: 'reserve', title: 'Transit-In'},
      {field: 'reservedStock', title: 'Reservation (Pending for Allocation)'},
      {field: 'faulty', title: 'Faulty'},
      {field: 'intransit', title: 'Advance Order (AO)'},
      {field: 'ao', title: 'Deposit Order (DO)'},
      {field: 'do', title: 'Transit-In'},
    ],
    selectedColumns: [],
    lineCols: [],
    frozenColumns: [
      {field: 'sourceSystem', title: 'Source System', width: '160px'},
      {field: 'shop', title: 'Channel', width: '100px'},
      {field: 'sku', title: 'Item', width: '80px'},
    ],
    frozenWidth: '340px',
    pageIndex: 0,
    pageSize: 20,
    totalRecords: 0,
    sortField: { field: "", order: -1 },
    loadingSwitch: false,
    editShow: true,
  }
  breadcrumb = ''
  loading:boolean = false
  activeIndex = 0
  defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission
  channelIpt
  itemIpt
  checkIpt

  constructor(private route: ActivatedRoute,private service: StockBalanceService) {
    makeAutoObservable(this)
    this.getPermission()
    this.initdata();
  }
  get channel() {
    return this.channelIpt.value
  }

  get item() {
    return this.itemIpt.value
  }

  getTitleByQuery(title){
    return this.queryPannel.ipts.find(item => item.title === title)
  }

  getPermission(){
    this.permission = LocalStorageHelper.getObject('PERMISSIONS')[this.route.snapshot.data.code]
    this.setPermission()
  }

  setPermission() {
    this.queryPannel.btns.forEach(item => {
      item.title === 'Search' && (item.disabled = !this.permission.includes('Search Stock Balance'))
      item.title === 'Export' && (item.disabled = !this.permission.includes('Export allocated on Balance Enquiry'))
      /* if(item.permissionType){
        let permission = this.defaultPermission[item.permissionType]
        // tslint:disable-next-line: no-bitwise
        item.show = (this.permission & permission) === permission
      } */
    })
  }

  initQueryIpt() {
    this.channelIpt = this.getTitleByQuery(this.service.TITLE.CHANNEL)
    this.itemIpt = this.getTitleByQuery(this.service.TITLE.ITEM)
    this.checkIpt = this.getTitleByQuery(this.service.TITLE.LIS_RT)

    const channels = LocalStorageHelper.getObject('REPOTREE')
    this.channelIpt.options = [{ data: 0, label: "ALL", expanded: true, children: channels }]
    const items = LocalStorageHelper.getObject('SKUMODULEBYUAM')
    this.itemIpt.options = items
  }

  clearSearchData() {
    this.channelIpt.value = null
    this.channelIpt.showValue = null
    this.itemIpt.value = null
    this.checkIpt.value = null
  }
  initdata() {
    this.tableData.selectedColumns = this.tableData.columns.slice(0, 5)
    this.initQueryIpt()
    this.search();
  }
  onChange(data) {
    const len = this.tableData.columns.length
    const sliceList = [[0, 4],[4,8],[8, len-1]]
    const r = sliceList[data.index]
    this.tableData.selectedColumns = this.tableData.columns.slice(r[0], r[1])
  }
  // shopId: [{id: 3, code: "HBC"}]
  // skuNum: [{id: 72, code: "2052841"}, {id: 3, code: "2088331"}]
  search() {

    const data = {
      pageIndex: this.tableData.pageIndex,
      pageSize: this.tableData.pageSize,
      sortEvent: this.tableData.sortField,
      ...(this.channel && { shopId: this.channel.map(item => {
          const {id, label:code, ...other} = item
          return {id, code}
        }),
      }),
      ...(this.item && { skuNum: this.item.map(item => {
          item.label = item.name.split("~")[0]
          const {code:id, label:code, ...other} = item
          return {id, code}
        }),
      })
    }
    console.log(this.channel, this.item, data);

    this.loading = true;
    this.tableData.loadingSwitch = true;
    this.service.searchBalance(data).subscribe(res => {
      this.loading = false;
      this.tableData.loadingSwitch = false;
      console.log(res);
      if (res.code === "000") {

        this.tableData.data = res.data
        this.tableData.totalRecords = res.msg
      } else {
        this.service.showMessage('error', this.breadcrumb, res.msg || res.message || res.error.message)
      }

    })
  }
  showTableDate(data) {
    this.tableData.data = data.data
    this.tableData.totalRecords = data.msg
  }

  page(e) {
    this.tableData.pageIndex = e.first / e.rows;
    this.tableData.pageSize = e.rows;
    this.search();
  }
}
