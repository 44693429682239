import { CommonService } from '@/service/common/common-service';
import { CommonStore } from '@/service/common/common-store-service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SearchStore } from 'src/app/stores/notice/SearchStore';
import { InjectHelper } from 'src/app/util/InjectHelper';

@Component({
  selector: "app-notice",
  templateUrl: "./notice.component.html",
  styles: [],
})
export class NoticeComponent implements OnInit {
  store
  constructor( private n: MessageService, private r: Router, private p: ActivatedRoute, private m: ConfirmationService , 
    public commonStore: CommonStore, public commonService: CommonService) {
    this.store = new SearchStore(InjectHelper.start(this));
    // this.store.tablePannel.sortField = 'eventTime'
    // this.store.tablePannel.sortOrder = -1
    this.store.commonService = commonService;
  }

  ngOnInit(): void {
    this.store.onInit()
  }
}
