import { CommonService } from '@/service/common/common-service';
import { DataMigrationService } from '@/service/common/data-migration-service';
import { LoadingService } from '@/service/common/loading-service';
import { CommonMethod } from '@/util/CommonMethod';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PermissionService } from "@/service/common/permission-service";
import { FUNCTION_CODE } from '@/service/common/permission.type';

const ERROR_CODE = {
  IGNORE: 1
}

enum FILTER_TITLE {
  REFRESHSTATUS = "Refresh Status",
  UPLOAD = "Upload",
  CHANNEL = "Channel",
  RUN = "Run"
}

@Component({
  selector: 'app-ppos-data-migration',
  templateUrl: './ppos-data-migration.component.html',
  styleUrls: ['./ppos-data-migration.component.css']
})
export class PposDataMigrationComponent implements OnInit {
  title
  pposDataEditEnable = false
  loadingService = new LoadingService();
  get loading(){return this.loadingService.loading}

  public pposParams = {}

  constructor(
    public dataMigrationService: DataMigrationService,
    public messageService: MessageService,
    public translateService: TranslateService,
    public commonService: CommonService,
    public permissionService: PermissionService,
  ) {
    this.getPermission()
    }

  balancePanel = {
    header: 'PPOS Stock Balance Import',
    btns: [
      {
        title:FILTER_TITLE.REFRESHSTATUS,
        label: 'Refresh Status',
        class: 'cus-btn-auto p-mr-1 p-button-outlined',
        click: ()=>this.refreshPposStatus()
      },
      {
        title:FILTER_TITLE.UPLOAD,
        label: 'Upload',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.uploadStockBalanceFileFromPpos()
      },
      {
        title:FILTER_TITLE.RUN,
        label: 'Run',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.stockBalanceFromPpos()
      },
      {
        label: 'Reconciliation Report',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.exportCompareQtyFile()
      },
      {
        label: 'Exception Report',
        class: 'cus-btn-auto',
        click: ()=>this.exportExceptionFile()
      },
    ]
  }

  serialPanel = {
    header: 'PPOS Stock Serial Number Import',
    btns: [
      {
        label: 'Refresh Status',
        class: 'cus-btn-auto p-mr-1 p-button-outlined',
        click: ()=>this.refreshPposStatus()
      },
      {
        label: 'Upload',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.uploadStockSerialNumberFileFromPpos()
      },
      {
        label: 'Run',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.stockSerialNumberFromPpos()
      },
      {
        label: 'Reconciliation Report',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.exportSerialReconciliationReport()
      },
      {
        label: 'Exception Report',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.exportSerialExceptionReport()
      },
      {
        label: 'CSTK Serial Item Summary',
        class: 'cus-btn-auto p-mr-1',
        click: ()=>this.exportSerialItemSummary()
      },
      {
        label: 'CSTK Serial Number List',
        class: 'cus-btn-auto',
        click: ()=>this.exportSerialNumberList()
      },
    ]
  }

  ngOnInit(): void {
    this.translateService.get('datamigration').subscribe(res => {this.title = res})
    this.refreshPposStatus()
  }

  addMessage(severity, summary, detail){
    this.messageService.add({severity:severity,summary:summary,detail:detail})
  }

  uploadStockBalanceFileFromPpos(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.openFileSelectorAndGetFile().pipe(
      switchMap(res=>{
        if(!res) throw 'An Unexpected Error Occurred'
        let data = {
          file: res
        }
        return this.dataMigrationService.uploadStockBalanceFileFromPpos(data)
      }),
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Balance Import - Upload Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      if(err==ERROR_CODE.IGNORE) return
      this.addMessage('error', this.title, err)
    })
  }
  stockBalanceFromPpos(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.stockBalanceFromPpos().subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Balance Import - Run Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportCompareQtyFile(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportCompareQtyFile().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'compareQty.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Balance Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportExceptionFile(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportExceptionFile().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'dataMigrationException.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Balance Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }

  uploadStockSerialNumberFileFromPpos(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.openFileSelectorAndGetFile().pipe(
      switchMap(res=>{
        if(!res) throw 'An Unexpected Error Occurred'
        let data = {
          file: res
        }
        return this.dataMigrationService.uploadStockSerialNumberFileFromPpos(data)
      }),
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Upload Success')
    }, (err:any)=>{
      this.loadingService.setLoadingBoolean(false)
      if(err==ERROR_CODE.IGNORE) return
      this.addMessage('error', this.title, err)
    })
  }
  stockSerialNumberFromPpos(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.stockSerialNumberFromPpos().subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Run Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportSerialItemSummary(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportSerialItemSummary().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'CSTK_Serial_item_summary.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportSerialNumberList(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportSerialNumberList().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'CSTK_Serial_number_list.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportSerialReconciliationReport(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportSerialReconciliationReport().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'CSTK_PPOS_Serial_reconciliation_report.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  exportSerialExceptionReport(){
    if(this.loading) return
    this.loadingService.setLoadingBoolean(true)
    this.dataMigrationService.exportSerialExceptionReport().pipe(
      switchMap(res=>{
        return CommonMethod.downloadFileFromResponse(res, 'CSTK_PPOS_Serial_exception_report.xlsx')
      })
    ).subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('success', this.title, 'PPOS Stock Serial Number Import - Download Report Success')
    }, err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }

  openFileSelectorAndGetFile(){
    let resolver
    let rejector
    let promise = new Promise((resolve,reject)=>{
      resolver = resolve; 
      rejector = reject;
    })
    var input = document.createElement('input');
    input.type = 'file';
    input.accept="text/csv"
    input.onchange = (e) => {
      var file = (<any>e.target).files[0]
      resolver(file)
    }
    input.oncancel = (e) =>{
      rejector(ERROR_CODE.IGNORE)
    }
    input.click();
    return from(promise)
  }

  getPPOSParams(){
    return this.commonService.getSysDefLookupByType("PPOS_PARAM").pipe(
      tap(res=>{
        res.forEach(e => {
          this.pposParams[e.code] = e.description
        });
      })
    )
  }

  refreshPposStatus(){
    this.loadingService.setLoadingBoolean(true)
    this.getPPOSParams().subscribe(res=>{
      this.loadingService.setLoadingBoolean(false)
    },err=>{
      this.loadingService.setLoadingBoolean(false)
      this.addMessage('error', this.title, err)
    })
  }
  getPermission(...e){
    this.setPermission()
  }
  setPermission(...e){
    let permissionService = this.permissionService
    if(permissionService.havePermission(FUNCTION_CODE.PPOS_DATA_MIGRATION_EDIT)){
      this.pposDataEditEnable=true;
    }
  }
}
