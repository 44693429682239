import { Component, Input, Output, EventEmitter } from '@angular/core';
/**
 * @description: 文本框组件
 * @author: 陈述承
 */
@Component({
  selector: 'app-textarea-cst',
  templateUrl: './textarea-cst.component.html',
  styleUrls:['./textarea-cst.component.css']
})
export class TextareaCustom {
    @Input() ipt
    @Input() style
    @Input() active=false
    @Output() change = new EventEmitter()
    onInput(v){
      console.log(v)
      event.stopPropagation();
      this.change.emit(v)
    }
}
