import { HttpHelper } from './../../util/HttpHelper';
import { Injectable } from '@angular/core';
import { URLDICT } from './../../config';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockTakeService {
  search(data) {
    return from(HttpHelper.post(URLDICT.STOCK_DEMAND_CYCLE_SEARCH, data))
  }
  getSerial(data) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_SEARCH_SERIAL, data))
  }
  getshowSerial(data) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_COMPARE_SERIAL, data))
  }
  appendSerial(data) {
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_APPEND_SERIAL, data))
    }))
  }
  deleteSerial(data) {
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_DELETE_SERIAL, data))
    }))
  }
  submitSerial(data) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_SUBMIT_SERIAL, data))
  }
  compareSerial(data) {
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_COMPARE_SERIAL, data))
    }))
  }
  compareAllSerial(data) {
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_COMPARE_ALL_SERIAL, data))
    }))
  }

  preStockTake(stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_PRE_STOCK_TAKE, {id: stockTakeId}))
  }

  markAsFirstCount(stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_MARK_AS_FIRST_COUNT, {id: stockTakeId}))
  }

  updateStockTakeStatus (stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_UPDATE_STATUS, {id: stockTakeId}))
  }

  searchDetailById(id){
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_SEARCH_DETAIL, {id: id}))
  }

  readyForStocktakeById(id){
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_READY, {id: id}))
  }

  saveStockTake(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_SAVE, data))
    }))
  }

  updateTakeLine(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_LINE_BATCH_UPDATE, data))
    }))
  }

  uploadEdmsFile(data:{[key:string]:string|Blob}):Observable<any>{
    let _data = new FormData();
    for (const [key, value] of Object.entries(data)) {
      _data.append(key,value)
    }
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.formPost(URLDICT.EDMS_UPLOAD, _data))
    }))
  }

  getEdmsFileIds(data):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.EDMS_GET_IDS, data))
    }))
  }

  getEdmsFile(edmsFileMappingId):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.postWithErrorHandle(URLDICT.EDMS_DOWNLOAD, {"edmsFileMappingId": edmsFileMappingId}))
    }), mergeMap((res:Response)=>{
      return from(res.blob())
    }))
  }

  deleteEdmsFIle(ids):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.EDMS_DELETE, {"edmsFileMappingIdsList": ids}))
    }))
  }

  readyFor2ndCount(stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_READY_SECOND_COUNT, {id: stockTakeId}))
  }

  save2ndSerial(stockTakeId){
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_SAVE_2ND_SERIAL, {id: stockTakeId}))
  }

  markAs2ndCount(stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_MARK_AS__SECOND_COUNT, {id: stockTakeId}))
  }
  backTo2ndCountDetail(stockTakeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_BACK_DETAIL, {id: stockTakeId}))
  }
  markAsAdjustInProgress(stockTakeId):Observable<any>{
    return of({}).pipe(mergeMap(res=>{
      return from(HttpHelper.post(URLDICT.STOCK_TAKE_ADJUST_IN_PROGRESS, {id: stockTakeId}))
    }))
  }
  getAllSerialByChannelItem(data) {
    return from(HttpHelper.post(URLDICT.QTY_ONHAND_SERIAL_SEARCH, data))
  }
  getAllSerialFromSnapshot(takeId) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_GET_SNAPSHOT_SERIAL_LIST, {id:takeId}))
  }
  cancelStockTake(data) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_GET_CANCEL_WITH_REASON, data))
  }
  getSnapshotLine(id) {
    return from(HttpHelper.post(URLDICT.STOCK_TAKE_GET_SNAPSHOT_LINE, {id: id}))
  }
}
