import { ConfirmationService, MessageService } from "primeng/api";
import { Component } from "@angular/core";
import { CommonService } from 'src/app/service/common/common-service';
import { HttpHelper } from "@/util/HttpHelper";
import { URLDICT } from "@/stores/base/BaseStore";

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.css"],
})
export class ChangepasswordComponent {
  constructor(
    private msg: MessageService,
    public confirmationService: ConfirmationService,
    public commonService: CommonService,
  ) {}

  public oldPassword='';
  public visible
  public confrimpassword='';
  public loading = false;
  public newPassword='';
  public accountName = this.commonService.getAccount().toString()
  confirm() {
    if(this.oldPassword.trim().length==0||this.newPassword.trim().length==0||this.confrimpassword.trim().length==0){
      this.msg.add({
        severity: "error",
        summary: "Error Message",
        detail: "Please enter old password/new password/confirm password",
      });
      return;
    }
    if (this.newPassword != this.confrimpassword) {
      this.msg.add({
        severity: "error",
        summary: "Error Message",
        detail: "Password not match confirm password",
      });
      return;
    } else {
      HttpHelper.post(URLDICT.CHANGE_PASSWORD, 
        {account: this.accountName,oldPassword: this.oldPassword,newPassword: this.newPassword}).then(
          res => {
            if (res.msg=="0") {
              this.msg.add({
                severity: "error",
                summary: "Error Message",
                detail: "Old password not match",
              });
            } else {
              this.msg.add({
                severity: "success",
                summary: "success",
                detail: "Password changed",
              });
            }

          }).catch(e => {
            console.error(e)
          })
    }
  }
}
