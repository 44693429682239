import { SerialScanAllCstComponent } from '@/pages/base/serial-scanall-cst/serial-scanall-cst.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgxFileDropComponent } from 'ngx-file-drop';
import { TakeEditStore } from 'src/app/stores/stock/take/takeEditStore';

@Component({
  selector: 'app-edit',
  providers: [TakeEditStore],
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  @ViewChild('serialcst') serialcst;
  @ViewChild('serialscanallcst') serialscanallcst: SerialScanAllCstComponent;
  @ViewChild('fileDropper') fileDropper: NgxFileDropComponent;
  constructor(
    public store: TakeEditStore, 
    public elRef: ElementRef,
  ) {}
  ngAfterViewInit(){
    this.store.elRef = this.elRef;
    this.store.serialcst = this.serialcst;
    this.store.setSerialscanallcst(this.serialscanallcst);
    this.store.fileDropper = this.fileDropper;
  }
}
