import { action, observable } from 'mobx';
import { CommonMethod } from 'src/app/util/CommonMethod';
import { DEFAULTVALUE, INPUT_TYPE, BaseStore, NOTI_TITLE, URLDICT } from './BaseStore'

export class BasePannel {

    @observable msgs = [] // 错误信息数据
    @observable ipts=[]  // 输入框组（页面展示）
    @observable btns=[]  // 按钮组
    @observable btnsclass = ""  // 按钮组样式
    @observable show:boolean = true //面板是否显示
    initIpts = [] //store传入的输入框

    constructor(ipts, btns, btnsclass=''){
        this.ipts = ipts
        this.btns = btns
        this.btnsclass = btnsclass
        this.initIpts = ipts
    }

    /**
     * 设置某个按钮对应键的值
     * @param index 按钮索引
     * @param k 键
     * @param v 值
     */
    @action setBtnProperty(idx,k,v){
        this.btns[idx][k] = v
    }
    /**
     * 设置某个输入框对应键的值
     * @param index 输入框索引
     * @param k 键
     * @param v 值
     */
    @action setIptProperty(idx,k,v){
        this.ipts[idx][k] = v
    }

    /**
     * 格式化输入框组成JSON对象（适用于动态表单）
     */
    @action twoDimArrFormat(){
        //let obj = {}
        let adc = []
        this.ipts.forEach(arr=>{
            let row = {}
            arr.forEach(ipt=>{
                if(ipt.value.length>0||ipt.value){
                    let obj = {}
                    obj[ipt.name] = []
                    obj[ipt.name+"LineId"] = []
                    if(ipt.type == 10){
                        if(ipt.lineId){
                            obj[ipt.name+"LineId"] = ipt.lineId
                        }

                        ipt.value.forEach(val=>{
                            //去掉mutiply select 带入的空值
                            if(val){
                                console.log("vvvvvv")
                                console.log(obj[ipt.name])
                                obj[ipt.name].push(val)
                            }
                        })
                    }else{
                        obj[ipt.name] = ipt.value
                    }
                    //将mutiply select中的每个值都拆成一行
                    if(ipt.type != 10){
                        row = Object.assign(row,obj)
                    }else{
                        obj[ipt.name].forEach((res,i)=>{
                            var id = ""
                            console.log(obj[ipt.name+"LineId"][i])
                            if(obj[ipt.name+"LineId"][i]){
                                id = obj[ipt.name+"LineId"][i]
                            }
                            let one = {
                                arrval:res,
                                lineId:id
                            }
                            adc.push(Object.assign(one,row))
                        })
                    }
                }

            })
        })
        console.warn(`Object After Format:`);
        console.warn(adc);
        console.warn(`===================`);
        return adc
    }



    /**
     * 格式化输入框组成JSON对象
     * format this.ipts into k-v object
     */
    @action format(){
      let obj:any = {}, isObj
      this.ipts.forEach(ipt => {
        if(ipt.nickName) {
          isObj = Object.prototype.toString.call(ipt.value)
          if (isObj === "[object Object]") {
            obj[ipt.nickName] = ipt.value?.code || ipt.value.data || ipt.value;
            if (ipt.nickName === "orderStatusCode") {
              obj[ipt.nickName] = ipt.value
            }
          } else if (isObj === "[object Date]") {
            obj[ipt.nickName] = ipt.value.getTime()
          } else {
            obj[ipt.nickName] = ipt.nickName === "createAt" ? 0 : ipt.value || null;
          }
        } else {
          if(ipt.type === INPUT_TYPE.SWITCH) {
            obj[ipt.name] = ipt.value
          } else if(ipt.value) {
            // if(ipt.type == 10 || (ipt.type == 3 && ipt.multiple)){
            if(ipt.type === INPUT_TYPE.MUTIPLESELECT || (ipt.type === INPUT_TYPE.TREESELECT && ipt.multiple)) {
              obj[ipt.name] = []
              ipt.value.forEach(val => {
                //去掉mutiply select 带入的空值
                if(val) {
                  obj[ipt.name].push(val)
                }
              })
            } else {
              if( ipt.type === INPUT_TYPE.TREESELECT) {
                isObj = Object.prototype.toString.call(ipt.value) === '[object Object]'
                obj[ipt.name] = isObj ? (ipt.value?.code || ipt.value?.data) : ipt.value
              } else {
                obj[ipt.name] = ipt.value
                // console.log(ipt.name, ipt.value);
              }
            }
          }
          // if(ipt.type == "13"){
          if(ipt.type === INPUT_TYPE.SELECTWITHTABLEMUTI) {
            let attrValueList = []
            let attrs = []
            ipt.tableData.forEach(i => {
              attrs.push(i.linkValue)
              attrValueList.push(i.value)
            })
            obj.attrValueList = attrValueList
            obj.attrs = attrs
          }
          if(ipt.type === INPUT_TYPE.CHECKBOX) {
            obj[ipt.name] = ipt.value && ipt.value.length ?  1 : 0
          }
        }

        if(ipt.beforeSubmit){
          obj[ipt.name] = ipt.beforeSubmit(obj[ipt.name], ipt)
        }
      })
      console.warn(`Object After Format:`);
      console.warn(obj);
      console.warn(`===================`);

      return obj
    }

    /**
     * 清理输入框组值
     * @param obj the EnqueryPannel Object
     * @param str clear with which string
     */
    @action clear(){
        this.msgs = []
        this.ipts.forEach(ipt=>{
          if(!ipt.disabled){
            ipt.value = null
                if(ipt.type == INPUT_TYPE.INPUT){
                  ipt.value=DEFAULTVALUE.INPUT_DEFAULT
                }else if(ipt.type==INPUT_TYPE.SELECT || ipt.type==INPUT_TYPE.SELECTDISPLAYINPUT ){
                  ipt.value=DEFAULTVALUE.SELECT_DEFAULT
                }else if(ipt.type==INPUT_TYPE.TEXTAREA){
                  ipt.value=DEFAULTVALUE.TEXTAREA_DEFAULT
                }else if(ipt.type == INPUT_TYPE.TREESELECT || ipt.type == INPUT_TYPE.MUTIPLETREESELECT){
                  ipt.value=DEFAULTVALUE.TREESELECT_DEFAULT
                  ipt.showValue=DEFAULTVALUE.TREESELECT_DEFAULT
                  ipt.selValue=DEFAULTVALUE.TREESELECT_DEFAULT
                }else if(ipt.type == INPUT_TYPE.DATETIME){
                  ipt.value = null
                }else if(ipt.type == INPUT_TYPE.DATERANGE){
                  ipt.value=DEFAULTVALUE.DATERANGE_DEFAULT
                }else if(ipt.type == INPUT_TYPE.AUTOCOMPLETE){
                  ipt.value=DEFAULTVALUE.AUTOCOMPLETE_DEFAULT
                }else if(ipt.type== INPUT_TYPE.MUTIPLESELECT){
                  ipt.value = DEFAULTVALUE.MUTIPLESELECT_DEFAULT
                }else if(ipt.type== INPUT_TYPE.INPUTNUMBER){
                  ipt.value = DEFAULTVALUE.INPUTNUMBER_DEFAULT
                }else if(ipt.type== INPUT_TYPE.SELECTWITHDESC){
                  ipt.value = DEFAULTVALUE.SELECTWITHDESC_DEFAULT
                }else if(ipt.type== INPUT_TYPE.SELECTWITHTABLEMUTI){
                  ipt.tableMultiData = DEFAULTVALUE.SELECTWITHTABLEMUTI_DEFAULT
                }
                if(ipt.name === 'fromStatusNature' || ipt.name === 'toStatusNature'){
                  ipt.options =  []
                }
            }
        })
    }
    getLinkageData(obj){
      if(obj.params.orderTypeId && (obj.params.fromChannelId || obj.params.toChannelId)){
        new BaseStore(null).$HTTP.post(obj.url, obj.params).then(
          res => {
            let selObj = this.ipts.find((item) => item.title === obj.name)
            console.log(res, selObj)
            selObj.options = res.data.map((item) => {
              if (typeof(item) === 'object')
                return {code: item.code?item.code:item.id, name: item.description?item.description:item.name}
              else
                return {code: item, name: item}
            });
            if(res.data.length === 0){
              selObj.value = undefined
            }
          }
        ).catch(e => console.log(e))
      }else{
        /* let selObj = this.ipts.find((item) => item.title === obj.name);
        selObj.options = null */
      }
    }
    linkageAll(data){
      const from = this.ipts.find((item) => item.title === 'From')
      this.linkageFromStatusNature(from)
      const to = this.ipts.find((item) => item.title === 'To')
      this.linkageToStatusNature(to)
    }
    linkageFromStatusNature(data){
      const orderTypeId = this.ipts[0].value
      const fromChannelId = data.value?.data
      const obj = {
        params: { orderTypeId, fromChannelId },
        url: URLDICT.STOCK_TRANSFER_GETFROMNATURE,
        name: data.title + ' Status Nature'
      }
      this.getLinkageData(obj)
    }
    linkageToStatusNature(data){
      const orderTypeId = this.ipts[0].value
      const toChannelId = data.value?.data
      const obj = {
        params: { orderTypeId, toChannelId },
        url: URLDICT.STOCK_TRANSFER_GETTONATURE,
        name: data.title + ' Status Nature'
      }
      this.getLinkageData(obj)
    }
    linkage(data){
      // console.log('----- linkage -----', data, this.ipts);
      // tslint:disable-next-line: no-unused-expression
      data.linkage && this['linkage' + data.linkage](data)

    }
    /**
     * 改变某个输入框 对应键的值（默认键为value）
     * @param idx which ipt in ipts need to change value
     * @param val value of user input
     * @param key which key of ipt to change,default key is 'value'
     */
    @action change(idx, val, target, key='value'){
        if(val instanceof Event) return
        console.log("====================");
        console.log(`[ipts changed]=>${idx}|${key}|${val}`, val,target);
        console.log("====================");
        target.value = val
        this.ipts[idx][key] = val
        // console.log(target.type, this.ipts);
        // 针对Stock Transaction模块的处理
        // tslint:disable-next-line: no-unused-expression
        target.linkage && this.linkage(target)
      //清除错误提示
      if(this.ipts[idx].error){
        this.ipts[idx].error = ''
        this.ipts[idx].errorInfo = ''
      }
      // console.log('>------------', target.type === INPUT_TYPE.MUTIPLESELECT)
      //这里是为了清除treeSelect中的ALL项（当选中了ALL之外的项，需移除ALl）
      if (target.type === INPUT_TYPE.MUTIPLESELECT) {
        if(Array.isArray(this.ipts[idx][key])){
          const len = this.ipts[idx][key].length
          this.ipts[idx][key] = len > 0 ?(this.ipts[idx][key][len - 1] === '' ? [""] : this.ipts[idx][key].filter((item) => item)) : [""];
        }
      }
      if (target.type === INPUT_TYPE.MUTIPLETREESELECT) {
        // console.log('>--- mutiple tree select', this.ipts[idx].selValue);

        /* if(Array.isArray(this.ipts[idx][key])){
          const len = this.ipts[idx][key].length
          this.ipts[idx][key] = len > 0 ?(this.ipts[idx][key][len - 1] === '' ? [""] : this.ipts[idx][key].filter((item) => item)) : [""];
        } */
      }
      //加载根据所选项查询的值
      if(this.ipts[idx].loadUrl){
        this.loadData(this.ipts[idx])
      }
      //联动控制页面组件 channel to item
      this.ipts[idx].linkageToItem && this.linkageToItem(this.ipts[idx])

      //联动控制页面组件
      if(this.ipts[idx].isLinkageOther&&this.ipts[idx].isLinkageOther==true){
        this.linkageOther(this.ipts[idx])
      }
      if(this.ipts[idx].linkageOther&&this.ipts[idx].linkageOther==true){
        this.linkOther(this.ipts[idx],idx)
      }
        //弹出SELECTWITHDESC
      if(this.ipts[idx+1] && this.ipts[idx+1].type == INPUT_TYPE.SELECTWITHDESC){
        this.openDes(this.ipts[idx+1],val,idx)
      }
      //弹出SELECTWITHTABLEMUTI
      if(this.ipts[idx+1] && this.ipts[idx+1].type == INPUT_TYPE.SELECTWITHTABLEMUTI){
        this.openDesMul(this.ipts[idx+1],val)
      }
      //reject 必填remark
      if(this.ipts[idx].title=="ApproveOrReject"&&this.ipts[idx].value=="APPROVED"){
        this.ipts[idx+1]["required"]=false;
      }
      //根据类型设置to
      if(this.ipts[idx].OutToType){
        //选完shop to 后修改type,清除shop to
        this.ipts[idx+1]["value"] = null
        //更新shop to  options
        if(this.ipts[idx].value=="S"){
          this.ipts[idx+1]["options"]=new BaseStore(null).$CACHE.getObject("SHOP")
          this.ipts[idx+1]["disabled"] = false
        }else if(this.ipts[idx].value=="W"){
          this.ipts[idx+1]["options"]=new BaseStore(null).$CACHE.getObject("WAREHOUSE")
          this.ipts[idx+1]["disabled"] = false
        }else{
          this.ipts[idx+1]["disabled"] = true
        }
        let repoTo=[]
        this.ipts[idx+1]["options"].forEach(arr => {
          if(arr.value!=this.ipts[idx-1].value) { repoTo.push(arr) }
        });
        this.ipts[idx+1]["options"]=repoTo

      }
      // 根据REPO from设置to
      if(this.ipts[idx].repoOutTo){
        this.ipts[idx+1]["disabled"] = false
        //选完shop to 后修改from,若值相同则清除,并更新shop to option
        if(this.ipts[idx].value==this.ipts[idx+2].value){
            this.ipts[idx+2]["value"] = null
        }
        if(this.ipts[idx+1].value=="S"){
              this.ipts[idx+2]["options"]= new BaseStore(null).$CACHE.getObject("SHOP")
          }else if(this.ipts[idx+1].value=="W"){
              this.ipts[idx+2]["options"]= new BaseStore(null).$CACHE.getObject("WAREHOUSE")
          }else{
              this.ipts[idx+2]["disabled"] = true
          }
          let shopTo=[]
          this.ipts[idx+2]["options"].forEach(res => {
            if(res.value != this.ipts[idx].value) { shopTo.push(res) }
          });
          this.ipts[idx+2]["options"]=shopTo
      }
      //根据rightType判断是否需要填url和权限标识
      if (this.ipts[idx]["type"] == INPUT_TYPE.SELECTDISPLAYINPUT) {
        this.ipts = this.initIpts
        let inputDisplay = this.ipts[idx]["inputDisplay"]
        if(this.ipts[idx]["inputDisplay"] && inputDisplay[val]){
            this.ipts = this.ipts.filter(ipt=>inputDisplay[val].indexOf(ipt.name)==-1)
          }
          this.ipts[idx][key] = val
      }
      //日期选择器输出 时间转换时间戳 并去掉时分秒
      if(this.ipts[idx]["type"] == INPUT_TYPE.DATETIME){
        let tempTimestamp = Date.parse(val)
        let newDate = CommonMethod.timestampToTime(tempTimestamp)
        let timestamp = Date.parse(newDate)
        this.ipts[idx][key] = timestamp
      }

        //去重验证
        this.repeatCheck(this.ipts[idx],idx)
        // 触发验证
        this.validate(this.ipts[idx])
        //检测必填项
        this.required(this.ipts)

      if(key=='value' && target.change){
        /**
         * in some case need to pass value change event to component / store,
         * basePanel not accepting queryPanel.change, block at QueryPannelStore constructor
         * pass change callback through ipt.change
         */
        target.change(val, target)
      }
    }
    @action  linkageToItem(ipt) {
      // console.log('----- linkage data to item', ipt);
      const skus = []
      new BaseStore(null).$HTTP.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, {"dtlRepoId":ipt.value?.id}).then(res => {
        if(res.code ==='000' && res.data.length > 0){
          res.data.forEach(skurepo=>{
            const name = skurepo.skuDesc ? skurepo.skuCode +' ~ ' + skurepo.skuDesc : skurepo.skuCode
            skus.push({code: skurepo.dtlSkuId, other: null, name})
          })
        }
        const target = this.ipts.find(item=>item.title === 'Item')
        // console.log(res.data, target, skus);

        target.options = skus
      }).catch(e => console.log(e)).finally(() => console.log('finally'))
    }
    /**联动控制组件**/
    @action linkOther(ipt,idx){
      this.ipts[idx+1].value = null
      delete this.ipts[idx+2].descriptionsData
      let verArr:any = [];
      let specArr:any = new BaseStore(null).$CACHE.getObject("SPEC_VERSION")
      specArr.forEach(d=>{
        if(d.label == ipt.value){
          d.value = d.other
          d.label = d.other
          verArr.push(d)
        }
      })
      this.ipts[idx+1].options = verArr
      new BaseStore(null).$CACHE.setObject("SPECVER",verArr)
    }

    /**联动控制组件**/
    @action linkageOther(sourceTgt) {
        this.ipts.forEach(ipt =>{
          if(sourceTgt.type==INPUT_TYPE.SELECT){

            if(ipt.type==INPUT_TYPE.TEXTAREA){
                if(sourceTgt.value==0){
                  ipt.value = ''
                  ipt.placeholder = 'custom'
                  ipt.disabled = false
                } else {
                  let ajReasons:any[] = new BaseStore(null).$CACHE.getObject(ipt.cacheName)
                  if(ajReasons.length > 0){
                    ajReasons.forEach(reason => {
                      if (sourceTgt.value==reason.value) {
                        ipt.value = reason.other
                        ipt.placeholder = ''
                        ipt.disabled = true
                      }
                    })
                  }
                }
            }

            if(ipt.type==INPUT_TYPE.INPUT&&ipt.valueUse){
              if(ipt.valueUse==sourceTgt.value){
                //ipt.value=''
                ipt.disabled=false
                ipt.required=true
              } else {
                ipt.value=''
                ipt.disabled=true
                ipt.required=false
              }
            }
          }
        })
    }

  /**
   * 根据下拉框联动加载数据并赋值
   */
  @action loadData(tagIpt) {
    let target = {}
    let nullSize = 0

    this.ipts.forEach(ipt => {if(ipt.loadKey) target = ipt})

    // tslint:disable-next-line: forin
    for(let paKey in  tagIpt.loadParam) {
      this.ipts.forEach(
        ipt => {
          if(ipt.valuePointer==paKey) {
            if(ipt.value==''||ipt.value==null) {
              nullSize++
              target['value'] = 0
              return
            }
            tagIpt.loadParam[paKey]=ipt.value
            if(ipt.name=='dtlSkuName'&&ipt.type==INPUT_TYPE.AUTOCOMPLETE){
              new BaseStore(null).$CACHE.getObject("SKU").forEach(sku=>{
                if(ipt.value==sku.label){
                  tagIpt.loadParam[paKey] = sku.value
                }
              })
            }
          }
        }
      )
      if(tagIpt.loadParam[paKey]=='') nullSize++
    }
    if(nullSize<=0){
      new BaseStore(null).$HTTP.post(tagIpt.loadUrl,tagIpt.loadParam).then(
        res => {
          if(res.code=='000' && res.state=="success" ){
            // console.log(res)
            if(res['data'][0]){
              if(target['productQty']){
                let proQty= new BaseStore(null).$CACHE.getObject("/STOCK/OUT/PRODUCTDATA").reduce((prev,cur)=>{
                  return parseInt(cur.dtlQty) + parseInt(prev);
                },0)
                target['value'] = res['data'][0][target['loadKey']]-proQty
              }else{
                target['value'] = res['data'][0][target['loadKey']]
              }
            }
            else target['value'] = 0
          } else {
            target['value'] = 0
            // console.log(res)
          }
        }
      ).catch(e => console.log(e))
    }
  }

  /**
   * 下拉框选择 触发change事件 调用此方法为打开的弹框准备数据
   * @param ipt
   * @param id
   */
  @action openDes(ipt,ver,idx){
      let val = this.ipts[idx-1].value
      let bs =  new BaseStore(null)
      let id = ""
        bs.$CACHE.getObject("SPEC_VERSION").forEach(d=>{
      if(d.other == ver && d.label == val){
        id = d.value
      }
      })
      if(id == ""){
        delete ipt.descriptionsData
      }else{ bs.$HTTP.post(ipt.api, id)
        .then(res => {
          if(res.data && res.data.length > 0){
            ipt.descriptionsData=res.data
          }else{
            delete ipt.descriptionsData
          }
        })
      .catch(err => {
        bs.$NOTICE.error(NOTI_TITLE, err)
      })}

  }

  /**
   * 为打开选择项弹出的多选框准备数据
   * @param ipt
   * @param id
   */
  @action openDesMul(ipt,id){
    let bs =  new BaseStore(null)
    let obj:any = {}
    obj.id = id
    if(id == null){
      delete ipt.tableData
      ipt.colon=true
      ipt.title=""
    }else{
      bs.$HTTP.post(ipt.api, obj)
        .then(res => {
          if(res.data && res.data.length > 0){
            ipt.tableMultiData=res.data[0]["tableMutiData"]
            ipt.tableData = res.data[0]["tableMutiData"]["tableData"]
            ipt.colon=false
            ipt.title="Default Spec"
          }else{
            delete ipt.tableData
            ipt.colon=true
            ipt.title=""
          }
        })
        .catch(err => {
          bs.$NOTICE.error(NOTI_TITLE, err)
        })
    }

  }


  @action changeTableMuti(idx,val,key='value'){
    if(val instanceof Event) return
    console.log("====================");
    console.log(`[ipts changed]=>${idx}|${key}|${val}`);
    console.log("====================");
    this.ipts.forEach(ipt=>{
      if(ipt.type == INPUT_TYPE.SELECTWITHTABLEMUTI){
        ipt.tableData[idx][key] = val
      }
    })
    //去重验证
    this.repeatCheck(this.ipts[idx],idx)
    // 触发验证
    this.validate(this.ipts[idx])
    //检测必填项
    this.required(this.ipts)
  }
    /**
     *
     * @param ipt
     */
    @action validate(ipt) {
        if (ipt.required && ipt.validate) {
            let regexs = ipt.validate
            regexs.forEach(reg => {
                let index = -1
                //验证类型是默认值时不需要验证
                if (reg.type != 3) {
                    //改变后的值是否符合正则表达式
                    if (!(reg.regex.test(ipt.value)) ) {
                        /**
                         * 改变后的值不符合正则并且没有提示信息，则存入提示信息，
                         * 同一个页面不同字段不能有相同的msg，在msg前加ipt.title
                         */
                        //重要：不要与上面if合并，防止第二次输入也是错误的值
                        if(this.msgs.indexOf(reg.msg) == -1)
                          this.msgs.push(reg.msg)
                    } else if ((index = this.msgs.indexOf(reg.msg)) > -1) {
                        //匹配出符合表达式值的msg位置并删除
                        this.msgs.splice(index, 1)
                    }
                }
            })
        }

    }
    /**
     * 统计没有value的必输项，将第一个没有填的元素存入提示信息
     * @param store
     */
    @action required(ipts) {
        let unobs = []
        let index = -1
        ipts.forEach(ipt => {
            //必填项未填
            if (ipt.required && (!ipt.value || ipt.value.length<=0)) {
                unobs.push("Please Enter " + ipt.title)
            } else if (ipt.required
                && ipt.value
                && (index = this.msgs.indexOf("Please Enter " + ipt.title)) > -1) {
                //之前未填的项，填入值后删除提示信息
                this.msgs.splice(index, 1)
            } else if(!ipt.required && (index = this.msgs.indexOf("Please Enter " + ipt.title)) > -1){
              //之前必填的项变为非必填
              this.msgs.splice(index, 1)
            }
        })
        //存在必填项未填且没有存入提示信息
        if (unobs.length > 0 && !(this.msgs.indexOf(unobs[0]) > -1)) {
            this.msgs.push(unobs[0])
        }
    }
    /**
     * 根据页面有无提示信息返回提交状态
     */
    @action isSubmitAvailable() {

        // let flag = false
        // if (this.msgs.length) {
        //     flag = true
        // }
        // return flag
        // console.log(this.msgs.length);

        return this.msgs.length>0?false:true
    }

    /**
     * 是否重复校验
     * @param ipt
     */
    @action repeatCheck(ipt,idx){
        if(ipt.repck){
            let baseStore = new BaseStore(null);
            let cacheArr = baseStore.$CACHE.getObject(ipt.repckCache)
            let flag = false
            cacheArr.forEach(item=>{
              if(ipt.repckCache != 'ATTRVALUE' && item.label == ipt.value){
                flag = true
              }
              if(ipt.repckCache == 'ATTRVALUE' && item.label == ipt.value
                || item.label == (ipt.value+"~"+ (this.ipts[idx+1] ? this.ipts[idx+1].value : ""))
                || item.label == ((this.ipts[idx-1] ? this.ipts[idx-1].value : "") +"~"+ipt.value)){
                flag = true
              }
            })
            if(flag && this.msgs.indexOf(ipt.title+" is repeat!") == -1){
                this.msgs.push(ipt.title+" is repeat!")
            }
            if(!flag){
                this.msgs = []
            }


        }
    }


}


