import { Component } from '@angular/core';
import { DemoStore } from 'src/app/stores/stock/balance/DemoStore';

@Component({
  selector: 'app-demo',
  providers: [DemoStore],
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent {

  constructor(public store: DemoStore) { }

}
