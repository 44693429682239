import { Pipe, PipeTransform } from '@angular/core';
import { CommonMethod } from 'src/app/util/CommonMethod';

@Pipe({
  name: 'toType'
})
export class ToTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const [name] = args
    // console.log(name);
    return CommonMethod.toSelectedLabel(name, value);
  }

}
