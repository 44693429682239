import { observable } from 'mobx';
import { BasePannel } from './BasePannel';




/**
 *  class for InputPannelStore
 *  输入面板
 */
export class InputPannelStore extends BasePannel{
    constructor(ip){
        super(ip.ipts,ip.btns,ip.btnsclass)
        this.collapse = ip.collapse
    }
@observable collapse

}
