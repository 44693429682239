import { LocalStorageHelper } from "../../util/LocalStorageHelper";
import { MENU } from "../../config";
export class MenuStore {
  // The module mapping
  private moduleMapping = {
    1: 'STOCK_BALANCE',
    2: 'TRANSACTION_HISTORY',
    3: 'STOCK_TAKE',
    4: 'RESERVATION',
    5: 'ALLOCATION',
    6: 'STOCK_TRANSACTION',
    7: 'DEMAND_CYCLE',
    8: 'REPORT',
    9: 'ITEM_MASTER',
    10: 'STOCK_BACKEND_TRANSACTION',
    11: 'DOC_CAL_FACTOR',
    12: 'CROSS_SHOP_QUOTA',
    13: 'STOCK_AVAILABLE_LEVEL',
    14: 'TABLES_MAINT_CONFIG',
    15: 'ITEM_TRANSFER_QUOTA',
    16: 'ROLE_FUNCT_MAP',
    17: 'USER_ROLE_MAP',
    18: 'FUNCTION_CONFIG',
    19: 'ROLE_CONFIG',
    101: 'PPOS_STOCK_MOVT_HISTORY',
    131: 'EXT_API_REQUEST_LOG',
    132: 'API_CALL_LOG',
    133: 'CHANGE_PASSWORD'
  }
  private menu = [
    {
      visible: false,
      name: "stock",
      label: MENU.STOCK,
      items: [
        {
          visible: true,
          category: MENU.CATEGORY.ENQUIRIES,
          name: "STOCK_BALANCE",
          label: MENU.STOCK_BALANCE,
          routerLink: ["/main/stock_balance/search"],
          children: [{ visible: true, routerLink: ["demo"] },],
        },
        {
          visible: true,
          category: MENU.CATEGORY.ENQUIRIES,
          name: "TRANSACTION_HISTORY",
          label: MENU.STOCK_TRANSACTION_HISTORY,
          routerLink: ["/main/stock_movement/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.ENQUIRIES,
          name: "PPOS_STOCK_MOVT_HISTORY",
          label: MENU.STOCK_MOVEMENT_HISTORY,
          routerLink: ["/main/stock_ppos/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_REPLENISHMENT,
          name: "DEMAND_CYCLE",
          label: MENU.DEMAND_CYCLE,
          routerLink: ["/main/demand_cycle/search"],
          children: [
            { visible: true, routerLink: ["create"] },
            { visible: true, routerLink: ["createspreadsheet"] },
            { visible: true, routerLink: ["edit"] },
            {
              visible: true,
              routerLink: ["demand"],
              children: [
                { visible: true, routerLink: ["search"] },
                { visible: true,routerLink: ["create"] },
                // { visible: true, routerLink: ["edit"] },
                // { visible: true, routerLink: ["upload"] }
              ],
            },
            {
              visible: true,
              routerLink: ["replenishment"],
              children: [
                { visible: true, routerLink: ["search"] },
                { visible: true, routerLink: ["create"] },
                { visible: true, routerLink: ["edit"] },
                { visible: true, routerLink: ["detail"] },
              ],
            },
          ],
        },
        /* {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_REPLENISHMENT,
          name: "demand",
          label: "",
          routerLink: ["/main/demand/search"],
          children: [
            { visible: true, routerLink: ["create"] },
            { visible: true, routerLink: ["edit"] },
          ],
        }, */
        /* {
          visible: true,
          category: MENU.CATEGORY.STOCK_ASSORTMENT,
          name: "request",
          label: MENU.STOCK_REQUEST,
          routerLink: ["/main/stock_request/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.STOCK_ASSORTMENT,
          name: "allocation",
          label: MENU.STOCK_ALLOCATION,
          routerLink: ["/main/stock_allocation/search"],
          children: [
            { visible: true, routerLink: ["detail"] },
            { visible: true, routerLink: ["create"] }
          ]
        }, */
        {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_OPERATIONS,
          name: "STOCK_TAKE",
          label: MENU.STOCK_TAKE,
          routerLink: ["/main/stock_take/search"],
          children: [
            { visible: true, routerLink: ["check"] },
            { visible: true, routerLink: ["detail"] },
            { visible: true, routerLink: ["create"] },
            { visible: true, routerLink: ["edit"] },
          ],
        },
        {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_OPERATIONS,
          name: "RESERVATION",
          label: MENU.STOCK_RESERVATION,
          routerLink: ["/main/stock_reservation/search"],
          children: [{ visible: true, routerLink: ["create"] }],
        },
        {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_OPERATIONS,
          name: "ALLOCATION",
          label: MENU.STOCK_ALLOCATION,
          routerLink: ["/main/stock_allocation/search"],
          children: []
        },
        {
          visible: true,
          category: MENU.CATEGORY.CHANNEL_OPERATIONS,
          name: "STOCK_TRANSACTION",
          label: MENU.STOCK_TRANSACTION,
          routerLink: ["/main/transfer_order/search"],
          children: [
            { visible: true, routerLink: ["create"] },
            { visible: true, routerLink: ["edit"] },
            { visible: true, routerLink: ["allocate_create"] } // auto-allocate
          ],
        },
        // {
        //   visible: true,
        //   category: MENU.CATEGORY.REPORTS,
        //   name: "REPORT",
        //   label: MENU.STOCK_DAILY_TRANSACTION_HISTORY_SUMMARY_REPORT,
        //   routerLink: ["/main/transaction_summary/search"],
        //   children: [],
        // },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "ITEM_MASTER",
          label: MENU.STOCK_ITEM_MASTER,
          routerLink: ["/main/item_master/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "TRANSACTION_LIS_INTERFACE",
          label: MENU.STOCK_TRANSACTION_LIS_INTERFACE,
          routerLink: ["/main/lis_interface/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "RESERVATION_INTERFACE",
          label: MENU.RESERVATION_INTERFACE,
          routerLink: ["/main/reservation_interface/search"],
          children: [],
        },
        // {
        //   visible: true,
        //   category: MENU.CATEGORY.SYSTEM,
        //   name: "STOCK_BACKEND_TRANSACTION",
        //   label: MENU.STOCK_BACKEND_TRANSACTION,
        //   routerLink: ["/main/transaction_backend/create"],
        //   children: [],
        // },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "QTY_ONHAND_SERIAL",
          label: MENU.QTY_ONHAND_SERIAL,
          routerLink: ["/main/qty_onhand_serial/search"],
          children: [],
        },{
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "API_CALL_LOG",
          label: MENU.API_CALL_LOG,
          routerLink: ["/main/api_call_log/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "EXT_API_REQUEST_LOG",
          label: MENU.EXT_API_REQUEST_LOG,
          routerLink: ["/main/ext_api_request_log/search"],
          children: [],
        },
        {
          visible: true,
          category: MENU.CATEGORY.SYSTEM,
          name: "PPOS_DATA_MIGRATION",
          label: MENU.PPOS_DATA_MIGRATION,
          routerLink: ["/main/ppos_data_migration"],
          children: [],
        },
      ],
    },
    {
      visible: false,
      name: "config",
      label: MENU.CHANNEL_ITEM_CONTROL,
      items: [
        {
          visible: true,
          name: "DOC_CAL_FACTOR",
          label: MENU.CIC_DOC_CALCULATION_FACTOR,
          routerLink: ["/main/day_of_cover/search"],
          children: [],
        },
        {
          visible: true,
          name: "CROSS_SHOP_QUOTA",
          label: MENU.CIC_CROSS_SHOP_PICKUP_QUOTA,
          routerLink: ["/main/cross_shop_pickup_quota/search"],
          children: [],
        },
        {
          visible: true,
          name: "STOCK_AVAILABLE_LEVEL",
          label: MENU.CIC_STOCK_AVAILABILITY_LEVEL,
          routerLink: ["/main/stock_availability_level/search"],
          children: [],
        },
        {
          visible: true,
          name: "TABLES_MAINT_CONFIG",
          label: MENU.CIC_CONFIGURATION_TABLES_MAINTENANCE,
          routerLink: ["/main/configuration_tables_maintenance"],
          children: [],
        },
        {
          visible: true,
          name: "ITEM_TRANSFER_QUOTA",
          label: MENU.CIC_ITEM_TRANSFER_QUOTA,
          routerLink: ["/main/item_transfer_quota/search"],
          children: [{ visible: true, routerLink: ["edit"] }],
        },
      ],
    },
    {
      visible: false,
      name: "uam",
      label: MENU.SECURITY,
      items: [
        {
          visible: true,
          name: "ROLE_FUNCT_MAP",
          label: MENU.SECURITY_ROLE_FUNCTION_MAPPING,
          routerLink: ["/main/uam_role_mapping/search"],
          children: [],
        },
        {
          visible: true,
          name: "USER_ROLE_MAP",
          label: MENU.SECURITY_USER_ROLE_MAPPING,
          routerLink: ["/main/uam_user_role/search"],
          children: [],
        },
        {
          visible: true,
          name: "FUNCTION_CONFIG",
          label: MENU.SECURITY_FUNCTION,
          routerLink: ["/main/uam_access_function/search"],
          children: [],
        },
        {
          visible: true,
          name: "ROLE_CONFIG",
          label: MENU.SECURITY_ROLE,
          routerLink: ["/main/uam_role/search"],
          children: [],
        }
      ],
    },
    
    { visible: true, category: MENU.STOCK, name: "cas-appointment", items: [], label: MENU.STOCK, routerLink: ["/main/stock/cas-appointment"], children: [], },
    { visible: true, category: MENU.CATEGORY.CHANNEL_OPERATIONS, name: "reservation", items: [], label: MENU.STOCK_RESERVATION, routerLink: ["/main/demo/table-tab"], children: [], },
    { visible: true, category: MENU.CHANGE_PASSWORD, name: "CHANGE_PASSWORD",  items: [], label: MENU.CHANGE_PASSWORD,routerLink: ["/main/system/changepassword"],children: [],}
  ];

  // constructor() { } Button access to the module
  setUserCode(list) {
    let btnAccessToModule = {}
    let menus = list.filter(item => {
      // console.log('access: ', item);
      if(item.parentFunctionListId) {
        let module = btnAccessToModule[item.parentFunctionListId]
        btnAccessToModule[item.parentFunctionListId] = !module ? [] : module
        if (item.parentFunctionListId === 6) {
          let obj = {
            type: +item.functionCode.split('_')[1].split('TYPE')[1],
            desc: item.description
          }
          btnAccessToModule[item.parentFunctionListId].push(obj)
        } else {
          btnAccessToModule[item.parentFunctionListId].push(item.description)
        }
      }
      return item.description.includes('Menu -')
    }).map(({functionCode}) => functionCode)
    // console.log(menus);
    // let permissions = {};
    // menus.push('ACCESS_FUNCTION', 'MOVEMENT_HISTORY')
    this.menu.forEach(parentMenu => {
      parentMenu.items.forEach(subMenu => {
        let isExist = menus.includes(subMenu.name)
        subMenu.visible = isExist
        // permissions[subMenu.label] = isExist ? 15 : 0
        // console.log(isExist, subMenu.label);
        if(subMenu.visible && !parentMenu.visible) {
          parentMenu.visible = true
        }
      })
    })

    // LocalStorageHelper.setObject("PERMISSIONS", permissions);
    let uamFunctionPermissions = {}
    list.forEach(item => {
      uamFunctionPermissions[item.functionCode] = [
        ...uamFunctionPermissions[item.functionCode]?uamFunctionPermissions[item.functionCode]:[], 
        item
      ]
    });
    LocalStorageHelper.setObject("UAM_FUNCTION_PERMISSIONS", uamFunctionPermissions);
    LocalStorageHelper.setObject("PERMISSIONS", btnAccessToModule);
    LocalStorageHelper.setObject("MENU", this.menu);
  }
}
