import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// import * as XLSX from 'xlsx';
// import * as xlsxPopulate from 'xlsx-populate';
// import { Workbook } from 'exceljs';
import * as _ExcelJS from '_exceljs';
// declare var Exceljs: any;
declare var ExcelJS: _ExcelJS;
import { CommonMethod } from "@/util/CommonMethod";
import { MessageService } from "primeng/api";
declare var dhx: any;

@Component({
  selector: "app-spreadsheet-table",
  templateUrl: "./spreadsheet-table.component.html",
  styleUrls: ["./spreadsheet-table.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetComponent implements OnInit, OnChanges {
  constructor(
    public datePipe: DatePipe,
    public cdRef: ChangeDetectorRef,
    public messageService: MessageService,
  ) {}

  @ViewChild("sheet") sheetEl: ElementRef;

  @Input() value;
  @Input() column;
  @Input() loading: boolean;
  @Input() checkDisabledOnChange = true;

  @Input() checkRowDeleteAvailableFn: Function
  @Input() allowImport = false
  @Input() exportXlsxName = ''

  @Output() onCellValueChanged = new EventEmitter();
  @Output() onValueChanged = new EventEmitter();
  @Output() onEditStart = new EventEmitter();
  @Output() onImport = new EventEmitter();

  @ViewChild('filter') filterEl;

  spreadSheet;

  set columnMap(e) {
    this._columnMap = e;
    let _columnMapByPrefix = {};
    let _columnMapByfield = {};
    e.forEach((map) => {
      _columnMapByPrefix[map.prefix] = map.column;
      _columnMapByfield[map.field] = map.column;
    });
    this._columnMapByPrefix = _columnMapByPrefix;
    this._columnMapByfield = _columnMapByfield;
  }
  get columnMap() {
    return this._columnMap;
  }

  currentRowCount = null;
  loadingSwitch = false;
  dataInited = false;

  _value;
  _spreadSheetData;
  _previousValue = [];
  _previousColumn = [];

  _columnMap = [];
  _columnMapByPrefix = {};
  _columnMapByfield = {};

  currentColumn
  sorterFilter = ''; // filter panel input value
  sorterOrder = 0; // filter panel order value
  filter: any = {};
  order: any = null;

  filteredValue = null;

  get filterApplied() {return (this.filter!=null && Object.keys(this.filter).length > 0) || this.order != null}

  @HostListener("document:touchstart", ["$event"])
  @HostListener("document:mousedown", ["$event"])
  clickOutside(event) {
    if (this.sheetEl.nativeElement.contains(event.target)) {
      // clicked inside
    } else {
      // clicked outside
      this.spreadSheet.endEdit();
    }
  }

  ngOnInit(): void {}

  ngOnChanges(change) {
    if (change["value"]) {
      this._value = this.value
    }
    if (change["value"] || change["column"]) {
      this.loadFilteredValue()
      this.parseData();
    }
  }

  reload() {
    this.parseData(true);
  }

  parseData(force = false) {
    if (this.value && this.column && this.sheetEl) {
      let value = this.filterApplied ? this.filteredValue : this.value;
      // if(this.filterApplied) { value = this.filteredValue };
      // let timeoutFn = () => {
        this.loadingSwitch = true;
        let _data = [];
        let _keyValueData = {}; // for update date loading time
        let _columnMap = [];
        this.currentRowCount = value.length;
        let maxCellx = this.numToSSColumn(this.column.length);
        let currentSelectedCell = null;
        if (this._previousValue) {
          currentSelectedCell = this.spreadSheet.selection.getSelectedCell();
        }
        if (force || (this.filterApplied && (this._previousValue && this._previousColumn && (this._previousValue.length > value.length || this._previousColumn.length > this.column.length)))) {
          // clear all cell

          if (this._previousValue && this._previousColumn) {
            let resetCell =  `A1:${this.numToSSColumn(this._previousColumn.length)}1`;
            this.spreadSheet.deleteColumn(resetCell);
            this._previousValue = [];
            this._previousColumn = [];
          }
        }

        // unlock all cell before edit value
        // if (this._previousValue.length > 0) {
        //   this.spreadSheet.unlock(`A2:${maxCellx}${value.length + 1}`);
        // }
        let lockCell = `A1:${maxCellx}1`;
        this.column.forEach((col, xidx) => {
          let rowCellx = this.numToSSColumn(xidx + 1);
          _columnMap.push({
            prefix: rowCellx,
            field: col.field,
            column: col,
          });
          if (col.disabled == null) {
            col.disabled = true;
          }
          if (col.disabled && value.length > 0) {
            lockCell += `,${rowCellx}2:${rowCellx}${value.length + 1}`;
          }
          let isSameColumn = true;
          let headerCell = null;
          if (
            !this._previousColumn[xidx] ||
            JSON.stringify(col) != JSON.stringify(this._previousColumn[xidx])
          ) {
            isSameColumn = false;
            // headerCell = { cell: `${rowCellx}1`, value: col.title, lock: true }
            // _data.push({ cell: `${rowCellx}1`, value: col.title, lock: true, css: 'filtered' });
          }
          headerCell = { cell: `${rowCellx}1`, value: col.title,
          //  lock: true, 
           css: '' }
          if(this.filter[col.field]) headerCell['css'] += ' filtered'
          if(this.order && this.order.column.field == col.field){
            switch(this.order.order){
              case 1:
                headerCell['css'] += ' ordered-asc';
                break;
              case -1:
                headerCell['css'] += ' ordered-desc';
                break;
            }
          }
          if(headerCell) _data.push(headerCell)
          value.forEach((data, yidx) => {
            if (
              // !this._previousColumn[xidx] ||
              force ||
              value.length < this._previousValue.length ||
              !isSameColumn ||
              !this._previousValue ||
              !this._previousValue[yidx] ||
              // JSON.stringify(col) != JSON.stringify(this._previousColumn[xidx]) ||
              (data && 
              JSON.stringify(data[col.field]) !=
                JSON.stringify(this._previousValue[yidx][col.field])) 
                ||
              col.disabled == false
            ) {
              let dataValue = data[col.field];
              if (col.type) {
                switch (col.type) {
                  // no date / time format at current dhtmlx spreadsheet version, manually format
                  case "datetime":
                    dataValue = this.datePipe.transform(
                      data[col.field],
                      "yyyy/MM/dd HH:mm:ss"
                    );
                    break;
                  case "date":
                    dataValue = this.datePipe.transform(
                      data[col.field],
                      "yyyy/MM/dd"
                    );
                    break;
                  default:
                    break;
                }
              }
              let cell: any = {
                cell: `${rowCellx}${yidx + 2}`,
                value: dataValue,
                css: "",
              };
              if (col.format && value.length > 0) {
                cell.format = col.format;
              }
              if (col.disabled || col.isAllowEditFn && !col.isAllowEditFn(data)) {
                cell.css += " cell-locked";
                // cell.lock = true;
              } else {
                cell.css += " cell-unlocked";
                // cell.lock = false;
              }
              _data.push(cell);
            }
          });
        });
        this.columnMap = _columnMap;
        let _spreadSheetData = this._spreadSheetData
          ? this._spreadSheetData
          : [];
        // _spreadSheetData = [...this._spreadSheetData, ..._data];
        // if (_spreadSheetData.length == 0) {
        _spreadSheetData = [..._data];
        // } else if (_spreadSheetData.length > 0 && _data.length > 0) {
        //   _data.forEach((data) => {
        //     let idx = _spreadSheetData.findIndex(
        //       (spreadSheetData) => spreadSheetData.cell == data.cell
        //     );
        //     if (idx > -1) {
        //       _spreadSheetData[idx] = data;
        //     } else {
        //       _spreadSheetData.push(data);
        //     }
        //   });
        // }
        this._spreadSheetData = _spreadSheetData;
        // setTimeout(() => {
        this.spreadSheet.parse(_data);
        if (currentSelectedCell) {
          this.spreadSheet.selection.setSelectedCell(currentSelectedCell);
        }
        // });
        this._previousValue = JSON.parse(JSON.stringify(value));
        this._previousColumn = this.column;
        this.loadingSwitch = false;
        this.dataInited = true;
      // };
      // setTimeout(timeoutFn);
    }
  }

  forceSelectSelectedCell(){
    let currentSelectedCell
    if (this._previousValue) {
      currentSelectedCell = this.spreadSheet.selection.getSelectedCell();
    }
    // parse([]) will do nothing for data displayed, but let the browser focus to the excel like, can use arrow key
    this.spreadSheet.parse([])
    if (currentSelectedCell) {
      this.spreadSheet.selection.setSelectedCell(currentSelectedCell);
    }
  }

  initSpreadSheet() {
    let that = this;
    this.spreadSheet = new dhx.Spreadsheet(this.sheetEl.nativeElement, {
      leftSplit: 2,
      toolbarBlocks: [
        // "undo",
        // "colors",
        // "decoration",
        // "align",
        // "lock",
        // "clear",
        "rows",
        // "columns",
        // "help",
        // "format",
        "file"
      ],
      rowsCount: this.value.length + 1,
      // colsCount: 0,
      formats: [{ name: "Common", id: "common", mask: "", example: "2702.31" }],
      menu: false,
      multiSheets: false,
      // editLine: false, // false will trigger error, use display none to hide it
    });
    this.spreadSheet.contextMenu.hide();
    this.spreadSheet.events.on("afterValueChange", (cell, value) => {
      // setTimeout(() => {
      that.cellValueChanged(cell, value);
      // });
    });
    this.spreadSheet.events.on("beforeValueChange", (cell, value)=>{
      let [xKey, yKey] = that.splitSSColumn(cell);
      let isOutOfRange = (this.filterApplied && yKey - 1 > this.filteredValue.length) || (!this.filterApplied && yKey - 1 > this._value.length);
      if(this._columnMapByPrefix[xKey] && !isOutOfRange)return true;
      return false;
    })
    this.spreadSheet.events.on("beforeEditStart", function (cell, value) {
      return that.editStart(cell, value);
    });
    this.spreadSheet.events.on("beforeRowDelete", function (cell) {
      if(/[A-Z]1[^0-9]/g.test(cell)){
        return false;
      }else{
        let xyKey: any[] = that.splitSSColumn(cell);
        let yKey = xyKey[1];
        let valueIdx = null
        if(this.filterApplied && yKey != 1){
          valueIdx = this._value.findIndex(val=>val.rowId==this.filteredValue[yKey-2].rowId)
        }else if(yKey == 1){
          valueIdx = null
        }else{
          valueIdx = yKey - 2
        }
        let isRow = yKey > 1 && (yKey - 1 <= that._value.length)
        let isValid = that.value[valueIdx]?that.checkDeleteAvailable(that.value[valueIdx]):true
        return isRow && isValid;
      }
    });
    this.spreadSheet.events.on("afterRowDelete", function(cells){
      that.afterRowDelete(cells)
    });
    this.spreadSheet.events.on("beforeRowAdd", function(cells){
      return false;
    });
    this.spreadSheet.undo = function () {};
    const originLoad = this.spreadSheet.load;
    this.spreadSheet.originLoad = originLoad;
    this.spreadSheet.load = function (url, type) {
      // return that.spreadSheet.originLoad(url, type).then(()=>{

      // })
      if(that.allowImport){
        let importAsJsonPromiseResolver
        let importAsJsonPromise = new Promise((resolve, reject)=>{
          importAsJsonPromiseResolver = resolve
        })
        that.importFile().then((res)=>{
          if(res==null) {
            throw null
          }else{
            return res.getFile()
          }
        }).then((res)=>{
          if(!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(res.type)) throw 'Please upload .xlsx file'
          that.loadingSwitch = true;
          that.cdRef.detectChanges();
          return res
          // return new Promise(resolve => setTimeout(() => resolve(res), 1000))
        }).then((res:File)=>{
          let fileReader = new FileReader();
          fileReader.onload = async function(e) {
            const buffer:any = fileReader.result;
            const wb = new ExcelJS.Workbook();
            // const wb = new Workbook();
            let workbook = await wb.xlsx.load(buffer)
            let sheet = workbook.worksheets[0]

            let _res = [];
            let firstRow = sheet.getRow(1);
            if (!firstRow.cellCount) return;
            let keys = <any[]>firstRow.values;
            sheet.eachRow((row, rowNumber) => {
              if (rowNumber == 1) return;
              let values = row.values
              let obj = {};
              for (let i = 1; i < keys.length; i ++) {
                obj[keys[i]] = values[i];
              }
              _res.push(obj);
            })
            
            let jsonRes = []
            let haveValue = false
            that.column.forEach(col=>{
              _res.forEach((row,idx)=>{
                let _data = {}
                if(jsonRes[idx]==null) jsonRes[idx] = _data
                if(row[col.title]!=null){
                  haveValue = true;
                  jsonRes[idx][col.field] = row[col.title]
                }
              })
            })
            importAsJsonPromiseResolver({data: jsonRes, isColumnMatch: haveValue})
          };
          fileReader.readAsArrayBuffer(res)
          return importAsJsonPromise
        }).then((res:any)=>{
          that.loadingSwitch = false
          that.onImport.emit({data: res.data, isColumnMatch: res.isColumnMatch})
        }).catch(e=>{
          if(e!=null){
            console.error(e)
            that.showMessage('error', 'System', e)
          }
        })
      }
    }
    this.spreadSheet.export.xlsx = function(){that.exportXlsx()}
  }

  async importFile(){
    try{
      let [handle] = await CommonMethod.showOpenFilePickerPolyfill({
        types: [
          {
            description: 'XLSX',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':['.xlsx']
            }
          },
        ]
      });
      if (!handle) {
        // User cancelled, or otherwise failed to open a file.
        return;
      }
      return handle
    }catch(e){
      return
    }
  }

  // resetFormat(){

  // }

  checkDeleteAvailable(row){
    if(this.checkRowDeleteAvailableFn){
      return this.checkRowDeleteAvailableFn(row)
    }else{
      return true
    }
  }

  ngAfterViewInit() {
    this.initSpreadSheet();
    this.parseData();
  }

  getData() {
    return this._value;
  }

  deleteRow(e) {
    this.spreadSheet.deleteRow(e);
  }

  deleteSelectedRows() {
    this.deleteRow(this.spreadSheet.getSelectedCell());
  }

  cellValueChanged(cell, value) {
    let [xKey, yKey] = this.splitSSColumn(cell);
    if (this._columnMapByPrefix[xKey]) {
      let column = this._columnMapByPrefix[xKey];
      let valueIsValid = true;
      if (column.validationFn && typeof column.validationFn === "function") {
        // if not valid, force trigger reset value to inital value
        valueIsValid = column.validationFn(value);
      }
      let isOutOfRange =
        (this.filterApplied && yKey - 1 > this.filteredValue.length) ||
        (!this.filterApplied && yKey - 1 > this._value.length) ||
        this.SSToNum(xKey) > this.column.length;
      let initData = this._spreadSheetData.find(
        (data) => data.cell == cell
      );
      if (yKey > 1 && column && !isOutOfRange) {
        // reload cell style when value change, copy paste cell may replace style
        let style = "";
        if (column.disabled == true || (column.isAllowEditFn&&!column.isAllowEditFn(this.filteredValue[yKey-2]))) {
          style += "cell-locked";
        } else {
          style += "cell-unlocked";
        }
        // style may remove after paste, set style with timeout to reset it
        setTimeout(()=>{
          this.spreadSheet.setStyle(cell, style);
        })
      }
      if (
        !valueIsValid ||
        (this.checkDisabledOnChange &&
          (yKey == 1 || column.disabled == true)) ||
        (column.unableDelete == true && (value == null || value.length == 0)) ||
        isOutOfRange
      ) {
        // reset value to initial value
        // when is header / column is disabled / invalid / column is unableDelete + value isempty
        if (initData != null && initData.value != value && !isOutOfRange) {
          this.spreadSheet.setValue(cell, initData.value)
        }else if(value!=='' && isOutOfRange) {
          this.spreadSheet.setValue(cell, '')
        }
        return;
      } else if(!isOutOfRange && (!initData || initData.value != value)) {
        // column.disabled == false, not header
        let _value = value;
        if (column.type && column.disabled == false) {
          switch (column.type) {
            case "date":
            case "datetime":
              _value = new Date(value).getTime();
              break;
            case "number":
              _value = !isNaN(Number.parseFloat(value))
                ? Number.parseFloat(value)
                : null;
              break;
            default:
              break;
          }
        }
        let valueIdx = null
        if(this.filterApplied && yKey != 1){
          valueIdx = this._value.findIndex(val=>val.rowId==this.filteredValue[yKey-2].rowId)
        }else{
          valueIdx = yKey - 2
        }
        if (column.disabled == false) {
          this._value[valueIdx][column.field] = _value;
          this.onValueChanged.emit(this._value);
        }
        this.onCellValueChanged.emit({
          row: this._value[valueIdx],
          field: column.field,
          index: valueIdx,
          newValue: _value,
        });
      }
    }else{
      // no column, out of range, reset cell,
      if(value!==''){
        this.spreadSheet.setValue(cell, '');
      }
      this.spreadSheet.setStyle(cell, '');
    }
  }

  numToSSColumn(num) {
    var s = "",
      t;
    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = ((num - t) / 26) | 0;
    }
    return s || undefined;
  }

  splitSSColumn(str: string): any {
    var patt1 = /[0-9]/g;
    var patt2 = /[a-zA-Z]/g;
    var letters = str.match(patt2);
    var digits = str.match(patt1);
    return [letters.join(""), Number.parseInt(digits.join(""), 10)];
  }

  SSToNum(letters) {
    for (var p = 0, n = 0; p < letters.length; p++) {
      n = letters[p].charCodeAt() - 64 + n * 26;
    }
    return n;
  }

  editStart(cell, value) {
    let [xKey, yKey] = this.splitSSColumn(cell);
    let isOutOfRange = (this.filterApplied && yKey - 1 > this.filteredValue.length) || (!this.filterApplied && yKey - 1 > this._value.length);
    if(yKey < 1 || yKey - 1 > this._value.length || isOutOfRange){
      return false
    }
    if (this._columnMapByPrefix[xKey]) {
      let column = this._columnMapByPrefix[xKey];
      let xIdx = this.SSToNum(xKey);
      let el = this.sheetEl.nativeElement.querySelector(
        // `.dhx_grid-body [dhx_id="${yKey}"] [dhx_col_id="${xIdx}"]`
        `.dhx_grid-body .dhx_selected_cell`
      );
      let dummyEvent = new CustomEvent("CustomEvent");
      Object.defineProperty(dummyEvent, "target", {
        writable: false,
        value: el,
      });

      let valueIdx = null
      if(this.filterApplied && yKey != 1){
        valueIdx = this._value.findIndex(val=>val.rowId==this.filteredValue[yKey-2].rowId)
      }else if(yKey == 1){
        valueIdx = null
      }else{
        valueIdx = yKey - 2
      }

      if(valueIdx!=null && column.isAllowEditFn && !column.isAllowEditFn(this._value[valueIdx])) return false

      this.onEditStart.emit({
        row: yKey == 1?null:this._value[valueIdx],
        field: column.field,
        index: yKey == 1?null:valueIdx,
        element: el,
        isHeader: yKey == 1,
        dummyEvent,
      });
      if (yKey == 1){
        // filter, sorter
        this.currentColumn = column
        this.loadCurrentFilterToFilterPanel(this.currentColumn)
        this.filterEl.toggle(dummyEvent)
        return false
      }
      if (column.externalDropdown) {
        return false;
      }
      if (column.disabled == true){
        return false;
      }
    }
    return true;
  }

  endEdit() {
    this.spreadSheet.endEdit();
  }

  afterRowDelete(cells){
    // let value = this.value;
    let _value = JSON.parse(JSON.stringify(this._value));
    // cells.forEach(cell => {
    let [yKey, rowNum] = this.splitSSColumn(cells);

    let valueIdx = null
    if(this.filterApplied && yKey != 1){
      valueIdx = this._value.findIndex(val=>val.rowId==this.filteredValue[yKey-2].rowId)
    }else if(yKey == 1){
      valueIdx = null
    }else{
      valueIdx = yKey - 2
    }
    _value[valueIdx] = undefined
    if(!valueIdx) {
      _value[rowNum-2] = undefined
    }
    // });
    _value = _value.filter(val=>val!=undefined);
    this._value = [..._value];
    this._previousValue = [..._value]
    // this.parseData();
    this.onValueChanged.emit(this._value);
  }

  applySortFilter(column = this.currentColumn){
    this.loadFilteredValue(column)
    this.parseData()
    this.filterEl.hide()
  }

  loadFilteredValue(column = this.currentColumn){
    let _datas = this._value;
    // let _datas = JSON.parse(JSON.stringify(this._value));
    let res:any[] = _datas;
    let filterString = this.sorterFilter;
    let order = this.sorterOrder;
    // let column = this.currentColumn
    if(column){
      if(filterString == '' || !filterString){
        delete this.filter[column.field];
      }else{
        this.filter[column.field] = {filter: filterString, column}
      }
      if(order != 0){
        this.order = {column, order};
      }else{
        this.order = null;
      }
      this.currentColumn = null;
    }
    if(this.filter && Object.keys(this.filter).length > 0){
      // do filter
      res = res.filter((val)=>{
        let _res = true;
        for(let key in this.filter){
          if(this.filter[key] && this.filter[key].filter){
            let value = null;
            let _filterStr = this.filter[key].filter
            switch (this.filter[key].column.type) {
              case "datetime":
                value = this.datePipe.transform(
                  val[key],
                  "yyyy/MM/dd HH:mm:ss"
                );
                break;
              case "date":
                value = this.datePipe.transform(
                  val[key],
                  "yyyy/MM/dd"
                );
                break;
              default:
                value = val[key];
                break;
            }
            if(value.toString().toLowerCase().indexOf(_filterStr.toString().toLowerCase()) == -1){
              _res = false;
              break; // break for each key loop
            }
          }
        }
        return _res
      })
    }

    if(this.order!=null && this.order.order!=0){
      if(res.length>0){
        // do sort
        res = res.sort((a,b)=>{
          let aVal = a[this.order.column.field]
          let bVal = b[this.order.column.field]
          let res1 = 0
          if(aVal > bVal) res1 = 1*this.order.order;
          if(aVal < bVal) res1 = -1*this.order.order;
          return res1
        })
      }
    }

    this.filteredValue = res;
  }

  sorterOrderOnClick(){
    if(this.sorterOrder == 1){
      this.sorterOrder = -1
    }else{
      this.sorterOrder++
    }
  }

  loadCurrentFilterToFilterPanel(column){
    if(column && (this.filter[column.field] || this.order && this.order.column && this.order.column.field == column.field)){
      if(this.filter[column.field]) this.sorterFilter = this.filter[column.field].filter;
      if(this.order && this.order.column && this.order.column.field == column.field) this.sorterOrder = this.order.order;
    }else{
      this.resetSorter()
    }
  }

  resetSorter(){
    this.sorterFilter = '';
    this.sorterOrder = 0;
  }

  exportXlsx(){
    // let res = this.getWorkSheet(this.value, this.column, 'field')
    // XLSX.writeFile({
    //   SheetNames:['Sheet1'],
    //   Sheets: {
    //     Sheet1: res,
    //   }
    // }, `${this.exportXlsxName&&this.exportXlsxName.length>0?this.exportXlsxName:'spreadsheet'}.xlsx`);
    CommonMethod.downloadXlsxWithOptions(this.value, this.column, `${this.exportXlsxName}`, this.datePipe, {fieldKey:'field', 
      columnStyles: [...this.column.map((e,idx)=>e.exportCellEditable?{
        columnIdx:idx+1,style:{fill:'FFFF99'}
      }:null).filter(e=>e)]
    })
    // this.genXlsxWithStyle(this.value, this.column)
  }

  // genXlsxWithStyle(data, col){
  //   let that = this;
  //   xlsxPopulate.fromBlankAsync().then(function (workbook) {
  //     let _res = []
  //     let colMaxWidth = []
  //     workbook.sheet(0).row(1).cell(1).value([col.map((_col, colIdx)=>{ 
  //       if(_col.title&&_col.title.length>0&&_col.title.length>8) colMaxWidth[colIdx+1]=_col.title.length+3;
  //       return _col.title
  //     })])
      
  //     let column_style = workbook.styleSheet().createStyle();
  //     column_style.style("fill", "FFFF99");
  //     try{
  //       col.forEach((_col, colIdx)=>{
  //         if(_col.exportCellEditable){
  //           let length = data.length;
  //           let startcell = workbook.sheet(0).row(1).cell(colIdx+1)
  //           let endcell = workbook.sheet(0).row(length+2).cell(colIdx+1)
  //           let range = startcell.rangeTo(endcell)
  //           range.style(column_style)
  //         }
  //         // if(_col.exportCellEditable) workbook.sheet(0).row(dataIdx+2).cell(colIdx+1).style("fill", "ffff99")
  //       })
  //     }catch(e){console.error(e)}

  //     data.forEach((_data, dataIdx)=>{
  //       let row = [];
  //       col.forEach((_col, colIdx)=>{
  //         let value = _data[_col.field]
  //         if(_col.type == 'datetime'){
  //           value = that.datePipe.transform(value, "yyyy/MM/dd HH:mm:ss")
  //         }else if(_col.type == 'date'){
  //           value = that.datePipe.transform(value, "yyyy/MM/dd")
  //         }
  //         if(value&&value.length>0&&value.length>8) {
  //           if(value.length > colMaxWidth[colIdx+1]){
  //             colMaxWidth[colIdx+2]=value.length+3;
  //           }
  //         }
  //         row.push(value)
  //         // if(_col.exportCellEditable) workbook.sheet(0).row(dataIdx+2).cell(colIdx+1).style("fill", "ffff99")
  //       })
  //       _res.push(row)
  //     })
  //     if(_res.length>0) {
  //       workbook.sheet(0).row(2).cell(1).value(_res)
        
  //       // set formula
  //       data.forEach((_data, dataIdx)=>{
  //         col.forEach((_col, colIdx)=>{
  //           if(_col.formula){
  //             let formula = that.getFormula(workbook.sheet(0), _col.formula, col, dataIdx+2, true)
  //             workbook.sheet(0).row(dataIdx+2).cell(colIdx+1).formula(formula)
  //           }
  //         })
  //       })
  //     }
  //     colMaxWidth.forEach((val,idx)=>{
  //       if(val){
  //         workbook.sheet(0).column(idx).width(val)
  //       }
  //     })
  //     return workbook.outputAsync();
  //   }).then(function (blob) {
  //     var url = window.URL.createObjectURL(blob);
  //     var a = document.createElement("a");
  //     document.body.appendChild(a);
  //     a.href = url;
  //     a.download = `${that.exportXlsxName&&that.exportXlsxName.length>0?that.exportXlsxName:'spreadsheet'}.xlsx`;
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(a);
  //   }).catch(function (err) {
  //     throw err;
  //   });
  // }

  /**
    formula sample
    {
      field: "qtyAllocate",
      title: "Replenish Qty",
      format: "common",
      formula: {
        sign: '+',
        children: [
          'qtyPreAllocate',
          'qtyUserInput',
        ]
      }
    }
  */
  getFormula(worksheet, formulaObj, col, rowIdx, isParent = false){
    let cellList = []
    formulaObj.children.forEach(child => {
      if(typeof child == 'string'){
        // child is key string
        let idx = col.findIndex(_col=>_col.field==child)
        cellList.push(worksheet.row(rowIdx).cell(idx+1).address())
      }else{
        // child is another formula
        // wip: not tested, case only need 1 layer when developing
        cellList.push(this.getFormula(worksheet, child, col,rowIdx)) 
      }
    });
    let res = '';
    cellList.forEach(cell=>{
      if(res.length>0)res+=formulaObj.sign;
      res+=`(${cell})`
    })
    if(isParent) res = '='+res;
    return res
  }
  
  showMessage(severity, summary, detail, isInnerHTML = false){
    this.messageService.add({severity, summary, detail, data: {isInnerHTML:isInnerHTML}});
  }

  // getWorkSheet(_data, _col, columnKey='key'){
  //   let data = [..._data];
  //   let col = [..._col];

  //   let ws_data = [];
  //   let merges: XLSX.Range[] = [];
  //   let header = []
  //   let rows = [...col].map(head=>head.parent?2:1)
  //   let maxRow = Math.max(...rows)
  //   for(let i=0;i<maxRow;i++){
  //     header.push([])
  //   }

  //   col.forEach((head,idx)=>{
  //     for(let i = 0;i<maxRow;i++){
  //       if(!head.parent){
  //         if(i==0) merges.push({s: {r: 0, c: header[0].length }, e: {r: maxRow - 1, c: header[0].length}})
  //         header[i].push(head.title)
  //       }else{
  //         if(i==0 && head.parent!==true){
  //           merges.push({s: {r: 0, c: header[0].length }, e: {r: 0, c: header[0].length - 1 + head.parent.colspan}})
  //           for(let k = 0; k<head.parent.colspan;k++){
  //             header[i].push(head.parent.title)
  //           }
  //         }
  //         if(i==1){
  //           header[i].push(head.title)
  //         }
  //       }
  //     }
  //   })

  //   let dataRow = []

  //   data.forEach(row=>{
  //     let _row = []
  //     col.forEach(head => {
  //       // export function should export not export any number empty number
  //       if(head.exportValue == false){
  //         _row.push('')
  //       }else{
  //         // if(val==0||val=='0') val = ''
  //         let val = row[head[columnKey]]
  //         _row.push(val)
  //       }
  //     });
  //     dataRow.push(_row)
  //   })

  //   ws_data = [...header, ...dataRow]
  //   let ws = XLSX.utils.aoa_to_sheet(ws_data);
  //   let objectMaxLength = [];

  //   for (let i = 0; i < ws_data.length; i++) {
  //     let value = <any>Object.values(ws_data[i]);
  //     for (let j = 0; j < value.length; j++) {
  //       if (typeof value[j] == "number") {
  //         objectMaxLength[j] = 10;
  //       } else {
  //         objectMaxLength[j] =
  //           objectMaxLength[j] >= (value[j]?value[j].length:0)
  //             ? objectMaxLength[j]
  //             : value[j].length;
  //       }
  //     }
  //   }

  //   if(!ws['!merges']) ws['!merges'] = [];
  //   ws['!merges'].push(...merges);
  //   if(!ws['!cols']) ws['!cols'] = [];
  //   ws['!cols'].push(...objectMaxLength.map(maxLength=>{return {width: maxLength>10?maxLength:10}}));
    
  //   return ws
  // }
}
