import { Location as _Location} from "@angular/common";
import { Injectable, ChangeDetectorRef } from "@angular/core";
import { LoadingService } from "./loading-service";
import { PermissionService } from "./permission-service";
import { CommonService } from "./common-service";
import { LocalStorageHelper } from "@/util/LocalStorageHelper";

@Injectable({
  providedIn: "root",
})
export class CommonStore {
  public _obj: { [key: string]: any }[] = [];
  public loadingService: LoadingService;
  public version: string = null;
  constructor(
    public location: _Location,
    public permissionService: PermissionService,
    public commonService: CommonService,
  ) {
    this.loadingService = new LoadingService()
  }
  public setObject(key: string, value: any): void {
    this._obj[key] = value;
  }

  public getObject(key: string): any {
    return this._obj[key];
  }

  public remove(key:string):any {
    delete this._obj[key]
  }

  public removeAll():any{
    this._obj = [];
  }

  public setAccount(e){
    this.commonService.setAccount(e)
  }

  public getAccount(){
    return this.commonService.getAccount()
  }

  public setVersion(e){this.version = e}
}
