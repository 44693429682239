import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InjectHelper } from 'src/app/util/InjectHelper';
import {SearchStore} from "../../../../stores/config/item_transfer_quota/SearchStore";
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonStore } from '@/service/common/common-store-service';

@Component({
  selector: 'app-search',
  template: `<div>
    <illustration name="Item Transfer Quota" path="Stock Take"></illustration>
    <app-query-pannel class="query-panel" [store]="store.queryPannel"></app-query-pannel>
    <app-add-pannel *ngIf="store.addPannel.show" [store]="store.addPannel"></app-add-pannel>
    <app-ptable-pannel [store]="store.tablePannel" [searchStore]="store"></app-ptable-pannel>
    <p-confirmDialog [style]="{width: '50vw'}" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  </div>`,
  styles: []
  // styleUrls: ['../../../base/table-pannel/table-pannel.component.css']
})
export class SearchComponent {
  public store: SearchStore = null
  constructor(private n:MessageService,private r:Router,private p:ActivatedRoute,private m:ConfirmationService,public  commonStore: CommonStore) {
    this.store = new SearchStore(InjectHelper.start(this))
  }
}
