import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { makeAutoObservable } from "mobx";
import { ConfirmationService, MessageService, SortEvent } from "primeng/api";
import { LocalStorageHelper } from "src/app/util/LocalStorageHelper";
import { INPUT_TYPE, URLDICT } from "../../base/BaseStore";
import { HttpHelper } from "src/app/util/HttpHelper";
import { StockTakeService } from "src/app/service/stock/stock-take.service";
import { CommonService } from "src/app/service/common/common-service";
import { map, switchMap, tap } from "rxjs/operators";
import { StockCommonService } from "src/app/service/stock/stock-common.service";
import { of } from "rxjs";
import { FRONTLINE_STOCK_TAKE_CODE, SURPRISE_STOCK_TAKE_CODE, TAKE_STATUS_ID, TITLE } from "./TakeShare";
import { FUNCTION_CODE } from "@/service/common/permission.type";
import { PermissionService } from "@/service/common/permission-service";
import { NgxFileDropComponent } from "ngx-file-drop";
import { CommonMethod } from "@/util/CommonMethod";
import { FilterCommonMethod } from "@/util/FilterCommonMethod";

// const QUERYTITLE = {
//   TYPE:"Stock Take Type",
//   CHANNEL:"Channel",
//   STOCKTAKEREFNO:"Stock Take Ref. No.",
//   STOCKADJREFNO:"Stock Adjustment Ref. No.",
//   TARGETDATE:"Target Stock Take Date",
//   BULKADD_FILTER:"Filter",
//   BULKADD_BRAND:"Brand",
//   BULKADD_ITEM:"Item",
//   COUNTBY:"Serialized Item Count By",
//   BU: 'BU Code',
//   LOB: 'LOB Code',
//   AVAILABLE: 'Have Available Stock',
//   SUBSUBCAT: 'Sub-Sub Cat',
//   SELECT_ITEM:"All Items",
// }
@Injectable()
export class CreateStore {
  queryTitle = TITLE;
  queryData
  //  = {
  //   ipts: [
  //     {
  //       title: QUERYTITLE.TYPE,
  //       type: INPUT_TYPE.SELECT,
  //       class: "p-col-12 p-md-4 p-lg-6",
  //       rows: 3,
  //       value: null,
  //       options: [],
  //       optionLabel: "name",
  //       optionValue: "code"
  //     },
  //     {
  //       title: QUERYTITLE.CHANNEL,
  //       type: INPUT_TYPE.TREESELECT,
  //       class: "p-col-12 p-md-4 p-lg-offset-3 p-lg-3",
  //       value: null,
  //       error: null,
  //       options: [],
  //       beforeSubmit: function(value) { return value?value.id:null },
  //       change: function(value, ipt) {
  //         if(!(value instanceof Event)){
  //           let seSku = that.queryPannel.ipts.find(item => item.name === "skuId");
  //           if(seSku){
  //             if(value==null){
  //               seSku.options = [];
  //               seSku.value = null;
  //               return
  //             }
  //             that.loadItemList(value.id).subscribe(res=>{
  //               seSku.options = res
  //             })
  //           }
  //         }
  //       }
  //     },
  //     {
  //       title: QUERYTITLE.STOCKTAKEREFNO,
  //       type: INPUT_TYPE.INPUT,
  //       class: "p-col-12 p-md-4 p-lg-3",
  //       value: null,
  //       disabled: true
  //     },
  //     {
  //       title: QUERYTITLE.STOCKADJREFNO,
  //       type: INPUT_TYPE.INPUT,
  //       class: "p-col-12 p-md-4 p-lg-3",
  //       value: null,
  //       disabled: true
  //     },
  //     {
  //       title: QUERYTITLE.TARGETDATE,
  //       type: INPUT_TYPE.DATETIME,
  //       class: "p-col-12 p-md-4 p-lg-3 p-lg-offset-3",
  //       rows: 3,
  //       value: null,
  //       minDate: null
  //     },
  //     /* {
  //       title: "surprise stock take",
  //       name: "surprise stock take",
  //       type: INPUT_TYPE.CHECKBOX,
  //       class: "p-col-12 p-md-4 p-lg-3",
  //       group: 'surprise',
  //       rows: 3,
  //       value: null,
  //       disabled: false
  //     } */
  //      /* {
  //       title: "Item",
  //       type: INPUT_TYPE.MUTIPLESELECT,
  //       class: "p-col-12 p-md-4 p-lg-3",
  //       value: null,
  //       error: null,
  //       options: []
  //     }, */
  //   ],
  //   btns:[{
  //     title: 'Back',
  //     class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
  //     show: true,
  //     handler: { click: () => this.back() }
  //   },  {
  //     title: 'Save',
  //     class: "p-order-3 p-mr-1",
  //     permissionType: 'c',
  //     show: true,
  //     disabled: false,
  //     handler: { click: () => this.createTake() }
  //   },
  //   /* {
  //     title: 'Create',
  //     class: "p-order-3 p-mr-1",
  //     permissionType: 'c',
  //     show: true,
  //     disabled: false,
  //     handler: { click: () => this.createTake() }
  //   }, {
  //     title: 'Add',
  //     class: "p-order-2 p-mr-1",
  //     permissionType: 'c',
  //     show: true,
  //     disabled: false,
  //     handler: { click: () => this.addItem() }
  //   }, */
  // ],
  //   btnsclass: 'p-d-flex p-col-9'
  // }
  tableData = {
    ipt:{
      title: "Item",
      type: INPUT_TYPE.MUTIPLESELECT,
      class: "p-col-12 p-md-4 p-lg-3",
      value: null,
      error: null,
      options: []
    },
    btns: [],
    data: [],
    showCheckboxSwitch:false,
    loadingSwitch: false,
    head: [
      {key:'itemCode',title:'Item Code', width:"20%"},
      {key:'itemDesc',title:'Item Description', width:"50%", edit: false},
      {key:'normal', parent: { title: '1st Count', colspan: 2 }, title:'FG', width:"20%", edit: false},
      {key:'faulty', parent: true, title:'Faulty', width:"20%", edit: false},
      {key:'normal_2', parent: { title: '2nd Count', colspan: 2 }, title:'FG', width:"20%", edit: false},
      {key:'faulty_2', parent: true, title:'Faulty', width:"20%", edit: false},
      // {key:'total',title:'Total', width:"5%"}
    ],
    sortField: 'itemCode',
    sortOrder: 1,
  }

  bulkAddQueryPanel
  selectRequest = null
  searchAll = true
  requestDialog = false
  // defaultPermission:any = {c: 8, u: 4, r: 2, d: 1}
  permission: string[]
  dialogTitle = ""
  info = {
    title: 'Stock Take Create',
    selectChannel : "Please select a Channel!",
    selectItem : "Please select a Item!",
    emptyFile: 'File content cannot be empty!',
    itemsUpdated: 'Items updated!',
    notItems: 'The selected channel has no related items!',
    addItems: 'Please click the Add button to add the item content!',
    required: 'Normal and Faulty is required!',
    labelRequired: 'label is required!',
    typeRequired: 'Stock Take Type is required!',
    repeat: 'Data duplication!',
    createSuccess: 'Data created successfully!',
    createFail: 'Failed to create data!',
    countByEmpty: 'Please Select Count By!',
    _required: '${1} is required!',
  }
  detailData:any = {} // allocation data parameter from parent
  uploadRemind = false // upload DOM is displayed
  isCreated = false
  addItemDisabled = false
  tempUploadData // temporarily reserved upload data
  itemList:any[];
  filterAddPanel
  buCodes=[]
  lobCode=[]
  subSubCat=[]
  mfgBrand=[]
  //ADD by zhang henry start
  mainCat=[]
  subCat=[]
  //ADD by zhang henry end
  public channels
  public selectedChannel
  public selectedChannelDisplay

  haveOptions = false

  _stockTakeTypeValue

  fileDropper: NgxFileDropComponent;
  takeStatus

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private msg: MessageService,
    private conf: ConfirmationService,
    private stockTakeService: StockTakeService,
    private commonService: CommonService,
    private stockCommonService: StockCommonService,
    private permissionService: PermissionService,
  ) {
    makeAutoObservable(this)
    this.getPermission(this.route)
    this.init()
    this.initFilterAddPanel()
    this.initQueryData()
    this.loadItemListBySkuModule().subscribe(res=>{
      // if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.SELECT_ITEM).options = this.itemList.map(item=>{return{
      //   id: item._data.itemid,
      //   name: item._data.item + ' - ' + item._data.itemdesc,
      //   itemCode: item._data.item,
      //   desc: item._data.itemdesc
      // }})
    })
    // this.initBulkAddPanel()
    /* this.route.data.pipe(pluck('status')).subscribe(res => {
      console.log('get route data', res);
    }); */
    this.getLableData()
  }
  get channel() {
    let ipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.CHANNEL)
    return ipt.value
  }
  /* get items() {
  } */
  get targetDate() {
    let ipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TARGETDATE)
    return ipt.value
  }
  get stocktakeType() {
    let ipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TYPE)
    return ipt.value
  }
  /* get surprise() {
  } */
  get showC() {
    return true // this.permission?.includes('Create Stock Take')
  }
  /* convertDate(d){
    var timeZone:number = 8 //目前是东八时区 需要改变时区时修改此属性值
    var date:Date = new Date(parseInt(d) + timeZone * 3600 * 1000)
    return date.toJSON().split('T')[0].replace(/-/g, '/')
  } */
  // getPermission(p){
  //   this.permission = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
  // }
  getQueryByTitle(title){
    return this.queryData.ipts.find(item => item.title === title)
  }
  getLableData(){
    HttpHelper.get(URLDICT.STOCK_TAKE_LABEL).then(res => {
    // console.log('query labels:', res);
    const label = this.getQueryByTitle(TITLE.TYPE)
    if (res.status === 200) {
      let labelList = []
      res.data.forEach(item => {
        // const name = item.labelName
        labelList.push({id:item.id, code: item.code, other: item.description, name: item.description})
      })
      //label.options = labelList

      let ipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TYPE)
      ipt.options = labelList
    } else {
      label.options = []
    }
  }).catch(e => console.log('error', e)).finally(() => console.log('Query labels completed!'))
}
  initQueryData() {
    let that = this
    let draftLabel = this.takeStatus.find(e=>e.value==TAKE_STATUS_ID.DRAFT).label
    this.channels = FilterCommonMethod.getRepoTreeForFilterInput();
    this.initDefaultChannel()
    this.queryData = {
      ipts: [
        {
          title: TITLE.TYPE,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-8 p-lg-6",
          rows: 3,
          value: null,
          options: [],
          required: true,
          optionLabel: "name",
          optionValue: "code",
        },
        {
          title: TITLE.CHANNEL,
          type: INPUT_TYPE.TREESELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: this.selectedChannel,
          showValue: this.selectedChannelDisplay,
          error: null,
          options: [],
          required: true,
          beforeSubmit: function(value) { return value?value.id:null },
          change: function(value, ipt) {
            // if(!(value instanceof Event)){
            //   // that.tableData.loadingSwitch = true
            //   // that.queryItems(value?.data)
            //   that.loadAvailableItemListByChannel()
            // }
            that.channelChanged(value)
          }
        },
        {
          title: TITLE.STATUS,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: draftLabel?draftLabel:'Draft',
          disabled: true
        },
        {
          title: TITLE.STOCKTAKEREFNO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          disabled: true
        },
        {
          title: TITLE.STOCKADJREFNO,
          type: INPUT_TYPE.INPUT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          disabled: true
        },
        {
          title: TITLE.COUNTBY,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          rows: 3,
          value: null,
          options: [],
          required: true,
          optionLabel: "name",
          optionValue: "code"
        },
        {
          title: TITLE.TARGETDATE,
          type: INPUT_TYPE.DATETIME,
          class: "p-col-12 p-md-4 p-lg-3",
          rows: 3,
          value: null,
          required: true,
          minDate: null
        },
        {
          title: TITLE.TAKEBU,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          disabled: false,
          optionLabel: "label",
          required: true,
          optionValue: "value"
        },
        {
          title: TITLE.REMARKS,
          value: null,
          type: INPUT_TYPE.TEXTAREA,
          class: "p-col-12 p-md-12 p-lg-6 textarea-min-width-100",
        },
        /* {
          title: "surprise stock take",
          name: "surprise stock take",
          type: INPUT_TYPE.CHECKBOX,
          class: "p-col-12 p-md-4 p-lg-3",
          group: 'surprise',
          rows: 3,
          value: null,
          disabled: false
        } */
        /* {
          title: "Item",
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-3",
          value: null,
          error: null,
          options: []
        }, */
      ],
      btns:[
        {
          title: TITLE.CREATE_BACK,
          class: "p-button-outlined p-ml-auto p-order-0 p-mr-1",
          show: true,
          handler: { click: () => this.back() }
        },  {
          title: TITLE.CREATE_SAVE,
          class: "p-order-3",
          // permissionType: 'c',
          show: true,
          disabled: false,
          handler: { click: () => this.createTake() }
        },
        /* {
          title: 'Create',
          class: "p-order-3 p-mr-1",
          permissionType: 'c',
          show: true,
          disabled: false,
          handler: { click: () => this.createTake() }
        }, {
          title: 'Add',
          class: "p-order-2 p-mr-1",
          permissionType: 'c',
          show: true,
          disabled: false,
          handler: { click: () => this.addItem() }
        }, */
      ],
      btnsclass: 'p-d-flex p-col-12',

      change: (idx, value, ipt)=>{
        if(ipt.title == TITLE.TYPE) {
          this._stockTakeTypeValue = value
          this.setPermission(this.queryData.btns, value)
        }
        if(ipt.title == TITLE.CHANNEL){
          if(ipt.change){
            ipt.change(value, ipt)
          }
        }
      }
    }
    this.setSelectOption()
    this.isCreated = false
    const crateDateObj = this.getQueryByTitle(TITLE.TARGETDATE)
    crateDateObj.value = new Date()
    crateDateObj.minDate = new Date()
    // console.log('initial data:');
    // const channelObj = this.getQueryByTitle(QUERYTITLE.CHANNEL)
    // reaction (
    //   () => channelObj.value,
    //   (data) => {
    //     this.tableData.loadingSwitch = true
    //     this.queryItems(data)
    //   }
    // );
    // reaction (
    //   () => this.tableData.ipt.value,
    //   (data) => {
    //     this.addItem()
    //   }
    // );
  }
  // setPermission(list, value) {
  //   let prefix = value === 'BKSUR' ? 'Surprise - ' : ''
  //   list.forEach(item => {
  //     item.title === 'Save' && (item.disabled = !this.permission.includes(prefix + 'Create Stock Take'))
  //   })
  // }
  queryItems(channel) {
    this.addItemDisabled = true
    if (channel) {
      HttpHelper.post(URLDICT.STOCK_COMMON_SEARCHBYREPO, {"dtlRepoId": channel}).then(res => {
        this.tableData.loadingSwitch = false
        // console.log('query items:', res);
        let skus = []
        this.tableData.ipt.options = []
        this.tableData.ipt.value = null

        this.tableData.data = []
        if(res.code === '000' && res.data.length > 0) {
          this.addItemDisabled = false
          res.data.forEach(sku => {
            const name = sku.skuDesc ? sku.skuCode +'-' + sku.skuDesc : sku.skuCode
            // skus.push({code: sku.dtlSkuId, other: null, name})
            skus.push({
              name,
              _data: sku,
              code: sku.dtlSkuId,
              itemCode: sku.skuCode,
              desc: sku.skuDesc,
            })
          })
          // this.showMessage('success', this.info.title, this.info.itemsUpdated)
        } else {
          skus = []
          // tslint:disable-next-line: no-unused-expression
          channel && this.showMessage('warn', this.info.title, this.info.notItems)
        }
        // this.changeItemOptions(skus)
        this.changeItemOptions(skus)
      }).catch(e => console.log('error', e)).finally(() => console.log('Query items completed!'))
    } else {
      // this.addItemDisabled = true
      this.tableData.loadingSwitch = false
      this.tableData.ipt.options = []
      this.tableData.ipt.value = null
      this.tableData.data = []
    }
  }
  changeItemOptions(list) {
    this.tableData.ipt.options = list
  }
  setSelectOption() {
    // const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMGROUP')
    const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE')
      .filter(repo=>repo.key.toLowerCase()!='warehouse')
      .filter(repo=>repo.children.length)
    let ipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.CHANNEL)

    ipt.options = repoList

    // const repoList = LocalStorageHelper.getObject('REPOMODULEBYUAMTREE').filter(repo=>repo.children.length)
    const countByList = LocalStorageHelper.getObject('STOCK_TAKE_COUNT_BY')
      .map(item=>{return {name:item.description,code:item.code}});
    let countByIpt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.COUNTBY)
    countByIpt.options = countByList

    const takeBuList = LocalStorageHelper.getObject('STOCK_TAKE_BU').map(item=>{return {
      label: item.code,
      value: item.id,
    }})
    let takeBuipt = this.queryData.ipts.find(ipt=>ipt.title==TITLE.TAKEBU)
    takeBuipt.options = takeBuList
  }

  validationData() {
    let flag = true
    this.queryData.ipts.forEach(ipt => {
      if(ipt.title === TITLE.CHANNEL && !ipt.value){
        ipt.error = 'error'
        this.showMessage('warn', this.info.title, this.info.selectChannel)
        flag = false
      }
      if(ipt.title === TITLE.TYPE && !ipt.value){
        ipt.error = 'error'
        this.showMessage('warn', this.info.title, this.info.typeRequired)
        flag = false
      }
      if(ipt.title === TITLE.TAKEBU && !ipt.value){
        ipt.error = 'error'
        this.showMessage('warn', this.info.title, this.info._required.replace('${1}', ipt.title))
        flag = false
      }
    })
    return flag
  }
  initDefaultChannel(){
    let channel = CommonMethod.getDefaultChannel(this.channels)
    if(channel){
       this.selectedChannel = [channel];
    }else{
      this.selectedChannel = null
    }
    this.selectedChannelDisplay = FilterCommonMethod.getRepoMultiSelectShowValue(this.selectedChannel);
  }
  // addItem() {
  //   if(this.addItemDisabled) {
  //     return
  //   }
  //   // if(this.validationData()){
  //   //   let list = []
  //   //   if(this.tableData.ipt.value){
  //   //     this.tableData.ipt.value
  //   //     .forEach(item => {
  //   //       const data = {
  //   //         code: item.code,
  //   //         itemCode : item.name.split(' ~ ')[0],
  //   //         itemDesc : item.name.split(' ~ ')[1],
  //   //         normal : 0,
  //   //         faulty : 0,
  //   //         // normal_2 : 0,
  //   //         // faulty_2 : 0,
  //   //         disabled: false
  //   //       }
  //   //       list.push(data)
  //   //     });
  //   //   }
  //   //   this.tableData.data = list
  //   // }
  //   this.tableData.data.push(
  //     {
  //       // code: item.code,
  //       // itemCode : item.name.split(' ~ ')[0],
  //       // itemDesc : item.name.split(' ~ ')[1],
  //       normal : 0,
  //       faulty : 0,
  //       // normal_2 : 0,
  //       // faulty_2 : 0,
  //       disabled: false
  //     }
  //   )

  // }
  delete(idx, data) {
    if(!data.disabled){
      this.conf.confirm({
        message: `Do you want to delete these 1 record?`,
        header: 'Delete Confirmation',
        accept: () => {
          this.tableData.data.splice(idx, 1)
        }
      });
    }
  }
  unique() {
    // 数据去重
    const unique = [...new Set(this.tableData.data.map(item => item.code))]
    return unique.length !== this.tableData.data.length ? true : false
  }
  createTake() {
    if(!this.validationData()){
      return
    }
    if (this.unique()) {
      this.showMessage('warn', this.info.title, this.info.repeat)
      return
    }
    if (this.addItemDisabled) {
      this.showMessage('warn', this.info.title, this.info.notItems)
      return
    }
    let countByIpt = this.getQueryByTitle(TITLE.COUNTBY)
    if(!countByIpt.value){
      this.showMessage('warn', this.info.title, this.info.countByEmpty)
      return
    }
    this.tableData.loadingSwitch = true
    let items = []
    if(this.tableData.data){
      this.tableData.data.forEach(item => {
      //normal(FG-1001) & faulty(FAULTY-1002)
        const tempNormalItem = {
          itemId: item.code,
          stockConditionId: "1001",
          qtyCount: 0
        };

        const tempFaultyItem = {
          itemId: item.code,
          stockConditionId: "1002",
          qtyCount: 0
        };

        items.push(tempNormalItem)
        items.push(tempFaultyItem)
      })
    }

    // const repos = LocalStorageHelper.getObject("REPO");
    // const channel = repos.find(item => item.id === this.channel)
    let channelIpt = this.getQueryByTitle(TITLE.CHANNEL)
    // let countByIpt = this.getQueryByTitle(QUERYTITLE.COUNTBY)
    let countByValue = countByIpt.options.find(item=>item.code == countByIpt.value).name
    let takeBu = this.getQueryByTitle(TITLE.TAKEBU)?.value
    let remark = this.getQueryByTitle(TITLE.REMARKS)?.value

    let data = {
      accountName: this.commonService.getAccount(),
      channelId: channelIpt.value.id,
      channelCode: channelIpt.value.label,
      targetDate: this.targetDate.getTime(),
      // isSurpriseStockTake: this.surprise && this.surprise.length > 0 ? true : false,
      bu: takeBu,
      countBy: countByValue,
      remarks: remark,
      stockTakeType: this.stocktakeType,
      statusId: 1,
      items
    }
    HttpHelper.post(URLDICT.STOCK_TAKE_CREATE, data).then(r => {
      // console.log('create finishfully', res);
      this.tableData.loadingSwitch = false
      if (r.code === '000') {
        this.isCreated = true
        this.showMessage('success', this.info.title, this.info.createSuccess)
        this.resetTakeDetail(r.data[0]);
        this.disableAll()
        this.backDetailPage(r.data[0])
      } else {
        this.showMessage('error', this.info.title, this.info.createFail)
      }
    }).catch(e => console.log('error', e)).finally(() => {
      // console.log('Create a Take completed!')
      this.tableData.loadingSwitch = false
    })
  }
  backDetailPage(data) {
    this.tableData.loadingSwitch = true
    /* let _data = {
      // ...data,
      // channelId: Array.isArray(data.channelId)?data.channelId:[data.channelId]
      id: data.id
    } */
    this.stockTakeService.searchDetailById(data.id).subscribe(res=>{
      if (res.code === '000' && res.data.length > 0) {
        this.tableData.loadingSwitch = false
        this.router.navigate(["main", "stock_take", "detail"], {queryParams: {id: res.data[0].id}});
        // LocalStorageHelper.setObject("takeDetail", res.data[0])
      }
    })
    // HttpHelper.post(URLDICT.STOCK_TAKE_SEARCH_DETAIL, _data).then(res => {
    //   if (res.code === '000' && res.data.length > 0) {
    //     this.tableData.loadingSwitch = false
    //     this.router.navigate(["main", "stock_take", "detail"], {queryParams: res.data[0]});
    //     LocalStorageHelper.setObject("takeDetail", res.data[0])
    //   }
    // })
  }
  resetTakeDetail(obj) {
    // console.log('=> save, ', obj);

    // const skus= LocalStorageHelper.getObject("SKU");
    const repos = LocalStorageHelper.getObject("REPO");
    const statusCode= {
      1: "ST-Draft",
      2: "ST-Review",
      3: "ST-Conf",
    };
    const status= {
      1: "Draft",
      2: "Review In Progress",
      3: "Confirm",
    };
    const repo = repos.find(item => item.id === obj.channelId)

    // let num = 0
    let items = []

    // num = 0
    let label = []

    const data = {
      stockTakeDate: this.targetDate.getTime(),
      create_by: obj.createBy,
      // isSurpriseStockTake: obj.isSurpriseStockTake,
      update_at: obj.updateAt,
      id: obj.id,
      stockCountReferenceNumber: obj.stockCountReferenceNumber,
      create_at: obj.createAt,
      update_by: obj.updateBy,
      stockAdjustmentReferenceNumber: null,
      label,
      items,
      channelCode: repo.label,
      statusCode: statusCode[obj.statusId],
      status: status[obj.statusId],
    };
    sessionStorage.setItem("takeDetail", JSON.stringify(data));
  }
  disableAll(){
    this.queryData.btns.forEach((item, idx) => {
      if(idx > 0){
        item.disabled = true
      }
    })
    this.queryData.ipts.forEach(item => {
      item.disabled = true
    })
    this.tableData.data.forEach(item => {
      item.disabled = true
    })
  }
  saveTake() {}

  back() {
    this.isCreated ? this.router.navigate(["main",'stock_take','detail']) : window.history.back()
  }

  showMessage(severity, summary, detail){
    this.msg.add({severity, summary, detail});
  }

  tableItemChange(e, item) {
    if (e.value) {
      let selectedItem = this.tableData.ipt.options.find(option=>{
        return option.code == e.value
      })
      item.itemCode = selectedItem.itemCode
      item.itemDesc = selectedItem.desc
      item.code = e.value // set from html ngModel
    } else {
      item.itemCode = ''
      item.itemDesc = ''
      item.code = ''
    }
  }

  loadItemListBySkuModule(){
    return this.commonService.getSkuModuleList().pipe(
      switchMap(res=>{
        return this.stockCommonService.getItemListByItemId({itemId: res.map(sku=>sku.value)})
      }),
      tap(res=>{
        if (res) {
          let _items = []
          res.forEach(element => {
            _items.push({code: element.itemid, name: element.codedesc, flag: element.itemdesc, itemCode: element.item, _data: element})
          });
          this.itemList = _items;
          this.setAddPanelOptions()
        }
      })
    )
  }

  // initBulkAddPanel(){
  //   this.bulkAddQueryPanel={
  //     ipts:[
  //       {
  //         title: QUERYTITLE.BULKADD_FILTER,
  //         type: INPUT_TYPE.SELECT,
  //         class: "p-col-12 p-md-4 p-lg-4",
  //         value: null,
  //         options: [
  //           // wip: hardcode, will store at db
  //           {code:1,name:'Items By Brand Name'},
  //           {code:2,name:'Items With Available Stock'},
  //           {code:3,name:'All Items'},
  //         ],
  //         optionLabel: "name",
  //         optionValue: "code",
  //         change:(i,e,ipt)=>{
  //           this.bulkAddOptionChanged(i,e,ipt)
  //         },
  //       },
  //       {
  //         title: QUERYTITLE.BULKADD_BRAND,
  //         type: INPUT_TYPE.SELECT,
  //         class: "p-col-12 p-md-4 p-lg-4",
  //         value: null,
  //         options: [
  //         ],
  //         disabled: true,
  //         optionLabel: "name",
  //         optionValue: "code",
  //         change:(i,e,ipt)=>{
  //           this.bulkAddOptionChanged(i,e,ipt)
  //         },
  //       },
  //       {
  //         title: QUERYTITLE.BULKADD_ITEM,
  //         type: INPUT_TYPE.MUTIPLESELECT,
  //         class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
  //         value: null,
  //         options: [],
  //         disabled: true,
  //         optionLabel: "itemDesc",
  //         optionValue: "id"
  //       },
  //     ],
  //     btns:[
  //       {
  //         title: 'Add Items',
  //         class: "p-ml-auto",
  //         show: true,
  //         handler: { click: () => this.bulkAdd() }
  //       },
  //     ],
  //     btnsclass: 'p-d-flex p-col-12',
  //   }
  // }

  initFilterAddPanel(){
    this.filterAddPanel={
      ipts:[
        {
          title: TITLE.BU,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "label",
          optionValue: "value",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
            const availableInput = this.filterAddPanel.ipts.find(
              (input) => input.title === TITLE.AVAILABLE
            );
            if (availableInput) {
              availableInput.value = ipt.value.length > 0 ? 'Y' : null;
            }
          },
        },
        {
          title: TITLE.LOB,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        //ADD by zhang henry start
        {
          title: TITLE.MAINCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.SUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        //ADD by zhang henry end
        {
          title: TITLE.SUBSUBCAT,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.BULKADD_BRAND,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            // {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        {
          title: TITLE.AVAILABLE,
          type: INPUT_TYPE.SELECT,
          class: "p-col-12 p-md-4 p-lg-4",
          value: null,
          options: [
            {code:'Y',name:'Yes'},
            // {code:'N',name:'No'},
          ],
          optionLabel: "name",
          optionValue: "code",
          change:(i,e,ipt)=>{
            // this.bulkAddOptionChanged(i,e,ipt)
          },
        },
        /*        {
                  type: null,
                  class: "p-d-none p-d-md-block p-md-4 p-lg-4",
                },*/
        {
          title: TITLE.SELECT_ITEM,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
          value: null,
          options: [],
          disabled: false,
          // optionLabel: "itemDesc",
          // optionValue: "id"
        },
        {
          title: TITLE.SERIALIZED_ITEM,
          type: INPUT_TYPE.MUTIPLESELECT,
          class: "p-col-12 p-md-4 p-lg-4 cus-multiselect-w100",
          value: null,
          options: [],
          disabled: false,
          // optionLabel: "itemDesc",
          // optionValue: "id"
        },
      ],
      btns:[
        // {
        //   title: 'Add Items By Filter',
        //   class: "p-ml-auto p-mr-1",
        //   show: true,
        //   handler: { click: () => this.filterAdd() }
        // },
        // {
        //   title: 'Add Selected Items',
        //   class: "",
        //   show: true,
        //   handler: { click: () => this.bulkAdd() }
        // },
        {
          title: 'Import',
          class: "p-mr-1",
          show: true,
          handler: { click: () => {this.fileDropper.openFileSelector()} }
        },
        {
          title: 'Add',
          class: "",
          show: true,
          handler: { click: () => {this.filterAdd();this.bulkAdd()} }
        },
      ],
      btnsclass: 'p-col-12 p-text-right',
    }
    // this.getBUCode().subscribe(res=>{
    //   this.filterAddPanel.ipts.find(ipt=>ipt.title == QUERYTITLE.BU).options = this.buCodes
    // })
    this.getItemMasterColumn().subscribe(res=>{
      this.setAddPanelOptions()
    })
  }

  setAddPanelOptions(){
    if(this.queryData.ipts.find(ipt=>ipt.title == TITLE.CHANNEL).value != null){
      // stock take additional items using all item
      // if(!this.haveOptions){
        this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BU).options = this.buCodes
        this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).options = this.lobCode
        this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).options = this.subSubCat
      //ADD by zhang henry start
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).options = this.mainCat
      this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).options = this.subCat
      //ADD by zhang henry end
        this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).options = this.mfgBrand
        this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.AVAILABLE).options = [
          {code:'Y',name:'Yes'},
        ]
        if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SELECT_ITEM).options = this.itemList.map(item=>{return{
          id: item._data.itemid,
          name: item._data.item + ' - ' + item._data.itemdesc,
          itemCode: item._data.item,
          desc: item._data.itemdesc
        }})
        // filter isserialcontrol data for serialized item input
        if(this.itemList)this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SERIALIZED_ITEM).options =
        this.itemList.filter(item=>item._data.isserialcontrol == 'Y').map(item=>{return{
          id: item._data.itemid,
          name: item._data.item + ' - ' + item._data.itemdesc,
          itemCode: item._data.item,
          desc: item._data.itemdesc
        }})
        this.haveOptions = true;
      // }
    }else{
      this.resetAddPanel()
    }
  }

  channelChanged(val){
    if(val){
      this.setAddPanelOptions()
    }else{
      this.resetAddPanel()
      this.clearItemPanel()
    }
  }

  resetAddPanel(){
    this.filterAddPanel.ipts.forEach(ipt => {
      ipt.options = [];
      ipt.value = null
    });
    this.haveOptions = false;
  }

  clearItemPanel(){
    this.tableData.data = [];
  }

  getItemMasterColumn(){
    return this.stockCommonService.getItemMasterColumn().pipe(map(res => {
      this.buCodes = []
      if(res.code === '000'){
        // res.data.forEach((item) => {
        //   this.buCodes.push({name: item,  value: item})
        // });
        if(res.data[0]){
          let data = res.data[0];
          this.buCodes = data['buCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.lobCode = data['lobCode']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subSubCat = data['subSubCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.mfgBrand = data['mfgBrand']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          //ADD by zhang henry start
          this.mainCat = data['mainCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          this.subCat = data['subCat']?.filter(e=>e?.length>0).map(e=>{return {name:e,value:e}})
          //ADD by zhang henry end
        }
        return res.data
      }else{
        return null;
      }
    }))
  }

  bulkAdd(){
    let itemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SELECT_ITEM);
    let serialitemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SERIALIZED_ITEM);
    let itemList = [...itemIpt.value?itemIpt.value:[], ...serialitemIpt.value?serialitemIpt.value:[]]
    this.addItemToTable(itemList)
    itemIpt.value = null;
    serialitemIpt.value = null;
  }

  // bulkAddOptionChanged(i,e,ipt){
  //   let filterIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_FILTER);
  //   let brandIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_BRAND);
  //   let itemIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_ITEM);
  //   if(ipt == filterIpt){
  //     switch(filterIpt.value){
  //       case 1:
  //         brandIpt.disabled = false;
  //         itemIpt.options = []
  //         itemIpt.value = null;
  //         break;
  //       case 2:
  //         brandIpt.disabled = true;
  //         brandIpt.value=null;
  //         itemIpt.disabled = false;
  //         itemIpt.options = []
  //         itemIpt.value = null;
  //         this.loadAvailableItemListByChannel();
  //         break;
  //       case 3:
  //         brandIpt.disabled = true;
  //         brandIpt.value=null;
  //         itemIpt.disabled = false;

  //         let _itemList = this.itemList
  //         .map(item=>{return{
  //           id: item._data.itemid,
  //           name: item._data.item + ' - ' + item._data.itemdesc,
  //           itemCode: item._data.item,
  //           desc: item._data.itemdesc
  //         }})
  //         itemIpt.options = _itemList;
  //         itemIpt.value = null;
  //         break;
  //       default:
  //         brandIpt.disabled = true;
  //         itemIpt.disabled = true;
  //         brandIpt.value = null;
  //         itemIpt.value = null;
  //         break;
  //     }
  //   }else if(ipt == brandIpt){
  //     itemIpt.disabled = false;
  //     itemIpt.options = []
  //     itemIpt.value = null;
  //     let brand = brandIpt.options.find(option=>option.code==brandIpt.value)
  //     if(brand){
  //       let _itemList = this.itemList
  //         .filter(item=>item._data.mfgbrand&&item._data.mfgbrand.toLowerCase()==brand.name.toLowerCase())
  //         .map(item=>{return{
  //           id: item._data.itemid,
  //           name: item._data.item + ' - ' + item._data.itemdesc,
  //           itemCode: item._data.item,
  //           desc: item._data.itemdesc
  //         }})
  //       if(_itemList.length>0){
  //         itemIpt.options = _itemList;
  //       }else{
  //         itemIpt.options = [];
  //       }
  //     }
  //   }
  // }

  // loadAvailableItemListByChannel(){
  //   let channel = this.queryData.ipts.find(ipt=>ipt.title==QUERYTITLE.CHANNEL)
  //   // let filterIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_FILTER);
  //   if(channel.value){
  //     let itemIpt = this.bulkAddQueryPanel.ipts.find(item => item.title === QUERYTITLE.BULKADD_ITEM);
  //     let selectitemIpt = this.filterAddPanel.ipts.find(item => item.title === QUERYTITLE.SELECT_ITEM);
  //     this.getItemListByChannel().subscribe(res=>{
  //       let _res = res
  //       // .filter(item=>{
  //       //   return item.totalOnHand!=null&&item.totalOnHand>0
  //       // })
  //       .map(item=>{
  //         return {
  //           id: item.skuId,
  //           name: item.sku + ' - ' + item.skuDesc,
  //           itemCode: item.sku,
  //           desc: item.skuDesc
  //         }
  //       })
  //       itemIpt.options = _res
  //       selectitemIpt.options = _res
  //     })
  //   }
  // }

  getItemListByChannel(){
    let channel = this.queryData.ipts.find(ipt=>ipt.title==TITLE.CHANNEL)
    if(!channel.value) {
      this.showMessage('warn', this.info.title, this.info.selectChannel)
      return of([])
    }
    return this.stockCommonService.searchBalance({
      accountName: this.commonService.getAccount(),
      isIncludeLis: 0,
      pageIndex: 0,
      pageSize: 2147483647,
      shopId: [{id: channel.value.id, key: channel.value.key}],
      skuNum: [],
      sortEvent: {},
    })
  }

  // filteradd function logic
  // not (( have value && not selected value ) || ( have value && not selected value ))
  // !((true && !true = false) || (true && !false = true) = true) = false
  // filter return false when item value not exist in selected option
  //

  filterAdd(){

    let haveFilter = false;

    let _bu = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BU).value
    let _lob = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.LOB).value
    let _sscat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBSUBCAT).value
    //ADD by zhang henry start
    let _maincat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.MAINCAT).value
    let _subcat = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.SUBCAT).value
    //ADD by zhang henry end
    let _brand = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.BULKADD_BRAND).value
    let _available = this.filterAddPanel.ipts.find(ipt=>ipt.title == TITLE.AVAILABLE).value

    let bu = _bu?_bu.map(val=>val.value):[]
    let lob = _lob?_lob.map(val=>val.value):[]
    let sscat = _sscat?_sscat.map(val=>val.value):[]
    let brand = _brand?_brand.map(val=>val.value):[]
    let available = _available?_available=='Y':null
    //ADD by zhang henry start
    let maincat = _maincat?_maincat.map(val=>val.value):[]
    let subcat = _subcat?_subcat.map(val=>val.value):[]
    if(maincat.length==0&&subcat.length==0
      //ADD by zhang henry end
      &&bu.length==0&&lob.length==0&&sscat.length==0&&brand.length==0&&!available) return;

    let itemAfterFilter = (maincat.length==0&&subcat.length==0&&bu.length==0&&lob.length==0&&sscat.length==0&&brand.length==0)?this.itemList:this.itemList.filter(item=>{
      return !(
        (bu.length>0 && !bu.includes(item._data.buCode))||
        (lob.length>0 && !lob.includes(item._data.lobCode))||
        (sscat.length>0 && !sscat.includes(item._data.subSubCat))||
        (brand.length>0 && !brand.includes(item._data.mfgbrand))||
        //ADD by zhang henry start
        (maincat.length>0 && !maincat.includes(item._data.mainCat))||
        (subcat.length>0 && !subcat.includes(item._data.subCat)) ||
        (item._data.isActive == "N")
        //ADD by zhang henry end
      )
    })
    haveFilter = itemAfterFilter.length > 0
    if(haveFilter==false&&available==null){
      // no thing selected, skip add items
      return
    }
    if(available == true){
      this.getItemListByChannel().subscribe(res=>{
        let _res = res.filter(item=>{
          return available?item.totalOnHand!=null&&item.totalOnHand>0:item
        }).filter(item=>{
          return itemAfterFilter.map(_itemAfterFilter=>_itemAfterFilter._data.item).indexOf(item.sku)>-1
        }).filter(item=>{
          //过滤不活动的item
          return item.isActive != 'N'
        }).map(item=>{
          return {
            id: item.skuId,
            name: item.sku + ' - ' + item.skuDesc,
            itemCode: item.sku,
            desc: item.skuDesc
          }
        })
        this.addItemToTable(_res)
      })
    }else if(haveFilter == true && available == null){
      let _res = itemAfterFilter.filter(item=>{
        return item.isActive != 'N'
      }).map(item=>{return{
        id: item._data.itemid,
        name: item._data.item + ' - ' + item._data.itemdesc,
        itemCode: item._data.item,
        desc: item._data.itemdesc
      }})
      this.addItemToTable(_res)
    }
  }

  addItemToTable(_itemList){
    let existItemId = [];
    let itemList = _itemList.filter(e=>{
      let duplicate = existItemId.includes(e.id)
      if(!duplicate) existItemId.push(e.id)
      return !duplicate
    })
    if(itemList && itemList.length>0)itemList.forEach(item => {
      let isDuplicate = this.tableData.data.some(row=>row.code == item.id)
      if(!isDuplicate) this.tableData.data.push(
        {
          code: item.id,
          itemCode : item.itemCode,
          itemDesc : item.desc,
          normal : 0,
          faulty : 0,
          // normal_2 : 0,
          // faulty_2 : 0,
          disabled: false
        }
      )
    });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
          result = -1;
      else if (value1 != null && value2 == null)
          result = 1;
      else if (value1 == null && value2 == null)
          result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
      else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  getPermission(...e){
    // this.permissions = LocalStorageHelper.getObject('PERMISSIONS')[p.snapshot.data.code]
    // this.setPermission(this.queryData.btns)
    this.setPermission()
  }

  setPermission(...e){
    let permissionService = this.permissionService
    let queryBtn = this.queryData?.btns
    queryBtn && queryBtn.forEach(item=>{
      if(item.title==TITLE.CREATE_SAVE){
        if(SURPRISE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)) {
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE)
        }else if(FRONTLINE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)) {
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE)
        }else{
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE)
        }
      }
    })
    let filterAddBtn = this.filterAddPanel?.btns
    filterAddBtn && filterAddBtn.forEach(item=>{
      if(item.title==TITLE.CREATE_ADD){
        if(SURPRISE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)) {
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SURP_CREATE)
        }else if(FRONTLINE_STOCK_TAKE_CODE.includes(this._stockTakeTypeValue)) {
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_FRONT_CREATE)
        }else{
          item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE)
        }
      }
    })
    // this.tablePannel && this.tablePannel.btns.length>0 && this.tablePannel.btns.forEach(item=>{
    //   // item.title==TITLE.SEARCH && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_SEARCH))
    //   // item.title==TITLE.CREATE && (item.disabled = !permissionService.havePermission(FUNCTION_CODE.STOCK_TAKE_CREATE))
    // })
  }

  fileDropped(e){
    let reader = new FileReader();
    e[0].fileEntry.file(file=>{
      reader.readAsText(file)
      reader.onload=()=>{
        let res = (<string>reader.result).split(/\r?\n/).filter(content=>content.length>0);
        this.addItemByItemCode(res)
        // res.forEach(val=>{
        //   let success = this.addToSerialList(val)
        //   if(success){successCount++}else{duplicateCount++}
        // });
        // this.setSerialList()
        // if(successCount) this.showMessage('info', 'Add Success', `${successCount} Serial Number Added`)
        // if(duplicateCount) this.showMessage('error', 'Add Fail', `${duplicateCount} Serial Number Duplicated`)
      }
    })
  }

  addItemByItemCode(itemCodeList = []){
    let itemIpt = this.filterAddPanel.ipts.find(item => item.title === TITLE.SELECT_ITEM);
    let options = itemIpt.options
    let itemList = options.filter(option=>itemCodeList.includes(option.itemCode))
    let foundItemCodeList = itemList.map(item=>item.itemCode)
    let notFoundItemCodeList = itemCodeList.filter(itemCode=>!foundItemCodeList.includes(itemCode))
    this.addItemToTable(itemList)
    let limit = 20
    let limitedNotFoundItemCodeList = notFoundItemCodeList.slice(0, limit)
    if(limitedNotFoundItemCodeList.length>0) this.showMessage('error', this.info.title, `Item code ${limitedNotFoundItemCodeList.toString().replace(/,/g, ', ')} (Total: ${notFoundItemCodeList.length}, displayed: ${limitedNotFoundItemCodeList.length}) not found`)
  }

  init(){
    this.takeStatus = LocalStorageHelper.getObject('TAKE_STATUS');
  }
}
