import { CommonService } from '@/service/common/common-service';
import { URLDICT } from '@/stores/base/BaseStore';
import { HttpHelper } from '@/util/HttpHelper';
import { LocalStorageHelper } from '@/util/LocalStorageHelper';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { action } from 'mobx';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public oauthService: OAuthService, public r: Router, public commonService: CommonService) { }

  ngOnInit(): void {
  }
  
  @action logout() {
    this.commonService.logout()
    // this.clearSession().then(()=>{
    //   LocalStorageHelper.removeAll()
    //   sessionStorage.clear()
    //   this.oauthService.logOut()
    //   this.r.navigate([""])
    // })
  }

  // /**
  //  * 调API删除redis中的session信息
  //  */
  // clearSession(){
  //   return HttpHelper.post(URLDICT.SYSTEM_LOGOUT, {}).then(
  //     res => {
  //       console.log(res)
  //     }
  //   )
  // }
}
